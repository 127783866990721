import React, { Component, useState, useEffect, useCallback, useRef } from "react";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Form from "react-bootstrap/Form";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { API_BASE_URL } from "../../api_url";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Highlight } from "@ckeditor/ckeditor5-highlight";
// import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromHTML } from "draft-js";
// import { ProgressBar } from "react-bootstrap";
import { saveAs } from "file-saver";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Skeleton, Box  } from '@mui/material';
import CountUp from 'react-countup';


const servicesBanner = require("../../assets/img/services-banner-min.jpg");
const docsImg = require("../../assets/img/docs-img.png");
const pdfImg = require("../../assets/img/pdf.png");

const rtfImg = require("../../assets/img/rtf-img.png");
const odtImg = require("../../assets/img/odt-img.png");
const pagesImg = require("../../assets/img/pages-img.png");
const pptImg = require("../../assets/img/ppt-img.png");
const xlsxImg = require("../../assets/img/xlsx-img.png");
const gdocImg = require("../../assets/img/gdoc-img.png");
const gslideImg = require("../../assets/img/google-slides.png");
const gsheetImg = require("../../assets/img/gsheet-img.png");
const plainTextImg = require("../../assets/img/txt-img.png");
const LatexImg = require("../../assets/img/text-img.png");

const choseEditorIcon = require("../../assets/img/chose-editor-icon.png");
const closedAutoSearch = require("../../assets/img/close-icon.png");

const Servicesnewclient = (state) => {
  const navigate = useNavigate();
  const [orderCancel, setOrderCancel] = useState(false); //modal
  const orderCancelClose = () => setOrderCancel(false); //modal-close

  const [userDetails, setUserDetails] = useState(false); //modal
  const userDetailsClose = () => setUserDetails(false); //modal-close

  const [showServiceName, setShowServiceName] = useState(); //set service name
  const [showClientData, setShowClientData] = useState(); // for editor info
  const [showWC, setShowWC] = useState([]); // for individual wc
  const [manualWC, setManualWC] = useState([]);
  const [isChecked, setIsChecked] = useState(false); // clicked if checkbox is enable
  const [showingWCText, setShowingWCText] = useState(false);

  const [serviceFileObj, setServiceFileObj] = useState([]);

  const [serviceId, setServiceId] = useState();
  const [isClickedClient, setIsClickedClient] = useState(false);

  // const onLoginFormSubmit = (e) => {
  //     e.preventDefault();
  //     orderCancelClose();
  //     orderCancelFormShow();
  // }
  // const {id,name} = useParams();
  const { name } = useParams();
  console.warn(name);
  const location = useLocation();
  // const {id} = location.state ?? '';
  // console.log(location.pathname);
  console.log(location); // here id is service id
  let data = JSON.parse(localStorage.getItem("user_data_elite"));
  let data1 = JSON.parse(localStorage.getItem("allFormData"));
  console.log(data);

  const [userData, setUserData] = useState(data);
  const [serviceInfo, setServiceInfo] = useState(); // service info
  const [subService, setSubService] = useState([]); // used to store subservices
  const [serviceFile, setServiceFile] = useState([]); // service file upload
  const [serviceFileNames, setServiceFileNames] = useState([]); // service file name
  const [instructionFile, setInstructionFile] = useState([]); // service file upload
  const [instructionFileNames, setInstructionFileNames] = useState([]); // instruction file names
  const [instructionFullFileNames, setInstructionFullFileNames] = useState([]); // instruction file names
  const [loading, setLoading] = useState(false); // this is for loader
  const [loading1, setLoading1] = useState(false); // this is for loader1

  // const [progress,setProgress] = useState(0); // this is for progress bar
  const [countDetails, setCountDetails] = useState(); // for count information

  const [updateCount, setUpdateCount] = useState(0); //File changes count
  const [updateRegPrice, setUpdateRegPrice] = useState(0); //File changes regular price
  const [updateExpPrice, setUpdateExpPrice] = useState(0); //File changes Expedited price
  const [updateRegService, setUpdateRegService] = useState(0); //File changes regular Service hrs
  const [updateExpService, setUpdateExpService] = useState(0); //File changes regular Service hrs

  const [onChangeUpdateRegPrice, setOnChangeUpdateRegPrice] = useState(0);
  const [onChangeUpdateExpPrice, setOnChangeUpdateExpPrice] = useState(0);
  const [onChangeUpdateRegService, setOnChangeUpdateRegService] = useState(0);
  const [onChangeUpdateExpService, setOnChangeUpdateExpService] = useState(0);

  const [selectedSubService, setSelectedSubService] = useState(); // for Selected sub service
  // const [selectedSubServiceName,setSelectedSubServiceName] = useState(); // for Selected sub service name
  const [selectedVersion, setSelectedVersion] = useState(); // for Selected version
  const [service, setService] = useState(); // for selected service
  const [showLevel, setShowLevel] = useState("Regular Service");
  const [serviceType, setServiceType] = useState(); // set service type
  const [editorData, setEditorData] = useState(); // for CK Editor Data
  const [autoData, setAutoData] = useState(false);
  const [instructFile, setInstructFile] = useState(); // store instruct file
  const [isVisible, SetIsVisible] = useState(false); //disable
  const [showSFile, setShowSFile] = useState(false);
  const [showIFile, setShowIFile] = useState(false);

  const [showError, setShowError] = useState(false); // for all fields validation
  const [fileDlt, setFileDlt] = useState();
  const [insFileDelete, setInsFileDelete] = useState();

  //multiple file upload service
  const [file, setFile] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [mergeServiceFile, setMergeServiceFile] = useState([]); // This is for merge file
  const [mergeServiceFileNames, setMergeServiceFileNames] = useState([]); // This is for merge file
  const [mergeServiceFullFileNames, setMergeServiceFullFileNames] = useState([]); // This is for merge file full name
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  //multiple file upload instruction
  const [insFile, setInsFile] = useState([]);
  const [insFileNames, setInsFileNames] = useState([]);

  const [validMatch, setValidMatch] = useState(0);

  const [isEmailExist, setIsEmailExist] = useState("");
  const [isPhoneExist, setIsPhoneExist] = useState("");
  const [isUplodad, setIsUpload] = useState(false);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [demoObj, setDemoObj] = useState({});

  const [demoObjExpPrice, setDemoObjExpPrice] = useState({});
  const [demoObjRegService, setDemoObjRegService] = useState({});
  const [demoObjExpService, setDemoObjExpService] = useState({});

  const [clientInfoModal, setClientInfoModal] = useState(false);
  const handleShow = () => setClientInfoModal(true);
  const handleClose = () => setClientInfoModal(false);
  const [showClientList, setShowClientList] = useState([]); // for all clients
  const [searchByEmailArr, setSearchByEmailArr] = useState([]); // search list by email

  //client search
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState();

  //Search features
  const ref = useRef(null);
  const [showOption, setShowOption] = useState(1);
  const [searchingData, setSearchingData] = useState();
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showExistingEditor, setshowExistingEditor] = useState([]); // existing client list
  const [showFilterData, setShowFilterData] = useState([]);
  const [selectEditor, setSelectEditor] = useState(); //select editor or not
  const [selectedEditorId, setSelectedEditorId] = useState(); // select editor id
  const [selectedEditorName, setSelectedEditorName] = useState(); // select editor name
  const [selectedEditorEmail, setSelectedEditorEmail] = useState(); // select editor email
  const [showRole, setShowRole] = useState();
  const [listEditor, setListEditor] = useState([]); //autocomplete search
  const [isSearchValueClicked, setIsSearchValueClicked] = useState(false);

  const [showCloseButton, setShowCloseButton] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState();
  const [isAddedClient, setIsAddedClient] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalDataAdded, setModalDataAdded] = useState(false);

  const onClear = () => {
    ref.current.value = "";
  };

  //Editor/Internal Search
  const handleDataSearch = (e) => {
    searchData(e.target.value);
    setSearchingData(e.target.value);
  };

  const searchData = (data) => {
    // console.log(data)
    setShowSearch(true);
    if (data) {
      const filterData = edtId.filter((item) => {
        console.log(item.designation.indexOf(data));
        return (
          // item.designation.indexOf(data) >= 0
          item.designation.indexOf(data) >= 0
        );
      });
      setShowFilterData(filterData);
      if (filterData.length > 0) {
        setNoRecordFound(false);
      } else {
        setNoRecordFound(true);
      }
    } else {
      setShowFilterData(edtId);
      // setNoRecordFound(true);
    }
  };

  // Editor Details
  const editorDetailsById = async (editor_id) => {
    // https://demoupdates.com/updates/elite/api/job/existingcount?user_id=62&editor_id=115
    console.log(editor_id);

    if (editor_id != "null") {
      try {
        let apiRes = await axios.get(`${API_BASE_URL}job/existingcount?user_id=${data?.id}&editor_id=${editor_id}`);
        if (apiRes) {
          console.log(apiRes.data.res);
          if (apiRes.data.data >= 8) {
            NotificationManager.warning("This Editor is already in 8 jobs,please choose another editor", "", 4000);
            setSelectEditor("null");
            // editorDetailsById(null);
          } else {
            let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`);
            if (apiRes) {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) {
                let fullName = "";
                fullName = apiRes.data.data[0].first_name + " " + apiRes.data.data[0].last_name;
                console.log(apiRes.data.data[0]);
                setSelectedEditorId(editor_id);
                setSelectedEditorName(fullName);
                setSelectedEditorEmail(apiRes.data.data[0]?.email_address);
                setSelectEditor(editor_id);
                setShowRole(apiRes.data.data[0]?.role);
                //    console.log(fullName);
                //    console.log(editor_id);
                //    console.log(apiRes.data.data[0]?.email_address);
              } else {
                NotificationManager.error("error", "", 2000);
              }
            }
          }
        }
      } catch (error) {
        // NotificationManager.error(error.message, "", 2000);
      }
    } else {
      setSelectEditor("null");
      setSelectedEditorId();
      setSelectedEditorName();
      setSelectedEditorEmail();
      setSelectEditor();
    }
  };

  //Existing Client List by search
  const existingClientList = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/existingeditorSearch?user_id=${data?.id}`);
      if (apiRes) {
        console.log(apiRes.data.data);
        if (apiRes.data.res == true) {
          // setshowExistingEditor(apiRes.data.data);
          setListEditor([]);
          apiRes.data.data.map((value) => {
            setListEditor((oldArray) => [
              ...oldArray,
              {
                id: value.editor_id,
                name: value.editor_id,
                editor_email: value.editor_email,
                editor_name: value.editor_name,
                designation: value.designation,
                role: value.role,
              },
            ]);
          });
        }
      }
    } catch (error) {
      // NotificationManager.error(error.message, "", 2000);
    }
  };

  //Existing Client List by dropdown
  const existingClientListDropdown = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/existingeditor?user_id=${data?.id}`);
      if (apiRes) {
        console.log(apiRes.data.data);
        if (apiRes.data.res == true) {
          setshowExistingEditor(apiRes.data.data);
        }
      }
    } catch (error) {
      // NotificationManager.error(error.message, "", 2000);
    }
  };

  const edtId = listEditor;
  console.log(edtId);

  useEffect(() => {
    // if (data=== null)
    // {
    //     navigate("/login");
    //     navigate(0);
    // }

    // if(data!=null && (data?.role == '0'))
    // {
    //   navigate("/admin-job-board");
    // }
    // else if(data!=null && (data?.role == '1'))
    // {
    //   navigate("/editor-job-board");
    // }
    // else if(data!=null && (data?.role == '3'))
    // {
    //   navigate("/deputy-admin-job-board");
    // }
    // else if(data!=null && (data?.role == '4'))
    // {
    //   navigate("/internal-editor-job-board");
    // }

    console.warn(showClientData);

    if (data != null && data?.role == "1") {
      navigate("/editor-job-board");
    }
  });

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    getAllServices();
    // getServiceDetailsById();

    // console.log(mergeServiceFileNames)
    // console.log(updateCount)
    // }, [mergeServiceFileNames]);
  }, [name]);

  useEffect(() => {
    getClientInfos();
    // handleChecked1();
    getAllClient();
    existingClientList(data?.id);
    existingClientListDropdown(data?.id);
  }, []);

  useEffect(() => {
    console.warn(serviceFileObj);
    // setServiceFileObj(serviceFileObj)
  }, [serviceFileObj]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      //   handleChecked();
    }
  }, [updateCount]);

  useEffect(() => {
    console.warn(serviceFileObj);
  }, [serviceFileObj]);

  useEffect(() => {
    console.log(selectedOrderType, isAddedClient, clientInfoModal);
  }, [selectedOrderType]);
  useEffect(() => {
    console.log(selectedOrderType);
  }, []);

  // get all services
  const getAllServices = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}service`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          console.log(apiRes.data.data);
          // setShowServices(apiRes.data.data);
          let temArr = apiRes.data.data;
          let matchArr = { name };
          let lower_name = matchArr.name.toUpperCase();
          console.warn(temArr);
          // console.warn(matchArr.name.toUpperCase());
          // const foundElement = myArray.find(item => item === elementToFind);
          const foundElementId = temArr.find((item) => item.title === lower_name);
          console.warn(foundElementId.id);
          getServiceDetailsById(foundElementId.id);
          setServiceId(foundElementId.id);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  // get service deatils by id
  const getServiceDetailsById = async (id) => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}service/servicedetail?id=${id}`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          setServiceInfo(apiRes.data.data[0]);
          setSubService(apiRes.data.data[0].subservice);
          setShowServiceName(apiRes.data.data[0].title);
        }
      }
    } catch (error) {
      // NotificationManager.error(error.message, "", 2000);
    }
  };

  const editorInstructionData = (data) => {
    // let text = "Test hellosumit@gmail.com Hi bye1@3";
    // let mobileRegEx = '/[0][4-5][0-9]{8}/g';

    // let result = data.match("@");
    // let result = data.match(
    //     /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/
    //   );
    // var matches = data.match(/\d+/g);

    // result == null => 'Not Match'
    // (matches != null)=>('number');

    // Regular expression pattern for email validation
    //   var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    var emailPattern1 = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]/;
    //   var phonePattern = /\d{9}/;
    //   var phonePattern1 = /\d{5}\s*\d{5}/;
    //   var phonePattern2 = /\d+\s*\d+/;

    var pattern_phone1 = /\b(?:\d{3}[-.\s]??\d{3}[-.\s]??\d{4}|\d{3}[-.\s]??\d{4})\b/;
    var pattern_phone2 = "/d{10}/";
    var pattern_phone3 = "/d{5}s*d{5}/";
    var pattern_phone4 = /\+\d{2}\s\d{2,}(?:\s*\d+)+/;
    var pattern_phone5 = /\b\d{5}\s*\d{6}\b/;
    var pattern_phone6 = /\b\d{9}\b/;
    var pattern_phone7 = /\b\+?\d+(?:-\d+){2,}\b/;
    var pattern_phone8 = /\b\d{3}(?:-?\d{3}){2}\b|\b\d{10}\b/;
    var pattern_phone9 = /\b\d{3}(?:-?\d{3}){2}\b|\b\d{14}\b/;

    // Find all email addresses in the content using the pattern
    var emails = data.match(emailPattern);
    //   var phones = data.match(phonePattern);
    //   var phones1 = data.match(phonePattern1);
    //   var phones2 = data.match(phonePattern2);
    var phone1 = data.match(pattern_phone1);
    var phone2 = data.match(pattern_phone2);
    var phone3 = data.match(pattern_phone3);
    var phone4 = data.match(pattern_phone4);
    var phone5 = data.match(pattern_phone5);
    var phone6 = data.match(pattern_phone6);
    var phone7 = data.match(pattern_phone7);
    var phone8 = data.match(pattern_phone8);
    var phone9 = data.match(pattern_phone9);

    var emails1 = data.match(emailPattern1);
    // console.log(emails1)
    if (emails != null) {
      setIsEmailExist("yes");
      setIsPhoneExist("no");
      console.log(1);
    } else if (emails1 != null) {
      setIsEmailExist("yes");
      setIsPhoneExist("no");
      console.log(2);
    } else if (
      phone1 != null ||
      phone2 != null ||
      phone3 != null ||
      phone4 != null ||
      phone5 != null ||
      phone6 != null ||
      phone7 != null ||
      phone8 != null ||
      phone9 != null
    ) {
      setIsPhoneExist("yes");
      setIsEmailExist("no");
    }
    // else if(phones!= null)
    // {
    //    setIsPhoneExist('yes');
    //    setIsEmailExist('no');
    // // console.log(emails)
    // // console.log(emails1)
    //     console.log(3);
    // }
    // else if(phones1!= null)
    // {
    //    setIsPhoneExist('yes');
    //    setIsEmailExist('no');
    //    console.log(4);
    // }
    // else if(phones2!= null)
    // {
    //    setIsPhoneExist('yes');
    //    setIsEmailExist('no');
    //    console.log(5);
    //    console.log(phones)
    // }
    // else if(emails!= null && phones!= null || phones1!= null || phones2!= null)
    // {
    //     setIsPhoneExist('yes');
    //     setIsEmailExist('yes');
    //     console.log(6);

    // }
    else {
      setIsPhoneExist("no");
      setIsEmailExist("no");
      console.log(7);
    }

    setEditorData(data);
    //   else
    //   {
    //     // No valid email found or content is empty
    //     console.log('No valid email and phone no found.');
    //     // NotificationManager.warning('Instruction contains emails or digits', "", 2000);
    //   }

    //   return
    // if((result == null) )
    // {
    //     // console.warn('Matched');
    //     setValidMatch(1);
    //     setEditorData(data);
    // }
    // else
    // {
    //     NotificationManager.warning('Instruction contains @ or digits', "", 2000);
    //     setValidMatch(2);
    // 	// console.warn('Not Matched');
    //     setEditorData(data);
    // }
    // console.warn(result)
  };

  //redirect to contact us
  const redirectComponent = () => {
    navigate("/");
  };

  // get editor information
  const getClientInfos = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${data?.id}`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          // console.log(apiRes.data.data);
          setShowClientData(apiRes.data.data[0]);
          setUserData(apiRes.data.data[0]);
          //   localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
        }
      }
    } catch (error) {
      //close try
      // NotificationManager.error(error, "", 2000);
    }
  };

  const updateEditorData = async (values, { resetForm }) => {
    console.log(values);
    try {
      setLoading(true);
      let apiRes = await axios.post(`${API_BASE_URL}account/editaccount`, {
        id: values.id,
        first_name: values.first_name,
        last_name: values.last_name,
        ph_no: values.ph_no.toString(),
        email_address: values.email_address,
        state: values.state,
        address: values.address,
        zipcode: values.zipcode,
        password: values.password,
        role: "2", // 2 for Client
      });
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          NotificationManager.success("Update Successfully", "", 2000);
          getClientInfos();
          setLoading(false);
          userDetailsClose();
        } else {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, '',2000);
    }
  };

  let schema = yup.object().shape({
    first_name: yup
      .string()
      // .ensure()
      .nullable("First name is required")
      .required("First name is required"),

    last_name: yup
      .string()
      .nullable("Last name is required")
      // .ensure()
      .required("Last name is required"),

    ph_no: yup.string().nullable("Phone number is required").required("Phone number is required").max(10, "Invalid Phone number"),

    email_address: yup
      .string()
      .nullable("Email is required")
      .required("Email is required")
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address!"),

    address: yup.string().nullable("Address is required").required("Address is required"),

    state: yup.string().nullable("State is required").required("State is required"),

    zipcode: yup.string().nullable("Zip Code is required").required("Zip Code is required"),
  });

  // const onEditorStateChange = useCallback(
  //     (rawcontent) => {
  //       setEditorState(rawcontent.blocks[0].text);
  //     },
  //     [editorState]
  // );

  const onEditorStateChange = useCallback(
    (rawcontent) => {
      setEditorState(rawcontent.blocks[0].text);
      console.log(rawcontent.blocks[0]);
    },
    [editorState]
  );

  //for deleting files one by one
  // function deleteFile(i)
  // {
  //   const dlt_fileName = fileNames.filter((item,index) => index !== i)
  //   const dlt_file = file.filter((item,index) => index !== i)

  //   setFileNames(dlt_fileName);
  //   setFile(dlt_file);
  //   console.log(data);
  //   console.log(dlt_file);
  // }

  // const deleteUser = value =>{
  //     if (window.confirm("Are you sure you want to delete?")){
  //         axios
  //         .post(
  //           `${API_BASE_URL}deleteUser`,
  //           {UserId:value}
  //         ).then(async (response)=>{
  //           getData(0);
  //           document.getElementById('success_msg').style.display = 'block';
  //           document.getElementById("success_msg").innerHTML = response.data.msg;
  //           setTimeout(() => {
  //             document.getElementById('success_msg').style.display = 'none';
  //             document.getElementById("success_msg").innerHTML = '';
  //           }, 3000);

  //         })
  //     }

  //  }

  //file download
  const downloadFile = (item) => {
    {
      let full_fileName = "https://elitereviser.com/serversite/public/uploads/" + item;
      saveAs(full_fileName);
    }
  };

  //delete file Service Multiple
  const deleteFile = async (i, itm) => {
    console.warn(itm);
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const check_file = itm.replace(/ /g, "");
        let apiRes = await axios.get(`${API_BASE_URL}service/removefiles?service_filename=${check_file}&total_count=${countDetails?.count}`);
        if (apiRes) {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) {
            let newArr = [];
            setCountDetails(apiRes.data.data[0]);
            console.log(apiRes.data.data[0]);

            let copyObj = [...serviceFileObj];
            console.warn(copyObj);

            // setUpdateCount(prev => prev - Number(apiRes.data.data[0].count)); //File changes count
            // setUpdateRegPrice(prev => prev - Number(apiRes.data.data[0].regularprice)); //File changes regular price
            // setUpdateExpPrice(prev => prev - Number(apiRes.data.data[0].expeditedprice)); //File changes Expedited price
            // setUpdateRegService(prev => prev - Number(apiRes.data.data[0].regular)); //File changes regular Service hrs
            // setUpdateExpService(prev => prev -Number(apiRes.data.data[0].expedited)); //File changes regular Service hrs

            setShowingWCText(false); //Not showing Word Count text

            newArr = serviceFileObj.filter((item, index) => index != i);
            console.warn(newArr);

            // setUpdateCount(prev => prev - Number(copyObj[i].wc)); //File changes count
            // setUpdateRegPrice(prev => prev - Number(copyObj[i].regPrice));
            // setUpdateExpPrice(prev => prev - Number(copyObj[i].expPrice));
            // setUpdateRegService(prev => prev - Number(copyObj[i].regService));
            // setUpdateExpService(prev => prev - Number(copyObj[i].expService));

            // setServiceFileObj(newArr);
            NotificationManager.success("File deleted successfully", "", 2000);
            if (newArr.length === 0) {
              setIsUpload(false);
              setServiceFileObj([]);
              setCountDetails();
              setUpdateCount(0);
              setUpdateRegPrice(0);
              setUpdateExpPrice(0);
              setUpdateRegService(0);
              setUpdateExpService(0);
              setIsChecked(false);
            } else {
              setIsUpload(true);
              setIsChecked(false);
              setServiceFileObj(newArr);

              let totalWordCount = 0;
              let regPrice = 0;
              let expPrice = 0;
              let regService = 0;
              let expService = 0;

              for (let i = 0; i < newArr.length; i++) {
                totalWordCount = totalWordCount + Number(newArr[i].wc);

                regPrice = regPrice + Number(newArr[i].regPrice);
                expPrice = expPrice + Number(newArr[i].expPrice);
                regService = regService + Number(newArr[i].regService);
                expService = expService + Number(newArr[i].expService);
              }
              setUpdateCount(totalWordCount);

              setUpdateRegPrice(regPrice);
              setUpdateExpPrice(expPrice);
              setUpdateRegService(regService);
              setUpdateExpService(expService);

              let apiRes1 = await axios.post(`${API_BASE_URL}service/priceCount`, {
                total_wc: totalWordCount,
                // total_wc: apiRes.data.data[0]?.count
              });
              if (apiRes1) {
                if (apiRes.data.res == true) {
                  console.warn(updateRegPrice);

                  setUpdateRegPrice(Number(apiRes1.data.data[0]?.regularprice));
                  setUpdateExpPrice(Number(apiRes1.data.data[0]?.expeditedprice));
                  setUpdateRegService(Number(apiRes1.data.data[0]?.regular));
                  setUpdateExpService(Number(apiRes1.data.data[0]?.expedited));
                } else {
                  // setIsChecked();
                  // NotificationManager.error('test1', "", 2000);
                }
              }
            }
          }
        }
      } catch (error) {
        // alert('catch')
        // NotificationManager.error(error.message, "", 2000);
      }
    }
  };

  //delete file Instruction Multiple
  const deleteInsFile = async (i, itm) => {
    // alert(itm);
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const check_file = itm.replace(/ /g, "");
        const dlt_fileName = insFileNames.filter((item, index) => item !== check_file);
        const dlt_file = insFile.filter((item, index) => item !== check_file);

        // let apiRes = await axios.get(`${API_BASE_URL}job/removeservicefiles?service_filename=${check_file}&total_count=${countDetails?.count}`)
        let apiRes = await axios.get(`${API_BASE_URL}service/removeinstructionfiles?instruction_filename=${check_file}`);
        if (apiRes) {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) {
            let newArr = [];
            let newArrFile = [];

            newArr = instructionFileNames.filter((item, index) => index != i);
            setInstructionFileNames(newArr);
            NotificationManager.success("File deleted successfully", "", 2000);
          }
        }
      } catch (error) {
        // NotificationManager.error(error.message, "", 2000);
      }
    }
  };

  //delete instruction file Single
  const deleteInstructionFile = async (instructionFileName) => {
    // alert(instructionFileName)
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/removeinstructionfile?instruction_filename=${instructionFileName}`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          setInsFileDelete("");
          setInstructionFile("");
          setInstructionFileNames("");
          NotificationManager.success("File deleted successfully", "", 2000);
        }
      }
    } catch (error) {
      NotificationManager.error(error.message, "", 2000);
    }
  };

  //Message
  const handleMessage = (val) => {
    if (val == 1) {
      NotificationManager.warning("Add address and contact number to place the order", "", 4000);
      setUserDetails(true);
    } else if (val == 2) {
      NotificationManager.warning("Only Client have an access to place the order", "", 4000);
    }
  };

  const notLoginSubmit = async () => {
    // alert('notLoginSubmit')
    console.log(service);
    // console.log(data);
    // console.log(data1);
    // console.log('id:' + id);
    // console.log('name:' + name);
    // console.log('selectedSubService:' + selectedSubService);
    // console.log('selectedVersion:' + selectedVersion);

    // return
    let error = 0;
    // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined && editorData==undefined && instructFile==undefined)
    // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined &&  instructFile==undefined)
    if (selectedSubService == undefined && selectedVersion == undefined && countDetails?.service_filename == undefined) {
      // alert(1)
      error = 0;
      NotificationManager.error("All fields are Blank!", "", 2000);
      setShowError(true);
    }
    // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined || editorData==undefined || instructFile==undefined)
    // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined ||  instructFile==undefined)
    // {
    //     // alert(2)
    //     error = 1;
    //     NotificationManager.error('Blank field is not allowed!', '',2000);
    //     setShowError(true);
    // }
    else {
      if (countDetails?.count > 100000) {
        NotificationManager.error("Word Count is greater than 100000", "", 2000);
        // NotificationManager.error('Word Count is greater than 100000', 'Contact Us!', 3000, () => {
        //     // alert('callback');
        //     orderCancel();
        // });
      } else {
        if (isEmailExist === "yes") {
          NotificationManager.warning("Please remove email address from instruction", "", 2000);
        } else if (isPhoneExist === "yes") {
          NotificationManager.warning("Please remove1 phone number from instruction", "", 2000);
        } else if (isEmailExist === "yes" && isPhoneExist === "yes") {
          NotificationManager.warning("Please remove email address and phone number from instruction", "", 2000);
        } else {
          // if (!isChecked)
          // {
          //     NotificationManager.warning('Please check and calculate the price', '',2000);
          // }
          // else
          // {
          localStorage.removeItem("allFormData");
          // alert(3)
          // dis_error = 2;
          var service_type = 2;
          var total_cost = 0;
          var total_hour = 0;
          // console.log(service)
          let temArr = [];
          {
            serviceFileObj?.map((item, index) => {
              temArr.push("https://elitereviser.com/serversite/public/uploads/" + item?.servFile);
            });
          }
          setMergeServiceFullFileNames(temArr);

          if (showLevel === "Regular Service") {
            service_type = 0;
            // total_cost = countDetails?.regularprice;
            total_cost = updateRegPrice;
            total_hour = updateRegService;
          } else if (showLevel === "Expedited Service") {
            service_type = 1;
            // total_cost = countDetails?.expeditedprice;
            total_cost = updateExpPrice;
            total_hour = updateExpService;
          }
          const collectdData = {
            // id: id,
            id: serviceId,
            service_name: showServiceName,
            sub_service: selectedSubService,
            english_version: selectedVersion,
            // service_filename: countDetails?.service_filename,
            // service_filename: mergeServiceFullFileNames,
            service_filename: temArr,
            // service_filename: serviceFileObj.servFile,
            // total_wordcount: countDetails?.count,
            total_wordcount: updateCount,
            total_price: total_cost,
            // service_hour: service && service,
            service_hour: total_hour,
            service_type: service_type,
            // client_instruction: editorData,
            client_instruction: editorData && editorData ? editorData : "null",
            instruction_filename: instructionFullFileNames,
            full_description: serviceInfo?.full_description,
            from_count: countDetails?.from_count,
            to_count: countDetails?.to_count,
            job_type: "type1",
            link: "exisingtype",
            option_type: showOption,
            editor_id: selectedEditorId ? selectedEditorId : "",
            editor_name: selectedEditorName ? selectedEditorName : "",
            editor_email: selectedEditorEmail ? selectedEditorEmail : "",
            role: data?.role ? data?.role : "",
          };
          localStorage.setItem("allFormData", JSON.stringify(collectdData));

          navigate("/login", { state: { from: "service" } });
          // }
        }
      }
    }
  };

  const LoggedSubmit = async () => {
    // alert('LoggedSubmit')
    // console.warn('Type: ' +showLevel);
    // console.warn('Reg: ' + updateRegService);
    // console.warn('Exp: ' + updateExpService)
    let error = 0;
    // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined && editorData==undefined && instructFile==undefined)
    // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined && instructionFileNames==undefined)
    if (selectedSubService == undefined && selectedVersion == undefined && mergeServiceFullFileNames?.length == 0) {
      // alert(1)
      error = 0;
      NotificationManager.error("All fields are Blank!", "", 2000);
      setShowError(true);
    }
    // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined || editorData==undefined || instructFile==undefined)
    // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined || instructionFileNames==undefined)
    else if (selectedSubService == undefined || selectedVersion == undefined || countDetails?.service_filename == undefined) {
      // alert(2)
      error = 1;
      console.warn(mergeServiceFullFileNames?.length);
      console.warn(mergeServiceFullFileNames);
      NotificationManager.error("Blank field is not allowed!", "", 2000);
      setShowError(true);
    } else {
      setShowError(false);
      if (countDetails?.count > 100000) {
        navigate("/contact");
      } else {
        if (isEmailExist === "yes") {
          NotificationManager.warning("Please remove email address from instruction", "", 2000);
        } else if (isPhoneExist === "yes") {
          NotificationManager.warning("Please remove phone1 number from instruction", "", 2000);
        } else if (isEmailExist === "yes" && isPhoneExist === "yes") {
          NotificationManager.warning("Please remove email address and phone number from instruction", "", 2000);
        } else {
          localStorage.removeItem("allFormData");
          // alert(3)
          var service_type = 2;
          var total_cost = 0;
          var total_hour = 0;
          console.log(service);
          let temArr = [];
          {
            serviceFileObj?.map((item, index) => {
              temArr.push("https://elitereviser.com/serversite/public/uploads/" + item?.servFile);
            });
          }
          setMergeServiceFullFileNames(temArr);
          if (showLevel === "Regular Service") {
            service_type = 0;
            total_cost = updateRegPrice;
            total_hour = updateRegService;
          } else if (showLevel === "Expedited Service") {
            service_type = 1;
            total_cost = updateExpPrice;
            total_hour = updateExpService;
          }
          const collectdData = {
            // id: id,
            id: serviceId,
            service_name: showServiceName,
            sub_service: selectedSubService,
            english_version: selectedVersion,
            // service_filename: countDetails?.service_filename,
            service_filename: temArr,
            // service_filename: mergeServiceFullFileNames,
            // service_filename: serviceFileObj.servFile,
            // total_wordcount: countDetails?.count,
            total_wordcount: updateCount,
            total_price: total_cost,
            // service_hour: service && service,
            service_hour: total_hour,
            service_type: service_type,
            // client_instruction: editorData,
            client_instruction: editorData && editorData ? editorData : "null",
            instruction_filename: instructionFullFileNames,
            full_description: serviceInfo?.full_description,
            from_count: countDetails?.from_count,
            to_count: countDetails?.to_count,
            job_type: "type1",
            // link: 'exisingtype',d
            link: "Proofreading",
            option_type: showOption,
            editor_id: selectedEditorId ? selectedEditorId : "",
            editor_name: selectedEditorName ? selectedEditorName : "",
            editor_email: selectedEditorEmail ? selectedEditorEmail : "",
            role: showRole ? showRole : "",
            order_type: selectedOrderType,
            job_post_for: inputValue ? inputValue : "clientself",
          };
          localStorage.setItem("allFormData", JSON.stringify(collectdData));
          console.warn(collectdData);

          if (data?.role == 2) {
            navigate("/payment", { state: { from: "service" } });
          }
          // else if(data?.role == 0 || data?.role == 3 || data?.role == 4)
          // {
          //     // alert('free')
          //     setClientInfoModal(true);
          // }
        }
      }
    }
  };

  //Calculate Total Price
  // const handleChecked1 = async() => {
  // //     console.warn(isChecked)
  // //     setIsChecked(!isChecked);
  // //     if(isChecked)
  // //     {
  // //        setUpdateRegService(0);
  // //        setUpdateExpService(0);
  // //     }
  // //     else
  // //     {
  // //         if(updateCount == 0)
  // //         {
  // //             setUpdateRegPrice(0);
  // //             setUpdateExpPrice(0);
  // //         }
  // //         else
  // //         {
  //             let apiRes = await axios.post(`${API_BASE_URL}service/priceCount`,{
  //                 total_wc: 428
  //             });
  //             if (apiRes)
  //             {
  //                 if (apiRes.data.res == true)
  //                 {
  //                     // console.log(apiRes.data.data[0]);
  //                     // setUpdateRegPrice(apiRes.data.data[0].regularprice);
  //                     // setUpdateExpPrice(apiRes.data.data[0].expeditedprice);

  //                     // setUpdateRegService(apiRes.data.data[0].regular);
  //                     // setUpdateExpService(apiRes.data.data[0].expedited);
  //                     // setIsChecked();
  //                 // NotificationManager.success('Sucessfully fetched', "", 2000);
  //                 }
  //                 else
  //                 {
  //                     // setIsChecked();
  //                     // NotificationManager.error('test1', "", 2000);
  //                 }
  //             }
  //         // }
  //     // }
  // }

  //Calculate Total Price
  const handleChecked = async (e, index) => {
    let newObj_regPrice = { ...demoObj };
    let newObj_expPrice = { ...demoObjExpPrice };

    let newObj_regService = { ...demoObjRegService };
    let newObj_expService = { ...demoObjExpService };

    return;
    if (Number(e.target.value) > 0) {
      console.warn("updateCount: " + updateCount);
      console.warn("val: " + Number(e.target.value));
      let apiRes = await axios.post(`${API_BASE_URL}service/priceCount`, {
        total_wc: updateCount + Number(e.target.value),
        // total_wc: Number(e.target.value)
      });
      if (apiRes) {
        if (apiRes.data.res == true) {
          console.warn("Count: " + (Number(updateCount) + Number(e.target.value)));
          console.warn("Regular Price: " + apiRes.data.data[0].regularprice);
          console.warn("Expedited Price: " + apiRes.data.data[0].expeditedprice);
          console.warn("Regular Service: " + apiRes.data.data[0].regular);
          console.warn("Expedited Service: " + apiRes.data.data[0].expedited);

          // setUpdateCount(apiRes.data.data[0].count);
          setUpdateRegPrice((prev) => prev + apiRes.data.data[0].regularprice);
          setUpdateExpPrice((prev) => prev + apiRes.data.data[0].expeditedprice);
          setUpdateRegService((prev) => prev + apiRes.data.data[0].regular);
          setUpdateExpService((prev) => prev + apiRes.data.data[0].expedited);

          // setOnChangeUpdateRegPrice(apiRes.data.data[0].regularprice);
          // setOnChangeUpdateExpPrice(apiRes.data.data[0].expeditedprice);
          // setOnChangeUpdateRegService(apiRes.data.data[0].regular);
          // setOnChangeUpdateExpService(apiRes.data.data[0].expedited);

          newObj_regPrice[index] = apiRes.data.data[0].regularprice;
          newObj_expPrice[index] = apiRes.data.data[0].expeditedprice;
          newObj_regService[index] = apiRes.data.data[0].regular;
          newObj_expService[index] = apiRes.data.data[0].expedited;

          setDemoObj(newObj_regPrice);
          // setDemoObjExpPrice(newObj_expPrice); *
          // setDemoObjRegService(newObj_regService); *
          // setDemoObjExpService(newObj_expService); *

          // setDemoObj(newObj_regPrice);
          // setDemoObj(newObj_regPrice);
          // setDemoObj(newObj_regPrice);

          // setUpdateCount(apiRes.data.data[0].count);
          // setUpdateRegPrice(apiRes.data.data[0].regularprice);
          // setUpdateExpPrice(apiRes.data.data[0].expeditedprice);
          // setUpdateRegService(apiRes.data.data[0].regular);
          // setUpdateExpService(apiRes.data.data[0].expedited);
        } else {
          // setIsChecked();
          // NotificationManager.error('test1', "", 2000);
        }
      }
    } else {
      console.warn(3);
      newObj_regPrice[index] = 0;
      newObj_expPrice[index] = 0;
      newObj_regService[index] = 0;
      newObj_expService[index] = 0;

      setDemoObj(newObj_regPrice);
      setDemoObjExpPrice(newObj_expPrice);
      setDemoObjRegService(newObj_regService);
      setDemoObjExpService(newObj_expService);
    }
  };

  //for service file uploading
  const serviceFileHandle = async (e) => {
    setIsChecked(false);
    if (
      e.currentTarget.files[0].type == "application/msword" ||
      e.currentTarget.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.currentTarget.files[0].type == "application/pdf" ||
      e.currentTarget.files[0].type == "application/rtf" ||
      e.currentTarget.files[0].type == "application/msword" ||
      e.currentTarget.files[0].type == "application/vnd.oasis.opendocument.text" ||
      e.currentTarget.files[0].type == "application/vnd.oasis.opendocument.text-flat-xml" ||
      e.currentTarget.files[0].type == "application/vnd.apple.pages" ||
      e.currentTarget.files[0].type == "" ||
      e.currentTarget.files[0].type == "application/vnd.ms-powerpoint" ||
      e.currentTarget.files[0].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      e.currentTarget.files[0].type == "application/vnd.ms-excel" ||
      e.currentTarget.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.currentTarget.files[0].type == "application/vnd.google-apps.document" ||
      e.currentTarget.files[0].type == "application/vnd.ms-powerpoint.presentation.macroEnabled.12" ||
      e.currentTarget.files[0].type == "application/vnd.ms-powerpoint.slideshow" ||
      e.currentTarget.files[0].type == "application/vnd.google-apps.spreadsheet" ||
      e.currentTarget.files[0].type == "text/plain" ||
      e.currentTarget.files[0].type == "text/plain"
    ) {
      const formData = new FormData();
      setLoading(true);
      setIsUpload(true);
      setShowError(false);
      for (let i = 0; i < e.target.files.length; i++) {
        setFileNames((old) => [...old, e.target.files[i].name]);
        setFile((old) => [...old, e.target.files[i]]);
        formData.append("service_filename[]", e.target.files[i]);
      }
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      let apiRes = await axios.post(`${API_BASE_URL}service/wordcount`, formData, config);
      if (apiRes) {
        if (apiRes.data.res == true) {
          setShowingWCText(true);
          setCountDetails(apiRes.data.data[0]);
          setShowWC((prev) => [...prev, Number(apiRes.data.data[0]?.count)]);
          setUpdateCount((prev) => prev + Number(apiRes.data.data[0]?.count));

          setFileDlt(apiRes.data.data[0].onlyFileName);
          setServiceFileNames(apiRes.data.data[0].onlyFileName);
          setService(apiRes.data.data[0].regular);
          setFile([]);
          if (apiRes?.data?.data[0]?.onlyFileName?.length > 1) {
            for (let i = 0; i < apiRes?.data?.data[0]?.onlyFileName?.length; i++) {
              setMergeServiceFileNames((old) => [...old, apiRes.data.data[0].onlyFileName[i]]);
              setMergeServiceFile((old) => [...old, e.target.files[i]]);
              setMergeServiceFullFileNames((old) => [...old, apiRes.data.data[0].service_filename[i]]);

              if (
                apiRes.data.data[0].onlyFileName[i].toString().endsWith(".doc") ||
                apiRes.data.data[0].onlyFileName[i].toString().endsWith(".DOC") ||
                apiRes.data.data[0].onlyFileName[i].toString().endsWith(".docx") ||
                apiRes.data.data[0].onlyFileName[i].toString().endsWith(".DOCX") ||
                apiRes.data.data[0].onlyFileName[i].toString().endsWith(".pdf") ||
                apiRes.data.data[0].onlyFileName[i].toString().endsWith(".PDF")
              ) {
                setServiceFileObj((prev) => [
                  ...prev,
                  {
                    servFile: apiRes.data.data[0].onlyFileName[i],
                    wc: Number(apiRes.data.data[0]?.singelcount[i]),
                    regPrice: Number(apiRes.data.data[0]?.regularprice),
                    expPrice: Number(apiRes.data.data[0]?.expeditedprice),
                    regService: Number(apiRes.data.data[0]?.regular),
                    expService: Number(apiRes.data.data[0]?.expedited),
                  },
                ]);
              } else {
                setUpdateRegPrice((prev) => prev + 0);
                setUpdateExpPrice((prev) => prev + 0);
                setUpdateRegService((prev) => prev + 0);
                setUpdateExpService((prev) => prev + 0);

                setServiceFileObj((prev) => [
                  ...prev,
                  {
                    servFile: apiRes.data.data[0].onlyFileName[i],
                    wc: "",
                    regPrice: "",
                    expPrice: "",
                    regService: "",
                    expService: "",
                  },
                ]);
              }
            }

            let apiRes1 = await axios.post(`${API_BASE_URL}service/priceCount`, {
              total_wc: updateCount + apiRes.data.data[0]?.count,
            });
            if (apiRes1) {
              if (apiRes.data.res == true) {
                setUpdateRegPrice(Number(apiRes1.data.data[0]?.regularprice));
                setUpdateExpPrice(Number(apiRes1.data.data[0]?.expeditedprice));
                setUpdateRegService(Number(apiRes1.data.data[0]?.regular));
                setUpdateExpService(Number(apiRes1.data.data[0]?.expedited));
              } else {
                // NotificationManager.error('test1', "", 2000);
              }
            }
          } else {
            if (
              apiRes.data.data[0].onlyFileName[0].toString().endsWith(".doc") ||
              apiRes.data.data[0].onlyFileName[0].toString().endsWith(".DOC") ||
              apiRes.data.data[0].onlyFileName[0].toString().endsWith(".docx") ||
              apiRes.data.data[0].onlyFileName[0].toString().endsWith(".DOCX") ||
              apiRes.data.data[0].onlyFileName[0].toString().endsWith(".pdf") ||
              apiRes.data.data[0].onlyFileName[0].toString().endsWith(".PDF")
            ) {
              let apiRes1 = await axios.post(`${API_BASE_URL}service/priceCount`, {
                total_wc: updateCount + apiRes.data.data[0]?.count,
              });
              if (apiRes1) {
                if (apiRes.data.res == true) {
                  setUpdateRegPrice(Number(apiRes1.data.data[0]?.regularprice));
                  setUpdateExpPrice(Number(apiRes1.data.data[0]?.expeditedprice));
                  setUpdateRegService(Number(apiRes1.data.data[0]?.regular));
                  setUpdateExpService(Number(apiRes1.data.data[0]?.expedited));
                } else {
                  // NotificationManager.error('test1', "", 2000);
                }
              }
              setServiceFileObj((prev) => [
                ...prev,
                {
                  servFile: apiRes.data.data[0].onlyFileName[0],
                  wc: Number(apiRes.data.data[0]?.singelcount[0]),
                  regPrice: Number(apiRes.data.data[0]?.regularprice),
                  expPrice: Number(apiRes.data.data[0]?.expeditedprice),
                  regService: Number(apiRes.data.data[0]?.regular),
                  expService: Number(apiRes.data.data[0]?.expedited),
                },
              ]);
            } else {
              setUpdateRegPrice((prev) => prev + 0);
              setUpdateExpPrice((prev) => prev + 0);
              setUpdateRegService((prev) => prev + 0);
              setUpdateExpService((prev) => prev + 0);

              setServiceFileObj((prev) => [
                ...prev,
                {
                  servFile: apiRes.data.data[0].onlyFileName[0],
                  wc: "",
                  regPrice: "",
                  expPrice: "",
                  regService: "",
                  expService: "",
                },
              ]);
            }
          }
          setAutoData(true);
          setShowSFile(true);
          setLoading(false);
        } else {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    } else {
      setLoading(false);
      setShowError(true);
      NotificationManager.error("File type is not supported!", "", 3000);
    }

    e.target.value = "";
  };

  // console.warn(serviceFileObj)

  //for instruction file uploading
  const instructionFileHandle = async (e) => {
    console.log(e.currentTarget.files[0]);
    console.log(e.currentTarget.files[0].type);
    if (
      e.currentTarget.files[0].type == "application/msword" ||
      e.currentTarget.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.currentTarget.files[0].type == "application/pdf" ||
      //New files
      e.currentTarget.files[0].type === "application/rtf" || // .rtf
      e.currentTarget.files[0].type === "application/vnd.oasis.opendocument.text" || // .odt
      e.currentTarget.files[0].type === "application/vnd.oasis.opendocument.text-flat-xml" || // .fodt
      e.currentTarget.files[0].type === "" ||
      e.currentTarget.files[0].type === "application/vnd.apple.pages" || // .pages
      e.currentTarget.files[0].type === "application/vnd.ms-powerpoint" || // .ppt
      e.currentTarget.files[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || // .pptx
      e.currentTarget.files[0].type === "application/vnd.ms-excel" || // .xls
      e.currentTarget.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
      e.currentTarget.files[0].type === "application/vnd.google-apps.document" || // .gdoc
      e.currentTarget.files[0].type === "application/vnd.ms-powerpoint" || // .ppt
      e.currentTarget.files[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || // .pptx
      e.currentTarget.files[0].type === "application/vnd.ms-powerpoint.presentation.macroEnabled.12" || // .pptm
      e.currentTarget.files[0].type === "application/vnd.ms-powerpoint.slideshow" || // .pps
      e.currentTarget.files[0].type === "application/vnd.google-apps.spreadsheet" || // .gsheet
      e.currentTarget.files[0].type === "text/plain" || // .txt
      e.currentTarget.files[0].type === "text/plain" // .text
    ) {
      const formData = new FormData();
      setLoading1(true);
      for (let i = 0; i < e.target.files.length; i++) {
        setInsFile((old) => [...old, e.target.files[i].name]);
        setInsFile((old) => [...old, e.target.files[i]]);
        formData.append("instruction_filename[]", e.target.files[i]);
      }
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      let apiRes = await axios.post(`${API_BASE_URL}service/instructionfiles`, formData, config);

      if (apiRes) {
        if (apiRes.data.res == true) {
          setInsFile([]);
          for (let i = 0; i < apiRes?.data?.data[0]?.onlyFileName?.length; i++) {
            setInstructionFileNames((old) => [...old, apiRes.data.data[0].onlyFileName[i]]);
            setInstructionFullFileNames((old) => [...old, apiRes.data.data[0].instruction_filename[i]]);
          }
          setAutoData(true);
          setShowIFile(true);
          setLoading1(false);
        } else {
          setLoading1(false);
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    } else {
      NotificationManager.error("File is not supported", "", 3000);
    }
    e.target.value = "";
  };

  const handleInputChange = (index, value) => {
    let copyarr = [...serviceFileObj];
    copyarr[index].wc = Number(value) ? Number(value) : "";
    console.warn(copyarr);
    setServiceFileObj(copyarr);
    calculateTotalWordCount();
  };

  // Function to calculate the total word count for all files
  const calculateTotalWordCount = async () => {
    let totalWordCount = 0;
    let regPrice = 0;
    let expPrice = 0;
    let regService = 0;
    let expService = 0;

    for (let i = 0; i < serviceFileObj.length; i++) {
      if (!isNaN(serviceFileObj[i].wc)) {
        // console.warn(1)
        totalWordCount = totalWordCount + Number(serviceFileObj[i].wc);
        regPrice = regPrice + Number(serviceFileObj[i].regPrice);
        expPrice = expPrice + Number(serviceFileObj[i].expPrice);
        regService = regService + Number(serviceFileObj[i].regService);
        expService = expService + Number(serviceFileObj[i].expService);
      } else {
        // console.warn(2)
      }
    }

    let apiRes1 = await axios.post(`${API_BASE_URL}service/priceCount`, {
      total_wc: totalWordCount,
    });
    if (apiRes1) {
      if (apiRes1.data.res == true) {
        console.warn(updateRegPrice);

        setUpdateCount(totalWordCount);
        setUpdateRegPrice(Number(apiRes1.data.data[0]?.regularprice));
        setUpdateExpPrice(Number(apiRes1.data.data[0]?.expeditedprice));
        setUpdateRegService(Number(apiRes1.data.data[0]?.regular));
        setUpdateExpService(Number(apiRes1.data.data[0]?.expedited));
      } else {
        // setIsChecked();
        // NotificationManager.error('test1', "", 2000);
      }
    }

    // setUpdateCount(totalWordCount);

    // console.warn(totalWordCount)

    // setUpdateRegPrice(regPrice);
    // setUpdateExpPrice(expPrice);

    // setUpdateRegService(regService);
    // setUpdateExpService(expService);
  };

  // get all clients
  const getAllClient = async () => {
    // Admin: 0, Editor: 1, Client: 2, Deputy Admin: 3, Internal Editor: 4
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=2`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          setShowClientList(apiRes.data.data);

          setSearchByEmailArr([]);
          apiRes.data.data.map((value) => {
            setSearchByEmailArr((oldArray) => [
              ...oldArray,
              {
                id: value.id,
                name: value.email_address,
                email: value.email_address,
              },
            ]);
          });
        } else {
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  const items_email = searchByEmailArr; // for client list

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnSelect = (item) => {
    // openJobDetlsPnl();
    // getInqueryDetails(item.id)
  };

  const handleInputChangeSearch = (e) => {
    console.warn(items_email);
    const value = e.target.value;
    setInputValue(value);

    // Filter suggestions based on user input
    const filtered = items_email.filter((item) => item.email.toLowerCase().includes(value.toLowerCase()));

    setFilteredSuggestions(filtered);

    setShowSuggestions(true);
    setShowCloseButton(!!value); // Show the close button when there is some input
  };

  const handleSuggestionClick = (selectedItem) => {
    // alert(selectedItem?.id)
    setSelectedClientId(selectedItem?.id);
    setInputValue(selectedItem.email);
    setShowSuggestions(false);
  };

  const handleClearSearch = () => {
    setInputValue("");
    setShowCloseButton(false);
  };

  // order_type
  const handleSelectOrderType = (e) => {
    setSelectedOrderType(e.target.value);
  };

  const allModalFunction = () => {
    handleClose();
    setSelectedOrderType();
    setInputValue();
    setIsError(false);
    setIsClickedClient(false);
    handleClearSearch();
  };

  const modalDataValidation = () => {
    if (data?.role == 1) {
      console.error("Editor cannot post a job");
    } else if (data?.role == 2) {
      if (selectedOrderType == undefined) {
        setModalDataAdded(false);
        NotificationManager.warning("Please select order type", "", 2000);
      } else {
        setModalDataAdded(true);
        handleClose();
      }
    } else {
      if (selectedOrderType == undefined) {
        setModalDataAdded(false);
        NotificationManager.warning("Please select order type", "", 2000);
      } else if (inputValue == undefined || inputValue == "" || inputValue == null || !isClickedClient) {
        NotificationManager.warning("Please select the client", "", 2000);
      } else {
        console.log(isClickedClient);
        setModalDataAdded(true);
        handleClose();
      }
    }
  };

  // add customer
  const handleOrderInfoType = (type) => {
    // alert(type);
    if (type == "client_self") {
      if (selectedOrderType == "Redo Order") {
        // handleClose();
        handleRedoOrderSubmission(type);
        // console.log(isError ,selectedOrderType)
      } else if (selectedOrderType == "New Order") {
        // handleClose();
        handleNewOrderSubmission(type);
        console.log(isError, selectedOrderType);
      } else {
        console.log("error selectedOrderType: " + selectedOrderType);
      }
    } else if (type == "others") {
      if (selectedOrderType == "Redo Order") {
        handleRedoOrderSubmission(type);
      } else if (selectedOrderType == "New Order") {
        handleNewOrderSubmission(type);
      } else {
        NotificationManager.warning("Please select order type2");
      }
    } else {
      console.log("error type parameter: " + type);
    }
  };

  // Redo Order (free job) submission(with login)
  const handleRedoOrderSubmission = async (type) => {
    console.log(data);
    let error = 0;
    if (selectedSubService == undefined && selectedVersion == undefined && mergeServiceFullFileNames?.length == 0) {
      error = 0;
      NotificationManager.error("All fields are Blank!", "", 2000);
      setShowError(true);
    } else if (selectedSubService == undefined || selectedVersion == undefined || countDetails?.service_filename == undefined) {
      error = 1;
      console.warn(mergeServiceFullFileNames?.length);
      console.warn(mergeServiceFullFileNames);
      NotificationManager.error("Blank field is not allowed!", "", 2000);
      setShowError(true);
    } else {
      setShowError(false);
      if (countDetails?.count > 100000) {
        navigate("/contact");
      } else {
        if (isEmailExist === "yes") {
          NotificationManager.warning("Please remove email address from instruction", "", 2000);
        } else if (isPhoneExist === "yes") {
          NotificationManager.warning("Please remove phone number from instruction", "", 2000);
        } else if (isEmailExist === "yes" && isPhoneExist === "yes") {
          NotificationManager.warning("Please remove email address and phone number from instruction", "", 2000);
        } else {
          // alert('001')
          // if(!isAddedClient)
          // {
          //     NotificationManager.warning('Please add client1', '',2000);
          // }
          // else
          // {
          localStorage.removeItem("allFormData");

          var service_type = 2;
          var total_cost = 0;
          var total_hour = 0;
          console.log(service);
          let temArr = [];
          {
            serviceFileObj?.map((item, index) => {
              temArr.push("https://elitereviser.com/serversite/public/uploads/" + item?.servFile);
            });
          }
          setMergeServiceFullFileNames(temArr);
          if (showLevel === "Regular Service") {
            // alert(301)
            service_type = 0;
            total_cost = updateRegPrice;
            total_hour = updateRegService;
          } else if (showLevel === "Expedited Service") {
            // alert(302)
            service_type = 1;
            total_cost = updateExpPrice;
            total_hour = updateExpService;
          }
          const collectdData = {
            // user_id : selectedClientId, // customer_id
            user_id: type == "client_self" ? userData?.id : selectedClientId,
            service_id: serviceId,
            service_name: showServiceName,
            sub_service: selectedSubService,
            english_version: selectedVersion,
            service_filename: temArr,
            total_wordcount: updateCount,
            service_hour: total_hour,
            service_type: service_type,
            client_instruction: editorData && editorData ? editorData : "",
            instruction_filename: instructionFullFileNames,
            full_description: serviceInfo?.full_description,
            from_count: countDetails?.from_count,
            to_count: countDetails?.to_count,
            job_type: "type1",

            editor_id: selectedEditorId ? selectedEditorId : "",
            editor_name: selectedEditorName ? selectedEditorName : "",
            editor_email: selectedEditorEmail ? selectedEditorEmail : "",
            role: showRole ? showRole : "",
            order_type: selectedOrderType,
            job_status: selectedEditorId ? 2 : 1,
            job_request: selectedEditorId ? "1" : "0",
            assign_user: showRole == "1" ? "Editor" : showRole == "4" ? "Internal" : "",
            // job_request:'0',
            client_approve: "0",
          };
          const formData = new FormData();
          Object.entries(collectdData).forEach(([key, value]) => {
            formData.append(key, value);
          });
          // localStorage.setItem('freeJobData', JSON.stringify(collectdData));

          localStorage.setItem("allFormData", JSON.stringify(collectdData));
          console.warn(collectdData);

          const config = {
            headers: { "content-type": "multipart/form-data" },
          };

          let apiRes = await axios.post(`${API_BASE_URL}job/FreeJob`, formData, config);
          if (apiRes.data.res == true) {
            navigate("/payment-success", {
              state: {
                redoData: collectdData,
                responseData: apiRes.data.Job,
                userDetails: apiRes.data.user,
                OrderType: "Redo Order",
              },
            });
          } else {
            NotificationManager.error("Something Went Wrong!", "", 2000);
          }
        }
      }
    }
  };

  // New Order submission(with login)
  const handleNewOrderSubmission = async (type) => {
    // alert('1')
    setClientInfoModal(false);
    console.log(type);
    console.log(isError, selectedOrderType);
    let error = 0;
    if (selectedSubService == undefined && selectedVersion == undefined && mergeServiceFullFileNames?.length == 0) {
      error = 0;
      NotificationManager.error("All fields are Blank!", "", 2000);
      setShowError(true);
    } else if (selectedSubService == undefined || selectedVersion == undefined || countDetails?.service_filename == undefined) {
      error = 1;
      console.warn(mergeServiceFullFileNames?.length);
      console.warn(mergeServiceFullFileNames);
      NotificationManager.error("Blank field is not allowed!", "", 2000);
      setShowError(true);
    } else {
      setShowError(false);
      if (countDetails?.count > 100000) {
        navigate("/contact");
      } else {
        if (isEmailExist === "yes") {
          NotificationManager.warning("Please remove email address from instruction", "", 2000);
        } else if (isPhoneExist === "yes") {
          NotificationManager.warning("Please remove phone number from instruction", "", 2000);
        } else if (isEmailExist === "yes" && isPhoneExist === "yes") {
          NotificationManager.warning("Please remove email address and phone number from instruction", "", 2000);
        } else {
          // setClientInfoModal(true)
          // console.log(isAddedClient);
          // if(isAddedClient)
          // {
          //     NotificationManager.warning('Please add client1', '',2000);
          // }
          // else
          // {
          // if(type == 'client_self')
          // {
          localStorage.removeItem("allFormData");
          var service_type = 2;
          var total_cost = 0;
          var total_hour = 0;
          console.log(service);
          let temArr = [];
          {
            serviceFileObj?.map((item, index) => {
              temArr.push("https://elitereviser.com/serversite/public/uploads/" + item?.servFile);
            });
          }
          setMergeServiceFullFileNames(temArr);
          if (showLevel === "Regular Service") {
            // alert(301)
            service_type = 0;
            total_cost = updateRegPrice;
            total_hour = updateRegService;
          } else if (showLevel === "Expedited Service") {
            // alert(302)
            service_type = 1;
            total_cost = updateExpPrice;
            total_hour = updateExpService;
          }
          const collectdData = {
            // user_id : selectedClientId, // customer_id  userData
            user_id: type == "client_self" ? userData?.id : selectedClientId,
            id: serviceId,
            service_name: showServiceName,
            sub_service: selectedSubService,
            english_version: selectedVersion,
            service_filename: temArr,
            total_wordcount: updateCount,
            total_price: total_cost,
            service_hour: total_hour,
            service_type: service_type,
            client_instruction: editorData && editorData ? editorData : "No instruction included.",
            instruction_filename: instructionFullFileNames,
            full_description: serviceInfo?.full_description,
            from_count: countDetails?.from_count,
            to_count: countDetails?.to_count,
            job_type: "type1",
            link: "Proofreading",
            option_type: showOption,
            editor_id: selectedEditorId ? selectedEditorId : "",
            editor_name: selectedEditorName ? selectedEditorName : "",
            editor_email: selectedEditorEmail ? selectedEditorEmail : "",
            role: showRole ? showRole : "",
            order_type: selectedOrderType,
            // job_post_for: inputValue ? inputValue : 'notClient'
            job_post_for: type,
          };

          localStorage.setItem("allFormData", JSON.stringify(collectdData));
          console.warn(collectdData);

          // if(data?.role!= 2 && data?.role!= 1)
          if (data?.role != 1) {
            navigate("/payment", { state: { from: "service" } });
          }
          // }
        }
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="inner-banner" style={{ backgroundImage: `url(${servicesBanner})` }}>
        <div className="container">
          <h3 data-aos="fade-up">Services</h3>
          <p data-aos="fade-up">We place great diligence on every work submitted. You can always count on us to achieve the best outcome.</p>
        </div>
      </div>
      <div className="services-sec-one">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-4 left-text-content">
              <h3>{showServiceName ? showServiceName : <Skeleton variant="rectangular" width={265} height={60} />}</h3>
            </div>
            <div className="col-md-7 col-lg-8 right-text-content">
              <p>
                {serviceInfo?.full_description ? serviceInfo?.full_description : 
                <Box sx={{ width: 700 }}>
                  <Skeleton variant="text" animation="pulse" sx={{ mb: 1 }} />
                  <Skeleton variant="text" animation="pulse" sx={{ mb: 1 }} />
                  <Skeleton variant="text" animation="pulse" sx={{ mb: 1 }} />
                </Box>
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-sec-two">
        <div className="container">
          <ul className="row service-form-list">
            <li className="col-md-6">
              <Form.Group>
                <Form.Select
                  name="subservice"
                  onChange={(e) => {
                    setSelectedSubService(e.target.value);
                  }}
                >
                  <option hidden>Service Option</option>
                  {subService &&
                    subService.map((item, index) => {
                      return <option value={item?.title}>{item?.title}</option>;
                    })
                  }
                </Form.Select>
              </Form.Group>
              {showError && (selectedSubService == undefined || selectedSubService == "") && (
                <div>
                  <p style={{ color: "red", fontFamily: "Poppins", fontWeight: "400" }}>Service Option is required</p>
                </div>
              )}
            </li>
            <li className="col-md-6">
              <Form.Group>
                <Form.Select
                  name="version"
                  onChange={(e) => {
                    setSelectedVersion(e.target.value);
                  }}
                >
                  <option hidden>Select English Version</option>
                  <option value="1">American</option>
                  <option value="2">Australian</option>
                  <option value="3">British</option>
                  <option value="4">Canadian</option>
                </Form.Select>
              </Form.Group>
              {showError && (selectedVersion == undefined || selectedVersion == "") && (
                <div>
                  <p style={{ color: "red", fontFamily: "Poppins", fontWeight: "400" }}>English Version is required</p>
                </div>
              )}
            </li>

            <li className="col-lg-6">
              {/* {selectedFileFormat && selectedFileFormat!= '0'  && ( */}
              <div className="row align-items-center">
                <div className="col-md-3">
                  {loading ? (
                    <>
                      <i class="fa fa-refresh fa-spin" style={{ fontSize: "48px" }}></i>
                      <br />
                    </>
                  ) : (
                    <div className="styles-browser-sec">
                      {/* <input type="file" className="browseInput" name = "file"  onChange={(e) => {serviceFileHandle(e)}}/> */}
                      {/* <input type="file" id="file" multiple name = "file" onChange={e => {fileHandle(e); setFieldValue('file',e.currentTarget.files[0]); }}/> */}
                      <input
                        type="file"
                        className="browseInput"
                        id="file"
                        multiple
                        name="file"
                        onChange={(e) => {
                          serviceFileHandle(e);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-9 upload-content">
                  {loading ? (
                    <div>
                      <h4>Uploading....</h4>
                      <p>Please wait....</p>
                    </div>
                  ) : (
                    <>
                      <h4>Choose File</h4>
                      {/* <p>Preferred files are MS Word & PDF</p> */}
                      {/* <p>Upload or drag & drop files</p> */}
                      {/* <p>MS Word, PowerPoint, & Excel; PDF; RTF; ODT; Pages; Google Docs, Slides, & Sheets; and Plain Text are preferred.</p> */}
                      <p>
                        <b>MS Word & PDF are Preferred. </b> For RTF; ODT; Pages; MS PowerPoint & Excel; Google Docs, Slides, & Sheets; and Plain Text, manually
                        enter your file word count.
                      </p>
                    </>
                  )}
                </div>
              </div>
              {/* )} */}
              {showError && (serviceFileNames == undefined || serviceFileNames == "") && (
                <div>
                  <p style={{ color: "red", fontFamily: "Poppins", fontWeight: "400" }}>File is required</p>
                </div>
              )}
              {/* {showError && (mergeServiceFileNames==undefined || mergeServiceFileNames == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>File is required</p></div>} */}
            </li>
            <li className="col-lg-6">
              <ul className="row radio-list">
                <li className="col-md-6">
                  {/* <span className="blue-text-price">Total Word Count : 585</span> */}
                  {countDetails && countDetails ? (
                    // <span className="blue-text-price">Total Word Count : {countDetails?.count}</span>
                    // <span className="blue-text-price">Total Word Count : {updateCount}
                    <span className="blue-text-price">
                      {/* Total Word Count : {updateCount} */}
                      Total Word Count : <CountUp end={updateCount} />
                      {/* <label class="style-checkbox">
                                                    <input type="checkbox" onChange={()=>handleChecked()} checked={isChecked}/>
                                                    <span class="checkmark"></span>
                                                </label> */}
                    </span>
                  ) : (
                    <span className="blue-text-price">
                      Total Word Count : 0
                      {/* <label class="style-checkbox">
                                                <input type="checkbox"/>
                                                <span class="checkmark"></span>
                                            </label> */}
                    </span>
                  )}
                </li>

                <li className="col-md-6">
                  {/* <span className="yellow-text-price">Total Price: $26.32</span> */}
                  {/* (Math.round(0.9)); */}
                  {countDetails?.regularprice != undefined && showLevel === "Regular Service" ? (
                    <>
                      {/* <span className="yellow-text-price">Total Price: ${Math.round(Number(countDetails?.regularprice))}</span> */}
                      {/* <span className="yellow-text-price">Total Price: ${(Number(countDetails?.regularprice)).toFixed(2)}</span> */}
                      <span className="yellow-text-price">
                        Total Price: ${(Number(updateRegPrice) + Object.values(demoObj).reduce((acc, currentValue) => acc + currentValue, 0)).toFixed(2)}
                      </span>
                      {/* <span className="yellow-text-price">Total Price: ${(Number(updateRegPrice)).toFixed(2)}</span> */}
                      {/* <span className="yellow-text-price">Total Price: ${(Number(Object.values(demoObj).reduce((acc, currentValue) => acc + currentValue, 0))).toFixed(2)}</span> */}
                    </>
                  ) : (
                    <>
                      {countDetails?.expeditedprice != undefined && showLevel === "Expedited Service" ? (
                        // <span className="yellow-text-price">Total Price: ${Math.round(Number(countDetails?.expeditedprice))}</span>
                        // <span className="yellow-text-price">Total Price: ${(Number(countDetails?.expeditedprice)).toFixed(2)}</span>
                        // <span className="yellow-text-price">Total Price: ${(Number(countDetails?.expeditedprice)).toFixed(2)}</span>
                        <span className="yellow-text-price">
                          Total Price: $
                          {(Number(updateExpPrice) + Object.values(demoObjExpPrice).reduce((acc, currentValue) => acc + currentValue, 0)).toFixed(2)}
                        </span>
                      ) : (
                        <span className="yellow-text-price">Total Price: $0</span>
                      )}
                    </>
                    // <span className="yellow-text-price">Total Price: $0</span>
                  )}

                  {/* {countDetails && countDetails ? (
                                        <span className="yellow-text-price">Total Price: ${countDetails?.expeditedprice}</span>
                                    ):(
                                        <span className="yellow-text-price">Total Price: $0</span>
                                    )} */}
                </li>
                {countDetails && countDetails != null ? (
                  <li className="col-md-6">
                    {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service" defaultChecked value="24" onChange={(e)=>{setService(e.target.value);setService('24')}}/> */}
                    {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service" defaultChecked value={countDetails?.regular} onChange={(e)=>{setService(e.target.value);setShowLevel('Regular Service')}}/> */}
                    {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service" defaultChecked value={countDetails?.regular} onChange={(e)=>{setService(e.target.value);setShowLevel('Regular Service')}}/> */}
                    {/* <Form.Check type="radio" label={` Regular Service (${updateRegService} hrs)`} name="service" defaultChecked value={updateRegService} onChange={()=>{setUpdateRegService(updateRegService);setShowLevel('Regular Service')}}/> */}
                    <Form.Check
                      type="radio"
                      label={` Regular Service (${
                        updateRegService + Object.values(demoObjRegService).reduce((acc, currentValue) => acc + currentValue, 0)
                      } hrs)`}
                      name="service"
                      defaultChecked
                      value={updateRegService + Object.values(demoObjRegService).reduce((acc, currentValue) => acc + currentValue, 0)}
                      onChange={() => {
                        setUpdateRegService(updateRegService);
                        setShowLevel("Regular Service");
                      }}
                    />
                  </li>
                ) : (
                  <li className="col-md-6">
                    {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service"  value="24" disabled/> */}
                    <Form.Check type="radio" label={` Regular Service (${updateRegService} hrs)`} name="service" value="24" disabled />
                  </li>
                )}

                {countDetails && countDetails != null ? (
                  <li className="col-md-6">
                    {/* <Form.Check type="radio" label={` Expedited Service (${countDetails?.expedited?countDetails?.expedited:'0'}hrs)`} name="service" value="12"  onChange={(e)=>{setService(e.target.value);setService('12')}}/> */}
                    {/* <Form.Check type="radio" label={` Expedited Service (${countDetails?.expedited?countDetails?.expedited:'0'}hrs)`} name="service" value={countDetails?.expedited}  onChange={(e)=>{setService(e.target.value);setShowLevel('Expedited Service')}}/> */}
                    <Form.Check
                      type="radio"
                      label={` Expedited Service (${
                        updateExpService + Object.values(demoObjExpService).reduce((acc, currentValue) => acc + currentValue, 0)
                      } hrs)`}
                      name="service"
                      value={updateExpService + Object.values(demoObjExpService).reduce((acc, currentValue) => acc + currentValue, 0)}
                      onChange={() => {
                        setUpdateExpService(updateExpService);
                        setShowLevel("Expedited Service");
                      }}
                    />
                  </li>
                ) : (
                  <li className="col-md-6">
                    {/* <Form.Check type="radio" label={` Expedited Service (${countDetails?.expedited?countDetails?.expedited:'0'}hrs)`} name="service" value="12" disabled/> */}
                    <Form.Check type="radio" label={` Expedited Service (${updateExpService} hrs)`} name="service" value="12" disabled />
                  </li>
                )}
              </ul>
            </li>

            <li className="col-lg-12">
              <div className="row">
                <div className="col-md-12 upload-content">
                  <ul className="new-service-upload-list">
                    <li>
                      <div className="row">
                        <div className="col-md-5">
                          <label>&nbsp;</label>
                        </div>
                        {showingWCText && (
                          <div className="col-md-2">
                            <label className="small-label mobile-hide-label">Word Count</label>
                          </div>
                        )}
                      </div>
                    </li>
                    <>
                      {/* {mergeServiceFullFileNames && mergeServiceFullFileNames.length>0 && mergeServiceFileNames.map((item,index) =>( */}
                      {serviceFileObj &&
                        serviceFileObj.length > 0 &&
                        serviceFileObj.map((item, index) => (
                          <>
                            <li>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="upload-border-box">
                                    <span className="icon-span">
                                      {
                                        // item?.endsWith(".docx")?<img src={docsImg} alt="img" />:
                                        // item?.endsWith(".DOCX")?<img src={docsImg} alt="img" />:
                                        // item?.endsWith(".doc")?<img src={docsImg} alt="img" />:
                                        // item?.endsWith(".DOC")?<img src={docsImg} alt="img" />:
                                        // item?.endsWith(".pdf")?<img src={pdfImg} alt="img" />:
                                        // item?.endsWith(".PDF")?<img src={pdfImg} alt="img" />:
                                        // NotificationManager.error('File type is not matched!', '',2000)

                                        item.servFile?.endsWith(".doc") ||
                                        item.servFile?.endsWith(".DOC") ||
                                        item.servFile?.endsWith(".docx") ||
                                        item.servFile?.endsWith(".DOCX") ? (
                                          <img src={docsImg} alt="img" />
                                        ) : item.servFile?.endsWith(".pdf") || item.servFile?.endsWith(".PDF") ? (
                                          <img src={pdfImg} alt="img" />
                                        ) : item.servFile?.endsWith(".rtf") ? (
                                          <img src={rtfImg} alt="img" />
                                        ) : item.servFile?.endsWith(".odt") || item.servFile?.endsWith(".fodt") ? (
                                          <img src={odtImg} alt="img" />
                                        ) : item.servFile?.endsWith(".pages") ? (
                                          <img src={pagesImg} alt="img" />
                                        ) : item.servFile?.endsWith(".ppt") || item.servFile?.endsWith(".pptx") ? (
                                          <img src={pptImg} alt="img" />
                                        ) : item.servFile?.endsWith(".xls") || item.servFile?.endsWith(".xlsx") ? (
                                          <img src={xlsxImg} alt="img" />
                                        ) : item.servFile?.endsWith(".gdoc") ? (
                                          <img src={gdocImg} alt="img" />
                                        ) : item.servFile?.endsWith(".pptm") || item.servFile?.endsWith(".pps") ? (
                                          <img src={gslideImg} alt="img" />
                                        ) : item.servFile?.endsWith(".gsheet") ? (
                                          <img src={gslideImg} alt="img" />
                                        ) : item.servFile?.endsWith(".txt") ? (
                                          <img src={plainTextImg} alt="img" />
                                        ) : item.servFile?.endsWith(".text") ? (
                                          <img src={LatexImg} alt="img" />
                                        ) : (
                                          <img src={pdfImg} alt="img" />
                                        )
                                      }
                                    </span>
                                    <span className="upload-title-span">{item.servFile.slice(5)}</span>
                                    <span className="delete-upload-span" onClick={() => deleteFile(index, item.servFile)}>
                                      <i class="bi bi-trash"></i>
                                    </span>
                                    <span className="downoad-upload-span" onClick={() => downloadFile(item.servFile)}>
                                      <i class="bi bi-download"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <label className="mobile-show-label">Word Count</label>
                                  {item?.servFile?.endsWith(".doc") || item?.servFile?.endsWith(".docx") || item?.servFile?.endsWith(".pdf") ? (
                                    // <input type="text" className="form-control"  value={showWC[index]}/>
                                    <input type="number" className="form-control" value={item.wc} readOnly />
                                  ) : (
                                    // <input type="text" key={index} className="form-control" onChange={(e) => handleInputChange(index, e.target.value)} value={manualWC}/>
                                    <input
                                      type="number"
                                      key={index}
                                      className="form-control"
                                      onChange={(e) => {
                                        handleInputChange(index, e.target.value);
                                        handleChecked(e, index);
                                      }}
                                      value={item.wc}
                                    />
                                  )}
                                </div>
                              </div>
                            </li>
                          </>
                        ))}
                    </>
                  </ul>
                </div>
              </div>
              {/* {showError && selectedFileFormat!=undefined && (mergeServiceFileNames==undefined || mergeServiceFileNames == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>File is required</p></div>} */}
            </li>

            {/* li hide end */}

            <li className="col-md-12 img-sec">
              <label>Client Instruction</label>
              {/* <img src={ckEditorImg} alt="" /> */}
              {/* <Editor
                                // editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                wrapperStyle={{ width: 1120, border: "1px solid black" }}
                                // onEditorStateChange={onEditorStateChange()}
                                // placeholder="Tell a story..."
                                onChange={onEditorStateChange}
                                toolbar={{
                                    options:['blockType','inline','colorPicker'],
                                    textAlign: { inDropdown: true },
                                    inline :{options:['bold', 'italic', 'underline', 'strikethrough','superscript','subscript']}
                                }}
                            /> */}
              <CKEditor
                editor={ClassicEditor}
                config={{
                  // toolbar: [ 'bold', 'italic', 'heading' ,'|', 'undo', 'redo']
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "blockQuote",
                    "numberedList",
                    "bulletedList",
                    "outdent",
                    "indent",
                    "|",
                    "undo",
                    "redo",
                    "highlight",
                    "|",
                  ],

                  // plugins : [Highlight],

                  // toolbar: {
                  //     items: [
                  //         'heading', '|',
                  //         'alignment', '|',
                  //         'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                  //         'link', '|',
                  //         'bulletedList', 'numberedList', 'todoList',
                  //         '-', // break point
                  //         'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                  //         'code', 'codeBlock', '|',
                  //         'insertTable', '|',
                  //         'outdent', 'indent', '|',
                  //         'uploadImage', 'blockQuote', '|',
                  //         'undo', 'redo'
                  //     ],
                  //     shouldNotGroupWhenFull: false
                  // }
                  // toolbar: {
                  //     items: [
                  //         'undo', 'redo',
                  //         '|', 'heading',
                  //         '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                  //         '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                  //         '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
                  //         '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                  //     ],
                  //     shouldNotGroupWhenFull: false
                  // }
                }}
                // data="<p>Hello from CKEditor 5!</p>"
                // onReady={ editor => {
                //     console.log( 'Editor is ready to use!', editor );
                // }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log(event);
                  console.log(editor);
                  // setEditorData(data);
                  editorInstructionData(data);
                }}
              />
              {/* {showError && (editorData==undefined || editorData=='') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Client Instruction is required</p></div>} */}
            </li>

            <li className="col-lg-6">
              <div className="row align-items-center">
                <div className="col-md-3">
                  {loading1 ? (
                    <>
                      <i class="fa fa-refresh fa-spin" style={{ fontSize: "48px" }}></i>
                      <br />
                      {/* <span>File Uploading....</span> */}
                    </>
                  ) : (
                    <div className="styles-browser-sec">
                      {/* <input type="file" className="browseInput" name = "file"  onChange={(e) => {serviceFileHandle(e)}}/> */}
                      {/* <input type="file" id="file" multiple name = "file" onChange={e => {fileHandle(e); setFieldValue('file',e.currentTarget.files[0]); }}/> */}
                      <input
                        type="file"
                        className="browseInput"
                        id="file"
                        multiple
                        name="file"
                        onChange={(e) => {
                          instructionFileHandle(e);
                        }}
                      />
                    </div>
                  )}
                </div>

                {loading1 ? (
                  <div className="col-md-9 upload-content">
                    <h4>Uploading....</h4>
                    <p>Please wait....</p>
                    {/* {progress && <ProgressBar now={progress} label={`${progress}%`} /> }{progress} */}
                  </div>
                ) : (
                  <div className="col-md-9 upload-content">
                    <h4>Choose Instruction Files</h4>
                    {/* <p>Preferred files are MS Word & PDF </p> */}
                    {/* <p>Upload or drag & drop files</p> */}
                    {/* <p><b>MS Word & PDF are Preferred. </b> For RTF; ODT; Pages; MS PowerPoint & Excel;
Google Docs, Slides, & Sheets; and Plain Text, manually enter your file word count.</p> */}
                    <p>MS Word, PowerPoint, & Excel; PDF; RTF; ODT; Pages; Google Docs, Slides, & Sheets; and Plain Text are preferred.</p>
                  </div>
                )}
                <div className="col-md-12 upload-content" style={{ marginTop: "30px" }}>
                  <ul className="service-upload-list">
                    <>
                      {instructionFileNames &&
                        instructionFileNames.length > 0 &&
                        instructionFileNames.map((item, index) => {
                          const fileExtensions = [
                            ".docx",
                            ".DOCX",
                            ".doc",
                            ".DOC",
                            ".pdf",
                            ".PDF",
                            ".rtf",
                            ".odt",
                            ".fodt",
                            ".pages",
                            ".ppt",
                            ".pptx",
                            ".xls",
                            ".xlsx",
                            ".gdoc",
                            ".ppt",
                            ".pptx",
                            ".pptm",
                            ".pps",
                            ".gsheet",
                            ".txt",
                            ".text",
                          ];

                          const extension = fileExtensions.find((ext) => item.endsWith(ext));

                          return (
                            <li key={index}>
                              <span className="icon-span">
                                {extension ? (
                                  extension.endsWith(".doc") || extension.endsWith(".DOC") || extension.endsWith(".docx") || extension.endsWith(".DOCX") ? (
                                    <img src={docsImg} alt="img" />
                                  ) : extension.endsWith(".pdf") || extension.endsWith(".PDF") ? (
                                    <img src={pdfImg} alt="img" />
                                  ) : extension.endsWith(".rtf") ? (
                                    <img src={rtfImg} alt="img" />
                                  ) : extension.endsWith(".odt") || extension.endsWith(".fodt") ? (
                                    <img src={odtImg} alt="img" />
                                  ) : extension.endsWith(".pages") ? (
                                    <img src={pagesImg} alt="img" />
                                  ) : extension.endsWith(".ppt") || extension.endsWith(".pptx") ? (
                                    <img src={pptImg} alt="img" />
                                  ) : extension.endsWith(".xls") || extension.endsWith(".xlsx") ? (
                                    <img src={xlsxImg} alt="img" />
                                  ) : extension.endsWith(".gdoc") ? (
                                    <img src={gdocImg} alt="img" />
                                  ) : extension.endsWith(".pptm") || extension.endsWith(".pps") ? (
                                    <img src={gslideImg} alt="img" />
                                  ) : extension.endsWith(".gsheet") ? (
                                    <img src={gslideImg} alt="img" />
                                  ) : extension.endsWith(".txt") ? (
                                    <img src={plainTextImg} alt="img" />
                                  ) : extension.endsWith(".text") ? (
                                    <img src={LatexImg} alt="img" />
                                  ) : (
                                    <img src={pdfImg} alt="img" />
                                  )
                                ) : null}
                              </span>
                              <span className="upload-title-span">{item.slice(5)}</span>
                              <span className="delete-upload-span" onClick={() => deleteInsFile(index, item)}>
                                <i className="bi bi-trash"></i>
                              </span>
                              <span className="downoad-upload-span" onClick={() => downloadFile(item)}>
                                <i className="bi bi-download"></i>
                              </span>
                            </li>
                          );
                        })}
                    </>
                  </ul>
                </div>
              </div>
              {/* {showError && (instructionFileNames==undefined || instructionFileNames == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>File is required</p></div>} */}
            </li>

            <li className="col-lg-6">
              <div className="row">
                <div className="col-md-2 servce-icon">
                  <img src={choseEditorIcon} alt="" className="editorIconImg" />
                </div>
                <div className="col-md-10 upload-content">
                  <h4>Choose Editor (Optional)</h4>
                  <p>Search editor by editor code OR choose from dropdown</p>
                  <br />
                  <ul className="row radio-list">
                    <li className="col-md-5">
                      <div className="form-check">
                        <input
                          name="choose-editor"
                          type="radio"
                          className="form-check-input"
                          onClick={() => {
                            setShowOption(1);
                          }}
                          value="bycode"
                          defaultChecked
                        />
                        <label title="" class="form-check-label">
                          {" "}
                          Search by code
                        </label>
                      </div>
                    </li>
                    <li className="col-md-7">
                      <div className="form-check">
                        <input
                          name="choose-editor"
                          type="radio"
                          className="form-check-input"
                          onClick={() => {
                            setShowOption(2);
                          }}
                          value="bydropdown"
                        />
                        <label title="" class="form-check-label">
                          {" "}
                          Choose from dropdown
                        </label>
                      </div>
                    </li>
                  </ul>
                  <ul className="search-list">
                    {showOption && showOption == 1 ? (
                      <li>
                        {/* {showError && selectedEditorId === undefined && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Enter Editor Id </p></div>} */}
                        {/* <input type="text" name="test"  placeholder="Search editor’s code or name here" /> */}
                        <div className="auto-search-input">
                          {/* <ReactSearchAutocomplete
                                                        items={edtId}
                                                        placeholder='Search Editor Code Here..'
                                                        onSearch={handleOnSearch}
                                                        onHover={handleOnHover}
                                                        onSelect={handleOnSelect}
                                                        onFocus={handleOnFocus}
                                                        autoFocus
                                                    /> */}
                          {/* {showError && selectedEditorId === undefined && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Editor is required</p></div>} */}
                          <span
                            className="closed-auto-search"
                            onClick={() => {
                              setSearchingData();
                              onClear();
                              setNoRecordFound(true);
                            }}
                          >
                            {searchingData != undefined ? (
                              <img src={closedAutoSearch} alt="" />
                            ) : (
                              // <img src={closedAutoSearch} alt="" />
                              <></>
                            )}
                          </span>
                          <input
                            ref={ref}
                            type="text"
                            name="test"
                            onChange={(e) => {
                              handleDataSearch(e);
                            }}
                            placeholder="Search Editor’s code here"
                            value={searchingData}
                          />
                          <>
                            <div className="editor-search-dropdown">
                              <ul>
                                {searchingData?.length >= 3 &&
                                  !noRecordFound &&
                                  showFilterData &&
                                  showFilterData?.map((item, index) => {
                                    return (
                                      <li className={!showSearch ? "list-hide" : ""}>
                                        <div
                                          onClick={() => {
                                            setSearchingData(item?.designation);
                                            editorDetailsById(item?.id);
                                            setShowSearch(false);
                                            setIsSearchValueClicked(true);
                                          }}
                                        >
                                          <>{!showSearch ? <></> : <>{item?.designation}</>}</>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </>
                        </div>
                      </li>
                    ) : (
                      <>
                        {showOption && showOption == 2 ? (
                          <li className="col-md-6">
                            {/* {showError && selectedEditorId === undefined && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Select Editor from dropdown</p></div>} */}
                            <div className="auto-search-input">
                              <Form.Group>
                                <Form.Select
                                  name="subservice"
                                  onChange={(e) => {
                                    editorDetailsById(e.target.value);
                                    setSelectEditor(e.target.value);
                                  }}
                                  value={selectEditor}
                                >
                                  <option value="null" selected>
                                    Select Editor
                                  </option>
                                  {showExistingEditor &&
                                    showExistingEditor.length > 0 &&
                                    showExistingEditor.map((item, index) => {
                                      return <option value={item?.editor_id}>{item?.designation}</option>;
                                    })}
                                </Form.Select>
                                {/* {showExistingEditor && showExistingEditor.length} */}

                                {/* {showError && (selectEditor==undefined || selectEditor == '' || selectEditor === 'null') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Existing Editor is required</p></div>} */}
                              </Form.Group>
                            </div>
                          </li>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </li>

            <li className="col-md-12 btn-list">
              {/* <input type="submit" name="" value="Submit" className="btn btn-primary" />  */}
              {/* <NavLink className="btn btn-primary" to="/payment">Submit</NavLink>  */}
              {/* <Link 
                                to = {`/edit-editor/${list?.id}`}
                            > 
                            </Link> */}
              {userData != null || userData != undefined ? (
                <>
                  {/* <NavLink className="btn btn-primary" 
                                        to="/payment"  
                                        state={{from:'service', path: location.pathname}}>
                                        Submit
                                    </NavLink> */}
                  {/* {userData?.role == '2' && userData?.ph_no!=null && userData?.state!=null && userData?.address!=null && userData?.zipcode!=null? (
                                        // <Button className="btn btn-primary" type="submit"  onClick={()=>LoggedSubmit()}>Submit</Button>
                                        <Button className="btn btn-primary" type="submit" onClick={()=>setClientInfoModal(true)}>Submit</Button>
                                    ): */}
                  {/* {userData?.role == '2' && (userData?.ph_no=='' || userData?.ph_no==null) && userData?.state==null && userData?.address==null && userData?.zipcode==null ?( */}
                  {(userData?.ph_no == "" || userData?.ph_no == null) && userData?.state == null && userData?.address == null && userData?.zipcode == null ? (
                    <Button className="btn btn-primary" type="submit" onClick={() => handleMessage(1)}>
                      Submit
                    </Button>
                  ) : (
                    // <Button className="btn btn-primary" type="submit" onClick={()=>handleMessage(2)}>Submit</Button>
                    <>
                      {isAddedClient && selectedOrderType === "Redo Order" ? (
                        <Button className="btn btn-primary" type="submit" onClick={() => handleRedoOrderSubmission()}>
                          Submit
                        </Button>
                      ) : isAddedClient && selectedOrderType === "New Order" ? (
                        <Button className="btn btn-primary" type="submit" onClick={() => handleNewOrderSubmission()}>
                          Submit
                        </Button>
                      ) : (
                        // <Button className="btn btn-primary" type="submit" onClick={()=>LoggedSubmit()}>Submit</Button>
                        <>
                          {/* {clientInfoModal ? (
                                                        <Button className="btn btn-primary" type="submit" onClick={()=>setClientInfoModal(true)}>Submit2{selectedOrderType}</Button>
                                                    ):(
                                                        <Button className="btn btn-primary" type="submit" onClick={()=>setClientInfoModal(true)}>Submit3{selectedOrderType}</Button>
                                                    )} */}

                          {userData?.role == "2" && selectedOrderType != undefined ? (
                            <li className="col-md-12">
                              <Button className="CmnBtn" type="submit" onClick={() => handleOrderInfoType("client_self")}>
                                Submit
                              </Button>
                            </li>
                          ) : // userData?.role == '2' && selectedOrderType == 'Redo Order' ? (
                          //     <li  className="col-md-12">
                          //         <Button className="CmnBtn" type="submit"  onClick={()=>handleRedoOrderSubmission('client_self')}>Submit41</Button>
                          //     </li>
                          // ):
                          userData?.role != "2" && selectedOrderType != undefined ? (
                            <li className="col-md-12">
                              <Button className="CmnBtn" type="submit" onClick={() => handleOrderInfoType("others")}>
                                Submit
                              </Button>
                            </li>
                          ) : (
                            <Button className="btn btn-primary" type="submit" onClick={() => setClientInfoModal(true)}>
                              Submit{selectedOrderType}
                            </Button>
                          )}
                        </>
                      )}
                    </>

                    // <a data-toggle="tooltip" data-placement="top" title='Only Client can place the order'>Submit</a>

                    // <Button className="btn btn-primary" type="submit">
                    //     <a  data-toggle="tooltip" data-placement="top" title="Only Client can place the order"></a>
                    // </Button>
                  )}
                </>
              ) : (
                // <NavLink className="btn btn-primary"
                //     to="/login"
                //     state={{from:'service', path: location.pathname}}
                // >
                //      Submit
                // </NavLink>
                <Button className="btn btn-primary" type="submit" onClick={() => notLoginSubmit()}>
                  Submit
                </Button>
              )}
              {/* <Button className="btn btn-primary" type="submit"  onClick={()=>submitAllData()}>Submit</Button> */}
              {/* <NavLink className="btn btn-primary" 
                                    to="/payment"  state={{from:'service', path: location.pathname}}>
                                Submit
                                </NavLink>
                              */}
            </li>
          </ul>
        </div>
      </div>
      <div className="home-yellow-section align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
              <h3>Still Not Sure What Service Is Right For You?</h3>
              <p>
                <NavLink to="/">Elite Reviser</NavLink> can handle that.
                <NavLink to="/contact"> Contact Us.</NavLink>
              </p>
            </div>
            <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
              <NavLink to="/services" className="btn btn-primary">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Send us a message</h2>
          {/* <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p> */}
          <p>Word Count is greater than 1,00,000 Please Contact us</p>
          <a class="CmnBtn yesBtn" onClick={() => redirectComponent()}>
            YES
          </a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>

      <Modal show={userDetails} onHide={userDetailsClose} className="CmnModal CancelOrder editAccModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <main className="main">
            <div className="pagetitle">
              <span className="TitleWrap">
                <span className="NameLogo">
                  {showClientData?.first_name?.charAt(0)}
                  {showClientData?.last_name.charAt(0)}
                </span>
                <h2>Edit Account</h2>
              </span>
            </div>

            <section className="section AccDtlsPnl">
              <Formik
                enableReinitialize
                initialValues={{
                  id: showClientData && showClientData.id,
                  first_name: showClientData && showClientData.first_name,
                  last_name: showClientData && showClientData.last_name,
                  ph_no: showClientData && showClientData.ph_no,
                  email_address: showClientData && showClientData.email_address,
                  address: showClientData && showClientData.address,
                  zipcode: showClientData && showClientData.zipcode,
                  state: showClientData && showClientData.state,
                }}
                onSubmit={(values, { resetForm }) => {
                  updateEditorData(values, { resetForm });
                }}
                validationSchema={schema}
              >
                {({ handleChange, handleSubmit, errors, values, setFieldValue }) => (
                  <div className="AccBox">
                    <div className="pagetitle">
                      <h3>Personal Details</h3>
                      {loading && loading ? (
                        <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{ cursor: "not-allowed" }}>
                          <i className="bi bi-check-circle" />
                          Update
                        </Button>
                      ) : (
                        <Button className="btn btn-primary CmnBtn" type="submit" onClick={() => handleSubmit()}>
                          <i className="bi bi-check-circle" />
                          Update
                        </Button>
                      )}
                    </div>
                    <form action method="get">
                      <ul className="AccDtls EditAccDtls row">
                        <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id} />
                        <li className="col-md-4">
                          <input
                            type="text"
                            className="InptField"
                            id="firstInput"
                            name="first_name"
                            placeholder="First Name"
                            onChange={handleChange}
                            value={values.first_name}
                          />
                          {errors.first_name ? <div className="error">{errors.first_name}</div> : null}
                        </li>
                        <li className="col-md-4">
                          <input
                            type="text"
                            className="InptField"
                            id="lastInput"
                            placeholder="Last Name"
                            name="last_name"
                            onChange={handleChange}
                            value={values.last_name}
                          />
                          {errors.last_name ? <div className="error">{errors.last_name}</div> : null}
                        </li>
                        <li className="col-md-4">
                          <input
                            type="number"
                            className="InptField"
                            id="phoneInput"
                            placeholder="Phone Number"
                            name="ph_no"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            onChange={handleChange}
                            value={values.ph_no}
                          />
                          {errors.ph_no ? <div className="error">{errors.ph_no}</div> : null}
                        </li>
                        <li className="col-md-4">
                          <input
                            type="email"
                            className="InptField"
                            id="emailInput"
                            placeholder="Email Address"
                            name="email_address"
                            onChange={handleChange}
                            value={values.email_address}
                          />
                          {errors.email_address ? <div className="error">{errors.email_address}</div> : null}
                        </li>
                        <li className="col-md-4">
                          <input
                            type="text"
                            className="InptField"
                            id="nameInput"
                            placeholder="State"
                            name="state"
                            onChange={handleChange}
                            value={values.state}
                          />
                          {errors.state ? <div className="error">{errors.state}</div> : null}
                        </li>
                        <li className="col-md-4">
                          <input
                            type="text"
                            className="InptField"
                            id="zipInput"
                            placeholder="Zip Code"
                            name="zipcode"
                            onChange={handleChange}
                            value={values.zipcode}
                          />
                          {errors.zipcode ? <div className="error">{errors.zipcode}</div> : null}
                        </li>
                        <li className="col-md-8">
                          <input
                            type="text"
                            className="InptField"
                            id="addressInput"
                            placeholder="Address"
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                          />
                          {errors.address ? <div className="error">{errors.address}</div> : null}
                        </li>
                      </ul>
                    </form>
                  </div>
                )}
              </Formik>
            </section>
          </main>

          {/* <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a> */}
          {/* <a class="cancelBtn noBtn" onClick={()=>orderCancelClose()}>NO</a> */}
        </Modal.Body>
      </Modal>

      {/* New job Redo Job Modal  */}
      <Modal size="lg" show={clientInfoModal} onHide={() => allModalFunction()} className="CmnModal CustReview serviceNewModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Choose Order Type</h2>
          <ul className="row radio-list">
            <li className="col-md-6 col-6">
              <Form.Check type="radio" label={`New Order`} name="job_type" value="New Order" onChange={(e) => handleSelectOrderType(e)} />
            </li>
            <li className="col-md-6 col-6">
              <Form.Check type="radio" label={`Redo Order`} name="job_type" value="Redo Order" onChange={(e) => handleSelectOrderType(e)} />
            </li>
            {isError && (selectedOrderType == undefined || selectedOrderType == null || selectedOrderType == "") ? (
              <div className="error" style={{ textAlign: "left" }}>
                Select Order Type
              </div>
            ) : null}
          </ul>
          <ul className="serviceSearchList">
            {userData?.role != "2" && (
              <li className="col-md-12">
                <div className="auto-search-input">
                  {showCloseButton && (
                    <span className="closed-auto-search" onClick={handleClearSearch}>
                      <img src={closedAutoSearch} alt="" />
                    </span>
                  )}

                  <input type="text" value={inputValue} onChange={handleInputChangeSearch} placeholder="Search by client email" />

                  <div className="editor-search-dropdown">
                    {showSuggestions && showCloseButton && (
                      <ul>
                        {filteredSuggestions?.length > 0 ? (
                          filteredSuggestions.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                handleSuggestionClick(item);
                                setIsClickedClient(true);
                              }}
                            >
                              {item.email}
                            </li>
                          ))
                        ) : (
                          <li>No Record Found.</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                {isError && (inputValue == undefined || inputValue == null || inputValue == "") ? (
                  <div className="error" style={{ textAlign: "center" }}>
                    Select Client
                  </div>
                ) : null}
              </li>
            )}

            {/* {userData?.role == '2' && !modalDataAdded ? ( */}
            {/* <li  className="col-md-12"> */}
            {/* <Button className="CmnBtn" type="submit"  onClick={()=>handleOrderInfoType('client_self')}>Add Client1</Button> */}
            {/* <Button className="CmnBtn" type="submit"  onClick={()=>modalDataValidation()}>Add Client1</Button> */}
            {/* </li> */}
            {/* ):( */}
            {/* <li  className="col-md-12"> */}
            {/* <Button className="CmnBtn" type="submit"  onClick={()=>handleOrderInfoType('others')}>Add Client2</Button> */}
            {/* <Button className="CmnBtn" type="submit"  onClick={()=>modalDataValidation()}>Add Client2</Button> */}
            {/* </li> */}
            {/* )} */}

            <li className="col-md-12">
              <Button className="CmnBtn" type="submit" onClick={() => modalDataValidation()}>
                Add Client
              </Button>
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Servicesnewclient;
