import React, { Component, useState, useEffect, useMemo, useRef  } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { saveAs} from 'file-saver';
import CountdownTimer from "../../Timer/CountdownTimer";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DateTime } from 'luxon';

import { get_date_format, get_time_format, downloadFile} from "../../utility/Common";
import Testing from "../../Testing";
import CountUpTimerComponent from "../../Timer-new/CountUpTimerComponent";

const reviewIcon = require("../../assets/img/ReviewIcon.png");
const docFileImg = require("../../assets/img/DocFileImg.png");
const docImg = require("../../assets/img/docs-img.png");
const iconImg = require("../../assets/img/image-icon.png");
const pdfImg = require("../../assets/img/pdf.png");
const rtfImg = require("../../assets/img/rtf-img.png");
const odtImg = require("../../assets/img/odt-img.png");
const pagesImg = require("../../assets/img/pages-img.png");
const pptImg = require("../../assets/img/ppt-img.png");
const xlsxImg = require("../../assets/img/xlsx-img.png");
const gdocImg = require("../../assets/img/gdoc-img.png");
const gslideImg = require("../../assets/img/google-slides.png");
const gsheetImg = require("../../assets/img/gsheet-img.png");
const plainTextImg = require("../../assets/img/txt-img.png");
const LatexImg = require("../../assets/img/text-img.png");
const attachFileIcon = require("../../assets/img/attach-icon.png");
const linkIcon = require("../../assets/img/link-icon.png");
const feedbackStarImg = require("../../assets/img/feedbackStar.png");


const Adminjobboard = () => {
  const windowRef = useRef();
  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  const timeZone = DateTime.local().zoneName;

  const [show, setShow] = useState(false);
  const [showRem, setShowRem] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [loading,setLoading] = useState(false); 
  const [loading1,setLoading1] = useState(false); 
  const [removeButton,setRemoveButton] = useState(false);
  const [removeButton1,setRemoveButton1] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseRem = () => setShowRem(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showModal, setShowModal] = useState(false);
  const modalclose  = () => setShowModal(false)
  const [showEditorName,setShowEditorName] = useState(); // editor name
  const [showDropDown,setShowDropDown] = useState(false);
  const [showDropDown1,setShowDropDown1] = useState(false);
  const [isAssigned,setIsAssigned] = useState(false);
  const [showMsg,setShowMsg] = useState();
  const [endCounterTimer,setEndCounterTimer] = useState(false);
  const [instructionFile,setInstructionFile] = useState();
  const [ShowAllComments,setShowAllComments] = useState([]); // all comments.
  const [adminMsg,setAdminMsg] = useState();
  const openJobDetlsPnl = () => {
    setIsWindowOpen(true);
    $(".openJobDetlsPnl").addClass("Act");
  };
  const closeJobDetlsPnl = () => { 
    $(".openJobDetlsPnl").removeClass("Act");
  };
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }
  const [showEditorList,setShowEditorList] = useState([]); // for all editors
  const [showInternalList,setShowInternalList] = useState([]); // for all internal
  const [selectedEditor,setSelectedEditor] = useState(); // selected editor
  const [jobList,setJobList] = useState([]); // all job list
  const [jobInfoById,setJobInfoById] = useState(); // job deatils by id
  const [serviceFile,setServiceFile] = useState();
  const [jobId,setJobId] = useState();
  const [editorAssign,setEditorAssign] = useState(false); // editor assign or not checking
  const [editorEmail,setEditorEmail] = useState(); // for editor assign
  const [callAPI,setCallAPI] = useState(false);
  const [file,setFile] = useState(); // comment file upload
  const [fileName,setFileName] = useState(); // comment file upload
  const [apiCall,setAPiCall] = useState(false);
  const [editorPrice,setEditorPrice] = useState();
  const [showError,setShowError] = useState(false);
  const [showColor,setShowColor] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [timeReachedMsg,setTimeReachedMsg] = useState(0);

  const [showCancelReasonTitle,setShowCancelReasonTitle] = useState(); // cancel reason title
  const [showCancelReasonDescription,setShowCancelReasonDescription] = useState(); // cancel reason description
  const [showCancelReasonTitleError,setShowCancelReasonTitleError] = useState(false); // cancel reason title
  const [showCancelReasonDescriptionError,setShowCancelReasonDescriptionError] = useState(false); // cancel reason description
  const [cancelLoading,setcancelLoading] = useState(false); 
  const [orderDetailsById,setOrderDetailsById] = useState();
  const [displayMsg,setDisplayMsg] = useState(0);
  const [orderList,setOrderList] = useState([]);
  const [isWindowOpen, setIsWindowOpen] = useState(false);

  let data= JSON.parse(localStorage.getItem("user_data_elite"));

  console.log(data);
  useEffect(() => {
      getAllEditor();
      getAllJobs();
      getAllInternal();
      // console.log(editorDetails)
  }, []);
  
  useEffect(() => {
    if(apiCall)
    {
      let timer = setTimeout(() => {
        console.clear();
        setCallAPI(!callAPI);
        // jobListByClient();
        fetchAllComments(jobInfoById?.user_id,jobInfoById?.editor_id,jobInfoById?.id,jobInfoById?.internal_id,jobInfoById?.deputy_id);
      }, 15000);
      return () => clearTimeout(timer);
      // console.log(callAPI);
    }
  // }, [callAPI])
  })
  
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
      {
        setIsWindowOpen(false)
        closeJobDetlsPnl();
      }
      else
      {
        console.log('here')
        console.log(isWindowOpen)
        console.log(windowRef.current)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
  
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
    }
  }, [isWindowOpen])

  // get all editors
  const getAllEditor = async () =>{
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=1`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowEditorList(apiRes.data.data);
        }
      }
      else
      {
        console.log('Something went wrong!');
      }
    }
    catch (error) 
    {
      console.error(error);
    }
  };

  // get all internal-editors
  const getAllInternal = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowInternalList(apiRes.data.data);
        }
      }
      else
      {
        console.log('Something went wrong!');
      }
    }
    catch (error) 
    {
      console.error(error);
    }
  };

  // All jobs for a specific Client
  const jobListByClient = async (user_id) => {
    try
    {
      setDisplayMsg(0);
      // let apiRes = await axios.get(`${API_BASE_URL}job/jobdetailbyuser?user_id=${data?.id}`)
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetailbyuser?user_id=${user_id}`)
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setOrderList(apiRes.data.data);
          setOrderDetailsById(apiRes.data.data[0]);
          if(apiRes.data.data.length > 0)
          {
            setDisplayMsg(1);
          }
          else
          {
            setDisplayMsg(0);
          }
        }
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }

  //Order Cancellation
  const orderCancellation = async() =>{

      // if((showCancelReasonTitle=== '' && showCancelReasonDescription=== '') || (showCancelReasonTitle=== undefined && showCancelReasonDescription=== undefined) || (showCancelReasonTitle=== null && showCancelReasonDescription=== null))
      // {
      //   setShowCancelReasonTitleError(true);
      //   setShowCancelReasonDescriptionError(true);
      //   NotificationManager.error('All fields are Blank!!', '',2000);
      // }
      // else if(showCancelReasonTitle=== ''  || showCancelReasonTitle=== undefined  || showCancelReasonTitle=== null )
      // {
      //   setShowCancelReasonTitleError(true);
      //   NotificationManager.error('Blank field is not allowed!!', '',2000);
      // }
      // else if(showCancelReasonDescription=== ''  || showCancelReasonDescription=== undefined  || showCancelReasonDescription=== null )
      // {
      //   setShowCancelReasonDescriptionError(true);
      //   NotificationManager.error('Blank field is not allowed!!', '',2000);
      // }
      // else
      // {
      //   console.warn(orderDetailsById)
        
        let service_type= '';
        var job_title = '';
        let job_type = '';
    
        if(orderDetailsById?.service_type == '0')
        {
          service_type = 'Regular Service'
        }
        else if(orderDetailsById?.service_type == '1')
        {
          service_type = 'Expedited Service'
        } 
    
        if(orderDetailsById?.job_type==='type2')
        {
          console.warn(orderDetailsById)
          job_type='type2';
          var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + '|' + '1 Week'
        }
        else if(orderDetailsById?.job_type==='type1')
        {
          job_type='type1';
          var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + orderDetailsById?.from_count +' '+'-' + orderDetailsById?.to_count +' ' + '|' + ' ' + service_type + ' ' + '|' + orderDetailsById?.service_hour + ' ' + 'hrs'
        }
        try
        {
            // setLoading(true);
            let full_name = data?.first_name + ' ' + data?.last_name
            setcancelLoading(true);
            let apiRes = await(
                axios
                .post(
                  `${API_BASE_URL}job/jobcancel`,{
                    job_id: orderDetailsById?.id,
                    job_title: job_title,
                    job_type: orderDetailsById?.job_type,
                    user_id:data?.id,
                    user_name: full_name,
                    user_mail: data?.email_address,
                    // cancellation_title: showCancelReasonTitle && showCancelReasonTitle,
                    // cancellation_description: showCancelReasonDescription && showCancelReasonDescription
                    cancellation_title: '',
                    cancellation_description: '',
                    cancelled_by: 'elite_reviser'
                  },
                )
            )
            if(apiRes)
            {
                console.log(apiRes.data.res)
                if(apiRes.data.res == true) 
                {
                    NotificationManager.success('Order Canceled Successfully','',2000);
                    orderCancelFormClose();
                    orderCancelClose();
                    $(".DtlsSectionWrapper").removeClass("Act");
                    jobListByClient();
                    setcancelLoading(false);
                }
                else
                {
                  NotificationManager.error('Order Not Canceled', '',2000);
                } 
            }
            else
            {
              // setLoading(false);
              setcancelLoading(false);
              // NotificationManager.error('error3', '',2000);
            }
        } //close try
        catch (error) 
        {
          setcancelLoading(false);
          // NotificationManager.error(error.messaage, '',2000);
        }
      // }
  }
  //Show all jobs
  const getAllJobs = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}job/joblist?UserTimeZone=${timeZone}`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setJobList(apiRes.data.data);
        }
      }
      else
      {
        console.log('Something went wrong!');
      }
    }
    catch (error)
    {
      console.error(error);
    }
  };

  //Date format
  // const get_date_format = (dt) =>{
  //   //  console.log(dt);
  //     var options = { year: 'numeric', month: 'short', day: 'numeric' };
  //     var created_date = dt;
  //     var date = created_date?.slice(0,10);
  //     var created_date  = new Date(date);
  //     var date_month_format = created_date.toLocaleDateString("en-US", options); // Saturday, September 17, 2016
  //     return date_month_format;
  // }

  //Short User Name
  const shortUserName = (user_name) =>{
      console.log(user_name);
      var sl_name = user_name?.split(' ');
      console.log(sl_name)
      var short_first = sl_name[0]?.charAt(0);
      var short_last = sl_name[1]?.charAt(0);
      var short_final = short_first + short_last;
      return short_final;
  }
  
  //Time format
  // const get_time_format = (tm) => {
  //     if(tm!= '')
  //     {
  //         var time_values = tm.slice(11,16);
  //         let start_time_split = time_values?.split(":");
  //         console.log(start_time_split)
  //         let start_splt_hour = start_time_split[0];
  //         let start_splt_minutes = start_time_split[1];
  //         let start_check_dt = start_splt_hour >= 12 ? 'PM' : 'AM';
  //         start_splt_hour = start_splt_hour % 12;
  //         start_splt_hour = start_splt_hour ? start_splt_hour : 12; //hour 0 => 12
  //         start_splt_minutes = start_splt_minutes < 10 ? start_splt_minutes :  start_splt_minutes;
  //         let start_time_final = start_splt_hour + ':' + start_splt_minutes + ' ' + start_check_dt;
  //         return start_time_final;
  //     }
  // }
  
  //file download
  // const downloadFile = (item) => {
  //   saveAs(item);
  // }

  //short file name
  const shortFileName=(item) =>{
    console.log(item?.slice(56,70));
    let filename = item?.slice(56,70);
    return filename + '...';
  }
  
  //short comment-file name
  const shortCommentFileName = (item) =>
  {
    let splt_filename = item?.split("/");
    return splt_filename[1];
    // return item;
  }

  // assign Editor from Service
  const assignEditor = async(editor_id) =>
  {
    // alert(editor_id)
    console.log('Editor id: ' + editor_id);
    console.log('Job id: ' + jobInfoById?.id);
    try
    {
      setIsAssigned(true);
      setLoading(true);
      setShowDropDown(false);
      setShowDropDown1(false);
      setRemoveButton(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/inserteditor?id=${jobInfoById?.id}&editor_id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let apiRes1 = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
            if (apiRes1) 
            {
                console.log(apiRes1.data.data);
                if (apiRes1.data.res == true) 
                {
                  let fullName = '';
                  let email = '';
                  console.log(apiRes1.data.data[0]);
                  // setEditorEmail(apiRes1.data?.data[0]?.email_address);
                  fullName = apiRes1.data?.data[0]?.first_name + ' ' + apiRes1.data.data[0]?.last_name;
                  email = apiRes1.data?.data[0]?.email_address;
                  // console.log(fullName)
                  // alert(fullName)
                  console.log('Editor Id: ' + editor_id)
                  console.log('Editor Name: ' + fullName)
                  // getJobDetails(jobInfoById?.id)
                  // setShowEditorName(fullName)
                  // let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                  let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&internal_id=&internal_name=&internal_email=&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                  if (apiRes2) 
                   {
                        console.log(apiRes2.data.res);
                        if (apiRes2.data.res == true) 
                        {
                          getJobDetails(jobInfoById?.id);
                          setShowDropDown(false);
                          setShowDropDown1(false);
                          setShowMsg(1);
                          setRemoveButton(false);
                          setIsAssigned(false);
                          NotificationManager.success('Job assigned successfully.', "", 2000);
                        }
                        else
                        {
                          
                          // NotificationManager.error('error', "", 2000);
                        }
                   }
                  setLoading(false);
                  setEditorEmail('');
                  // setShowEditorList('');
                }
                else
                {
                  setLoading(false);
                  // NotificationManager.error('error', "", 2000);
                }
            }
            getAllJobs();
          }
      }
    }
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  // assign Internal Editor from Service
  const assignInternalEditor = async(internal_id) =>
  {
    // alert(editor_id)
    console.log('Internal Editor id: ' + internal_id);
    console.log('Job id: ' + jobInfoById?.id);
    try 
    {
      setIsAssigned(true);
      setLoading(false);
      setLoading1(true);
      setShowDropDown(false);
      setShowDropDown1(false);
      setRemoveButton1(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/inserteditor?id=${jobInfoById?.id}&internal_id=${internal_id}`)
      if (apiRes) 
      { 
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let apiRes1 = await axios.get(`${API_BASE_URL}account?id=${internal_id}`)
            if (apiRes1) 
            {
                console.log(apiRes1.data.data);
                if (apiRes1.data.res == true) 
                {
                  let fullName = '';
                  let email = '';
                  console.log(apiRes1.data.data[0]);
                  // setEditorEmail(apiRes1.data?.data[0]?.email_address);
                  fullName = apiRes1.data?.data[0]?.first_name + ' ' + apiRes1.data.data[0]?.last_name;
                  email = apiRes1.data?.data[0]?.email_address;
                  // console.log(fullName)
                  // alert(fullName)
                  console.log('Internal Editor Id: ' + internal_id)
                  console.log('Internal Name: ' + fullName)
                  // getJobDetails(jobInfoById?.id)
                  // setShowEditorName(fullName)
                  // let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                  // let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&internal_id=&internal_name=&internal_email=&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                  let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&editor_id=&editor_name=&editor_email=&internal_id=${internal_id}&internal_name=${fullName}&internal_email=${email}`)
                  if (apiRes2) 
                  {
                        console.log(apiRes2.data.res);
                        if (apiRes2.data.res == true) 
                        {
                          getJobDetails(jobInfoById?.id);
                          setShowDropDown(false);
                          setShowDropDown1(false);
                          setShowMsg(1);
                          setRemoveButton1(false);
                          setIsAssigned(false);
                          NotificationManager.success('Job assigned successfully.', "", 2000);
                        }
                        else
                        {
                          
                          // NotificationManager.error('error', "", 2000);
                        }
                  }
                  setLoading(false);
                  setLoading1(false);
                  setEditorEmail('');
                  // setShowEditorList('');
                }
                else
                {
                  setLoading1(false);
                  setLoading(false);
                  // NotificationManager.error('error', "", 2000);
                }
            }
            getAllJobs();
          }
      }
    }
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  // assign Editor from cv
   // assign Editor 
  const assignEditorFromCv = async(editor_id) =>
   {
    var jobInfoId = jobInfoById?.id;
     // alert(editor_id)
     console.log('Editor id: ' + editor_id);
     console.log('Job id: ' + jobInfoById?.id);
     try 
     {
       setLoading(true);
       setShowDropDown(true);
       let apiRes = await axios.get(`${API_BASE_URL}cvresume/inserteditor?id=${jobInfoById?.id}&editor_id=${editor_id}`)
      // let apiRes = await axios.get(`${API_BASE_URL}job/inserteditor?id=${jobInfoById?.id}&editor_id=${editor_id}`)
      
       if (apiRes) 
       {
           console.log(apiRes.data.res);
           if (apiRes.data.res == true) 
           {
             let apiRes1 = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
             if (apiRes1) 
             {
                 console.log(apiRes1.data.data);
                 console.log('Job id: ' + jobInfoById?.id);
                 if (apiRes1.data.res == true) 
                 {
                   let fullName = '';
                   let email = '';
                   console.log(apiRes1.data.data[0]);
                   // setEditorEmail(apiRes1.data?.data[0]?.email_address);
                   fullName = apiRes1.data?.data[0]?.first_name + ' ' + apiRes1.data.data[0]?.last_name;
                   email = apiRes1.data?.data[0]?.email_address;
                   // console.log(fullName)
                   // alert(fullName)
                   console.log('Editor Id: ' + editor_id)
                   console.log('Editor Name: ' + fullName)
                   console.log(jobInfoById?.id);

                   // getJobDetails(jobInfoById?.id)
                   // setShowEditorName(fullName)
                   let apiRes2 = await axios.get(`${API_BASE_URL}cvresume/inserteditorname?id=${jobInfoById?.id}&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                   if (apiRes2) 
                    {
                        console.log(apiRes2.data.res);
                        if (apiRes2.data.res == true) 
                        {
                          console.log('Final id: ' + jobInfoById?.id)
                          getJobDetailsResumeCv(jobInfoById?.id);
                          setShowDropDown(false);
                          setShowDropDown1(true);
                          NotificationManager.success('Job assigned successfully.', "", 2000);
                        }
                        else
                        {
                          NotificationManager.error('error', "", 2000);
                        }
                    }
                   setLoading(false);
                   setEditorEmail('');
                   // setShowEditorList('');
                 }
                 else
                 {
                   setLoading(false);
                   NotificationManager.error('error', "", 2000);
                 }
             }
             getAllJobs();
           }
       }
     }
     catch (error) 
     {
      //  NotificationManager.error(error.message, "", 2000);
     }
  }
  
  // Editor Details 
  const editorDetailsById = async(editor_id) =>
  {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let fullName='';
            console.log(apiRes.data.data[0])
            // setEditorEmail(apiRes.data?.data[0]?.first_name);
            setEditorEmail(apiRes.data?.data[0]?.first_name);
            fullName = apiRes.data?.data[0]?.first_name + ' ' + apiRes.data.data[0]?.last_name;
            console.log(fullName)
            alert(fullName)
            setShowEditorName(fullName)
            return fullName;
          }
          else
          {
            // NotificationManager.error('error', "", 2000);
          }
      }
    
  }

  const calculate_age = () =>{
    alert('function called!');
  }
  // Editor Details
  const editorName = async(editor_id) =>
  {
    console.log(editor_id)
    return
    try 
    {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
           
            console.log(apiRes.data.data[0])
            return apiRes.data?.data[0]?.first_name + ' ' + apiRes.data.data[0]?.last_name;
            
          }
      }
    }
    catch (error) 
    {
      NotificationManager.error(error.message, "", 2000);
    }
  }

  //get job details by id
  const getJobDetails= async(job_id) =>{
    // alert(job_id)
    try
    {
      setAPiCall(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            console.log(apiRes.data.data[0]);
            setJobInfoById(apiRes.data.data[0]);
            setServiceFile(apiRes.data.data[0].service_filename);
            setInstructionFile(apiRes.data.data[0].instruction_filename);
            jobListByClient(apiRes.data.data[0].user_id)
            // setTimeout(() => {
            //   setCallAPI(!callAPI);
            //   // getAllJobs();
              fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id,apiRes.data.data[0]?.internal_id);
            // }, 3000);
              
          }
      }
    }
      catch (error)
      {
        // NotificationManager.error(error.message, "", 2000);
      }
  }

  //get Resume/Cv by id
  const getJobDetailsResumeCv= async(cv_id) =>{
    // alert(job_id)
    try
    {
      // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumedetail?id=${cv_id}`)
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${cv_id}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            // console.log(apiRes.data.data[0]);
            setJobInfoById(apiRes.data.data[0]);
            setServiceFile(apiRes.data.data[0].service_filename);
            setInstructionFile(apiRes.data.data[0].instruction_filename);
          }
      }
    } 
    catch (error)
    {
      // NotificationManager.error(error.message, "", 2000);
    }

  }


  // Start Filter data

  // Function to get filtered list
  function getFilteredList() {
    console.warn(jobList)
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return jobList;
    }
    return jobList.filter((item) => item.new_status === selectedCategory);
  }

  // Avoid duplicate function calls with useMemo
  var filteredList = useMemo(getFilteredList, [selectedCategory, jobList]);


  function handleCategoryChange(event)
  {
    setSelectedCategory(event);
  }

// End Filter data

  //sorting job list
  const sortingJobList = async(filter_id) =>{
    console.log(filter_id)
    if (filter_id == 0)
    {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/joblist`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setJobList(apiRes.data.data);
            }
        }
      }//close try 
      catch (error)
      {
        NotificationManager.error(error.message, "", 2000);
      }
    }
    else
    {
      try 
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setJobList(apiRes.data.data);
            }
        }
      } 
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
    }
  }

  //reAssign Editor
  const reAssignEditor = () =>{
    // alert(1)
    setShowDropDown(true);
    setShowDropDown1(false);

    setRemoveButton(false);
    setRemoveButton1(false);
  }

  //reAssign Internal Editor
  const reAssignInternalEditor = () =>{
    setShowDropDown1(true);
    setShowDropDown(false);

    setRemoveButton(false);
    setRemoveButton1(false);
  }

const timeReachedMessage = () =>{
  // setTimeReachedMsg(1);
  return 'Time Reached';
}
  //Counter
const remainingTimeCount = (datetime) =>{
  if(datetime!= 0)
  {
    console.log(datetime)
    var split_days = datetime?.split(",");
      console.log(split_days)
      var sp_days = split_days[0].slice(0,2);
      var sp_hours = split_days[1].slice(0,2);
      var sp_minutes = split_days[2].slice(0,2);
      var sp_seconds = split_days[3].slice(0,2);

      // console.log('Days: ' + sp_days + ' ' + 'Hours: ' + sp_hours + ' ' + 'Minutes: ' + sp_minutes + ' ' + 'Seconds: ' + sp_seconds);
      // console.log('Days: ' + sp_days.slice(0,2) + ' ' + 'Hours: ' + sp_hours.slice(0,2) + ' ' + 'Minutes: ' + sp_minutes.slice(0,2) + ' ' + 'Seconds: ' + sp_seconds.slice(0,2));

    const DAYS_IN_MS = parseInt(sp_days.slice(0,2))* 24 * 60 * 60 * 1000;
    const hour_ms = parseInt(sp_hours.slice(0,2)) * 3600000;
    const minutes_ms = parseInt(sp_minutes.slice(0,2)) * 60000;
    const sec_ms = parseInt(sp_seconds.slice(0,2)) * 1000;
    const total_ms = DAYS_IN_MS + hour_ms + minutes_ms + sec_ms;
    console.log(total_ms)

    if(total_ms < 0 )
    {
      setEndCounterTimer(true);
    }
    else
    {
      // setEndCounterTimer(true);
      const NOW_IN_MS = new Date().getTime();
      const dateTimeAfterThreeDays = NOW_IN_MS + total_ms;
      console.log(dateTimeAfterThreeDays)
      // <CountdownTimer targetDate={dateTimeAfterThreeDays} />
      return(<CountdownTimer targetDate={dateTimeAfterThreeDays}/>)
    }
  }
  else
  {
    return 0
  }
}

  // New Time-Up-Counter
  // const countUpTimer= (datetime) =>{
  //   // console.log(datetime)
  //   let total_ms;
  //   if(datetime!= 0)
  //   {
  //     var split_days = datetime?.split(",");
  //     console.log(split_days)
  //     var sp_days = split_days[0].slice(0,2);
  //     var sp_hours = split_days[1].slice(0,2);
  //     var sp_minutes = split_days[2].slice(0,2);
  //     var sp_seconds = split_days[3].slice(0,2);
  
  //     const DAYS_IN_MS = parseInt(sp_days.slice(0,2))* 24 * 60 * 60 * 1000;
  //     const hour_ms = parseInt(sp_hours.slice(0,2)) * 3600000;
  //     const minutes_ms = parseInt(sp_minutes.slice(0,2)) * 60000;
  //     const sec_ms = parseInt(sp_seconds.slice(0,2)) * 1000;
  //     total_ms = DAYS_IN_MS + hour_ms + minutes_ms + sec_ms;
  //     // console.log(total_ms)
  //   }else{
  //     return (<CountUpTimerComponent MS={0}/>)
  //   }
  //   return (<CountUpTimerComponent MS={total_ms}/>)
  // }

  const countUpTimer = (datetime) => {
    let total_ms = 0;
  
    if (datetime !== "0") {
      const split_days = datetime?.split(",");
      if (split_days?.length === 4) {
        const sp_days = parseInt(split_days[0]);
        const sp_hours = parseInt(split_days[1]);
        const sp_minutes = parseInt(split_days[2]);
        const sp_seconds = parseInt(split_days[3]);
  
        const DAYS_IN_MS = sp_days * 24 * 60 * 60 * 1000;
        const HOURS_IN_MS = sp_hours * 60 * 60 * 1000;
        const MINUTES_IN_MS = sp_minutes * 60 * 1000;
        const SECONDS_IN_MS = sp_seconds * 1000;
  
        total_ms = DAYS_IN_MS + HOURS_IN_MS + MINUTES_IN_MS + SECONDS_IN_MS;
      }
    }
  
    return <CountUpTimerComponent MS={total_ms} />;
  };

  //single file upload in comments
  const uploadFileHandle = (e) =>{
    if(
      e.currentTarget.files[0].type=='application/msword' ||
      e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||
      e.currentTarget.files[0].type== 'application/pdf' ||
      e.currentTarget.files[0].type== 'image/jpeg' ||
      e.currentTarget.files[0].type=='image/png'
      // e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.presentationml.presentation'||
    )
    {
      console.log(e.target.files[0].type);
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
    else
    {
      NotificationManager.warning('File is not supported.Supported file types are DOC,DOCX,PDF,JPEG,PNG','',3000);
    }
    
  }

//single file delete in comments
const deleteFileHandle = () =>
{    
  setFile('');
  setFileName('');
}

  //All Insert Comments
  //  const allComments = async() =>{
  //   try 
  //   {
  //     let apiRes = await(
  //         axios
  //         .post(
  //           `${API_BASE_URL}getAllComments`,{
  //             user_id: jobInfoById?.user_id,
  //             editor_id: jobInfoById?.editor_id,
  //             job_id: jobInfoById?.id,
  //           },
  //         )
  //       )
  //     if(apiRes)
  //     { 
  //         console.log(apiRes.data.res)
  //         if(apiRes.data.res == true) 
  //         {
  //           setAdminMsgArray(apiRes.data.data[0]);
  //         }
  //         else
  //         {
  //           NotificationManager.error('error', '',2000);
  //         }
  //     }
  //   }
  //   catch (error) 
  //   {
  //     NotificationManager.error(error.message, '',2000);
  //   }

  // }

   //Client-Editor-Admin Comment Section
  const commentSection = async() =>{
    if((adminMsg === '' || adminMsg == null || adminMsg == undefined) && (fileName === '' || fileName == null || fileName == undefined))
    {
        console.log(adminMsg);
        NotificationManager.warning('Please write something..','',2000);
    }
    else
    {
      try 
      {
        // setLoading(true);
        // let apiRes = await(
        //     axios
        //     .post(
        //       `${API_BASE_URL}comment`,{
        //         user_id: jobInfoById?.user_id,
        //         editor_id: jobInfoById?.editor_id,
        //         admin_id: loggedUser?.id, 
        //         job_id: jobInfoById?.id,
        //         client_comment: '',
        //         editor_comment: '',
        //         admin_comment: adminMsg,
        //         // client_file: '',
        //         // editor_file: '',
        //         // admin_file: file
        //       },
        //     )
        //   )
          const formData = new FormData();

          formData.append('user_id',jobInfoById?.user_id);
          formData.append('editor_id',jobInfoById?.editor_id ? jobInfoById?.editor_id : '');
          formData.append('admin_id',loggedUser?.id);
          formData.append('internal_id',jobInfoById?.internal_id ? jobInfoById?.internal_id : '');
          formData.append('deputy_id',jobInfoById?.deputy_id ? jobInfoById?.deputy_id : '');
          formData.append('job_id',jobInfoById?.id);
          formData.append('client_comment','');
          formData.append('editor_comment','');
          formData.append('admin_comment',adminMsg);
          formData.append('internal_comment','');
          formData.append('deputy_comment','');
          formData.append('client_file','');
          formData.append('editor_file','');
          formData.append('admin_file',file);
          formData.append('internal_file','');
          formData.append('deputy_file','');

          const config = {     
            headers: { 'content-type': 'multipart/form-data' }
          }

          let apiRes = await axios.post(`${API_BASE_URL}comment`,formData,config) 

          if(apiRes)
          { 
            console.log(apiRes.data.res)
            if(apiRes.data.res == true) 
            {
              setAdminMsg('');
              setFile('');
              setFileName('');
              fetchAllComments(jobInfoById?.user_id,jobInfoById?.editor_id,jobInfoById?.id,jobInfoById?.internal_id);
            }
            else
            {
              // NotificationManager.warning(apiRes.data.msg, '',2000);
            }
          }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      }
    }
  }
    
    //fetch all comments Editor-Client
    const fetchAllComments = async(user_id,editor_id,job_id,internal_id,deputy_id) =>{
      try 
      {
        let intern_id= '';
        let dep_id = '';
        if(internal_id === null)
        {
          intern_id = '';
          let dep_id = '';
        }
        else
        {
          intern_id = internal_id;
        }
        // setLoading(true);
        // let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}`)
        let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}&internal_id=${intern_id}&deputy_id=${dep_id}&UserTimeZone=${timeZone}`)
          if(apiRes)
          { 
            console.log(apiRes.data.res)
            if(apiRes.data.res == true) 
            {
              setShowAllComments(apiRes.data.data);
            }
            else
            {
              // NotificationManager.warning(apiRes.data.msg, '',2000);
            }
          }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      }
    }

    const convertToSec = (countdown) =>{
      var split_days = countdown?.split(",");
      var sp_days = split_days[0].slice(0,2);
      var sp_hours = split_days[1].slice(0,2);
      var sp_minutes = split_days[2].slice(0,2);
      var sp_seconds = split_days[3].slice(0,2);

      let day_to_secs = parseInt(sp_days.slice(0,2))* 24 * 60 * 60;  //86400
      let hour_to_secs = parseInt(sp_hours.slice(0,2)) * 3600;
      let minutes_to_secs = parseInt(sp_minutes.slice(0,2)) * 60;
      let sec_to_secs = parseInt(sp_seconds.slice(0,2));
      let total = parseInt(day_to_secs) + parseInt(hour_to_secs) + parseInt(minutes_to_secs) + parseInt(sec_to_secs);
      
      return total;
    }

    const projectStatus =(countdown,item)=>{
      // console.warn(countdown);
      // console.warn(item);
      // alert(100);
      
      let val='';
      let service_hr = item?.service_hour;
      let cal_per75 = service_hr*0.75;

      let cal_coundown_sec = convertToSec(countdown);
      let cal_editor_countdown = convertToSec(item?.service_hour75);
  
      // console.log('sp_hours: ' + sp_hours);
      // var total_days = (sp_days*24);
      // var total = parseInt(total_days) + parseInt(sp_hours);
      // console.log('total_hr: ' + total);
      // console.log('service_hr: ' + service_hr);
      // let cal_percentage = parseFloat((total/cal_per75) * 100).toFixed(2);
      // console.log('cal_percentage: ' + cal_percentage + '%');
      // 24
      // 18
      // 18/18*100 = 100%
      // 17/18*100 = 94%
      let cal_percentage = parseFloat((cal_coundown_sec/cal_editor_countdown) *100).toFixed(2);
      console.warn(cal_percentage);
      // let cal_percentage = 100 ;
      // // if(calculate39 > 0.00 && calculate39 <= 7.02)
      if(cal_percentage > 80.00 && cal_percentage <= 100.00)
      {
        console.warn(1);
        // val = 'Low Priority';
        val = 'L Priority';
        item.new_status = 'lowpriority';
      }
      // else if(calculate40 >= 7.03 && calculate40 <= 10.80)
      else if(cal_percentage >= 60.00 && cal_percentage <= 79.99)
      {
        console.warn(2);
        // val = 'Medium Priority';
        val = 'M Priority';
        item.new_status = 'mediumpriority';
      }
      // else if (calculate60 >= 10.81 && calculate60 <= 14.40)
      else if (cal_percentage >= 40.00 && cal_percentage <= 59.99)
      {
        console.warn(3);
        // val = 'High Priority';
        val = 'H Priority';
        item.new_status = 'highpriority';
      }
      // else if(calculate80 >= 14.41)
      else if(cal_percentage >= 0.00 && cal_percentage <= 39.99)
      {
        console.warn(4);
        val = 'Critical';
        item.new_status = 'critical';
      }
      else
      {
        console.warn(5);
        val = null;
        item.new_status = null;
      }
      return val;
    }
    
    // Update Editor Remuneration
    const updateEditorRem = async() =>{
    // alert(1);
    // console.log('Job ID:' + jobInfoById?.id);
    // console.log('Price:' + editorPrice);
    if(editorPrice == undefined)
    {
      setShowError(true);
    }
    else if(editorPrice > 100)
    {
      NotificationManager.warning('Exceeding number of percentage', '',2000);
    }
    else
    {
      setShowError(false);
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/ChangeEditorRemuneration?id=${jobInfoById?.id}&editor_reg_price=${editorPrice}`) 
        if (apiRes) 
        {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            console.log(apiRes.data.data);
            handleCloseRem();
            $(".DtlsSectionWrapper").removeClass("Act");
            setEditorPrice();
            getAllJobs();
          }
        }
      }
      catch (error)
      {
         setShowError(false);
      }
    }
    }
  
  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Job Board</h2>
          </span>

          <div className="dropdown">
            Filter By
              <select className="dropdown-toggle" data-toggle="dropdown" style={{border: 'none',outline: 'none',scrollBehavior: 'smooth'}} onChange={(e)=>handleCategoryChange(e.target.value)}>
                <option className="dropdown-item" href="#" value=''>All Job</option>
                <option className="dropdown-item" href="#" value='critical'>Critical</option>
                {/* <option className="dropdown-item" href="#" value='highpriority'>High Priority</option>
                <option className="dropdown-item" href="#" value='mediumpriority'>Medium Priority</option>
                <option className="dropdown-item" href="#" value='lowpriority'>Low Priority</option> */}
                <option className="dropdown-item" href="#" value='highpriority'>H Priority</option>
                <option className="dropdown-item" href="#" value='mediumpriority'>M Priority</option>
                <option className="dropdown-item" href="#" value='lowpriority'>L Priority</option>
              </select>
          </div>

        </div>
        {/* <br className="clearfix" /> */}
        <section className="section OdrDtlsPnl">
          <div className="complete-table-sec">
            <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table AdminJobBoard">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th style={{fontSize:'13px'}}>Task Name</th>
                  {/* <th style={{fontSize:'13px'}}>Word Count</th> */}
                  {/* <th style={{fontSize:'13px'}}>Total Word Count/<br/>Page No.</th>  */}
                  <th style={{fontSize:'13px'}}>WC/Pages</th> 
                  <th style={{fontSize:'13px'}}>Amount</th> 
                  <th style={{fontSize:'13px'}}>Due Date</th>
                  {/* <th>Created On</th> */}
                  {/* Editor Countdown */}
                  <th style={{textAlign: "center",fontSize:'13px'}}>Editor Timer</th>
                  <th style={{textAlign: "center",fontSize:'13px'}}>Client Timer</th>
                  <th style={{textAlign: "center",fontSize:'13px'}}>Status</th>
                  <th style={{fontSize:'13px'}}>Editor</th>
                  <th style={{fontSize:'13px'}}>Client</th>
                  {/* <th style={{fontSize:'13px'}}>Assigned </th> */}
                  <th style={{fontSize:'13px'}}>Payment</th>
                  {/* <td>test demo</td> */}
                  {/* <th>Client Name</th> */}
                  {/* <th>Client Name</th> */}
                  {/* <th>Request Job</th> */}
                  {/* <th>Assign Editor</th> */}
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredList?.length > 0 ? (
                  filteredList?.map((item,index)=>{
                    return(
                      <>
                      {(item?.job_type==='type2')?(
                        <tr>
                          <td> {item?.id} </td>
                          <td>
                            <p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetails(item?.id)}}>
                              {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                            </p>
                          </td>
                          <td style={{textAlign: "center"}}>{item.number_of_pages}</td>
                          {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                          {/* <td>{item?.payment===''?'---':'---'}</td> */}
                          <td>${parseFloat(Number(item.editor_cv_price)).toFixed(2)}</td>
                          <td><span class="GreenTxt">{get_date_format(item?.due_date)}</span></td>
                          <td style={{textAlign: "center"}}>
                            <div className="count-down-timer">
                              {item?.date75 == 0 ?(
                                <div class="count-down-timer count-down-timer-stop">
                                  <div class="show-counter">
                                    {countUpTimer(item?.date75_new)}
                                  </div>
                                </div>
                              ):(
                                <>
                                  {remainingTimeCount(item?.date75)}
                                </>
                              )}
                            </div>
                          </td>

                          <td>
                            <div className="count-down-timer">
                              {item?.differencetime == 0 ?(
                                <div class="count-down-timer count-down-timer-stop">
                                  <div class="show-counter">
                                    {countUpTimer(item?.differencetime_new)}
                                  </div>
                                </div>
                              ):(
                                <>
                                  {remainingTimeCount(item?.differencetime)}
                                </>
                              )}
                            </div>
                          </td>
                          {/* <td>{item?.differencetime}</td> */}
                          <td style={{whiteSpace: 'nowrap'}}>
                            {
                              (item?.job_status == '1' && item?.date75!= 0) ? (
                                <>
                                {projectStatus(item?.date75,item)=== 'Critical' ?(
                                  <span className="asign-red">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):
                                // projectStatus(item?.date75,item)=== 'High Priority' ?(
                                projectStatus(item?.date75,item)=== 'H Priority' ?(
                                  <span className="asign-orange">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):
                                // projectStatus(item?.date75,item)=== 'Medium Priority' ?(
                                projectStatus(item?.date75,item)=== 'M Priority' ?(
                                  <span className="asign-blue">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):
                                // projectStatus(item?.date75,item)=== 'Low Priority' ?(
                                projectStatus(item?.date75,item)=== 'L Priority' ?(
                                  <span className="asign-green">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):''}
                                </>
                              ):
                              // item?.date75 == 0 && item?.differencetime == 0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                              item?.differencetime == 0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                              item?.job_status == '2'? (<span className="asign-deep-blue"><i class="bi bi-clock"></i> Assigned</span>):
                              item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                              item?.job_status == '30' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                              item?.job_status == '31' && item?.date75!= 0 ? (<span className="StatusTxt reassign"><i class="bi bi-arrow-clockwise"></i> ReAssign</span>):
                              item?.job_status == '32' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                              item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                              item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Canceled</span>):''
                            }
                          </td>
                          <td>
                            {
                              (item?.editor_name!=null)? item?.editor_name + ' ' +'(' + item?.editor_designation + ')' :
                              (item?.internal_name!=null)? item?.internal_name + ' ' +'(' + item?.internal_designation + ')' : '--'
                              
                            }
                          </td>

                          {/* <td>{item?.editor_name?item?.editor_name:'---'}</td> */}
                          <td>{item?.client_name}</td>
                          
                          {/* <td>{item?.payment===''?'---':'---'}</td> */}
                          <td>${parseFloat(item.cv_price).toFixed(2)}</td>
                          {/* <td>${parseFloat(item.GrandTotal).toFixed(2)}</td> */}
                          {/* <td>{projectStatus(item?.date75)}</td> */}
                        </tr>
                      ):(
                        <tr>
                          <td> {item?.id} </td>
                          <td><p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetails(item?.id)}}>
                            {/* #{item?.id} | {item?.service_name} | {item?.sub_service} | Word Count {item?.from_count}-{item?.to_count} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} |  [{item?.service_hour}hrs]</p></td> */}
                            {/* #{item?.id} | {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} |  {item?.service_hour} hrs </p></td> */}
                            {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} |  {item?.service_hour} hrs </p></td>
                          {/* <td>{item?.from_count}-{item?.to_count}</td> */}
                          <td style={{textAlign: "center"}}>{item.total_wordcount}</td>
                          {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                          {/* <td>{item?.payment===''?'---':'---'}</td> */}
                          {item.editor_reg_price!=null ? (
                            <td>${parseFloat(item.editor_reg_price).toFixed(2)}</td>
                          ):(
                            <td>$0.00</td>
                          )}
                          
                          <td><span class="GreenTxt">{get_date_format(item?.due_date)}</span></td>
                          <td style={{textAlign: "center"}}>
                            <div className="count-down-timer">
                              {item?.date75 == 0 ? (
                                <div class="count-down-timer count-down-timer-stop">
                                  <div class="show-counter">
                                    {countUpTimer(item?.date75_new)}
                                  </div>
                                </div>
                              ):(
                                <>
                                  {remainingTimeCount(item?.date75)}
                                </>
                              )}
                            </div>
                          </td>

                        <td>
                          <div className="count-down-timer">
                            {item?.differencetime == 0 ? (
                              <div class="count-down-timer count-down-timer-stop">
                                <div class="show-counter">
                                  {countUpTimer(item?.differencetime_new)}
                                </div>
                              </div>
                            ):(
                              <>
                                {remainingTimeCount(item?.differencetime)}
                              </>
                            )}
                          </div>
                        </td>
                          {/* <td>{item?.differencetime}</td> */}
                          <td style={{whiteSpace: 'nowrap'}}>
                            {
                              (item?.job_status == '1' && item?.date75!= 0) ? (
                                <>
                                {projectStatus(item?.date75,item)=== 'Critical' ?(
                                  <span className="asign-red">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):
                                // projectStatus(item?.date75,item)=== 'High Priority' ?(
                                projectStatus(item?.date75,item)=== 'H Priority' ?(
                                  <span className="asign-orange">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):
                                // projectStatus(item?.date75,item)=== 'Medium Priority' ?(
                                projectStatus(item?.date75,item)=== 'M Priority' ?(
                                  <span className="asign-blue">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):
                                // projectStatus(item?.date75,item)=== 'Low Priority' ?(
                                projectStatus(item?.date75,item)=== 'L Priority' ?(
                                  <span className="asign-green">
                                  <i class="bi bi-clock"></i> 
                                  {/* Not Yet Assigned<br/> */}
                                  {projectStatus(item?.date75,item)}
                                </span>
                                ):''}
                                </>
                              ):
                              // item?.date75 == 0 && item?.differencetime == 0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                              item?.differencetime == 0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                              item?.job_status == '2'? (<span className="asign-deep-blue"><i class="bi bi-clock"></i> Assigned</span>):
                              item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                              item?.job_status == '30' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                              item?.job_status == '31' && item?.date75!= 0 ? (<span className="StatusTxt reassign"><i class="bi bi-arrow-clockwise"></i> ReAssign</span>):
                              item?.job_status == '32' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                              item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                              item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Canceled</span>):''
                            }
                          </td>
                          {/* <td>{item?.editor_name?item?.editor_name:'---'}</td> */}
                          {/* <td>{item?.client_name}</td> */}
                          <td>
                            {
                              (item?.editor_name!=null)? item?.editor_name + ' ' + '(' + item?.editor_designation + ')' :
                              (item?.internal_name!=null)? item?.internal_name + ' ' + '(' + item?.internal_designation + ')' : '--'
                              
                            }
                          </td>
                          <td>{item?.client_name}</td>
                          {/* <td>{item?.payment===''?'---':'---'}</td> */}
                          <td>${parseFloat(item.total_price).toFixed(2)}</td>
                          {/* <td>${parseFloat(item.GrandTotal).toFixed(2)}</td> */}
                          {/* <td>{projectStatus(item?.date75)}</td> */}
                        </tr>
                      )}
                        
                      </>
                    
                    )
                  })
                ):(
                    // <p style={{textAlign:'end'}}>No Jobs Found</p>
                  <tr>
                    <td className='no-data' colSpan={11} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                  </tr>
                )}
              
              </tbody>
            </table>
          </div>
        </section>
        
         {/* left Job Details Pannel */}
         <div className={`DtlsSectionWrapper OngoingPnl openJobDetlsPnl`} ref={windowRef}>
         {/* <div className={`DtlsSectionWrapper OngoingPnl ${orderDetailsById?.job_status === '3' ? 'textarea-sec':'no-textarea-sec'}`} > */}
            <div className={`TaskDtlsPnl ${jobInfoById && jobInfoById?.job_status == '3' ? 'textarea-sec':'no-textarea-sec'}`}>
              <div className="HdrTop">
              {/* <span className="Rht">
                  <a className="CmnBtn CancelOrdereBtn" onClick={orderCancelShow}>
                    {jobInfoById?.job_status === '5' ? (
                        <a className="CmnBtn CancelledTag">
                          <i className="bi bi-x-circle"/> Cancelled
                        </a>
                    ):
                    jobInfoById?.job_status === '1' ? ( 
                      <i className="bi bi-check-circle"> Cancel Order</i>
                    ):
                    (
                      <>
                          <i className="bi bi-check-circle" /> Cancel Order
                      </>
                    )}
                  </a>
                </span> */}
              {jobInfoById?.job_status === '5' ?(
                <span className="Rht">
                  <a onClick={()=>{closeJobDetlsPnl();setShowDropDown(false)}} className="CmnBtn CancelledTag">
                    <i className="bi bi-x-circle"/> Canceled
                  </a>
                </span>
              ):(
                <a className="CmnBtn CancelOrdereBtn" onClick={orderCancelShow}>
                  {/* <i className="bi bi-check-circle"/> Cancel Order */}
                  Cancel Order
                </a>
              )}
              <span className="Rht">
                <a onClick={()=>{closeJobDetlsPnl();setShowDropDown(false);setAPiCall(false)}} className="CloseBtn" style={{position:'absolute', right: '5px'}}>
                  <i className="bi bi-x" />
                </a>
              </span>



              {/* {jobInfoById && jobInfoById?.job_status === '5' ? (
                <span className="Rht">
                  <a onClick={()=>{closeJobDetlsPnl();setShowDropDown(false);setAPiCall(false)}} className="CmnBtn CancelledTag">
                  <i className="bi bi-x-circle"/> Cancelled
                  </a>
                </span>
              ):(
                <span className="Rht">
                  <a onClick={()=>{closeJobDetlsPnl();setShowDropDown(false);setAPiCall(false)}} className="CloseBtn">
                    <i className="bi bi-x" />
                  </a>
                </span>
              )} */}
                
                {jobInfoById?.job_status == '1' && jobInfoById?.date75!= 0 && (
                  // <a className="change-editor-remuneration-link" onClick={()=>setShowRem(true)} >Change Editor Remuneration %</a>
                  // <a className="change-editor-remuneration-link" onClick={()=>setShowRem(true)} >Update Remuneration</a>
                  <Button className="CmnBtn edit-rem" onClick={()=>setShowRem(true)} >Edit Remuneration</Button>
                )}
                
              </div>
                  
              <div className="MiddleBody">
                {/* <h2>
                  #84 | WRITER | Book | Word Count 2,000-3,999 | Expedited
                  Service [32hrs]
                </h2> */}
                {(jobInfoById?.job_type==='type2') ? (
                  <>
                    <h2>
                      {/* #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.from_count}-{jobInfoById?.to_count} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  [{jobInfoById?.service_hour}hrs] */}
                      #{jobInfoById?.id} | {jobInfoById?.service_name} | Number of Pages {jobInfoById?.number_of_pages} | {jobInfoById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                    </h2>
                    <table className="DtlsDescrpTbl">
                      <tbody>
                        <tr>
                          <td>Editor</td> 
                            <td>
                              {jobInfoById?.editor_name!=""?(
                                <>
                                  {jobInfoById?.editor_name}
                                  <div className="edit-sec">
                                  {loading && loading ? (
                                      <i class="fa fa-refresh fa-spin"></i>
                                      ):(
                                        <></>
                                  )}
                                  {((showDropDown && showDropDown) && (!removeButton && !removeButton)) ?(
                                      <Form.Group>
                                        <Form.Select name = "subservice" onChange={(e)=>{assignEditor(e.target.value)}}>
                                          <option selected hidden>Select Editor</option>
                                          {showEditorList && showEditorList.map((item,index)=>{
                                            return(
                                                <option key={index} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                            )
                                          })}
                                        </Form.Select>
                                      </Form.Group>
                                  ):(
                                    <>
                                      {(jobInfoById?.job_status == '4' || jobInfoById?.job_status == '5') ? (
                                        <></>
                                      ):(
                                        <>
                                          {jobInfoById?.date75 == 0 ?(
                                              // <Button className="CmnBtn" type="submit" disabled>Assign12</Button>
                                            <>
                                              {removeButton && removeButton ?(
                                                <strong>  Assigning to Editor....</strong>
                                              ):(
                                                <>
                                                  {isAssigned ? (
                                                    <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                                  ):(
                                                    <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ):(
                                            <>
                                              {removeButton && removeButton ?(
                                                <strong>  Assigning to Editor....</strong>
                                              ):(
                                                <>
                                                  {isAssigned ? (
                                                    <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                                  ):(
                                                    <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                                  )}
                                                </>
                                                
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  </div>
                                </>
                              ):(
                                <></>
                              )}
                            </td>
                        </tr>

                        <tr>
                        <td>Internal Editor</td> 
                          <td>
                            {jobInfoById?.internal_name!=""?(
                              <>
                                {jobInfoById?.internal_name}
                                <div className="edit-sec">
                                {loading1 && loading1 ? (
                                    <i class="fa fa-refresh fa-spin"></i>
                                    ):(
                                      <></>
                                )}
                                {showDropDown1 && showDropDown1 ?(
                                  <Form.Group>
                                    <Form.Select name = "subservice" onChange={(e)=>{assignInternalEditor(e.target.value)}}>
                                      <option selected hidden>Select Internal</option>
                                      {showInternalList && showInternalList.map((item,index)=>{
                                        return(
                                            <option key={index} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                        )
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                ):(
                                  <>
                                    {(jobInfoById?.job_status == '4' || jobInfoById?.job_status == '5') ? (
                                      <></>
                                    ):(
                                      <>
                                        {jobInfoById?.date75 == 0 ?(
                                            // <Button className="CmnBtn" type="submit" disabled>Assign13</Button>
                                          <>
                                            {removeButton1 && removeButton1 ?(
                                              <strong>  Assigning to Internal Editor....</strong>
                                            ):(
                                              <>
                                                {isAssigned ? (
                                                  <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                                ):(
                                                  <Button className="CmnBtn" type="submit" onClick={()=>reAssignInternalEditor()}>Assign</Button>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ):(
                                          <>
                                            {removeButton1 && removeButton1 ?(
                                              <strong>  Assigning to Internal Editor....</strong>
                                            ):(
                                              <>
                                                {isAssigned ? (
                                                  <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                                ):(
                                                  <Button className="CmnBtn" type="submit" onClick={()=>reAssignInternalEditor()}>Assign</Button>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                </div>
                              </>
                            ):(
                              <></>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Due date</td>
                          {/* <td>Dec 08, 2022</td> */}
                          {/* <td>Created at</td> */}
                          <td><span class="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span></td>
                        </tr>
                        <tr>
                          <td>Instruction</td>
                          {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} /> */}
                          {jobInfoById?.client_instruction!='null' ? (
                            <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} />
                          ):(
                            <td>No instruction included.</td>
                          )}
                        </tr>
                        <tr>
                          <td>Services:</td>
                          {/* <td>ESL</td> */}
                          <td>{jobInfoById?.service_name}</td>
                        </tr>
                        {/* <tr>
                          <td>Options:</td>
                          <td>{jobInfoById?.sub_service}</td>
                        </tr> */}
                        {/* <tr>
                          <td>Word Count:</td>
                          <td>{jobInfoById?.from_count}-{jobInfoById?.to_count}</td>
                        </tr> */}
                        <tr>
                          <td>Service Type:</td>
                          {/* <td>Regular[24hrs]</td> */}
                          <td>{jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                        </tr>
                        <tr>
                          <td>Number of Pages: </td>
                          <td>{jobInfoById?.number_of_pages}</td>
                        </tr>

                        <tr>
                          <td>Price: </td>
                          <td>${parseFloat(jobInfoById?.cv_price).toFixed(2)}</td>
                        </tr>
                        {/* <tr>
                          <td>Language:</td>
                          <td>
                            {
                              jobInfoById?.english_version=='1'?'American':
                              jobInfoById?.english_version=='2'?'British':
                              jobInfoById?.english_version=='3'?'Canadian':''
                            }
                          </td>
                        </tr> */}
                        <tr>
                          <td>Service File:</td>
                          <td>
                                {/* {
                                  jobInfoById?.service_filename.endsWith('.doc') || 
                                  jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                } */}
                              {serviceFile && serviceFile.length > 0 && serviceFile.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                                  // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>Instruction File:</td>
                          <td><br/>
                            {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                              return(
                                item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                              )
                                      // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ):
                (
                  <>
                    <h2>
                      {/* #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.from_count}-{jobInfoById?.to_count} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  [{jobInfoById?.service_hour}hrs] */}
                      #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.total_wordcount} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {jobInfoById?.service_hour} hrs
                    </h2>
                    <table className="DtlsDescrpTbl">
                      <tbody>
                      <tr>
                          <td>Editor</td> 
                            <td>
                              {jobInfoById?.editor_name!=""?(
                                <>
                                  {jobInfoById?.editor_name}
                                  <div className="edit-sec">
                                  {loading && loading ? (
                                      <i class="fa fa-refresh fa-spin"></i>
                                      ):(
                                        <></>
                                  )}
                                  {((showDropDown && showDropDown) && (!removeButton && !removeButton)) ?(
                                      <Form.Group>
                                        <Form.Select name = "subservice" onChange={(e)=>{assignEditor(e.target.value)}}>
                                          <option selected hidden>Select Editor</option>
                                          {showEditorList && showEditorList.map((item,index)=>{
                                            return(
                                              <option key={index} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                            )
                                          })}
                                        </Form.Select>
                                      </Form.Group>
                                  ):(
                                    <>
                                      {(jobInfoById?.job_status == '4' || jobInfoById?.job_status == '5') ? (
                                        <></>
                                      ):(
                                        <>
                                          {jobInfoById?.date75 == 0 ?(
                                              // <Button className="CmnBtn" type="submit" disabled>Assign14</Button>
                                            <>
                                              {removeButton && removeButton ?(
                                                <strong>  Assigning to Editor....</strong>
                                              ):(
                                                <>
                                                  {isAssigned ? (
                                                    <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                                  ):(
                                                    <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ):(
                                            <>
                                              {removeButton && removeButton ?(
                                                <strong>  Assigning to Editor....</strong>
                                              ):(
                                                <>
                                                  {isAssigned ? (
                                                    <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                                  ):(
                                                    <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                                  )}
                                                </>
                                                
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  </div>
                                </>
                              ):(
                                <></>
                              )}
                            </td>
                      </tr>

                      <tr>
                      <td>Internal Editor</td> 
                        <td>
                          {jobInfoById?.internal_name!=""?(
                            <>
                              {jobInfoById?.internal_name}
                              <div className="edit-sec">
                              {loading1 && loading1 ? (
                                  <i class="fa fa-refresh fa-spin"></i>
                                  ):(
                                    <></>
                              )}
                              {showDropDown1 && showDropDown1 ?(
                                  <Form.Group>
                                    <Form.Select name = "subservice" onChange={(e)=>{assignInternalEditor(e.target.value)}}>
                                      <option selected hidden>Select Internal</option>
                                      {showInternalList && showInternalList.map((item,index)=>{
                                        return(
                                            <option key={index} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                        )
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                              ):(
                                <>
                                  {(jobInfoById?.job_status == '4' || jobInfoById?.job_status == '5') ? (
                                    <></>
                                  ):(
                                    <>
                                      {jobInfoById?.date75 == 0 ?(
                                          // <Button className="CmnBtn" type="submit" disabled>Assign15</Button>
                                        <>
                                          {removeButton1 && removeButton1 ?(
                                            <strong>  Assigning to Internal Editor....</strong>
                                          ):(
                                            <>
                                              {isAssigned ? (
                                                <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                              ):(
                                                <Button className="CmnBtn" type="submit" onClick={()=>reAssignInternalEditor()}>Assign</Button>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ):(
                                        <>
                                          {removeButton1 && removeButton1 ?(
                                            <strong>  Assigning to Internal Editor....</strong>
                                          ):(
                                            <>
                                              {isAssigned ? (
                                                <Button className="CmnBtn" type="submit" disabled style={{cursor:'not-allowed'}}>Assign</Button>
                                              ):(
                                                <Button className="CmnBtn" type="submit" onClick={()=>reAssignInternalEditor()}>Assign</Button>
                                              )}
                                            </>
                                            
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              </div>
                            </>
                          ):(
                            <></>
                          )}
                        </td>
                      </tr>


                        <tr>
                          <td>Due date</td>
                          {/* <td>Dec 08, 2022</td> */}
                          {/* <td>Created at</td> */}
                          <td><span class="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span></td>
                        </tr>
                        <tr>
                          <td>Instruction</td>
                          {/* <td>{jobInfoById?.full_description}</td> */}
                          {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.full_description}} /> */}
                          {jobInfoById?.client_instruction!='null' ? (
                            <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} />
                          ):(
                            <td>No instruction included.</td>
                          )}
                        </tr>
                        <tr>
                          <td>Services:</td>
                          {/* <td>ESL</td> */}
                          <td>{jobInfoById?.service_name}</td>
                        </tr>
                        <tr>
                          <td>Options:</td>
                          {/* <td>Document</td> */}
                          <td>{jobInfoById?.sub_service}</td>
                        </tr>
                        <tr>
                          <td>Word Count:</td>
                          {/* <td>251-999</td> */}
                          {/* <td>{jobInfoById?.total_wordcount}</td> */}
                          {/* <td>{jobInfoById?.from_count}-{jobInfoById?.to_count}</td> */}
                          <td>{jobInfoById?.total_wordcount}</td>
                        </tr>
                        <tr>
                          <td>Service Type:</td>
                          {/* <td>Regular[24hrs]</td> */}
                          <td>{jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                        </tr>
                        <tr>
                          <td>Language:</td>
                          {/* <td>British</td> */}
                          <td>
                            {
                              jobInfoById?.english_version=='1'?'American':
                              jobInfoById?.english_version=='2'?'Australian':
                              jobInfoById?.english_version=='3'?'British':
                              jobInfoById?.english_version=='4'?'Canadian':''
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Service File:</td>
                          <td>
                                {/* {
                                  jobInfoById?.service_filename.endsWith('.doc') || 
                                  jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                } */}
                                {serviceFile && serviceFile.length > 0 && serviceFile.map((item,index)=>{
                                    return(
                                      item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                      item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                    )
                                      // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                                })}
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>Instruction File:</td>
                          <td><br/>
                            {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                              return(
                                item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                              )
                                      // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}

                {/* Comment Section Start */}

                {ShowAllComments && ShowAllComments.map((item,index)=>{
                  return(
                    <div className="CommentItem">
                      {/* --Client Comment Section Start-- */}
                        {item?.client_comment!='' && (
                          <>
                            <p className="AuthorName">
                              {/* <span className="NameLogo">sw</span> Sam Willium */}
                              <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.client_comment =="undefined" ? '' : item?.client_comment}}/>
                          </>
                        )}

                        {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment =='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                {
                                  item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>

                        )}

                        {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment!='' && (
                          <>
                            <ul className="showAttachFileList">
                              <li>
                                {
                                  item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>

                        )}
                      {/* --Client Comment Section End-- */}
                        
                      {/* --Editor Comment Section Start-- */}
                        {item?.editor_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.editor_comment =="undefined" ? '' : item?.editor_comment}}/>
                          </>
                        )}

                        {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment =='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment!='' &&(

                        <ul className="showAttachFileList">
                          <li>
                            { 
                            item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                            }
                              
                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>

                        )}
                      {/* --Editor Comment Section End-- */}

                      {/* --Admin Comment Section Start-- */}
                        {item?.admin_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.admin_comment =="undefined" ? '' : item?.admin_comment}}/>
                          </>
                        )}

                        {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment!='' && (

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                            }
                              
                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>

                        )}
                      {/* --Admin Comment Section End-- */}

                      {/* --Internal Comment Section Start-- */}
                        {item?.internal_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.internal_comment =="undefined" ? '' : item?.internal_comment }}/>
                          </>
                        )}

                        {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment!='' &&(

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                            }
                              
                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>

                        )}
                      {/* --Internal Comment Section End-- */}

                      {/* --Deputy Comment Section Start-- */}
                        {item?.deputy_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.deputy_comment =="undefined" ? '' : item?.deputy_comment }}/>
                          </>
                        )}

                        {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment =='' &&(
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                              </p>
                              <ul className="showAttachFileList">
                                <li>
                                  { 
                                    item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                                  }
                                </li>
                              </ul>
                            </>
                        )}

                        {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment!=''&& (

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                            }
                          </li>
                        </ul>

                        )}
                      {/* --Deputy Comment Section End-- */}

                    <div className="RhtDate">
                      <span>  
                        {get_date_format(item?.created_at)}
                        <br />
                        {get_time_format(item?.created_at)}
                      </span>
                    </div>

                  </div>
                )})}

                {/* Comment Section End */}
                
              </div>
              

              {jobInfoById?.job_status === '3' &&(
                <div className="BtmFtr">
                  <a className="attachFileLink"><input type="file" className="inputFile" onChange={(e) => {uploadFileHandle(e)}}/><img src={attachFileIcon} alt /></a>
                  <CKEditor 
                    editor={ ClassicEditor }
                    data = {adminMsg}
                    config={{
                      toolbarLocation: "bottom",
                      toolbar: ['link']
                    }}
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                        setAdminMsg(data);
                      }}
                  />
                  {/* <ul className="showAttachFileList">
                    <li>
                      <span className="icon-span"><img src={pdfImg} alt="img" /></span>
                      <span className="upload-title-span">feedback3.pdf</span>
                      <span className="delete-upload-span"><i class="bi bi-trash"></i></span>
                    </li>
				          </ul> */}

                  <ul className="showAttachFileList">
                    {((fileName && fileName != undefined) || (fileName && fileName != null) || (fileName && fileName != '')) ? (
                      <li>
                         <span className="icon-span">
                          {
                            fileName?.endsWith('.pdf') ? <img src={pdfImg} alt="img" />:
                            fileName?.endsWith('.PDF') ? <img src={pdfImg} alt="img" />:
                            fileName?.endsWith('.docx') ? <img src={docImg} alt="img" />:
                            fileName?.endsWith('.DOCX') ? <img src={docImg} alt="img" />:
                            fileName?.endsWith('.jpg') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JPG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.jpeg') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JPEG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.png') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.PNG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.jfif') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JFIF') ? <img src={iconImg} alt="img" />:''
                          }
                        </span>
                        {/* <span className="icon-span"><img src={pdfImg} alt="img" /></span> */}
                        {/* <span className="upload-title-span">feedback3.pdf</span> */}
                        <span className="upload-title-span">{fileName}</span>
                        <span className="delete-upload-span"><i class="bi bi-trash" onClick={()=>deleteFileHandle()}></i></span>
                      </li>
                    ):(
                      <li></li>
                    )}
				          </ul>

                  <a className="btn btn-primary CmnBtn submitBtm" onClick={()=>commentSection()}>Submit</a>
                </div>
              )}

            </div>
         </div>

      </main>
      <Modal show={markComplete} onHide={markClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Rate your experience and quality of work</p>
          <p><img src={feedbackStarImg} alt="" /></p>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Mark Complete</a>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Feedback</h2>
          <p>Give some feedback about your experience with our service to the admin</p>
          <textarea name="" cols="" rows="" placeholder="Type here..."></textarea>
          <label className="radio inline">
            <input defaultChecked name="lost-password" defaultValue type="radio" />
            <span>Send to all</span>
          </label>
          <label className="radio inline">
            <input name="lost-password" defaultValue type="radio" />
            <span>Send to only admin</span>
          </label>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Feedback</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <h2>Request Order Cancelation</h2> */}
          <h2>Order Cancelation</h2>
          {/* <h2>Confirm Cancelation</h2> */}
          {/* <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p> */}
          <p>Canceling will remove the job from the job board. Are you sure you want to cancel?</p>
          {/* <p>Are you sure you want to cancel?</p> */}
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn" onClick={orderCancelClose}>NO</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancelForm} onHide={()=>{orderCancelFormClose();setShowCancelReasonTitleError(false);setShowCancelReasonDescriptionError(false)}} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <h2>Request Order Cancellation</h2> */}
          <h2>Confirm Cancelation</h2>
          {/* <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p> */}
          <p>Are you sure you want to cancel your order?</p>
          {/* <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" name="cancellation_title" onChange={(e)=>setShowCancelReasonTitle(e.target.value)} value={showCancelReasonTitle}/> */}
          {/* {showCancelReasonTitleError && (showCancelReasonTitle=== '' || showCancelReasonTitle=== undefined || showCancelReasonTitle=== null) && (<div className='error'>Reason is required</div>)} */}
          
          {/* <textarea class="form-control" rows="3" placeholder="Type here reason with full description..." name="cancellation_description" onChange={(e)=>setShowCancelReasonDescription(e.target.value)} value={showCancelReasonDescription}></textarea> */}
          {/* {showCancelReasonDescriptionError && (showCancelReasonDescription=== '' || showCancelReasonDescription=== undefined || showCancelReasonDescription=== null) && (<div className='error'> Reason Description is required</div>)} */}
          
          {/* <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a> */}
          

          {/* {cancelLoading && cancelLoading ? (
              <Button class="CmnBtn" disabled><i class="fa fa-refresh fa-spin"></i>Send Request Order Cancellation</Button>
            ):(
              <Button class="CmnBtn" onClick={()=>orderCancellation()}><i class="bi bi-check-circle"></i> Send Request Order Cancellation</Button>
            )
          } */}

          {cancelLoading && cancelLoading ? (
              <Button className="CmnBtn yesBtn" disabled><i class="fa fa-refresh fa-spin"></i>YES</Button>
            ):(
              <>
                <Button className="CmnBtn yesBtn" onClick={()=>orderCancellation()}>YES</Button>
                <Button className="cancelBtn noBtn" onClick={()=>orderCancelFormClose()}>NO</Button>
              </>
            )
          }
          
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={modalclose}  className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Select Editor</h2>
          <>
            <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value);modalclose();}}>
              <option value='null'>--Select Editor--</option>
                {showEditorList && showEditorList.map((item,index)=>{
                  return(
                    // <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                    <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                  )
                })}    
            </select>
          </>
          {/* <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a> */}
        </Modal.Body>
      </Modal>

      <Modal centered show={showRem} onHide={()=>{setEditorPrice();setShowError(false);handleCloseRem()}} className="CmnModal editor-remuneration-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <h2>Editor Remuneration (%)</h2> */}
          <h2>Editor Remuneration ($)</h2>
          <input type="number" class="form-control" placeholder="Enter Editor Remuneration" onChange={(e)=>setEditorPrice(e.target.value)} value={editorPrice}/>
          <span>
            {showError && (editorPrice==undefined || editorPrice == '' || editorPrice === 'null') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Editor Remuneration is required</p></div>}
          </span>
          <a class="CmnBtn" onClick={()=>updateEditorRem()}><i class="bi bi-check-circle"></i>Update Remuneration</a>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default Adminjobboard;
