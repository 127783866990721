import {
  React, useEffect, useState,
  useNavigate,
  axios,
  NotificationManager,
  Rating
} from '../../utility/CommonImport'; // Import 

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";

import { API_BASE_URL} from '../../api_url';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const userImg = require("../../assets/img/user-img.png");

const Admindashboard = () => {
  const [arrData,setArrData] = useState([]);
  const [userData, setUserData] = useState({
    type: 'line',
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        // label: 'Dataset 1',
        label: 'Completed Jobs',
        // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
        data: arrData,
        borderColor: 'rgb(11, 14, 120)',
        backgroundColor: 'rgb(11, 14, 120)',
        borderWidth: 2,
        pointRadius: 5,
      },
    ]
  }) 
  const [showDataByAdmin,setShowDataByAdmin] = useState(); //Admin data
  const [showShortName,setShowShortName] = useState(); // short name 
  const navigate = useNavigate();
  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));

  console.log(loggedUser) 

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
    },
    scales: {
      x: {
          grid: {
            min: 0,
            max: 70,
            grace: '5%',
          },
          title: {
            display: true,
            text: 'Month',
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          },
          ticks: {
            color: '#525252',
            // stepSize: 0.5,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
      y: {
        min: 0,
        max: showDataByAdmin?.complete,
        grace: '5%',
          grid: {
        },
        title: {
          display: true,
          text: 'Number of Jobs',
          font: {
            size: 13,
            family: "'Poppins', sans-serif",
            weight: 400
          }
        },
        ticks: {
          color: '#525252',
          precision:0,
          // stepSize: 0.5,
          font: {
            size: 13,
            family: "'Poppins', sans-serif",
            weight: 400
          }
        }
      },
    },
  };

  useEffect(() => {
    dashboardDataByAdmin();
    // getAllOngoingJobs();
    // getAllCompletedJobs();
    completeJobCount();
  }, []);
  
  // useEffect(() => {
  //   if (loggedUser=== null)
  //   {
  //       navigate("/admin-login");
  //       navigate(0);
  //   }
  //   else if(loggedUser?.role == '1' || loggedUser?.role == '2')
  //   {
  //       navigate("/admin-login");
  //   }
  // })

  //fetch current month name
  const currentMonth = (month) =>{
    switch(month) {
      case '01':
        return 'JANUARY';
      case '02':
        return 'FEBRUARY';
      case '03':
        return 'MARCH';
      case '04':
        return 'APRIL';
      case '05':
        return 'MAY';
      case '06':
        return 'JUNE';
      case '07':
        return 'JULY';
      case '08':
        return 'AUGUST';
      case '09':
        return 'SEPTEMBER';
      case '10':
        return 'OCTOBER';
      case '11':
        return 'NOVEMBER';
      case '12':
        return 'DECEMBER';
      default:
        return '';
    }
  }

  //Complete Job count per month
  const completeJobCount = async() =>{
    try 
    {
      // https://www.demoupdates.com/updates/elite/api/job/numberofjobspermonth?editor_id=132&all_id=editor_id
      let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobspermonth?id=`)
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          setArrData(apiRes.data.data);
          console.log(apiRes.data.data);


          setUserData({
            type: 'line',
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
              {
                // label: 'Dataset 1',
                label: 'Completed Jobs',
                // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
                data: apiRes.data.data,
                borderColor: 'rgb(11, 14, 120)',
                backgroundColor: 'rgb(11, 14, 120)',
                borderWidth: 2,
                pointRadius: 5,
              },
            ]
          }) 

        }
      }
    } 
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  const dashboardDataByAdmin = async() =>{
    try 
    {
        let apiRes = await axios.get(`${API_BASE_URL}job/numberofalljoblist`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                setShowDataByAdmin(apiRes.data.data);
            }
            else
            {
                NotificationManager.error(apiRes.data.msg, "", 2000);
            }
        }
        else
        {
          throw new Error('Network request failed');
        }
    }
    catch (error)
    {
        NotificationManager.error(error.message, "", 2000);
    }
 }

    //redirect component
    const redirectComponent = (section) => {
      switch(section)
      {
        case 1:
          return navigate("/admin-completed-job");
        case 2:
          return navigate("/admin-on-going-job");
        case 3:
          return navigate("/admin-job-board");
      }
    }
    
  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Dashboard</h2>
          </span>
        </div>
        <section className="section dasboardPnl">
          <div className="row">
            <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card word-count-card dash-board-card-box-2">
                <div className="border-box">
                  <h4>Total Word Count</h4>
                  <h5>{currentMonth(showDataByAdmin?.currentMonth)}</h5>
                </div>
                <h3>{showDataByAdmin?.totalWordcount}</h3>
              </div>
            </div>
            <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card total-amount-card dash-board-card-box-2">
                <div className="border-box">
                  <h4>Total Amount</h4>
                  <h5>{currentMonth(showDataByAdmin?.currentMonth)}</h5>
                </div>
                {(showDataByAdmin?.totalPrice === undefined || showDataByAdmin?.totalPrice === null)?(
                  <h3>$0.00</h3>
                ):(
                  <h3>${parseFloat(parseInt(showDataByAdmin?.totalPrice)).toFixed(2)}</h3>
                )}
              </div>
            </div>
            <div className="col-md-4" onClick={()=>redirectComponent(3)}>
              <div className="card dashboard-card submitted-jobs-card dash-board-card-box-2">
                <div className="border-box">
                 <h4>Submitted Jobs</h4>
                  <h5>{currentMonth(showDataByAdmin?.currentMonth)}</h5>
                </div>
                <h3>{showDataByAdmin?.jobCount}</h3>
              </div>
            </div>
            <div className="col-md-8 d-none d-md-block">
              <div className="card dashboard-card2 chart-card">
                <h3>Workflow Chart</h3>
                <span className="star-rating-span">
                  <Rating initialValue={showDataByAdmin?.rating} readonly/>
                  <span className="rating-text">{(showDataByAdmin?.rating === null || showDataByAdmin?.rating === undefined) ? '0' : showDataByAdmin?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>
            </div>
            <div className="col-md-4">
              <div className="card dashboard-card completed-jobs-card dash-board-card-box-2" onClick={()=>redirectComponent(1)}>
                <div className="border-box">
                  <h4>Completed Jobs</h4>
                  <h5>{currentMonth(showDataByAdmin?.currentMonth)}</h5>
                </div>
                <h3>{showDataByAdmin?.complete}</h3>
              </div>

              <div className="card dashboard-card ongoing-jobs-card dash-board-card-box-2" onClick={()=>redirectComponent(2)}> 
                <div className="border-box">
                  <h4>Ongoing Jobs</h4>
                  <h5>{currentMonth(showDataByAdmin?.currentMonth)}</h5>
               </div>
                <h3>{showDataByAdmin?.ongoing}</h3>
              </div>

              <div className="d-md-none card dashboard-card2 chart-card">
                <h3>Workflow Chart</h3>
                <span className="star-rating-span">
                  <Rating initialValue={showDataByAdmin?.rating} readonly/>
                  {/* <span className="rating-text">{showDataByAdmin?.rating} out of 5</span> */}
                  <span className="rating-text">{(showDataByAdmin?.rating === null || showDataByAdmin?.rating === undefined) ? '0' : showDataByAdmin?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Admindashboard;
