import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

function PayPal(props) {

    console.log(props)
    
    let eliteServicePrice= JSON.parse(localStorage.getItem("Elite_Service_price"));
    let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
    let loggedInfo = JSON.parse(localStorage.getItem("allFormData"));
    console.log(loggedInfo)

    // console.log(props.expRate)
    // console.log(props.regRate)
    // console.log(props.data?.service_type)
    console.log(eliteServicePrice)
    console.log(loggedUser)
    const navigate = useNavigate();
    const paypal = useRef();
    // let vat_service_price = props.data?.total_price + ((props.data?.total_price * 10)/100);
    // let vat_cv_price = props.data?.price + ((props.data?.price * 10)/100);
    // let vat_cv_price = parseFloat(props.data?.price) + ((props.data?.price * 10)/100);


    
    let vat_service_price = parseFloat(props.data?.total_price);
    let vat_cv_price = parseFloat(props.data?.price);

    console.log(vat_service_price);
    console.log(vat_cv_price);

    useEffect(()=>{
        // console.log(props.expRate)
        // console.log(props.regRate)
        console.log('here')
        console.log('Props useEffect:' , props)
        
        window.paypal.Buttons({
            createOrder: (data, actions, err)=> {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units : [
                        {
                            description: "Cool looking table",
                            amount: {
                                currency_code: "USD",
                                value: parseFloat(props.price).toFixed(2)
                            }
                        }
                    ]
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                // console.log(order);
                // console.log(data);
                // console.log(order.id);
                // console.log('here1')
                if(order.status === "COMPLETED")
                {
                    if(props.data?.link === "cvtype")
                    {
                        try 
                        {
                            console.log(1)
                            const formData = new FormData();
                            formData.append('user_id',props.loggedUser?.id);// customer_id
                            formData.append('service_name',props.data?.service_name);
                            formData.append('english_version',props.data?.english_version);
                            formData.append('service_filename',props.data?.service_filename);
                            formData.append('number_of_pages',props.data?.pages);
                            formData.append('total_wordcount',props.data?.total_wordcount);
                            formData.append('cv_price',(parseFloat(props.data?.price).toFixed(2)));
                            formData.append('GrandTotal',(parseFloat(vat_cv_price).toFixed(2)));
                            formData.append('total_price',0);
                            // formData.append('service_hour',data?.service_hour);
                            formData.append('service_hour',168); // for a week hours
                            formData.append('service_type',props.data?.service_type);
                            formData.append('client_instruction',props.data?.client_instruction);
                            formData.append('instruction_filename',props.data?.instruction_filename);
                            formData.append('from_count',props.data?.from_count);
                            formData.append('to_count',props.data?.to_count);
                            formData.append('job_type','type2');
                            formData.append('job_request','0');
                            formData.append('CustmrTrnsctn',order.id);
                            formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                            formData.append('client_approve','0');

                            // cv_price   , number_of_pages
                            const config = {     
                                headers: { 'content-type': 'multipart/form-data' }
                            }
                            // let apiRes = await axios.post(`${API_BASE_URL}cvresume`,formData,config) 
                            let apiRes = await axios.post(`${API_BASE_URL}job/payplePayment`,formData,config)
                            if (apiRes) 
                            {
                                console.log(apiRes.data.res);
                                if (apiRes.data.res == true) 
                                {
                                //   setIsPaymentSuccess(false);
                                //   navigate('/payment-success');

                                  navigate('/payment-success', { state: { 
                                    jobid: apiRes.data.JobId,
                                    cardExpire:'',
                                    cardNumber:'',
                                    cvc:'',
                                    orderId:apiRes.data.Job.OrderId,
                                    transactionDate:apiRes.data.Job.created_at,
                                    userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                    userAddress: apiRes.data.user.address,
                                    userState: apiRes.data.user.state,
                                    userZipCode: apiRes.data.user.zipcode,
                                    userCountry: apiRes.data.user.country
                                  }});
                                    // console.log(apiRes.data);00
                                    // setLoading(false);
                                    // setPaymentSuccessMsg(true);
                                    
                
                                    // NotificationManager.success('Email has been sent to you','',2000);
                                    // localStorage.removeItem("allFormData");
                                    // localStorage.clear();
                                    // navigate("/");
                                }
                                else
                                {
                                //   setIsPaymentSuccess(false);
                                  navigate('/payment');
                                }
                            }
                        } //try closed
                        catch (error) 
                        {
                            // setLoading(false);
                            // NotificationManager.error(error, '',2000);
                        } 
                    }
                    else if(props.data?.link === "Proofreading")
                    {
                        alert('008')
                        if(props.loggedUser?.role == '2')
                        {
                            alert('001')
                            if(props.data?.role == '1')
                            {
                                alert('Editor Selected 002');
                                try
                                {
                                    const formData = new FormData();
                                    formData.append('user_id',props.loggedUser?.id);
                                    formData.append('service_id','2');
                                    formData.append('service_name',props.data?.service_name);
                                    formData.append('sub_service',props.data?.sub_service);
                                    formData.append('english_version',props.data?.english_version);
                                    formData.append('service_filename',props.data?.service_filename);
                                    formData.append('total_wordcount',props.data?.total_wordcount);
                                    formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                                    formData.append('cv_price',0);
                                    formData.append('service_hour',props.data?.service_hour);
                                    formData.append('service_type',props.data?.service_type);
                                    formData.append('client_instruction',props.data?.client_instruction);
                                    formData.append('instruction_filename',props.data?.instruction_filename);
                                    formData.append('full_description',props.data?.client_instruction);
                                    formData.append('from_count',props.data?.from_count);
                                    formData.append('to_count',props.data?.to_count);
                                    formData.append('job_type','type1');
                                    formData.append('editor_id',props.data?.editor_id);
                                    formData.append('editor_name',props.data?.editor_name);
                                    formData.append('editor_email',props.data?.editor_email);
                                    formData.append('job_status',props.data?.editor_id === ''?1:2);
                                    formData.append('job_request','1');
                                    formData.append('assign_user','Editor');
                                    formData.append('CustmrTrnsctn',order.id);
                                    formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                                    formData.append('client_approve','0');
                                   
                                    const config = {     
                                        headers: { 'content-type': 'multipart/form-data' }
                                    }
                                    let apiRes = await axios.post(`${API_BASE_URL}job/payplePaymentexistingjobinsert`,formData,config) 
                                        
                                    if (apiRes) 
                                    {
                                        if (apiRes.data.res == true) 
                                        {
                                            navigate('/payment-success', { state: { 
                                                jobid: apiRes.data.JobId,
                                                cardExpire:'',
                                                cardNumber:'',
                                                cvc:'',
                                                orderId:apiRes.data.Job.OrderId,
                                                transactionDate:apiRes.data.Job.created_at,
                                                userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                                userAddress: apiRes.data.user.address,
                                                userState: apiRes.data.user.state,
                                                userZipCode: apiRes.data.user.zipcode,
                                                userCountry: apiRes.data.user.country,
                                                OrderType: loggedInfo?.order_type
                                            }});
                                        }
                                        else
                                        {
                                          navigate('/payment');
                                        }
                                    }
                                }
                                catch (error) 
                                {
                                    console.error('Error in - Editor Selected')
                                } 
                            }
                            else if(props.data?.role == '4')
                            {
                                alert('Internal Editor Selected')
                                try
                                {
                                    const formData = new FormData();
                                    formData.append('user_id',props.loggedUser?.id);
                                    formData.append('service_id','2');
                                    formData.append('service_name',props.data?.service_name);
                                    formData.append('sub_service',props.data?.sub_service);
                                    formData.append('english_version',props.data?.english_version);
                                    formData.append('service_filename',props.data?.service_filename);
                                    formData.append('total_wordcount',props.data?.total_wordcount);
                                    formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                                    formData.append('cv_price',0);
                                    formData.append('service_hour',props.data?.service_hour);
                                    formData.append('service_type',props.data?.service_type);
                                    formData.append('client_instruction',props.data?.client_instruction);
                                    formData.append('instruction_filename',props.data?.instruction_filename);
                                    formData.append('full_description',props.data?.client_instruction);
                                    formData.append('from_count',props.data?.from_count);
                                    formData.append('to_count',props.data?.to_count);
                                    formData.append('job_type','type1');
                                    formData.append('internal_id',props.data?.editor_id);
                                    formData.append('internal_name',props.data?.editor_name);
                                    formData.append('internal_email',props.data?.editor_email);
                                    formData.append('job_status',props.data?.editor_id === ''?1:2);
                                    formData.append('job_request','1');
                                    formData.append('assign_user','Internal');
                                    formData.append('CustmrTrnsctn',order.id);
                                    formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                                    formData.append('client_approve','0');
    
                                    const config = {     
                                        headers: { 'content-type': 'multipart/form-data' }
                                    }
                                    let apiRes = await axios.post(`${API_BASE_URL}job/payplePaymentexistingjobinsert`,formData,config) 
                                        
                                    if (apiRes) 
                                    {
                                        if (apiRes.data.res == true) 
                                        {
                                          navigate('/payment-success', { state: { 
                                            jobid: apiRes.data.JobId,
                                            cardExpire:'',
                                            cardNumber:'',
                                            cvc:'',
                                            orderId:apiRes.data.Job.OrderId,
                                            transactionDate:apiRes.data.Job.created_at,
                                            userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                            userAddress: apiRes.data.user.address,
                                            userState: apiRes.data.user.state,
                                            userZipCode: apiRes.data.user.zipcode,
                                            userCountry: apiRes.data.user.country,
                                            OrderType: loggedInfo?.order_type
                                          }});
                                        }
                                        else
                                        {
                                          navigate('/payment');
                                        }
                                    }
                                }
                                catch (error) 
                                {
                                    console.error('Error in - Internal Editor Selected')
                                } 
                            }
                            else
                            {
                                alert('Client with no-one selected')
                                try
                                {
                                    const formData = new FormData();
                                    formData.append('user_id',props.loggedUser?.id);
                                    formData.append('service_id','2');
                                    formData.append('service_name',props.data?.service_name);
                                    formData.append('sub_service',props.data?.sub_service);
                                    formData.append('english_version',props.data?.english_version);
                                    formData.append('service_filename',props.data?.service_filename);
                                    formData.append('total_wordcount',props.data?.total_wordcount);
                                    formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                                    formData.append('cv_price',0);
                                    formData.append('service_hour',props.data?.service_hour);
                                    formData.append('service_type',props.data?.service_type);
                                    formData.append('client_instruction',props.data?.client_instruction);
                                    formData.append('instruction_filename',props.data?.instruction_filename);
                                    formData.append('full_description',props.data?.client_instruction);
                                    formData.append('from_count',props.data?.from_count);
                                    formData.append('to_count',props.data?.to_count);
                                    formData.append('job_type','type1');
                                    formData.append('internal_id',props.data?.editor_id ? props.data?.editor_id : '');
                                    formData.append('internal_name',props.data?.editor_name ? props.data?.editor_name : '');
                                    formData.append('internal_email',props.data?.editor_email ? props.data?.editor_email : '');
                                    formData.append('job_status',props.data?.editor_id === '' ? 1:2);
                                    formData.append('job_request', props.data?.editor_id == '' ? '0' : '1');
                                    formData.append('assign_user', props.data?.role == '1' ? 'Editor' : props.data?.role == '4' ? 'Internal' : '');
                                    formData.append('CustmrTrnsctn',order.id);
                                    formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                                    formData.append('client_approve','0');
    
                                    const config = {     
                                        headers: { 'content-type': 'multipart/form-data' }
                                    }
                                    let apiRes = await axios.post(`${API_BASE_URL}job/payplePaymentexistingjobinsert`,formData,config) 
                                        
                                    if (apiRes) 
                                    {
                                        if (apiRes.data.res == true) 
                                        {
                                            navigate('/payment-success', { state: { 
                                                jobid: apiRes.data.JobId,
                                                cardExpire:'',
                                                cardNumber:'',
                                                cvc:'',
                                                orderId:apiRes.data.Job.OrderId,
                                                transactionDate:apiRes.data.Job.created_at,
                                                userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                                userAddress: apiRes.data.user.address,
                                                userState: apiRes.data.user.state,
                                                userZipCode: apiRes.data.user.zipcode,
                                                userCountry: apiRes.data.user.country,
                                                OrderType: loggedInfo?.order_type
                                            }});
                                        }
                                        else
                                        {
                                            navigate('/payment');
                                        }
                                    }
                                }
                                catch (error) 
                                {
                                    // NotificationManager.error(error.message, '',2000);
                                    console.error('Error in - Client with no-one selected')
                                }
                            }
                        }
                        else if (props.loggedUser?.role!= '2' && props.loggedUser?.role!= '1')
                        {
                            if(props.data?.role == '1')
                            {
                                console.log('Editor Selected 009')
                                try
                                {
                                    const formData = new FormData();
                                    formData.append('user_id',props.data?.user_id);// customer_id
                                    formData.append('service_id','2');
                                    formData.append('service_name',props.data?.service_name);
                                    formData.append('sub_service',props.data?.sub_service);
                                    formData.append('english_version',props.data?.english_version);
                                    formData.append('service_filename',props.data?.service_filename);
                                    formData.append('total_wordcount',props.data?.total_wordcount);
                                    // formData.append('total_price',Math.round(Number(data?.total_price)));
                                    formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                                    formData.append('cv_price',0);
                                    formData.append('service_hour',props.data?.service_hour);
                                    formData.append('service_type',props.data?.service_type);
                                    formData.append('client_instruction',props.data?.client_instruction);
                                    formData.append('instruction_filename',props.data?.instruction_filename);
                                    formData.append('full_description',props.data?.client_instruction);
                                    formData.append('from_count',props.data?.from_count);
                                    formData.append('to_count',props.data?.to_count);
                                    formData.append('job_type','type1');
                                    formData.append('editor_id',props.data?.editor_id);
                                    formData.append('editor_name',props.data?.editor_name);
                                    formData.append('editor_email',props.data?.editor_email);
                                    formData.append('job_status',props.data?.editor_id === ''?1:2);
                                    formData.append('job_request','1');
                                    formData.append('assign_user','Editor');
                                    formData.append('CustmrTrnsctn',order.id);
                                    formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                                    formData.append('client_approve','0');
    
                                    const config = {     
                                        headers: { 'content-type': 'multipart/form-data' }
                                    }
                                    let apiRes = await axios.post(`${API_BASE_URL}job/payplePaymentexistingjobinsert`,formData,config) 
                                        
                                    if (apiRes) 
                                    {
                                        console.log(apiRes.data.res);
                                        if (apiRes.data.res == true) 
                                        {
                                        //   setIsPaymentSuccess(false);
                                        //   navigate('/payment-success');
                                        navigate('/payment-success', { state: { 
                                            jobid: apiRes.data.JobId,
                                            cardExpire:'',
                                            cardNumber:'',
                                            cvc:'',
                                            orderId:apiRes.data.Job.OrderId,
                                            transactionDate:apiRes.data.Job.created_at,
                                            userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                            userAddress: apiRes.data.user.address,
                                            userState: apiRes.data.user.state,
                                            userZipCode: apiRes.data.user.zipcode,
                                            userCountry: apiRes.data.user.country,
                                            OrderType: loggedInfo?.order_type
                                          }});
    
    
                                            // data.submitStatus = 1;
                                            // console.log(apiRes.data);
                                            // setPaymentSuccessMsg(true);
                                            // NotificationManager.success('Job Created successfully','',3000);
                                            // NotificationManager.success('Email has been sent to you','',2000);
                                            // localStorage.setItem('allFormData', JSON.stringify(data));
                                             // localStorage.setItem("allFormData");
                                            // navigate("/");
                        
                                            // localStorage.removeItem("allFormData");
                                            // localStorage.clear();
                                            // navigate("/");
                                        }
                                        else
                                        {
                                        //   setIsPaymentSuccess(false);
                                          navigate('/payment');
                                        }
                                    }
                                }
                                catch (error) 
                                {
                                    // NotificationManager.error(error.message, '',2000);
                                } 
                            }
                            else if(props.data?.role == '4')
                            {
                                console.log('Internal Editor Selected')
                                try
                                {
                                    // console.log(3)
                                    const formData = new FormData();
                                    formData.append('user_id',props.data?.user_id?props.data?.user_id:'');// customer_id
                                    formData.append('service_id','2');
                                    formData.append('service_name',props.data?.service_name);
                                    formData.append('sub_service',props.data?.sub_service);
                                    formData.append('english_version',props.data?.english_version);
                                    formData.append('service_filename',props.data?.service_filename);
                                    formData.append('total_wordcount',props.data?.total_wordcount);
                                    // formData.append('total_price',Math.round(Number(data?.total_price)));
                                    // formData.append('total_price',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                                    formData.append('cv_price',0);
                                    formData.append('service_hour',props.data?.service_hour);
                                    formData.append('service_type',props.data?.service_type);
                                    formData.append('client_instruction',props.data?.client_instruction);
                                    formData.append('instruction_filename',props.data?.instruction_filename);
                                    formData.append('full_description',props.data?.client_instruction);
                                    formData.append('from_count',props.data?.from_count);
                                    formData.append('to_count',props.data?.to_count);
                                    formData.append('job_type','type1');
                                    formData.append('internal_id',props.data?.editor_id);
                                    formData.append('internal_name',props.data?.editor_name);
                                    formData.append('internal_email',props.data?.editor_email);
                                    formData.append('job_status',props.data?.editor_id === ''?1:2);
                                    formData.append('job_request','1');
                                    formData.append('assign_user','Internal');
                                    formData.append('CustmrTrnsctn',order.id);
                                    formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                                    formData.append('client_approve','0');
    
                                    const config = {     
                                        headers: { 'content-type': 'multipart/form-data' }
                                    }
                                    let apiRes = await axios.post(`${API_BASE_URL}job/payplePaymentexistingjobinsert`,formData,config) 
                                        
                                    if (apiRes)
                                    {
                                        console.log(apiRes.data.res);
                                        if (apiRes.data.res == true) 
                                        {
                                        //   setIsPaymentSuccess(false);
                                        //   navigate('/payment-success');
    
                                          navigate('/payment-success', { state: { 
                                            jobid: apiRes.data.JobId,
                                            cardExpire:'',
                                            cardNumber:'',
                                            cvc:'',
                                            orderId:apiRes.data.Job.OrderId,
                                            transactionDate:apiRes.data.Job.created_at,
                                            userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                            userAddress: apiRes.data.user.address,
                                            userState: apiRes.data.user.state,
                                            userZipCode: apiRes.data.user.zipcode,
                                            userCountry: apiRes.data.user.country,
                                            OrderType: loggedInfo?.order_type
                                          }});
                                            // data.submitStatus = 1;
                                            // console.log(apiRes.data);
                                            // setPaymentSuccessMsg(true);
                                            // NotificationManager.success('Job Created successfully','',3000);
                                            // NotificationManager.success('Email has been sent to you','',2000);
                                            // localStorage.setItem('allFormData', JSON.stringify(data));
                                             // localStorage.setItem("allFormData");
                                            // navigate("/");
                        
                                            // localStorage.removeItem("allFormData");
                                            // localStorage.clear();
                                            // navigate("/");
                                        }
                                        else
                                        {
                                        //   setIsPaymentSuccess(false);
                                          navigate('/payment');
                                        }
                                    }
                                    else
                                    {
                                        console.log('Error data')
                                    }
                                }
                                catch (error) 
                                {
                                    // NotificationManager.error(error.message, '',2000);
                                } 
                            }
                            else
                            {
                                console.log('Other with no-one selected')
                                try
                                {
                                    const formData = new FormData();
                                    formData.append('user_id',props.data?.user_id);// customer_id
                                    formData.append('service_id','2');
                                    formData.append('service_name',props.data?.service_name);
                                    formData.append('sub_service',props.data?.sub_service);
                                    formData.append('english_version',props.data?.english_version);
                                    formData.append('service_filename',props.data?.service_filename);
                                    formData.append('total_wordcount',props.data?.total_wordcount);
                                    // formData.append('total_price',Math.round(Number(data?.total_price)));
                                    formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                                    formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                                    formData.append('cv_price',0);
                                    formData.append('service_hour',props.data?.service_hour);
                                    formData.append('service_type',props.data?.service_type);
                                    formData.append('client_instruction',props.data?.client_instruction);
                                    formData.append('instruction_filename',props.data?.instruction_filename);
                                    formData.append('full_description',props.data?.client_instruction);
                                    formData.append('from_count',props.data?.from_count);
                                    formData.append('to_count',props.data?.to_count);
                                    formData.append('job_type','type1');
                                    formData.append('internal_id',props.data?.editor_id ? props.data?.editor_id : '');
                                    formData.append('internal_name',props.data?.editor_name ? props.data?.editor_name : '');
                                    formData.append('internal_email',props.data?.editor_email ? props.data?.editor_email : '');
                                    formData.append('job_status',props.data?.editor_id === '' ? 1:2);
                                    // formData.append('job_request','1');
                                    formData.append('job_request', props.data?.editor_id == '' ? '0' : '1');
                                    // formData.append('assign_user','Editor');
                                    formData.append('assign_user', props.data?.role == '1' ? 'Editor' : props.data?.role == '4' ? 'Internal' : '');
                                    formData.append('CustmrTrnsctn',order.id);
                                    formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                                    formData.append('client_approve','0');
    
                                    const config = {     
                                        headers: { 'content-type': 'multipart/form-data' }
                                    }
                                    let apiRes = await axios.post(`${API_BASE_URL}job/payplePaymentexistingjobinsert`,formData,config) 
                                        
                                    if (apiRes) 
                                    {
                                        console.log(apiRes.data.res);
                                        if (apiRes.data.res == true) 
                                        {
                                        //   setIsPaymentSuccess(false);
                                        //   navigate('/payment-success');
                                        navigate('/payment-success', { state: { 
                                            jobid: apiRes.data.JobId,
                                            cardExpire:'',
                                            cardNumber:'',
                                            cvc:'',
                                            orderId:apiRes.data.Job.OrderId,
                                            transactionDate:apiRes.data.Job.created_at,
                                            userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                                            userAddress: apiRes.data.user.address,
                                            userState: apiRes.data.user.state,
                                            userZipCode: apiRes.data.user.zipcode,
                                            userCountry: apiRes.data.user.country,
                                            OrderType: loggedInfo?.order_type
                                            }});
    
    
                                            // data.submitStatus = 1;
                                            // console.log(apiRes.data);
                                            // setPaymentSuccessMsg(true);
                                            // NotificationManager.success('Job Created successfully','',3000);
                                            // NotificationManager.success('Email has been sent to you','',2000);
                                            // localStorage.setItem('allFormData', JSON.stringify(data));
                                                // localStorage.setItem("allFormData");
                                            // navigate("/");
                        
                                            // localStorage.removeItem("allFormData");
                                            // localStorage.clear();
                                            // navigate("/");
                                        }
                                        else
                                        {
                                        //   setIsPaymentSuccess(false);
                                            navigate('/payment');
                                        }
                                    }
                                }
                                catch (error) 
                                {
                                    // NotificationManager.error(error.message, '',2000);
                                }
                            }
                        }
                        else
                        {
                            console.error('Payment Error.')
                        }
                        // return
                    }
                    else
                    {
                        console.log('Props data type: ' + props.data?.link);
                    }
                    // else
                    // {
                    //     console.log('009')
                    //     try {
                    //             // console.log(4)
                    //             const formData = new FormData();
                    //             formData.append('user_id',props.loggedUser?.id); // customer_id
                    //             formData.append('service_id','2');
                    //             formData.append('service_name',props.data?.service_name);
                    //             formData.append('sub_service',props.data?.sub_service);
                    //             formData.append('english_version',props.data?.english_version);
                    //             formData.append('service_filename',props.data?.service_filename);
                    //             formData.append('total_wordcount',props.data?.total_wordcount);
                    //             // formData.append('total_price',(parseFloat(data?.total_price).toFixed(2)));
                    //             formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                    //             formData.append('total_price',(parseFloat(props.data?.total_price).toFixed(2)));
                    //             formData.append('cv_price',0);
                    //             formData.append('service_hour',props.data?.service_hour);
                    //             formData.append('service_type',props.data?.service_type);
                    //             formData.append('client_instruction',props.data?.client_instruction);
                    //             formData.append('instruction_filename',props.data?.instruction_filename);
                    //             formData.append('full_description',props.data?.client_instruction);
                    //             formData.append('from_count',props.data?.from_count);
                    //             formData.append('to_count',props.data?.to_count);
                    //             formData.append('job_type','type1');
                    //             formData.append('job_request','0');
                    //             formData.append('CustmrTrnsctn',order.id);
                    //             formData.append('price_per_rate',props.data?.service_type == 0? eliteServicePrice.regularprice: eliteServicePrice.expeditedprice);
                    //             // cv_price   , number_of_pages  
                    //             formData.append('client_approve','0');
                                 
                    //             const config = {     
                    //                 headers: { 'content-type': 'multipart/form-data' }
                    //             }
                    //             let apiRes = await axios.post(`${API_BASE_URL}job/payplePayment`,formData,config) 
                                
                    //             if (apiRes) 
                    //             {
                    //                 console.log(apiRes.data.res);
                    //                 if (apiRes.data.res == true) 
                    //                 {
                    //                 //   navigate('/payment-success');

                    //                   navigate('/payment-success', { state: { 
                    //                     jobid: apiRes.data.JobId,
                    //                     cardExpire:'',
                    //                     cardNumber:'',
                    //                     cvc:'',
                    //                     orderId:apiRes.data.Job.OrderId,
                    //                     transactionDate:apiRes.data.Job.created_at,
                    //                     userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                    //                     userAddress: apiRes.data.user.address,
                    //                     userState: apiRes.data.user.state,
                    //                     userZipCode: apiRes.data.user.zipcode,
                    //                     userCountry: apiRes.data.user.country
                    //                   }});

                    //                     // data.submitStatus = 1;
                    //                     // console.log(apiRes.data);
                    //                     // setPaymentSuccessMsg(true);
                    //                     // NotificationManager.success('Job Created successfully','',3000);
                    //                     // NotificationManager.success('Email has been sent to you','',2000);
                    //                     // localStorage.setItem('allFormData', JSON.stringify(data));
                    //                      // localStorage.setItem("allFormData");
                    //                     // navigate("/");
                
                    //                     // localStorage.removeItem("allFormData");
                    //                     // localStorage.clear();
                    //                     // navigate("/");
                    //                 }
                    //             }
                    //         }
                    //         catch (error) 
                    //         {
                    //             // NotificationManager.error(error.message, '',2000);
                    //         } 
                    // } 

                    
                }
                else
                {
                    alert('error');
                    console.log('006')
                }
            },
            onError: (err) =>{
                 console.log(err)
                 console.log('007')
            }
        }).render(paypal.current)
    },[])
    
  return (
    <div>
        <div ref={paypal}></div>
    </div>
  )
}

export default PayPal