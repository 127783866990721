import {
  React, useEffect, useState, useRef, useMemo,
  useNavigate, Link,
  axios, 
  NotificationManager,
  Button,
  Modal,
  DateTime,
  Rating,
  CKEditor,
  ClassicEditor,

  // all icons
  reviewIcon,
  docFileImg,
  docImg,
  iconImg,
  pdfImg,
  attachFileIcon,
  rtfImg,
  odtImg,
  pagesImg,
  pptImg,
  xlsxImg,
  gdocImg,
  gslideImg,
  gsheetImg,
  plainTextImg,
  LatexImg,
  linkIcon,
  feedbackStarImg
} from '../../utility/CommonImport'; // Import 

import Editorheader from "../../Components/EditorComponents/Editorheader";
import Editorsidebar from "../../Components/EditorComponents/Editorsidebar";

import $ from "jquery";
import { API_BASE_URL} from '../../api_url';
import { get_date_format } from '../../utility/Common';
import { saveAs} from 'file-saver'
import CountdownTimer from "../../Timer/CountdownTimer";
import CountUpTimerComponent from '../../Timer-new/CountUpTimerComponent';


const Editorjobboard = () => {
  const windowRef = useRef();
  const navigate = useNavigate();
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  console.log(data);
  const timeZone = DateTime.local().zoneName;
  useEffect(() => {
    $(".completed-list").click(function(){
      $(".OngoingPnl").removeClass("Act");
      $(".CompletedPnl").addClass("Act");
    });
    $(".yet-to-asign-list, .ongoing-list").click(function(){
      $(".CompletedPnl").removeClass("Act");
      $(".OngoingPnl").addClass("Act");
    });
    $(".CloseBtn").click(function(){
      $(".DtlsSectionWrapper").removeClass("Act");
    });
  });


  const openJobDetlsPnl = () => { 
    setIsWindowOpen(true)
    $(".OngoingPnl").addClass("Act");
  };

  const closeJobDetlsPnl = () => { 
    $(".OngoingPnl").removeClass("Act");
  };
  
  useEffect(() => {
    jobListByEditor();
    // allComments();
}, []);

useEffect(() => {
  if (data== null)
  {
      navigate("/login");
      navigate(0);
  }
  else if(data!=null && (data?.role == '2' || data?.role == '0'))
  {
      navigate("/");
  }
})

// useEffect(()=>{
//   const timer =setTimeout(() => {
//     console.log('Elite Reviser');
//   }, 1000);
//   return() => clearTimeout(timer);
// })

  const btnRef = useRef();
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [orderDetailsById,setOrderDetailsById] = useState();
  const [isAccept,setIsAccept] = useState(false);
  const [orderList,setOrderList] = useState([]);
  const [copyOrderList,setCopyOrderList] = useState([]);
  const [showJobAccept,setShowJobAccept] = useState(null); // job accept or not
  const [arrayLength,setArrayLength] = useState();
  const [activeStatus,setActiveStatus] = useState(); // for accept_status == 1 or 0
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setInstructionFile] = useState();
  const [displayMsg,setDisplayMsg] = useState(0);
  const [endCounterTimer,setEndCounterTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignLoading,setAssignLoading] = useState(false);
  const [editorMsg,setEditorMsg] = useState();
  const [editorMsgArray,setEditorMsgArray] = useState(); //Show editor all message
  const [showIndex,setShowIndex] = useState();
  const [ShowAllComments,setShowAllComments] = useState([]); // editor client comments.
  const [selectedCategory, setSelectedCategory] = useState();
  const [isWindowOpen, setIsWindowOpen] = useState(false)

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }

// Short User Name
const shortUserName = (user_name) =>{
  console.log(user_name);
  var sl_name = user_name?.split(' ');
  console.log(sl_name)
  var short_first = sl_name[0]?.charAt(0);
  var short_last = sl_name[1]?.charAt(0);
  var short_final = short_first + short_last;
  return short_final;
  // return 0;
}

 //Time format
 const get_time_format = (tm) => {
  // let clicktime = tm.toLocaleTimeString({hour: '2-digit', minute:'2-digit'});
  // 2023-04-05T08:00:18.000000Z
  if(tm!= '')
  {
    var time_values = tm.slice(11,16)
    // console.log(time_values);
      let start_time_split = time_values?.split(":");
      console.log(start_time_split)
      let start_splt_hour = start_time_split[0];
      let start_splt_minutes = start_time_split[1];
      let start_check_dt = start_splt_hour >= 12 ? 'PM' : 'AM';
      start_splt_hour = start_splt_hour % 12;
      start_splt_hour = start_splt_hour ? start_splt_hour : 12; //hour 0 => 12
      start_splt_minutes = start_splt_minutes < 10 ? start_splt_minutes :  start_splt_minutes;
      let start_time_final = start_splt_hour + ':' + start_splt_minutes + ' ' + start_check_dt;
      return start_time_final;
  }
}

  function toSeconds(days,hours, minutes, seconds) {
    return ((days * 86400)+(hours * 3600 + minutes * 60 + seconds));
  }

  const timeReachedMessage = () =>{
    // setTimeReachedMsg(1);
    return 'Time Reached';
    
  }
  
  // //Counter
  // const remainingTimeCount = (datetime) =>{
  //   console.log(datetime)
    
  //   // differncetime: "0 days, 5 hours, 10 minutes, 55 seconds"
  // //   return
  //   var split_days = datetime.split(",");
  //   console.log(split_days)
  //   var sp_days = split_days[0].slice(0,2);
  //   var sp_hours = split_days[1].slice(0,2);
  //   var sp_minutes = split_days[2].slice(0,2);
  //   var sp_seconds = split_days[3].slice(0,2);

  //   console.log('Days: ' + sp_days + ' ' + 'Hours: ' + sp_hours + ' ' + 'Minutes: ' + sp_minutes + ' ' + 'Seconds: ' + sp_seconds);
  //   console.log('Days: ' + sp_days.slice(0,1) + ' ' + 'Hours: ' + sp_hours.slice(0,2) + ' ' + 'Minutes: ' + sp_minutes.slice(0,2) + ' ' + 'Seconds: ' + sp_seconds.slice(0,2));
  // //   // 2023-02-28 08:31:07
  // //   // return hours * 3600 + minutes * 60 + seconds;
  //   var final_seconds = toSeconds(sp_days,sp_hours,sp_minutes,sp_seconds);
  //   console.log(final_seconds)
  // //   return
  //   var date = datetime?.slice(0,10);
  //   var time = datetime?.slice(11,19);
  //   var options = { year: 'numeric', month: 'short', day: 'numeric'};
  //   console.log(date)
  //   console.log(time)
  //   var created_date  = new Date(date);
  //   var date_month_format = created_date.toLocaleDateString("en-US", options); // Saturday, September 17, 2016
  //   console.log(date_month_format)
    
  //   var final_dt = date_month_format + ' ' + time;
  //   console.log(final_dt); //Feb 28, 2023 08:31:07
  //   var countDownDate = new Date(final_dt).getTime();
  //   console.log(countDownDate)
  // //   // Update the count down every 1 second
  //   var x = setInterval(function() 
  //   {
    
  // //     // Get todays date and time
  // //     var now = new Date().getTime();
      
  // //     // Find the distance between now an the count down date
  // //     var distance = countDownDate - now;
      
  // //     // Time calculations for days, hours, minutes and seconds
  //     // var hours = Math.floor((countDownDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     // var minutes = Math.floor((countDownDate % (1000 * 60 * 60)) / (1000 * 60));
  //     // var seconds = Math.floor((countDownDate % (1000 * 60)) / 1000);


  //     var days = Math.floor(countDownDate / (1000 * 60 * 60 * 24));
  //     var hours = Math.floor((countDownDate%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
  //     var minutes = Math.floor((countDownDate % (1000 * 60 * 60)) / (1000 * 60));
  //     var seconds = Math.floor((countDownDate % (1000 * 60)) / 1000);
      
  //     console.log('d:' + days + ' ' + 'h:' + hours + ' ' + 'm:' + minutes + ' ' + 's:' + seconds);
  // //     // Output the result in an element with id="demo"
  // //     document.getElementById("demo").innerHTML = hours + "h "
  // //     + minutes + "m " + seconds + "s ";
      
  // //     // If the count down is over, write some text 
  // //     if (distance < 0) {
  // //         clearInterval(x);
  // //         document.getElementById("demo").innerHTML = "EXPIRED";
  // //     }
  // }, 1000);
  // }
  
//Counter
const remainingTimeCount = (datetime) =>{
  console.log(datetime)
  
  var split_days = datetime?.split(",");
    console.log(split_days)
    var sp_days = split_days[0].slice(0,2);
    var sp_hours = split_days[1].slice(0,2);
    var sp_minutes = split_days[2].slice(0,2);
    var sp_seconds = split_days[3].slice(0,2);

    // console.log('Days: ' + sp_days + ' ' + 'Hours: ' + sp_hours + ' ' + 'Minutes: ' + sp_minutes + ' ' + 'Seconds: ' + sp_seconds);
    // console.log('Days: ' + sp_days.slice(0,2) + ' ' + 'Hours: ' + sp_hours.slice(0,2) + ' ' + 'Minutes: ' + sp_minutes.slice(0,2) + ' ' + 'Seconds: ' + sp_seconds.slice(0,2));

  const DAYS_IN_MS = parseInt(sp_days.slice(0,2))* 24 * 60 * 60 * 1000;
  const hour_ms = parseInt(sp_hours.slice(0,2)) * 3600000;
  const minutes_ms = parseInt(sp_minutes.slice(0,2)) * 60000;
  const sec_ms = parseInt(sp_seconds.slice(0,2)) * 1000;
  const total_ms = DAYS_IN_MS + hour_ms + minutes_ms + sec_ms;
  console.log(total_ms)

  if(total_ms < 0 )
  {
    setEndCounterTimer(true);
  }
  else
  {
    // setEndCounterTimer(true);
    const NOW_IN_MS = new Date().getTime();
    const dateTimeAfterThreeDays = NOW_IN_MS + total_ms;
    console.log(dateTimeAfterThreeDays)
    // <CountdownTimer targetDate={dateTimeAfterThreeDays} />
    return(<CountdownTimer targetDate={dateTimeAfterThreeDays} />)
  }
}

const countUpTimer = (datetime) => {
  let total_ms = 0;

  if (datetime !== "0") {
    const split_days = datetime?.split(",");
    if (split_days?.length === 4) {
      const sp_days = parseInt(split_days[0]);
      const sp_hours = parseInt(split_days[1]);
      const sp_minutes = parseInt(split_days[2]);
      const sp_seconds = parseInt(split_days[3]);

      const DAYS_IN_MS = sp_days * 24 * 60 * 60 * 1000;
      const HOURS_IN_MS = sp_hours * 60 * 60 * 1000;
      const MINUTES_IN_MS = sp_minutes * 60 * 1000;
      const SECONDS_IN_MS = sp_seconds * 1000;

      total_ms = DAYS_IN_MS + HOURS_IN_MS + MINUTES_IN_MS + SECONDS_IN_MS;
    }
  }

  return <CountUpTimerComponent MS={total_ms} />;
};

  //get Resume/Cv order details by id
  const getOrderDetailsResumeCv= async(cv_id) =>{
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumedetail?id=${cv_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            // console.log(apiRes.data.data);
            setOrderDetailsById(apiRes.data.data[0]);
            setServiceFile(apiRes.data.data[0].service_filename);
            setActiveStatus(apiRes.data.data[0].accept_status);
          }
      }
    } //close try
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }
  
  //get order details by id
  const getOrderDetails= async(job_id) =>{
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              // console.log(apiRes.data.data);
              setOrderDetailsById(apiRes.data.data[0]);
              console.log(apiRes.data.data[0]);
              setActiveStatus(apiRes.data.data[0].accept_status);
              setServiceFile(apiRes.data.data[0].service_filename);
              setInstructionFile(apiRes.data.data[0].instruction_filename);
              // setTimeout(() => {
                // console.log('hi')
                fetchAllComments(apiRes.data.data[0].user_id,apiRes.data.data[0].editor_id,apiRes.data.data[0].id);
              // }, 1000);
            }
        }
      } //close try
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
  }

   // All jobs for a specific Editor
  const jobListByEditor = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}job/joblistbyeditor?editor_id=${data?.id}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            setOrderList(apiRes.data.data);
            setCopyOrderList(apiRes.data.data);
            // setArrayLength
            console.log(apiRes.data.data)
          }
      }
    } 
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  //file download
  const downloadFile = (item) => {
    saveAs(item);
  }

  //short file name
  const shortFileName=(item) =>{
    console.log(item?.slice(62,70));
    let filename = item?.slice(56,70);
    return filename + '...';
    // return item
   
  }

// Start Filter data

  // Function to get filtered list
  function getFilteredList() {
    console.warn(orderList)
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return orderList;
    }
    return orderList.filter((item) => item.new_status == selectedCategory);
  }

  // Avoid duplicate function calls with useMemo
  var filteredList = useMemo(getFilteredList, [selectedCategory, orderList]);


  function handleCategoryChange(event)
  {
    setSelectedCategory(event);
  }

// End Filter data
  
  // Job is accepted or not   {accept_type = 0 initially, accept_type = 1 accept, accept_type = 2 declined pickup = 3}
  const jobAcceptOrNot = async(job_id,accept_type) =>{
    // alert(job_id);
    // alert(accept_type);
    // return
    if(btnRef.current){
      btnRef.current.setAttribute("disabled", "disabled");
    }
    try 
      {
        setIsAccept(true);
        if(accept_type == 1)
        {
          let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?id=${job_id}&accept_status=${accept_type}&job_status=${3}&editor_id=${data.id}`)
          // let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?editor_id=${}&editor_name=${}&id=${job_id}&accept_status=${accept_type}&job_status=${3}`)
          if (apiRes)
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setShowJobAccept('Accept');
                setIsAccept(false);
                NotificationManager.success('Job Accepted', "", 2000);
                $(".DtlsSectionWrapper").removeClass("Act");
                // navigate("/editor-my-jobs");
                handleClose();
                jobListByEditor();
              }
              else
              {
                $(".DtlsSectionWrapper").removeClass("Act");
                // NotificationManager.warning(apiRes.data.msg, "", 2000);
              }
          }
        }
        else if(accept_type == 2)
        {
          // let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?id=${job_id}&accept_status=${accept_type}&job_status=${1}`)
          let apiRes = await axios.get(`${API_BASE_URL}job/rejectjob?id=${job_id}&accept_status=${0}&job_status=${1}`)
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setShowJobAccept('Declined');
                NotificationManager.info('Job Declined', "", 2000);
                $(".DtlsSectionWrapper").removeClass("Act");
                jobListByEditor();
                handleClose();
              }
          }
        }
        else if(accept_type == 3)
        {
          setLoading(true);
          let full_name = data.first_name + ' ' + data.last_name;
          // let apiRes = await axios.get(`${API_BASE_URL}job/jobpickup?id=${job_id}&accept_status=${accept_type}&job_status=${3}&editor_id=${data.id}&editor_name=${full_name}&editor_email=${data.email_address}`)
          let apiRes = await axios.get(`${API_BASE_URL}job/jobpickup?id=${job_id}&accept_status=${accept_type}&internal_id=&internal_name=&internal_email=&job_status=${3}&editor_id=${data.id}&editor_name=${full_name}&editor_email=${data.email_address}`)
          // axios.get(
          //   `${API_BASE_URL}job/jobpickup?
          //     id=${job_id}&
          //     accept_status=${accept_type}&
          //     job_status=${3}&
          //     editor_id=${data.id}&
          //     editor_name=${full_name}&
          //     editor_email=${data.email_address}&
          //     internal_id='--'&
          //     internal_name='--'&
          //     internal_email='--'`
          //   )
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setShowJobAccept('pickup');
                NotificationManager.success('Job Accepted', "", 2000);
                $(".DtlsSectionWrapper").removeClass("Act");
                jobListByEditor();
                setLoading(false);
                // handleClose();
              }
              else
              {
                setLoading(false);
                $(".DtlsSectionWrapper").removeClass("Act");
                // NotificationManager.warning(apiRes.data.msg, "", 2000);
              }
          }
        }
        else
        {
          setLoading(false);
          // NotificationManager.error('Job Status error', "", 2000);
        }
      } 
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
  }

    // CV is accepted or not   {accept_type = 0 initially, accept_type = 1 accept, accept_type = 2 declined}
    const cvAcceptOrNot = async(job_id,accept_type,index) =>{
      // alert('cv:' + accept_type)
      // return
      setShowIndex(index);
      if(btnRef.current){
        // alert(1)
        btnRef.current.setAttribute("disabled", "disabled");
      }
      try 
      {
          if(accept_type == 1)
          {
            setAssignLoading(true);
            let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?id=${job_id}&accept_status=${accept_type}&job_status=${3}&editor_id=${data.id}`)
            // let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?editor_id=${}&editor_name=${}&id=${job_id}&accept_status=${accept_type}&job_status=${3}`)
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                  
                  setShowJobAccept('Accept');
                  NotificationManager.success('Job Accepted', "", 2000);
                  setAssignLoading(false);
                  $(".DtlsSectionWrapper").removeClass("Act");
                  // navigate("/editor-my-jobs");
                  handleClose();
                  jobListByEditor();
                }
                else
                {
                  setAssignLoading(false);
                  $(".DtlsSectionWrapper").removeClass("Act");
                  // NotificationManager.warning(apiRes.data.msg, "", 3000);
                }
            }
            
          }
          else if(accept_type == 2)
          {
            // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumeacceptstatus?id=${job_id}&accept_status=${accept_type}&job_status=${1}`)
            // let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?id=${job_id}&accept_status=${accept_type}&job_status=${1}`)
            let apiRes = await axios.get(`${API_BASE_URL}job/rejectjob?id=${job_id}&accept_status=${0}&job_status=${1}`)
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                  setShowJobAccept('Declined');
                  NotificationManager.info('Job Declined', "", 2000);
                  $(".DtlsSectionWrapper").removeClass("Act");
                  jobListByEditor();
                  handleClose();
                }
            }
          }
          else if(accept_type == 3)
          {
            setLoading(true);
            let full_name = data.first_name + ' ' + data.last_name;
            // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumepickup?id=${job_id}&accept_status=${accept_type}&job_status=${3}&editor_id=${data.id}&editor_name=${full_name}&editor_email=${data.email_address}`)
            // let apiRes = await axios.get(`${API_BASE_URL}job/jobpickup?id=${job_id}&accept_status=${accept_type}&job_status=${3}&editor_id=${data.id}&editor_name=${full_name}&editor_email=${data.email_address}`)
            let apiRes = await axios.get(`${API_BASE_URL}job/jobpickup?id=${job_id}&accept_status=${accept_type}&internal_id=&internal_name=&internal_email=&job_status=${3}&editor_id=${data.id}&editor_name=${full_name}&editor_email=${data.email_address}`)
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                  setShowJobAccept('pickup');
                  NotificationManager.success('Job Accepted', "", 2000);
                  $(".DtlsSectionWrapper").removeClass("Act");
                  jobListByEditor();
                  setLoading(false);
                  // handleClose();
                }
                else
                {
                  setLoading(false);
                  $(".DtlsSectionWrapper").removeClass("Act");
                  NotificationManager.warning(apiRes.data.msg, "", 2000);
                }
            }
          }
          else
          {
            setLoading(false);
            setAssignLoading(false);
            NotificationManager.success('Job Status error', "", 2000);
          }
      } 
      catch (error) 
      {
        setLoading(false);
        setAssignLoading(false);
        // NotificationManager.error(error.message, "", 2000);
      }
    }

  //All Insert Comments
  //  const allComments = async() =>{
  //   try 
  //   {
  //     let apiRes = await(
  //         axios
  //         .post(
  //           `${API_BASE_URL}getAllComments`,{
  //             user_id: orderDetailsById?.user_id,
  //             editor_id: orderDetailsById?.editor_id,
  //             job_id: orderDetailsById?.id,
  //           },
  //         )
  //       )
  //     if(apiRes)
  //     { 
  //         console.log(apiRes.data.res)
  //         if(apiRes.data.res == true) 
  //         {
  //           setEditorMsgArray(apiRes.data.data[0]);
  //         }
  //         else
  //         {
  //           NotificationManager.error('error', '',2000);
  //         }
  //     }
  //   }
  //   catch (error) 
  //   {
  //     NotificationManager.error(error.message, '',2000);
  //   }

  // }

   //Client-Editor Comment Section
   const commentSection = async() =>{
    try 
    {
      setLoading(true);
      let apiRes = await(
          axios
          .post(
            `${API_BASE_URL}comment`,{
              user_id: orderDetailsById?.user_id,
              editor_id: orderDetailsById?.editor_id,
              job_id: orderDetailsById?.id,
              client_comment: '',
              editor_comment: editorMsg
            },
          )
        )
        if(apiRes)
        { 
          console.log(apiRes.data.res)
          if(apiRes.data.res == true) 
          {
            setEditorMsg('');
            fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id);
          }
          else
          {
            // NotificationManager.warning(apiRes.data.msg, '',2000);
          }
        }
    }
    catch (error) 
    {
      // NotificationManager.error(error.message, '',2000);
    }

  }
 
  //fetch all comments Editor-Client
  const fetchAllComments = async(user_id,editor_id,job_id) =>{
      try 
      {
        setLoading(true);
        let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&job_id=${job_id}&UserTimeZone=${timeZone}`)
          if(apiRes)
          { 
            console.log(apiRes.data.res)
            if(apiRes.data.res == true) 
            {
              setShowAllComments(apiRes.data.data);
            }
            else
            {
              // NotificationManager.warning(apiRes.data.msg, '',2000);
            }
          }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      }
  }
  
  const convertToSec = (countdown) =>{
    var split_days = countdown?.split(",");
    var sp_days = split_days[0].slice(0,2);
    var sp_hours = split_days[1].slice(0,2);
    var sp_minutes = split_days[2].slice(0,2);
    var sp_seconds = split_days[3].slice(0,2);

    let day_to_secs = parseInt(sp_days.slice(0,2))* 24 * 60 * 60;  //86400
    let hour_to_secs = parseInt(sp_hours.slice(0,2)) * 3600;
    let minutes_to_secs = parseInt(sp_minutes.slice(0,2)) * 60;
    let sec_to_secs = parseInt(sp_seconds.slice(0,2));
    let total = parseInt(day_to_secs) + parseInt(hour_to_secs) + parseInt(minutes_to_secs) + parseInt(sec_to_secs);
    
    return total;
  }

  const projectStatus =(countdown,item)=>{
    // console.warn(countdown);
    // console.warn(item);
    // alert(100);
    
    let val='';
    let service_hr = item?.service_hour;
    let cal_per75 = service_hr*0.75;

    let cal_coundown_sec = convertToSec(countdown);
    let cal_editor_countdown = convertToSec(item?.service_hour75);

    // console.log('sp_hours: ' + sp_hours);
    // var total_days = (sp_days*24);
    // var total = parseInt(total_days) + parseInt(sp_hours);
    // console.log('total_hr: ' + total);
    // console.log('service_hr: ' + service_hr);
    // let cal_percentage = parseFloat((total/cal_per75) * 100).toFixed(2);
    // console.log('cal_percentage: ' + cal_percentage + '%');
    // 24
    // 18
    // 18/18*100 = 100%
    // 17/18*100 = 94%
    let cal_percentage = parseFloat((cal_coundown_sec/cal_editor_countdown) *100).toFixed(2);
    console.warn(cal_percentage);
    // let cal_percentage = 100 ;
    // // if(calculate39 > 0.00 && calculate39 <= 7.02)
    if(cal_percentage > 80.00 && cal_percentage <= 100.00)
    {
      console.warn(1);
      // val = 'Low Priority';
      val = 'L Priority';
      item.new_status = 'lowpriority';
    }
    // else if(calculate40 >= 7.03 && calculate40 <= 10.80)
    else if(cal_percentage >= 60.00 && cal_percentage <= 79.99)
    {
      console.warn(2);
      // val = 'Medium Priority';
      val = 'M Priority';
      item.new_status = 'mediumpriority';
    }
    // else if (calculate60 >= 10.81 && calculate60 <= 14.40)
    else if (cal_percentage >= 40.00 && cal_percentage <= 59.99)
    {
      console.warn(3);
      // val = 'High Priority';
      val = 'H Priority';
      item.new_status = 'highpriority';
    }
    // else if(calculate80 >= 14.41)
    else if(cal_percentage >= 0.00 && cal_percentage <= 39.99)
    {
      console.warn(4);
      val = 'Critical';
      item.new_status = 'critical';
    }
    else
    {
      console.warn(5);
      val = null;
      item.new_status = null;
    }
    return val;
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
      {
        setIsWindowOpen(false)
        closeJobDetlsPnl()
      }
      else
      {
        console.log('here')
        console.log(isWindowOpen)
        console.log(windowRef.current)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
  
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
    }
  }, [isWindowOpen])
  
  return (
    <div>
      <Editorheader />
      <Editorsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Job Board</h2>
          </span>
          <div className="dropdown">
            Filter By
              <select className="dropdown-toggle" data-toggle="dropdown" style={{border: 'none',outline: 'none',scrollBehavior: 'smooth'}} onChange={(e)=>handleCategoryChange(e.target.value)}>
                <option className="dropdown-item" href="#" value=''>All Job</option>
                <option className="dropdown-item" href="#" value='critical'>Critical</option>
                {/* <option className="dropdown-item" href="#" value='highpriority'>High Priority</option>
                <option className="dropdown-item" href="#" value='mediumpriority'>Medium Priority</option>
                <option className="dropdown-item" href="#" value='lowpriority'>Low Priority</option> */}
                <option className="dropdown-item" href="#" value='highpriority'>H Priority</option>
                <option className="dropdown-item" href="#" value='mediumpriority'>M Priority</option>
                <option className="dropdown-item" href="#" value='lowpriority'>L Priority</option>
              </select>
          </div>
        </div>
        <section className="section OdrDtlsPnl">
          {/* <div className="ButtonDiv"> */}
            {/* <NavLink to="/services-existing-client" className="CmnBtn AddOrderBtn">Add Order</NavLink> */}
          {/* </div> */}
          <div className="editor-job-board-table">
            <table className="CmnTbl OrdersTable table-striped blue-header-table EditorJobBoard">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th>Task Name</th>
                  <th>WC/Pages</th> 
                  <th>Amount</th> 
                  <th>Due Date</th>
                  <th style={{textAlign: "center"}}>Editor Timer</th>
                  <th style={{textAlign: "center"}}>Status</th>
                  <th>Job Request</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {filteredList?.length > 0 ? (
                filteredList?.map((item,index)=>{
                  return(
                      <tr>
                        {(item?.job_type=='type2') ? (
                          <>
                            <td> {item?.id} </td>
                            <td>
                              <p className="ListItem yet-to-asign-list" onClick={()=>{getOrderDetails(item?.id);openJobDetlsPnl()}}>
                                {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                              </p>
                            </td>
                            <td style={{textAlign: "center"}}>{item.number_of_pages}</td>
                            {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                            <td>${parseFloat(Number(item.editor_cv_price)).toFixed(2)}</td>
                            <td> <span className="GreenTxt">{get_date_format(item?.due_date)}</span></td>
                            {/* <td>{remainingTimeCount(item?.differncetime)}</td> */}
                            {/* <td>{item?.differncetime}</td> */}
                            {/* <td><div className="count-down-timer">{remainingTimeCount(item?.differncetime)}</div></td> */}
                            <td style={{textAlign: "center"}}>
                              <div className="count-down-timer">
                                {item?.date75 == 0 ?(
                                  <div class="count-down-timer count-down-timer-stop">
                                    <div class="show-counter">{countUpTimer(item?.date75_new)}</div>
                                  </div>
                                ):(
                                  <>
                                    {remainingTimeCount(item?.date75)}
                                  </>
                                )}
                              </div>
                            </td>

                            <td style={{whiteSpace: 'nowrap'}}>
                              {
                                (item?.job_status == '1' && item?.date75!= 0) ? (
                                  <>
                                    {projectStatus(item?.date75,item)== 'Critical' ?(
                                      <span className="asign-red">
                                        <i class="bi bi-clock"></i> 
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):
                                    projectStatus(item?.date75,item)== 'H Priority' ?(
                                      <span className="asign-orange">
                                        <i class="bi bi-clock"></i> 
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):
                                    projectStatus(item?.date75,item)== 'M Priority' ?(
                                      <span className="asign-blue">
                                        <i class="bi bi-clock"></i> 
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):
                                    projectStatus(item?.date75,item)== 'L Priority' ?(
                                      <span className="asign-green">
                                        <i class="bi bi-clock"></i>
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):''}
                                  </>
                                ):
                                // item?.date75 == 0 && item?.differncetime ==0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                                (item?.date75 == 0 && item?.job_status!= '5') ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                                item?.job_status == '2'? (<span className="asign-deep-blue"><i class="bi bi-clock"></i> Assigned</span>):
                                item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                                item?.job_status == '30' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                                item?.job_status == '31' && item?.date75!= 0 ? (<span className="StatusTxt reassign"><i class="bi bi-arrow-clockwise"></i> ReAssign</span>):
                                item?.job_status == '32' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                                item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                                item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Canceled</span>):''

                                // item?.date75 == 0 && item?.differncetime == 0 && (item?.job_status == '2' || item?.job_status == '3' || item?.job_status == '30' || item?.job_status == '31' ||item?.job_status == '32' ) ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                                // item?.job_status == '2'? (<span className="asign-deep-blue"><i class="bi bi-clock"></i> Assigned</span>):
                                // item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                                // item?.job_status == '30' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                                // item?.job_status == '31' && item?.date75!= 0 ? (<span className="StatusTxt reassign"><i class="bi bi-arrow-clockwise"></i> ReAssign</span>):
                                // item?.job_status == '32' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                                // item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                                // item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Canceled</span>):''
                              }
                            </td>
                            
                            {item?.job_request == '1' ? (
                              <td>{item?.editor_designation}</td>
                            ):(
                              <td></td>
                            )}
                            <td>
                              {(item?.job_status == '1' || item?.job_status == '2') ? (
                                <>
                                  {endCounterTimer ? (
                                    <></>
                                  ):(
                                    <>
                                      {loading && loading && (index==showIndex)? (
                                        <Button className="accept-job-link" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Please Wait..</Button>
                                      ):(
                                        <>
                                          {item?.date75 == 0  ? (
                                            // <Button className="accept-job-link" type="submit" disabled>Accept</Button>
                                            <Button className="accept-job-link" type="submit" onClick={()=>cvAcceptOrNot(item?.id,3,index)}>Accept</Button>
                                          ):(
                                            <Button className="accept-job-link" type="submit" onClick={()=>cvAcceptOrNot(item?.id,3,index)}>Accept</Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ):(
                                <></>
                              )}
                            </td>
                          </>
                        ):(
                          <>
                            <td> {item?.id} </td>
                            <td>
                              <p className="ListItem yet-to-asign-list" onClick={()=>{getOrderDetails(item?.id);openJobDetlsPnl()}}>
                                {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {item?.service_hour} hrs
                              </p>
                            </td>
                            <td style={{textAlign: "center"}}>{item.total_wordcount}</td>
                            {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                            <td>${parseFloat(Number(item.editor_reg_price)).toFixed(2)}</td>
                            <td> <span className="GreenTxt">{get_date_format(item?.due_date)}</span></td>
                            {/* <td>{item?.differncetime}</td> */}
                            {/* <td><div className="count-down-timer">{remainingTimeCount(item?.differncetime)}</div></td> */}
                            
                            <td style={{textAlign: "center"}}>
                              <div className="count-down-timer">
                                {item?.date75 == 0 ?(
                                  <div class="count-down-timer count-down-timer-stop">
                                    <div class="show-counter">
                                      {countUpTimer(item?.date75_new)}
                                    </div>
                                  </div>
                                ):(
                                  <>
                                    {remainingTimeCount(item?.date75)}
                                  </>
                                )}
                              </div>
                            </td>
                                
                            <td style={{whiteSpace: 'nowrap'}}>
                              {
                                (item?.job_status == '1' && item?.date75!= 0) ? (
                                  <>
                                    {projectStatus(item?.date75,item)== 'Critical' ?(
                                      <span className="asign-red">
                                        <i class="bi bi-clock"></i>
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):
                                    projectStatus(item?.date75,item)== 'H Priority' ?(
                                      <span className="asign-orange">
                                        <i class="bi bi-clock"></i>
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):
                                    projectStatus(item?.date75,item)== 'M Priority' ?(
                                      <span className="asign-blue">
                                        <i class="bi bi-clock"></i>
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):
                                    projectStatus(item?.date75,item)== 'L Priority' ?(
                                      <span className="asign-green">
                                        <i class="bi bi-clock"></i>
                                        {projectStatus(item?.date75,item)}
                                      </span>
                                    ):''}
                                  </>
                                ):
                                // item?.date75 == 0 && item?.differncetime ==0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                                item?.date75 == 0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                                item?.job_status == '2'? (<span className="asign-deep-blue"><i class="bi bi-clock"></i> Assigned</span>):
                                item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                                item?.job_status == '30' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                                item?.job_status == '31' && item?.date75!= 0 ? (<span className="StatusTxt reassign"><i class="bi bi-arrow-clockwise"></i> ReAssign</span>):
                                item?.job_status == '32' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                                item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                                item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Canceled</span>):''
                              }
                            </td>
                            
                            {item?.job_request == '1' ? (
                              <td>{item?.editor_designation}</td>
                            ):(
                              <td></td>
                            )}
                            <td>
                              {(item?.job_status == '1' || item?.job_status == '2') ? (
                                <>
                                  {endCounterTimer ? (
                                    <></>
                                  ):(
                                    <>
                                      {loading && loading && (index==showIndex) ? (
                                        <Button className="accept-job-link" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Please Wait..</Button>
                                      ):(
                                        <>
                                          {item?.date75 == 0  ? (
                                            // <Button className="accept-job-link" type="submit" disabled>Accept</Button>
                                            <Button className="accept-job-link" type="submit" onClick={()=>cvAcceptOrNot(item?.id,3,index)}>Accept</Button>
                                          ):(
                                            <Button className="accept-job-link" type="submit" onClick={()=>cvAcceptOrNot(item?.id,3,index)}>Accept</Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ):(
                                <></>
                              )}
                            </td>
                          </>
                          
                        )}
                      </tr>
                  )
                })
                ):(
                  <>
                    {displayMsg && displayMsg == 0 ?(
                      <p style={{textAlign:'end'}}>Please Wait while fetching job list..</p>
                    ):(
                      <>
                        {displayMsg && displayMsg == 1 ?(
                          <></>
                        ):(
                          <tr>
                            <td className='no-data' colSpan={9} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        
          {/* left Ongoing Pannel */}
          <div className="DtlsSectionWrapper OngoingPnl" ref={windowRef}>
            <div className={`TaskDtlsPnl ${orderDetailsById?.job_status == '3' ? 'textarea-sec':'no-textarea-sec'}`}>
              <div className="HdrTop">
                <span className="Lft">
                  {orderDetailsById?.job_type=='type1'?(
                    <>
                      {orderDetailsById?.job_status == '5' ?(
                        // <a className="CmnBtn CancelledTag">
                        //   <i className="bi bi-x-circle"/> Canceled
                        // </a>
                        <a class="CmnBtn CancelOrdereBtn cancel-odr-deactivate" onClick={()=>closeJobDetlsPnl()} style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Order Canceled">Canceled</a>
                      ):(
                        <>
                          {((activeStatus=='1' || activeStatus=='3') && (orderDetailsById.job_status!='4' || orderDetailsById.job_status!='5'))?(
                            <a className="CmnBtn CompleteTag">
                              <i className="bi bi-check-circle"/> Job Accepted
                            </a>
                          ):(
                            <>
                              {/* job assigned but either it is not accept or declined*/}
                              {(activeStatus == '0')? (
                                <>
                                  {(orderDetailsById?.job_status!='4' && orderDetailsById?.job_status!='1') ? (
                                    <>
                                      {isAccept && isAccept ?(
                                        <a className="CmnBtn CompleteTag" style={{cursor: 'not-allowed'}}>
                                          <i class="fa fa-refresh fa-spin"/> Please Wait..
                                        </a>
                                      ):(
                                        // <a ref={btnRef} className="CmnBtn CompleteTag" style={{cursor:'pointer'}} onClick={()=>jobAcceptOrNot(orderDetailsById?.id,1)}>
                                        //   <i className="bi bi-check-circle" /> Accept
                                        // </a>
                                        <Button className="details-job-link" type="submit" onClick={()=>jobAcceptOrNot(orderDetailsById?.id,1)}>Accept</Button>
                                      )}

                                        {/* <a className="CmnBtn CancelOrdereBtn" onClick={()=>jobAcceptOrNot(orderDetailsById?.id,2)}>
                                          <i className="bi bi-check-circle" />
                                           Reject
                                        </a> */}
                                        <Button className="details-job-link" type="submit" onClick={()=>jobAcceptOrNot(orderDetailsById?.id,2)}>Reject</Button>
                                    </>
                                  ):(
                                      <>
                                        {/* <a className="CmnBtn CancelOrdereBtn">
                                          <i className="bi bi-check-circle" /> Accept
                                        </a> */}
                                        
                                        {/* <a className="CmnBtn CancelOrdereBtn">
                                          <i className="bi bi-check-circle" /> Reject02
                                        </a> */}
                                      </>
                                  )}
                                  
                                </>
                              ):(
                                <>
                                  <a className="CmnBtn CompleteTag">
                                    <i className="bi bi-check-circle"/> Job Completed
                                  </a>
                                </>
                              )}
                            </>
                          )
                        }
                      </>
                    )}    
                    </>
                  ):(
                    <>
                      {orderDetailsById?.job_status == '5' ?(
                        <a className="CmnBtn CancelledTag">
                          <i className="bi bi-x-circle"/> Canceled
                        </a>
                      ):(
                        <>
                          {((activeStatus =='1' || activeStatus =='3') && (orderDetailsById.job_status!='4'))?(
                            <a className="CmnBtn CompleteTag">
                              <i className="bi bi-check-circle"/> Job Accepted
                            </a>
                          ):(
                            <>
                              {/* job assigned but either it is not accept or declined*/}
                              {(activeStatus == '0')? (
                                <>
                                  {(orderDetailsById?.job_status!='4' && orderDetailsById?.job_status!='1')?(
                                    <>
                                      {assignLoading && assignLoading ? (
                                        <a className="CmnBtn CompleteTag" style={{cursor:'not-allowed'}}>
                                           Accept
                                          {/* <i className="bi bi-check-circle" /> Accept */}
                                        </a>
                                      ):(
                                        <a ref={btnRef} className="CmnBtn CompleteTag" style={{cursor:'pointer'}}onClick={()=>cvAcceptOrNot(orderDetailsById?.id,1)}>
                                           Accept
                                          {/* <i className="bi bi-check-circle" /> Accept */}
                                        </a>
                                      )}

                                      <a className="CmnBtn CancelOrdereBtn" onClick={()=>cvAcceptOrNot(orderDetailsById?.id,2)}>
                                        Reject
                                        {/* <i className="bi bi-check-circle" /> Reject */}
                                      </a>
                                    </>
                                  ):(
                                    <>
                                      {/* <a className="CmnBtn CancelOrdereBtn">
                                        Accept */}
                                        {/* <i className="bi bi-check-circle" /> Accept */}
                                      {/* </a> */}
                                      <Button className="details-job-link" type="submit" onClick={()=>jobAcceptOrNot(orderDetailsById?.id,1)}>Accept</Button>
                                      
                                      {/* <a className="CmnBtn CancelOrdereBtn"> */}
                                         {/* Reject */}
                                        {/* <i className="bi bi-check-circle" /> Reject */}
                                      {/* </a> */}

                                      <Button className="details-job-link" type="submit" onClick={()=>jobAcceptOrNot(orderDetailsById?.id,2)}>Reject</Button>
                                    </>
                                  )}
                                  
                                </>
                                ):(
                                  <>
                                    <a className="CmnBtn CompleteTag">
                                      <i className="bi bi-check-circle"/> Job Completed
                                    </a>
                                  </>
                                )
                              }
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </span>
                <span className="Rht">
                  {/* <a className="OpenReview" onClick={handleShow}>
                    <img src={reviewIcon} al="img" />
                  </a> */}
                  <a href="javascript:void(0);" className="CloseBtn"onClick={()=>closeJobDetlsPnl()} >
                    <i className="bi bi-x" />
                  </a>
                </span>
              </div>
              <div className="MiddleBody">
              {/* <h2>
                  #84 | WRITER | Book | Word Count 2,000-3,999 | Expedited
                  Service [32hrs]
                </h2> */}
                {(orderDetailsById?.job_type=='type2')?(
                  <>
                      <h2>
                        #{orderDetailsById?.id} | {orderDetailsById?.service_name} | Number of Pages {orderDetailsById?.number_of_pages} | {orderDetailsById?.service_type == '0' ? 'Regular Service  | 1 Week ' : 'Expedited Service'}
                      </h2>
                      <table className="DtlsDescrpTbl">
                  <tbody>
                    <tr>
                      {/* <td>Editor</td> */}
                      {/* <td>
                        <span className="NameLogo">sw</span> <span className="name-span">Sam Willium</span>
                      </td> */}
                    </tr>
                    <tr>
                      <td>Due date</td>
                      <td>
                        <span className="GreenTxt">{get_date_format(orderDetailsById?.due_date)}</span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>Description</td> */}
                      <td>Instruction</td>
                      {/* <td>{orderDetailsById?.client_instruction}</td> */}
                      {/* <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} /> */}
                      {orderDetailsById?.client_instruction!='null' ? (
                        <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} />
                      ):(
                        <td>No instruction included.</td>
                      )}
                    </tr>
                    <tr>
                      <td>Service Type:</td>
                      <td>{orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                    </tr>
                    <tr>
                      <td>Number of Pages: </td>
                      <td>{orderDetailsById?.number_of_pages}</td>
                    </tr>

                    <tr>
                      <td>Price: </td>
                      <td>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Service File:</td>
                      <td>
                            {/* {
                              jobInfoById?.service_filename.endsWith('.doc') || 
                              jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                            } */}
                            {serviceFile && serviceFile.length > 0 && serviceFile.map((item,index)=>{
                                return(
                                    item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                 )
                                  // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                             })}
                        <br />
                      </td>
                    </tr>
                    
                    <tr>
                        <td>Instruction File:</td>
                        <td><br/>
                          {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                              return(
                                item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                              )
                                    // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                          })}
                        </td>
                    </tr>
                   
                  </tbody>
                      </table>
                  </>
                ):(
                  <>
                        <h2>
                {/* #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {orderDetailsById?.sub_service} | Word Count {orderDetailsById?.from_count}-{orderDetailsById?.to_count} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  [{orderDetailsById?.service_hour}hrs] */}
                #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {orderDetailsById?.sub_service} | Word Count {orderDetailsById?.total_wordcount} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {orderDetailsById?.service_hour} hrs
                {/* #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {(orderDetailsById?.service_filename?.endsWith('.docx') || 
                    orderDetailsById?.service_filename?.endsWith('.doc'))? 'Document':'' } | Word Count {orderDetailsById?.total_wordcount} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  [{orderDetailsById?.service_hour}hrs] */}
                        </h2>
                        <table className="DtlsDescrpTbl">
                  <tbody>
                    <tr>
                      {/* <td>Editor</td> */}
                      {/* <td>
                        <span className="NameLogo">sw</span> <span className="name-span">Sam Willium</span>
                      </td> */}
                    </tr>
                    <tr>
                      <td>Due date</td>
                      <td>
                        <span className="GreenTxt">{get_date_format(orderDetailsById?.due_date)}</span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td>Description</td> */}
                      <td>Instruction</td>
                      {/* <td>{orderDetailsById?.client_instruction}</td> */}
                      {/* <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} /> */}
                      {orderDetailsById?.client_instruction!='null' ? (
                        <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} />
                      ):(
                        <td>No instruction included.</td>
                      )}
                    </tr>
                    <tr>
                      <td>Services:</td>
                      {/* <td>ESL</td> */}
                      <td>{orderDetailsById?.service_name}</td>
                    </tr>
                    <tr>
                      <td>Options:</td>
                      <td>{orderDetailsById?.sub_service}</td>
                    </tr>
                    <tr>
                      <td>Word Count:</td>
                      {/* <td>251-999</td> */}
                      {/* <td>{orderDetailsById?.total_wordcount}</td> */}
                      {/* <td>{orderDetailsById?.from_count}-{orderDetailsById?.to_count}</td> */}
                      <td>{orderDetailsById?.total_wordcount}</td>
                    </tr>
                    <tr>
                      {/* <td>Seep Services:</td> */}
                      <td>Service Type:</td>
                      {/* <td>Regular[24hrs]</td> */}
                      <td>{orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                    </tr>
                    <tr>
                      <td>Language:</td>
                      {/* <td>British</td> */}
                      <td>
                        {
                            orderDetailsById?.english_version=='1'?'American':
                            orderDetailsById?.english_version=='2'?'Australian':
                            orderDetailsById?.english_version=='3'?'British':
                            orderDetailsById?.english_version=='4'?'Canadian':''
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Service File:</td>
                      <td>
                            {/* {
                              jobInfoById?.service_filename.endsWith('.doc') || 
                              jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                            } */}
                            {serviceFile && serviceFile.length > 0 && serviceFile.map((item,index)=>{
                                return(
                                    item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                  )
                                  // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                             })}
                        <br />
                      </td>
                    </tr>
                    
                    <tr>
                      <td>Instruction File:</td>
                      <td><br/>
                        {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                          return(
                            item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                            item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                          )
                            // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                        })}
                      </td>
                    </tr>
                   
                  </tbody>
                  </table>
                  </>
                )}
                
                {/* <div className="CommentItem">
                  <p className="AuthorName">
                    <span className="NameLogo BlueNameLogo">ad</span> Editor
                    Comment:
                  </p>
                  <p>Attached File</p>
                  <img src={docFileImg} alt />
                  <p>Your document is ready and I am submitted.</p>
                  <div className="RhtDate">
                    <span>
                      Dec 09, 2022
                      <br />
                      10:45 PM
                    </span>
                  </div>
                </div> */}
                {orderDetailsById?.job_status == '3' || orderDetailsById?.job_status == '30' &&(
                  <>
                    {ShowAllComments && ShowAllComments.map((item,index)=>{
                      return(
                        <div className="CommentItem">
                          {item?.client_comment!=null && (
                            <>
                              <p className="AuthorName">
                                {/* <span className="NameLogo">sw</span> Sam Willium */}
                                <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_name} [Client]
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.client_comment}}/>
                            </>
                          )}
                          
                          {item?.editor_comment!=null && (
                            <>
                              <p className="AuthorName">
                                {/* <span className="NameLogo BlueNameLogo">ad</span> Editor
                                  Comment: */}
                                <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_name} [Editor]
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.editor_comment}}/>
                            </>
                          )}

                        <div className="RhtDate">
                          <span>
                            {/* Dec 09, 2022 */}
                            {get_date_format(item?.created_at)}
                            <br />
                            {/* 10:45 PM */}
                            {get_time_format(item?.created_at)}
                          </span>
                        </div>

                      </div>
                    )})}
                  </>
                )}
              </div>

              {/* {orderDetailsById?.job_status == '3' && (
                <div className="BtmFtr">
                  <span class="NameLogo">sw</span>
                  <textarea cols="" rows=""  onChange={(e)=>setEditorMsg(e.target.value)} value={editorMsg}></textarea>
                  <input type="button" value="Submit" className="btn btn-primary CmnBtn" onClick={()=>commentSection()}/>
                  <ul className="msg-list">
                    <li><img src={attachFileIcon} alt /></li>
                    <li><img src={linkIcon} alt /></li>
                  </ul>
                </div>
              )} */}

              {orderDetailsById?.job_status == '3' &&(
                <div className="BtmFtr">
                  <CKEditor 
                    editor={ ClassicEditor }
                    data = {editorMsg}
                    config={{
                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', 
                                    '|', 'link', 'outdent', 'indent','|', 'undo', 'redo', '|']
                    }}
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                        setEditorMsg(data);
                    }}
                  />
                  <p onClick={()=>commentSection()}>Submit</p>
                </div>
              )}

            </div>
          </div>
        </section>
      </main>
      <Modal show={markComplete} onHide={markClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Rate your experience and quality of work</p>
          <p><img src={feedbackStarImg} alt="" /></p>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Mark Complete</a>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <h2>Customer Feedback</h2> */}
          <h2>Client Feedback</h2>
          {/* <p>Give some feedback about your experience with our service to the admin</p> */}
          <p>Give some feedback about your experience with our service to better serve you.</p>
          <textarea name="" cols="" rows="" placeholder="Type here..."></textarea>
          <label className="radio inline">
            <input defaultChecked name="lost-password" defaultValue type="radio" />
            <span>Send to all</span>
          </label>
          <label className="radio inline">
            <input name="lost-password" defaultValue type="radio" />
            <span>Send to only admin</span>
          </label>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Feedback</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrderForm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p>
          <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" />
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..."></textarea>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Editorjobboard;
