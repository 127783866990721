import React, { Component, useState, useEffect } from "react";
import { NavLink, useNavigate, Link, useParams } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications';

const contactBanner = require("../../assets/img/contact-banner.jpg");

const EmailVerification = () => { 
    const {verifycode,lastid} = useParams();
    console.log(verifycode,lastid);

    useEffect(() => {
        AOS.init();
    });

    const emailVerify = async() =>{
        let payloadData = {
            EmailVerificationCode: verifycode,
            lastInsertId: lastid
        }
        let apiRes = await axios.post(`${API_BASE_URL}login/user_verification`,payloadData)
        if (apiRes) 
        {
            if (apiRes.data.res == true) {
                console.log('Email Verified')
            }
            else{
                console.log('Email is not Verified')
            }
        }
    }

    useEffect(()=>{
        emailVerify();
    },[])
    
    return(
        <div>
            <div className="inner-banner">
                <div className="container">
                    <h3 data-aos="fade-up"> Email is Verified!</h3>
                    <p data-aos="fade-up">Your email has been successfully verified. You can now go back to the <Link to="/login">login</Link> page to access the features.</p>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;