import {
  React,
  Component,
  useEffect,
  useState,
  useRef,
  NavLink,
  useNavigate,
  Formik,
  Field,
  yup,
  axios,
  NotificationManager,
  Button,
  Modal,
  DateTime,
  DatePicker,
} from "../../utility/CommonImport"; // Import

import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";

import { API_BASE_URL } from "../../api_url";
import { get_date_format, downloadFile, get_time_format, getymdFormattedDate, downloadEditorFile } from "../../utility/Common";

const reviewIcon = require("../../assets/img/ReviewIcon.png");
const docFileImg = require("../../assets/img/DocFileImg.png");
const docImg = require("../../assets/img/docs-img.png");
const iconImg = require("../../assets/img/image-icon.png");
const pdfImg = require("../../assets/img/pdf.png");
const rtfImg = require("../../assets/img/rtf-img.png");
const odtImg = require("../../assets/img/odt-img.png");
const pagesImg = require("../../assets/img/pages-img.png");
const pptImg = require("../../assets/img/ppt-img.png");
const xlsxImg = require("../../assets/img/xlsx-img.png");
const gdocImg = require("../../assets/img/gdoc-img.png");
const gslideImg = require("../../assets/img/google-slides.png");
const gsheetImg = require("../../assets/img/gsheet-img.png");
const plainTextImg = require("../../assets/img/txt-img.png");
const LatexImg = require("../../assets/img/text-img.png");
const attachFileIcon = require("../../assets/img/attach-icon.png");
const linkIcon = require("../../assets/img/link-icon.png");
const feedbackStarImg = require("../../assets/img/feedbackStar.png");

const AdminCompletedJobs = () => {
  const windowRef = useRef();
  const timeZone = DateTime.local().zoneName;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //API dates
  const [useStartDate, setUseStartDate] = useState();
  const [useEndDate, setUseEndDate] = useState();

  const openJobDetlsPnl = () => {
    setIsWindowOpen(true);
    $(".openJobDetlsPnl").addClass("Act");
  };
  const closeJobDetlsPnl = () => {
    $(".openJobDetlsPnl").removeClass("Act");
  };
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showModal, setShowModal] = useState(false);
  const modalclose = () => setShowModal(false);
  const [showEditorName, setShowEditorName] = useState(); // editor name
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [showMsg, setShowMsg] = useState();
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  };

  const [showEditorList, setShowEditorList] = useState([]); // for all editors
  const [jobList, setJobList] = useState([]); // all job list
  const [jobInfoById, setJobInfoById] = useState(); // job deatils by id
  const [serviceFile, setServiceFile] = useState();
  const [instructionFile, setInstructionFile] = useState();
  const [editorEmail, setEditorEmail] = useState(); // for editor assign
  const [seconds, setSeconds] = useState(30);
  const [pause, setPause] = useState(false);
  const [ShowAllComments, setShowAllComments] = useState([]); // all comments.
  const [apiCall, setAPiCall] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [isWindowOpen, setIsWindowOpen] = useState(false);

  useEffect(() => {
    getAllEditor();
    getAllJobs();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pause) {
        //I used '!paused' because I set pause initially to false.
        if (seconds > 0) {
          setSeconds(seconds - 1);
          // console.log(1);
        }
      }
      // getAllJobs();
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (apiCall) {
      let timer = setTimeout(() => {
        console.clear();
        setCallAPI(!callAPI);
        // jobListByClient();
        fetchAllComments(jobInfoById?.user_id, jobInfoById?.editor_id, jobInfoById?.id, jobInfoById?.internal_id);
      }, 15000);

      return () => clearTimeout(timer);
      // console.log(callAPI);
    }
    // }, [callAPI])
  });

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target)) {
        setIsWindowOpen(false);
        closeJobDetlsPnl();
      } else {
        console.log("here");
        console.log(isWindowOpen);
        console.log(windowRef.current);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
    };
  }, [isWindowOpen]);

  //get formatted start date
  const getFormattedStartDate = (start_date) => {
    setUseStartDate(start_date);
  };

  //short comment-file name
  const shortCommentFileName = (item) => {
    let splt_filename = item?.split("/");
    return splt_filename[1];
  };

  //fetch all comments Editor-Client
  const fetchAllComments = async (user_id, editor_id, job_id, internal_id) => {
    try {
      let intern_id = "";
      if (internal_id === null) {
        intern_id = "";
      } else {
        intern_id = internal_id;
      }
      // setLoading(true);
      // let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}`)
      let apiRes = await axios.get(
        `${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}&internal_id=${intern_id}&UserTimeZone=${timeZone}`
      );
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          setShowAllComments(apiRes.data.data);
        } else {
          // NotificationManager.warning(apiRes.data.msg, '',2000);
        }
      }
    } catch (error) {
      // NotificationManager.error(error.message, '',2000);
    }
  };

  //Short User Name
  const shortUserName = (user_name) => {
    console.log(user_name);
    var sl_name = user_name?.split(" ");
    console.log(sl_name);
    var short_first = sl_name[0]?.charAt(0);
    var short_last = sl_name[1]?.charAt(0);
    var short_final = short_first + short_last;
    return short_final;
  };

  //replace-editor-submitted-file
  const handleReplaceFile = async (e, id, job_id) => {
    const formData = new FormData();
    formData.append("editor_submit_file[]", e.target.files[0]);
    formData.append("id", id);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let apiRes = await axios.post(`${API_BASE_URL}job/jobhold_update`, formData, config);
    if (apiRes) {
      if (apiRes.data.res == true) {
        NotificationManager.success("File replaced successfully.", "", 2000);
        // getJobDetails(job_id);
        // holdingJobList();
        getAllJobs();
      } else {
        NotificationManager.error("File is not replaced successfully.", "", 2000);
        getJobDetails(job_id);
      }
    }
    closeJobDetlsPnl();
  };

  //get formatted end date
  const getFormattedEndDate = async (end_date) => {
    var startDate = "";
    var endDate = "";
    setUseEndDate(end_date);
    startDate = getymdFormattedDate(useStartDate);
    endDate = getymdFormattedDate(end_date);
    if (startDate == "" || startDate == undefined || startDate == null) {
      NotificationManager.warning("Select Start Date", "", 1000);
    } else if (endDate == "" || endDate == undefined || endDate == null) {
      NotificationManager.warning("Select End Date", "", 1000);
    } else {
      // alert('Start Date:' + startDate);
      // alert('End Date:' + endDate);
      // Start Date:2023-04-12
      // End Date:2023-04-27
      // https://www.demoupdates.com/updates/elite/api/job/joblistByDate?startDate=2023-01-12&endDate=2023-09-12
      try {
        // setJobList
        let apiRes = await axios.get(`${API_BASE_URL}job/joblistByDate?startDate=${startDate}&endDate=${endDate}&job_status=${4}`);
        if (apiRes) {
          if (apiRes.data.res == true) {
            setJobList(apiRes.data.data);
          } else {
            // NotificationManager.error(apiRes.data.msg, '',2000);
          }
        }
      } catch (error) {
        // NotificationManager.error(error.message, '',2000);
      }
    }
  };

  // get all editors
  const getAllEditor = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=1`);
      if (apiRes) {
        if (apiRes.data.res == true) {
          setShowEditorList(apiRes.data.data);
        } else {
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      } else {
        throw new Error("Network request failed");
      }
    } catch (error) {
      NotificationManager.error(error.message, "", 2000);
    }
  };

  // show all jobs
  const getAllJobs = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${4}&UserTimeZone=${timeZone}`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          setJobList(apiRes.data.data);
          setPause(!pause);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  //short file name
  const shortFileName = (item) => {
    console.log(item?.slice(62, 70));
    let filename = item?.slice(56, 70);
    return filename + "...";
    // return item
  };

  // assign Editor
  const assignEditor = async (editor_id) => {
    // alert(editor_id)
    console.log("Editor id: " + editor_id);
    console.log("Job id: " + jobInfoById?.id);
    try {
      setLoading(true);
      setShowDropDown(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/inserteditor?id=${jobInfoById?.id}&editor_id=${editor_id}`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          let apiRes1 = await axios.get(`${API_BASE_URL}account?id=${editor_id}`);
          if (apiRes1) {
            console.log(apiRes1.data.data);
            if (apiRes1.data.res == true) {
              let fullName = "";
              let email = "";
              console.log(apiRes1.data.data[0]);
              // setEditorEmail(apiRes1.data?.data[0]?.email_address);
              fullName = apiRes1.data?.data[0]?.first_name + " " + apiRes1.data.data[0]?.last_name;
              email = apiRes1.data?.data[0]?.email_address;
              // console.log(fullName)
              // alert(fullName)
              console.log("Editor Id: " + editor_id);
              console.log("Editor Name: " + fullName);
              // getJobDetails(jobInfoById?.id)
              // setShowEditorName(fullName)
              let apiRes2 = await axios.get(
                `${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`
              );
              if (apiRes2) {
                console.log(apiRes2.data.res);
                if (apiRes2.data.res == true) {
                  getJobDetails(jobInfoById?.id);
                  setShowDropDown(false);
                  setShowDropDown1(true);
                  NotificationManager.success("Job Successfully Assigned", "", 2000);
                } else {
                  // NotificationManager.error('error', "", 2000);
                }
              }
              setLoading(false);
              setEditorEmail("");
              // setShowEditorList('');
            } else {
              setLoading(false);
              // NotificationManager.error('error', "", 2000);
            }
          }
          getAllJobs();
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  // Editor Details
  const editorDetailsById = async (editor_id) => {
    let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`);
    if (apiRes) {
      console.log(apiRes.data.res);
      if (apiRes.data.res == true) {
        let fullName = "";
        console.log(apiRes.data.data[0]);
        // setEditorEmail(apiRes.data?.data[0]?.first_name);
        setEditorEmail(apiRes.data?.data[0]?.first_name);
        fullName = apiRes.data?.data[0]?.first_name + " " + apiRes.data.data[0]?.last_name;
        console.log(fullName);
        alert(fullName);
        setShowEditorName(fullName);
        return fullName;
      } else {
        // NotificationManager.error('error', "", 2000);
      }
    }
  };

  //get job details by id
  const getJobDetails = async (job_id) => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`);
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
          setJobInfoById(apiRes.data.data[0]);
          setServiceFile(apiRes.data.data[0].service_filename);
          setInstructionFile(apiRes.data.data[0].instruction_filename);
          fetchAllComments(apiRes.data.data[0]?.user_id, apiRes.data.data[0]?.editor_id, apiRes.data.data[0]?.id, apiRes.data.data[0]?.internal_id);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  //sorting job list
  const sortingJobList = async (filter_id) => {
    console.log(filter_id);
    if (filter_id == 0) {
      try {
        let apiRes = await axios.get(`${API_BASE_URL}job/joblist`);
        if (apiRes) {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) {
            setJobList(apiRes.data.data);
          }
        }
      } catch (error) {
        //close try
        // NotificationManager.error(error, "", 2000);
      }
    } else {
      try {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}&UserTimeZone=${timeZone}`);
        if (apiRes) {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) {
            setJobList(apiRes.data.data);
          }
        }
      } catch (error) {
        // NotificationManager.error(error, "", 2000);
      }
    }
  };

  //Pause Counter
  //  const handlePauseToggle = () => {
  //   setPause(!pause);
  // }

  // Project Status
  // 5.4h to 0h => Critical
  // 5.5h to 8.1h => High
  // 8.2h to 10.8h => Medium
  // 10.9h >=   =>Low

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Completed Jobs</h2>
          </span>
        </div>
        <div className="date-range-sec">
          <div className="chose-date-sec">
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                getFormattedStartDate(date);
                setStartDate(date);
              }}
              dateFormat="P"
            />
          </div>
          <div className="chose-date-sec">
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                getFormattedEndDate(date);
                setEndDate(date);
              }}
              dateFormat="P"
            />
          </div>
        </div>
        <br className="clearfix" />
        <section className="section OdrDtlsPnl">
          <div className="complete-table-sec">
            {/* <th>Created On</th> */}
            <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table AdminCompleteTable">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th>Task Name</th>
                  <th>WC/Pages</th>
                  <th>Amount</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Editor</th>
                  <th>Client</th>
                  <th>Payment</th>
                </tr>
              </thead>
              <tbody>
                {jobList?.length > 0 ? (
                  jobList.map((item, index) => {
                    return (
                      <>
                        {item?.job_type === "type2" ? (
                          <tr>
                            <td> {item?.id} </td>
                            <td>
                              <p
                                className="ListItem yet-to-asign-list"
                                onClick={() => {
                                  openJobDetlsPnl();
                                  getJobDetails(item?.id);
                                }}
                              >
                                {item?.service_name} | Number of Pages {item?.number_of_pages} |{" "}
                                {item?.service_type == "0" ? "Regular Service  | 1 Week " : "Expedited Service"}
                              </p>
                            </td>
                            <td>{item.number_of_pages}</td>
                            {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                            <td>${parseFloat(Number(item.editor_cv_price)).toFixed(2)}</td>
                            <td>
                              <span className="GreenTxt">{get_date_format(item?.due_date)}</span>
                            </td>
                            {/* <td>
                                <div className="count-down-timer">{remainingTimeCount(item?.differencetime)}</div>
                              </td> */}
                            <td style={{ whiteSpace: "nowrap" }}>
                              {item?.job_status == "4" ? (
                                <span className="StatusTxt completed">
                                  <i class="bi bi-check-circle"></i> Completed
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td>{item?.editor_name}</td> */}
                            <td>
                              {item?.editor_name != null
                                ? item?.editor_name + " " + "(" + item?.editor_designation + ")"
                                : item?.internal_name != null
                                ? item?.internal_name + " " + "(" + item?.internal_designation + ")"
                                : "--"}
                            </td>
                            <td>{item?.client_name}</td>
                            {/* <td>{item?.payment===''?'---':'---'}</td> */}
                            {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                            <td>${parseFloat(item.GrandTotal).toFixed(2)}</td>
                            {/* <td>{projectStatus(item?.differencetime)}</td> */}
                            {/* <td>{projectStatus(item?.differencetime)}</td> */}
                            {/* <td>{projectStatus(item?.date75)}</td> */}
                          </tr>
                        ) : (
                          <tr>
                            <td> {item?.id} </td>
                            <td>
                              <p
                                className="ListItem yet-to-asign-list"
                                onClick={() => {
                                  openJobDetlsPnl();
                                  getJobDetails(item?.id);
                                }}
                              >
                                {/* #{item?.id} | {item?.service_name} | {item?.sub_service} | Word Count {item?.from_count}-{item?.to_count} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} |  [{item?.service_hour}hrs]</p></td> */}
                                {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} |{" "}
                                {item?.service_type == "0" ? "Regular Service" : "Expedited Service"} | {item?.service_hour} hrs
                              </p>
                            </td>
                            {/* <td>{item?.from_count}-{item?.to_count}</td> */}
                            <td>{item.total_wordcount}</td>
                            {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                            <td>${parseFloat(Number(item.editor_reg_price)).toFixed(2)}</td>
                            <td>
                              <span className="GreenTxt">{get_date_format(item?.due_date)}</span>
                            </td>
                            {/* <td><div className="count-down-timer">{remainingTimeCount(item?.differencetime)}</div></td> */}
                            <td style={{ whiteSpace: "nowrap" }}>
                              {item?.job_status == "4" ? (
                                <span className="StatusTxt completed">
                                  <i class="bi bi-check-circle"></i> Completed
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td>{item?.editor_name}</td> */}
                            <td>
                              {item?.editor_name != null
                                ? item?.editor_name + " " + "(" + item?.editor_designation + ")"
                                : item?.internal_name != null
                                ? item?.internal_name + " " + "(" + item?.internal_designation + ")"
                                : "--"}
                            </td>
                            <td>{item?.client_name}</td>
                            {/* <td>{item?.payment===''?'---':'---'}</td> */}
                            {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                            <td>${parseFloat(Number(item.GrandTotal)).toFixed(2)}</td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td className="no-data" colSpan={9} style={{ background: "none" }}>
                        <p style={{ textAlign: "center" }}>No Jobs Found</p>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </section>

        {/* left Job Details Pannel */}
        <div className="DtlsSectionWrapper OngoingPnl openJobDetlsPnl" ref={windowRef}>
          <div className={`TaskDtlsPnl ${jobInfoById?.job_status == "3" ? "textarea-sec" : "no-textarea-sec"}`}>
            <div className="HdrTop">
              <span className="Rht">
                <a
                  onClick={() => {
                    closeJobDetlsPnl();
                    setShowDropDown(false);
                  }}
                  className="CloseBtn"
                >
                  <i className="bi bi-x" />
                </a>
              </span>
            </div>

            <div className="MiddleBody">
              {jobInfoById?.job_type === "type2" ? (
                <>
                  <h2>
                    #{jobInfoById?.id} | {jobInfoById?.service_name} | Number of Pages {jobInfoById?.number_of_pages} |{" "}
                    {jobInfoById?.service_type == "0" ? "Regular Service | 1 Week " : "Expedited Service"}
                  </h2>
                  <table className="DtlsDescrpTbl">
                    <tbody>
                      <tr>
                        <td>Editor</td>
                        <td>
                          <span className="name-span">
                            {jobInfoById?.editor_name != null ? (
                              <>{jobInfoById?.editor_name}</>
                            ) : (
                              <>{jobInfoById?.internal_name != null ? <>{jobInfoById?.internal_name}</> : <></>}</>
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>
                          <span className="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction</td>
                        {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} /> */}
                        {jobInfoById?.client_instruction != "null" ? (
                          <td dangerouslySetInnerHTML={{ __html: jobInfoById?.client_instruction }} />
                        ) : (
                          <td>No instruction included.</td>
                        )}
                      </tr>
                      <tr>
                        <td>Services:</td>
                        <td>{jobInfoById?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Service Type:</td>
                        <td>{jobInfoById?.service_type == "0" ? "Regular Service" : "Expedited Service"}</td>
                      </tr>
                      <tr>
                        <td>Number of Pages: </td>
                        <td>{jobInfoById?.number_of_pages}</td>
                      </tr>

                      <tr>
                        <td>Price: </td>
                        {/* <td>${parseFloat(jobInfoById?.cv_price).toFixed(2)}</td> */}
                        <td>${parseFloat(jobInfoById?.GrandTotal).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Service File:</td>
                        <td>
                          {/* {
                                  jobInfoById?.service_filename.endsWith('.doc') || 
                                  jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                } */}
                          {serviceFile &&
                            serviceFile.length > 0 &&
                            serviceFile.map((item, index) => {
                              return item.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".doc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOC") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".rtf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={rtfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".odt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".fodt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pages") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pagesImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".ppt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xls") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xlsx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gdoc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gdocImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptm") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pps") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gsheet") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gsheetImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".txt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={plainTextImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".text") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={LatexImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : (
                                ""
                              );
                              // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                            })}
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction File:</td>
                        <td>
                          <br />
                          {instructionFile &&
                            instructionFile.length > 0 &&
                            instructionFile.map((item, index) => {
                              return item.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".doc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOC") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".rtf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={rtfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".odt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".fodt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pages") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pagesImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".ppt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xls") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xlsx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gdoc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gdocImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptm") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pps") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gsheet") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gsheetImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".txt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={plainTextImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".text") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={LatexImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : (
                                ""
                              );
                              // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                            })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <h2>
                    #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.total_wordcount} |{" "}
                    {jobInfoById?.service_type == "0" ? "Regular Service" : "Expedited Service"} | {jobInfoById?.service_hour} hrs
                  </h2>
                  <table className="DtlsDescrpTbl">
                    <tbody>
                      <tr>
                        <td>Editor</td>
                        <td>
                          <span className="name-span">
                            {jobInfoById?.editor_name != null ? (
                              <>{jobInfoById?.editor_name}</>
                            ) : (
                              <>{jobInfoById?.internal_name != null ? <>{jobInfoById?.internal_name}</> : <></>}</>
                            )}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>Editor Submitted Files</td>
                        <td>
                          <ul className="uploadList">
                            {jobInfoById?.submit_file.length > 0 ? (
                              jobInfoById?.submit_file.map((item, index) => {
                                return (
                                  <li>
                                    <input type="text" name="file_name" value={item?.submit_file?.substring(56)} className="form-control" />
                                    <a className="DownloadBtn" onClick={() => downloadEditorFile(item?.submit_file)}></a>
                                    <div className="UploadSec">
                                      <input type="button" name="replace" value="Replace" className="btn" />
                                      <input type="file" name="" className="InputFile" onChange={(e) => handleReplaceFile(e, item.id, item.job_id)} />
                                      {/* <input type='file' name="" className="InputFile" disabled style={{cursor:'not-allowed'}}/> */}
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </ul>
                        </td>
                      </tr>

                      <tr>
                        <td>Due date</td>
                        <td>
                          <span className="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction</td>
                        {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.full_description}} /> */}
                        {jobInfoById?.client_instruction != "null" ? (
                          <td dangerouslySetInnerHTML={{ __html: jobInfoById?.client_instruction }} />
                        ) : (
                          <td>No instruction included.</td>
                        )}
                      </tr>
                      <tr>
                        <td>Services:</td>
                        <td>{jobInfoById?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Options:</td>
                        <td>{jobInfoById?.sub_service}</td>
                      </tr>
                      <tr>
                        <td>Word Count:</td>
                        <td>{jobInfoById?.total_wordcount}</td>
                      </tr>
                      <tr>
                        <td>Service Type:</td>
                        <td>{jobInfoById?.service_type == "0" ? "Regular Service" : "Expedited Service"}</td>
                      </tr>
                      <tr>
                        <td>Language:</td>
                        <td>
                          {jobInfoById?.english_version == "1"
                            ? "American"
                            : jobInfoById?.english_version == "2"
                            ? "Australian"
                            : jobInfoById?.english_version == "3"
                            ? "British"
                            : jobInfoById?.english_version == "4"
                            ? "Canadian"
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td>Service File:</td>
                        <td>
                          {/* {
                                    jobInfoById?.service_filename.endsWith('.doc') || 
                                    jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                  } */}
                          {serviceFile &&
                            serviceFile.length > 0 &&
                            serviceFile.map((item, index) => {
                              return item.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".doc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOC") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".rtf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={rtfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".odt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".fodt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pages") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pagesImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".ppt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xls") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xlsx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gdoc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gdocImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptm") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pps") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gsheet") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gsheetImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".txt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={plainTextImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".text") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={LatexImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : (
                                ""
                              );
                              // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                            })}
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction File:</td>
                        <td>
                          <br />
                          {instructionFile &&
                            instructionFile.length > 0 &&
                            instructionFile.map((item, index) => {
                              return item.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".doc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".DOC") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".rtf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={rtfImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".odt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".fodt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={odtImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pages") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pagesImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".ppt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={pptImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xls") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".xlsx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={xlsxImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gdoc") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gdocImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pptm") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".pps") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gslideImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".gsheet") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={gsheetImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".txt") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={plainTextImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : item.endsWith(".text") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item?.slice(62, 80)}
                                  onClick={() => downloadFile(item)}
                                  className="anchor-link service-file-link"
                                >
                                  <img src={LatexImg} alt="img" />
                                  <span>{shortFileName(item)}</span>
                                </a>
                              ) : (
                                ""
                              );
                              // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                            })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}

              {/* Comment Section Start */}

              {ShowAllComments &&
                ShowAllComments.map((item, index) => {
                  return (
                    <div className="CommentItem">
                      {/* --Client Comment Section Start-- */}
                      {item?.client_comment != "" && (
                        <>
                          <p className="AuthorName">
                            {/* <span className="NameLogo">sw</span> Sam Willium */}
                            <span className="NameLogo">{shortUserName(item?.client_name)}</span>
                            {item?.client_designation}
                          </p>
                          <p dangerouslySetInnerHTML={{ __html: item?.client_comment == "undefined" ? "" : item?.client_comment }} />
                        </>
                      )}

                      {item?.client_file != "https://elitereviser.com/serversite/public/uploads/" && item?.client_comment == "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.client_name)}</span>
                            {item?.client_designation}
                          </p>
                          <ul className="showAttachFileList">
                            <li>
                              {item?.client_file?.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".jpg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".JPG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".jpeg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".JPEG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".png") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".PNG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".jfif") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".JFIF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : (
                                ""
                              )}

                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>
                        </>
                      )}

                      {item?.client_file != "https://elitereviser.com/serversite/public/uploads/" && item?.client_comment != "" && (
                        <>
                          <ul className="showAttachFileList">
                            <li>
                              {item?.client_file?.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".jpg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".JPG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".jpeg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".JPEG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".png") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".PNG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".jfif") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : item?.client_file?.endsWith(".JFIF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.client_file_name)}
                                  onClick={() => downloadFile(item?.client_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.client_file_name)}</span>
                                </a>
                              ) : (
                                ""
                              )}

                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>
                        </>
                      )}
                      {/* --Client Comment Section End-- */}

                      {/* --Editor Comment Section Start-- */}
                      {item?.editor_comment != "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>
                            {item?.editor_designation}
                          </p>
                          <p dangerouslySetInnerHTML={{ __html: item?.editor_comment == "undefined" ? "" : item?.editor_comment }} />
                        </>
                      )}

                      {item?.editor_file != "https://elitereviser.com/serversite/public/uploads/" && item?.editor_comment == "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>
                            {item?.editor_designation}
                          </p>
                          <ul className="showAttachFileList">
                            <li>
                              {item?.editor_file?.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".jpg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".JPG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".jpeg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".JPEG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".png") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".PNG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".jfif") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : item?.editor_file?.endsWith(".JFIF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.editor_file)}
                                  onClick={() => downloadFile(item?.editor_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.editor_file_name)}</span>
                                </a>
                              ) : (
                                ""
                              )}

                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>
                        </>
                      )}

                      {item?.editor_file != "https://elitereviser.com/serversite/public/uploads/" && item?.editor_comment != "" && (
                        <ul className="showAttachFileList">
                          <li>
                            {item?.editor_file?.endsWith(".pdf") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".PDF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".docx") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".DOCX") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".jpg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".JPG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".jpeg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".JPEG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".png") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".PNG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".jfif") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : item?.editor_file?.endsWith(".JFIF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.editor_file)}
                                onClick={() => downloadFile(item?.editor_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.editor_file_name)}</span>
                              </a>
                            ) : (
                              ""
                            )}

                            {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>
                      )}
                      {/* --Editor Comment Section End-- */}

                      {/* --Admin Comment Section Start-- */}
                      {item?.admin_comment != "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.admin_name)}</span>
                            {item?.admin_designation}
                          </p>
                          <p dangerouslySetInnerHTML={{ __html: item?.admin_comment == "undefined" ? "" : item?.admin_comment }} />
                        </>
                      )}

                      {item?.admin_file != "https://elitereviser.com/serversite/public/uploads/" && item?.admin_comment == "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.admin_name)}</span>
                            {item?.admin_designation}
                          </p>
                          <ul className="showAttachFileList">
                            <li>
                              {item?.admin_file?.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".jpg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".JPG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".jpeg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".JPEG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".png") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".PNG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".jfif") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : item?.admin_file?.endsWith(".JFIF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.admin_file_name)}
                                  onClick={() => downloadFile(item?.admin_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.admin_file_name)}</span>
                                </a>
                              ) : (
                                ""
                              )}

                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>
                        </>
                      )}

                      {item?.admin_file != "https://elitereviser.com/serversite/public/uploads/" && item?.admin_comment != "" && (
                        <ul className="showAttachFileList">
                          <li>
                            {item?.admin_file?.endsWith(".pdf") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".PDF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".docx") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".DOCX") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".jpg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".JPG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".jpeg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".JPEG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".png") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".PNG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".jfif") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : item?.admin_file?.endsWith(".JFIF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.admin_file_name)}
                                onClick={() => downloadFile(item?.admin_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.admin_file_name)}</span>
                              </a>
                            ) : (
                              ""
                            )}

                            {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>
                      )}
                      {/* --Admin Comment Section End-- */}

                      {/* --Internal Comment Section Start-- */}
                      {item?.internal_comment != "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.internal_name)}</span>
                            {item?.internal_designation}
                          </p>
                          <p dangerouslySetInnerHTML={{ __html: item?.internal_comment == "undefined" ? "" : item?.internal_comment }} />
                        </>
                      )}

                      {item?.internal_file != "https://elitereviser.com/serversite/public/uploads/" && item?.internal_comment == "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.internal_name)}</span>
                            {item?.internal_designation}
                          </p>
                          <ul className="showAttachFileList">
                            <li>
                              {item?.internal_file?.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".jpg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".JPG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".jpeg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".JPEG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".png") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".PNG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".jfif") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : item?.internal_file?.endsWith(".JFIF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.internal_file_name)}
                                  onClick={() => downloadFile(item?.internal_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.internal_file_name)}</span>
                                </a>
                              ) : (
                                ""
                              )}

                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>
                        </>
                      )}

                      {item?.internal_file != "https://elitereviser.com/serversite/public/uploads/" && item?.internal_comment != "" && (
                        <ul className="showAttachFileList">
                          <li>
                            {item?.internal_file?.endsWith(".pdf") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".PDF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".docx") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".DOCX") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".jpg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".JPG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".jpeg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".JPEG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".png") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".PNG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".jfif") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : item?.internal_file?.endsWith(".JFIF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.internal_file_name)}
                                onClick={() => downloadFile(item?.internal_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.internal_file_name)}</span>
                              </a>
                            ) : (
                              ""
                            )}

                            {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>
                      )}
                      {/* --Internal Comment Section End-- */}

                      {/* --Deputy Comment Section Start-- */}
                      {item?.deputy_comment != "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>
                            {item?.deputy_designation}
                          </p>
                          <p dangerouslySetInnerHTML={{ __html: item?.deputy_comment == "undefined" ? "" : item?.deputy_comment }} />
                        </>
                      )}

                      {item?.deputy_file != "https://elitereviser.com/serversite/public/uploads/" && item?.deputy_comment == "" && (
                        <>
                          <p className="AuthorName">
                            <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>
                            {item?.deputy_designation}
                          </p>
                          <ul className="showAttachFileList">
                            <li>
                              {item?.deputy_file?.endsWith(".pdf") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".PDF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={pdfImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".docx") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".DOCX") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={docImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".jpg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".JPG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".jpeg") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".JPEG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".png") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".PNG") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".jfif") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : item?.deputy_file?.endsWith(".JFIF") ? (
                                <a
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={shortCommentFileName(item?.deputy_file_name)}
                                  onClick={() => downloadFile(item?.deputy_file)}
                                  className=" icon-span anchor-link service-file-link"
                                >
                                  <img src={iconImg} alt="img" />
                                  <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                                </a>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </>
                      )}

                      {item?.deputy_file != "https://elitereviser.com/serversite/public/uploads/" && item?.deputy_comment != "" && (
                        <ul className="showAttachFileList">
                          <li>
                            {item?.deputy_file?.endsWith(".pdf") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".PDF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={pdfImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".docx") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".DOCX") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={docImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".jpg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".JPG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".jpeg") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".JPEG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".png") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".PNG") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".jfif") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : item?.deputy_file?.endsWith(".JFIF") ? (
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title={shortCommentFileName(item?.deputy_file_name)}
                                onClick={() => downloadFile(item?.deputy_file)}
                                className=" icon-span anchor-link service-file-link"
                              >
                                <img src={iconImg} alt="img" />
                                <span>{shortCommentFileName(item?.deputy_file_name)}</span>
                              </a>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      )}
                      {/* --Deputy Comment Section End-- */}

                      <div className="RhtDate">
                        <span>
                          {get_date_format(item?.created_at)}
                          <br />
                          {get_time_format(item?.created_at)}
                        </span>
                      </div>
                    </div>
                  );
                })}

              {/* Comment Section End */}
            </div>
          </div>
        </div>
      </main>
      <Modal show={markComplete} onHide={markClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Rate your experience and quality of work</p>
          <p>
            <img src={feedbackStarImg} alt="" />
          </p>
          <a class="CmnBtn">
            <i class="bi bi-check-circle"></i> Mark Complete
          </a>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Feedback</h2>
          <p>Give some feedback about your experience with our service to the admin</p>
          <textarea name="" cols="" rows="" placeholder="Type here..."></textarea>
          <label className="radio inline">
            <input defaultChecked name="lost-password" defaultValue type="radio" />
            <span>Send to all</span>
          </label>
          <label className="radio inline">
            <input name="lost-password" defaultValue type="radio" />
            <span>Send to only admin</span>
          </label>
          <a class="CmnBtn">
            <i class="bi bi-check-circle"></i> Send Feedback
          </a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>
            *You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when
            you cancel order after 30 min of submission there is no full refund.
          </p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>
            YES
          </a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrderForm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>
            <span>*</span>You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to
            understand that when you cancel order after 30 min of submission there is no full refund.
          </p>
          <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" />
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..."></textarea>
          <a class="CmnBtn">
            <i class="bi bi-check-circle"></i> Send Request Oder Cancellation
          </a>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={modalclose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Select Editor</h2>
          <>
            <select
              name="subservice"
              onChange={(e) => {
                assignEditor(e.target.value);
                modalclose();
              }}
            >
              <option value="null">--Select Editor--</option>
              {showEditorList &&
                showEditorList.map((item, index) => {
                  return (
                    <option value={item?.id}>
                      {item?.first_name} {item?.last_name}
                    </option>
                  );
                })}
            </select>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminCompletedJobs;
