import React, { Component, useState, useEffect, useRef  } from "react";
import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { Rating } from 'react-simple-star-rating';
import { DateTime } from 'luxon';
import { get_date_format } from '../../utility/Common';

const reviewIcon = require("../../assets/img/ReviewIcon.png");
const docFileImg = require("../../assets/img/DocFileImg.png");
const attachFileIcon = require("../../assets/img/attach-icon.png");
const linkIcon = require("../../assets/img/link-icon.png");
const feedbackStarImg = require("../../assets/img/feedbackStar.png");

const InternalEditorFeedback = () => {
  const windowRef = useRef();

  const timeZone = DateTime.local().zoneName;
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showFeedback,setShowFeedback] = useState([]); // all feedbacks
  const [showFeedbackDetails,setShowFeedbackDetails] = useState() ; // feedback details by id
  const [showRating,setShowRating] = useState(); // set show rating
  const [showShortName,setShortName] = useState(); //Short name
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  // const [rating, setRating] = useState(showRating);
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }

  useEffect(() => {
    getAllFeedbacks();
}, []);

useEffect(() => {
  const checkIfClickedOutside = e => {
    if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
    {
      setIsWindowOpen(false)
      closeEditorfeedbackPnl();
    }
    else
    {
      console.log('here')
      console.log(isWindowOpen)
      console.log(windowRef.current)
    }
  }
  document.addEventListener("mousedown", checkIfClickedOutside)

  return () => {
    document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
  }
}, [isWindowOpen])


const openEditorfeedbackPnl = () => { 
  setIsWindowOpen(true);
  $(".CompletedPnl").addClass("Act");
};
const closeEditorfeedbackPnl = () => { 
  $(".CompletedPnl").removeClass("Act");
};

    //short comment
    const shortComment = (str) =>{
      return str.length > 45 ? str.substring(0, 45) + "........" : str;
    }
    
    //get all feedbacks
    const getAllFeedbacks= async() =>{
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}feedback/getallfeedback?UserTimeZone=${timeZone}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              console.log(apiRes.data.data);
              setShowFeedback(apiRes.data.data);
            }
        }
      } //close try
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }

    //fetch feedback details with job & cv
    const getFeedbackDetailsById= async(id,job_type) =>{
      // alert(job_type);
      if(job_type === 'type1')
      {
        try
        {
          let apiRes = await axios.get(`${API_BASE_URL}feedback/feedbackbyjob?job_id=${id}`)
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                let short_split_name = apiRes.data.data[0].user_name.split(' ');
                let split_fname = short_split_name[0].slice(0,1);
                let split_lname = short_split_name[1].slice(0,1);
                let full_short_name = split_fname + split_lname;
                // console.log(split_fname + ' ' + split_lname);
                console.log(apiRes.data.data);
                setShowFeedbackDetails(apiRes.data.data[0]);
                setShowRating(parseInt(apiRes.data.data[1]?.rating));
                setShortName(full_short_name);
              }
          }
        } //close try
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
      }
      else if(job_type === 'type2')
      {
        try
        {
          // let apiRes = await axios.get(`${API_BASE_URL}feedback/feedbackbycvresume?job_id=${id}`)
          let apiRes = await axios.get(`${API_BASE_URL}feedback/feedbackbyjob?job_id=${id}`)
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                let short_split_name = apiRes.data.data[0].user_name.split(' ');
                let split_fname = short_split_name[0].slice(0,1);
                let split_lname = short_split_name[1].slice(0,1);
                let full_short_name = split_fname + split_lname;
                // console.log(split_fname + ' ' + split_lname);
                console.log(apiRes.data.data);
                setShowFeedbackDetails(apiRes.data.data[0]);
                setShowRating(parseInt(apiRes.data.data[1]?.rating));
                setShortName(full_short_name);
              }
          }
        } //close try
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
      }
    }

  return (
    <div>
      <InternalEditorHeader />
      <InternalEditorSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            {/* <span className="NameLogo">sw</span> */}
            <h2>Feedback</h2>
          </span>
          {/* <div className="dropdown">
            <button type="button" className="dropdown-toggle" data-toggle="dropdown">
              All Feeback
            </button>
            <div className="dropdown-menu">
              <a className="dropdown-item" href="#">Link 1</a>
              <a className="dropdown-item" href="#">Link 2</a>
              <a className="dropdown-item" href="#">Link 3</a>
            </div>
          </div> */}
        </div>
        <section className="section OdrDtlsPnl">
          <div className="FeedbackTableSec">
            <table width="100%" className="CmnTbl feedbackTable table-striped blue-header-table">
              <thead>
                <tr>
                  {/* <th>Client Name</th> */}
                  <th style={{whiteSpace: "nowrap"}}>Job #</th>
                  <th>Task Name</th>
                  {/* <th>Word Count/Page No.</th>  */}
                  <th>WC/Pages</th> 
                  {/* <th>Feedback Date</th> */}
                  <th>Date</th>
                  {/* <th>Name</th> */}
                  <th style={{textAlign:'center'}}>Feedback</th>
                  <th>Rating</th>
                </tr>
              </thead>
              <tbody>
                {showFeedback && showFeedback.length > 0 ?
                showFeedback && showFeedback.map((item,index)=>{
                  return(
                    <tr>
                      {/* <td>Sam Williuam</td>
                      <td><p onClick={openEditorfeedbackPnl}>WRITER  |  Book  |  Word Count 2,000-3,999  |  Expedited Service...</p></td>
                      <td>Dec 15, 2022</td> */}
                      {/* <td>{item?.user_name}</td> */}
                      <td>{item?.job_id}</td>
                      {/* <td><p onClick={()=>{openEditorfeedbackPnl();getFeedbackDetailsById(item?.job_id,item?.job_type)}}>{item?.job_title}</p></td> */}
                      <td><p onClick={()=>{openEditorfeedbackPnl();getFeedbackDetailsById(item?.job_id,item?.job_type)}}>{item?.job_title}</p></td>
                      <td className="text-center">{item?.job_type==='type1'? item?.total_wordcount:item?.number_of_pages}</td>
                      <td className="text-center"><span class="GreenTxt">{get_date_format(item?.created_at)}</span></td>
                      {/* <td>{item?.editor_name}</td> */}
                      {/* <td>{item?.comment}</td> */}
                      <td>{shortComment(item?.comment)}</td>
                      <td><div className="common-star-rating"><Rating initialValue={item?.rating} readonly/></div></td>
                    </tr>
                  )
                }):(
                  <tr>
                    <td className='no-data' colSpan={6} style={{background: "none"}}><p style={{textAlign:'center'}}>No Feedbacks Found</p></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
        {/* left Complete Pannel */}
        <div className="DtlsSectionWrapper CompletedPnl editorfeedbackPnl" ref={windowRef}>
            <div className="TaskDtlsPnl">
              <div className="MiddleBody">
                <a onClick={closeEditorfeedbackPnl} className="CloseBtn">
                    <i className="bi bi-x" />
                  </a>
                <h2>
                  {/* #84 | WRITER | Book | Word Count 2,000-3,999 | Expedited
                  Service [32hrs] */}
                  {showFeedbackDetails?.job_title}
                </h2>
                <table className="DtlsDescrpTbl">
                  <tbody>
                    {/* <tr>
                      <td>Client</td>
                      <td>
                        <span className="NameLogo">{showShortName}</span> <span className="name-span">{showFeedbackDetails?.user_name}</span>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
                <span className="message-span">
                  {/* {showFeedbackDetails?.send_option==='0' ?'Visible to All':'Visible only to Admin,Deputy-Admin and Internal-Editor'} */}
                  {showFeedbackDetails?.send_option=='2' ?'Send to All':'Send to Elite Reviser'}
                </span>
                <h3>Client’s Feedback</h3>
                {/* <h5>Dec 10, 2022</h5> */}
                <h5>
                  <span className="GreenTxt">{get_date_format(showFeedbackDetails?.created_at)}</span>
                </h5>
                <span className="client-rating">
                  <Rating initialValue={showRating} readonly/>
                  {/* <i class="bi bi-star-fill"></i> 
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-half"></i> */}
                </span>
                {/* <p dangerouslySetInnerHTML={{__html: subsec.EditorTextMessages}} /> */}
                <p dangerouslySetInnerHTML={{__html: showFeedbackDetails?.comment}}></p>
              </div>
              {/* <div className="BtmFtr">
                <span class="editorNameLogo">MW</span>
                <textarea cols="" rows=""></textarea>
                <input type="button" value="Submit" className="btn btn-primary CmnBtn" />
                <p>Note: <span>*</span>Admin will be just send feedback reply to only admin</p>
              </div> */}
            </div>
        </div>
      </main>
      <Modal show={markComplete} onHide={markClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Rate your experience and quality of work</p>
          <p><img src={feedbackStarImg} alt="" /></p>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Mark Complete</a>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Feedback</h2>
          <p>Give some feedback about your experience with our service to the admin</p>
          <textarea name="" cols="" rows="" placeholder="Type here..."></textarea>
          <label className="radio inline">
            <input defaultChecked name="lost-password" defaultValue type="radio" />
            <span>Send to all</span>
          </label>
          <label className="radio inline">
            <input name="lost-password" defaultValue type="radio" />
            <span>Send to only admin</span>
          </label>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Feedback</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>*You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when you cancel order after 30 min of submission there is no full refund.</p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrderForm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p><span>*</span>You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when you cancel order after 30 min of submission there is no full refund.</p>
          <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" />
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..."></textarea>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InternalEditorFeedback;
