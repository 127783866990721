import React, { Component,useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from "react-bootstrap/Button";
import {NotificationManager} from 'react-notifications'; 

const DeputyEditPassword = () => {
  const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const [deputyData,setDeputyData] = useState();
    const [loading,setLoading] = useState(false); 
    useEffect(() => {
        console.log(data);
        // console.log(data.first_name.charAt(0))
        getDeputyInfos();
    }, []);

    // get admin information
    const getDeputyInfos = async () => {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              // console.log(apiRes.data.data);
              setDeputyData(apiRes.data.data[0]);
            }
        }
      }
      catch (error)
      {
        // NotificationManager.error(error.message, "", 2000);
      }
  };

  //update password
  const adminUpdatePassword = async(values,{resetForm}) =>{
    console.log(values);
      try
      {
          setLoading(true);
          let apiRes = await(
              axios
              .post(
              `${API_BASE_URL}account/changepassword`,{
                  id: data.id,
                  old_password: values.old_password,
                  new_password: values.new_password,
                  confirm_password: values.confirm_password,
              },
            )
          )
          if(apiRes)
          {
              console.log(apiRes.data.res)
              if(apiRes.data.res == true) 
              {
                  NotificationManager.success('Password Updated Successfully','',2000);
                  setLoading(false);
                  navigate("/internal-editor-my-account");
              }
              else
              {
                  setLoading(false);
                  NotificationManager.error(apiRes.data.msg, '',2000);
              }
          }
    }
    catch (error) 
    {
          // NotificationManager.error(error.message, '',2000);
    } 
  }

  let schema = yup.object().shape({
  
    old_password: yup
    .string()
    // .ensure()
    .required('Old Password is required')
    .min(6,'Old Password must be at least 6 characters')
    .max(20,'Old Password must be at most 20 characters'),

    new_password: yup
    .string()
    // .ensure()
    .required('New Password is required')
    .min(6,'New Password must be at least 6 characters')
    .max(20,'New Password must be at most 20 characters'),

    confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .min(6,'Confirm Password must be at least 6 characters')
    .max(20,'Confirm Password must be at most 20 characters')
    .oneOf([yup.ref('new_password'),null],'Passwords did not match')
}); 

  return (
    <div>
      <DeputyAdminHeader />
      <DeputyAdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <span className="NameLogo">{deputyData?.first_name?.charAt(0)}{deputyData?.last_name?.charAt(0)}</span>
            <h2>Change Password</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
          {/* <div className="AccBox">
            <div className="pagetitle">
              <h3>Personal Details</h3>
              <Link 
                to = {`/admin-my-account/admin-edit-account/${deputyData?.id}`}
                className="btn btn-primary CmnBtn"
              > 
                <i className="bi bi-pencil" /> Edit Account      
              </Link>
            </div>
            <ul className="AccDtls row">
              <li className="col-md-3">
                <label>First Name</label>
                <p className="InputTxt">{deputyData?.first_name}</p>
              </li>
              <li className="col-md-3">
                <label>Last Name</label>
                <p className="InputTxt">{deputyData?.last_name}</p>
              </li>
              <li className="col-md-3">
                <label>Phone Number</label>
                <p className="InputTxt">{deputyData?.ph_no}</p>
              </li>
              <li className="col-md-3">
                <label>Email Address</label>
                <p className="InputTxt">{deputyData?.email_address}</p>
              </li>
              <li className="col-md-3">
                <label>Address</label>
                <p className="InputTxt">{deputyData?.address}</p>
              </li>
              <li className="col-md-3">
                <label>Role</label>
                  <p className="InputTxt">
                    {
                      deputyData?.role == '0' ? 'Administrator':
                      deputyData?.role == '1' ? 'Editor':
                      deputyData?.role == '2' ? 'Client':''
                    }
                  </p>
              </li>
              <li className="col-md-3">
                  <label>Organization</label>
                    <p className="InputTxt">{deputyData?.organization}</p>
              </li> 
              <li className="col-md-3">
                <label>ZIP Code</label>
                  <p className="InputTxt">{deputyData?.zipcode}</p>
              </li>
            </ul>
          </div> */}
          <Formik
              enableReinitialize
              initialValues={{
                id: data.id,
                old_password: '',
                new_password: '',
                confirm_password: '',
              }}
                  onSubmit={(values,{resetForm})=>{adminUpdatePassword(values,{resetForm})}}
                  validationSchema = {schema}
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        values, 
                        setFieldValue
                    })=>(
          <div className="AccBox">
            <div className="pagetitle">
              <h3>Password</h3>
              {/* <button className="btn btn-primary CmnBtn">
                <i className="bi bi-check-circle" /> Save
              </button> */}
              {loading && loading ? (
                <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i className="bi bi-check-circle" />Save</Button>
                ):(
                    <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Save</Button>
                  )
              }
            </div>
            <form action method="get">
              <ul className="AccDtls EditAccDtls EditAccPswd row">
              <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                <li className="col-md-4">
                  <input className="InptField" type="password" placeholder="Old Password" name="old_password" onChange={handleChange} value={values.old_password} />
                  {errors.old_password ? <div className='error'>{errors.old_password}</div> : null}
                </li>
                <li className="col-md-4">
                  <input className="InptField" type="password" placeholder="New Password" name="new_password" onChange={handleChange} value={values.new_password}/>
                  {errors.new_password ? <div className='error'>{errors.new_password}</div> : null}
                </li>
                <li className="col-md-4">
                  <input className="InptField" type="password" placeholder="Retype New Password" name="confirm_password" onChange={handleChange} value={values.confirm_password}/>
                  {errors.confirm_password ? <div className='error'>{errors.confirm_password}</div> : null}
                </li>
              </ul>
            </form>
          </div>
        )}
        </Formik>

        </section>
      </main>
    </div>
  );
};

export default DeputyEditPassword;
