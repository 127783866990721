import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const PasswordInput = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      <input
        type="text"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className="password-container">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="toggle-password" onClick={togglePasswordVisibility}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </span>
      </div>
      <button onClick={() => console.log('Email:', email, 'Password:', password)}>Login</button>
    </div>
  );
};

export default PasswordInput;


// import React, { useState } from 'react';

// const PasswordInput = () => {
//   const [base64Image, setBase64Image] = useState('');

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];

//     if (file) {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => {
//         setBase64Image(reader.result);
//       };
//       reader.onerror = (error) => {
//         console.error('Error: ', error);
//       };
//     }
//   };

//   return (
//     <div style={{ textAlign: 'center', marginTop: '50px' }}>
//       <h1>Image Uploader</h1>
//       <input type="file" accept="image/*" onChange={handleFileChange} />
//       {base64Image && (
//         <div style={{ marginTop: '20px' }}>
//           <h2>Preview:</h2>
//           <img src={base64Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default PasswordInput;

// import React, { useState } from 'react';

// function PasswordInput() {
//   const [base64String, setBase64String] = useState('');
//   const [imageSrc, setImageSrc] = useState('');

//   const handleInputChange = (event) => {
//     setBase64String(event.target.value);
//   };

//   const handleConvertClick = () => {
//     if (base64String) {
//       // Ensure the input is a valid Base64 string for an image
//       setImageSrc(`data:image/png;base64,${base64String}`);
//     }
//   };

//   return (
//     <div>
//       <h1>Base64 to Image Converter</h1>
//       <textarea
//         value={base64String}
//         onChange={handleInputChange}
//         placeholder="Paste your Base64 string here"
//         rows="10"
//         cols="50"
//       />
//       <br />
//       <button onClick={handleConvertClick}>Convert</button>
//       <br />
//       {imageSrc && <img src={imageSrc} alt="Converted Base64" />}
//     </div>
//   );
// }

// export default PasswordInput;




