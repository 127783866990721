import React, { Component, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';


const Clientsidebar = () => { 
    const navigate = useNavigate();
    const sidebarRef = useRef();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[clientData,setClientData] = useState();
    

//start
const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
      
      document.body.classList.add('toggle-sidebar');
    } else {
      setDeviceType("Desktop");
    }
  }, []);


//end


    useEffect(() => {
        // console.log(data)
        if (data=== null) 
        {
            navigate("/login");
            navigate(0);
        }
        // else if(data!= null && (data.role==1 && data.role==2)) 
        // {
        //     navigate("/admin-login");
        // }
        else
        {
            setClientData(data);
        }
       
    }, []);
    useEffect(() => {
        const currentUrl = window.location.href;
        const links = document.querySelectorAll("ul.sidebar-nav a");
    
        links.forEach((link) => {
          if (link.href === currentUrl) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        });
    
        // Cleanup function (optional)
        return () => {
          links.forEach((link) => {
            link.classList.remove("active");
          });
        };
    }, []);

    useEffect(() => {
        const select = (el, all = false) => {
          el = el.trim();
          if (all) {
            return [...document.querySelectorAll(el)];
          } else {
            return document.querySelector(el);
          }
        };
      
        const on = (type, el, listener, all = false) => {
          if (all) {
            select(el, all).forEach((e) => e.addEventListener(type, listener));
          } else {
            select(el, all).addEventListener(type, listener);
          }
        };
      
        const handleBodyClick = (e) => {
          // Check if the clicked element is outside the sidebar
          if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
            select('body').classList.add('toggle-sidebar');
          }
        };
      
        on('click', '.close-sidebar-btn', function (e) {
          select('body').classList.toggle('toggle-sidebar');
        });
      
        // Add the event listener to the document for body clicks
        // document.addEventListener('click', handleBodyClick);
      
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleBodyClick);
        };
      }, []);
      
    const LogoutFunction=async()=>
    {
        localStorage.removeItem("user_data_elite");
        localStorage.clear();
        googleLogout();
        navigate("/login");
    }
    return(
        <div>
            <aside id="sidebar" className="sidebar">
                <a className="CloseBtn close-sidebar-btn">
                    <i className="bi bi-x" />
                </a>
                <ul className="sidebar-nav" id="sidebar-nav">
                    {/* <li className="nav-item"><NavLink to="/client-my-account" className="nav-link"><span class="icon-span"><i className="bi bi-house" /></span> <span>My Account</span></NavLink></li> */}
                    <li className="nav-item"><NavLink to="/client-my-account" className="nav-link"><span class="icon-span"><i className="bi bi-person" /></span> <span>My Profile</span></NavLink></li>
                    <li className="nav-item"><NavLink to="/client-my-orders" className="nav-link collapsed"><span class="icon-span"><i className="bi bi-calendar4" /></span> <span>My Orders</span></NavLink></li>
                    <li className="nav-item"><NavLink to="/settings" className="nav-link collapsed"><span class="icon-span"><i className="bi bi-gear" /></span> <span>Settings</span></NavLink></li>
                    {/* <li className="nav-item no-link"><NavLink className="nav-link collapsed"><i className="bi bi-person" /> <span>Settings</span></NavLink></li> */}
                    <li className="nav-item admin-billing-details-item"><NavLink to="/billing-details" className="nav-link collapsed"><i className="bi bi-inbox" /> <span>Billing Details</span></NavLink></li>
                    {/* <li className="nav-item no-link"><NavLink  className="nav-link collapsed"><span class="icon-span"><i className="bi bi-inbox" /></span> <span>Billing Details</span></NavLink></li> */}
                    {/* <li className="nav-item"><NavLink to="/" className="nav-link collapsed"><i className="bi bi-inbox" /> <span>Logout</span></NavLink></li> */}
                    <li className="nav-item logout-item">
                        {/* <NavLink to="/" className="nav-link collapsed">
                            <span>Logout</span>
                        </NavLink> */}
                        <a href="javascript:void(0);" className="nav-link collapsed" onClick={()=>LogoutFunction()}>Sign Out</a>
                    </li>
                </ul>
            </aside>
            <div className="side-menu-overlay"></div>
        </div>
    );
};

export default Clientsidebar;