import React from 'react';
import { useCreditCardValidator, images } from 'react-creditcard-validator';

const CreditCardValidation = () => {
  function expDateValidate(month, year) {
    if (Number(year) > 2035) {
      return 'Expiry Date Year cannot be greater than 2035';
    }
    return;
  }

  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs }
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  return (
    <div>
      {/* <h1>React CreditCard Validator</h1> */}
      <h1>React Validator</h1>

      <div className="input-group">
        <svg {...getCardImageProps({ images })} />
        <label>Card Number</label>
        <input {...getCardNumberProps()} />
      </div>
      <small>{erroredInputs.cardNumber && erroredInputs.cardNumber}</small>

      <div className="multi-input">
        <div className="input-group">
          <label>Valid Till</label>
          <input {...getExpiryDateProps()} />
          <small>{erroredInputs.expiryDate && erroredInputs.expiryDate}</small>
        </div>

        <div className="input-group">
          <label>CVC</label>
          <input {...getCVCProps()} />
          <small>{erroredInputs.cvc && erroredInputs.cvc}</small>
        </div>
      </div>
    </div>
  );
};

export default CreditCardValidation;
