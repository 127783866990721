import {
  React, useEffect, useState,
  NavLink, useNavigate, Link,
  axios,
  NotificationManager,
  Modal
} from '../../utility/CommonImport'; // Import 

import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import { get_date_format } from '../../utility/Common';
import { API_BASE_URL} from '../../api_url';

const AdminClientManagement = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showClientList,setShowClientList] = useState([]); // for all clients
  const [statusType,setStatusType] = useState();
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }
  const [showModal, setShowModal] = useState(false);
  const modalclose  = () => setShowModal(false)
  const [buttonValue,setButtonValue] = useState({UserId:'',status:''});
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  
//To Inactive
const toInactive=async(UserId,status)=>{
  setStatusType('InActive');
  try
  {
    let apiRes = await axios.get(`${API_BASE_URL}account/changestatus?id=${UserId}&status=${status}`) 
    if (apiRes) 
    {
      if (apiRes.data.res == true) 
      {
        getAllClient();
        modalclose();
      }
      else
      {
        console.error(apiRes.data.res);
      }
    }
    else
    {
      console.error('Something Went Wrong!');
    }
  }
  catch (error)
  {
    console.error(error);
  }
}

//To active
const toActive=async(UserId,status)=>{
  setStatusType('InActive');
  try
  {
    let apiRes = await axios.get(`${API_BASE_URL}account/changestatus?id=${UserId}&status=${status}`) 
    if (apiRes) 
    {
      if (apiRes.data.res == true) 
      {
        getAllClient();
        modalclose();
      }
      else
      {
        console.error(apiRes.data.res);
      }
    }
    else
    {
      console.error('Something Went Wrong!');
    }
  }
  catch (error)
  {
    console.error(error);
  }
}

// get all clients
const getAllClient = async () => {
  try
  {
    let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=2`)
    if (apiRes) 
    {
      if (apiRes.data.res == true) 
      {
        setShowClientList(apiRes.data.data);
      }
      else
      {
        console.error(apiRes.data.res);
      }
    }
    else
    {
      console.error('Something WentWrong!');
    }
  }
  catch (error)
  {
    console.error(error);
  }
};

// delete Editor
const deleteClient = async (user_id) => {
  if (window.confirm("Are you sure you want to delete?"))
  { 
    try
    {
      let apiRes = await axios.post(`${API_BASE_URL}account/deleteaccount`,{id: user_id})
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          getAllClient();
          NotificationManager.success('Successfully Deleted','',2000);
          navigate("/admin-client-management");
        }
        else
        {
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }
}

useEffect(() => {
  getAllClient();
}, []);

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle pagetitle2">
          <span className="TitleWrap">
            <h2>Client Management</h2>
          </span>
            <div className="pagetitle">
              <NavLink to="/adding/signing-up" className="btn btn-primary CmnBtn">
                Add Client
              </NavLink>
            </div>
        </div>
        <section className="section OdrDtlsPnl OdrDtlsPnlNew" style={{marginTop:'-35px'}}>
          <div className="complete-table-sec">
            <table width="100%" className="CmnTbl myJobTable admin-editor-managment table-striped blue-header-table admin-management-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th className="text-center">Date Added</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">Phone No.</th>
                  <th>Address</th>
                  <th className="text-center">City</th>
                  <th className="text-center">State</th>
                  <th className="text-center">Zip Code</th>
                  <th className="text-center">Country</th>
                  <th className="text-center">Status</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {showClientList?.length > 0 ? (
                  showClientList?.map((list,index)=>{
                    return(
                      <tr className="not-set-job">
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{list?.first_name} {list?.last_name}</td>
                        <td className="text-center">{list?.role =='2' ? 'Client':''}</td>
                        <td className="text-center">
                          <span className="GreenTxt">{get_date_format(list?.created_at)}</span>
                        </td>
                        <td className="text-center">{list?.email_address}</td>
                        <td className="text-center">{list?.ph_no}</td>
                        <td className="text-center">{list?.address}</td>
                        <td className="text-center">{list?.city!=null ? list?.city : '---'}</td>
                        <td className="text-center">{list?.state}</td>
                        <td className="text-center">{list?.zipcode}</td>
                        <td className="text-center">{list?.country}</td>
                        <td className="text-center">
                          {list.status=='1'?
                            <a onClick={()=>{setShowModal(true);
                              setButtonValue(
                                {
                                  UserId:list.id,
                                  status:0
                                });
                                setStatusType('Inactive');
                            }}>
                            <span class="badge bg-active-green" style={{cursor:'pointer'}}>Active</span></a>: <a onClick={()=>{
                              setShowModal(true);
                              setButtonValue(
                                {
                                  UserId:list.id,
                                  status:1
                                });
                                setStatusType('Active');
                            }}>
                            <span class="badge bg-light-red" style={{cursor:'pointer'}}>Inactive</span></a>
                          }
                        </td>
                        <td>
                          <Link to = {`/admin-client-management/view-client/${list?.id}`}> 
                          <i class="bi bi-eye"></i>       
                          </Link>
                        </td>

                        <td>
                          <Link to = {`/admin-client-management/edit-client/${list?.id}`}> 
                          <i class="bi bi-pen"></i>       
                          </Link>
                        </td>

                        <td><i class="bi bi-trash" onClick={()=>deleteClient(list?.id)}></i></td>
                      </tr>
                    )
                  })
                ):(
                  <tr>
                    <td className='no-data' colSpan={15} style={{background: "none"}}><p style={{textAlign:'center'}}>No Record Found.</p></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>

      <Modal show={showModal}  onHide={modalclose} className="CmnModal changeStatusModal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure to change status to {statusType}?</h3>
          {statusType && statusType!="Active"?(
            <button onClick={()=>toActive(buttonValue.UserId,buttonValue.status)} className="CmnBtn yesBtn">
              {statusType}
            </button>
          ):(
            <button onClick={()=>toInactive(buttonValue.UserId,buttonValue.status)} className="CmnBtn yesBtn">
              {statusType}
            </button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminClientManagement;
