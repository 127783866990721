import React, { Component, useState, useEffect  } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { TypeAnimation } from "react-type-animation";
import Carousel from 'react-bootstrap/Carousel';
import Marquee from "react-fast-marquee";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { SplashScreen } from "../LoadingPage/SplashScreen";

const homeBanner = require("../../assets/img/home-banner-new.jpg");
const bannerImg = require("../../assets/img/home-banner-img.png");
const marqueeImg = require("../../assets/img/elite-transparent-logo.svg").default;
const brandImg1 = require("../../assets/img/brand-img1.png");
const brandImg2 = require("../../assets/img/brand-img2.png");
const brandImg3 = require("../../assets/img/brand-img3.png");
const brandImg4 = require("../../assets/img/brand-img4.png");
const brandImg5 = require("../../assets/img/brand-img5.png");
const brandImg6 = require("../../assets/img/brand-img6.png");
const brandImg7 = require("../../assets/img/brand-img7.png");
const homeBottomImg = require("../../assets/img/home-bottom-img-new.png");
const carouselImg = require("../../assets/img/carousel-img.jpg");
const submitIcon = require("../../assets/img/submit-icon.png");
const submitActiveIcon = require("../../assets/img/submit-active-icon.png");
const editorIcon = require("../../assets/img/editor-icon.png");
const editorActiveIcon = require("../../assets/img/editor-active-icon.png");
const workCompletedIcon = require("../../assets/img/work-completed-icon.png");
const workCompletedActiveIcon = require("../../assets/img/work-completed-active-icon.png");
const qualityEvaluatedIcon = require("../../assets/img/quality-evaluated-icon.png");
const qualityEvaluatedActiveIcon = require("../../assets/img/quality-evaluated-active-icon.png");

const Home = () => {
  const navigate = useNavigate();
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  const [showServices,setShowServices] = useState([]); // For all service
  const [splaceLoading,setIsSplaceLoading] = useState(true);

  useEffect(() => {
    AOS.init();
  });
  useEffect(() => 
  {
    getAllServices();
  }, []);

//   useEffect(() => {
//     setTimeout(() => {
//         setIsSplaceLoading(false);
//     }, 3000);
// }, []);

  useEffect(() => {
    // if (data=== null)
    // {
    //     navigate("/login");
    //     navigate(0);
    // }

    // if(data!=null && (data?.role == '0'))
    // {
    //   navigate("/admin-job-board");
    // }
    // else if(data!=null && (data?.role == '1'))
    // {
    //   navigate("/editor-job-board");
    // }
    // else if(data!=null && (data?.role == '3'))
    // {
    //   navigate("/deputy-admin-job-board");
    // }
    // else if(data!=null && (data?.role == '4'))
    // {
    //   navigate("/internal-editor-job-board");
    // }
    if(data!=null && (data?.role == '1'))
    {
      navigate("/editor-job-board");
    }

  })

  
// Admin: 0    => /admin-job-board
// Editor: 1   => /editor-job-board
// Client: 2
// Sub Admin/Deputy Admin: 3    => /deputy-admin-job-board
// Internal Editor: 4   => /internal-editor-job-board



  const state = {
    responsive: {
      0: {
        items: 1,
        autoHeight: false,
      },
      450: {
        items: 1,
        autoHeight: false,
      },
      600: {
        items: 1,
        autoHeight: false,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  // get all services
  const getAllServices = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}service`)
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowServices(apiRes.data.data);
        }
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }

  return (
    <>
      {/* {!splaceLoading ? ( */}
        <div>
          <Header />
          <div className="home-banner" style={{backgroundImage: `url(${homeBanner})`}}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 banner-text-content">
                  <h2>
                    We Provide
                    <TypeAnimation
                      sequence={[
                        "CV/Resume Services",
                        1000,
                        "Editing Services",
                        1000,
                        "Proofreading Services",
                        1000,
                        () => {
                          console.log("Done typing!");
                        },
                      ]}
                      wrapper="div"
                      cursor={true}
                      repeat={Infinity}
                    />
                  </h2>
                  <p>Our services are all about quality, speed, and affordability. No matter what your needs are, our elite editors and proofreaders have all the diligence and expertise to turn your work into a masterpiece.</p>
                  <ul className="icon-list">
                    <li>Editing Services</li>
                    <li>Proofreading Services</li>
                    <li>Resume/CV Services</li>
                  </ul>
                  <NavLink className="btn btn-primary" to="/services">Place Order</NavLink>
                </div>
                <div className="col-lg-6 banner-img">
                  <img src={bannerImg} alt="img" />
                </div>
              </div>
            </div>
            <div className="banner-bottom-text">
              <div>
                <span><img src={marqueeImg} alt="img" /></span> <span><img src={marqueeImg} alt="img" /></span>
              </div>
            </div>
          </div>
          <div className="home-category-section">
            <h3 data-aos="fade-up">Choose Your Category And Get Started</h3>
            
            {/* {showServices && showServices.map((item,index)=>{
                return(
                  <div key = {index} className="item services1">
                    <div className="card-box" style={{backgroundImage: 'url()'}}>
                      <h4>{item?.title}</h4>
                      <p>{item?.short_description}</p>
                      <NavLink className="btn btn-primary" to="/services-new-client">Place Order</NavLink>
                    </div>
                  </div>
                )
              })} */}
              
        {showServices && showServices.length > 0 && (
        <>
          {/* <OwlCarousel className="owl-theme" loop nav={true} dots={false} autoplay={true} autoHeight={true} center={true} responsive={state.responsive}> */}
          <OwlCarousel className="owl-theme" loop nav={true} dots={false} autoplay={true} autoHeight={true}  center={true} responsive={state.responsive}>
            {showServices && showServices?.map((item,index)=>{
              return(
                <div className={`item services${index}`}>
                  {/* backgroundImage: `url(${(item.bg_image && item.bg_image)})` */}
                  <div className="card-box" style={{backgroundImage:`url(${(item?.bg_image)})`}}>
                    <h4>{item?.title}</h4>
                    <p>{item?.short_description}</p>
                    {/* <NavLink className="btn btn-primary" to="/services-new-client">Place Order</NavLink> */}
                    {/* /:id/:name */}
                    <Link  className="btn btn-primary"
                      // to = {`/services-new-client/${item?.id}/${item?.title}`}
                      // to = {`/service/${item?.id}`}
                        to={{pathname: `/service/${item?.title.toLowerCase()}`}}
                        // state={{ id: item?.id }}
                    >  
                      Place Order
                    </Link>
    
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
        </>
      )}
          
        </div>
          <div className="home-process-section">
            <div className="container">
              <h3 data-aos="fade-up">How Our Process Works</h3>
              <p>Let our team of expert proofreaders and editors help you revise your work to make it a masterpiece.</p>
              <div className="d-none d-lg-block d-xl-block">
                <Carousel fade>
                  <Carousel.Item>
                    <div className="items-box">
                      <div className="row align-items-center">
                        <div className="col-md-8 text-sec">
                          <Carousel.Caption>
                            <h3>Submit your work</h3>
                            <p>When you upload your work on our website and make the appropriate payment, your order will be placed.</p>
                          </Carousel.Caption>
                        </div>
                        <div className="col-md-4 img-sec"><img className="" src={submitActiveIcon} alt="First slide" /></div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="items-box">
                      <div className="row align-items-center">
                        <div className="col-md-8 text-sec">
                          <Carousel.Caption>
                            <h3>An editor starts editing</h3>
                            <p>Once your order is placed, an editor will  start editing your work for grammar,  accuracy, and articulation.</p>
                          </Carousel.Caption>
                        </div>
                        <div className="col-md-4 img-sec"><img className="" src={editorActiveIcon} alt="Second slide" /></div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="items-box">
                    <div className="row align-items-center">
                        <div className="col-md-8 text-sec">
                          <Carousel.Caption>
                            <h3>Quality is evaluated</h3>
                            <p>At this point, your work is given additional  review by an expert to ensure that the work  is a masterpiece.</p>
                          </Carousel.Caption>
                        </div>
                        <div className="col-md-4 img-sec"><img className="" src={qualityEvaluatedActiveIcon} alt="Third slide" /></div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="items-box">
                    <div className="row align-items-center">
                        <div className="col-md-8 text-sec">
                          <Carousel.Caption>
                            <h3>Your work is completed</h3>
                            <p>Once your work is completed, you will be  notified to download it for your review and  approval.</p>
                          </Carousel.Caption>
                        </div>
                        <div className="col-md-4 img-sec"><img className="" src={workCompletedActiveIcon} alt="Third slide" /></div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel> 
              </div>
              <div className="row d-flex d-lg-none mobile-processing-work">
                <div className="col-12 col-md-6 text-content">
                  <div className="cardBox">
                    <div className="imgBox">
                      <img className="" src={submitActiveIcon} alt="Third slide" />
                    </div>
                    <h3>Submit your work</h3>
                    <p>When you upload your work on our website and make the appropriate payment, your order will be placed.</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 text-content">
                  <div className="cardBox">
                    <div className="imgBox">
                      <img className="" src={editorActiveIcon} alt="Third slide" />
                    </div>
                    <h3>An editor starts editing</h3>
                    <p>Once your order is placed, an editor will  start editing your work for grammar,  accuracy, and articulation.</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 text-content">
                  <div className="cardBox">
                    <div className="imgBox">
                      <img className="" src={qualityEvaluatedActiveIcon} alt="Third slide" />
                    </div>
                    <h3>Quality is evaluated</h3>
                    <p>At this point, your work is given additional  review by an expert to ensure that the work  is a masterpiece.</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 text-content">
                  <div className="cardBox">
                    <div className="imgBox">
                      <img className="" src={workCompletedActiveIcon} alt="Third slide" />
                    </div>
                    <h3>Your work is completed</h3>
                    <p>Once your work is completed, you will be  notified to download it for your review and  approval.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-yellow-section align-items-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                  <h3>Still Not Sure What Service Is Right For You?</h3>
                  <p>
                    <NavLink to="/">Elite Reviser</NavLink> can handle that.
                    <NavLink to="/contact"> Contact Us.</NavLink>
                  </p>
                </div>
                <div
                  className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                  <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                </div>
              </div>
            </div>
          </div>
    
          <div className="home-bottom-section">
            <div className="container">
              <h3 data-aos="fade-up">Resume Building</h3>
              <div className="row">
                <div className="col-lg-5 img-section" data-aos="fade-right">
                  <img src={homeBottomImg} alt="" />
                </div>
                <div className="col-lg-7 text-section" data-aos="fade-left">
                  {/* <p>Do you want a Resume or CV built from scratch?</p> */}
                  {/* <p>We can handle that. We believe your dream job needs a perfect resume or CV.</p> */}
                  {/* <p>How your Resume/CV appears means a lot to the hiring managers; so, let us take care of that for you.</p> */}
                    <p>Do you want a resume built from scratch? We can handle that for you.  We believe your dream job deserves a perfect resume. How your resume appears says a lot about you to the hiring managers; so, let us take care of that for you.</p>
                    <p><strong>Start your journey into a perfect career today with a perfect resume. What are you waiting for?</strong></p>
                  {/* <NavLink to="/services-choose-cv" className="blue-link">
                    <span className="small-text-span">Start your journey into a perfect career</span>
                    <span className="bold-text-span">today with a perfect resume/CV.</span>
                  </NavLink> */}
                  <NavLink className="btn btn-primary" to="/services-choose-cv">Get Started</NavLink>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      {/* ):(
          <SplashScreen/>
        <></>
      )} */}
    </>

  );
};

export default Home;
