import React, { Component,useEffect, useState } from "react";
import { NavLink, useNavigate, Link , useParams } from "react-router-dom";
import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const ViewEditor = () => {
    const {id} = useParams();
    console.log(id);
    const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const [loading,setLoading] = useState(false); 
    const [showEditorData,setShowEditorData] = useState(); // for editor info
    useEffect(() => {
        console.log(data);
        // console.log(data.first_name.charAt(0))
        getAdminInfos();
    }, []);

     // get admin information
    const getAdminInfos = async () => {
        try {
        let apiRes = await axios.get(`${API_BASE_URL}account?id=${id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
            // console.log(apiRes.data.data);
            setShowEditorData(apiRes.data.data[0]);
            }
        }
        } catch (error) {
        //close try
        // NotificationManager.error(error, "", 2000);
        }
    };

    // get editor infos
  const getEditorInfos = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${showEditorData?.id}`) 
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          // console.log(apiRes.data.data);
          setShowEditorData(apiRes.data.data[0]);
        }
      }
    } catch (error) {
      //close try
    //   NotificationManager.error(error, "", 2000);
    }
  };

  const updateEditorData = async(values,{resetForm}) =>{
      console.log(values);
        try {
            setLoading(true);
            let apiRes = await(
                axios
                .post(
                `${API_BASE_URL}account/editaccount`,{
                    id: values.id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    ph_no: values.ph_no.toString(),
                    email_address: values.email_address,
                    // organization: values.organization,
                    address: values.address,
                    city: values.city,
                    state: values.state,
                    zipcode: values.zipcode,
                    country: values.country,
                    password: values.password,
                    assigned_number_of_job: values.assigned_number_of_job,
                    designation : values.designation,
                    role: '1'  // 1 for editor
                    // role: values?.designation==='Editor' ?'1':'4'  // 1 for editor 4 for Internal Editor
                },
            )
            )
            if(apiRes)
            {
                console.log(apiRes.data.res)
                if(apiRes.data.res == true) 
                {
                    NotificationManager.success('Update Successfully','',2000);
                    setLoading(false);
                    navigate("/admin-editor-management");
                    // resetForm({values:''})
                }else
                {
                    setLoading(false);
                    NotificationManager.error(apiRes.data.msg, '',2000);
                }
            }
        }
        catch (error) 
        {
            // NotificationManager.error(error.message, '',2000);
        } 
  }

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
        <main id="main" className="main">
            <div className="pagetitle">
                <span className="TitleWrap">
                <span className="editorNameLogo">{showEditorData?.first_name?.charAt(0)}{showEditorData?.last_name?.charAt(0)}</span>
                    <h2>Editor View</h2>
                </span>
            </div>
            <section className="section AccDtlsPnl">
                <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Personal Details</h3>
                        <Link  className="btn btn-primary CmnBtn"
                                // to = {`/edit-editor/${showEditorData?.id}`}
                                to = {`/admin-editor-management/edit-editor/${showEditorData?.id}`}
                            > 
                            <i className="bi bi-pencil" /> Edit Editor
                        </Link>
                    </div>
                    <ul className="AccDtls row">
                        <li className="col-md-3">
                            <label>First Name</label>
                            <p className="InputTxt">{showEditorData?.first_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Last Name</label>
                            <p className="InputTxt">{showEditorData?.last_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Phone Number</label>
                            <p className="InputTxt">{showEditorData?.ph_no}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Email Address</label>
                            <p className="InputTxt">{showEditorData?.email_address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Street Address</label>
                            <p className="InputTxt">{showEditorData?.address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>City</label>
                            <p className="InputTxt">{showEditorData?.city}</p>
                        </li>
                        <li className="col-md-3">
                            <label>State</label>
                            <p className="InputTxt">{showEditorData?.state}</p>
                        </li>
                        <li className="col-md-3">
                            <label>ZIP Code</label>
                            <p className="InputTxt">{showEditorData?.zipcode}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Role</label>
                            <p className="InputTxt">
                              {
                                showEditorData?.role == '0' ? 'Administrator':
                                showEditorData?.role == '1' ? 'Editor':
                                showEditorData?.role == '2' ? 'Client':
                                showEditorData?.role == '3' ? 'Deputy Admin':
                                showEditorData?.role == '4' ? 'Internal Editor':''
                              }
                            </p>
                        </li>
                        {/* <li className="col-md-3">
                            <label>Organization</label>
                            <p className="InputTxt">{showEditorData?.organization}</p>
                        </li>  */}
                        <li className="col-md-3">
                            <label>Country</label>
                            <p className="InputTxt">{showEditorData?.country}</p>
                        </li>
                        
                    </ul>
                </div>
                {/* <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Password</h3>
                        <NavLink to="/editor-my-account/editor-edit-password" className="btn btn-primary CmnBtn">
                            <i className="bi bi-pencil" /> Edit Password
                        </NavLink>
                    </div>
                    <ul className="AccDtls row">
                        <li className="col-md-4">
                            <label>Password</label>
                            <p className="InputTxt">Sam@123456</p>
                        </li>
                    </ul>
                </div> */}
            </section>
        </main>
    </div>
  );
};

export default ViewEditor;
