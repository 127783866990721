import {
  React, useEffect, useState, 
  axios,
  NotificationManager,
  Form
} from '../../utility/CommonImport'; // Import 

import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import $ from "jquery";

import { API_BASE_URL} from '../../api_url';
import { get_date_format } from '../../utility/Common';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useRef } from 'react';

const visaImg = require("../../assets/img/visa-img.png");
const paypalImg = require("../../assets/img/paypal-img.png");
const stripeImg = require("../../assets/img/stripe-img.png");
const alipayImg = require("../../assets/img/alipay-img.png");
const masterImg = require("../../assets/img/master-card-img.png");
const signatureImg = require("../../assets/img/signature-img.png");
const docImg = require("../../assets/img/docs-img.png");
const iconImg = require("../../assets/img/image-icon.png");
const pdfImg = require("../../assets/img/pdf.png");

const AdminInquirydetails = () => {
  const windowRef = useRef();
  const [customerData,setCustomerData] = useState();
  const [inqList,setInqList] = useState([]);
  const [inqDetailsById,setInqDetailsById] = useState();
  const [type,setType] = useState();

  const [searchByNameArr,setSearchByNameArr] = useState([]); //search list by name
  const [searchByEmailArr,setSearchByEmailArr] = useState([]); //search list by email
  const [searchByServiceNeedArr,setSearchByServiceNeedArr] = useState([]); //search list by service needed

  const [isWindowOpen, setIsWindowOpen] = useState(false)

  let data= JSON.parse(localStorage.getItem("user_data_elite"));

  const openJobDetlsPnl = () => { 
    setIsWindowOpen(true)
    $(".openJobDetlsPnl").addClass("Act");
  };

  const closeJobDetlsPnl = () => { 
    $(".openJobDetlsPnl").removeClass("Act");
  };
 
  useEffect(()=>{
    getClientInfos();
    getAllInquries();
  },[])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
      {
        setIsWindowOpen(false)
        closeJobDetlsPnl()
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
    }
  }, [isWindowOpen])

  // get client information
  const getClientInfos = async () => {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setCustomerData(apiRes.data.data[0]);
            }
        }
      } 
      catch (error)
      {
        NotificationManager.error(error.message, "", 2000);
      }
  };

//All inquiries
const getAllInquries = async() =>{
  try
  {
    let apiRes = await axios.get(`${API_BASE_URL}account/inquiryList`)
    if (apiRes) 
    {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          setInqList(apiRes.data.data);
        }
        setSearchByNameArr([]);
        apiRes.data.data.map((value) => {
          setSearchByNameArr(oldArray => [...oldArray, {
            id: value.id,
            name: value.first_name + ' ' + value.last_name,
            email_address: value.email_address
          }]);
        });
        setSearchByEmailArr([]);
        apiRes.data.data.map((value) => {
          setSearchByEmailArr(oldArray => [...oldArray, {
            id: value.id,
            name: value.email_address,
          }]);
        });
        setSearchByServiceNeedArr([]);
        apiRes.data.data.map((value) => {
          setSearchByServiceNeedArr(oldArray => [...oldArray, {
            id: value.id,
            name: value.serviceneeded,
          }]);
        });
    }
  } 
  catch (error)
  {
    // NotificationManager.error(error, "", 2000);
  }
}
const items_name = searchByNameArr;
const items_email = searchByEmailArr;
const items_service_need = searchByServiceNeedArr;

//Remove Inquiry
const deleteInquiry = async(enq_id) =>{
  try
  {
    let apiRes = await axios.post(`${API_BASE_URL}account/deleteInquiry`, {
      id: enq_id,
    });
    if (apiRes) 
    {
        if (apiRes.data.res == true) 
        {
          NotificationManager.success('Inquiry Removed Successfully', "", 2000);
          getAllInquries();
        }
        else
        {
          NotificationManager.error('An error occurred while removing the inquiry', "", 2000);
        }
    }
  } 
  catch (error)
  {
    NotificationManager.error('Something went wrong. Please try again!', '', 2000);
  }
}

//Details of an Inquiry
const getInqueryDetails = async(inq_id) =>{
  try
  {
    let apiRes = await axios.get(`${API_BASE_URL}account/inquiryDetails?id=${inq_id}`) 
    if (apiRes) 
    {
      if (apiRes.data.res == true) 
      {
        setInqDetailsById(apiRes.data.data);
      }
      else
      {
        NotificationManager.error("An error occurred while fetching details of the inquiry","",2000);
      }
    }
  } 
  catch (error)
  {
    NotificationManager.error("Something went wrong. Please try again.", "", 2000);
  }
}

const handleOnSearch = (string, results) => {
  // onSearch will have as the first callback parameter
  // the string searched and for the second the results.
  console.log(string, results)
}

const handleOnSelect = (item) => {
  openJobDetlsPnl();
  getInqueryDetails(item.id)
}

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle pagetitleWithSearch">
          <span className="TitleWrap TitleWrapNonSpce enq-title-wrap">
            <span className="NameLogo">{customerData?.first_name?.charAt(0)}{customerData?.last_name?.charAt(0)}</span>
            <h2>Inquiry List</h2>
          </span>
          <ul className="admin-search admin-enquiry-list">
            <li>
              <Form.Group>
                <Form.Select name = "subservice" onChange={(e)=>{setType(e.target.value)}}>
                  <option selected hidden value='null'>Select Type</option>
                  <option value='byname'>Name</option>
                  <option value='byemail'>Email</option>
                  <option value='byserviceneed'>Service</option>
                </Form.Select>
              </Form.Group>
            </li>
            <li>
              {type && type =='byname' ?(
                  <ReactSearchAutocomplete
                    items={items_name}
                    onSearch={handleOnSearch}
                    // onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // onFocus={handleOnFocus}
                    placeholder='Search by Name'
                    autoFocus
                  />
                ):
                type && type =='byemail' ?(
                  <ReactSearchAutocomplete
                    items={items_email}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    placeholder='Search by Email'
                    autoFocus
                  />
                ):
                type && type =='byserviceneed' ?(
                  <ReactSearchAutocomplete
                    items={items_service_need}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    placeholder='Search by Service'
                    autoFocus
                  />
              ):null}
            </li>
          </ul>
        </div>
        
        <br className="clearfix" />
          {/* <section className="section BillDtlsPnl"> */}
          <section className="section OdrDtlsPnl enquiry-OdrDtlsPnl-sec">
            <div className="complete-table-sec">
              <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table AdminInquiryTable">
                <thead>
                  <tr>
                    {/* <th># Serial No.</th>
                    <th>Date of Submission</th>
                    <th>Service Needed</th>
                    <th>Order Per Month</th>
                    <th>WC Per Document</th>
                    <th>Submitted By</th>
                    <th>Email Address</th>
                    <th>Action</th> */}
                    <th>ID</th>
                    <th>Date</th>
                    <th>Service</th>
                    <th>Order/Month</th>
                    <th>WC/File</th>
                    <th>Inquirer</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {inqList?.length > 0 ? (
                  inqList?.map((item,index)=>{
                    return(
                      <tr>
                        <td className="text-center">{item?.id}</td>
                        <td className="text-center" style={{cursor:'pointer'}} onClick={()=> {getInqueryDetails(item.id);openJobDetlsPnl()}}> 
                          <span className="GreenTxt">{get_date_format(item?.created_at)}</span>
                        </td>
                        <td className="text-center" style={{cursor:'pointer'}} onClick={()=> {getInqueryDetails(item.id);openJobDetlsPnl()}}>
                            <span className="text-capitalize">{item?.serviceneeded?.toLowerCase()}</span>
                        </td>
                        <td className="text-center" style={{cursor:'pointer'}} onClick={()=> {getInqueryDetails(item.id);openJobDetlsPnl()}}>
                          {item?.numberordermonth}
                        </td>
                        <td className="text-center" style={{cursor:'pointer'}} onClick={()=> {getInqueryDetails(item.id);openJobDetlsPnl()}}>
                          {item?.avgwcpd}
                        </td>
                        <td className="text-center" style={{cursor:'pointer'}} onClick={()=> {getInqueryDetails(item.id);openJobDetlsPnl()}}>
                          {item?.first_name} {item?.last_name}
                        </td>
                        <td className="text-center" style={{cursor:'pointer'}} onClick={()=> {getInqueryDetails(item.id);openJobDetlsPnl()}}>
                          {item?.email_address}
                        </td>
                        <td onClick={()=>deleteInquiry(item?.id)} className="text-center"><i class="fa fa-trash-o" aria-hidden="true"></i></td>
                      </tr>
                    )
                  })
                  ):(
                    <tr>
                      <td className='no-data' colSpan={8} style={{background: "none"}}><p style={{textAlign:'center'}}>No Inquiry Found</p></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </section>

        {/* left Job Details Pannel */}
        <div className={`DtlsSectionWrapper OngoingPnl openJobDetlsPnl`} ref={windowRef}>
          <div className={`TaskDtlsPnl no-textarea-sec`}>
            <div className="HdrTop">
              <span className="Rht">
                <a onClick={()=>closeJobDetlsPnl()} className="CloseBtn" style={{position:'absolute', right: '5px'}}>
                  <i className="bi bi-x" />
                </a>
              </span>
            </div>
            <div className="MiddleBody">
              <table className="DtlsDescrpTbl">
                <tbody>
                  {/* <tr>
                    <td># Serial No.</td>
                    <td>{inqDetailsById?.id}</td>
                  </tr> */}
                  <tr>
                    <td>Submitted By</td>
                    <td>{inqDetailsById?.first_name} {inqDetailsById?.last_name}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>
                      <a href="mailto:contact@elitereviser.com">
                        {inqDetailsById?.email_address}
                      </a>
                    </td>
                  </tr>
                  
                  <tr>
                    <td>Company Website</td>
                    <td>
                      <a href='#'>{inqDetailsById?.companywebsite}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Role in Company</td>
                    <td>{inqDetailsById?.roleincompany}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{inqDetailsById?.country}</td>
                  </tr>
                  <tr>
                    <td>Date of Submission</td>
                    <td>
                      <span className="GreenTxt">{get_date_format(inqDetailsById?.created_at)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Service Needed</td>
                    <td className="text-capitalize">{inqDetailsById?.serviceneeded?.toLowerCase()}</td>
                  </tr>
                  <tr>
                    <td>Order Per Month</td>
                    <td>{inqDetailsById?.numberordermonth}</td>
                  </tr>
                  <tr>
                    <td>WC Per Document</td>
                    <td>{inqDetailsById?.avgwcpd}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};


export default AdminInquirydetails;
