import {
  React, useEffect, useState,
  NavLink, useNavigate, useLocation,
  Formik,yup,
  axios,
  NotificationManager,
  Button,
  AOS
} from '../../utility/CommonImport'; 

import "aos/dist/aos.css";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import { API_BASE_URL } from "../../api_url";
import { loginUser } from "../../API/apiRequests";
import ClientGoogleLogin from "../../Components/ClientComponents/ClientGoogleLogin";
import ClientAppleLogin from "../../Components/ClientComponents/ClientAppleLogin";

const loginBanner = require("../../assets/img/login-banner.jpg");
const viewEye = require("../../assets/img/view.png");
const hideEye = require("../../assets/img/hide.png");

// const fbImg = require("../../assets/img/fb2-icon.png");
// const twitterImg = require("../../assets/img/twitter2-icon.png");
// const gglImg = require("../../assets/img/ggl-icon.png");
// const gIcon = require("../../assets/img/google-icon.png");

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loginData = async (values, { resetForm }) => {
    return new Promise(async (resolve, reject) => {
      try
      {
        setLoading(true);
        let apiRes = await loginUser(values);
        if (apiRes)
        {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true)
          {
            localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
            console.log(JSON.stringify(apiRes.data.data[0]));

            if (apiRes.data.data[0].role == 1)
            {
              navigate("/editor-job-board");
            }
            else if (apiRes.data.data[0].role == 2)
            {
              if (location?.state?.from && location?.state?.from === 'service')
              {
                navigate("/payment");
              }
              else if (location?.state?.from && location?.state?.from === 'servicecv')
              {
                navigate("/payment");
              }
              else
              {
                navigate("/client-my-orders");
              }
            }
            else if (apiRes.data.data[0].role == 4)
            {
              navigate("/internal-editor-dashboard");
            }
            resolve("Login successful");
          }
          else
          {
            setLoading(false);
            NotificationManager.error(apiRes.data.msg, "", 2000);
            reject(apiRes.data.msg);
          }
        }
      }
      catch (error)
      {
        setLoading(false);
        reject(error.message);
      }
    });
  };
    
  let schema = yup.object().shape({
    email_address: yup
      .string()
      .required("Email is required")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address!"
      ),
    password: yup
        .string()
        .required("Password is required")
        .min(6)
        .max(20),
  });

  const handleKeyPress = (event,submitForm) => {
    if (event.key === 'Enter')
    {
      event.preventDefault();
      submitForm();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    AOS.init();
    console.log(location?.state?.from)
  });

  return (
    <div>
      <Header />
      <div className="inner-small-banner" style={{ backgroundImage: `url(${loginBanner})` }}>
        <div className="container">
          <h3 data-aos="fade-up">Welcome Back</h3>
        </div>
      </div>
      <Formik
        initialValues={{
          email_address: "",
          password: "",
        }}
        onSubmit={(values, { resetForm }) => {
          loginData(values, { resetForm });
        }}
        validationSchema={schema}
      >
        {({ handleChange, handleSubmit, errors, values, setFieldValue, touched, submitForm }) => (
            <div className="login-body">
                <div className="container">
                <h3>Sign In</h3>
                <ul className="row login-form-list">
                  <li className="col-12">
                    <div class="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Username/Email"
                        name="email_address"
                        onChange={handleChange}
                        value={values.email_address}
                        onKeyDown={(e)=>{
                          if(e.key === 'Enter')
                          {
                            e.preventDefault();
                            document.getElementById("passwordInput").focus();
                          }
                        }}
                      />
                      <label for="emailInput">Username/Email</label>
                    </div>
                    {errors.email_address && touched.email_address ? <div className='error'>{errors.email_address}</div> : null}
                  </li>
                  <li className="col-12">
                    <div class="form-floating">
                      <input
                        // type="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="passwordInput"
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        onKeyPress={(e) => handleKeyPress(e, submitForm)}
                      />
                      <div
                        variant="outline-secondary"
                        className="viewbtn"
                        style={{marginLeft:'500px',marginTop:'-31px'}}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={hideEye} className="viewimg" alt="logo" />
                        ) : (
                          <img src={viewEye} className="hideimg" alt="logo" />
                        )}
                      </div>
                        <label for="passwordInput">
                        <span>*</span>Password
                        </label>
                    </div>
                    {errors.password && touched.password ? <div className='error'>{errors.password}</div> : null}
                    <a className="forgot-password-link">
                      <NavLink to='/forget-Password'>                                                                    
                          Forgot Password?
                      </NavLink>
                    </a>
                  </li>
                  <li className="col-md-12">
                    {loading && loading ? (
                      <Button className="btn btn-primary" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Sign In</Button>
                    ):(
                      <Button className="btn btn-primary" type="submit"  onClick={()=>handleSubmit()}>Sign In</Button>
                    )}
                  </li>
                </ul>

                <h4>or</h4>
                <ul className="row social-list2">
                  <li className="col-md-12 text-center">
                    <div className="gLogin">
                      <ClientGoogleLogin/>
                    </div>
                    <div className="gLogin">
                      <ClientAppleLogin/>
                    </div>
                    {/* <div className="gLogin-custom">
                      <a><img src={gIcon} alt="" className="google-img" />Sign in with Google</a>
                    </div> */}
                    {/* <button onClick={()=>googleLogin()}>Click Me!</button> */}
                  </li>
                  <li className="col-md-6"></li>
                </ul>
                <div className="signup-sec">
                    {/* <NavLink className="btn btn-primary" to="/signup">
                    Sign Up
                    </NavLink> */}

                    {/* if(location?.state?.from && location?.state?.from === 'service')
                    {
                      navigate("/payment");
                      
                    }
                    else
                    {
                      navigate("/client-my-account");
                    }  */}
                    {(location?.state?.from && location?.state?.from === 'service') ? (
                        // navigate("/payment")
                        // navigate('/login', { state: { from: 'service'}});
                        <NavLink className="btn btn-primary" 
                            to="/signup"
                            state={{from: 'service'}}
                        >
                            Sign Up
                        </NavLink>
                    ):(
                      // navigate("/client-my-account")
                      <NavLink className="btn btn-primary" to="/signup">
                        Sign Up
                      </NavLink>
                    )}
                </div>
                </div>
            </div>
        )}
      </Formik>
      <div className="home-yellow-section align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
              <h3>Still Not Sure What Service Is Right For You?</h3>
              <p>
                <NavLink to="/">Elite Reviser</NavLink> can handle that. 
                <NavLink to="/contact"> Contact Us.</NavLink>
              </p>
            </div>
            <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
              <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
