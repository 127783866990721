import {
  React, Component, useEffect, useState,
  NavLink, useNavigate,
  Formik, Field, 
  yup,
  axios,
  NotificationManager,
  Button,
  DateTime
} from '../../utility/CommonImport'; // Import 

import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";

import { API_BASE_URL} from '../../api_url';
import { fetchCountryList } from '../../API/apiRequests';

const AddNewInternalEditorByAdmin = () => {
    const navigate = useNavigate();
    const timeZone = DateTime.local().zoneName;
    const [loading,setLoading] = useState(false);
    const [showCountryList,setShowCountryList] = useState([]); // for getting all countries
    let data= JSON.parse(localStorage.getItem("user_data_elite")); 

//get country list
const getCountry = async() =>
{
    try
    {
      let apiRes = await fetchCountryList();
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowCountryList(apiRes.data.data);
        }
        else
        {
          NotificationManager.error(apiRes.data.msg, '',2000);
        }
      }
      else
      {
        throw new Error('Network request failed');
      }
    }
    catch (error) 
    {
        NotificationManager.error(error.message, "", 2000);
    }   
}

const addNewInternalData = async(values,{resetForm}) =>{
  try
  {
    setLoading(true);
    let apiRes = await(
        axios
        .post(
          `${API_BASE_URL}login/registration`,{
            first_name: values.first_name,
            last_name: values.last_name,
            ph_no: values.ph_no.toString(),
            email_address: values.email_address,
            address: values.address,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            country: values.country,
            password: values.password,
            assigned_number_of_job: values.assigned_number_of_job,
            designation : values.designation,
            role: '4',
            UserTimeZone: timeZone
            // Admin: 0, Editor: 1, Client: 2, Deputy Admin: 3, Internal Editor: 4
          },
        )
    )
    if(apiRes)
    {
      if(apiRes.data.res == true) 
      {
          NotificationManager.success('Successfully Registered','',2000);
          setLoading(false);
          navigate("/admin-internal-editor-management");
      }
      else
      {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, '',2000);
      }
    }
    else
    {
      throw new Error('Network request failed');
    }
  }
  catch (error) 
  {
    NotificationManager.error(error.message, '',2000);
  } 
}
  
let schema = yup.object().shape({

    first_name: yup
    .string()
    .required('First name is required'),

    last_name: yup
    .string()
    .required('Last name is required'),

    ph_no: yup
    .string()
    .required('Phone number is required')
    .max(10,'Invalid Phone number'),

    email_address: yup
    .string()
    .required('Email is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
      
    address: yup
    .string()
    .required('Street Address is required'),

    city: yup
    .string()
    .required('City is required'),

    state: yup
    .string()
    .required('State is required'),

    zipcode: yup
    .string()
    .required('Zip Code is required'),

    country: yup
    .string()
    .required('Country is required'),

    password: yup
    .string()
    .required('Password is required')
    .min(6)
    .max(20),

    cpassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'),null],'Passwords did not match'),

    assigned_number_of_job: yup
    .number()
    // .max(1 , 'Maximum number of job can assign is 8')
    // .required('Assign number of Jobs')
    // .moreThan(0, 'Minimum number of job can assign is 1')
    // .lessThan(9, "Maximum number of job can assign is 8"),
    // .max(1 , 'Max. no. of jobs at a time is 8')
    .required('Number of Jobs')
    .moreThan(0, 'Min. no. of jobs at a time is 1')
    .lessThan(9, "Max. no. of jobs at a time is 8"),

    designation: yup
    .string()
    .required('Designation is required'),
});  

useEffect(()=>{
  getCountry();
},[]);

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Create New Internal Editor</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
          <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                ph_no: '',
                email_address: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                country: '',
                password: '',
                cpassword: '',
                assigned_number_of_job: '',
                designation: ''
            }}
            onSubmit={(values,{resetForm})=>{addNewInternalData(values,{resetForm})}}
            validationSchema = {schema}
          >
          {({
              handleChange,
              handleSubmit,
              errors,
              values
          })=>(
            <div className="AccBox">
                <div className="pagetitle">
                <h3>Personal Details</h3>
               {loading && loading ? (
                  <Button className="btn btn-primary CmnBtn" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Save</Button>
                ):(
                  <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Save</Button>
                )}
                </div>
                <ul className="AccDtls EditAccDtls EditAccDtls addEditorForm row">
                    <li className="col-md-3">
                      <div className="small-input-box">
                       <input type="text" className="InptField" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} value={values.first_name}/>
                      </div>
                      {errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                       <input type="text" className="InptField" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name}/>
                      </div>
                      {errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <input type="number" className="InptField" id="phoneInput" placeholder="Phone Number" name="ph_no"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                      </div>
                      {errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <input type="email" className="InptField" id="emailInput" placeholder="Email Address" name="email_address" onChange={handleChange} value={values.email_address}/>
                      </div>
                      {errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <input type="text" className="InptField" name="address" placeholder="Street Address" onChange={handleChange} value={values.address}/>
                      </div>
                      {errors.address ? <div className='error'>{errors.address}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <input type="text" className="InptField" name="city" placeholder="City" onChange={handleChange} value={values.city}/>
                      </div>
                      {errors.city ? <div className='error'>{errors.city}</div> : null}
                    </li>

                    <li className="col-md-3">
                      <div className="small-input-box">
                        <input type="text" className="InptField" name="state" placeholder="State" onChange={handleChange} value={values.state}/>
                      </div>
                      {errors.state ? <div className='error'>{errors.state}</div> : null}
                    </li>

                    <li className="col-md-3">
                      <div className="small-input-box">
                        <input type="text" className="InptField" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                      </div>
                      {errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <select class="select-input" id="selectCountry" aria-label="Floating label select example" name="country" onChange={handleChange} value={values.country}>
                         <option selected hidden>Country</option>
                          {showCountryList && showCountryList.map((item,index)=>{
                            return(
                              <option value={item?.country_name}>{item?.country_name}</option>
                            )
                          })}
                        </select>
                        {errors.country ? <div className='error'>{errors.country}</div> : null}
                      </div>
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                       <input type="password" className="InptField" id="passwordInput" placeholder="Password" name="password" onChange={handleChange} value={values.password}/>
                      </div>
                      {errors.password ? <div className='error'>{errors.password}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                       <input type="password" className="InptField" id="confirmInput" placeholder="Confirm Password" name="cpassword" onChange={handleChange} value={values.cpassword}/>
                      </div>
                      {errors.cpassword ? <div className='error'>{errors.cpassword}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                       <input type="number" className="InptField" id="assigned_number_of_job" placeholder="Number of Jobs"  name="assigned_number_of_job"  onChange={handleChange} value={values.assigned_number_of_job}/>
                      </div>
                      {errors.assigned_number_of_job ? <div className='error'>{errors.assigned_number_of_job}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                       <input type="text" className="InptField" placeholder="Designation" name="designation" onChange={handleChange} value={values.designation}/>
                      </div>
                      {errors.designation ? <div className='error'>{errors.designation}</div> : null}
                    </li>
                </ul>
            </div>
          )}
          </Formik>
        </section>
      </main>
    </div>
  );
};

export default AddNewInternalEditorByAdmin;
