// import React, { useEffect, useState } from 'react';

// const CountUpTimerComponent = (props) => {
//   console.log(props.MS)
//   // const [countUp, setCountUp] = useState(0);
//   const [countUp, setCountUp] = useState(props.MS);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCountUp(prevCountUp => prevCountUp + 1000); // increment by 1000 milliseconds (1 second)
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const getReturnValues = (countUp) => {
//     console.log(countUp)
//     // calculate time elapsed
//     const days = Math.floor(countUp / (1000 * 60 * 60 * 24));
//     const hours = Math.floor((countUp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//     const minutes = Math.floor((countUp % (1000 * 60 * 60)) / (1000 * 60));
//     const seconds = Math.floor((countUp % (1000 * 60)) / 1000);
//     return [days, hours, minutes, seconds];
//   };

//   const [days, hours, minutes, seconds] = getReturnValues(countUp);

//   const formatValue = (value) => {
//     return value < 10 ? '0' + value : value;
//   };

//   return (
//     <div className="show-counter">
//       <DateTimeDisplay value={formatValue(days)} type={'D'} isDanger={days <= 3} />
//       <DateTimeDisplay value={formatValue(hours)} type={'H'} isDanger={false} />
//       <DateTimeDisplay value={formatValue(minutes)} type={'M'} isDanger={false} />
//       <DateTimeDisplay value={formatValue(seconds)} type={'S'} isDanger={false} />
//     </div>
//   );
// };

// const DateTimeDisplay = ({ value, type, isDanger }) => {
//   return (
//     <div className={isDanger ? 'countdown danger' : 'countdown'}>
//       <p>{value}</p>
//       <span>{type}</span>
//     </div>
//   );
// };

// export default CountUpTimerComponent;

import React, { useEffect, useState } from 'react';

const CountUpTimerComponent = (props) => {
  const [countUp, setCountUp] = useState(props.MS);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountUp(prevCountUp => prevCountUp + 1000); // increment by 1000 milliseconds (1 second)
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getReturnValues = (countUp) => {
    // calculate time elapsed
    const days = Math.floor(countUp / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countUp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countUp % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countUp % (1000 * 60)) / 1000);
    return [days, hours, minutes, seconds];
  };

  const [days, hours, minutes, seconds] = getReturnValues(countUp);

  const formatValue = (value) => {
    return value < 10 ? '0' + value : value;
  };

  if (days > 99) {
    return <div className="show-counter"><p style={{textAlign:'center',margin:'5px'}}>----</p></div>;
  }

  return (
    <div className="show-counter">
      <DateTimeDisplay value={formatValue(days)} type={'D'} isDanger={days <= 3} />
      <DateTimeDisplay value={formatValue(hours)} type={'H'} isDanger={false} />
      <DateTimeDisplay value={formatValue(minutes)} type={'M'} isDanger={false} />
      <DateTimeDisplay value={formatValue(seconds)} type={'S'} isDanger={false} />
    </div>
  );
};

// DateTimeDisplay Component
const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};

export default CountUpTimerComponent;