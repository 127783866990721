import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { get_date_format,commaFunc, commaFunc1 } from '../../utility/Common';
import { NotificationManager } from "react-notifications";

const InternalEditormyearningsByDeputy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // alert(location.state)
  let data= JSON.parse(localStorage.getItem("user_data_elite"));

  const [orderList,setOrderList] = useState([]);
  const [totalArray,setTotalArr] = useState([]);
  const [currentMonth,setCurrentMonth] = useState();

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const d = new Date();
  let name = month[d.getMonth()];

  const [totalCount, setTotalCount] = useState();
  const [selectedMonth,setSelectedMonth] = useState();

  const [editorEarrningArr,setEditorEarrningArr] = useState([]);

  useEffect(()=>{
    console.log(editorEarrningArr);
  },[editorEarrningArr])

  // setCurrentMonth(name);

  // All jobs for a specific Client
//   const jobListByEditorEarning = async () => {
//     try
//     {
//       let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?editor_id=${data?.id}`)
//       if (apiRes) 
//       {
//           console.log(apiRes.data.res);
//           if (apiRes.data.res == true) 
//           {
//             setOrderList(apiRes.data.data);
//             apiRes.data.data.map((item,index)=>{
//               totalArray.push(
//                 {
//                   'total_wc': item.total_wordcount ? item.total_wordcount: 0 ,
//                   'total_pages': item.number_of_pages ?  item.number_of_pages: 0 ,
//                   'total_amount': item.total_price
//                 }
//               )
//             })
//           }
//       }
//     }
//     catch (error)
//     {
//       // NotificationManager.error(error.message, "", 2000);
//     }
//  }


// const jobListByEditorEarning = async () => {
//   let total_wc = 0;
//   let total_pages = 0;
//   let total_amount = 0;
  
//   try
//   {      
//     let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?editor_id=${location.state.user_id}`);
//     if (apiRes)
//     {
//       if (apiRes.data.res === true) {
//         apiRes.data.data.forEach((item) =>
//         {
//           total_wc += item.total_wordcount ? item.total_wordcount : 0;
//           total_pages += item.number_of_pages ? item.number_of_pages : 0;
//           total_amount += item.editor_reg_price;

//           setTotalArr({
//             'total_wc': total_wc,
//             'total_pages': total_pages,
//             'total_amount': total_amount,
//           });
//         });
//         setOrderList(apiRes.data.data);
//       }
//     }
//   }
//   catch (error)
//   {
//     // Handle the error here
//     // NotificationManager.error(error.message, "", 2000);
//   }
// };

 useEffect(()=>{
  // jobListByEditorEarning();
  handleMonthChange(d.getMonth()+1)
},[])

useEffect(() => {
  setCurrentMonth(month[new Date().getMonth()]);
}, []);

const getMonthName = (month) => {
  const monthNames = [
    "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];
  return monthNames[month];
};

const getFullJobTitle = (type,service_name,number_of_pages,service_type,sub_service,total_wc,service_hr) => {
  let job_title = '';
  let service = '';
  if(service_type == '0'){
    service = 'Regular Service';
  }else{
    service = 'Expedited Service';
  }

  if(type == 'type2'){
    job_title = service_name + ' ' + '|' + ' ' + 'Number of Pages' + ' ' + number_of_pages + ' ' + '|' + ' ' + service + ' ' + '|' + ' ' +'| 1 Week';
  }else if(type == 'type1'){
    job_title = service_name + ' ' + '|' + ' ' + sub_service + ' ' + '|' + ' ' + 'Word Count' + ' ' + total_wc + ' ' + '|' + ' ' + service + ' ' + '|' + ' ' + service_hr + 'hrs';
  }
  return job_title;
}

  //Month Change
  const handleMonthChange = async(month_value) => {
    let total_wc = 0;
    let total_pages = 0;
    let total_amount = 0;
    setSelectedMonth(month_value);
    try
    {      
      let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?editor_id=${''}&created_at=${month_value}&internal_id=${location.state.user_id}`);
      if (apiRes)
      {
        if (apiRes.data.res === true) {
          apiRes.data.data.forEach((item) =>
          {
            // total_wc += item.total_wordcount ? item.total_wordcount : 0;
            // total_pages += item.number_of_pages ? item.number_of_pages : 0;
            // total_amount += item.editor_reg_price;
  
            // setTotalArr({
            //   'total_wc': total_wc,
            //   'total_pages': total_pages,
            //   'total_amount': total_amount,
            // });
          });

          console.log(apiRes.data.data)
          const editorItemArr = apiRes.data.data.map((itemData,itemIndex) => ({
            job_id: itemData?.id,
            job_title : '',
            job_type: itemData?.job_type,
            word_count: itemData?.job_type == "type1" ? itemData.total_wordcount : 0,
            number_of_pages: itemData?.job_type == "type2" ? itemData.number_of_pages : 0,
            total_price: itemData?.job_type == "type1" ? itemData.total_price : 0,
            cv_price: itemData?.job_type == "type2" ? itemData.cv_price : 0,
            service_editor_commission: itemData?.job_type == "type1" ? itemData.editor_reg_price : 0,
            cv_editor_commision: itemData?.job_type == "type2" ? itemData.editor_cv_price : 0,
            job_status: itemData?.job_status,
            completion_date: get_date_format(itemData?.due_date),
            job_title: getFullJobTitle(itemData?.job_type,itemData?.service_name,itemData?.number_of_pages,itemData?.service_type,itemData?.sub_service,itemData?.total_wordcount,itemData?.service_hour)
          }));

          apiRes.data.data.forEach((item) =>
          {
            total_wc += item.total_wordcount ? item.total_wordcount : 0;
            total_pages += item.number_of_pages ? item.number_of_pages : 0;
            total_amount += item.editor_reg_price;
  
            setTotalArr({
              'total_wc': total_wc,
              'total_pages': total_pages,
              'total_amount': total_amount,
            });
          });
          
          setEditorEarrningArr(editorItemArr);

          setOrderList(apiRes.data.data);
          setTotalCount(total_wc)
        }
      }
    }
    catch (error)
    {
      // Handle the error here
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  // word-count changes=
  // const handleWordCountChange = (index, value) => {
  //   const updatedArray = editorEarrningArr.map((item, i) => 
  //     i === index ? { ...item, word_count: value } : item
  //   );
  //   setEditorEarrningArr(updatedArray);
  //   console.log(updatedArray);

  //   // Calculate new total word count
  //   const newTotalWordCount = updatedArray.reduce((total, item) => {
  //     return total + (parseInt(item.word_count) || 0);
  //   }, 0);

  //   setEditorEarrningArr(updatedArray);
  //   setTotalArr(prevTotalArray => ({
  //     ...prevTotalArray,
  //     total_wc: newTotalWordCount
  //   }));

  // };

  // Function to update word count and earnings in the array
  const handleWordCountChange = async (index, value, field) => {
    // Remove commas to get the plain numeric value
    const plainValue = value.replace(/,/g, '');
    
    // Convert input to a plain number
    const numericValue = parseInt(plainValue, 10);
  
    // If the input is empty or invalid, treat it as 0
    const finalValue = isNaN(numericValue) ? 0 : numericValue;
  
    // Update the editorEarrningArr state with the new value
    const updatedArray = editorEarrningArr.map((item, i) => 
      i === index ? { ...item, [field]: finalValue } : item
    );
  
    // Calculate the new total word count
    const newTotalWordCount = updatedArray.reduce((total, item) => 
      total + (item.word_count || 0), 0
    );
  
    // Update the state
    setEditorEarrningArr(updatedArray);
    setTotalArr(prev => ({
      ...prev,
      total_wc: newTotalWordCount,
    }));
  
    // console.log(finalValue);
    // console.log(value);
    // Avoid sending a backend request if the value is 0 and input is empty
    if ( finalValue == 0 || finalValue > 0 ) {
      // Update the backend with the correct numeric value
      try {
        if (field === 'word_count') {
          await axios.post(`${API_BASE_URL}job/changeTotalWordcount`, {
            JobId: updatedArray[index].job_id,
            number_of_pages: '',
            total_wordcount: finalValue
          });
        } else if (field === 'service_editor_commission') {
          await axios.post(`${API_BASE_URL}job/changeCommissionByAdmin`, {
            JobId: updatedArray[index].job_id,
            cv_price: '',
            total_price: finalValue
          });
        }
        handleMonthChange(selectedMonth); // Re-fetch data after update
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleWordCountChange1 = async (index, value, field) => {
    // Remove commas from the input but keep the decimal point
    const plainValue = value.replace(/,/g, '');
  
    // Update the editorEarrningArr state with the plain string value
    const updatedArray = editorEarrningArr.map((item, i) => 
      i === index ? { ...item, [field]: plainValue } : item
    );
  
    // Calculate the new total word count or commission if needed
    const newTotalWordCount = updatedArray.reduce((total, item) => 
      total + (parseFloat(item.word_count) || 0), 0
    );
  
    // Update the state with the raw string value
    setEditorEarrningArr(updatedArray);
    setTotalArr(prev => ({
      ...prev,
      total_wc: newTotalWordCount,
    }));
  
    // Avoid sending a backend request if the value is empty
    if (plainValue === '') {
      return;
    }
  
    // Convert to a number only when updating the backend
    const numericValue = parseFloat(plainValue);
  
    // Update the backend with the correct numeric value
    try {
      if (field === 'word_count') {
        await axios.post(`${API_BASE_URL}job/changeTotalWordcount`, {
          JobId: updatedArray[index].job_id,
          number_of_pages: '',
          total_wordcount: numericValue
        });
      } else if (field === 'service_editor_commission') {
        await axios.post(`${API_BASE_URL}job/changeCommissionByAdmin`, {
          JobId: updatedArray[index].job_id,
          cv_price: '',
          total_price: numericValue
        });
      }
      handleMonthChange(selectedMonth); // Re-fetch data after update
    } catch (error) {
      console.error(error);
    }
  };  

  return (
    <div>
      <DeputyAdminHeader />
      <DeputyAdminSidebar />
      <main id="main" className="main">
        <a className="BackBtn" onClick={()=>navigate('/deputy-admin-internal-editor-management')}><span className="ArrowSpan"></span> Back</a>
        <div className="pagetitle pageTitleWithFloat">
          <span className="TitleWrap fullWidthTitleWrap">
            {/* <span className="editorNameLogo">MW</span> */}
            {/* <h2>{location.state.user_name} Earnings</h2> */}
            <h2 style={{whiteSpace: 'nowrap'}}>{location.state.user_name}'s Earnings</h2>
            {/* <ul className="select-date-list">
              <li>
                <DatePicker onChange={onChange} value={value} format="MM-dd-y" />
              </li>
              <li>
                <DatePicker onChange={onChange2} value={endValue} format="MM-dd-y" />
              </li>
              <li><input type="button" value="Search" className="btn btn-primary" /></li>
            </ul> */}
            <div className="dropdown">
              Filter By
                <select className="dropdown-toggle" data-toggle="dropdown" style={{border: 'none',outline: 'none',scrollBehavior: 'smooth'}} onChange={(e)=>handleMonthChange(e.target.value)}>
                {month.map((m,index) => (
                  <option key={m} className="dropdown-item" href="#" value={index+1} selected={currentMonth === m}>
                  {m}
                </option>
                ))}                
              </select>
            </div>
          </span>
          
        </div>
        <section className="section BillDtlsPnl BillDtlsPnlClear" style={{marginTop:'-25px'}}>
          <div className="complete-table-sec">
            <table width="100%" className="CmnTbl myJobTable admin-editor-managment table-striped blue-header-table new-earning-table">
              <thead>
                <tr>
                  <th style={{textAlign: "center"}}>Job #</th>
                  <th style={{textAlign: "center"}}>Task Name</th>
                  <th style={{textAlign: "center"}}>Word Count</th>
                  <th style={{textAlign: "center"}}>Page</th>
                  <th style={{textAlign: "center"}}>Completion Date</th>
                  <th style={{textAlign: "center"}}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {editorEarrningArr?.length > 0 ?
                  editorEarrningArr?.map((item,index)=>{
                  return(
                    <tr key={index}>
                      {(item?.job_type==='type2')?(
                        <>
                          <td> {item?.job_id} </td>
                          <td>
                            <p className="ListItem yet-to-asign-list">{item?.job_title}</p>
                          </td>
                        </>
                      ):(
                        <>
                          <td> {item?.job_id} </td>
                          <td>
                            <p className="ListItem yet-to-asign-list">{item?.job_title}</p>
                          </td>
                        </>
                      )}

                      {item?.job_type == "type1" ? (
                        <>
                          <td style={{textAlign: "center"}}>
                            {/* <span className="WhiteBorderBox">{item?.total_wordcount}</span> */}
                              {/* <input type="text" name="word-count" className="WhiteBorderBox2" onChange={(e)=>setTotalCount(e.target.value)} value={item?.word_count}/> */}
                            {/* <input type="text" name="word-count" value={item?.total_wordcount} className="WhiteBorderBox2" onChange={(e)=>setTotalCount(e.target.value)} value={totalCount}/> */}
                          
                            {/* <input 
                              type="text" 
                              name="word-count" 
                              className="WhiteBorderBox2" 
                              onChange={(e) => handleWordCountChange(index, e.target.value)} 
                              value={item?.word_count} 
                            /> */}

                            <input 
                              type="text" 
                              name="word-count" 
                              // className="WhiteBorderBox2" 
                              className="WhiteBorderBox2 word-count-input" 
                              onChange={(e) => handleWordCountChange(index, e.target.value, 'word_count')} 
                              value={commaFunc(item?.word_count)} 
                            />
                          
                          </td>
                          <td style={{textAlign: "center"}}><span className="WhiteBorderBox">--</span></td>
                        </>
                      ):(
                        <>
                          <td style={{textAlign: "center"}}><span className="WhiteBorderBox">--</span></td>
                          <td style={{textAlign: "center"}}><span className="WhiteBorderBox">{item?.number_of_pages}</span></td>
                        </>
                      )}

                      <td style={{textAlign: "center"}}>
                        <span className="GreenTxt">{get_date_format(item?.completion_date)}</span>
                      </td>
                      <td style={{textAlign: "center"}}>
                        {/* <span className="WhiteBorderBox"> */}
                          {item?.job_type==='type2' ? (
                            <>${parseFloat(Number(item?.cv_editor_commission)).toFixed(2)}</>
                          ):(
                            // <>${parseFloat(Number(item?.service_editor_commission)).toFixed(2)}</>
                            <input 
                              type="text" 
                              name="editor_commission" 
                              // className="WhiteBorderBox2" 
                              className="WhiteBorderBox2 change-comission"
                              onChange={(e) => handleWordCountChange1(index, e.target.value, 'service_editor_commission')} 
                              // value={commaFunc(item?.service_editor_commission)}
                              value={commaFunc1(item?.service_editor_commission)}
                            />
                          )}
                        {/* </span> */}
                        {/* $41.20 USD */}
                      </td>
                      {/* <td><span className="paid-span">Paid</span></td> */}
                    </tr>
                  )
                }):(
                  <tr>
                    <td className='no-data' colSpan={6} style={{background: "none"}}><p style={{textAlign:'center'}}>No Earnings Found</p></td>
                  </tr>
                )}

                {editorEarrningArr?.length > 0 && (
                  <tr className="special-foot-tr">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td><span>{commaFunc(totalArray?.total_wc)}</span></td>
                    <td><span>{commaFunc(totalArray?.total_pages)}</span></td>
                    <td>&nbsp;</td>
                    {/* <td><span>${parseFloat(totalArray?.total_amount).toFixed(2)}</span></td> */}
                    <td><span>${commaFunc1(totalArray?.total_amount)}</span></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </div>
  );
};

export default InternalEditormyearningsByDeputy;
