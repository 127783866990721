import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "react-bootstrap/Button";
import { Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { API_BASE_URL } from "../../api_url";
import { NotificationManager } from "react-notifications";

const ResetPassword = () => {
  const {token} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
 
  const passwordReset = async (values, { resetForm }) => {
    try {
      setLoading(true);
      let apiRes = await axios.post(`${API_BASE_URL}login/resetpassword`, {
        token : token,
        new_password: values.new_password,
        confirm_password: values.confirm_password
      });
      if (apiRes) {
        if (apiRes.data.res == true) 
        {
          NotificationManager.success('Password Updated Successfully', "", 2000); 
          resetForm({values:''})
          setLoading(false);
          navigate('/login');
        } else {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  let schema = yup.object().shape({
    new_password: yup
    .string()
    .required('New Password is required')
    .min(6,'New Password must be at least 6 characters')
    .max(20,'New Password must be at most 20 characters'),

    confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .min(6,'Confirm Password must be at least 6 characters')
    .max(20,'Confirm Password must be at most 20 characters')
    .oneOf([yup.ref('new_password'),null],'Passwords did not match')
  });

  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Formik
        initialValues={{
          new_password: '',
          confirm_password: '',
        }}
        onSubmit={(values, { resetForm }) => {passwordReset(values, { resetForm })}}
        validationSchema={schema}
      >
        {({ handleChange, handleSubmit, errors, values, touched, setFieldValue }) => (
          <div className="login-body">
            <div className="container">
              <h3>Reset Password</h3>
              <ul className="row login-form-list">
                <li className="col-12">
                  <div class="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      placeholder="New Password"
                      name ="new_password"
                      onChange={handleChange}
                      value={values.new_password}
                    />
                    <label for="emailInput">New Password</label>
                  </div>
                  {errors.new_password ? <div className='error'>{errors.new_password}</div> : null}
                </li>

                <li className="col-12">
                  <div class="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      name ="confirm_password"
                      onChange={handleChange}
                      value={values.confirm_password}
                    />
                    <label for="emailInput">Confirm Password</label>
                  </div>
                  {touched.confirm_password && errors.confirm_password ? <div className='error'>{errors.confirm_password}</div> : null}
                </li>
                  
                <li className="col-md-12">
                  {loading ? (
                    <Button className="btn btn-primary" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Sending Link</Button>
                  ):(
                    <Button className="btn btn-primary" type="submit"  onClick={()=>handleSubmit()}>Reset Password</Button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
