import React, { Component, useEffect, useState, useRef  } from 'react';
import { NavLink, useNavigate } from "react-router-dom";

const Editorsidebar = () => { 
    const navigate = useNavigate();
    const sidebarRef = useRef();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[editorData,setEditorData] = useState();
    useEffect(() => {
        console.log(data)
        if (data=== null) 
        {
            navigate("/login");
        }
        // else if(data!= null && (data.role==1 && data.role==2)) 
        // {
        //     navigate("/admin-login");
        // }
        else
        {
            setEditorData(data);
        }
       
    }, []);
    //start
const [deviceType, setDeviceType] = useState("");

useEffect(() => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      var UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  if (hasTouchScreen) {
    setDeviceType("Mobile");
    
    document.body.classList.add('toggle-sidebar');
  } else {
    setDeviceType("Desktop");
  }
}, []);
//end
    useEffect(() => {
        const currentUrl = window.location.href;
        const links = document.querySelectorAll("ul.sidebar-nav a");
    
        links.forEach((link) => {
          if (link.href === currentUrl) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        });
    
        // Cleanup function (optional)
        return () => {
          links.forEach((link) => {
            link.classList.remove("active");
          });
        };
    }, []);

    useEffect(() => {
        const select = (el, all = false) => {
          el = el.trim();
          if (all) {
            return [...document.querySelectorAll(el)];
          } else {
            return document.querySelector(el);
          }
        };
    
        const on = (type, el, listener, all = false) => {
          if (all) {
            select(el, all).forEach((e) => e.addEventListener(type, listener));
          } else {
            select(el, all).addEventListener(type, listener);
          }
        };
    
        const handleBodyClick = (e) => {
          // Check if the clicked element is outside the sidebar
          if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
            select('body').classList.add('toggle-sidebar');
          }
        };
    
        on('click', '.close-sidebar-btn', function (e) {
          select('body').classList.toggle('toggle-sidebar');
        });
    
        // Add the event listener to the document for body clicks
        //document.addEventListener('click', handleBodyClick);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleBodyClick);
        };
      }, []);

    const LogoutFunction=()=>
    {
        localStorage.removeItem("user_data_elite");
        localStorage.clear();
        navigate("/login");
    }
    return(
        <div>
            <aside id="sidebar" className="sidebar">
                <a className="CloseBtn close-sidebar-btn">
                    <i className="bi bi-x" />
                </a>
                <ul className="sidebar-nav" id="sidebar-nav">
                    {/* <li className="nav-item no-link"><NavLink to="/editor-dashboard" className="nav-link"><i className="bi bi-house"></i> <span>Dashboard</span></NavLink></li>
                    <li className="nav-item job-board-item"><NavLink to="/editor-job-board" className="nav-link collapsed"> <span>Job Board</span></NavLink></li>
                    <li className="nav-item my-jobs-item"><NavLink to="/editor-my-jobs" className="nav-link collapsed"> <span>My Jobs</span></NavLink></li>
                    <li className="nav-item"><NavLink to="/editor-my-account" className="nav-link"><i className="bi bi-person"></i> <span>My Account</span></NavLink></li>
                    <li className="nav-item"><NavLink to="/editor-settings" className="nav-link collapsed"><i class="bi bi-gear"></i> <span>Settings</span></NavLink></li>
                    <li className="nav-item billing-details-item"><NavLink to="/editor-my-earnings" className="nav-link collapsed"> <span>My Earnings</span></NavLink></li>
                    <li className="nav-item clients-feedback-item"><NavLink to="/editor-feedback" className="nav-link collapsed"> <span>Clients Feedback</span></NavLink></li> */}

                    <li className="nav-item"><NavLink to="/editor-dashboard" className="nav-link collapsed"><span class="icon-span"><i class="fa fa-tachometer" aria-hidden="true"></i></span> <span>Dashboard</span></NavLink></li>
                    <li className="nav-item job-board-item"><NavLink to="/editor-job-board" className="nav-link collapsed"> <span>Job Board</span></NavLink></li>
                    <li className="nav-item job-board-item"><NavLink to="/editor-my-jobs" className="nav-link collapsed"> <span>My Jobs</span></NavLink></li>
                    <li className="nav-item my-account-item"><NavLink className="nav-link collapsed" to="/editor-my-account"><span class="icon-span"><i className="bi bi-person"></i></span> <span>My Profile</span></NavLink></li>
                    <li className="nav-item settings-item"><NavLink  className="nav-link collapsed" to="/editor-settings"><span class="icon-span"><i class="bi bi-gear"></i></span> <span>Settings</span></NavLink></li>
                    {/* <li className="nav-item billing-details-item no-link"><a className="nav-link collapsed"> <span>My Earnings</span></a></li> */}
                    <li className="nav-item billing-details-item"><NavLink to="/editor-my-earnings" className="nav-link collapsed"> <span>My Earnings</span></NavLink></li>
                    <li className="nav-item clients-feedback-item"><NavLink to="/editor-feedback" className="nav-link collapsed"> <span>Feedback</span></NavLink></li>

                    <li className="nav-item logout-item logout2-item">
                        {/* <NavLink to="/" className="nav-link collapsed"> <span>Logout</span></NavLink> */}
                        <a href="javascript:void(0);" className="nav-link collapsed" onClick={()=>LogoutFunction()}>Sign Out</a>
                    </li>
                </ul>
            </aside>
            <div className="side-menu-overlay"></div>
        </div>
    );
};

export default Editorsidebar;