import React, { Component, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Clientheader from "../../Components/ClientComponents/Clientheader";
import Clientsidebar from "../../Components/ClientComponents/Clientsidebar";
import Button from "react-bootstrap/Button";
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const Clienteditaccount = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false); 
    const [showClientData,setShowClientData] = useState(); 
    const [showCountryList,setShowCountryList] = useState([]); // for getting all countries


  //get country list
  const getCountry =async() =>
  {
    try{
      let apiRes = await axios.get(`${API_BASE_URL}login/countries`)
      if (apiRes.data.res == true) {
        setShowCountryList(apiRes.data.data);
      }else{

      }
        
    }catch (error) {
      console.error(error)
    }   
  }

  // get client information
  const getClientInfos = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${id}`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowClientData(apiRes.data.data[0]);
        }
        else
        {
          // NotificationManager.error(apiRes.data.res, "", 2000);
        }
      }
    }
    catch (error)
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  };

  const updateClientData = async(values,{resetForm}) =>{
    console.log(values);
      try {
        setLoading(true);
        let apiRes = await(
          axios
          .post(
          ` ${API_BASE_URL}account/editaccount`,{
              id: values.id,
              first_name: values.first_name,
              last_name: values.last_name,
              ph_no: values.ph_no.toString(),
              email_address: values.email_address,
              state: values.state,
              address: values.address,
              city: values.city,
              zipcode: values.zipcode,
              country: values.country,
              password: values.password,
              designation : values.designation,
              role: '2'  // 2 for Client
            },
          )
        )
        if(apiRes) {
          if(apiRes.data.res == true) {
            NotificationManager.success('Update Successfully','',2000);
            setLoading(false);
            navigate("/client-my-account");
          }
          else {
            setLoading(false);
            NotificationManager.error(apiRes.data.msg, '',2000);
          }
        }
      }
      catch (error) {
        console.error(error);
      }
  }
  
  let schema = yup.object().shape({
  
      first_name: yup
      .string()
      .required('First name is required'),

      last_name: yup
      .string()
      .required('Last name is required'),
  
      ph_no: yup
      .string()
      .required('Phone number is required')
      .max(10,'Invalid Phone number'),

      email_address: yup
      .string()
      .required('Email is required')
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
        
      address: yup
      .string()
      .required('Street Address is required'),

      state: yup
      .string()
      .required('State is required'),

      city: yup
      .string()
      .required('City is required'),

      zipcode: yup
      .string()
      .required('Zip Code is required'),

      country: yup
      .string()
      .required('Country is required'),

      designation: yup
      .string()
      .required('Designation is required'),

  });  

  useEffect(() => 
  {
    getClientInfos();
    getCountry();
  }, []);

  return (
    <div>
      <Clientheader />
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Edit Profile</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
          <Formik
            enableReinitialize
            initialValues={{
              id: showClientData && showClientData.id,
              first_name: showClientData && showClientData.first_name,
              last_name: showClientData && showClientData.last_name,
              ph_no: showClientData && showClientData.ph_no,
              email_address: showClientData && showClientData.email_address,
              address: showClientData && showClientData.address,
              state: showClientData && showClientData.state,
              city: showClientData && showClientData.city,
              zipcode: showClientData && showClientData.zipcode,
              country: showClientData && showClientData.country,
              // organization: showClientData && showClientData.organization,
              // assigned_number_of_job: showClientData && showClientData.assigned_number_of_job? showClientData.assigned_number_of_job:'0',
              designation: showClientData && showClientData.designation,
            }}
            onSubmit={(values,{resetForm})=>{updateClientData(values,{resetForm})}}
            validationSchema = {schema}
          >
            {({
                handleChange,
                handleSubmit,
                errors,
                values, 
                setFieldValue
            })=>(
              <div className="AccBox">
                <div className="pagetitle">
                  <h3>Personal Details</h3>
                  {loading ? (
                    <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i class="fa fa-refresh fa-spin"></i>Update</Button>
                  ):(
                    <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Update</Button>
                  )}
                  </div>
                  <ul className="AccDtls EditAccDtls addEditorForm row">
                    <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>First Name</label>
                        <input type="text" className="InptField" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} disabled value={values.first_name}/>
                      </div>
                      {errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>Last Name</label>
                        <input type="text" className="InptField" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} disabled value={values.last_name}/>
                      </div>
                      {errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>Phone Number</label>
                        <input type="number" className="InptField" id="phoneInput" placeholder="Phone Number" name="ph_no"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                        </div>
                      {errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                    </li>

                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>Email Address</label>
                        <input type="email" className="InptField" id="emailInput" placeholder="Email Address" name="email_address" disabled onChange={handleChange} value={values.email_address}/>
                      </div>
                      {errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>Street Address</label>
                        <input type="text" className="InptField" name="address" placeholder="Street Address" onChange={handleChange} value={values.address}/>
                      </div>
                      {errors.address ? <div className='error'>{errors.address}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>State</label>
                        <input type="text" className="InptField" name="state" placeholder="State" onChange={handleChange} value={values.state}/>
                      </div>
                      {errors.state ? <div className='error'>{errors.state}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>City</label>
                        <input type="text" className="InptField" name="city" placeholder="City" onChange={handleChange} value={values.city}/>
                      </div>
                      {errors.city ? <div className='error'>{errors.city}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>Zip Code</label>
                        <input type="text" className="InptField" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                      </div>
                      {errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                    </li>
                    <li className="col-md-3">
                      <div className="small-input-box">
                        <label>Country</label>
                        <select class="select-input" id="selectCountry" aria-label="Floating label select example" name="country" onChange={handleChange} value={values.country}>
                          <option selected hidden>Country</option>
                          {showCountryList && showCountryList.map((item,index)=>{
                            return(
                              <option value={item?.country_name}>{item?.country_name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </li>
                      <li className="col-md-3">
                        <div className="small-input-box">
                         <input type="hidden" className="InptField" placeholder="Designation" name="designation" onChange={handleChange} disabled value={values.designation}/>
                        </div>
                      </li>
                      {/* <li className="col-md-3"> */}
                      {/* <input className="InptField" type="text" defaultValue="Woodstock" /> */}
                      {/* <input type="password" className="InptField" id="passwordInput" placeholder="Password" name="password" onChange={handleChange} value={values.password}/> */}
                      {/* {errors.password ? <div className='error'>{errors.password}</div> : null} */}
                      {/* </li> */}
                      {/* <li className="col-md-3"> */}
                      {/* <input className="InptField" type="text" defaultValue={30188} /> */}
                      {/* <input type="password" className="InptField" id="confirmInput" placeholder="Confirm Password" name="cpassword" onChange={handleChange} value={values.cpassword}/> */}
                      {/* {errors.cpassword ? <div className='error'>{errors.cpassword}</div> : null} */}
                      {/* </li> */}
                  </ul>
              </div>
            )}
           </Formik>
        </section>
      </main>
    </div>
  );
};

export default Clienteditaccount;
