import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppleLogin from 'react-apple-login';
// import ClientAppleLogin from '../ClientComponents/ClientAppleLogin.css';

const ClientAppleLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin)
      {
        const { data } = event;
        const parsedData = JSON.parse(data);
        // console.log('Data: ', parsedData)

        // Extract code and id_token
        const codeData = parsedData.data.authorization.code ? parsedData.data.authorization.code : '';
        const idTokenData = parsedData.data.authorization.id_token ? parsedData.data.authorization.id_token : '';
        const stateData = parsedData.data.authorization.state ? parsedData.data.authorization.state : '';
        const userData = parsedData.data.authorization.user ? parsedData.data.authorization.user: '';

        if (codeData && idTokenData)
        {
          // console.log('Navigating with code - id_token - state - user:', codeData, idTokenData,stateData,userData);
          navigate('/apple-callback', { state: { codeData, idTokenData, stateData, userData } });
        }
        else
        {
          console.error('Authorization code or ID token is missing');
        }
      }
      else
      {
        console.error('Even origin and window origin both are not same!' , event.origin, window.location.origin);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [navigate]);

  const handleSuccess = (response) => {
    console.log('Apple login success:', response);
  };

  const handleFailure = (error) => {
    console.error('Apple login failed:', error);
  };

  return (
    <AppleLogin
      clientId="com.elitereviser.webapp.services"
      redirectURI={`${window.location.origin}/beta/apple-callback`}
      usePopup={true}
      scope="email name"
      responseMode="web_message"
      responseType="code id_token"
      state="state"
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      render={(renderProps) => (
        <button
          id="appleid-signin"
          onClick={renderProps.onClick}
          className='apple-login-btn'
          // style={{
          //   width: 188,
          //   height: 40,
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   background: 'black',
          //   color: 'white',
          //   borderRadius: 5,
          //   cursor: 'pointer',
          // }}
        >
          <i className="fa-brands fa-apple px-2"></i>
          Continue with Apple
        </button>
      )}
    />
  );
};

export default ClientAppleLogin;
 