import React, {Component, useState, useEffect} from "react";
import { NavLink, useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Editorheader from "../../Components/EditorComponents/Editorheader";
import Editorsidebar from "../../Components/EditorComponents/Editorsidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { get_date_format, lastLoginformatDate } from '../../utility/Common';
import {NotificationManager} from 'react-notifications'; 
import { Rating } from 'react-simple-star-rating';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const lineOptions = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//       position: 'top'
//     },
//   },
//   scales: {
//     x: {
//         grid: {
//         },
//         ticks: {
//           color: '#525252',
//           font: {
//             size: 13,
//             family: "'Poppins', sans-serif",
//             weight: 400
//           }
//         }
//     },
//     y: {
//         grid: {
//         },
//         ticks: {
//           color: '#525252',
//           font: {
//             size: 13,
//             family: "'Poppins', sans-serif",
//             weight: 400
//           }
//         }
//     },
//   },
// };


const userImg = require("../../assets/img/user-img.png");

const Editordashboard = () => {
  const [arrData,setArrData] = useState([]);
  const [userData, setUserData] = useState({
    type: 'line',
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        // label: 'Dataset 1',
        label: 'Completed Jobs',
        // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
        data: arrData,
        borderColor: 'rgb(11, 14, 120)',
        backgroundColor: 'rgb(11, 14, 120)',
        borderWidth: 2,
        pointRadius: 5,
      },
    ]
  }) 
  const [showDataByEditor,setShowDataByEditor] = useState(); //editor data
  const [showShortName,setShowShortName] = useState(); // short name 
  const [showRating,setShowRating] = useState(); // set show rating
  const [allOngoing,setAllOngoing] = useState(); // count all ongoing
  const [allComplete,setAllComplete] = useState(); // count all complete
  const navigate = useNavigate();
  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  console.log(loggedUser)

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
    },
    scales: {
      x: {
          grid: {
            min: 0,
            max: 0,
            grace: '5%',
          },
          title: {
            display: true,
            text: 'Month',
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          },
          ticks: {
            color: '#525252',
            // stepSize: 0.5,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
      y: {
        min: 0,
        max: allComplete,
        grace: '5%',
          grid: {
          },
          title: {
            display: true,
            text: 'Number of Jobs',
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          },
          ticks: {
            color: '#525252',
            precision:0,
            // stepSize: 1,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
    },
  };




  useEffect(() => {
    dashboardDataByEditor();
}, []);

useEffect(() => {
  if (loggedUser=== null)
  {
      navigate("/login");
      navigate(0);
  }
  else if(loggedUser!=null && (loggedUser?.role == '2' || loggedUser?.role == '0'))
  {
    navigate("/");
  }
})
useEffect(() => {
  completeJobCount();
}, []);


  //fetch current month name
  const currentMonth = (month) =>{
    switch(month) {
      case '01':
        return 'JANUARY';
      case '02':
        return 'FEBRUARY';
      case '03':
        return 'MARCH';
      case '04':
        return 'APRIL';
      case '05':
        return 'MAY';
      case '06':
        return 'JUNE';
      case '07':
        return 'JULY';
      case '08':
        return 'AUGUST';
      case '09':
        return 'SEPTEMBER';
      case '10':
        return 'OCTOBER';
      case '11':
        return 'NOVEMBER';
      case '12':
        return 'DECEMBER';
      default:
        return '';
    }
  }

  //Complete Job count per month
  const completeJobCount = async() =>{
    try 
    {
      // https://www.demoupdates.com/updates/elite/api/job/numberofjobspermonth?editor_id=132&all_id=editor_id
      let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobspermonth?id=${loggedUser?.id}&all_id=editor_id`)
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setArrData(apiRes.data.data);

          setUserData({
            type: 'line',
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
              {
                // label: 'Dataset 1',
                label: 'Completed Jobs',
                // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
                data: apiRes.data.data,
                borderColor: 'rgb(11, 14, 120)',
                backgroundColor: 'rgb(11, 14, 120)',
                borderWidth: 2,
                pointRadius: 5,
              },
            ]
          }) 

        }
      }
    } 
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }


  const dashboardDataByEditor = async() =>{
    try {
      // https://www.demoupdates.com/updates/elite/api/job/numberofjobs?editor_id=115
      let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobs?editor_id=${loggedUser?.id}`)
      if (apiRes) {
        if (apiRes.data.res == true) {
          let split_val = apiRes.data.data.editorname.split(' ');
          // console.log(split_val)
          let fname = split_val[0].charAt(0);
          let lname = split_val[1].charAt(0);
          let full_name = fname + lname;
          // console.log(full_name)
          setShowDataByEditor(apiRes.data.data);
          setShowShortName(full_name);
          // console.log(apiRes.data.data)
          setAllOngoing(apiRes.data.data.ongoing);
          setAllComplete(apiRes.data.data.complete);
        }else{
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    }
    catch (error)
    {
        
        // NotificationManager.error(error.message, "", 2000);
    }
 }


  return (
    <div>
      <Editorheader />
      <Editorsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
          <span className="editorNameLogo">{showShortName}</span>
            <h2>Dashboard</h2>
          </span>
        </div>
        <section className="section dasboardPnl">
          <div className="row">
          <div className="col-md-4">
              <div className="card dashboard-card word-count-card dash-board-card-box-2">
                <div className="border-box">
                  {/* <p>Total Word Count for this month</p> */}
                  <h4>Total Word Count</h4>
                  <h5>{currentMonth(showDataByEditor?.currentMonth)}</h5>
                </div>
                <h3>{showDataByEditor?.totalWordcount}</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card dashboard-card total-amount-card dash-board-card-box-2">
                <div className="border-box">
                  <h4>Total Amount</h4>
                  <h5>{currentMonth(showDataByEditor?.currentMonth)}</h5>
                  {/* <h3>$600</h3> */}
                </div>
                {showDataByEditor?.totalPrice === undefined ? (
                  // <h3>$0.00</h3>
                  <h3></h3>
                ):(
                  <h3>${parseFloat(parseInt(showDataByEditor?.totalPrice)).toFixed(2)}</h3>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="card dashboard-card completed-jobs-card dash-board-card-box-2">
                <div className="border-box">
                  {/* <p>Number of Jobs for this month</p> */}
                  <h4>Completed Jobs</h4>
                  <h5>{currentMonth(showDataByEditor?.currentMonth)}</h5>
                  {/* <h3>150</h3> */}
                </div>
                <h3>{allComplete}</h3>
              </div>
            </div>
            <div className="col-md-8 d-none d-md-block">
              <div className="card dashboard-card2 chart-card">
                {/* <h3>Work Flow Cart</h3> */}
                <h3>Workflow Chart</h3>
                {/* <ul className="rating-list"> */}
                  {/* <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-half"></i></li>
                  <li><i class="bi bi-star"></i></li> 
                  <li><span className="rating-text">out of 5</span></li>*/}
                {/* </ul> */}
                <span className="star-rating-span">
                  <Rating initialValue={showDataByEditor?.rating} readonly/>
                  <span className="rating-text">{showDataByEditor?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>
            </div>

            <div className="col-md-4">
              <div className="card dashboard-card ongoing-jobs-card dash-board-card-box-2">
                <div className="border-box">
                  {/* <p>Number of Jobs for this month</p> */}
                  <h4>Ongoing Jobs</h4>
                  <h5>{currentMonth(showDataByEditor?.currentMonth)}</h5>
                  {/* <h3>150</h3> */}
                  {/* <h3>{showDataByAdmin?.jobCount}</h3> */}
                </div>
                <h3>{allOngoing}</h3>
              </div>

              <div className="d-md-none card dashboard-card2 chart-card">
                {/* <h3>Work Flow Cart</h3> */}
                <h3>Workflow Chart</h3>
                {/* <ul className="rating-list"> */}
                  {/* <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-half"></i></li>
                  <li><i class="bi bi-star"></i></li> 
                  <li><span className="rating-text">out of 5</span></li>*/}
                {/* </ul> */}
                <span className="star-rating-span">
                  <Rating initialValue={showDataByEditor?.rating} readonly/>
                  <span className="rating-text">{showDataByEditor?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>
              
              <div className="card dashboard-card2 user-card">
                <div className="user-top">
                  <div className="row">
                    {/* <div className="col-md-3"><span className="user-img-sec"><img src={userImg} alt="" /></span></div> */}
                    <div className="col-md-3">
                      <span className="user-img-sec">
                        {showDataByEditor?.editorname?.match(/\b\w/g)?.join('')}
                      </span>
                    </div>
                    <div className="col-md-9 user-text-sec">
                      {/* <h4>Molly Williams</h4> */}
                      <h4>{showDataByEditor?.editorname}</h4>
                      {/* <p>Number of Jobs of this month</p> */}
                      <p>Last sign-in: {lastLoginformatDate(showDataByEditor?.LastLogin)}</p>
                    </div>
                  </div>
                </div>
                <ul className="user-details-list">
                  {/* <li>Joining Date: <strong>30 Aug, 2018</strong></li>
                  <li>Designation: <strong>Senior Editor</strong></li>
                  <li>Email Address: <strong>molly@19williams.info</strong></li>
                  <li>Address: <strong>629 N. Shady Road</strong></li>
                  <li>Country: <strong>United States of America</strong></li>
                  <li>State: <strong>Boston</strong></li> */}

                  <li>Joining Date: <strong>{get_date_format(showDataByEditor?.joiningDate)}</strong></li>
                  <li>Designation: <strong>{showDataByEditor?.designation}</strong></li>
                  <li>Email Address: <strong>{showDataByEditor?.useremail}</strong></li>
                  <li>Address: <strong>{showDataByEditor?.address}</strong></li>
                  <li>Country: <strong>{showDataByEditor?.country}</strong></li>
                  {/* <li>State: <strong>{showDataByEditor?.designation}</strong></li> */}
                </ul>
                <a className="my-account-link">
                  <Link to="/editor-my-account">
                   {/* Go to My Account */}
                   Go to My Profile
                  </Link>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Editordashboard;
