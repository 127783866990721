import {
  React, useEffect, useState,
  NavLink, useNavigate, Link,
  axios, 
  NotificationManager,
  Modal,
} from '../../utility/CommonImport';

import $ from "jquery";
import { API_BASE_URL} from '../../api_url';
import { get_date_format } from '../../utility/Common';

import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";

const reviewIcon = require("../../assets/img/ReviewIcon.png");
const docFileImg = require("../../assets/img/DocFileImg.png");
const attachFileIcon = require("../../assets/img/attach-icon.png");
const linkIcon = require("../../assets/img/link-icon.png");
const feedbackStarImg = require("../../assets/img/feedbackStar.png");


const InternalEditorManagementByDeputy = () => {
  const navigate = useNavigate();
  const opencompleteMyJobPnl = () => { 
    $(".completeMyJob").addClass("Act");
  };
  const closecompleteMyJobPnl = () => { 
    $(".completeMyJob").removeClass("Act");
  };
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showInternalList,setShowInternalList] = useState([]); // for all deputy
  const [statusType,setStatusType] = useState();
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }
  const [showModal, setShowModal] = useState(false);
  const modalclose  = () => setShowModal(false)
  const [buttonValue,setButtonValue] = useState({UserId:'',status:''});
  let data= JSON.parse(localStorage.getItem("user_data_elite"));

  //To Inactive
  const toInactive=async(UserId,status)=>{
    setStatusType('InActive');
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account/changestatus?id=${UserId}&status=${status}`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          getAllInternalEditor();
          modalclose();
        }
        else
        {
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }

  //To active
  const toActive=async(UserId,status)=>{
    setStatusType('InActive');
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account/changestatus?id=${UserId}&status=${status}`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          getAllInternalEditor();
          modalclose();
        }
        else
        {
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }

   // get all deputy
  const getAllInternalEditor = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowInternalList(apiRes.data.data);
        }
        else
        {
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error)
    {
      console.error(error);
    }
  };


  // delete Editor
  const deleteInternal = async (user_id) => {
    if (window.confirm("Are you sure you want to delete?"))
    { 
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}account/deleteaccount?id=${user_id}`)
        if (apiRes) 
        {
          if (apiRes.data.res == true) 
          {
            getAllInternalEditor();
            NotificationManager.success('Successfully Deleted','',2000);
            navigate("/deputy-admin-internal-editor-management");
          }
          else
          {
            console.error(apiRes.data.res);
          }
        }
        else
        {
          console.error('Something Went Wrong!');
        }
      }
      catch (error)
      {
        console.error(error);
      }
    }
  };

  // internal editor earning page
  const handleRedirectEditorEaringByDeputy = (user_id,user_name) =>{
    navigate('/internal-editor-earining-by-deputy',{state:{
      user_id:user_id,
      user_name: user_name
    }})
  }

  useEffect(() => {
    getAllInternalEditor();
  }, []);

  return (
    <div>
      <DeputyAdminHeader />
      <DeputyAdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Internal Editor Management</h2>
          </span>
            <div className="pagetitle">
                <NavLink to="/deputy-admin-internal-editor-management/add-new-internal-editor" className="btn btn-primary CmnBtn">
                  Add Internal Editor
                </NavLink>
            </div>
        </div>
        <section className="section OdrDtlsPnl OdrDtlsPnlNew" style={{marginTop:'-35px'}}>
        <table width="100%" className="CmnTbl myJobTable admin-editor-managment table-striped blue-header-table admin-management-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Designation</th>
                <th className="text-center">Date Added</th>
                <th className="text-center">Email</th>
                <th className="text-center">Phone No.</th>
                <th>Address</th>
                <th className="text-center">City</th>
                <th className="text-center">State</th>
                <th className="text-center">Zip Code</th>
                <th className="text-center">Country</th>
                <th className="text-center">Status</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {showInternalList?.length > 0 ? (
                showInternalList?.map((list,index)=>{
                  return(
                    <tr className="not-set-job">
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center" onClick={()=>handleRedirectEditorEaringByDeputy(list?.id,list?.first_name)}>{list?.first_name} {list?.last_name}</td>
                      <td className="text-center">{list?.designation}</td>
                      <td className="text-center">
                        <span className="GreenTxt">{get_date_format(list?.created_at)}</span>
                      </td>
                      <td className="text-center">{list?.email_address}</td>
                      <td className="text-center">{list?.ph_no}</td>
                      <td className="text-center">{list?.address}</td>
                      <td className="text-center">{list?.city}</td>
                      <td className="text-center">{list?.state}</td>
                      <td className="text-center">{list?.zipcode}</td>
                      <td className="text-center">{list?.country}</td>
                      <td className="text-center">
                        {list.status=='1'?
                          <a onClick={()=>{setShowModal(true);
                            setButtonValue(
                            {
                              UserId:list.id,
                              status:0
                            });
                            setStatusType('Inactive');
                            }}
                          >
                          <span class="badge bg-active-green" style={{cursor:'pointer'}}>Active</span></a>: <a onClick={()=>{
                            setShowModal(true);
                            setButtonValue(
                            {
                              UserId:list.id,
                              status:1
                            });
                            setStatusType('Active');
                            }}
                          >
                          <span class="badge bg-light-red" style={{cursor:'pointer'}}>Inactive</span></a>
                          }
                      </td>
                      
                      <td className="text-center">
                        <Link to = {`/deputy-admin-internal-editor-management/view-internal/${list?.id}`}>
                          <i class="bi bi-eye"></i>       
                        </Link>
                      </td>

                      <td className="text-center">
                        <Link to = {`/deputy-admin-internal-editor-management/edit-internal/${list?.id}`}> 
                          <i class="bi bi-pen"></i>       
                        </Link>
                      </td>

                      <td className="text-center"><i class="bi bi-trash" onClick={()=>deleteInternal(list?.id)}></i></td>
                    </tr>
                  )
                })
              ):(
                <tr>
                  <td className='no-data' colSpan={15} style={{background: "none"}}><p style={{textAlign:'center'}}>No Record Found.</p></td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </main>

      <Modal show={showModal}  onHide={modalclose} className="CmnModal changeStatusModal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure to change status to {statusType}?</h3>
            {statusType && statusType!="Active"?(
              <button onClick={()=>toActive(buttonValue.UserId,buttonValue.status)} className="CmnBtn yesBtn">
                {statusType}
              </button>
            ):(
              <button onClick={()=>toInactive(buttonValue.UserId,buttonValue.status)} className="CmnBtn yesBtn">
                {statusType}
              </button>
            )}
        </Modal.Body>
      </Modal> 
    </div>
  );
};

export default InternalEditorManagementByDeputy;
