import React, {Component, useState, useEffect} from "react";
import { NavLink, useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Rating } from 'react-simple-star-rating';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { get_date_format, lastLoginformatDate } from '../../utility/Common';
import {NotificationManager} from 'react-notifications'; 

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const userImg = require("../../assets/img/user-img.png");

const DeputyAdmindashboard = () => {
  const [arrData,setArrData] = useState([]);
  const [userData, setUserData] = useState({
    type: 'line',
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        // label: 'Dataset 1',
        label: 'Completed Jobs',
        // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
        data: arrData,
        borderColor: 'rgb(11, 14, 120)',
        backgroundColor: 'rgb(11, 14, 120)',
        borderWidth: 2,
        pointRadius: 5,
      },
    ]
  }) 
  const [showDataByDeputy,setShowDataByDeputy] = useState(); //Deputy Admin data
  const [showDeputyInfo,setShowDeputyInfo] = useState(); //Deputy Admin Info
  const [showShortName,setShowShortName] = useState(); // short name 
  const navigate = useNavigate();
  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  console.log(loggedUser) 


  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
    },
    scales: {
      x: {
          grid: {
            min: 0,
            max: 70,
            grace: '5%',
          },
          title: {
            display: true,
            text: 'Month',
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          },
          ticks: {
            color: '#525252',
            // stepSize: 0.5,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
      y: {
        min: 0,
        max: showDataByDeputy?.complete,
        grace: '5%',
          grid: {
          },
        title: {
            display: true,
            text: 'Number of Jobs',
            font: {
            size: 13,
            family: "'Poppins', sans-serif",
            weight: 400
          }
        },
          ticks: {
            color: '#525252',
            precision:0,
            // stepSize: 0.5,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
    },
  };

  useEffect(() => {
    dashboardDataByDeputy();
    loggedDeputyData();
    completeJobCount();
    // getAllOngoingJobs();
    // getAllCompletedJobs();
  }, []);
  
  useEffect(() => {
    if (loggedUser=== null)
    {
        navigate("/admin-login");
        navigate(0);
    }
    else if(loggedUser?.role == '1' || loggedUser?.role == '2' || loggedUser?.role == '4')
    {
        navigate("/login");
    }
  })

  //fetch current month name
  const currentMonth = (month) =>{
    switch(month) {
      case '01':
        return 'JANUARY';
      case '02':
        return 'FEBRUARY';
      case '03':
        return 'MARCH';
      case '04':
        return 'APRIL';
      case '05':
        return 'MAY';
      case '06':
        return 'JUNE';
      case '07':
        return 'JULY';
      case '08':
        return 'AUGUST';
      case '09':
        return 'SEPTEMBER';
      case '10':
        return 'OCTOBER';
      case '11':
        return 'NOVEMBER';
      case '12':
        return 'DECEMBER';
      default:
        return '';
    }
  }

    //Complete Job count per month
    const completeJobCount = async() =>{
      try 
      {
        // https://www.demoupdates.com/updates/elite/api/job/numberofjobspermonth?editor_id=132&all_id=editor_id
        let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobspermonth?id=`)
        if (apiRes) 
        {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            setArrData(apiRes.data.data);
            console.log(apiRes.data.data);
  
  
            setUserData({
              type: 'line',
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              datasets: [
                {
                  // label: 'Dataset 1',
                  label: 'Completed Jobs',
                  // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
                  data: apiRes.data.data,
                  borderColor: 'rgb(11, 14, 120)',
                  backgroundColor: 'rgb(11, 14, 120)',
                  borderWidth: 2,
                  pointRadius: 5,
                },
              ]
            }) 
  
          }
        }
      } 
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }

  const dashboardDataByDeputy = async() =>{
    try 
    {
        let apiRes = await axios.get(`${API_BASE_URL}job/numberofalljoblist`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                setShowDataByDeputy(apiRes.data.data);
                // console.log(apiRes.data.data)
            }
            else
            {
                NotificationManager.error(apiRes.data.msg, "", 2000);
            }
        }
    }//close try
    catch (error)
    {
        // NotificationManager.error(error.message, "", 2000);
    }
 }

 //deputy infos
 const loggedDeputyData = async() =>{
  try 
  {
      // let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobsByDeputyId`)
      let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobsByDeputyId?deputy_id=${loggedUser?.id}`)
      if (apiRes) 
      {
          if (apiRes.data.res == true) 
          {
            setShowDeputyInfo(apiRes.data.data);
            console.log(apiRes.data.data);
          }
          else
          {
              NotificationManager.error(apiRes.data.msg, "", 2000);
          }
      }
  }
  catch (error)
  {
      // NotificationManager.error(error.message, "", 2000);
  }
}

  //redirect component
  const redirectComponent = (section) => {
      switch(section) {
        case 1:
          navigate("/deputy-admin-job-board");
          break;
        case 2:
          navigate("/deputy-admin-assign-jobs");
          break;
        case 3:
          navigate("/deputy-admin-completed-job");
          break;
        case 4:
          navigate("/deputy-admin-on-going-job");
          break;
        // case 5:
        //   navigate("/admin-completed-job");
        default:
          return 0;
      }
  }

  return (
    <div>
     <DeputyAdminHeader />
     <DeputyAdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
          {/* <span className="editorNameLogo">{showShortName}</span> */}
            <h2>Dashboard</h2>
          </span>
        </div>
        <section className="section dasboardPnl">
          <div className="row">
            {/* <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card dash-card-1">
                <p>Total Word Count</p>
                <p>{currentMonth(showDataByDeputy?.currentMonth)}</p>
                <h3>{showDataByDeputy?.totalWordcount}</h3>
              </div>
            </div> */}

            <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card submitted-jobs-card dash-board-card-box-2">
                <div className="border-box">
                  <h4>Submitted Jobs</h4>
                  <h5>{currentMonth(showDataByDeputy?.currentMonth)}</h5>
                  {/* <h3>150</h3> */}
                </div>
                <h3>{showDataByDeputy?.jobCount}</h3>
              </div>
            </div>

            <div className="col-md-4" onClick={()=>redirectComponent(2)}>
              <div className="card dashboard-card dash-card-2 dash-board-card-box-2">
                <div className="border-box">
                  {/* <h4>Unassigned Jobs</h4> */}
                  <h4>Assigned Jobs</h4>
                  <h5>{currentMonth(showDataByDeputy?.currentMonth)}</h5>
                </div>
                {/* <h3>{showDataByDeputy?.notassigned}</h3> */}
                <h3>{showDataByDeputy?.assigned}</h3>
                {/* <h3>0</h3> */}
              </div>
            </div>

            <div className="col-md-4" onClick={()=>redirectComponent(3)}>
              <div className="card dashboard-card completed-jobs-card dash-board-card-box-2">
                  <div className="border-box">
                    {/* <p>Number of Jobs for this month</p> */}
                    <h4>Completed Jobs</h4>
                    <h5>{currentMonth(showDataByDeputy?.currentMonth)}</h5>
                    {/* <h3>150</h3> */}
                  </div>
                  <h3>{showDataByDeputy?.complete}</h3>
              </div>
            </div>

            {/* <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card dash-card-2">
                <p>Total Amount</p>
                <p>{currentMonth(showDataByDeputy?.currentMonth)}</p>
                <h3>${parseFloat(parseInt(showDataByDeputy?.totalPrice)).toFixed(2)}</h3>
              </div>
            </div> */}

            {/* <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card dash-card-3">
                <p>Submitted Jobs</p>
                <p>{currentMonth(showDataByDeputy?.currentMonth)}</p>
                <h3>{showDataByDeputy?.jobCount}</h3>
              </div>
            </div> */}
 
            <div className="col-md-8 d-none d-md-block">
              <div className="card dashboard-card2 chart-card">
                {/* <h3>Work Flow Cart</h3> */}
                <h3>Workflow Chart</h3>
                {/* <ul className="rating-list">
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-half"></i></li>
                  <li><i class="bi bi-star"></i></li>
                  <li><span>3.5 out of 5</span></li>
                </ul> */}
                <span className="star-rating-span">
                  <Rating initialValue={showDataByDeputy?.rating} readonly/>
                  <span className="rating-text">{showDataByDeputy?.rating === null ? '0' : showDataByDeputy?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>
            </div>
            <div className="col-md-4">
              

              <div className="card dashboard-card ongoing-jobs-card dash-board-card-box-2" onClick={()=>redirectComponent(4)}> 
                <div className="border-box">
                  {/* <p>Number of Jobs for this month</p> */}
                  <h4>Ongoing Jobs</h4>
                  <h5>{currentMonth(showDataByDeputy?.currentMonth)}</h5>
                  {/* <h5>{showDataByDeputy?.ongoing}</h5> */}
                  {/* <h3>150</h3> */}
                  {/* <h3>{showDataByDeputy?.jobCount}</h3> */}
                </div>
                <h3>{showDataByDeputy?.ongoing}</h3>
              </div>

              <div className="d-md-none card dashboard-card2 chart-card">
                {/* <h3>Work Flow Cart</h3> */}
                <h3>Workflow Chart</h3>
                {/* <ul className="rating-list">
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-fill"></i></li>
                  <li><i class="bi bi-star-half"></i></li>
                  <li><i class="bi bi-star"></i></li>
                  <li><span>3.5 out of 5</span></li>
                </ul> */}
                <span className="star-rating-span">
                  <Rating initialValue={showDataByDeputy?.rating} readonly/>
                  {/* <span className="rating-text">{showDataByDeputy?.rating} out of 5</span> */}
                  <span className="rating-text">{showDataByDeputy?.rating === null ? '0' : showDataByDeputy?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>

              <div className="card dashboard-card2 user-card">
                <div className="user-top">
                  <div className="row">
                    <div className="col-md-3"><span className="user-img-sec">{showDeputyInfo?.editorname?.match(/\b\w/g)?.join('')}<img  alt="" /></span></div>
                    <div className="col-md-9 user-text-sec">
                      <h4>{showDeputyInfo?.editorname}</h4>
                      {/* <p>Number of Jobs of this month</p> */}
                      {/* <p>Last sign-in: Dec 23,2023 | 7:59 AM</p> */}
                      <p>Last sign-in: {lastLoginformatDate(showDeputyInfo?.LastLogin)}</p>
                    </div>
                  </div>
                </div>
                <ul className="user-details-list">
                  <li>Joining Date: <strong>{get_date_format(showDeputyInfo?.joiningDate)}</strong></li>
                  <li>Designation: <strong>{showDeputyInfo?.designation}</strong></li>
                  <li>Email Address: <strong>{showDeputyInfo?.useremail}</strong></li>
                  <li>Address: <strong>{showDeputyInfo?.address}</strong></li>
                  <li>Country: <strong>{showDeputyInfo?.country}</strong></li>
                </ul>
                <a className="my-account-link">
                  <Link to="/deputy-admin-my-account">
                   {/* Go to My Account */}
                   Go to My Profile
                  </Link>
                </a>
              </div>
            </div>
        
          </div>
        </section>
      </main>
    </div>
  );
};

export default DeputyAdmindashboard;
