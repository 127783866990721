import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'react-notifications/lib/notifications.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "./App.css";
import "./Dashboard.css";
import "./Dashboard-custom.css";
import "./Dashboard-Responsive.css";
import { Routes, Route } from "react-router-dom";
import {NotificationContainer} from 'react-notifications';
import Home from "./Pages/CommonPages/Home";
import About from "./Pages/CommonPages/About";
import HowOurProcessWorks from "./Pages/CommonPages/HowOurProcessWorks";
import Career from "./Pages/CommonPages/Career";
import PartnerWithUs from "./Pages/CommonPages/PartnerWithUs";
import Services from "./Pages/CommonPages/Services";
import Servicesexistingclient from "./Pages/CommonPages/Servicesexistingclient";
import Servicesnewclient from "./Pages/CommonPages/Servicesnewclient";
import Serviceschoosecv from "./Pages/CommonPages/Serviceschoosecv";
import Contact from "./Pages/CommonPages/Contact";
import Faq from "./Pages/CommonPages/Faq";
import Signup from "./Pages/ClientPages/Signup";
import Login from "./Pages/LogPages/Login";
 

// --Import Client Start--
import Clientmyaccount from "./Pages/ClientPages/Clientmyaccount";
import Clienteditaccount from "./Pages/ClientPages/Clienteditaccount";
import Clienteditpassword from "./Pages/ClientPages/Clienteditpassword";
import Clientmyorders from "./Pages/ClientPages/Clientmyorders";

import Settings from "./Pages/ClientPages/Settings";
import Billingdetails from "./Pages/ClientPages/Billingdetails";
import ClientNotification from "./Pages/ClientPages/ClientNotification";
import Payment from "./Pages/PaymentPages/Payment";
import Paymentsuccess from "./Pages/PaymentPages/Paymentsuccess";
// --Import Client End--


// --Import Editor Start--
import Editordashboard from "./Pages/EditorPages/Editordashboard";
import Editorjobboard from "./Pages/EditorPages/Editorjobboard";
import Editormyjobs from "./Pages/EditorPages/Editormyjobs";
import Editormyaccount from "./Pages/EditorPages/Editormyaccount";
import Editoreditaccount from "./Pages/EditorPages/Editoreditaccount";
import Editoreditpassword from "./Pages/EditorPages/Editoreditpassword";
import Editorsettings from "./Pages/EditorPages/Editorsettings";
import Editormyearnings from "./Pages/EditorPages/Editormyearnings";
import Editorfeedback from "./Pages/EditorPages/Editorfeedback";
import EditorNotification from "./Pages/EditorPages/EditorNotification";
// --Import Editor End--


// --Import Admin Start--
import AdminLogin from "./Pages/LogPages/AdminLogin";
import Admindashboard from "./Pages/AdminPages/Admindashboard";
import Adminjobboard from "./Pages/AdminPages/Adminjobboard";
import AdminOnGoingJob from "./Pages/AdminPages/AdminOnGoingJob";
import AdminHoldingJobs from "./Pages/AdminPages/AdminHoldingJobs";
import AdminReturnedJobs from "./Pages/AdminPages/AdminReturnedJobs";
import AdminCompletedJobs from "./Pages/AdminPages/AdminCompletedJobs";
import AdminCancelledJobs from "./Pages/AdminPages/AdminCancelledJobs";
import DeputyManagementByAdmin from "./Pages/AdminPages/DeputyManagementByAdmin";
import AddNewDeputyByAdmin from "./Pages/AdminPages/AddNewDeputyByAdmin";
import EditDeputyByAdmin from "./Pages/AdminPages/EditDeputyByAdmin";
import ViewDeputyByAdmin from "./Pages/AdminPages/ViewDeputyByAdmin";
import InternalEditorManagementByAdmin from "./Pages/AdminPages/InternalEditorManagementByAdmin";
import AddNewInternalEditorByAdmin from "./Pages/AdminPages/AddNewInternalEditorByAdmin";
import EditInternalByAdmin from "./Pages/AdminPages/EditInternalByAdmin";
import ViewInternalByAdmin from "./Pages/AdminPages/ViewInternalByAdmin";
import AdminEditorManagement from "./Pages/AdminPages/AdminEditorManagement";
import AddNewEditor from "./Pages/AdminPages/AddNewEditor";
import EditEditor from "./Pages/AdminPages/EditEditor";
import ViewEditor from "./Pages/AdminPages/ViewEditor";
import AdminClientManagement from "./Pages/AdminPages/AdminClientManagement";
import AddNewClientByAdmin from "./Pages/AdminPages/AddNewClientByAdmin";
import ViewClient from "./Pages/AdminPages/ViewClient";
import EditClient from "./Pages/AdminPages/EditClient";
import Adminmyaccount from "./Pages/AdminPages/Adminmyaccount";
import Admineditpassword from "./Pages/AdminPages/Admineditpassword";
import Adminsettings from "./Pages/AdminPages/Adminsettings";
import Adminfeedback from "./Pages/AdminPages/Adminfeedback";
import EditormyearningsByAdmin from "./Pages/AdminPages/EditormyearningsByAdmin";
import InternalEditormyearningsByAdmin from "./Pages/AdminPages/InternalEditormyearningsByAdmin";
import AdminBillingdetails from './Pages/AdminPages/AdminBillingdetails';
import AdminInquirydetails from "./Pages/AdminPages/AdminInquirydetails";
import AdminNotification from "./Pages/AdminPages/AdminNotification";
// import Admineditaccount from "./Admin/Admineditaccount";
// import Adminmyearnings from "./Admin/Adminmyearnings";
// import Adminmyjobs from "./Admin/Adminmyjobs";

// --Import Admin End--


// --Import Deputy Admin Start--
import DeputyAdmindashboard from "./Pages/DeputyPages/DeputyAdmindashboard";
import DeputyAdminjobboard from "./Pages/DeputyPages/DeputyAdminjobboard";
import DeputyAdminOnGoingJob from "./Pages/DeputyPages/DeputyAdminOnGoingJob";
import DeputyAdminHoldingJobs from "./Pages/DeputyPages/DeputyAdminHoldingJobs";
import DeputyAdminCompletedJobs from "./Pages/DeputyPages/DeputyAdminCompletedJobs";
import DeputyAdminCancelledJobs from "./Pages/DeputyPages/DeputyAdminCancelledJobs";
import DeputyAdminMyAccount from "./Pages/DeputyPages/DeputyAdminMyAccount";
import DeputyEditAccount from "./Pages/DeputyPages/DeputyEditAccount";
import DeputyEditPassword from "./Pages/DeputyPages/DeputyEditPassword";
import InternalEditorManagementByDeputy from "./Pages/DeputyPages/InternalEditorManagementByDeputy";
import AddNewInternalEditorByDeputy from "./Pages/DeputyPages/AddNewInternalEditorByDeputy";
import EditInternalByDeputy from "./Pages/DeputyPages/EditInternalByDeputy";
import ViewInternalByDeputy from "./Pages/DeputyPages/ViewInternalByDeputy";
import DeputyAdminManagement from "./Pages/DeputyPages/DeputyAdminManagement";
import AddNewEditorByDeputy from "./Pages/DeputyPages/AddNewEditorByDeputy";
import EditEditorByDeputy from "./Pages/DeputyPages/EditEditorByDeputy";
import ViewEditorByDeputy from "./Pages/DeputyPages/ViewEditorByDeputy";
import DeputyAdminClientManagement from "./Pages/DeputyPages/DeputyAdminClientManagement";
import AddNewClient from "./Pages/DeputyPages/AddNewClient";
import EditClientByDeputy from "./Pages/DeputyPages/EditClientByDeputy";
import ViewClientByDeputy from "./Pages/DeputyPages/ViewClientByDeputy";
import DeputyAdminsettings from "./Pages/DeputyPages/DeputyAdminsettings";
import DeputyAdminFeedback from "./Pages/DeputyPages/DeputyAdminFeedback";
import DeputyAdminBillingdetails from "./Pages/DeputyPages/DeputyAdminBillingdetails";
import DeputyAdminInquirydetails from "./Pages/DeputyPages/DeputyAdminInquirydetails";
import DeputyAdminAssignedJob from "./Pages/DeputyPages/DeputyAdminAssignedJob";
import DeputyAdminNotification from "./Pages/DeputyPages/DeputyAdminNotification";
import EditormyearningsByDeputy from "./Pages/DeputyPages/EditormyearningsByDeputy";
import InternalEditormyearningsByDeputy from "./Pages/DeputyPages/InternalEditormyearningsByDeputy";
// --Import Deputy Admin End--


import ForgetPassword from "./Pages/CommonPages/ForgetPassword";
import ResetPassword from "./Pages/CommonPages/ResetPassword";

// import DeputyAdminSidebar from "./Admin/DeputyAdmin/DeputyAdminSidebar";


// --Import Internal Editor Start--
import InternalEditorDashboard from "./Pages/InternalPages/InternalEditorDashboard";
import InternalEditorJobboard from "./Pages/InternalPages/InternalEditorJobboard";
import InternalEditorMyJobs from "./Pages/InternalPages/InternalEditorMyJobs";
import InternalEditorOnGoingJob from "./Pages/InternalPages/InternalEditorOnGoingJob";
import InternalEditorHoldingJobs from "./Pages/InternalPages/InternalEditorHoldingJobs";
import InternalEditorCompletedJobs from "./Pages/InternalPages/InternalEditorCompletedJobs";
import InternalEditorCancelledJobs from "./Pages/InternalPages/InternalEditorCancelledJobs";
import InternalEditorMyAccount from "./Pages/InternalPages/InternalEditorMyAccount";
import InternalEditAccount from "./Pages/InternalPages/InternalEditAccount";
import InternalEditorEditPassword from "./Pages/InternalPages/InternalEditorEditPassword";
import InternalEditorsettings from "./Pages/InternalPages/InternalEditorsettings";
import InternalEditorMyEarnings from "./Pages/InternalPages/InternalEditorMyEarnings";
import InternalEditorFeedback from "./Pages/InternalPages/InternalEditorFeedback";
import InternalEditorNotification from "./Pages/InternalPages/InternalEditorNotification";
import InternalAssignedJob from "./Pages/InternalPages/InternalAssignedJob";

// --Import Internal Editor End--



// import InternalEditorManagement from "./Admin/InternalEditor/InternalEditorManagement";
// import AddNewInternalEditor from "./Admin/InternalEditor/AddNewInternalEditor";
// import EditInternal from "./Admin/InternalEditor/EditInternal";
// import ViewInternal from "./Admin/InternalEditor/ViewInternal";



import Example from "./Example";
import Testing from "./Testing";
import PrivacyPolicy from "./Pages/CommonPages/PrivacyPolicy";
import TermsOfService from "./Pages/CommonPages/TermsOfService";
import Auth from "./ProtectedRoutes/Auth";
import NotLoginAuth from "./ProtectedRoutes/NotLoginAuth";
import CreditCardValidation from "./Pages/CreditCardValidation";
import StrongPassword from "./Pages/OtherLogins/StrongPassword";
import { Pagination } from "react-bootstrap";
import Page from "./Pages/OtherLogins/Page";
import Calculator from "./Pages/OtherLogins/Calculator";
import PasswordInput from "./Pages/OtherLogins/PasswordInput";
import CustomPopUp from "./Pages/OtherLogins/CustomPopUp";
import DropdownWithFilter from "./DropdownWithFilter";
import AppleCallback from "./Components/ClientComponents/AppleCallback";
import EmailVerification from "./Pages/CommonPages/EmailVerification";










// import TestPage from "./Pages/CommonPages/TestPage";


function App() {
  return (
    <div class="wrapper">
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/how-our-process-works' element={<HowOurProcessWorks />} />
          <Route path='/career' element={<Career />} />
          <Route path='/services' element={<Services />} />
          <Route path='/service/:name' element={<Servicesnewclient />} />
          <Route path='/services-choose-cv' element={<Serviceschoosecv />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/terms-of-service' element={<TermsOfService/>} />
          <Route path='/services-existing-client/:name' element={<Servicesexistingclient />} />
          <Route path='/partner-with-us' element={<PartnerWithUs/>} />
          <Route path="/user_verification/:verifycode/:lastid" element={<EmailVerification/>} />
          {/* https://elitereviser.com/beta/user_verification/151700400/232 */}
          <Route path='/admin-deputy-management/add-new-deputy-admin' element={<AddNewDeputyByAdmin />} />

        {/* Not Login Routes */}
        <Route element={<NotLoginAuth/>} >
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login/>} />
          <Route path='/admin-login' element={<AdminLogin />} />
        </Route>
        
        {/* Payment page access */}
        <Route element={<Auth allowedRoles={['0','2','3','4']}/>}>
          <Route path='/payment' element={<Payment />} />
          <Route path='/payment-success' element={<Paymentsuccess />} />
        </Route>
       
        {/* --Admin Routes-- */}
        <Route element={<Auth allowedRoles={['0']}/>}>
          <Route path='/admin-dashboard' element={<Admindashboard />} />
          <Route path='/admin-job-board' element={<Adminjobboard />} />
          <Route path='/admin-on-going-job' element={<AdminOnGoingJob />} />
          <Route path='/admin-holding-job' element={<AdminHoldingJobs />} />
          <Route path='/admin-returned-job' element={<AdminReturnedJobs />} />
          <Route path='/admin-completed-job' element={<AdminCompletedJobs />} />
          <Route path='/admin-cancelled-job' element={<AdminCancelledJobs />} />

          <Route path='/admin-deputy-management' element={<DeputyManagementByAdmin />} />
          {/* <Route path='/admin-deputy-management/add-new-deputy-admin' element={<AddNewDeputyByAdmin />} /> */}
          <Route path='/admin-deputy-management/edit-deputy-admin/:id' element={<EditDeputyByAdmin />} />
          <Route path='/admin-deputy-management/view-deputy-admin/:id' element={<ViewDeputyByAdmin />} />

          <Route path='/admin-editor-management' element={<AdminEditorManagement />} />
          <Route path='/admin-editor-management/add-new-editor' element={<AddNewEditor />} />
          <Route path='/admin-editor-management/edit-editor/:id' element={<EditEditor />} />
          <Route path='/admin-editor-management/view-editor/:id' element={<ViewEditor />} />

          <Route path='/admin-client-management' element={<AdminClientManagement />} />
          {/* <Route path='/adding/signing-up' element={<AddNewClientByAdmin/>} /> */}
          <Route path='/admin-client-management/edit-client/:id' element={<EditClient />} />
          <Route path='/admin-client-management/view-client/:id' element={<ViewClient />} />

          <Route path='/admin-internal-editor-management' element={<InternalEditorManagementByAdmin/>} />
          <Route path='/admin-internal-editor-management/add-new-internal-editor' element={<AddNewInternalEditorByAdmin />} />
          <Route path='/admin-internal-editor-management/edit-internal/:id' element={<EditInternalByAdmin />} />
          <Route path='/admin-internal-editor-management/view-internal/:id' element={<ViewInternalByAdmin />} />

          <Route path='/admin-my-account' element={<Adminmyaccount/>} />
          <Route path='/admin-my-account/admin-edit-password' element={<Admineditpassword />} />
          <Route path='/admin-pricing-settings' element={<Adminsettings />} />
          <Route path='/admin-feedback' element={<Adminfeedback />} />
          <Route path='/editor-earining-by-admin' element={<EditormyearningsByAdmin />} />
          <Route path='/internal-editor-earining-by-admin' element={<InternalEditormyearningsByAdmin />} />
          <Route path='/admin-billing-details' element={<AdminBillingdetails />} />
          <Route path='/admin-inquiry-details' element={<AdminInquirydetails />} />
          <Route path="/admin-notification" element={<AdminNotification/>} />
        </Route>

        {/* --Editor Routes-- */}
        <Route element={<Auth allowedRoles={['1']}/>}>
          <Route path='/editor-dashboard' element={<Editordashboard />} />
          <Route path='/editor-job-board' element={<Editorjobboard />} />
          <Route path='/editor-my-jobs' element={<Editormyjobs />} />
          <Route path='/editor-my-account' element={<Editormyaccount />} />
          {/* <Route path='/editor-my-account/:id' element={<Editormyaccount />} /> */}
          <Route path='/editor-my-account/editor-edit-account/:id' element={<Editoreditaccount />} />
          <Route path='/editor-my-account/editor-edit-password' element={<Editoreditpassword />} />
          <Route path='/editor-settings' element={<Editorsettings />} />
          <Route path='/editor-my-earnings' element={<Editormyearnings />} />
          <Route path='/editor-feedback' element={<Editorfeedback />} />
          <Route path='/editor-notifications' element={<EditorNotification />} />
        </Route>

        {/* --Added Client by Admin and Deputy-Admin-- */}
        <Route element={<Auth allowedRoles={['0','3']}/>}>
          <Route path='/adding/signing-up' element={<AddNewClient/>} />
        </Route>

        {/* Apple redirect route */}
        <Route path="/apple-callback" element={<AppleCallback />} />

        {/* --Client Routes-- */}
        <Route element={<Auth allowedRoles={['2']}/>}>
          <Route path='/client-my-account' element={<Clientmyaccount />} />
          <Route path='/client-my-account/client-edit-account/:id' element={<Clienteditaccount />} />
          <Route path='/client-my-account/client-edit-password' element={<Clienteditpassword />} />
          <Route path='/client-my-orders' element={<Clientmyorders />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/billing-details' element={<Billingdetails />} />
          <Route path='/client-notifications' element={<ClientNotification />} />
          {/* <Route path="/apple-callback" element={<AppleCallback />} /> */}
        </Route>

        {/* --Deputy Admin Routes-- */}
        <Route element={<Auth allowedRoles={['3']}/>}>
          <Route path='/deputy-admin-dashboard' element={<DeputyAdmindashboard />} />
          <Route path='/deputy-admin-job-board' element={<DeputyAdminjobboard />} />
          <Route path='/deputy-admin-on-going-job' element={<DeputyAdminOnGoingJob />} />
          <Route path='/deputy-admin-holding-job' element={<DeputyAdminHoldingJobs />} />
          <Route path='/deputy-admin-completed-job' element={<DeputyAdminCompletedJobs />} />
          <Route path='/deputy-admin-cancelled-job' element={<DeputyAdminCancelledJobs />} />
          <Route path='/deputy-admin-my-account' element={<DeputyAdminMyAccount />} />
          <Route path='/deputy-admin-my-account/deputy-admin-edit-account/:id' element={<DeputyEditAccount />} />
          <Route path='/deputy-admin-my-account/deputy-admin-edit-password' element={<DeputyEditPassword />} />

          <Route path='/deputy-admin-internal-editor-management' element={<InternalEditorManagementByDeputy />} />
          <Route path='/deputy-admin-internal-editor-management/add-new-internal-editor' element={<AddNewInternalEditorByDeputy />} />
          <Route path='/deputy-admin-internal-editor-management/edit-internal/:id' element={<EditInternalByDeputy />} />
          <Route path='/deputy-admin-internal-editor-management/view-internal/:id' element={<ViewInternalByDeputy />} />

          <Route path='/deputy-admin-editor-management' element={<DeputyAdminManagement />} />
          <Route path='/deputy-admin-editor-management/add-new-editor' element={<AddNewEditorByDeputy />} />
          <Route path='/deputy-admin-editor-management/edit-editor/:id' element={<EditEditorByDeputy />} />
          <Route path='/deputy-admin-editor-management/view-editor/:id' element={<ViewEditorByDeputy />} />

          <Route path='/deputy-admin-client-management' element={<DeputyAdminClientManagement />} />
          {/* <Route path='/adding/signing-up' element={<AddNewClient/>} /> */}
          <Route path='/deputy-client-management/edit-client/:id' element={<EditClientByDeputy />} />
          <Route path='/deputy-client-management/view-client/:id' element={<ViewClientByDeputy />} />

          <Route path='/deputy-admin-pricing-settings' element={<DeputyAdminsettings />} />
          <Route path='/deputy-admin-feedback' element={<DeputyAdminFeedback />} />
          <Route path='/deputy-admin-billing-details' element={<DeputyAdminBillingdetails />} />
          <Route path='/deputy-admin-inquiry-details' element={<DeputyAdminInquirydetails />} />
          <Route path='/deputy-admin-assign-jobs' element={<DeputyAdminAssignedJob/>} />
          <Route path="/deputy-admin-notification" element={<DeputyAdminNotification/>} />
          {/* <Route path='/deputy-admin-sidebar' element={<DeputyAdminSidebar />} /> */}
          <Route path='/editor-earining-by-deputy' element={<EditormyearningsByDeputy/>} />
          <Route path='/internal-editor-earining-by-deputy' element={<InternalEditormyearningsByDeputy />} />
        </Route>

        {/* --Internal Editor Routes-- */}
        <Route element={<Auth allowedRoles={['4']}/>}>
          <Route path='/internal-editor-dashboard' element={<InternalEditorDashboard />} />
          <Route path='/internal-editor-job-board' element={<InternalEditorJobboard />} />
          <Route path='/internal-editor-my-job' element={<InternalEditorMyJobs />} />
          <Route path='/internal-on-going-job' element={<InternalEditorOnGoingJob />} />
          <Route path='/internal-holding-job' element={<InternalEditorHoldingJobs />} />
          <Route path='/internal-completed-job' element={<InternalEditorCompletedJobs />} />
          <Route path='/internal-cancelled-job' element={<InternalEditorCancelledJobs />} />
          <Route path='/internal-editor-my-account' element={<InternalEditorMyAccount />} />
          <Route path='/internal-editor-my-account/internal-editor-edit-account/:id' element={<InternalEditAccount />} />
          <Route path='/internal-editor-my-account/internal-editor-edit-password' element={<InternalEditorEditPassword />} />
          <Route path='/internal-editor-settings' element={<InternalEditorsettings />} />
          <Route path='/internal-editor-my-production' element={<InternalEditorMyEarnings />} />
          <Route path='/internal-editor-feedback' element={<InternalEditorFeedback />} />
          <Route path='/internal-editor-notification' element={<InternalEditorNotification />} />
          <Route path='/internal-editor-assigned-jobs' element={<InternalAssignedJob />} />
        </Route>

       
        {/* Forget Password and Reset Passwords */}
        <Route path= '/forget-Password' element = {<ForgetPassword/>} />
        <Route path= '/reset-Password/:token' element = {<ResetPassword/>} />


        <Route path="*" element={<p>There's nothing here: 404!<br/>Hello, the page you were looking for doesn't seem to exist anymore.</p>} />
        {/* <Route path= '/example' element = {<Example/>} /> */}
        {/* <Route path= '/pagination' element = {<Pagination/>} /> */}
        {/* <Route path= '/page' element = {<Page/>} /> */}
        {/* <Route path= '/calculator' element = {<Calculator/>} /> */}


        <Route path= '/testing' element = {<Testing/>} />
        {/* <Route path='/test-page' element={<TestPage />} /> */}
        {/* <Route path='/strong-password' element={<StrongPassword />} /> */}
        <Route path='/drop-down-with-filter' element={<DropdownWithFilter />} />
        <Route path='/credit-card-validation' element={<CreditCardValidation />} />
    
        <Route path='/password' element={<PasswordInput />} />
        <Route path='/custom-pop-up' element={<CustomPopUp />} />
      </Routes>
      {/* <Example/> */}
      <NotificationContainer/>
    </div>
  );
}

export default App;
