import {
  React, useEffect, useState,
  useNavigate,
  Formik,yup,
  axios,
  NotificationManager,
  Button,
  AOS
} from '../../utility/CommonImport'; 

import { API_BASE_URL } from "../../api_url";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const loginData = async (values, { resetForm }) => {
    try {
      setLoading(true);
      let apiRes = await axios.post(`${API_BASE_URL}login/signin`, {
        email_address: values.email_address,
        password: values.password,
        user_type: 'admin' 
      });
      if (apiRes) {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
            localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
            console.log( JSON.stringify(apiRes.data.data[0]))
            if(apiRes.data.data[0]?.role==0)
            {
              console.warn('admin')
                // navigate("/admin-my-account");
                navigate("/admin-job-board");
            }
            else if(apiRes.data.data[0]?.role==3)
            {
              console.warn('dep admin')
              navigate("/deputy-admin-dashboard");
            }
        } 
        else 
        {
          console.warn('error1')
            setLoading(false);
            NotificationManager.error(apiRes.data.msg, "", 2000);
        }
        }
    } catch (error) {
      console.warn('error2')
      //close try
      // NotificationManager.error(error.message, "", 2000);
    }
  };

  const handleKeyPress = (event,submitForm) => {
    if (event.key === 'Enter')
    {
      event.preventDefault();
      submitForm();
    }
  };

  let schema = yup.object().shape({
    email_address: yup
      .string()
      .required("Email is required")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address!"
      ),
    password: yup
        .string()
        .required("Password is required")
        .min(6)
        .max(20),
  });

  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Formik
        initialValues={{
          email_address: "",
          password: "",
        }}
        onSubmit={(values, { resetForm }) => {
          loginData(values, { resetForm });
        }}
        validationSchema={schema}
      >
        {({ handleChange, handleSubmit, errors, values, setFieldValue, touched, submitForm }) => (
          <div className="login-body">
            <div className="container">
              <h3>Admin Login</h3>
              <ul className="row login-form-list">
                <li className="col-12">
                  <div class="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="emailInput"
                      placeholder="Username/Email"
                      name ="email_address"
                      onChange={handleChange}
                      value={values.email_address}
                      onKeyDown={(e)=>{
                        if(e.key === 'Enter')
                        {
                          e.preventDefault();
                          document.getElementById("passwordInput").focus();
                        }
                      }}
                    />
                    <label for="emailInput"> <span>*</span>Email</label>
                  </div>
                  {errors.email_address && touched.email_address ? <div className='error'>{errors.email_address}</div> : null}
                </li>
                <li className="col-12">
                  <div class="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      id="passwordInput"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      onKeyPress={(e) => handleKeyPress(e, submitForm)}
                    />
                    <label for="passwordInput">
                      <span>*</span>Password
                    </label>
                  </div>
                  {errors.password && touched.password ? <div className='error'>{errors.password}</div> : null}
                </li>
                <li className="col-md-12">
                  {loading && loading ? (
                      <Button className="btn btn-primary" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Sign In</Button>
                  ):(
                      <Button className="btn btn-primary" type="submit"  onClick={()=>handleSubmit()}>Sign In</Button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AdminLogin;
