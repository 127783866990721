import React,{ Component, useEffect, useState, useRef } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
const dashboardLogo = require("../../assets/img/logo-new.svg").default;

const Editorheader = ({unreadMsg}) => { 
    const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[editorData,setEditorData] = useState();
    const [notificationMsg,setNotificationMsg] = useState();
    const [funcCall,setFuncCall] =useState(false);
    const sidebarRef = useRef(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(data)
        if (data=== null) 
        {
            navigate("/login");
        }
        else
        {
            setEditorData(data);
           
        }
        // getEditorNotification();
       
    }, []);

    useEffect(()=>{
      setTimeout(() => {
        setFuncCall(!funcCall)
        getEditorNotification();
      }, 5000);
    },[funcCall]);

    useEffect(() => {
        const select = (el, all = false) => {
          el = el.trim();
          if (all) {
            return [...document.querySelectorAll(el)];
          } else {
            return document.querySelector(el);
          }
        };
    
        const on = (type, el, listener, all = false) => {
          if (all) {
            select(el, all).forEach((e) => e.addEventListener(type, listener));
          } else {
            select(el, all).addEventListener(type, listener);
          }
        };
    
        const handleBodyClick = (e) => {
          // Check if the clicked element is outside the sidebar
          if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
            select('body').classList.add('toggle-sidebar');
          }
        };
    
        on('click', '.toggle-sidebar-btn', function (e) {
          select('body').classList.toggle('toggle-sidebar');
        });
    
        // Add the event listener to the document for body clicks
        //document.addEventListener('click', handleBodyClick);

        if (isMobile) { 
          document.addEventListener('click', handleBodyClick);
        }
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleBodyClick);
        };
      }, []); 

    const LogoutFunction=()=>
    {
        localStorage.removeItem("user_data_elite");
        localStorage.clear();
        navigate("/login");
    }

        //get Editor Notification
        const getEditorNotification= async() =>{
          try
          {
              let apiRes = await axios.post(`${API_BASE_URL}job/getNotification`, {
                  user_id: data?.id,
              });
              if (apiRes) 
              {
                  console.log(apiRes.data.res);
                  if (apiRes.data.res == true) 
                  {
                      setNotificationMsg(apiRes.data.NotificationUnred);
                  }
              }
          } //close try
          catch (error) 
          {
              // NotificationManager.error(error.message, "", 2000);
          }
      }
    return(
        <div>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between" ref={sidebarRef}><i className="bi bi-list toggle-sidebar-btn" /><NavLink to="#" className="logo d-flex align-items-center"><img src={dashboardLogo} alt="" /></NavLink></div>
                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li class="nav-item dropdown pe-3 notification-nav-item">
                            <Link to="/editor-notifications" class="nav-link">
                                <i class="fa fa-bell"></i>
                                {unreadMsg == undefined ? (
                                    <span className="badge badge-warning navbar-badge">{notificationMsg}</span>
                                ):(
                                    <span className="badge badge-warning navbar-badge">{unreadMsg}</span>
                                )}
                            </Link>
                        </li>


                        <li className="nav-item dropdown pe-3">
                          <Link  to="/editor-my-account" className="nav-link nav-user pe-0">
                            <i className="bi bi-person-fill" />
                          </Link>
                        </li>

                        <li className="nav-item dropdown pe-3">
                          <a className="nav-link nav-profile pe-0" href="#" data-toggle="dropdown">
                              {/* <i className="bi bi-person-fill" /> */}
                              <span className="dropdown-toggle">Hello, {editorData?.first_name}</span>
                              <span className="dropdown-role-span">{editorData?.designation}</span>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                              <a href="javascript:void(0);" className="dropdown-item d-flex align-items-center" onClick={()=>LogoutFunction()}><i className="bi bi-box-arrow-right" /> <span>Sign Out</span></a>
                          </ul>
                        </li>
                    </ul>
                </nav>
            </header>
        </div>
    );
};

export default Editorheader;