import React, {Component, useState, useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";

const Auth = ({allowedRoles}) => {
    const [userRole] = useState(JSON.parse(localStorage.getItem("user_data_elite"))?.role!=null? JSON.parse(localStorage.getItem("user_data_elite"))?.role : null);
  // useEffect(()=>{
  //   console.warn(userRole);
  //   console.warn(JSON.parse(localStorage.getItem("user_data_elite")))
  // },[])
    return (
    // isLoggedIn ? <Outlet /> : <Navigate to= '/login' />
    allowedRoles.find((role)=>role.includes(userRole))? <Outlet/> : <Navigate to= '/login' />
  )
}

export default Auth