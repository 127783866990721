
import {
  React, useEffect, useState,
  NavLink, Link, useLocation,
  axios,
  NotificationManager,
  AOS,
  Skeleton
} from '../../utility/CommonImport';
import "aos/dist/aos.css";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Loading from "../LoadingPage/Loading";
import { fetchAllServices } from "../../API/apiRequests";

const servicesBanner = require("../../assets/img/services-banner.jpg");
// const servicesImg1 = require("../../assets/img/services-img1.jpg");
// const servicesImg2 = require("../../assets/img/services-img2.jpg");
// const servicesImg3 = require("../../assets/img/services-img3.jpg");
// const servicesImg4 = require("../../assets/img/services-img4.jpg");
// const servicesImg5 = require("../../assets/img/services-img5.jpg");
// const servicesImg6 = require("../../assets/img/services-img6.jpg");

const Services = () => {
  // let data = JSON.parse(localStorage.getItem("user_data_elite"));
  // const location = useLocation();
  const [showServices, setShowServices] = useState([]);
  const [load,setLoad] = useState(true);

  // const { clientAccess } = location.state ?? "";
  // console.log(clientAccess);

  const getAllServices = async () => {
    try {
      setLoad(!load);
      let apiRes = await fetchAllServices();
      if (apiRes.data.res == true) {
        setShowServices(apiRes.data.data);
      }else{
        NotificationManager.warning('The server failed to fullfill a request.');
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(load);
    }
  }

  useEffect(() => {
    AOS.init();
  });
  
  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <div>
      <Header />
      <div className="inner-banner" style={{ backgroundImage: `url(${servicesBanner})` }}>
        <div className="container">
          <h3 data-aos="fade-up">Services</h3>
          <p data-aos="fade-up">We place great diligence on every work submitted. You can always count on us to achieve the best outcome.</p>
        </div>
      </div>
      <div className="services-sec"> 
        <div className="container">
          <h3>Choose Your Category And Get Started</h3>
          {/* <Suspense fallback={<Loading/>}>
            <AllServices/>
          </Suspense> */}
            {load ? (
              <ul className="row services-list">
                {showServices?.length > 0 && (
                  showServices?.map((item, index) => {
                    return (
                      <li className="col-md-6">
                        <div className="row">
                          <div className="col-md-5 img-section">
                            {item?.bg_image ? <img src={item?.bg_image} alt="Image" /> : <Skeleton variant="rectangular" width={230} height={267} />}
                          </div>
                          <div className="col-md-7 text-section">
                            <h4>{item?.title}</h4>
                            <p>{item?.short_description}</p>
                            <Link
                              className="btn btn-primary"
                              to={{ pathname: `/service/${item?.title.toLowerCase()}` }}
                              state={{ id: item?.id }}
                            >
                              Place Order
                            </Link>
                          </div>
                        </div>
                      </li>
                    )
                  })
                )}
              </ul>
            ) : <Loading/>}
        </div>
      </div>
      <div className="home-yellow-section align-items-center">
        <div className="container">
          <div className="row">9
            <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
              <h3>Still Not Sure What Service Is Right For You?</h3>
              <p>
                <NavLink to="/">Elite Reviser</NavLink> can handle that.
                <NavLink to="/contact"> Contact Us.</NavLink>
              </p>
            </div>
            <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
              <NavLink to="/services" className="btn btn-primary">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>  
  );
};

export default Services; 
