import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { ColorRing } from  'react-loader-spinner'
import PayPal from "./PayPal";
import { v4 as uuid } from 'uuid';
import { useCreditCardValidator, images } from 'react-creditcard-validator';

const paymentBanner = require("../../assets/img/payment-banner.jpg");
const cardImg = require("../../assets/img/card-img.png");

const Payment = () => {
  const navigate = useNavigate();
  const [allData,setAllData] = useState();
  const [fullName,setFullName] = useState();
  const [clientData,setClientData] = useState();
  const [isPaymentSuccess,setIsPaymentSuccess] = useState(false);
  const [isValidationError,setIsValidationError] = useState(false);
  const pay = useRef();
  const unique_id = uuid();
  const small_id = unique_id.slice(0,8)
  
  // First Name - Card Holder Name
  // Credit Card Number
  // CVC
  // Card Expiration
  // MM/YY

  // Card Payment details
  const [cardHolderName,setCardHolderName] = useState();
  const [cardNumber,setCardNumber] = useState();
  const [cvcNumber,setCvcNumber] = useState();
  const [cardExpire,setCardExpire] = useState();

  const [showError,setShowError] = useState(false);
  const [checkOut,setCheckOut] = useState(false);

  const [priceRegWordUpdate,setPriceRegWordUpdate] = useState();
  const [priceExpWordUpdate,setPriceExpWordUpdate] = useState();

  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  let data= JSON.parse(localStorage.getItem("allFormData"));
  
  // setAllData(data)
  console.log(data);
  console.log(loggedUser);

  useEffect(()=>{
    fetchAmount();
  },[])


  // console.log(data?.price);
  // console.log((data?.price * 10)/100);
  // console.log(parseFloat(data?.price) + ((data?.price * 10)/100));

//  let vat_service_price = data?.total_price + ((data?.total_price * 10)/100);
//  let vat_cv_price = parseFloat(data?.price) + ((data?.price * 10)/100);

 let vat_service_price = data?.total_price;
 let vat_cv_price = parseFloat(data?.price);


 const expDateValidate = (month, year) => {
    if (Number(year) > 2035)
    {
      return 'Expiry Date Year cannot be greater than 2035';
    }
    return;
  }

  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs }
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  const userData = async() => {
    try
    {
        let apiRes = await axios.get(`${API_BASE_URL}account?id=${loggedUser.id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                console.log(apiRes.data.data[0]);
                let fullname= apiRes.data.data[0].first_name + ' ' + apiRes.data.data[0].last_name;
                
                setClientData(apiRes.data.data[0]);
                localStorage.setItem('user_infos', JSON.stringify(apiRes.data.data[0]));
                setFullName(fullname);
            }
        }
    }
    catch (error)
    {
        //close try
        // NotificationManager.error(error, "", 2000);
    }
  }

const calculateVAT =(price)=>{
  let vat = (parseFloat(price*10)/100);
   return vat.toFixed(2);
}

const calculateTotal =(price)=>{
  let vat = (parseFloat(price*10)/100).toFixed(2);
  let total = parseFloat(price) + parseFloat(vat);
 return total.toFixed(2);
}

  const newData = async() => {
    if( (cardHolderName == undefined || cardHolderName == null || cardHolderName == '') &&
        (cardHolderName == undefined || cardHolderName == null || cardHolderName == '' ) &&
        (cardNumber == undefined || cardNumber == null || cardNumber == '' ) &&
        (cardExpire == undefined || cardExpire == null || cardExpire == '' ))
    {
      setShowError(true);
      NotificationManager.warning('Enter Payment details','',2000);
    }
    else if( cardHolderName == undefined || cardHolderName == null || cardHolderName == '' )
    {
      setShowError(true);
    }
    else if( cardNumber == undefined || cardNumber == null || cardNumber == '' )
    {
      setShowError(true);
    }
    else if( cvcNumber == undefined || cvcNumber == null || cvcNumber == '' )
    {
      setShowError(true);
    }
    else if( cardExpire == undefined || cardExpire == null || cardExpire == '' )
    {
      setShowError(true);
    }
    else
    {
      // alert('1')
      let split_data = cardExpire.split('/');
      
      if(data?.link === "cvtype")
      {
        // alert('2')
          var job_title = '#' + ' '+ data?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ data?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week';
          var full_name = clientData.first_name + ' ' + clientData.last_name;
          try 
          {
            setIsPaymentSuccess(true);
            const formData = new FormData();
              
            formData.append('user_id',loggedUser?.id);// customer_id
            formData.append('service_name',data?.service_name);
            formData.append('english_version',data?.english_version);
            formData.append('service_filename',data?.service_filename);
            formData.append('number_of_pages',data?.pages);
            formData.append('total_wordcount',data?.total_wordcount);
            formData.append('cv_price', (parseFloat(data?.price).toFixed(2)));
            // formData.append('GrandTotal',(parseFloat(vat_cv_price).toFixed(2)));

            formData.append('total_price',0);
            // formData.append('service_hour',data?.service_hour);
            formData.append('service_hour',168); // for a week hours
            formData.append('service_type',data?.service_type);
            formData.append('client_instruction',data?.client_instruction);
            formData.append('instruction_filename',data?.instruction_filename);
            formData.append('from_count',data?.from_count);
            formData.append('to_count',data?.to_count);
            formData.append('job_type','type2');
            formData.append('job_request','0');
  
            formData.append('number',cardNumber);
            formData.append('exp_month',split_data[0]);
            formData.append('exp_year',split_data[1]);
            formData.append('cvc',cvcNumber);

            formData.append('line1',clientData.address);
            formData.append('postal_code',clientData.zipcode);
            formData.append('city','');
            formData.append('state',clientData.state);
            formData.append('country',clientData.country);
            formData.append('email',clientData.email_address);
            formData.append('name',full_name);
            formData.append('GrandTotal',(parseFloat(vat_cv_price).toFixed(2)));
            formData.append('description',job_title);
            formData.append('price_per_rate',data?.service_type == 0 ? priceRegWordUpdate:priceExpWordUpdate);
            formData.append('client_approve','0');
            // if(data?.service_type == 0)
            // {
            //   formData.append('price_per_rate',priceRegWordUpdate);
            // }
            // else
            // {
            //   formData.append('price_per_rate',priceExpWordUpdate);
            // }
              
  
              // cv_price   , number_of_pages
              const config = {     
                  headers: { 'content-type': 'multipart/form-data' }
              }
              // let apiRes = await axios.post(`${API_BASE_URL}cvresume`,formData,config) 
              // let apiRes = await axios.post(`${API_BASE_URL}job`,formData,config) 
              let apiRes = await axios.post(`${API_BASE_URL}job/jobinsert2`,formData,config) 
              if (apiRes) {
                if (apiRes.data.res == true) {
                  setIsPaymentSuccess(false);
                  navigate('/payment-success', { state: { 
                    jobid: apiRes.data.JobId,
                    cardExpire:cardExpire,
                    cardNumber:cardNumber,
                    cvc:cvcNumber,
                    orderId:apiRes.data.Job.OrderId,
                    transactionDate:apiRes.data.Job.created_at,
                    userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                    userAddress: apiRes.data.user.address,
                    userState: apiRes.data.user.state,
                    userZipCode: apiRes.data.user.zipcode,
                    userCountry: apiRes.data.user.country,
                    // OrderType: data?.order_type
                    OrderType: 'New Order'
                  }});
                }
                else{
                  setIsPaymentSuccess(false);
                  setIsValidationError(true);
                  setTimeout(() => {
                    setIsValidationError(false);
                    setIsPaymentSuccess(false);
                  }, 4000);
                  navigate('/payment');
                }
              }
          }catch (error) {
            // setLoading(false);
            // NotificationManager.error(error, '',2000);
          }
      }
      else if(data?.link === "Proofreading")
      {
        // alert('Proofreading')
          // var job_title = '#' + ' ' + '|' +' ' + data?.service_name +' ' + '|' +' ' + data?.sub_service +' ' + '|' +' ' +'Word Count' + data?.total_wordcount +' ' + '|' + ' ' + data?.service_type + ' ' + '|' + data?.service_hour + ' '  +'hrs'
          var job_title = '#' +' ' + data?.service_name +' ' + '|' +' ' + data?.sub_service +' ' + '|' +' ' +'Word Count' + data?.total_wordcount +' ' + '|' + ' ' + data?.service_type + ' ' + '|' + data?.service_hour + ' '  +'hrs'
          var full_name = clientData.first_name + ' ' + clientData.last_name;
          // if(data?.role == '1')
          console.log(loggedUser?.role,data?.job_post_for);
          if(loggedUser?.role == '2' && data?.job_post_for == 'client_self')  //2 'client_self'
          {
            // alert('001');
            try
            {
                setIsPaymentSuccess(true);
                const formData = new FormData();
                formData.append('user_id',loggedUser?.id);// customer_id
                formData.append('service_id','2');
                formData.append('service_name',data?.service_name);
                formData.append('sub_service',data?.sub_service);
                formData.append('english_version',data?.english_version);
    
    
                formData.append('service_filename',data?.service_filename);
                formData.append('total_wordcount',data?.total_wordcount);
                formData.append('total_price',(parseFloat(data?.total_price).toFixed(2)));
                formData.append('cv_price',0);
                formData.append('service_hour',data?.service_hour);
                formData.append('service_type',data?.service_type);
                formData.append('client_instruction',data?.client_instruction);
                formData.append('instruction_filename',data?.instruction_filename);
                formData.append('full_description',data?.client_instruction);
                formData.append('from_count',data?.from_count);
                formData.append('to_count',data?.to_count);
                formData.append('job_type','type1');
                formData.append('editor_id',data?.editor_id ? data?.editor_id : '');
                formData.append('editor_name',data?.editor_name ? data?.editor_name : '');
                formData.append('editor_email',data?.editor_email ? data?.editor_email : '');
                formData.append('job_status',data?.editor_id === ''? 1:2);
                formData.append('job_request', data?.editor_id == '' ? '0' : '1');
                formData.append('assign_user', data?.role == '1' ? 'Editor' : data?.role == '4' ? 'Internal' : '');

                formData.append('number',cardNumber);
                formData.append('exp_month',split_data[0]);
                formData.append('exp_year',split_data[1]);
                formData.append('cvc',cvcNumber);

                formData.append('line1',clientData.address);
                formData.append('postal_code',clientData.zipcode);
                formData.append('city','');
                formData.append('state',clientData.state);
                formData.append('country',clientData.country);
                formData.append('email',clientData.email_address);
                formData.append('name',full_name);
                formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                formData.append('description',job_title);
                formData.append('price_per_rate',data?.service_type == 0 ? priceRegWordUpdate:priceExpWordUpdate);
                formData.append('client_approve','0');

                const config = {     
                    headers: { 'content-type': 'multipart/form-data' }
                }
                // let apiRes = await axios.post(`${API_BASE_URL}job/existingjobinsert`,formData,config)
                let apiRes = await axios.post(`${API_BASE_URL}job/jobinsert`,formData,config)
                    
                if (apiRes) 
                {
                  console.log(data);
                  console.log(data?.order_type);
                    console.log(apiRes.data.res);
                    if (apiRes.data.res == true) 
                    {
                      setIsPaymentSuccess(false);
                      // navigate('/payment-success');
                        console.warn(apiRes.data.JobId);
                        console.warn(apiRes.data.Job);
                      //  navigate('/payment-success', { state: { jobid: apiRes.data.JobId,cardExpire:cardExpire,cardNumber:cardNumber,cvc:cvcNumber}});
                      navigate('/payment-success', { state: { 
                        jobid: apiRes.data.JobId,
                        cardExpire:cardExpire,
                        cardNumber:cardNumber,
                        cvc:cvcNumber,
                        orderId:apiRes.data.Job.OrderId,
                        transactionDate:apiRes.data.Job.created_at,
                        userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                        userAddress: apiRes.data.user.address,
                        userState: apiRes.data.user.state,
                        userZipCode: apiRes.data.user.zipcode,
                        userCountry: apiRes.data.user.country,
                        OrderType: data?.order_type
                      }});
  
                        // data.submitStatus = 1;
                        // console.log(apiRes.data);
                        // setPaymentSuccessMsg(true);
                        // NotificationManager.success('Job Created successfully','',3000);
                        // NotificationManager.success('Email has been sent to you','',2000);
                        // localStorage.setItem('allFormData', JSON.stringify(data));
                          // localStorage.setItem("allFormData");
                        // navigate("/");
    
                        // localStorage.removeItem("allFormData");
                        // localStorage.clear();
                        // navigate("/");
                    }
                    else
                    {
                      // NotificationManager.error(apiRes.data.msg,'',2000);
                      setIsPaymentSuccess(false);
                      setIsValidationError(true);

                      setTimeout(() => {
                        setIsValidationError(false);
                        setIsPaymentSuccess(false);
                      }, 4000);
                      navigate('/payment');
                    }
                }
            }
            catch (error) 
            {
                // NotificationManager.error(error.message, '',2000);
            } 
          }
          else
          {
            // alert('2');
            console.log('user-role: ' + loggedUser?.role);
            console.warn('Role:' + data?.role);
            console.warn('Job Post: ' + data?.job_post_for);
            console.log(data);
              // alert('Internal Editor Selected')
              try
              {
                  setIsPaymentSuccess(true);
                  const formData = new FormData();
                  formData.append('user_id',data?.user_id); // customer_id
                  formData.append('service_id','');
                  formData.append('service_name',data?.service_name);
                  formData.append('sub_service',data?.sub_service);
                  formData.append('english_version',data?.english_version);
      
      
                  formData.append('service_filename',data?.service_filename);
                  formData.append('total_wordcount',data?.total_wordcount);
                  formData.append('total_price',(parseFloat(data?.total_price).toFixed(2)));
                  formData.append('cv_price',0);
                  formData.append('service_hour',data?.service_hour);
                  formData.append('service_type',data?.service_type);
                  formData.append('client_instruction',data?.client_instruction);
                  formData.append('instruction_filename',data?.instruction_filename);
                  formData.append('full_description',data?.client_instruction);
                  formData.append('from_count',data?.from_count);
                  formData.append('to_count',data?.to_count);
                  formData.append('job_type','type1');
                  formData.append('editor_id',data?.editor_id ? data?.editor_id : '');
                  formData.append('editor_name',data?.editor_name ? data?.editor_name : '');
                  formData.append('editor_email',data?.editor_email ? data?.editor_email : '');
                  formData.append('job_status',data?.editor_id === ''? 1:2);
                  formData.append('job_request', data?.editor_id == '' ? '0' : '1');
                  formData.append('assign_user', data?.role == '1' ? 'Editor' : data?.role == '4' ? 'Internal' : '');
  
                  formData.append('number',cardNumber);
                  formData.append('exp_month',split_data[0]);
                  formData.append('exp_year',split_data[1]);
                  formData.append('cvc',cvcNumber);
  
                  formData.append('line1',clientData.address);
                  formData.append('postal_code',clientData.zipcode);
                  formData.append('city','');
                  formData.append('state',clientData.state);
                  formData.append('country',clientData.country);
                  formData.append('email',clientData.email_address);
                  formData.append('name',full_name);
                  formData.append('GrandTotal',(parseFloat(vat_service_price).toFixed(2)));
                  formData.append('description',job_title);
                  formData.append('price_per_rate',data?.service_type == 0 ? priceRegWordUpdate:priceExpWordUpdate);
                  formData.append('client_approve','0');
  
                  const config = {     
                      headers: { 'content-type': 'multipart/form-data' }
                  }
                  // let apiRes = await axios.post(`${API_BASE_URL}job/existingjobinsert`,formData,config)
                  let apiRes = await axios.post(`${API_BASE_URL}job/jobinsert`,formData,config)
                      
                  if (apiRes) 
                  {
                      console.log(apiRes.data.res);
                      if (apiRes.data.res == true) 
                      {
                        setIsPaymentSuccess(false);
                        // navigate('/payment-success');
                          console.warn(apiRes.data.JobId);
                          console.warn(apiRes.data.Job);
                        //  navigate('/payment-success', { state: { jobid: apiRes.data.JobId,cardExpire:cardExpire,cardNumber:cardNumber,cvc:cvcNumber}});
                        navigate('/payment-success', { state: { 
                          jobid: apiRes.data.JobId,
                          cardExpire:cardExpire,
                          cardNumber:cardNumber,
                          cvc:cvcNumber,
                          orderId:apiRes.data.Job.OrderId,
                          transactionDate:apiRes.data.Job.created_at,
                          userName:apiRes.data.user.first_name + ' ' + apiRes.data.user.last_name,
                          userAddress: apiRes.data.user.address,
                          userState: apiRes.data.user.state,
                          userZipCode: apiRes.data.user.zipcode,
                          userCountry: apiRes.data.user.country,
                          OrderType: data?.order_type
                        }});
    
                          // data.submitStatus = 1;
                          // console.log(apiRes.data);
                          // setPaymentSuccessMsg(true);
                          // NotificationManager.success('Job Created successfully','',3000);
                          // NotificationManager.success('Email has been sent to you','',2000);
                          // localStorage.setItem('allFormData', JSON.stringify(data));
                            // localStorage.setItem("allFormData");
                          // navigate("/");
      
                          // localStorage.removeItem("allFormData");
                          // localStorage.clear();
                          // navigate("/");
                      }
                      else
                      {
                        // NotificationManager.error(apiRes.data.msg,'',2000);
                        setIsPaymentSuccess(false);
                        setIsValidationError(true);
  
                        setTimeout(() => {
                          setIsValidationError(false);
                          setIsPaymentSuccess(false);
                        }, 4000);
                        navigate('/payment');
                      }
                  }
              }
              catch (error) 
              {
                  setIsPaymentSuccess(false);
                  setIsValidationError(false);
                  // NotificationManager.error(error.message, '',2000);
              } 
          }
          // return
      }
      else
      {
        console.error('Error in data link:' + data?.link);
      }
    }
  }

  //Fetch All Amount
  const fetchAmount = async() =>{
    // console.log(commissionUpdate);
      try{
      let apiRes = await(
        axios
        .post(
          // `${API_BASE_URL}job/getPagecountPrice`,{
          `${API_BASE_URL}cvresume/getPagecountPrice`,{
            },
          )
        )
        if(apiRes)
        {
          if(apiRes.data.res == true) {
            // console.log('Reg:' ,apiRes.data.Pagecount.regularprice)
            setPriceRegWordUpdate(apiRes.data.Pagecount.regularprice);
            setPriceExpWordUpdate(apiRes.data.Pagecount.expeditedprice);

            // setPriceRegWordUpdate(apiRes.data.Pagecount.regularprice);
            // setPriceExpWordUpdate(apiRes.data.Pagecount.expeditedprice);
            localStorage.setItem('Elite_Service_price', JSON.stringify(apiRes.data.Pagecount));
          }
          else{
            NotificationManager.error('Not updated','',2000);
          }
        }
      } 
      catch (error) {
        console.error(error);
      }
  }

function formatString(event) {
  var inputChar = String.fromCharCode(event.keyCode);
  var code = event.keyCode;
  var allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }

  event.target.value = event.target.value.replace(
    /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
  ).replace(
    /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
  ).replace(
    /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
  ).replace(
    /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
  ).replace(
    /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
  ).replace(
    /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
  ).replace(
    /\/\//g, '/' // Prevent entering more than 1 `/`
  );
  setCardExpire(event.target.value);
}

function formatCvc(event) {
 console.warn(event.target.value.length)
}

  console.log(data);
  console.log(loggedUser);

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const handleExpiryDateChange = (e) => {
    setCardExpire(e.target.value);
  };

  const handleCvcChange = (e) => {
    setCvcNumber(e.target.value);
  };

  useEffect(() => {
    AOS.init();
    userData();
    // setAllData(data);
  }, []);
  return (
    <div>
      <Header />
      <div
        className="inner-small-banner"
        style={{ backgroundImage: `url(${paymentBanner})` }}
      >
        <div className="container">
          <h3 data-aos="fade-up">Order Details</h3>
        </div>
      </div>
      <div className="payment-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 left-payment-sec">
              <h3>Payment Method</h3>
              <Tab.Container
                defaultActiveKey="stripe"
                id="uncontrolled-tab-example"
                className=""
              >
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="stripe">
                      <span className="stripe">Stripe</span>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                      <Nav.Link eventKey="credit"><span>Credit Card/Debit Card</span></Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="paypal">
                      <span className="paypal">Paypal</span>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                        <Nav.Link eventKey="stripe"><span>Stripe</span></Nav.Link>
                      </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="alipay">
                      <span className="alipay">Alipay</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="stripe">
                    <div className="payment-tabs-content">
                      <ul className="row payment-list">
                        <li className="col-md-12">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="fullInput"
                              placeholder="Full Name"
                              onChange={(e)=>setCardHolderName(e.target.value)}
                            />
                            <label for="fullInput">Card Holder Name</label>
                          </div>
                          {(showError && (cardHolderName == undefined || cardHolderName == '' || cardHolderName == null)) && (<div className='error'><p>Card Holder Name is required</p></div>)}
                        </li>
                        <li className="col-md-12">
                          <div class="form-floating">
                            <input
                              {...getCardNumberProps({
                                className: 'form-control',
                                placeholder: 'Credit Card Number',
                                onChange: handleCardNumberChange
                              })}
                            />
                            <label for="lastInput">Credit Card Number</label> 
                          </div>
                          {showError && (<small className='error'>{erroredInputs.cardNumber && erroredInputs.cardNumber}</small>)}
                        </li>

                        <li className="col-lg-6">
                          <ul className="row sub-form-list">
                            <li className="col-6">
                              <label className="custom-label">
                                Card Expiration
                              </label>
                            </li>
                            <li className="col-6">
                              <div class="form-floating">
                                <input
                                  {...getExpiryDateProps({
                                    className: 'form-control',
                                    placeholder: 'MM/YY',
                                    onChange: handleExpiryDateChange
                                  })}
                                />
                                <label for="phoneInput">MM / YY</label>
                                {showError && (<small className='error'>{erroredInputs.expiryDate && erroredInputs.expiryDate}</small>)}
                              </div>
                            </li>
                          </ul>
                        </li>

                        <li className="col-lg-6">
                          <ul className="row sub-form-list sub-form-list2">
                            <li className="col-md-6">
                              <div class="form-floating">
                                <input
                                  {...getCVCProps({
                                    className: 'form-control',
                                    placeholder: 'CVC',
                                    onChange: handleCvcChange
                                  })}
                                />
                                <label for="phoneInput">CVC</label>
                              </div>
                              {showError && (<small className='error'>{erroredInputs.cvc && erroredInputs.cvc}</small>)}
                            </li>
                          </ul>
                        </li>

                        {isValidationError && (
                          <h5 className='error'>Your card number is incorrect.</h5>
                        )}
                        <li className="col-md-12">
                          {/* <input type="submit" name="" value="Submit Order" className="btn btn-primary" /> */}
                          {isPaymentSuccess? (
                            // <button disabled><i class="fa fa-refresh fa-spin"></i>Please Wait..</button>
                            <>
                              <h6>Please Wait..We're Processing Your Payment.</h6>
                              
                              <ColorRing
                              visible={true}
                              height="100"
                              width="100"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                            </>
                            
                          ):(
                            <button
                              className="btn btn-primary"
                              // to="/payment-success"
                              onClick={()=>newData()}
                            >
                              Submit Order
                            </button>
                          )}
                          
                          <img src={cardImg} alt="" />
                        </li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="paypal">
                    <div className="">
                      {checkOut ? (
                        <PayPal 
                          price={
                            data?.link=='cvtype' ? vat_cv_price : 
                            data?.link === "Proofreading" ? vat_service_price : vat_service_price
                          }
                          clientData = {clientData}
                          data = {data}
                          // regRate = {priceRegWordUpdate?priceRegWordUpdate:0}
                          // expRate = {priceExpWordUpdate?priceExpWordUpdate:0}

                          loggedUser = {loggedUser}
                        />
                      ):(
                        <button className="btn btn-primary" onClick={setCheckOut(true)}>Submit Order</button>
                      )}
                  
                    </div>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="credit">
                                        <div className="">tab 3</div>
                                    </Tab.Pane> */}
                  <Tab.Pane eventKey="alipay">
                    <div className="">tab 4</div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div className="col-lg-4 col-md-5 right-payment-sec">
              {/* <div className="gray-summary-box">
                <h4>Order Summary</h4>
                {data?.link=='cvtype'?(
                  <>
                    <table className="table">
                      <tr>
                        <td>Category:</td>
                        <td>{data?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Number of Pages :</td>
                        <td>
                          {data?.pages}
                        </td>
                      </tr>
                     
                      

                      <tr>
                        <td>
                          <strong>SubTotal: </strong>
                        </td>
                        <td>
                          
                          <strong> ${parseFloat(data?.price).toFixed(2)}</strong>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>VAT(10%): </strong>
                        </td>
                        <td>
                          
                          <strong> ${calculateVAT(data?.price)}</strong>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Grand Total: </strong>
                        </td>
                        <td>
                          
                          <strong> ${calculateTotal(data?.price)}</strong>
                        </td>
                      </tr>

                    </table>
                  </>
                ):(
                  <>
                      <table className="table">
                      <tr>
                        <td>Category:</td>
                       
                        <td>{data?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Option:</td>
                       
                        <td>
                          {data?.sub_service}
                        </td>
                      </tr>
                      <tr>
                        <td>Word Count:</td>
                       
                        <td>{data?.total_wordcount}</td>
                      </tr>
                      <tr>
                        <td>Services:</td>
                        
                       
                        {data?.service_type == '0' ? `Regular (${data?.service_hour}hrs)`:
                          `Expedited (${data?.service_hour}hrs)`}   
                  
                       
                      </tr>


                      <tr>
                        <td>
                          <strong>SubTotal: </strong>
                        </td>
                        <td>
                         
                          <strong> ${parseFloat(data?.total_price).toFixed(2)}</strong>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>VAT(10%): </strong>
                        </td>
                        <td>
                          
                          <strong> ${calculateVAT(data?.total_price)}</strong>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Grand Total: </strong>
                        </td>
                        <td>
                         
                          <strong> ${calculateTotal(data?.total_price)}</strong>
                        </td>
                      </tr>



                    </table>
                  </>
                )}
               
                <h5></h5>
              </div> */}
              
              {data?.link=='cvtype'?(
                <div className="new-order-summary">
                  <div className="top-sec">
                    <h3> View Order</h3>
                  </div>
                  <div className="card">
                    <h5>Item Description</h5>
                    <table className="table table-two">
                      <tbody>
                        <tr>
                          <td>Category</td>
                          <td className="right-text">{data?.service_name}</td>
                        </tr>
                        <tr>
                          <td>Number of Pages</td>
                          <td className="right-text">{data?.pages}</td>
                        </tr>
                        <tr>
                          <td>Speed</td>
                          <td className="right-text">
                            {
                              data?.service_type == '0' ? 'Regular' :
                              data?.service_type == '1' ? 'Expedited':''
                            }
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td>Total</td>
                        <td className="right-text">${parseFloat(data?.price).toFixed(2)}</td>
                      </tr>
                      {/* <tr>
                        <td>VAT(10%)</td>
                        <td className="right-text">${calculateVAT(data?.price)}</td>
                      </tr> */}
                      {/* <tr>
                        <td>Grand Total</td>
                        <td className="right-text">${calculateTotal(data?.price)}</td>
                      </tr> */}
                      </tfoot>
                    </table>
                  </div>
                </div>
              ):(
                <div className="new-order-summary">
                <div className="top-sec">
                  <h3> View Order</h3>
                </div>
                <div className="card">
                  <h5>Item Description</h5>
                  <table className="table table-two">
                    <tbody>
                      <tr>
                        <td>Category</td>
                        <td className="right-text">{data?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Option</td>
                        <td className="right-text">{data?.sub_service}</td>
                      </tr>
                      <tr>
                        <td>Word Count</td>
                        <td className="right-text">{data?.total_wordcount}</td>
                      </tr>
                      <tr>
                        <td>Speed</td>
                        <td className="right-text">
                        {
                          data?.service_type == '0' ? 'Regular' :
                          data?.service_type == '1' ? 'Expedited':''
                        }
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td>Total</td>
                      <td className="right-text">${parseFloat(data?.total_price).toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <td>VAT(10%)</td>
                      <td className="right-text">${calculateVAT(data?.total_price)}</td>
                    </tr> */}
                    {/* <tr>
                      <td>Grand Total</td>
                      <td className="right-text">${calculateTotal(data?.total_price)}</td>
                    </tr> */}
                    </tfoot>
                  </table>
                </div>
                </div>
              )}
              

              <ul className="coupon-list">
                {/* <li>
                  <div class="form-floating">
                    <input type="text" className="form-control" id="couponInput" placeholder="Enter Coupon Code"  />
                    <label for="couponInput">Enter Coupon Code</label>
                  </div>
                </li> */}
                {/* <li>
                  <input type="submit" name="" value="Apply" className="btn btn-primary" />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="home-yellow-section align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7 col-md-8 text-content"
              data-aos="fade-right"
            >
              <h3>Still Not Sure What Service Is Right For You?</h3>
              <p>
                <NavLink to="/">Elite Reviser</NavLink> can handle that. 
                <NavLink to="/contact"> Contact Us.</NavLink>
              </p>
            </div>
            <div
              className="col-lg-5 col-md-4 right-content"
              data-aos="fade-left"
            >
              <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Payment;
