import React from 'react'
import { saveAs} from 'file-saver';
import { updateJobStatus } from '../API/apiRequests';

const getMonthName = (month) => {
    const monthNames = [
      "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];
    return monthNames[month];
  };

//Date format
export const get_date_format = (dt) =>{
      var options = { year: 'numeric', month: 'short', day: 'numeric' };
      var created_date = dt;
      var date = created_date?.slice(0,10);
      var created_date  = new Date(date);
      var date_month_format = created_date.toLocaleDateString("en-US", options); // Saturday, September 17, 2016
    //   return date_month_format;
      return formatDateString(date_month_format);
}

const formatDateString = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = getMonthName(date.getMonth());
    // const year = date.getFullYear();
    const year = String(date.getFullYear()).slice(-2); // Use last two digits of the year
    return (`${day}-${month}-${year}`);
  }

// last login date time format
export const lastLoginformatDate = (dateString) => {
    const optionsDate = { month: 'short', day: 'numeric', year: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric' };
    
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', optionsDate);
    const formattedTime = new Date(dateString).toLocaleTimeString('en-US', optionsTime);
    const newFormattedDate = formatDateString(formattedDate);
    // return `${formattedDate} | ${formattedTime}`;
    return `${newFormattedDate} | ${formattedTime}`;
};

// comma function
// export const commaFunc = (val) => {
//   return (new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3}).format(val))
// }

// Updated comma function to handle undefined or null values
export const commaFunc = (val) => {
  if (val === undefined || val === null || isNaN(val)) {
    return ''; // or return '0' if you prefer to show 0 instead
  }
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 6 }).format(val);
}

//for fractional value
// export const commaFunc1 = (val) => {
//   if (val === undefined || val === null || val === '') {
//     return ''; // or return '0' if you prefer to show 0 instead
//   }

//   // Split the value into integer and fractional parts
//   const [integerPart, fractionalPart] = val.toString().split('.');

//   // Format the integer part with commas
//   const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   // Reassemble the integer and fractional parts
//   return fractionalPart !== undefined ? `${formattedInteger}.${fractionalPart}` : formattedInteger;
// };

// export const commaFunc1 = (val) => {
//   if (val === undefined || val === null || val === '') {
//     return ''; // or return '0' if you prefer to show 0 instead
//   }

//   // Split the value into integer and fractional parts
//   const [integerPart, fractionalPart] = val.toString().split('.');

//   // Format the integer part with commas
//   const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   // Reassemble the integer and fractional parts
//   return fractionalPart !== undefined ? `${formattedInteger}.${fractionalPart}` : formattedInteger;
// };

export const commaFunc1 = (val) => {
  if (val === undefined || val === null || val === '') {
    return ''; // or return '0' if you prefer to show 0 instead
  }

  // Split the value into integer and fractional parts
  const [integerPart, fractionalPart] = val.toString().split('.');

  // Format the integer part with commas
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Reassemble the integer and fractional parts
  return fractionalPart !== undefined ? `${formattedInteger}.${fractionalPart}` : formattedInteger;
};


//Time format
export const get_time_format = (tm) => {
  if(tm!= '') {
    var time_values = tm.slice(11,16);
    let start_time_split = time_values?.split(":");
    console.log(start_time_split)
    let start_splt_hour = start_time_split[0];
    let start_splt_minutes = start_time_split[1];
    let start_check_dt = start_splt_hour >= 12 ? 'PM' : 'AM';
    start_splt_hour = start_splt_hour % 12;
    start_splt_hour = start_splt_hour ? start_splt_hour : 12; //hour 0 => 12
    start_splt_minutes = start_splt_minutes < 10 ? start_splt_minutes :  start_splt_minutes;
    let start_time_final = start_splt_hour + ':' + start_splt_minutes + ' ' + start_check_dt;
    return start_time_final;
  }
}


//file download
export const downloadFile = (item) => {
    saveAs(item);
}

export const downloadEditorFile = async (job_id,url,download_status) => 
{
  // submit_file

  // const response = await fetch(url);
  // const blob = await response.blob();
  
  // const link = document.createElement('a');
  // link.href = window.URL.createObjectURL(blob);
  // link.download = 'Submitted_file';
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);

  // window.open(url, '_blank');

  // let payload_data = {id:job_id}
  // let apiRes = await updateJobStatus(payload_data);
  // let responseData = await apiRes.json();
  // if(responseData.result) {
  //   console.log(1);
  // }else{
  //   console.log(0);
  // }
  try {
    // Fetch the file
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    const blob = await response.blob();
    
    // Create a link to download the file
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Submitted_file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Open the file in a new tab
    window.open(url, '_blank');

    if (download_status == 0) {
      const payload_data = { id: job_id };
      const apiRes = await updateJobStatus(payload_data);
      const responseData = await apiRes.json();
    
      if (responseData.result) {
        console.log('Job status updated successfully.');
      } else {
        console.log('Failed to update job status.');
      }
    }
  } catch (error) {
    console.error('Error during the download or API call:', error);
  }
  return 1;
};

//formatted date yyyy-mm-dd
export const getymdFormattedDate = (use_date) =>{
    var year = use_date.toLocaleString("default", { year: "numeric" });
    var month = use_date.toLocaleString("default", { month: "2-digit" });
    var day = use_date.toLocaleString("default", { day: "2-digit" });
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
}

export const shortUserName = async (user_name) =>{
  var sl_name = user_name?.split(' ');
  var short_first = sl_name[0]?.charAt(0);
  var short_last = sl_name[1]?.charAt(0);
  var short_final = short_first + short_last;
  return short_final;
}

