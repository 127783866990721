import React, { Component, useState, useEffect } from "react";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import Marquee from "react-fast-marquee";
import axios from "axios";
import { API_BASE_URL } from "../../api_url";
import { HashLink, NavHashLink } from "react-router-hash-link";

// const footerLogo = require("./assets/img/footer-logo.png");
const footerLogo = require("../../assets/img/logo-new.svg").default;
const fbIcon = require("../../assets/img/fb-icon.png");
const twtrIcon = require("../../assets/img/twitter-icon.png");
const igIcon = require("../../assets/img/ig-icon.png");
const brandImg1 = require("../../assets/img/brand-img1.png");
const brandImg2 = require("../../assets/img/brand-img2.png");
const brandImg3 = require("../../assets/img/brand-img3.png");
const brandImg4 = require("../../assets/img/brand-img4.png");
const brandImg5 = require("../../assets/img/brand-img5.png");
const brandImg6 = require("../../assets/img/brand-img6.png");
const brandImg7 = require("../../assets/img/brand-img7.png");
const brandImg8 = require("../../assets/img/iso2015-1024x395.png");
const PrivacyPdf = require("../../pdf/Privacy-Policy.pdf");
const TermsPdf = require("../../pdf/Terms-of-Service.pdf");

const Footer = () => {
  const [showServices, setShowServices] = useState([]); // For all service
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  // console.log(loggedUser)

  useEffect(() => {
    getAllServices();
  }, []);

  // get all services
  const getAllServices = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}service`);
      if (apiRes) {
        // console.log(apiRes.data.res);
        if (apiRes.data.res == true) {
        //   console.log(apiRes.data.data);
          setShowServices(apiRes.data.data);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  return (
    <div>
      <div className="home-brand-section">
        <Marquee speed="60" delay={0} gradient={false}>
          {/* <img src={brandImg1} alt="" />
                    <img src={brandImg2} alt="" />
                    <img src={brandImg3} alt="" />
                    <img src={brandImg4} alt="" />
                    <img src={brandImg5} alt="" />
                    <img src={brandImg6} alt="" />
                    <img src={brandImg7} alt="" /> */}
          <div class="ifame-div ifame-div1">
            {/* <iframe id="TL_iFrame_id_8573_2507_1687367292" src="https://app.trustlock.co/iframe/8573/my-badge/2507" frameborder="0" vspace="0" hspace="0"></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2507_1702960706' src='https://app.trustlock.co/iframe/8573/my-badge/2507' frameborder='0' vspace='0' hspace='0'></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2703_1703305404' src='https://app.trustlock.co/iframe/8573/my-badge/2703' frameborder='0' vspace='0' hspace='0'></iframe> */}
            <iframe
              id="TL_iFrame_id_8573_2703_1708672048"
              src="https://app.trustlock.co/iframe/8573/my-badge/2703"
              frameborder="0"
              vspace="0"
              hspace="0"
            ></iframe>
          </div>
          <div class="ifame-div ifame-div2">
            {/* <iframe id="TL_iFrame_id_8573_2506_1687367063" src="https://app.trustlock.co/iframe/8573/my-badge/2506" frameborder="0" vspace="0" hspace="0"></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2704_1703305440' src='https://app.trustlock.co/iframe/8573/my-badge/2704' frameborder='0' vspace='0' hspace='0'></iframe> */}
            <iframe
              id="TL_iFrame_id_8573_2704_1708672107"
              src="https://app.trustlock.co/iframe/8573/my-badge/2704"
              frameborder="0"
              vspace="0"
              hspace="0"
            ></iframe>
          </div>
          <div class="ifame-div ifame-div3">
            {/* <iframe id="TL_iFrame_id_8573_2505_1687366706" src="https://app.trustlock.co/iframe/8573/my-badge/2505" frameborder="0" vspace="0" hspace="0"></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2705_1703305305' src='https://app.trustlock.co/iframe/8573/my-badge/2705' frameborder='0' vspace='0' hspace='0'></iframe> */}
            <iframe
              id="TL_iFrame_id_8573_2705_1708672160"
              src="https://app.trustlock.co/iframe/8573/my-badge/2705"
              frameborder="0"
              vspace="0"
              hspace="0"
            ></iframe>
          </div>
          <div className="marquee-img-box">
            <a href="https://www.skillfront.com/Badges/39884700395901" target="_blank">
              <img src={brandImg8} alt="" />
            </a>
          </div>
          <div class="ifame-div ifame-div1">
            {/* <iframe id="TL_iFrame_id_8573_2507_1687367292" src="https://app.trustlock.co/iframe/8573/my-badge/2507" frameborder="0" vspace="0" hspace="0"></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2507_1702960706' src='https://app.trustlock.co/iframe/8573/my-badge/2507' frameborder='0' vspace='0' hspace='0'></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2703_1703305404' src='https://app.trustlock.co/iframe/8573/my-badge/2703' frameborder='0' vspace='0' hspace='0'></iframe> */}
            <iframe
              id="TL_iFrame_id_8573_2703_1708672048"
              src="https://app.trustlock.co/iframe/8573/my-badge/2703"
              frameborder="0"
              vspace="0"
              hspace="0"
            ></iframe>
          </div>
          <div class="ifame-div ifame-div2">
            {/* <iframe id="TL_iFrame_id_8573_2506_1687367063" src="https://app.trustlock.co/iframe/8573/my-badge/2506" frameborder="0" vspace="0" hspace="0"></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2704_1703305440' src='https://app.trustlock.co/iframe/8573/my-badge/2704' frameborder='0' vspace='0' hspace='0'></iframe> */}
            <iframe
              id="TL_iFrame_id_8573_2704_1708672107"
              src="https://app.trustlock.co/iframe/8573/my-badge/2704"
              frameborder="0"
              vspace="0"
              hspace="0"
            ></iframe>
          </div>
          <div class="ifame-div ifame-div3">
            {/* <iframe id="TL_iFrame_id_8573_2505_1687366706" src="https://app.trustlock.co/iframe/8573/my-badge/2505" frameborder="0" vspace="0" hspace="0"></iframe> */}
            {/* <iframe id='TL_iFrame_id_8573_2705_1703305305' src='https://app.trustlock.co/iframe/8573/my-badge/2705' frameborder='0' vspace='0' hspace='0'></iframe> */}
            <iframe
              id="TL_iFrame_id_8573_2705_1708672160"
              src="https://app.trustlock.co/iframe/8573/my-badge/2705"
              frameborder="0"
              vspace="0"
              hspace="0"
            ></iframe>
          </div>
          <div className="marquee-img-box">
            <a href="https://www.skillfront.com/Badges/39884700395901" target="_blank">
              <img src={brandImg8} alt="" />
            </a>
          </div>
        </Marquee>
      </div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 footer-menu footer-menu1">
              <img src={footerLogo} alt="footer logo" className="footer-logo" />
              <ul className="row quick-contact">
                <li className="col-12">+1 (469) 305 1828</li>
                <li className="col-12">contact@elitereviser.com</li>
                <li className="col-12">9550 Forest Lane Ste 135, Dallas, TX 75243</li>
              </ul>
              {/* <ul className="social-list">
                                <li><a><img src={fbIcon} alt="footer logo" /></a></li>
                                <li><a><img src={twtrIcon} alt="footer logo" /></a></li>
                                <li><a><img src={igIcon} alt="footer logo" /></a></li>
                            </ul> */}
            </div>
            <div className="col-lg-2 col-md-3 common-footer-menu footer-menu2">
              <h3>Company</h3>
              <ul className="row menu-link-list">
                <li className="col-12">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="col-12">
                  <NavLink to="/about">About Us</NavLink>
                </li>
                {/* <li className="col-12"><NavLink to="/how-our-process-works">Our Process</NavLink></li> */}
                <li className="col-12">
                  <NavLink to="/career">Careers</NavLink>
                </li>
                <li className="col-12">
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li className="col-12">
                  <NavLink to="/partner-with-us">Partner With Us</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 common-footer-menu footer-menu3">
              <h3>Services</h3>
              <ul className="row menu-link-list">
                {showServices &&
                  showServices.length > 0 &&
                  showServices?.map((item, index) => {
                    return (
                      <>
                        <li className="col-md-12">
                          <Link
                            to={{ pathname: `/service/${item?.title.toLowerCase()}` }}
                            // state={{ id: item?.id }}
                          >
                            {item?.title === "ESL" ? <a>{item?.title}</a> : <a className="text-capitalize">{item?.title?.toLowerCase()}</a>}
                          </Link>
                        </li>
                      </>
                    );
                  })}

                <li className="col-md-12">
                  <NavLink to="/services-choose-cv">Resume Building</NavLink>
                </li>

                {/* <li className="col-md-6"><a>Academic</a></li>
                                <li className="col-md-6"><a>Student</a></li>
                                <li className="col-md-6"><a>Corporation</a></li>
                                <li className="col-md-6"><a>Writer</a></li>
                                <li className="col-md-6"><a>ESL</a></li>
                                <li className="col-md-6"><NavLink to="/services-choose-cv">Resume Building</NavLink></li>
                                <li className="col-md-6"><a>Professional</a></li> */}
              </ul>
            </div>
            {loggedUser != null ? (
              <div className="col-lg-2 col-md-3 common-footer-menu footer-menu4">
                {/* {loggedUser && loggedUser.role === '2' ?( */}
                {/* <h3>Customers</h3> */}
                {/* <h3>MyER</h3> */}
                <h3>My Account</h3>
                {/* ):(
                                        <h3>&nbsp;</h3>
                                    )} */}
                <ul className="row menu-link-list">
                  {/* Admin: 0, Editor: 1, Client: 2, Deputy Admin: 3, Internal Editor: 4 */}
                  {loggedUser && loggedUser.role == "0" ? (
                    <>
                      <li className="col-12">
                        <NavLink to="/admin-my-account">My Account</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/admin-dashboard" className="text-capitalize">
                          Dashboard
                        </NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/admin-job-board" className="text-capitalize">
                          Job Board
                        </NavLink>
                      </li>
                    </>
                  ) : loggedUser && loggedUser.role == "2" ? (
                    <>
                      {/* <li className="col-12"><NavLink to="/client-my-account">My Account</NavLink></li> */}
                      <li className="col-12">
                        <NavLink to="/client-my-account">My Profile</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/client-my-orders">My Orders</NavLink>
                      </li>
                    </>
                  ) : loggedUser && loggedUser.role == "3" ? (
                    <>
                      <li className="col-12">
                        <NavLink to="/deputy-admin-my-account">My Account</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/deputy-admin-dashboard">Dashboard</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/deputy-admin-job-board">Job Board</NavLink>
                      </li>
                    </>
                  ) : loggedUser && loggedUser.role == "4" ? (
                    <>
                      {/* <li className="col-12"><NavLink to="/internal-editor-my-account">My Account</NavLink></li> */}
                      <li className="col-12">
                        <NavLink to="/internal-editor-my-account">My Profile</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/internal-editor-my-job">My Jobs</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/internal-editor-dashboard">Dashboard</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/internal-editor-job-board">Job Board</NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="col-12">
                        <NavLink to="/login">My Account</NavLink>
                      </li>
                      <li className="col-12">
                        <NavLink to="/signup">Create an Account</NavLink>
                      </li>
                    </>
                  )}
                  <li className="col-12">
                    <NavLink to="/faq">FAQs</NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="col-lg-2 col-md-3 common-footer-menu footer-menu4">
                {/* <h3>MyER</h3> */}
                <h3>Customers</h3>
                <ul className="row menu-link-list">
                  {/* <li className="col-12"><NavLink to="/login">My Account</NavLink></li> */}
                  <li className="col-12">
                    <NavLink to="/login">My Profile</NavLink>
                  </li>
                  <li className="col-12">
                    <NavLink to="/login">My Orders</NavLink>
                  </li>
                  <li className="col-12">
                    <NavLink to="/signup">Create an Account</NavLink>
                  </li>
                  <li className="col-12">
                    <NavLink to="/faq">FAQs</NavLink>
                  </li>
                </ul>
              </div>
            )}
            <div className="col-lg-2 col-md-3 common-footer-menu footer-menu5">
              {/* <h3>Legal</h3> */}
              <h3>Privacy & Use</h3>
              <ul className="row menu-link-list">
                {/* <li className="col-12"><Link to="/terms-of-service">Terms of Service</Link></li>
                                <li className="col-12"><Link to="/privacy-policy">Privacy Policy</Link></li> */}
                <li className="col-12">
                  {/* <Link target="_blank" onClick={window.open(PrivacyPdf)}>Terms of Service</Link> */}
                  <a href={TermsPdf} without rel="noopener noreferrer" target="_blank">
                    Terms of Service
                  </a>
                </li>
                <li className="col-12">
                  {/* <Link target="_blank" onClick={window.open(TermsPdf)}>Privacy Policy</Link> */}
                  <a href={PrivacyPdf} without rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </a>
                </li>

                {/* import Pdf from '../Documents/Document.pdf'; */}
              </ul>
            </div>

            {/* <div className="col-lg-12 col-md-12 footer-social">
                            <ul className="separate-social-list">
                                <li><a><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                <li><a><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                            </ul>
                        </div> */}
            <div className="bottom-footer">
              {/* <div className="row bottom-security-sec">
                                <div className="col-md-12 text-center">
                                    <div className="ifame-div">
                                        <iframe id="TL_iFrame_id_8573_2505_1687366706" src="https://app.trustlock.co/iframe/8573/my-badge/2505" frameborder="0" vspace="0" hspace="0"></iframe>
                                    </div>
                                    <div className="ifame-div">
                                        <iframe id="TL_iFrame_id_8573_2506_1687367063" src="https://app.trustlock.co/iframe/8573/my-badge/2506" frameborder="0" vspace="0" hspace="0"></iframe>
                                    </div>
                                    <div className="ifame-div">
                                        <iframe id="TL_iFrame_id_8573_2507_1687367292" src="https://app.trustlock.co/iframe/8573/my-badge/2507" frameborder="0" vspace="0" hspace="0"></iframe>
                                    </div>
                                </div>
                            </div> */}
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <p>
                    Copyright &copy; {currentYear} | Elite Reviser | All rights reserved. &nbsp;
                    {/* <Link to="/terms-of-service">
                                            Terms of Service
                                        </Link> | &nbsp; 
                                        <Link to="/privacy-policy">
                                            Privacy Policy
                                        </Link> */}
                  </p>
                </div>
                <div className="col-lg-4 col-md-12">
                  <ul className="separate-social-list">
                    <li>
                      <a>
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    {/* <li><a><i class="fa fa-brands fa-x-twitter" aria-hidden="true"></i></a></li> */}
                    <li>
                      <a>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a>
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a>
                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-4 col-md-12">
                                <HashLink smooth to={'/privacy-policy?msg=true'}>
                                    Section Two
                                </HashLink>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
