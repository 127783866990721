import React, { Component } from "react";
import ReactJsAlert from "reactjs-alert";

 class CustomPopUp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: "info",
      status: true,
      title: "Hey! this is an error.",
      quote: "Something went wrong. Please try again!",
    };
  }

  
  render() {
    return (
      <div className="App">
        <ReactJsAlert
          type={this.state.type} // success, warning, error, info
          title={this.state.title} // title you want to display
          status={this.state.status} // true or false
          Close={() => this.setState({ status: false })}
        />
      </div>
    );
  }
}
export default CustomPopUp