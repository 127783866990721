import React, { useEffect} from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

const careerBanner = require("../../assets/img/career-banner.jpg");
const careerImg = require("../../assets/img/career-img1-new.png");
const benefitsImg1 = require("../../assets/img/benefits-icon1.png");
const benefitsImg2 = require("../../assets/img/benefits-icon2.png");
const benefitsImg3 = require("../../assets/img/benefits-icon3.png");
const benefitsImg4 = require("../../assets/img/benefits-icon4.png");

const Career = () => {
    const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite")); 
    useEffect(() => {
        AOS.init();
    });

    useEffect(() => {
        // if (data=== null)
        // {
        //     navigate("/login");
        //     navigate(0);
        // }
        // if(data!=null && (data?.role == '0'))
        // {
        //   navigate("/admin-job-board");
        // }
        if(data!=null && (data?.role == '1'))
        {
          navigate("/editor-job-board");
        }
        // else if(data!=null && (data?.role == '3'))
        // {
        //   navigate("/deputy-admin-job-board");
        // }
        // else if(data!=null && (data?.role == '4'))
        // {
        //   navigate("/internal-editor-job-board");
        // }
      })

    return(
        <div>
            <Header />
            <div className="inner-banner" style={{backgroundImage: `url(${careerBanner})`}}>
                <div className="container">
                    <h3 data-aos="fade-up">Careers</h3>
                    <p data-aos="fade-up">Our proofreaders and editors are the cream of the crop. They are well-trained to bring out their best.</p>
                </div>
            </div>
            <div className="career-body-top">
                <div className="container">
                    <h3 data-aos="fade-up">Working For Us</h3>
                    <div className="row align-items-center" data-aos="fade-up">
                        <div className="col-lg-5 col-12 img-section"><img src={careerImg} alt="Image" /></div>
                        <div className="col-lg-7 col-12 text-section">
                            <p>We are a company that is regularly looking for proofreaders and editors with different levels of educational backgrounds. Your country of residence is of no significance when you work for us because our jobs are done remotely. What is important to us is your expertise and willingness to achieve excellence in proofreading and editing. You must meet our prerequisites if working for us is of interest to you. If you become selected to work for us, we will do our best to make sure you are supported and equipped in every way possible.</p>
                            <p>At Elite Reviser, we are diligent in the selection of our proofreaders and editors to ensure the quality of our services are first class. We always strive to provide excellent services to all our customers, with no exception. In our industry, employing, training, and retaining the experienced and talented proofreaders and editors is key to achieving 100% customer satisfaction. Your performance will frequently be assessed to help you improve your expertise, where necessary. Our team members are given the support they need to continually be the cream of the crop.</p>
                            <p>If working for us interests you, we would love to evaluate your skills.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits-section">
                <div className="container">
                    <h3 data-aos="fade-up">The Benefits Of Joining Us</h3>
                    <ul className="row benefits-list" data-aos="fade-up">
                        <li className="col-md-3">
                            <div className="img-sec"><img src={benefitsImg1} alt="Image" /></div>
                            <div className="text-section">
                                <h4>Competitive Pay</h4>
                                <p>We offer our proofreaders and editors one of the highest rates of pay in the industry.</p>
                            </div>
                        </li>
                        <li className="col-md-3">
                            <div className="img-sec"><img src={benefitsImg2} alt="Image" /></div>
                            <div className="text-section">
                                <h4>Telework</h4>
                                <p>Our proofreaders and editors have the ability to do their job remotely if they choose to.</p>
                            </div>
                        </li>
                        <li className="col-md-3">
                            <div className="img-sec"><img src={benefitsImg3} alt="Image" /></div>
                            <div className="text-section">
                                <h4>Flexibility</h4>
                                <p>Our proofreaders and editors take advantage of flexible hours — you choose when to work.</p>
                            </div>
                        </li>
                        <li className="col-md-3">
                            <div className="img-sec"><img src={benefitsImg4} alt="Image" /></div>
                            <div className="text-section">
                                <h4>Support</h4>
                                <p>We offer our proofreaders and editors great working conditions and a supportive team.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="criteria-section">
                <div className="container">
                    <h3  data-aos="fade-up">Selection Criteria</h3>
                    <ul className="row criteria-list">
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Native English speaker</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Ability to meet stringent deadlines, if necessary</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Ability to adopt American, British, or Canadian English</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">At least a bachelor’s degree, but a graduate degree is preferred</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Experience in document proofreading and editing in various disciplines</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Any professional certification in the industry is an advantage</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Employment experience as a proofreader or an editor</div>
                        </li>
                        <li className="col-md-6" data-aos="fade-up">
                            <div className="white-section">Advanced skills in Microsoft Word 2010 or above</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="apply-section">
                <div className="container">
                    <h3  data-aos="fade-up">Apply Now</h3>
                    <p  data-aos="fade-up">If you meet our selection criteria, please send us your CV and cover letter to <a href="mailto:contact@elitereviser.com">contact@elitereviser.com</a>. We will get back to you should we find you qualified to work for us.</p>
                    <p  data-aos="fade-up">If you have any questions about working with us, please feel free to contact us.</p>
                    {/* <a className="contact-us-btn"  data-aos="fade-up">Contact Us</a> */}
                    <NavLink className="contact-us-btn" data-aos="fade-up" to="/contact"> Contact Us</NavLink>
                </div>
            </div>
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p>
                                <NavLink to="/">Elite Reviser</NavLink> can handle that. 
                                <NavLink to="/contact"> Contact Us.</NavLink>
                            </p>                      
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                            {/* <a className="btn btn-primary">Get Started</a> */}
                            <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Career;