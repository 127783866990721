import React, {Component, useState, useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";

const NotLoginAuth = () => {
    const [userRole] = useState(JSON.parse(localStorage.getItem("user_data_elite"))?.role ? JSON.parse(localStorage.getItem("user_data_elite"))?.role : null);
    console.warn(userRole)
    return (
    // isLoggedIn ? <Outlet /> : <Navigate to= '/login' />
    !userRole? <Outlet/> : <Navigate to= '/' />
  )
}

export default NotLoginAuth


// Admin: 0
// Editor: 1
// Client: 2
// Sub Admin/Deputy Admin: 3
// Internal Editor: 4