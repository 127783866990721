import axios from "axios";
import { API_BASE_URL } from "../api_url";
import { DateTime } from "luxon";

const timeZone = DateTime.local().zoneName;


// Registration API
export const registerUser =  (userData) => {
    userData.role = '2'; // Add the 'role' key-value pair
    userData.UserTimeZone= timeZone  // Add the 'time-zone' key-value pair
   
    const response =  axios.post(`${API_BASE_URL}login/registration`, userData)
    return response;
}

// Login API
export const loginUser = (userData) => {
    userData.user_type = 'notAdmin';  // Add the 'user-type' key-value pair
    const response =  axios.post(`${API_BASE_URL}login/signin`, userData)
    return response;
}

// Fetch Account Details
export const fetchUserDetails = async () => {

}

// Fetch All Jobs
export const fetchJobList = async () => {

}

//get country list
export const fetchCountryList =() =>
{
    let response = axios.get(`${API_BASE_URL}login/countries`)
    return response;
}

// client apple login
// export const userAppleLogin =  (loggedData) => {
//     const response =  axios.post(`${API_BASE_URL}login/applelogin`, loggedData)
//     return response;
// }

// client apple login
export const userAppleLogin = async (loggedData) => {
    const response = await fetch(`${API_BASE_URL}login/applelogin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(loggedData),
    });
    return response;
}

export const updateJobStatus = async(job_id) => {
    const response = await fetch(`${API_BASE_URL}job/jobdownloadstatus`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(job_id),
    });
    return response;
}


// ----Common Section APIs Start----
export const fetchAllServices = async () => {
    let apiRes = await axios.get(`${API_BASE_URL}service`);
    return apiRes;
}
export const userAccountDetails = async (user_id) => {
    let apiRes = await axios.get(`${API_BASE_URL}account?id=${user_id}`);
    return apiRes;
}
export const jobDetailsById = async (job_id,timeZone) => {
    let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`);
    return apiRes;
}
export const jobDetailsByUser = async (user_id,timeZone) => {
    let apiRes = await axios.get(`${API_BASE_URL}job/jobdetailbyuser?user_id=${user_id}&UserTimeZone=${timeZone}`);
    return apiRes;
}
export const fetchAllNotification = async (payload_data) => {
    let apiRes = await axios.post(`${API_BASE_URL}job/getNotification`, payload_data);
    return apiRes;
}
// ----Common Section APIs End----

// ----Client Section APIs Start----
export const clientRating = async (job_id,rating,job_status,user_id) => {
    let apiRes = await axios.get(`${API_BASE_URL}job/jobstatus?id=${job_id}&rating=${rating}&job_status=${job_status}&user_id=${user_id}`);
    return apiRes;
}
export const submitClientFeedbackFunc = async (payload_data) => {
    let apiRes = await axios.post(`${API_BASE_URL}feedback`,payload_data);
    return apiRes;
}
export const sortingJobListFunc = async (select_job_status,user_id) => {
    let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatusbyuser?job_status=${select_job_status}&user_id=${user_id}`);
    return apiRes;
}
export const jobCanceledByClient = async (payload_data) => {
    let apiRes = await axios.post(`${API_BASE_URL}job/jobcancel`,payload_data);
    return apiRes;
}
export const postComment = async (formData,config) => {
    let apiRes = await axios.post(`${API_BASE_URL}comment`,formData,config);
    return apiRes;
}
export const postCommentMail = async (formData,config) => {
    let apiRes = await axios.post(`${API_BASE_URL}comment/chatmail`,formData,config);
    return apiRes;
}
export const commentDetailsByEditor = async (user_id,editor_id,admin_id,job_id,internal_id,deputy_id,timezone) => {
    let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${admin_id}&job_id=${job_id}&internal_id=${internal_id}&deputy_id=${deputy_id}&UserTimeZone=${timezone}`);
    return apiRes;
}
export const jobApprovedByClient = async (payload_data) => {
    let apiRes = await(axios.post(`${API_BASE_URL}job/jobApprove`,payload_data));
    return apiRes;
}
export const jobApprovedMailByClient = async (payload_data) => {
    let apiRes = await(axios.post(`${API_BASE_URL}job/jobApproveMail`, payload_data));
    return apiRes;
}
// ----Client Section APIs Start----