import React, { Component, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate,Link } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { NotificationManager } from "react-notifications";

const aboutBanner = require("../../assets/img/about-banner-new.jpg");
const aboutImg = require("../../assets/img/about-img-new.png");

const About = () => {
    const navigate = useNavigate();
    let data = JSON.parse(localStorage.getItem("user_data_elite"));
    console.log(data);
    const [showAboutData,setShowAboutData] = useState(); // all about us content
    useEffect(() => {
        AOS.init();
    });

    useEffect(()=>{
        aboutUsDatas();
    },[]);

    // useEffect(() => {
    //     if (loggedUser=== null || loggedUser === undefined || loggedUser === '') // check local stroage data is logged or not
    //     {
    //         navigate('/');
    //     }
    // }, []);
    useEffect(() => {
        // if (data=== null)
        // {
        //     navigate("/login");
        //     navigate(0);
        // }
        // if(data!=null && (data?.role == '0'))
        // {
        //   navigate("/admin-job-board");
        // }
        if(data!=null && (data?.role == '1'))
        {
          navigate("/editor-job-board");
        }
        // else if(data!=null && (data?.role == '3'))
        // {
        //   navigate("/deputy-admin-job-board");
        // }
        // else if(data!=null && (data?.role == '4'))
        // {
        //   navigate("/internal-editor-job-board");
        // }
      })

    const aboutUsDatas = async() =>{
        try 
        {
            let apiRes = await axios.get(`${API_BASE_URL}cms?id=${1}`)
            if (apiRes) 
            {
                if (apiRes.data.res == true) 
                {
                    console.log(apiRes.data.data[0]);
                    setShowAboutData(apiRes.data.data[0]);
                }
                else
                {
                    NotificationManager.error(apiRes.data.msg, "", 2000);
                }
            }
            else
            {
                throw new Error('Network request failed');
            }
        }
        catch (error)
        {
            // NotificationManager.error(error, "", 2000);
        }
    }

    return(
        <div>
            <Header />
            <div className="inner-banner" style={{backgroundImage: `url(${aboutBanner})`}}>
                <div className="container">
                    {/* <h3 data-aos="fade-up">About Us</h3> */}
                    <h3 data-aos="fade-up">{showAboutData?.title}</h3>
                    {/* <p data-aos="fade-up">We place great diligence on every work submitted. You can always count on us to achieve the best outcome.</p> */}
                    <p data-aos="fade-up">{showAboutData?.short_description}</p>
                    {/* <p data-aos="fade-up" dangerouslySetInnerHTML={{__html: showAboutData?.full_description}}></p> */}
                    {/* <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} /> */}
                </div>
            </div>
            <div className="about-content">
                <div className="container">
                    <div className="row align-items-center" data-aos="fade-up">
                    <div className="col-lg-5 col-12 img-content d-lg-none d-xl-none">
                        <h3>Who We Are</h3>
                        <img src={aboutImg} alt="Image" />
                    </div>
                    <div className="col-lg-7 col-12 text-content">
                            <h3>Who We Are</h3>
                            <h4>Elite Reviser is an American proofreading and editing company that is owned and managed by academics and professionals.</h4>
                            <p>Our office is located in Dallas, Texas. Quality, speed, and affordability are central to all the services we offer our clients. No matter what your needs are, we are well-equipped for them. We offer services for the needs of academic, businesses, non-English speakers, professionals, students, and prolific writers. In addition to proofreading and editing, we specialize in building curriculum vitae (CV) and resume from the scratch.</p>
                            <p>Our proofreaders and editors are qualified, experienced individuals from various academic and professional backgrounds who always go the extra mile necessary to put smiles on the faces of our clients. We would like to see you join the growing list of our satisfied clients. Tell us how we may better serve you.</p>
                        </div>
                        <div className="col-lg-5 col-12 img-content d-none d-lg-block" data-aos="fade-left"><img src={aboutImg} alt="Image" /></div>
                    </div>
                </div>
            </div>
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p><NavLink to="/">Elite Reviser</NavLink> can handle that. 
                                <NavLink to="/contact"> Contact Us.</NavLink>
                            </p>
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                            <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default About;