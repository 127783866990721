import React, { Component,useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const InternalEditorMyAccount = () => {
    const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[internalEditorData,setInternalEditorData] = useState();
    useEffect(() => {
        if (data=== null)
        {
            navigate("/login");
            navigate(0);
        }
        console.log(data);
        // console.log(data.first_name.charAt(0))
        getInternalInfos();
    }, []);
    
    // get admin information
    const getInternalInfos = async () => {
        try
        {
            let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                    setInternalEditorData(apiRes.data.data[0]);
                }
            }
        }
        catch (error)
        {
            // NotificationManager.error(error, "", 2000);
        }
    };

  return (
    <div>
        <InternalEditorHeader/>
        <InternalEditorSidebar/>
        <main id="main" className="main">
            <div className="pagetitle">
                <span className="TitleWrap TitleWrapNonSpce">
                <span className="editorNameLogo">{internalEditorData?.first_name?.charAt(0)}{internalEditorData?.last_name?.charAt(0)}</span>
                    {/* <h2>My Account</h2> */}
                    <h2>My Profile</h2>
                </span>
            </div>
            <section className="section AccDtlsPnl">
                <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Personal Details</h3>
                        <Link
                             to = {`/internal-editor-my-account/internal-editor-edit-account/${internalEditorData?.id}`}
                            className="btn btn-primary CmnBtn"
                        > 
                            {/* <i className="bi bi-pencil" /> Edit Account */}
                            <i className="bi bi-pencil" /> Edit Profile
                        </Link>
                    </div>
                   
                    <ul className="AccDtls row">
                        <li className="col-md-3">
                            <label>First Name</label>
                            <p className="InputTxt">{internalEditorData?.first_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Last Name</label>
                            <p className="InputTxt">{internalEditorData?.last_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Phone Number</label>
                            <p className="InputTxt">{internalEditorData?.ph_no}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Email Address</label>
                            <p className="InputTxt">{internalEditorData?.email_address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Street Address</label>
                            <p className="InputTxt">{internalEditorData?.address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>City</label>
                            <p className="InputTxt">{internalEditorData?.city}</p>
                        </li>
                        <li className="col-md-3">
                            <label>State</label>
                            <p className="InputTxt">{internalEditorData?.state}</p>
                        </li>
                        
                        <li className="col-md-3">
                            <label>ZIP Code</label>
                            <p className="InputTxt">{internalEditorData?.zipcode}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Country</label>
                            <p className="InputTxt">{internalEditorData?.country}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Role</label>
                            <p className="InputTxt">
                              {
                                internalEditorData?.role == '0' ? 'Administrator':
                                internalEditorData?.role == '1' ? 'Editor':
                                internalEditorData?.role == '2' ? 'Client':
                                internalEditorData?.role == '3' ? 'Deputy Admin':
                                internalEditorData?.role == '4' ? 'Internal Editor':''
                              }
                            </p>
                        </li>
                        
                    </ul>
                </div>

                <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Password</h3>
                        {/* <NavLink to="/admin-my-account/admin-edit-password" className="btn btn-primary CmnBtn">
                            <i className="bi bi-pencil" /> Edit Password
                        </NavLink> */}
                        <Link 
                             to = {`/internal-editor-my-account/internal-editor-edit-password`}
                            className="btn btn-primary CmnBtn"
                        > 
                            <i className="bi bi-pencil" /> Edit Password      
                        </Link>
                    </div>
                    {/* <ul className="AccDtls row">
                        <li className="col-md-4">
                            <label>Password</label>
                            <p className="InputTxt">Sam@123456</p>
                        </li>
                    </ul> */}
                </div>

            </section>
        </main>
    </div>
  );
};

export default InternalEditorMyAccount;
