import React, { Component, useState, useEffect } from "react";

const visaImg = require("../../assets/img/visa-img.png");
const paypalImg = require("../../assets/img/paypal-img.png");
const stripeImg = require("../../assets/img/stripe-img.png");
const alipayImg = require("../../assets/img/alipay-img.png");
const masterImg = require("../../assets/img/master-card-img.png");
const signatureImg = require("../../assets/img/signature-img.png");


const PrintableData = (props) => {
    // const [printData,setPrintData] = useState([]);
    const {orderDetailsById} = props;
    console.log(props);
    console.log(orderDetailsById);

//Calculate VAT
const calculateVAT = (price,vat) =>{
  let get_price = Number(price);
  let get_vat = Number(vat);
  let cal_vat = parseFloat((get_price * get_vat)/100).toFixed(2);
  return cal_vat;
}

  return (
      //<div className="MiddleBody">
      //                 <h2>
      //                   #84 | WRITER | Book | Word Count 2,000-3,999 | Expedited Sumit
      //                   Service [32hrs]
      //                 </h2>
      //                 <table className="table table-header">
      //                   <tr>
      //                     <td>
      //                       <table className="table table-left-header">
      //                         <tr>
      //                           <td><span class="NameLogo">sw</span></td>
      //                           <td>
      //                             <h5>Sam Willium</h5>
      //                             <p>7455 Drew Court White City,<br />
      //                                Kansas 66872 U.S<br />
      //                                270-510-0585</p>
      //                           </td>
      //                         </tr>
      //                       </table>
      //                     </td>
      //                     <td align="right">
      //                         <h3>Invoice</h3>  
      //                         <h4>Invoice# INV-000136</h4>
      //                         <h5>Amount<br /><strong>$550.00</strong></h5>
      //                     </td>
      //                   </tr>
      //                 </table>
      //                 <table className="table table-user-details">
      //                   <tr>
      //                     <td></td>
      //                     <td>
      //                       <table className="table">
      //                         <tr>
      //                           <th>Invoice Date:</th>
      //                           <td>14 Dec 2022</td>
      //                         </tr>
      //                         <tr>
      //                           <th>Terms:</th>
      //                           <td>Due on Receipt</td>
      //                         </tr>
      //                         <tr>
      //                           <th>Due Date:</th>
      //                           <td>12 Dec 2022</td>
      //                         </tr>
      //                         <tr>
      //                           <th>Project Name:</th>
      //                           <td>Corporate</td>
      //                         </tr>
      //                         <tr>
      //                           <th>Editor Name:</th>
      //                           <td>Erish Callum</td>
      //                         </tr>
      //                       </table>
      //                     </td>
      //                   </tr>
      //                 </table>
      //                 <table className="table table-invoice-details">
      //                   <thead>
      //                     <tr>
      //                       <td>#</td>
      //                       <td>Task & Description</td>
      //                       <td>Hours</td>
      //                       <td>Rate</td>
      //                       <td>Word Count</td>
      //                       <td>Amount</td>
      //                     </tr>
      //                   </thead>
      //                   <tbody>
      //                     <tr>
      //                       <td>1</td>
      //                       <td>Document Writing</td>
      //                       <td>24hrs</td>
      //                       <td>$0.055</td>
      //                       <td>1,000</td>
      //                       <td>$550.00</td>
      //                     </tr>
      //                     <tr className="sub-total-row">
      //                       <td colSpan={4}>&nbsp;</td>
      //                       <td>Subtotal</td>
      //                       <td>$550.00</td>
      //                     </tr>
      //                     <tr className="grand-total-row">
      //                       <td colSpan={4}>&nbsp;</td>
      //                       <td>Total</td>
      //                       <td>$550.00</td>
      //                     </tr>
      //                   </tbody>
      //                 </table>
      //                 {/* <table className="table note-table">
      //                   <tr>
      //                     <td>
      //                       <p>Note<br />
      //                       <small>Thanks for your business.</small></p>
      //                     </td>
      //                     <td><img src={signatureImg} alt /></td>
      //                   </tr>
      //                   <tr>
      //                     <td colSpan={2}>
      //                       <p>Tearm & Conditions<br /><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
      // normal distribution of letters, as opposed to using making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</small></p>
      //                     </td>
      //                   </tr>
      //                 </table> */}
      //</div>

    <div className="MiddleBody">
      {(orderDetailsById?.job_type==="type2")?(
          <>
            <h2>
              #{orderDetailsById?.id} | {orderDetailsById?.service_name} | Number of Pages {orderDetailsById?.number_of_pages} | {orderDetailsById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
            </h2>
            <table className="table table-header">
              <tr>
                <td>
                  <table className="table table-left-header">
                    <tr>
                      {/* <td><span class="NameLogo">sw</span></td> */}
                      <td>
                        <h5>{orderDetailsById?.ClientName}</h5>
                        <p>{orderDetailsById?.address}<br />
                          {orderDetailsById?.zipcode}</p>
                      </td>
                    </tr>
                  </table>
                </td>
                <td align="right">
                    <h3>Invoice</h3>  
                    {/* <h4>Invoice# INV-000136</h4>  */}
                    <h4>Invoice #{orderDetailsById?.invoice}</h4> 
                    <h5>Amount<br /><strong>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</strong></h5>
                </td>
              </tr>
            </table>
            {/* <table className="table table-user-details">
              <tr>
                <td></td>
                <td>
                  <table className="table">
                    <tr>
                      <th>Invoice Date:</th>
                      <td>14 Dec 2022</td>
                    </tr>
                    <tr>
                      <th>Terms:</th>
                      <td>Due on Receipt</td>
                    </tr>
                    <tr>
                      <th>Due Date:</th>
                      <td>{get_date_format(orderDetailsById?.due_date)}</td>
                    </tr>
                    <tr>
                      <th>Project Name:</th>
                      <td>Corporate</td>
                    </tr>
                    <tr>
                      <th>Editor Name:</th>
                      <td>{orderDetailsById?.editor_name?orderDetailsById?.editor_name:'---'}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table> */}
            <table className="table table-invoice-details">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Task & Description</td>
                  <td>Number of Pages</td>
                  <td></td>
                  <td></td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  {/* <td>Document Writing</td> */}
                  <td>{orderDetailsById?.service_name}</td>
                  <td style={{textAlign: "center"}}>{orderDetailsById?.number_of_pages}</td>
                  <td></td>
                  <td></td>
                  <td>${parseFloat(Number(orderDetailsById?.GrandTotal)).toFixed(2)}</td>
                </tr>
                {/* <tr className="sub-total-row">
                  <td colSpan={4}>&nbsp;</td>
                  <td>VAT({orderDetailsById?.vat}%)</td>
                  <td>${calculateVAT(orderDetailsById?.GrandTotal,orderDetailsById?.vat)}</td>
                </tr> */}
                <tr className="grand-total-row">
                  <td colSpan={4}>&nbsp;</td>
                  <td><b>Total</b></td>
                  {/* <td><b>${calculateTotalPrice(orderDetailsById?.GrandTotal,orderDetailsById?.vat)}</b></td> */}
                  <td><b>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</b></td>
                </tr>
              </tbody>
            </table>
            {/* <table className="table note-table">
              <tr>
                <td>
                  <p>Note<br />
                  <small>Thanks for your business.</small></p>
                </td>
                <td><img src={signatureImg} alt /></td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p>Tearm & Conditions<br /><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
normal distribution of letters, as opposed to using making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</small></p>
                </td>
              </tr>
            </table> */}
          </>
        ):(
          <>
            <h2>
              #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {orderDetailsById?.sub_service} | Word Count {orderDetailsById?.total_wordcount} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {orderDetailsById?.service_hour}hrs
            </h2>
            <table className="table table-header">
              <tr>
                <td>
                  <table className="table table-left-header">
                    <tr>
                      {/* <td><span class="NameLogo">sw</span></td> */}
                      <td>
                        <h5>{orderDetailsById?.ClientName}</h5>
                        <p>{orderDetailsById?.address}<br />
                          {orderDetailsById?.zipcode}</p>
                      </td>
                    </tr>
                  </table>
                </td>
                <td align="right">
                    <h3>Invoice</h3>  
                    <h4>Invoice# {orderDetailsById?.invoice}</h4> 
                    <h5>Amount<br /><strong>${parseFloat(orderDetailsById?.total_price).toFixed(2)}</strong></h5>
                </td>
              </tr>
            </table>
            {/* <table className="table table-user-details">
              <tr>
                <td></td>
                <td>
                  <table className="table">
                    <tr>
                      <th>Invoice Date:</th>
                      <td>14 Dec 2022</td>
                    </tr>
                    <tr>
                      <th>Terms:</th>
                      <td>Due on Receipt</td>
                    </tr>
                    <tr>
                      <th>Due Date:</th>
                      <td>{get_date_format(orderDetailsById?.due_date)}</td>
                    </tr>
                    <tr>
                      <th>Project Name:</th>
                      <td>Corporate</td>
                    </tr>
                    <tr>
                      <th>Editor Name:</th>
                      <td>{orderDetailsById?.editor_name?orderDetailsById?.editor_name:'---'}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table> */}
            <table className="table table-invoice-details">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Task & Description</td>
                  <td>Hours</td>
                  {/* <td>Rate</td> */}
                  <td></td>
                  <td>Word Count</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{orderDetailsById?.service_name}</td>
                  <td>{orderDetailsById?.service_hour}hrs</td>
                  {/* <td>
                    {orderDetailsById?.service_type === "0" ?(
                        <>
                            {orderDetailsById?.Pagecount?.regularprice}
                        </>
                    ):(
                        <>
                             {orderDetailsById?.Pagecount?.expeditedprice}
                        </>
                    )}
                  </td> */}
                  <td></td>
                  <td>{orderDetailsById?.total_wordcount}</td>
                  <td>${parseFloat(Number(orderDetailsById?.GrandTotal)).toFixed(2)}</td>
                </tr>
                {/* <tr className="sub-total-row">
                  <td colSpan={4}>&nbsp;</td>
                  <td>VAT({orderDetailsById?.vat}%)</td>
                  <td>${calculateVAT(orderDetailsById?.GrandTotal,orderDetailsById?.vat)}</td>
                </tr> */}
                <tr className="grand-total-row">
                  <td colSpan={4}>&nbsp;</td>
                  <td><b>Total</b></td>
                  {/* <td><b>${calculateTotalPrice(orderDetailsById?.total_price,orderDetailsById?.vat)}</b></td> */}
                  <td><b>${parseFloat(orderDetailsById?.total_price).toFixed(2)}</b></td>
                </tr>
              </tbody>
            </table>
            {/* <table className="table note-table">
              <tr>
                <td>
                  <p>Note<br />
                  <small>Thanks for your business.</small></p>
                </td>
                <td><img src={signatureImg} alt /></td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p>Tearm & Conditions<br /><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
normal distribution of letters, as opposed to using making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</small></p>
                </td>
              </tr>
            </table> */}
          </>
        )}
    </div>
  );
};

export default PrintableData;
