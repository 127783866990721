import React, { useState } from 'react';

function DropdownWithFilter() {
  const allOptions = ['1', '2', '3', '4'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(allOptions);

  const handleSelect = (selectedOption) => {
    if (selectedOptions.includes(selectedOption)) {
      setSelectedOptions(selectedOptions.filter(option => option !== selectedOption));
      setFilteredOptions(allOptions);
    } else {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setFilteredOptions(allOptions.filter(option => option !== selectedOption));
    }
  };

  return (
    <div>
      <h2>Select Options</h2>
      <select onChange={(e) => handleSelect(e.target.value)}>
        <option value="">Select an option</option>
        {filteredOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>

      <h2>Selected Options</h2>
      <ul>
        {selectedOptions.map((option, index) => (
          <li key={index}>{option}</li>
        ))}
      </ul>
    </div>
  );
}
 
export default DropdownWithFilter;
