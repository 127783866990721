import React, { Component , useEffect } from "react";
import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { useState } from "react";

const exceptThisSymbols = ["e", "E", "+", "-"];

const Adminsettings = () => {

const [priceRegWordUpdate,setPriceRegWordUpdate] = useState();
const [priceExpWordUpdate,setPriceExpWordUpdate] = useState();
const [pricePageUpdate,setPricePageUpdate] = useState();
const [commissionUpdate,setCommissionUpdate] = useState();

const [updatedResumePrice,setUpdatedResumePrice] = useState();
const [updatedCVPrice,setUpdatedCVPrice] = useState();
const [updatedResumeCVCommission,setUpdatedResumeCVCommission] = useState();

const [showPriceRegError,setShowPriceRegError] = useState(false);
const [showPriceExpError,setShowPriceExpError] = useState(false);
const [showPricePageError,setShowPricePageError] = useState(false);
const [showCommissionError,setShowCommissionError] = useState(false);

const [showResumePriceError,setShowResumePriceError] = useState(false);
const [showCVPriceError,setShowCvPriceError] = useState(false);
const [showRCCommissionError,setShowRCCommissionError] = useState(false);


useEffect(()=>{
  fetchAmount();
},[])

  //Fetch All Amount
  const fetchAmount = async() =>{
    // console.log(commissionUpdate);
      try
    {
      let apiRes = await(
        axios
        .post(
            `${API_BASE_URL}cvresume/getPagecountPrice`,{
              },
          )
      )
      if(apiRes)
      {
          console.log(apiRes.data.res)
          if(apiRes.data.res == true) 
          {
            console.log(apiRes.data.data)
            // console.log(apiRes.data.Commission.commission)
            setPriceRegWordUpdate(apiRes.data.Pagecount.regularprice);
            setPriceExpWordUpdate(apiRes.data.Pagecount.expeditedprice);
            setCommissionUpdate(apiRes.data.Commission.commission);
            // setCommissionUpdate('');
            let update_Resume_Price = (apiRes.data.Resumeprice.price / apiRes.data.Resumeprice.numberofpages);
            let update_CV_Price = (apiRes.data.Cvprice.price / apiRes.data.Cvprice.numberofpages);
            setUpdatedResumePrice(update_Resume_Price);
            setUpdatedCVPrice(update_CV_Price);
            setUpdatedResumeCVCommission(apiRes.data.Commission.Cvresumecommission);
          }
          else
          {
            NotificationManager.error('Not updated','',2000);
          }
      }
    }
    catch (error) 
    {
      
    }
  }

  //Update Reg Price per word
  const update_reg_price_word = async() =>{
    if(priceRegWordUpdate === '' || priceRegWordUpdate === undefined)
    {
      setShowPriceRegError(true);
      NotificationManager.warning('Enter Regular Price Per Word Amount','',3000);
    }
    else
    {
      try
      {
       let apiRes = await(
        axios
        .post(
            `${API_BASE_URL}cvresume/editPagecountRegularPrice`,{
              regularprice: priceRegWordUpdate,
            },
          )

        )
        if(apiRes)
        {
            console.log(apiRes.data.res)
            if(apiRes.data.res == true) 
            {
                NotificationManager.success('Updated Editor Remuneration','',2000);
                setShowPriceRegError(false);
                setShowPriceExpError(false);
                setShowPricePageError(false);
                setShowCommissionError(false);
            }
            else
            {
              NotificationManager.error('Not updated','',2000);
            }
        }
      }
      catch (error) 
      {
        
      }
    }
  }

  //Update Exp Price per word
  const update_exp_price_word = async() =>{
      if(priceExpWordUpdate === '' || priceExpWordUpdate === undefined)
      {
        setShowPriceExpError(true);
        NotificationManager.warning('Enter Expedited Price Per Word Amount','',3000);
      }
      else
      {
        try
       {
         let apiRes = await(
          axios
          .post(
              `${API_BASE_URL}cvresume/editPagecountExpeditedPrice`,{
                expeditedprice: priceExpWordUpdate,
                },
            )
          )
          if(apiRes)
          {
              console.log(apiRes.data.res)
              if(apiRes.data.res == true) 
              {
                  NotificationManager.success('Updated Editor Remuneration','',2000);
                  setShowPriceRegError(false);
                  setShowPriceExpError(false);
                  setShowPricePageError(false);
                  setShowCommissionError(false);
              }
              else
              {
                NotificationManager.error('Not updated','',2000);
              }
          }
        }
        catch (error) 
        {
          
        }
      }
  }

  //Update Price per page
  const update_price_page = async() =>{
    if(pricePageUpdate === '' || pricePageUpdate === undefined)
    {
      setShowPricePageError(true);
      NotificationManager.warning('Enter Price Per Page Amount','',3000);
    }
    // else
    // {
    //   try
    //  {
    //    let apiRes = await(
    //     axios
    //     .post(
    //         `${API_BASE_URL}job/getPagecountPrice`,{
    //           commission: commissionUpdate,
    //           },
    //       )
    //   )
    //   if(apiRes)
    //   {
    //       console.log(apiRes.data.res)
    //       if(apiRes.data.res == true) 
    //       {
    //          NotificationManager.success('Updated Editor Remuneration','',2000);
                setShowPriceRegError(false);
                setShowPriceExpError(false);
                setShowPricePageError(false);
                setShowCommissionError(false);
    //       }
    //       else
    //       {
    //         NotificationManager.success('Successfully Registered','',2000);
    //       }
    //   }
    // }
    // catch (error) 
    // {
      
    // }
    // }
  }

  //Editor Commission
  const editor_remuneration = async() =>{
    console.log(commissionUpdate);
    if(commissionUpdate === '' || commissionUpdate === undefined)
    {
      setShowCommissionError(true);
      NotificationManager.warning('Enter Editor Remuneration Amount','',3000);
    }
    else
    {
      try
     {
       let apiRes = await(
        axios
        .post(
            `${API_BASE_URL}cvresume/editorRemuneration`,{
              commission: commissionUpdate,
              },
          )
      )
      if(apiRes)
      {
          console.log(apiRes.data.res)
          if(apiRes.data.res == true) 
          {
              NotificationManager.success('Updated Editor Remuneration','',2000);
              setShowPriceRegError(false);
              setShowPriceExpError(false);
              setShowPricePageError(false);
              setShowCommissionError(false);
          }
          else
          {
            NotificationManager.error('Not updated','',2000);
          }
      }
    }
    catch (error) 
    {
      
    }
    }
  }

  //Update RC data
  const updateRCData = async(type) =>{
    if(type == 'resume')
    {
      if(updatedResumePrice === '' || updatedResumePrice === undefined)
      {
        setShowResumePriceError(true);
        NotificationManager.warning('Enter Resume Price','',3000);
      }
      else
      {
        try
       {
         let apiRes = await(
          axios
            .post(
              `${API_BASE_URL}cvresume/editRCPrice`,{price: updatedResumePrice,type:'resume'},
            )
        )
        if(apiRes)
        {
            if(apiRes.data.res == true) 
            {
                NotificationManager.success('Updated Resume Price','',2000);
                setShowPriceRegError(false);
                setShowPriceExpError(false);
                setShowPricePageError(false);
                setShowCommissionError(false);

                setShowResumePriceError(false);
            }
            else
            {
              NotificationManager.error('Not updated','',2000);
            }
        }
      }
      catch (error) 
      {
        
      }
      }
    }
    else if(type == 'cv')
    {
      if(updatedCVPrice === '' || updatedCVPrice === undefined)
      {
        setShowCvPriceError(true);
        NotificationManager.warning('Enter CV Price','',3000);
      }
      else
      {
        try
       {
         let apiRes = await(
          axios
            .post(
              `${API_BASE_URL}cvresume/editRCPrice`,{price: updatedCVPrice,type:'cv'},
            )
        )
        if(apiRes)
        {
            if(apiRes.data.res == true) 
            {
                NotificationManager.success('Updated CV Price','',2000);
                setShowPriceRegError(false);
                setShowPriceExpError(false);
                setShowPricePageError(false);
                setShowCommissionError(false);

                setShowCvPriceError(false);
            }
            else
            {
              NotificationManager.error('Not updated','',2000);
            }
        }
      }
      catch (error) 
      {
        
      }
      }
    }
    else
    {
      if(updatedResumeCVCommission === '' || updatedResumeCVCommission === undefined)
      {
        setShowRCCommissionError(true);
        NotificationManager.warning('Enter Editor Remuneration Amount For CV/Resume','',3000);
      }
      else
      {
        try
       {
         let apiRes = await(
          axios
            .post(
              `${API_BASE_URL}cvresume/editRCPrice`,{price: updatedResumeCVCommission,type:'rc_remuneration'},
            )
        )
        if(apiRes)
        {
            if(apiRes.data.res == true) 
            {
                NotificationManager.success('Updated Editor Remuneration For CV/Resume','',2000);
                setShowPriceRegError(false);
                setShowPriceExpError(false);
                setShowPricePageError(false);
                setShowCommissionError(false);

                setShowRCCommissionError(false);
            }
            else
            {
              NotificationManager.error('Not updated','',2000);
            }
        }
      }
      catch (error) 
      {
        console.log(error);
      }
      }
    }
  }

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            {/* <span className="editorNameLogo">MW</span> */}
            <h2>Pricing Settings</h2>
          </span> 
        </div>
        {/* for services */}
        <section className="section AccDtlsPnl">
          <div className="profile_tab_content pricing-setting">
            <ul className="noti_list_main row">
              <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="row noti_list price-list">

                    <li className="col-md-6 col-lg-4">
                      <div class="form-floating">
                          <input type="number" className="form-control"  placeholder="Regular Price per word" name="reg_price_per_word" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setPriceRegWordUpdate(e.target.value)} value={priceRegWordUpdate}/>
                          {/* <label for="lastInput"><span>*</span>Regular Price Per Word</label> */}
                          <label for="lastInput"><span>*</span>Regular Price Per Word ($)</label>
                      </div>
                      <Button className="CmnBtn" type="submit" onClick={()=>update_reg_price_word()}></Button>
                      <span>{(priceRegWordUpdate === undefined || priceRegWordUpdate === '' || priceRegWordUpdate === null ) && showPriceRegError ? (<div className='error'><p>Regular Price per word is required</p></div>):''}</span><br/>
                      {/* <Button className="CmnBtn btn btn-primary" type="submit" onClick={()=>update_reg_price_word()}>Update Reg Price/Word</Button> */}
                    </li>

                    <li className="col-md-6 col-lg-4">
                      <div class="form-floating">
                          <input type="number" className="form-control"   placeholder="Expedited Price per word" name="exp_price_per_word" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setPriceExpWordUpdate(e.target.value)} value={priceExpWordUpdate}/>
                          {/* <label for="lastInput"><span>*</span>Expedited Price Per Word</label> */}
                          <label for="lastInput"><span>*</span>Expedited Price Per Word ($)</label>
                      </div>
                      <Button className="CmnBtn" type="submit" onClick={()=>update_exp_price_word()}></Button>
                      <span>{(priceExpWordUpdate === undefined || priceExpWordUpdate === '' || priceExpWordUpdate === null ) && showPriceExpError ? (<div className='error'><p>Expedited Price per word is required</p></div>):''}</span><br/>   
                      {/* <Button className="CmnBtn btn btn-primary" type="submit" onClick={()=>update_exp_price_word()}>Update Exp Price/Word</Button> */}
                    </li>

                    {/* <li className="col-md-6"><br/>
                      <div class="form-floating">
                          <input type="number" className="form-control" id="lastInput" placeholder="Price per page" name="price_per_page"  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setPricePageUpdate(e.target.value)} value={pricePageUpdate}/>
                          <label for="lastInput"><span>*</span>Price per page</label>
                      </div>
                      <span>{(pricePageUpdate === undefined || pricePageUpdate === '' || pricePageUpdate === null ) && showPricePageError ? (<div className='error'><p>Price per page is required</p></div>):''}</span><br/>
                      <Button className="btn btn-primary" type="submit" onClick={()=>update_price_page()}>Update Price/Page</Button>
                    </li> */}

                    <li className="col-md-6 col-lg-4">
                      <div class="form-floating">
                          <input type="number" className="form-control" id="lastInput" placeholder="Editor Remuneration %" name="editor_commission" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setCommissionUpdate(e.target.value)} value={commissionUpdate}/>
                          <label for="lastInput"><span>*</span>Editor Remuneration (%)</label>
                      </div>
                      <Button className="CmnBtn" type="submit" onClick={()=>editor_remuneration()}></Button>
                      <span>{(commissionUpdate === undefined || commissionUpdate === '' || commissionUpdate === null ) && showCommissionError ? (<div className='error'><p>Editor Remuneration is required</p></div>):''}</span><br/>
                      {/* <Button className="CmnBtn btn btn-primary" type="submit" onClick={()=>editor_remuneration()}>Update Remuneration</Button> */}
                    </li>
                  
                  </ul>
                </div>
              </li>
              
              {/* <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="noti_list row">
                    <li className="col-lg-7 noti_text">
                      <h4>Email Notification</h4>
                      <p>Turn on email notification to get updates through email.</p>
                    </li>
                    <li className="col-lg-5 noti_toggle">
                    <div class="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" />
                    </div>
                    </li>
                  </ul>
                </div>
              </li> */}
             
            </ul>
            {/* <div className="noti_top">
              <p>Enable the "Remember me" option:</p>
              <div>
                <label className="radio inline">
                  <input defaultChecked name="rember-me" defaultValue type="radio" />
                  <span>Yes</span>
                </label>
                <label className="radio inline">
                  <input name="rember-me" defaultValue type="radio" />
                  <span>No</span>
                </label>
              </div>
            </div> */}
            {/* <div className="noti_top">
              <p>Enable lost password form:</p>
              <div>
                <label className="radio inline">
                  <input defaultChecked name="lost-password" defaultValue type="radio" />
                  <span>Yes</span>
                </label>
                <label className="radio inline">
                  <input name="lost-password" defaultValue type="radio" />
                  <span>No</span>
                </label>
              </div>
            </div> */}
          </div>
        </section>

        {/* for resume/cv */}
        <section className="section AccDtlsPnl">
          <div className="profile_tab_content pricing-setting">
            <ul className="noti_list_main row">
              <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="row noti_list price-list">

                    <li className="col-md-6 col-lg-4">
                      <div class="form-floating">
                          <input type="number" className="form-control"  placeholder="Resume Price per page" name="resume_price_per_page" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setUpdatedResumePrice(e.target.value)} value={updatedResumePrice}/>
                          <label for="lastInput"><span>*</span>Resume Price Per Page ($)</label>
                      </div>
                      <Button className="CmnBtn" type="submit" onClick={()=>updateRCData('resume')}></Button>
                      <span>{(updatedResumePrice === undefined || updatedResumePrice === '' || updatedResumePrice === null ) && showResumePriceError ? (<div className='error'><p>Resume Price is required</p></div>):''}</span><br/>
                      {/* <Button className="CmnBtn btn btn-primary" type="submit" onClick={()=>update_reg_price_word()}>Update Reg Price/Word</Button> */}
                    </li>

                    <li className="col-md-6 col-lg-4">
                      <div class="form-floating">
                          <input type="number" className="form-control"   placeholder="CV Price per page" name="cv_price_per_page" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setUpdatedCVPrice(e.target.value)} value={updatedCVPrice}/>
                          <label for="lastInput"><span>*</span>CV Price Per Page ($)</label>
                      </div>
                      <Button className="CmnBtn" type="submit" onClick={()=>updateRCData('cv')}></Button>
                      <span>{(updatedCVPrice === undefined || updatedCVPrice === '' || updatedCVPrice === null ) && showCVPriceError ? (<div className='error'><p>CV Price is required</p></div>):''}</span><br/>   
                      {/* <Button className="CmnBtn btn btn-primary" type="submit" onClick={()=>update_exp_price_word()}>Update Exp Price/Word</Button> */}
                    </li>

                    {/* <li className="col-md-6"><br/>
                      <div class="form-floating">
                          <input type="number" className="form-control" id="lastInput" placeholder="Price per page" name="price_per_page"  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setPricePageUpdate(e.target.value)} value={pricePageUpdate}/>
                          <label for="lastInput"><span>*</span>Price per page</label>
                      </div>
                      <span>{(pricePageUpdate === undefined || pricePageUpdate === '' || pricePageUpdate === null ) && showPricePageError ? (<div className='error'><p>Price per page is required</p></div>):''}</span><br/>
                      <Button className="btn btn-primary" type="submit" onClick={()=>update_price_page()}>Update Price/Page</Button>
                    </li> */}

                    <li className="col-md-6 col-lg-4">
                      <div class="form-floating">
                          <input type="number" className="form-control" id="lastInput" placeholder="Editor Remuneration %" name="editor_rc_commission" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>setUpdatedResumeCVCommission (e.target.value)} value={updatedResumeCVCommission}/>
                          <label for="lastInput"><span>*</span>Editor Remuneration (%)</label>
                      </div>
                      <Button className="CmnBtn" type="submit" onClick={()=>updateRCData('rc_remuneration')}></Button>
                      <span>{(updatedResumeCVCommission === undefined || updatedResumeCVCommission === '' || updatedResumeCVCommission === null ) && showRCCommissionError ? (<div className='error'><p>Editor Remuneration is required</p></div>):''}</span><br/>
                      {/* <Button className="CmnBtn btn btn-primary" type="submit" onClick={()=>editor_remuneration()}>Update Remuneration</Button> */}
                    </li>
                  
                  </ul>
                </div>
              </li>
              
              {/* <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="noti_list row">
                    <li className="col-lg-7 noti_text">
                      <h4>Email Notification</h4>
                      <p>Turn on email notification to get updates through email.</p>
                    </li>
                    <li className="col-lg-5 noti_toggle">
                    <div class="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" />
                    </div>
                    </li>
                  </ul>
                </div>
              </li> */}
             
            </ul>
            {/* <div className="noti_top">
              <p>Enable the "Remember me" option:</p>
              <div>
                <label className="radio inline">
                  <input defaultChecked name="rember-me" defaultValue type="radio" />
                  <span>Yes</span>
                </label>
                <label className="radio inline">
                  <input name="rember-me" defaultValue type="radio" />
                  <span>No</span>
                </label>
              </div>
            </div> */}
            {/* <div className="noti_top">
              <p>Enable lost password form:</p>
              <div>
                <label className="radio inline">
                  <input defaultChecked name="lost-password" defaultValue type="radio" />
                  <span>Yes</span>
                </label>
                <label className="radio inline">
                  <input name="lost-password" defaultValue type="radio" />
                  <span>No</span>
                </label>
              </div>
            </div> */}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Adminsettings;
