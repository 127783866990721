
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import jwtDecode from 'jwt-decode';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { API_BASE_URL } from "../../api_url";
import { NotificationManager } from "react-notifications";
import { useState } from 'react';
// import { Loader } from 'rsuite';

 const ClientGoogleLogin = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);

  const logOut = () => {
    googleLogout();
};
    return(
        <>
            <GoogleOAuthProvider 
                clientId="106774422810-obs99o84un57bfol7d1msnssact2b354.apps.googleusercontent.com">
                {/* {loading && <i class="fa fa-refresh fa-spin"></i>} */}
            <GoogleLogin
                onSuccess={async(credentialResponse) => {
                const detailsInfo = jwtDecode(credentialResponse.credential)
                // console.log(credentialResponse);
                console.log(detailsInfo);
                try
                {
                    setLoading(true);
                    let apiRes =  await axios.post(`${API_BASE_URL}login/googlelogin`, {
                    first_name: detailsInfo.given_name,
                    last_name: detailsInfo.family_name,
                    email_address: detailsInfo.email,
                    });
                    console.warn(apiRes)
                    if (apiRes)
                    {
                        if (apiRes.data.res == true) 
                        {
                            setLoading(false);
                            localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
                            if(apiRes.data.data[0].role==2)
                            {
                                // navigate('/client-my-account');
                                navigate(`/client-my-account/client-edit-account/${apiRes.data.data[0].id}`);
                            }
                        }
                        else
                        {
                            // alert(apiRes.data.msg);
                            // NotificationManager.error(apiRes.data.msg, "", 2000);
                            NotificationManager.error('Email id already exist', "", 2000);
                            setLoading(false);
                        }
                    }
                }
                catch(error)
                {
                    console.warn(error.msg);
                    setLoading(false);
                }
            }}
                onError={() => {
                console.log('Login Failed');
                }}
            
            />

            </GoogleOAuthProvider>

                {/* Sign In With Google */}
        </>
    )
 }


 export default ClientGoogleLogin;