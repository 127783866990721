import React, { Component, useState, useEffect  } from "react";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const servicesBanner = require("../../assets/img/services-banner.jpg");
const servicesImg1 = require("../../assets/img/services-img1.jpg");
const servicesImg2 = require("../../assets/img/services-img2.jpg");
const servicesImg3 = require("../../assets/img/services-img3.jpg");
const servicesImg4 = require("../../assets/img/services-img4.jpg");
const servicesImg5 = require("../../assets/img/services-img5.jpg");
const servicesImg6 = require("../../assets/img/services-img6.jpg");

const HowOurProcessWorks = () => { 

    const [showServices,setShowServices] = useState([]); // For all service
    const location = useLocation();
    const {clientAccess} = location.state ?? '';
    console.log(clientAccess);
    useEffect(() => {
        AOS.init();
    });

    useEffect(()=>{
        getAllServices();
    },[])

// get all services
const getAllServices = async () => {
    try
    {
        let apiRes = await axios.get(`${API_BASE_URL}service`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                console.log(apiRes.data.data);
                setShowServices(apiRes.data.data);
            }
        }
    }
    catch (error)
    {
        // NotificationManager.error(error, "", 2000);
    }
}
    return(
        <div>
            <Header />
            <div className="inner-banner" style={{backgroundImage: `url(${servicesBanner})`}}>
                <div className="container">
                    <h3 data-aos="fade-up">How Our Process Works</h3>
                </div>
            </div>
            <div className="services-sec">
                <div className="container">
                    <h3>How Our Process Works</h3>
                    <ul className="row services-list">
                        <li className="col-md-6">
                            <div className="row">
                                <div className="col-md-3 col-lg-2 count-section"><span className="process-count-span">1</span></div>
                                <div className="col-md-9 col-lg-10 text-section count-text-section">
                                    <h4>Order Placed</h4>
                                    <p>When you upload your work on our website and make the appropriate payment, your order will be placed.</p>
                                </div>
                            </div>
                        </li>
                        <li className="col-md-6">
                            <div className="row">
                                <div className="col-md-3 col-lg-2 count-section"><span className="process-count-span">2</span></div>
                                <div className="col-md-9 col-lg-10 text-section count-text-section">
                                    <h4>Editor Assigned</h4>
                                    <p>Once order is placed, your work will go to an editor who will diligently improve the quality of your work.</p>
                                </div>
                            </div>
                        </li>
                        <li className="col-md-6">
                            <div className="row">
                                <div className="col-md-3 col-lg-2 count-section"><span className="process-count-span">3</span></div>
                                <div className="col-md-9 col-lg-10 text-section count-text-section">
                                    <h4>Quality Evaluated</h4>
                                    <p>At this point, your work is given more review by an expert to ensure that the work is a masterpiece.</p>
                                </div>
                            </div>
                        </li>
                        <li className="col-md-6">
                            <div className="row">
                                <div className="col-md-3 col-lg-2 count-section"><span className="process-count-span">4</span></div>
                                <div className="col-md-9 col-lg-10 text-section count-text-section">
                                    <h4>Order Completed</h4>
                                    <p>Once your work is completed, you will be notified to download it for your review and approval.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p><a>Elite Reviser</a> can handle that. <a>Contact Us.</a></p>                      
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left"><a className="btn btn-primary">Get Started</a></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HowOurProcessWorks;