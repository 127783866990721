import {
  React, useEffect, useState,
  Link,
  NotificationManager,
  Skeleton
} from '../../utility/CommonImport';

import Clientheader from "../../Components/ClientComponents/Clientheader";
import Clientsidebar from "../../Components/ClientComponents/Clientsidebar";
import { userAccountDetails } from '../../API/apiRequests';

const Clientmyaccount = () => {
  let data = JSON.parse(localStorage.getItem("user_data_elite"));
  const [customerData, setCustomerData] = useState();

  const fetchClientDetails = async () => {
    try {
      let apiRes = await userAccountDetails(data?.id)
      if (apiRes.data.res == true) {
        setCustomerData(apiRes.data.data[0]);
      } else{
        NotificationManager.warning('The server failed to fullfill a request.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const mergeShortLetter = (first_letter,last_letter) => {
    let name = first_letter + last_letter;
    return name
  }

  useEffect(() => {
    fetchClientDetails();
  }, []);

  return (
    <div>
      <Clientheader />
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          {customerData ? (
            <span className="TitleWrap TitleWrapNonSpce">
              <span className="NameLogo">
                {mergeShortLetter(customerData?.first_name?.charAt(0),customerData?.last_name?.charAt(0))}
              </span>
              <h2>My Profile</h2>
            </span>
          ):(
            <span className="TitleWrap TitleWrapNonSpce">
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" style={{ width: "87px", height: "10px" }} />
            </span>
          )}
        </div>
        <section className="section AccDtlsPnl">
          <div className="AccBox">
            <div className="pagetitle">
              <h3>Personal Details</h3>
              <Link to={`/client-my-account/client-edit-account/${customerData?.id}`} className="btn btn-primary CmnBtn">
                <i className="bi bi-pencil" /> Edit Profile
              </Link>
            </div>
            <ul className="AccDtls">
              <li className="col-md-3">
                <label>First Name</label>
                <p className="InputTxt">
                  {customerData?.first_name ? customerData?.first_name : <Skeleton variant="text" style={{ width: "87px", height: "10px" }} />}
                </p>
              </li>
              <li className="col-md-3">
                <label>Last Name</label>
                <p className="InputTxt">
                  {customerData?.last_name ? customerData?.last_name : <Skeleton variant="text" style={{ width: "87px", height: "10px" }} />}
                </p>
              </li>
              <li className="col-md-3">
                <label>Phone Number</label>
                <p className="InputTxt">{customerData?.ph_no ? customerData?.ph_no : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}</p>
              </li>
              <li className="col-md-3">
                <label>Email Address</label>
                <p className="InputTxt">
                  {customerData?.email_address ? customerData?.email_address : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}
                </p>
              </li>
              <li className="col-md-3">
                <label>Street Address</label>
                <p className="InputTxt">
                  {customerData?.address ? customerData?.address : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}
                </p>
              </li>
              <li className="col-md-3">
                <label>City</label>
                <p className="InputTxt">{customerData?.city ? customerData?.city : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}</p>
              </li>
              <li className="col-md-3">
                <label>State</label>
                <p className="InputTxt">{customerData?.state ? customerData?.state : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}</p>
              </li>
              <li className="col-md-3">
                <label>ZIP Code</label>
                <p className="InputTxt">
                  {customerData?.zipcode ? customerData?.zipcode : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}
                </p>
              </li>
              <li className="col-md-3">
                <label>Country</label>
                <p className="InputTxt">
                  {customerData?.country ? customerData?.country : <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />}
                </p>
              </li>
              <li className="col-md-3">
                <label>Role</label>
                <p className="InputTxt">
                  {customerData ? (
                    customerData?.role == "0" ? "Administrator" : 
                    customerData?.role == "1" ? "Editor" : 
                    customerData?.role == "2" ? "Client" : ""
                  ): <Skeleton variant="text" style={{ width: "100px", height: "10px" }} />
                  }
                </p>
              </li>
            </ul>
          </div>
          {customerData?.login_type !== "Google" ? (
            <div className="AccBox">
              <div className="pagetitle">
                <h3>Password</h3>
                <Link to={`/client-my-account/client-edit-password`} className="btn btn-primary CmnBtn">
                  <i className="bi bi-pencil" />
                  Edit Password
                </Link>
              </div>
            </div>
          ) : null}
        </section>
      </main>
    </div>
  );
};

export default Clientmyaccount;
