import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAppleLogin } from '../../API/apiRequests';
import ClipLoader from 'react-spinners/ClipLoader';

const AppleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { codeData, idTokenData, stateData, userData } = location.state || {};
  const [loading, setLoading] = useState(true);

  const handleAppleLogin = async () => {
    try
    {
      if (codeData && idTokenData)
      {
        let payload_data = {
          code: codeData,
          id_token : idTokenData,
          state : stateData,
          user : userData
        }
    
        let apiRes = await userAppleLogin(payload_data);
        let responseData = await apiRes.json();
    
        if(responseData.res)
        {
          console.log('Apple login successful.');
          localStorage.setItem('user_data_elite', JSON.stringify(responseData.data[0]));
          if (responseData.data[0].role == 2)
          {
            navigate("/client-my-orders");
          }
          else
          {
            NotificationManager.error('User is not allowed!','',2000);
          }
        }
        else
        {
          NotificationManager.error(' Error in : Apple login.','',2000);
        }
      }
      else
      {
        NotificationManager.error('Error : Missing TokenData');
        navigate('/login');
      }
    }
    catch(error)
    {
      console.error('Error : ' , error);
    }
    finally
    {
      setLoading(false);
    }
    
  }

  // useEffect(async() => {
  //   console.log('AppleCallback state:', location.state);
  //   if (codeData && idTokenData)
  //   {
  //     console.log('Calling API with codeData and idTokenData:', codeData, idTokenData);
  //     let payload_data = {
  //       code: codeData,
  //       id_token : idTokenData,
  //       state : stateData,
  //       user : userData
  //     }

  //     let apiRes = await userAppleLogin(payload_data);
  //     let responseData = await apiRes.json();

  //     if(responseData.res)
  //     {
  //       console.log('Apple login successful.');
  //     }
  //     else
  //     {
  //       console.error(' Error in : Apple login.');
  //     }
      
  //     fetch('https://elitereviser.com/serversite/api/login/applelogin', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       // body: JSON.stringify({ codeData, idTokenData }),
  //       // body: JSON.stringify({ id_token: idTokenData }),
  //       body: JSON.stringify(payload_data),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log('API response:', data);
  //         NotificationManager.success('Logged-in successfully..','',2000);
  //       //   localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
  //       //     if (apiRes.data.data[0].role == 2)
  //       //     {
  //       //         navigate("/client-my-orders");
  //       //     }
  //       //     else
  //       //     {
  //       //         console.error('User is not allowed!')
  //       //     }
  //         // Perform further actions based on the API response
  //         // Example: navigate("/client-my-orders")
  //       })
  //       .catch((error) => {
  //         console.error('Error calling API:', error);
  //       });
  //   }
  //   else
  //   {
  //     console.error('Missing codeData or idTokenData');
  //   }

  // }, [codeData, idTokenData, location.state, navigate]);

  useEffect(() => {
    handleAppleLogin()
  }, [codeData, idTokenData, location.state, navigate]);

  // return <div>Processing login...</div>;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      {loading && <ClipLoader color="#000" loading={loading} size={50} />}
      <div>Processing login...</div>
    </div>
  );
}; 

export default AppleCallback;
