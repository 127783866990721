import React, { Component,useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Clientheader from "../../Components/ClientComponents/Clientheader";
import Clientsidebar from "../../Components/ClientComponents/Clientsidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from "react-bootstrap/Button";
import {NotificationManager} from 'react-notifications'; 

const Clienteditpassword = () => {
  const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[adminData,setAdminData] = useState();
    const [loading,setLoading] = useState(false); 
    useEffect(() => {
        console.log(data);
        // console.log(data.first_name.charAt(0))
        getAdminInfos();
    }, []);

    // get admin information
    const getAdminInfos = async () => {
      try {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
          // console.log(apiRes.data.data);
          setAdminData(apiRes.data.data[0]);
          }
      }
      } catch (error) {
      //close try
      // NotificationManager.error(error, "", 2000);
      }
  };

  //update password
  const adminUpdatePassword = async(values,{resetForm}) =>{
    console.log(values);
      try {
          setLoading(true);
          let apiRes = await(
              axios
              .post(
              `${API_BASE_URL}account/changepassword`,{
                  id: data.id,
                  old_password: values.old_password,
                  new_password: values.new_password,
                  confirm_password: values.confirm_password,
              },
          )
          )
          if(apiRes)
          {
              console.log(apiRes.data.res)
              if(apiRes.data.res == true) 
              {
                  NotificationManager.success('Password Updated Successfully','',2000);
                  setLoading(false);
                  navigate("/client-my-account");
                  // resetForm({values:''})
              }else
              {
                  setLoading(false);
                  NotificationManager.error(apiRes.data.msg, '',2000);
              }
          }
      }
      catch (error) 
      {
          // NotificationManager.error(error, '',2000);
      } 
  }

  let schema = yup.object().shape({
  
    old_password: yup
    .string()
    // .ensure()
    .required('Old Password is required')
    .min(6,'Old Password must be at least 6 characters')
    .max(20,'Old Password must be at most 20 characters'),

    new_password: yup
    .string()
    // .ensure()
    .required('New Password is required')
    .min(6,'New Password must be at least 6 characters')
    .max(20,'New Password must be at most 20 characters'),

    confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .min(6,'Confirm Password must be at least 6 characters')
    .max(20,'Confirm Password must be at most 20 characters')
    .oneOf([yup.ref('new_password'),null],'Passwords did not match')
}); 
  return (
    <div>
      <Clientheader />
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <span className="NameLogo">{adminData?.first_name?.charAt(0)}{adminData?.last_name?.charAt(0)}</span>
            <h2>My Account</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
          <Formik
            enableReinitialize
            initialValues={{
              id: data.id,
              old_password: '',
              new_password: '',
              confirm_password: '',
            }}
            onSubmit={(values,{resetForm})=>{adminUpdatePassword(values,{resetForm})}}
            validationSchema = {schema}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              values, 
              setFieldValue
            })=>(
              <div className="AccBox">
                <div className="pagetitle">
                  <h3>Password</h3>
                  {loading ? (
                    <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i className="bi bi-check-circle" />Save</Button>
                  ):(
                    <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Save</Button>
                  )}
                </div>
                <form action method="get">
                  <ul className="AccDtls EditAccDtls EditAccPswd row">
                  <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                    <li className="col-md-4">
                      <input className="InptField" type="password" placeholder="Old Password" name="old_password" onChange={handleChange} value={values.old_password} />
                      {errors.old_password ? <div className='error'>{errors.old_password}</div> : null}
                    </li>
                    <li className="col-md-4">
                      <input className="InptField" type="password" placeholder="New Password" name="new_password" onChange={handleChange} value={values.new_password}/>
                      {errors.new_password ? <div className='error'>{errors.new_password}</div> : null}
                    </li>
                    <li className="col-md-4">
                      <input className="InptField" type="password" placeholder="Retype New Password" name="confirm_password" onChange={handleChange} value={values.confirm_password}/>
                      {errors.confirm_password ? <div className='error'>{errors.confirm_password}</div> : null}
                    </li>
                  </ul>
                </form>
              </div>
            )}
          </Formik>
        </section>
      </main>
    </div>
  );
};

export default Clienteditpassword;
