import React, { Component,useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const DeputyAdminMyAccount = () => {
    const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[deputyData,setDeputyData] = useState();
    // useEffect(() => {
    //     if (data=== null)
    //     {
    //         navigate("/admin-login");
    //         navigate(0);
    //     }
    //     else if(data?.role == '1' || data?.role == '2' || data?.role == '4')
    //     {
    //         navigate("/admin-login");
    //     }
    //   })
    useEffect(()=>{
        getDeputyAdminInfos();
    },[])
    
    // get admin information
    const getDeputyAdminInfos = async () => {
        try
        {
            let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                    setDeputyData(apiRes.data.data[0]);
                }
            }
        }
        catch (error)
        {
            // NotificationManager.error(error, "", 2000);
        }
    };

  return (
    <div>
        <DeputyAdminHeader />
        <DeputyAdminSidebar />
        <main id="main" className="main">
            <div className="pagetitle">
                <span className="TitleWrap TitleWrapNonSpce">
                <span className="editorNameLogo">{deputyData?.first_name?.charAt(0)}{deputyData?.last_name?.charAt(0)}</span>
                    <h2>My Profile</h2>
                </span>
            </div>
            <section className="section AccDtlsPnl">
                <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Personal Details</h3>
                        <Link
                            to = {`/deputy-admin-my-account/deputy-admin-edit-account/${deputyData?.id}`}
                            className="btn btn-primary CmnBtn"
                        > 
                            <i className="bi bi-pencil" /> Edit Profile      
                        </Link>
                    </div>
                   
                    <ul className="AccDtls row">
                        <li className="col-md-3">
                            <label>First Name</label>
                            <p className="InputTxt">{deputyData?.first_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Last Name</label>
                            <p className="InputTxt">{deputyData?.last_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Phone Number</label>
                            <p className="InputTxt">{deputyData?.ph_no}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Email Address</label>
                            <p className="InputTxt">{deputyData?.email_address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Street Address</label>
                            <p className="InputTxt">{deputyData?.address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>City</label>
                            <p className="InputTxt">{deputyData?.city}</p>
                        </li>
                        <li className="col-md-3">
                            <label>State</label>
                            <p className="InputTxt">{deputyData?.state}</p>
                        </li>
                        
                        <li className="col-md-3">
                            <label>ZIP Code</label>
                            <p className="InputTxt">{deputyData?.zipcode}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Country</label>
                            <p className="InputTxt">{deputyData?.country}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Role</label>
                            <p className="InputTxt">
                              {
                                deputyData?.role == '0' ? 'Administrator':
                                deputyData?.role == '1' ? 'Editor':
                                deputyData?.role == '2' ? 'Client':
                                deputyData?.role == '3' ? 'Deputy Admin':
                                deputyData?.role == '4' ? 'Internal Editor':''
                              }
                            </p>
                        </li>
                        
                    </ul>
                </div>

                <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Password</h3>
                        {/* <NavLink to="/admin-my-account/admin-edit-password" className="btn btn-primary CmnBtn">
                            <i className="bi bi-pencil" /> Edit Password
                        </NavLink> */}
                        <Link 
                            to = {`/deputy-admin-my-account/deputy-admin-edit-password`}
                            className="btn btn-primary CmnBtn"
                        > 
                            <i className="bi bi-pencil" /> Edit Password      
                        </Link>
                    </div>
                    {/* <ul className="AccDtls row">
                        <li className="col-md-4">
                            <label>Password</label>
                            <p className="InputTxt">Sam@123456</p>
                        </li>
                    </ul> */}
                </div>

            </section>
        </main>
    </div>
  );
};

export default DeputyAdminMyAccount;
