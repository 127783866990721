import React, { Component, useState, useEffect } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import { Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { API_BASE_URL } from "../../api_url";
import { NotificationManager } from "react-notifications";

const contactBanner = require("../../assets/img/contact-banner.jpg");

const Contact = () => {
  const navigate = useNavigate();
  let data = JSON.parse(localStorage.getItem("user_data_elite"));
  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    // if (data=== null)
    // {
    //     navigate("/login");
    //     navigate(0);
    // }
    // if(data!=null && (data?.role == '0'))
    // {
    //   navigate("/admin-job-board");
    // }
    if (data != null && data?.role == "1") {
      navigate("/editor-job-board");
    }
    // else if(data!=null && (data?.role == '3'))
    // {
    //   navigate("/deputy-admin-job-board");
    // }
    // else if(data!=null && (data?.role == '4'))
    // {
    //   navigate("/internal-editor-job-board");
    // }
  });

  const [loading, setLoading] = useState(false);

  // Contact us function
  const contactUsData = async (values, { resetForm }) => {
    try {
      let payloadData = {
        name: values.name,
        email_address: values.email_address,
        ph_no: values.ph_no,
        message: values.message,
      };
      setLoading(true);
      // let apiRes = await axios.get(`${API_BASE_URL}contact?name=${values.name}&email_address=${values.email_address}&ph_no=${values.ph_no}&message=${values.message}`)
      let apiRes = await axios.post(`${API_BASE_URL}contact`, payloadData);
      if (apiRes) {
        if (apiRes.data.res == true) {
          resetForm({ values: "" });
          setLoading(false);
          NotificationManager.success("Thanks for contacting us! We will be in touch you shortly.", "", 2000);
        } else {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  let schema = yup.object().shape({
    name: yup.string().required("Name is required"),

    email_address: yup
      .string()
      .required("Email is required")
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address!"),

    ph_no: yup.string().required("Phone number is required").max(10, "Invalid Phone number"),

    message: yup.string().required("Your Message is required"),
  });

  return (
    <div>
      <Header />
      <div className="inner-banner" style={{ backgroundImage: `url(${contactBanner})` }}>
        <div className="container">
          <h3 data-aos="fade-up">Contact Us</h3>
          <p data-aos="fade-up">Feel free to contact us!</p>
        </div>
      </div>
      <div className="contact-body">
        <div className="container">
          <h3>Send Us A Message</h3>
          <div className="row">
            <Formik
              initialValues={{
                name: "",
                email_address: "",
                ph_no: "",
                message: "",
              }}
              onSubmit={(values, { resetForm }) => {
                contactUsData(values, { resetForm });
              }}
              validationSchema={schema}
            >
              {({ handleChange, handleSubmit, errors, values, setFieldValue }) => (
                <div className="col-md-6">
                  <ul className="row contact-list">
                    <li className="col-12">
                      <Form.Group controlId="formFullName">
                        <Form.Control type="text" placeholder="Full Name" className={errors.name && 'border-danger'} onChange={handleChange} name="name" value={values.name} />
                      </Form.Group>
                      {errors.name ? <div className="error"><i class="fa fa-exclamation-triangle" style="font-size:28px;color:red"></i>{errors.name}</div> : null}
                    </li>
                    <li className="col-12">
                      <Form.Group controlId="formEmailAddress">
                        <Form.Control type="email" placeholder="Email Address" onChange={handleChange} name="email_address" value={values.email_address} />
                      </Form.Group>
                      {errors.email_address ? <div className="error">{errors.email_address}</div> : null}
                    </li>
                    <li className="col-12">
                      <Form.Group controlId="formPhoneNumber">
                        <Form.Control type="tel" placeholder="Phone Number" onChange={handleChange} name="ph_no" value={values.ph_no} />
                      </Form.Group>
                      {errors.ph_no ? <div className="error">{errors.ph_no}</div> : null}
                    </li>
                    <li className="col-12">
                      <Form.Group controlId="formAddress">
                        <Form.Control
                          as="textarea"
                          style={{ resize: "none" }}
                          rows={3}
                          placeholder="Message"
                          onChange={handleChange}
                          name="message"
                          value={values.message}
                        />
                      </Form.Group>
                      {errors.message ? <div className="error">{errors.message}</div> : null}
                    </li>

                    {loading ? (
                      <li className="col-md-12">
                        <Button variant="primary" type="submit" disabled>
                          Submit
                        </Button>
                        {/* <Button variant="primary" type="submit" onClick={()=>handleSubmit()}>Submit</Button> */}
                      </li>
                    ) : (
                      <li className="col-md-12">
                        <Button variant="primary" type="submit" onClick={() => handleSubmit()}>
                          Submit
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </Formik>

            <div className="col-md-6">
              <ul className="quick-contact">
                <li>+1 (469) 305 1828</li>
                <li>
                  <a href="mailto:contact@elitereviser.com">contact@elitereviser.com</a>
                </li>
                <li>9550 Forest Lane Ste 135, Dallas, TX 75243</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="ggl-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.625821980145!2d-96.72965518492909!3d32.90805998459957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c1fdd5d0f39e7%3A0xd8f289b46826aa01!2s9550%20Forest%20Ln%20%23135%2C%20Dallas%2C%20TX%2075243%2C%20USA!5e0!3m2!1sen!2sin!4v1672042968066!5m2!1sen!2sin"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="iframe-map"
        ></iframe>
      </div>
      <div className="clearfix"></div>
      <Footer />
    </div>
  );
};

export default Contact;
