import React, { Component, useEffect, useState, useRef, useMemo  } from 'react';
import { NavLink, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import {NotificationManager} from 'react-notifications';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import { Rating } from 'react-simple-star-rating';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AOS from "aos";
import { Skeleton, Box, Typography } from "@mui/material";

export const reviewIcon = require("../assets/img/ReviewIcon.png");
export const docFileImg = require("../assets/img/DocFileImg.png");
export const docImg = require("../assets/img/docs-img.png");
export const iconImg = require("../assets/img/image-icon.png");
export const pdfImg = require("../assets/img/pdf.png");
export const attachFileIcon = require("../assets/img/attach-icon.png");
export const rtfImg = require("../assets/img/rtf-img.png");
export const odtImg = require("../assets/img/odt-img.png");
export const pagesImg = require("../assets/img/pages-img.png");
export const pptImg = require("../assets/img/ppt-img.png");
export const xlsxImg = require("../assets/img/xlsx-img.png");
export const gdocImg = require("../assets/img/gdoc-img.png");
export const gslideImg = require("../assets/img/google-slides.png");
export const gsheetImg = require("../assets/img/gsheet-img.png");
export const plainTextImg = require("../assets/img/txt-img.png");
export const LatexImg = require("../assets/img/text-img.png");
export const linkIcon = require("../assets/img/link-icon.png");
export const feedbackStarImg = require("../assets/img/feedbackStar.png");

export { 
    React, Component, useEffect, useState, useRef, useMemo,
    NavLink, useNavigate, useLocation, Link, useParams,
    Formik, Field, 
    yup, 
    axios, 
    NotificationManager,
    Form,
    Button,
    Modal,
    DateTime,
    DatePicker,
    Rating,
    CKEditor,
    ClassicEditor,
    AOS,
    Skeleton, Box, Typography
};