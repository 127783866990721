import React from 'react';
// import DateTimeDisplay from './DateTimeDisplay';
// import { useCountdown } from './useCountdown';
import DateTimeDisplay from '../Timer/DateTimeDisplay';
import { useCountdown } from '../Timer/useCountdown';

const ExpiredNotice = () => {

  return (
    // <div className="expired-notice">
      // {/* <span> Job Expired!!!</span> */}
      // {/* <span> Time limit reached!!!!</span> */}
      // {/* <p>Please select a future date and time.</p> */}
    // </div>
    <h6 style={{color: 'red'}}>
      {/* Time Reached */}
      <div class="count-down-timer count-down-timer-stop"><div class="show-counter"><div class="countdown danger"><p>00</p><span>D</span></div><div class="countdown"><p>00</p><span>H</span></div><div class="countdown"><p>00</p><span>M</span></div><div class="countdown"><p>00</p><span>S</span></div></div></div>
    </h6>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  var cnt_days = (days < 10) ? '0' + days : days;
  var cnt_hours = (hours < 10) ? '0' + hours : hours;
  var cnt_minutes = (minutes < 10) ? '0' + minutes : minutes;
  var cnt_seconds = (seconds < 10) ? '0' + seconds : seconds;
  return (
    <div className="show-counter">
      {/* <a
        href="https://tapasadhikary.com"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link"
      > */}
        <DateTimeDisplay value={cnt_days} type={'D'} isDanger={cnt_days <= 3} />
        {/* <p>:</p> */}
        <DateTimeDisplay value={cnt_hours} type={'H'} isDanger={false} />
        {/* <p>:</p> */}
        <DateTimeDisplay value={cnt_minutes} type={'M'} isDanger={false} />
        {/* <p>:</p> */}
        <DateTimeDisplay value={cnt_seconds} type={'S'} isDanger={false} />
      {/* </a> */}
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
       <ShowCounter
         days={days}
         hours={hours}
         minutes={minutes}
         seconds={seconds}
       />
    );
  }
};

export default CountdownTimer;
