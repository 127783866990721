import React, { Component, useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { saveAs} from 'file-saver';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import StarRating from "./StarRating";
import Button from "react-bootstrap/Button";
import { Rating } from 'react-simple-star-rating';
import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";
import { get_date_format, get_time_format } from "../../utility/Common";

import { DateTime } from 'luxon';

const InternalEditorNotification = () => {
  const navigate = useNavigate();
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  console.log(data);
  const timeZone = DateTime.local().zoneName;
  const editorRef = useRef();
  const [progress, setProgress] = useState()
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelAfter30, setOrderCancelAfter30] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [orderDetailsById,setOrderDetailsById] = useState();
  const [orderList,setOrderList] = useState([]);
  const [arrayLength,setArrayLength] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelCloseAfter30mins = () => setOrderCancelAfter30(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelShow30mins = () => setOrderCancelAfter30(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setInstructionFile] = useState();
  const [loading,setLoading] = useState(false); 
  const [cancelLoading,setcancelLoading] = useState(false); 
  const [clientMsg,setClientMsg] = useState(); // set message onChange
  const [ShowAllComments,setShowAllComments] = useState([]); // editor client comments.
  const [callAPI,setCallAPI] = useState(false);
  const [commentData,setCommentData] = useState(null);
  const [star, setStar] = useState({
    rating: 0,
    totalStars: 5,
    sharpness: 2.5,
    width: 50,
    height: 50,
    outlined: true,
    outlinedColor: "",
    fullStarColor: "#FFBC00",
    emptyStarColor: "transparent"
  });
  const [rating, setRating] = useState(0);
  const [showMarkCompleteBtn,setShowMarkCompleteBtn] = useState(false);
  const [loadingMarkComplete,setLoadingMarkComplete] = useState(false); 
  const [loadingMarkComplete1,setLoadingMarkComplete1] = useState(false); 
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }
  const [displayMsg,setDisplayMsg] = useState(0);
  
  const [clientFeedback,setClientFeedback] = useState();
  const [oneTimeFeedback,setOneTimeFeedBack] = useState('0');
  const [sendMsgTo,setSendMsgTo] = useState();
  const [clientFeedbackError,setClientFeedbackError] = useState(false); //for client feedback validation
  const [sendMsgToError,setsendMsgToError] = useState(false); //for sendMsgTo validation
  const [showSendOption,setShowSendOption] = useState(); //send option
  const [showCancelReasonTitle,setShowCancelReasonTitle] = useState(); // cancel reason title
  const [showCancelReasonDescription,setShowCancelReasonDescription] = useState(); // cancel reason description

  const [showCancelReasonTitleError,setShowCancelReasonTitleError] = useState(false); // cancel reason title
  const [showCancelReasonDescriptionError,setShowCancelReasonDescriptionError] = useState(false); // cancel reason description
  const [editorData,setEditorData] = useState(); // for CK Editor Data
  const [file,setFile] = useState(); // comment file upload
  const [fileName,setFileName] = useState(); // comment file upload
  const [jobStats,setJobStats] = useState();
  const [apiCall,setAPiCall] = useState(false);
  const [feedbackLoading,setFeedbackLoading] = useState(false);
  const [notificationMsg,setNotificationMsg] = useState();
  const [notificationList,setNotificationList] = useState();

  useEffect(() => {
    $(".completed-list").click(function(){
      $(".OngoingPnl").removeClass("Act");
      $(".CompletedPnl").addClass("Act");
    });
    $(".yet-to-asign-list, .ongoing-list").click(function(){
      $(".CompletedPnl").removeClass("Act");
      $(".OngoingPnl").addClass("Act");
    });
    $(".CloseBtn").click(function(){
      $(".DtlsSectionWrapper").removeClass("Act");
    });
  });
  useEffect(() => {
    jobListByClient();
    getInternalNotification();
    notificationUpdate();
    // fetchAllComments();
    // console.log(fileName) => undefined
}, []);

// useEffect(() => {
//   setTimeout(() => {
//     console.clear();
//     setCallAPI(!callAPI);
//     // jobListByClient();
//     fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id);
//   }, 3000);
//   console.log(callAPI);
// }, [callAPI])

useEffect(() => {
  if(apiCall)
  {
    let timer = setTimeout(() => {
      console.clear();
      setCallAPI(!callAPI);
      // jobListByClient();
      fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
    }, 15000);
    return () => clearTimeout(timer);
    // console.log(callAPI);
  }
// }, [callAPI])
})

// Admin: 0 Editor: 1 Client: 2 Sub Admin/Deputy Admin: 3 Internal Editor: 4
// useEffect(() => {
//   if (data=== null)
//   {
//       navigate("/login");
//       navigate(0);
//   }
//   else if(data?.role === '0' || data?.role === '1' || data?.role === '3' || data?.role === '4')
//   {
//       navigate("/");
//   }
// })
  
// Short User Name
const shortUserName = (user_name) =>{
  var sl_name = user_name?.split(' ');
  var short_first = sl_name[0]?.charAt(0);
  var short_last = sl_name[1]?.charAt(0);
  var short_final = short_first + short_last;
  return short_final;
}
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }

  //Send Option
  const sendOption = (val) =>{
    setSendMsgTo(val);
    setsendMsgToError(false);
  }

  //Contact support team
  const contactTeam = () =>{
    navigate('/contact');
  }

  //single file upload in comments
  const uploadFileHandle = (e) =>{
    if(
      e.currentTarget.files[0].type=='application/msword' ||
      e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||
      e.currentTarget.files[0].type== 'application/pdf' ||
      e.currentTarget.files[0].type== 'image/jpeg' ||
      e.currentTarget.files[0].type=='image/png'
      // e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.presentationml.presentation'||
    )
    {
      console.log(e.target.files[0].type);
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
    else
    {
      NotificationManager.warning('File is not supported.Supported file types are DOC,DOCX,PDF,JPEG,PNG','',3000);
    }
    
  }

  //single file delete in comments
  const deleteFileHandle = () =>
  {    
    setFile('');
    setFileName('');
  }

  // Mark Complete Type one with star rating
  const submitTypeOneMarkComplete = async() =>{
    try
    {
      setLoadingMarkComplete(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/jobstatus?id=${orderDetailsById?.id}&rating=${rating}&job_status=${4}&user_id=${data.id}`)
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowMarkCompleteBtn(false);
          setLoadingMarkComplete(false);
          NotificationManager.success('Mark Completed Successfully..', "", 2000);
          markClose();
          // handleClose();
          $(".DtlsSectionWrapper").removeClass("Act");
          jobListByClient();
        }
      }
    } //close try
    catch (error) 
    {
      setLoadingMarkComplete(false);
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  // Mark Complete Type Two with star rating
  const submitTypeTwoMarkComplete = async() =>{
    // alert(rating)
    // alert(orderDetailsById?.id)
    try
    {
      setLoadingMarkComplete1(true);
      // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumestatus?id=${orderDetailsById?.id}&rating=${rating}&job_status=${4}&user_id=${data.id}`)
      let apiRes = await axios.get(`${API_BASE_URL}job/jobstatus?id=${orderDetailsById?.id}&rating=${rating}&job_status=${4}&user_id=${data.id}`)
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          setShowMarkCompleteBtn(false);
          setLoadingMarkComplete1(false);
          NotificationManager.success('Mark Completed Successfully..', "", 2000);
          markClose();
          // handleClose();
          $(".DtlsSectionWrapper").removeClass("Act");
          jobListByClient();
        }
      }
    } //close try
    catch (error) 
    {
      setLoadingMarkComplete1(false);
      // NotificationManager.error(error.message, "", 2000);
    }
  }

    //get order details by id
    const getOrderDetails= async(job_id) =>{
      try
      {
        setAPiCall(true);
        let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              // console.log(apiRes.data.data);
              setOrderDetailsById(apiRes.data.data[0]);
              console.log(apiRes.data.data[0]);
              setServiceFile(apiRes.data.data[0].service_filename);
              setInstructionFile(apiRes.data.data[0].instruction_filename);
              // setShowSendOption(apiRes.data.data[1].send_option);
              fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id,apiRes.data.data[0].deputy_id);
            }
        }
      } //close try
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }

    //get Resume/Cv order details by id
    const getOrderDetailsResumeCv= async(cv_id) =>{
      try
      {
        setAPiCall(true);
        // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumedetail?id=${cv_id}`)
        let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${cv_id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.data);
            if (apiRes.data.res == true) 
            {
              // console.log(apiRes.data.data);
              setOrderDetailsById(apiRes.data.data[0]);
              setServiceFile(apiRes.data.data[0].service_filename);
              setInstructionFile(apiRes.data.data[0].instruction_filename);
              fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id);
            }
        }
      } //close try
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }

   // All jobs for a specific Client
   const jobListByClient = async () => {
      try
      {
        setDisplayMsg(0);
        let apiRes = await axios.get(`${API_BASE_URL}job/jobdetailbyuser?user_id=${data?.id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              // console.log(apiRes.data.data.length)
              setOrderList(apiRes.data.data);

              // setTimeout(() => {
              //   fetchAllComments(apiRes.data.data[0].user_id,apiRes.data.data[0].editor_id,apiRes.data.data[0].id);
              // }, 1000);

              // setArrayLength
              console.log(apiRes.data.data)
              if(apiRes.data.data.length > 0)
              {
                setDisplayMsg(1);
              }
              else
              {
                setDisplayMsg(0);
              }

            }
        }
      }
      catch (error)
      {
        // NotificationManager.error(error.messaage, "", 2000);
      }
   }

  //file download
  const downloadFile = (item) => {
    saveAs(item);
  }

  //short service-file name
  const shortFileName=(item) =>{
    console.log(item?.slice(62,70));
    let filename = item?.slice(62,70);
    return filename + '...';
    // return item
  }

 //short comment-file name
  const shortCommentFileName = (item) =>
  {
    let splt_filename = item?.split("/");
    // console.log(splt_filename);
    return splt_filename[1];
    // return item;
  }

   //short instruction-file name
   const shortInstFileName=(item) =>{
    console.log(item?.slice(57,65));
    let filename = item?.slice(57,65);
    return filename;
    // return item
  }

  
  //sorting job list
  const sortingJobList = async(filter_id) =>{
    console.log(filter_id)
    if (filter_id == 0)
    {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/joblist`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setOrderList(apiRes.data.data);
            }
        }
      }//close try 
      catch (error)
      {
        // NotificationManager.error(error.messaage, "", 2000);
      }
    }
    else
    {
      try 
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setOrderList(apiRes.data.data);
            }
        }
      } 
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }
  }

    //sorting job list
    const sortingJobListByClientId = async(filter_id) =>{
      console.log(filter_id)
      if (filter_id == 0)
      {
        try
        {
          // https://www.demoupdates.com/updates/elite/api/job/jobdetailbyuser?user_id=62
          let apiRes = await axios.get(`${API_BASE_URL}job/jobdetailbyuser?user_id=${62}`) 
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setOrderList(apiRes.data.data);
              }
          }
        }//close try 
        catch (error)
        {
          // NotificationManager.error(error.message, "", 2000);
        }
      }
      else
      {
        try 
        {
          // https://www.demoupdates.com/updates/elite/api/job/jobliststatusbyuser?job_status=4&user_id=115
          // let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}`)
          let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatusbyuser?job_status=${filter_id}&user_id=${data?.id}`)
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setOrderList(apiRes.data.data);
              }
          }
        } 
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
      }
    }

  // Submit Feedback 
  const onSubmitFeedback = async() =>{
    console.log(clientFeedback)
    console.log(sendMsgTo)
    console.log(orderDetailsById)

    if(orderDetailsById?.service_type == '0')
    {
      service_type = 'Regular Service'
    }
    else if(orderDetailsById?.service_type == '1')
    {
      service_type = 'Expedited Service'
    } 

    var full_name = data.first_name + ' ' + data.last_name;
    
    console.log(job_title)

    // return
    if((clientFeedback=== '' && sendMsgTo=== '') || (clientFeedback=== undefined && sendMsgTo=== undefined) || (clientFeedback=== null && sendMsgTo=== null))
    {
      setClientFeedbackError(true);
      setsendMsgToError(true);
      NotificationManager.error('All fields are Blank!!', '',2000);
    }
    else if(clientFeedback=== ''  || clientFeedback=== undefined  || clientFeedback=== null )
    {
      setClientFeedbackError(true);
      NotificationManager.error('Blank field is not allowed!!', '',2000);
    }
    else if(sendMsgTo=== '' || sendMsgTo=== undefined ||  sendMsgTo=== null)
    {
      setsendMsgToError(true);
      NotificationManager.error('Blank field is not allowed!!', '',2000);
    }
    else
    {
      let job_type = '';
      if(orderDetailsById?.job_type==='type2')
      {
        job_type='type2';
        // #7 | CV | Number of Pages 40 | Regular Service [24hrs]
        var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week'
        // #{jobInfoById?.id} | {jobInfoById?.service_name} | Number of Pages {jobInfoById?.number_of_pages} | {jobInfoById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
      }
      else if(orderDetailsById?.job_type==='type1')
      {
        job_type='type1';
        // var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + orderDetailsById?.from_count +' '+'-' + orderDetailsById?.to_count +' ' + '|' + ' ' + service_type + ' ' + '[' + orderDetailsById?.service_hour +'hrs' + ']'
        var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + orderDetailsById?.total_wordcount +' ' + '|' + ' ' + service_type + ' ' + '|' + orderDetailsById?.service_hour + ' '  +'hrs'
        // 
        // #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.total_wordcount} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {jobInfoById?.service_hour}hrs
      }
      setClientFeedbackError(false);
      setsendMsgToError(false);
      var service_type='';
      // console.log('clientFeedback' + clientFeedback);
      // console.log('sendMsgTo' + sendMsgTo);
      // console.log(data);
      // console.log(orderDetailsById)

     
      try 
      {
        setFeedbackLoading(true);
        let apiRes = await(
            axios
            .post(
              `${API_BASE_URL}feedback`,{
                user_id: data?.id,
                user_name: full_name,
                editor_id: orderDetailsById?.editor_id,
                editor_name: orderDetailsById?.editor_name,
                editor_email: orderDetailsById?.editor_email,
                job_id: orderDetailsById?.id,
                job_title: job_title,
                comment: clientFeedback,
                send_option: sendMsgTo && sendMsgTo ==='Send-to-all'? '0':'1',
                job_type: job_type,
                // feedback_send: 0 
              },
            )
          )
        if(apiRes)
        { 
            console.log(apiRes.data.res)
            if(apiRes.data.res == true) 
            {
                NotificationManager.success('Feedback Successfully Send','',2000);
                setClientFeedback('');
                setOneTimeFeedBack('1');
                setSendMsgTo('');
                handleClose();
                setFeedbackLoading(false);
            }else
            {
                setFeedbackLoading(false);
                NotificationManager.error(apiRes.data.msg, '',2000);
            }
        }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      } 
    }
  }

  //Order Cancellation
  const orderCancellation = async() =>{

    if((showCancelReasonTitle=== '' && showCancelReasonDescription=== '') || (showCancelReasonTitle=== undefined && showCancelReasonDescription=== undefined) || (showCancelReasonTitle=== null && showCancelReasonDescription=== null))
    {
      setShowCancelReasonTitleError(true);
      setShowCancelReasonDescriptionError(true);
      NotificationManager.error('All fields are Blank!!', '',2000);
    }
    else if(showCancelReasonTitle=== ''  || showCancelReasonTitle=== undefined  || showCancelReasonTitle=== null )
    {
      setShowCancelReasonTitleError(true);
      NotificationManager.error('Blank field is not allowed!!', '',2000);
    }
    else if(showCancelReasonDescription=== ''  || showCancelReasonDescription=== undefined  || showCancelReasonDescription=== null )
    {
      setShowCancelReasonDescriptionError(true);
      NotificationManager.error('Blank field is not allowed!!', '',2000);
    }
    else
    {
      
      let service_type= '';
      var job_title = '';
      let job_type = '';
  
      if(orderDetailsById?.service_type == '0')
      {
        service_type = 'Regular Service'
      }
      else if(orderDetailsById?.service_type == '1')
      {
        service_type = 'Expedited Service'
      } 
  
      if(orderDetailsById?.job_type==='type2')
      {
        job_type='type2';
        var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + '|' + '1 Week'
      }
      else if(orderDetailsById?.job_type==='type1')
      {
        job_type='type1';
        var job_title = '#' + ' ' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + orderDetailsById?.from_count +' '+'-' + orderDetailsById?.to_count +' ' + '|' + ' ' + service_type + ' ' + '|' + orderDetailsById?.service_hour +'hrs'
      }
      try
      {
          // setLoading(true);
          let full_name = data?.first_name + ' ' + data?.last_name
          setcancelLoading(true);
          let apiRes = await(
              axios
              .post(
                `${API_BASE_URL}job/jobcancel`,{
                  job_id: orderDetailsById?.id,
                  job_title: job_title,
                  job_type: orderDetailsById?.job_type,
                  user_id:data?.id,
                  user_name: full_name,
                  user_mail: data?.email_address,
                  cancellation_title: showCancelReasonTitle && showCancelReasonTitle,
                  cancellation_description: showCancelReasonDescription && showCancelReasonDescription
                },
              )
          )
          if(apiRes)
          {
              console.log(apiRes.data.res)
              if(apiRes.data.res == true) 
              {
                  NotificationManager.success('Order Cancelled Successfully','',2000);
                  orderCancelFormClose();
                  orderCancelClose();
                  $(".DtlsSectionWrapper").removeClass("Act");
                  jobListByClient();
                  setcancelLoading(false);
              }
              else
              {
                NotificationManager.error('Order Not Cancelled', '',2000);
              } 
          }
          else
          {
            // setLoading(false);
            setcancelLoading(false);
            // NotificationManager.error('error3', '',2000);
          }
      } //close try
      catch (error) 
      {
        setcancelLoading(false);
        // NotificationManager.error(error.messaage, '',2000);
      }
    }
  }
  
  //fetch all comments Editor-Client
  const fetchAllComments = async(user_id,editor_id,job_id,internal_id,deputy_id) =>{
    try 
    {
      let intern_id= '';
      let dep_id = '';
      setLoading(true);
      if(internal_id === null && deputy_id === null)
      {
        intern_id = '';
        dep_id = '';
      }
      else
      {
        intern_id = internal_id;
        dep_id = '';
      }
      // let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}`)
      let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}&internal_id=${intern_id}&deputy_id=${dep_id}`)
        if(apiRes)
        { 
          console.log(apiRes.data.res)
          if(apiRes.data.res == true) 
          {
            setShowAllComments(apiRes.data.data);
            console.log(apiRes.data.data);
          }
          else
          {
            // NotificationManager.warning(apiRes.data.msg, '',2000);
          }
        }
    }
    catch (error) 
    {
      // NotificationManager.error(error.message, '',2000);
    }
 }

  //Client-Editor Comment Section
  const commentSection = async(editor) =>{
    // console.log(file)
    // alert('working')
    // http://www.demoupdates.com/updates/elite/api/comment/commentdetailbyusereditor?user_id=62&editor_id=115
    // editorRef?.current?.setData('');
    // setCommentData(null);
    // var Editor1 = CKEditor.GetInstance('comment-textarea');
    if((clientMsg === '' || clientMsg == null || clientMsg == undefined) && (fileName === '' || fileName == null || fileName == undefined))
    {
        console.log(clientMsg);
        NotificationManager.warning('Please write something..','',2000);
    }
    else
    {
      var job_title='';
      var service_type ='';
      setClientMsg('');
      try 
      {
        if(orderDetailsById?.service_type == '0')
        {
          service_type = 'Regular Service'
        }
        else if(orderDetailsById?.service_type == '1')
        {
          service_type = 'Expedited Service'
        }

        if(orderDetailsById?.job_type ==='type2')
        {
          job_title = '#' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week'
        }
        else if(orderDetailsById?.job_type ==='type1')
        {
          job_title = '#' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + ' ' + orderDetailsById?.total_wordcount +' ' + '|' + ' ' + service_type + ' ' + '|' + ' ' + ' ' +orderDetailsById?.service_hour + ' '  +'hrs'
        }

        // alert(orderDetailsById?.internal_id);
        setLoading(true);
        const formData = new FormData();

        formData.append('user_id',orderDetailsById?.user_id);
        formData.append('jobtitle',job_title)
        formData.append('editor_id',orderDetailsById?.editor_id? orderDetailsById?.editor_id : '');
        formData.append('admin_id','5');
        formData.append('internal_id',orderDetailsById?.internal_id? orderDetailsById?.internal_id : '');
        formData.append('deputy_id',orderDetailsById?.deputy_id ? orderDetailsById?.deputy_id : '');
        formData.append('job_id',orderDetailsById?.id);

        formData.append('editor_designation',orderDetailsById?.editor_designation? orderDetailsById?.editor_designation : '');
        formData.append('internal_designation',orderDetailsById?.internal_designation? orderDetailsById?.internal_designation : '');
        formData.append('admin_designation','Admin');
        formData.append('deputy_designation',orderDetailsById?.deputy_designation ? orderDetailsById?.deputy_designation : '');

        formData.append('client_comment',clientMsg);
        formData.append('editor_comment','');
        formData.append('admin_comment','');
        formData.append('internal_comment','');
        formData.append('deputy_comment','');
        formData.append('client_file',file);
        formData.append('editor_file','');
        formData.append('admin_file','');
        formData.append('internal_file','');
        formData.append('deputy_file','');

        const config = {     
          headers: { 'content-type': 'multipart/form-data' }
        }
        
        let apiRes = await axios.post(`${API_BASE_URL}comment`,formData,config) 
         
        if(apiRes)
        { 
          setClientMsg('');
          setFile('');
          setFileName('');
          formData.append('comment_id',apiRes.data.comment_id);
          fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
          // setTimeout(() => {
          //   apiRes1 = axios.post(`${API_BASE_URL}comment/chatmail`,formData,config) 
          // }, 12000);
          let apiRes1 = await axios.post(`${API_BASE_URL}comment/chatmail`,formData,config) 
            // console.log(apiRes.data.res)
            if(apiRes1.data.res == true) 
            {
              // setClientMsg('');
              // setFile('');
              // setFileName('');
              // editorRef?.current?.setData('');
              fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
              CKEditor.instances['editor1'].setData('');
            }
            else
            {
              // NotificationManager.error(apiRes.data.msg, '',2000);
            }
        }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000); 
      }
    }
  }

  //get Notification
  const getInternalNotification= async() =>{
    try
    {
        let apiRes = await axios.post(`${API_BASE_URL}job/getNotification`, {
          user_id: data?.id,
          UserTimeZone: timeZone
        });
        if (apiRes) 
        {
            console.log(apiRes.data.data);
            if (apiRes.data.res == true) 
            {
                setNotificationMsg(apiRes.data.NotificationUnred);
                setNotificationList(apiRes.data.data);

            }
        }
    } //close try
    catch (error) 
    {
        // NotificationManager.error(error.message, "", 2000);
    }
  }

  //get Notification
  const notificationUpdate= async() =>{
    try
    {
        let apiRes = await axios.post(`${API_BASE_URL}job/NotificationStatusUpdate`, {
          user_id: data?.id,
        });
        if (apiRes) 
        {
            console.log(apiRes.data.data);
            if (apiRes.data.res == true) 
            {
                setNotificationMsg(apiRes.data.NotificationUnred);
                getInternalNotification();
            }
        }
    } //close try
    catch (error) 
    {
        // NotificationManager.error(error.message, "", 2000);
    }
  }

  //clear Notification
  const clearNotification= async() =>{
      try
      {
          let apiRes = await axios.post(`${API_BASE_URL}job/deleteNotificationByUserId`, {
            user_id: data?.id,
          });
          if (apiRes) 
          {
              console.log(apiRes.data.data);
              if (apiRes.data.res == true) 
              {
                  setNotificationList();
                  getInternalNotification();
              }
          }
      } //close try
      catch (error) 
      {
          // NotificationManager.error(error.message, "", 2000);
      }
  }

  //Notification delete
  const deleteNotification= async(notification_id) =>{
      if (window.confirm("Are you sure you want to delete?")){ 
        try
        {
            let apiRes = await axios.post(`${API_BASE_URL}job/deleteNotification`, {
              id: notification_id,
              user_id: data?.id
            });
            if (apiRes) 
            {
                console.log(apiRes.data.data);
                if (apiRes.data.res == true) 
                {
                    // setNotificationMsg(apiRes.data.NotificationUnred);
                    setNotificationList(apiRes.data.data);
                    NotificationManager.success(apiRes.data.msg, '',2000);
                }
            }
        }
        catch (error) 
        {
            // NotificationManager.error(error.message, "", 2000);
        }
      }
  }

  return (
    <div>
      <InternalEditorHeader unreadMsg = {notificationMsg}/>
      <InternalEditorSidebar/>
      <main id="main" className="main">
        <div className="pagetitle rightBtnTitle">
          <span className="TitleWrap">
            <h2>Notifications</h2>
          </span>
          {(notificationList && notificationList.length) > 0 && (
            <button className="btn btn-primary CmnBtn" onClick={()=>clearNotification()}>Clear All</button>
          )} 
        </div>
        <section className="section OdrDtlsPnl notificationTablePnl">
          <table width="100%" className="CmnTbl notification-table table-striped blue-header-table">
            <tbody>
              {(notificationList && notificationList.length) > 0 ?
                notificationList && notificationList.map((item,index)=>{
                  return(
                    <tr>
                        {/* <td><strong>{item?.SenderName}</strong> &nbsp; {item?.Message}</td> */}
                        <td>{item?.Message}</td>
                        <td>
                          <span className="GreenTxt">{get_date_format(item?.created_at)}  {get_time_format(item?.created_at)}</span>
                        </td>
                        <td onClick={()=>deleteNotification(item?.id)}><i class="fa fa-trash-o" aria-hidden="true"></i></td>
                    </tr>
                  )
              }):(
                <>You don't have any notification at this time.</>
              )}
            </tbody>
          </table>
        </section>
      </main>
      <Modal show={markComplete} onHide={markClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Rate your experience and quality of work</p>
          <p> 
            <Rating
              onClick={handleRating}
              // onPointerEnter={onPointerEnter}
              // onPointerLeave={onPointerLeave}
              // onPointerMove={onPointerMove}
              /* Available Props */
            />
          </p>
          {/* <p><img src={feedbackStarImg} alt="" /></p> */}
          {/* <p><DynamicStar rating={5} /></p> */}

          {/* <p onChange={(e)=>getRating(e)}> */}
            {/* <DynamicStar
              rating={parseFloat(star.rating)}
              width={parseFloat(star.width)}
              height={parseFloat(star.height)}
              outlined={star.outlinedColor ? star.outlinedColor : star.outlined}
              totalStars={star.totalStars}
              sharpnessStar={star.sharpness}
              fullStarColor={star.fullStarColor}
              emptyStarColor={star.emptyStarColor}
              
            /> */}
          {/* </p> */}

          {/* <div>
            <DynamicStar rating={5} />
          </div> */}
          {/* <a class="CmnBtn"><i class="bi bi-check-circle"></i> Mark Complete</a> */}
          {orderDetailsById?.job_type === "type2" ?(
              <>
                  {showMarkCompleteBtn && showMarkCompleteBtn ? (
                      <button className="CmnBtn" disabled><i class="bi bi-check-circle"></i> Mark Complete</button>
                  ):(
                    <>
                        { loadingMarkComplete1 &&  loadingMarkComplete1 ?(
                            <Button className="CmnBtn" onClick={()=>submitTypeTwoMarkComplete()} disabled><i class="fa fa-refresh fa-spin"></i>Mark Complete</Button>
                        ):(
                            <Button className="CmnBtn" onClick={()=>submitTypeTwoMarkComplete()}><i class="bi bi-check-circle"></i> Mark Complete</Button>
                        )}
                    </>
                      // <button className="CmnBtn" onClick={()=>submitTypeTwoMarkComplete()}><i class="bi bi-check-circle"></i> Mark Complete</button>
                  )}
              </>
          ):(
            <>
              {orderDetailsById?.job_type === "type1" ? (
                <>
                   {showMarkCompleteBtn && showMarkCompleteBtn ? (
                       <button className="CmnBtn" disabled><i class="bi bi-check-circle"></i> Mark Complete</button>
                   ):(
                      <>
                        {loadingMarkComplete &&  loadingMarkComplete ?(
                          <Button className="CmnBtn" onClick={()=>submitTypeOneMarkComplete()} disabled><i class="fa fa-refresh fa-spin"></i>Mark Complete</Button>
                        ):(
                          <Button className="CmnBtn" onClick={()=>submitTypeOneMarkComplete()}><i class="bi bi-check-circle"></i> Mark Complete</Button>
                        )}
                      </>
                       
                   )}
               </>
              ):(
                  <></>
              )}
            </>
          )}
         
          
      </Modal.Body>
      </Modal>
      <Modal show={show} onHide={()=>{setClientFeedbackError(false);setSendMsgTo(false);handleClose()}} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <h2>Customer Feedback</h2> */}
          <h2>Client Feedback</h2>
          {/* <p>Give some feedback about your experience with our service to the admin</p> */}
          <p>Give some feedback about your experience with our service to better serve you.</p>
          
          <textarea cols="" rows="" placeholder="Type here..." name="feedback" onChange={(e)=>setClientFeedback(e.target.value)} value={clientFeedback}></textarea>
          {clientFeedbackError && (clientFeedback=== '' || clientFeedback=== undefined || clientFeedback=== null) && (<div className='error error-feedback'> Feedback is required</div>)}
          <label className="radio inline">
            <input Checked name="sendTo" type="radio" value="Send-to-all"  onChange={(e)=>sendOption(e.target.value)}/>
            <span>Send to all</span>
          </label>
          <label className="radio inline">
            <input name="sendTo" type="radio" value="Send-to-only-admin" onChange={(e)=>sendOption(e.target.value)}/>
            <span>Send to only admin</span>
          </label>
          {feedbackLoading && feedbackLoading ? (
              <button class="CmnBtn" disabled><i class="bi bi-check-circle"></i> Send Feedback</button>
            ):(
              <button class="CmnBtn" onClick={()=> onSubmitFeedback()}><i class="bi bi-check-circle"></i> Send Feedback</button>
              )
          }
          {sendMsgToError && (sendMsgTo=== '' || sendMsgTo=== undefined || sendMsgTo=== null) && (<div className='error error-bottom-feedback'> Send option is required</div>)}
        </Modal.Body>
      </Modal>
      
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>

      <Modal show={orderCancelAfter30} onHide={orderCancelCloseAfter30mins} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancelation</h2>
          <p>Contact the support team to cancel your order at <br/><a href="#">sales@elitereviser.com</a></p>
          <a class="CmnBtn yesBtn" onClick={()=>orderCancelCloseAfter30mins()}>YES</a>
          {/* <a class="cancelBtn noBtn">NO</a> */}
        </Modal.Body>
      </Modal>

      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrderForm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p>
          
          <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" name="cancellation_title" onChange={(e)=>setShowCancelReasonTitle(e.target.value)} value={showCancelReasonTitle}/>
          {showCancelReasonTitleError && (showCancelReasonTitle=== '' || showCancelReasonTitle=== undefined || showCancelReasonTitle=== null) && (<div className='error'>Reason is required</div>)}
          
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..." name="cancellation_description" onChange={(e)=>setShowCancelReasonDescription(e.target.value)} value={showCancelReasonDescription}></textarea>
          {showCancelReasonDescriptionError && (showCancelReasonDescription=== '' || showCancelReasonDescription=== undefined || showCancelReasonDescription=== null) && (<div className='error'> Reason Description is required</div>)}
          {/* <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a> */}
          

          {cancelLoading && cancelLoading ? (
              <Button class="CmnBtn" disabled><i class="fa fa-refresh fa-spin"></i>Send Request Order Cancellation</Button>
            ):(
              <Button class="CmnBtn" onClick={()=>orderCancellation()}><i class="bi bi-check-circle"></i> Send Request Order Cancellation</Button>
            )
          }
          
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InternalEditorNotification;
