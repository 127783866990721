import React, {Component, useState, useEffect} from "react";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Clientheader from "../../Components/ClientComponents/Clientheader";
import Clientsidebar from "../../Components/ClientComponents/Clientsidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications';

const Settings = () => {
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  const [isSelectedOrderPickup,setIsSelectedOrderPickup] = useState();
  const [isSelectedOrderComplete,setIsSelectedOrderComplete] = useState();
  const [isSelectedOrderEmail,setIsSelectedOrderEmail] = useState();
  const [isSelectedOrderUpdate,setIsSelectedOrderUpdate] = useState();

  const orderSelected = async() =>{
    try 
    {
        let apiRes = await axios.get(`${API_BASE_URL}account/settingstatus?id=${data?.id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setIsSelectedOrderPickup(apiRes.data.data.pickup);
              setIsSelectedOrderComplete(apiRes.data.data.complete);
              setIsSelectedOrderEmail(apiRes.data.data.EmailNotification);
              setIsSelectedOrderUpdate(apiRes.data.data.UpdateOrders);
            }
            else
            {
                // NotificationManager.error(apiRes.data.msg, "", 2000);
                NotificationManager.success('You will not be notified', "", 2000);
            }
        }
    }//close try
    catch (error)
    {
        
        // NotificationManager.error(error.message, "", 2000);
    }
  }

 const orderpickupStatus = async(e) =>{
    try 
    {
        let apiRes = await axios.get(`${API_BASE_URL}account/orderpickupstatus?id=${data?.id}&orderpickupstatus=${e.target.checked ? 1 : 0}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                NotificationManager.success(apiRes.data.msg, "", 2000);
                // NotificationManager.success('You will be notified', "", 2000);
                console.log(apiRes.data.data)
                orderSelected();
            }
            else
            {
                NotificationManager.error(apiRes.data.msg, "", 2000);
            }
        }
    }//close try
    catch (error)
    {
        
        // NotificationManager.error(error.message, "", 2000);
    }
 }

 const orderCompleteStatus = async(e) =>{
  try 
  {
      let apiRes = await axios.get(`${API_BASE_URL}account/jobcompletestatus?id=${data?.id}&jobcompletestatus=${e.target.checked ? 1 : 0}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
              NotificationManager.success(apiRes.data.msg, "", 2000);
              // NotificationManager.success('You will be notified.', "", 2000);
              console.log(apiRes.data.data);
              orderSelected();
          }
          else
          {
              NotificationManager.error(apiRes.data.msg, "", 2000);
              // NotificationManager.success('You will not be notified', "", 2000);
          }
      }
  }//close try
  catch (error)
  {
      
      // NotificationManager.error(error.message, "", 2000);
  }
}

const OrderEmailNotificationStatus = async(e) =>{
  try 
  {
      let apiRes = await axios.get(`${API_BASE_URL}account/EmailNotification?id=${data?.id}&EmailNotification=${e.target.checked ? 1 : 0}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
              NotificationManager.success(apiRes.data.msg, "", 2000);
              // NotificationManager.success('You will be notified.', "", 2000);
              console.log(apiRes.data.data);
              orderSelected();
          }
          else
          {
              NotificationManager.success(apiRes.data.msg, "", 2000);
          }
      }
  }//close try
  catch (error)
  {
      
      // NotificationManager.error(error.message, "", 2000);
  }
}

const orderUpdateStatus = async(e) =>{
  try 
  {
      let apiRes = await axios.get(`${API_BASE_URL}account/UpdateOrders?id=${data?.id}&UpdateOrders=${e.target.checked ? 1 : 0}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
              NotificationManager.success(apiRes.data.msg, "", 2000);
              // NotificationManager.success('You will be notified.', "", 2000);
              console.log(apiRes.data.data);
              orderSelected();
          }
          else
          {
              NotificationManager.success(apiRes.data.msg, "", 2000);
          }
      }
  }//close try
  catch (error)
  {
      
      // NotificationManager.error(error.message, "", 2000);
  }
}

useEffect(() => 
{
  orderSelected();
}, []);

  return (
    <div>
      <Clientheader />
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            {/* <span className="NameLogo">sw</span> */}
            <h2>Settings</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
          <div className="profile_tab_content client-settings">
            <ul className="noti_list_main row">
              <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="noti_list row">
                    <li className="col-lg-7 col-8 noti_text">
                      <h4>Order Pickup</h4>
                      <p>You will be notified when an editor picks up your order.</p>
                    </li>
                    <li className="col-lg-5 col-4 noti_toggle">
                    <div class="form-check form-switch">
                        {/* <input className="form-check-input" type="checkbox" checked role="switch" /> */}
                        {/* {isSelectedOrderPickup && isSelectedOrderPickup === '1' ? (
                          <input className="form-check-input" type="checkbox" checked role="switch" onChange={(e)=>orderpickupStatus(e)}/>
                        ):( */}
                          <input className="form-check-input" type="checkbox" checked={isSelectedOrderPickup == '1'? true:false } role="switch" onChange={(e)=>orderpickupStatus(e)}/>
                        {/* )} */}
                       
                    </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="noti_list row">
                    <li className="col-lg-7 col-8 noti_text">
                      <h4>Order Completion</h4>
                      <p>You will be notified when your order is completed.</p>
                    </li>
                    <li className="col-lg-5 col-4 noti_toggle">
                    <div class="form-check form-switch">
                        {/* <input className="form-check-input" type="checkbox" checked role="switch" /> */}
                        {/* {isSelectedOrderComplete && isSelectedOrderComplete === '1' ? (
                         <input className="form-check-input" type="checkbox" checked role="switch" onChange={(e)=>orderCompleteStatus(e)}/>
                        ):( */}
                          <input className="form-check-input" type="checkbox" checked={isSelectedOrderComplete == '1' ? true: false} role="switch" onChange={(e)=>orderCompleteStatus(e)}/>
                        {/* )} */}
                    
                    </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="noti_list row">
                    <li className="col-lg-7 col-8 noti_text">
                      <h4>Email Notification</h4>
                      <p>Turn on email notification to get updates through email.</p>
                    </li>
                    <li className="col-lg-5 col-4 noti_toggle">
                    <div class="form-check form-switch">
                        {/* <input className="form-check-input" type="checkbox" role="switch" /> */}
                        <input className="form-check-input" type="checkbox" checked={isSelectedOrderEmail == '1' ? true: false} role="switch" onChange={(e)=>OrderEmailNotificationStatus(e)}/>
                    </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="col-lg-12">
                <div className="gray_noti_box">
                  <ul className="noti_list row">
                    <li className="col-lg-7 col-8 noti_text">
                      <h4>Update</h4>
                      <p>You will be notified when an update is made to your order.</p>
                    </li>
                    <li className="col-lg-5 col-4 noti_toggle">
                    <div class="form-check form-switch">
                        {/* <input className="form-check-input" type="checkbox" role="switch" /> */}
                        <input className="form-check-input" type="checkbox" checked={isSelectedOrderUpdate == '1' ? true: false} role="switch" onChange={(e)=>orderUpdateStatus(e)}/>
                    </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            {/* <div className="noti_top">
              <p>Enable the "Remember me" option:</p>
              <div>
                <label className="radio inline">
                  <input defaultChecked name="rember-me" defaultValue type="radio" />
                  <span>Yes</span>
                </label>
                <label className="radio inline">
                  <input name="rember-me" defaultValue type="radio" />
                  <span>No</span>
                </label>
              </div>
            </div>
            <div className="noti_top">
              <p>Enable lost password form:</p>
              <div>
                <label className="radio inline">
                  <input defaultChecked name="lost-password" defaultValue type="radio" />
                  <span>Yes</span>
                </label>
                <label className="radio inline">
                  <input name="lost-password" defaultValue type="radio" />
                  <span>No</span>
                </label>
              </div>
            </div> */}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Settings;
