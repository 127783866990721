import React, {Component, useState, useEffect} from "react";
import { NavLink, useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { Rating } from 'react-simple-star-rating';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import InternalEditorHeader from "./InternalEditorHeader";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { get_date_format, lastLoginformatDate } from '../../utility/Common';
import {NotificationManager} from 'react-notifications'; 
import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const userImg = require("../../assets/img/user-img.png");

const InternalEditorDashboard = () => {
  const [arrData,setArrData] = useState([]);
  const [userData, setUserData] = useState({
    type: 'line',
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        // label: 'Dataset 1',
        label: 'Completed Jobs',
        // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
        data: arrData,
        borderColor: 'rgb(11, 14, 120)',
        backgroundColor: 'rgb(11, 14, 120)',
        // borderWidth: 2,
        // pointRadius: 5,
      },
    ]
  }) 
  const [showDataByInternal,setShowDataByInternal] = useState(); //Admin data
  const [showShortName,setShowShortName] = useState(); // short name 
  const navigate = useNavigate();
  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  const [allOngoing,setAllOngoing] = useState(); // count all ongoing
  const [allComplete,setAllComplete] = useState(); // count all complete
  console.log(loggedUser) 

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
    },
    scales: {
      x: {
          grid: {
            min: 0,
            max: 70,
            grace: '5%',
          },
          title: {
            display: true,
            text: 'Month',
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          },
          ticks: {
            color: '#525252',
            // stepSize: 0.5,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
      y: {
        min: 0,
        max: allComplete,
        grace: '5%',
          grid: {
          },
          title: {
            display: true,
            text: 'Number of Jobs',
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          },
          ticks: {
            color: '#525252',
            precision:0,
            // stepSize: 0.5,
            font: {
              size: 13,
              family: "'Poppins', sans-serif",
              weight: 400
            }
          }
      },
    },
  };

  useEffect(() => {
    dashboardDataByInternal();
    completeJobCount();
    // getAllOngoingJobs();
    // getAllCompletedJobs();
  }, []);
  
  useEffect(() => {
    if (loggedUser=== null)
    {
        navigate("/login");
        navigate(0);
    }
    else if(loggedUser?.role == '1' || loggedUser?.role == '2')
    {
        navigate("/login");
    }
  })

  //fetch current month name
  const currentMonth = (month) =>{
    switch(month) {
      case '01':
        return 'JANUARY';
      case '02':
        return 'FEBRUARY';
      case '03':
        return 'MARCH';
      case '04':
        return 'APRIL';
      case '05':
        return 'MAY';
      case '06':
        return 'JUNE';
      case '07':
        return 'JULY';
      case '08':
        return 'AUGUST';
      case '09':
        return 'SEPTEMBER';
      case '10':
        return 'OCTOBER';
      case '11':
        return 'NOVEMBER';
      case '12':
        return 'DECEMBER';
      default:
        return '';
    }
  }

  //Complete Job count per month
  const completeJobCount = async() =>{
    try 
    {
      // https://www.demoupdates.com/updates/elite/api/job/numberofjobspermonth?editor_id=132&all_id=editor_id
      let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobspermonth?id=${loggedUser?.id}&all_id=internal_id`)
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          setArrData(apiRes.data.data);
          console.log(apiRes.data.data);


          setUserData({
            type: 'line',
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [
              {
                // label: 'Dataset 1',
                label: 'Completed Jobs',
                // data:[10, 30, 20, 50, 60, 40, 50, 40, 80, 60, 70, 30, 0, 100],
                data: apiRes.data.data,
                borderColor: 'rgb(11, 14, 120)',
                backgroundColor: 'rgb(11, 14, 120)',
                borderWidth: 2,
                pointRadius: 5,
              },
            ]
          }) 

        }
      }
    } 
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }


  const dashboardDataByInternal = async() =>{
    // try 
    // {
    //     let apiRes = await axios.get(`${API_BASE_URL}job/numberofalljoblist`)
    //     if (apiRes) 
    //     {
    //         console.log(apiRes.data.res);
    //         if (apiRes.data.res == true) 
    //         {
    //             setShowDataByInternal(apiRes.data.data);
    //             // console.log(apiRes.data.data)
    //         }
    //         else
    //         {
    //             NotificationManager.error(apiRes.data.msg, "", 2000);
    //         }
    //     }
    // }//close try
    // catch (error)
    // {
    //     NotificationManager.error(error.message, "", 2000);
    // }
    try 
    {
        let apiRes = await axios.get(`${API_BASE_URL}job/numberofjobsByInternalId?internal_id=${loggedUser?.id}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                let split_val = apiRes.data.data.editorname.split(' ');
                let fname = split_val[0].charAt(0);
                let lname = split_val[1].charAt(0);
                let full_name = fname + lname;
                setShowDataByInternal(apiRes.data.data);
                setShowShortName(full_name);
                setAllOngoing(apiRes.data.data.ongoing);
                setAllComplete(apiRes.data.data.complete);
            }
            else
            {
                NotificationManager.error(apiRes.data.msg, "", 2000);
            }
        }
    }//close try
    catch (error)
    {
        
        // NotificationManager.error(error.message, "", 2000);
    }
 }


    //redirect component
    const redirectComponent = (section) => {
      switch(section) {
        case 1:
          navigate("/internal-completed-job");
          break;
        case 2:
          navigate("/internal-editor-assigned-jobs");
          break;
        case 3:
          navigate("/internal-on-going-job");
          break;
        default:
          return 'false';
      }
    }

  return (
    <div>
      <InternalEditorHeader/>
      <InternalEditorSidebar/>
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
          {/* <span className="editorNameLogo">{showShortName}</span> */}
            <h2>Dashboard</h2>
          </span>
        </div>
        <section className="section dasboardPnl">
          <div className="row">
              {/* <div className="col-md-4">
              <div className="card dashboard-card dash-card-1 dash-board-card-box-2">
                <div className="border-box">
                  <h4>Total Word Count</h4>
                  <h5>{currentMonth(showDataByInternal?.currentMonth)}</h5>
                </div>
                <h3>{showDataByInternal?.totalWordcount}</h3>
              </div>
            </div> */}
             <div className="col-md-4" onClick={()=>redirectComponent(1)}>
              <div className="card dashboard-card word-count-card dash-board-card-box-2">
                <div className="border-box">
                  <h4>Total Word Count</h4>
                  <h5>{currentMonth(showDataByInternal?.currentMonth)}</h5>
                  {/* <h3>50,000</h3> */}
                </div>
                <h3>{showDataByInternal?.totalWordcount}</h3>
              </div>
            </div>
            <div className="col-md-4" onClick={()=>redirectComponent(2)}>
              <div className="card dashboard-card dash-card-2 dash-board-card-box-2">
                <div className="border-box">
                  {/* <h4>Unassigned Jobs</h4> */}
                  <h4>Assigned Jobs</h4>
                  <h5>{currentMonth(showDataByInternal?.currentMonth)}</h5>
                </div>
                {/* <h3>{showDataByInternal?.notassigned}</h3> */}
                <h3>{showDataByInternal?.assigned}</h3>
                {/* <h3>0</h3> */}
              </div>
            </div>
            <div className="col-md-4" onClick={()=>redirectComponent(3)}>
              {/* <div className="card dashboard-card dash-card-3 dash-board-card-box-2">
                <div className="border-box">
                  <h4>Ongoing Jobs</h4>
                  <h5>{currentMonth(showDataByInternal?.currentMonth)}</h5>
                </div>
                <h3>{showDataByInternal?.ongoing}</h3>
              </div> */}
              <div className="card dashboard-card ongoing-jobs-card dash-board-card-box-2"> 
                <div className="border-box">
                    <h4>Ongoing Jobs</h4>
                    <h5>{currentMonth(showDataByInternal?.currentMonth)}</h5>
                </div>
                <h3>{showDataByInternal?.ongoing}</h3>
              </div>
            </div>
            {/* <div className="card dashboard-card ongoing-jobs-card dash-board-card-box-2" onClick={()=>redirectComponent(2)}> 
              <div className="border-box">
                  <h4>Ongoing Jobs</h4>
                  <h5>{currentMonth(showDataByInternal?.currentMonth)}</h5>
              </div>
              <h3>{showDataByInternal?.ongoing}</h3>
            </div> */}
            
            <div className="col-md-8">
              <div className="card dashboard-card2 chart-card">
                <h3>Workflow Chart</h3>
                <span className="star-rating-span">
                  <Rating initialValue={showDataByInternal?.rating} readonly/>
                  <span className="rating-text">{showDataByInternal?.rating} out of 5</span>
                </span>
                <Line options={lineOptions} data={userData}  />
              </div>
            </div>
            <div className="col-md-4">
              <div className="card dashboard-card2 user-card">
                <div className="user-top">
                  <div className="row">
                    <div className="col-md-3">
                      <span className="user-img-sec">
                        {showDataByInternal?.editorname?.match(/\b\w/g)?.join('')}
                      </span>
                    </div>
                    <div className="col-md-9 user-text-sec">
                      <h4>{showDataByInternal?.editorname}</h4>
                      <p>Last sign-in: {lastLoginformatDate(showDataByInternal?.LastLogin)}</p>
                    </div>
                  </div>
                </div>
                <ul className="user-details-list">
                  <li>Joining Date: <strong>{get_date_format(showDataByInternal?.joiningDate)}</strong></li>
                  <li>Designation: <strong>Internal Editor</strong></li>
                  <li>Email Address: <strong>{showDataByInternal?.useremail}</strong></li>
                  <li>Address: <strong>{showDataByInternal?.address}</strong></li>
                  <li>Country: <strong>{showDataByInternal?.country}</strong></li>
                </ul>
                <a className="my-account-link">
                  <Link to="/internal-editor-my-account"> Go to My Profile </Link>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default InternalEditorDashboard;
