import React, { Component,useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const Adminmyaccount = () => {
    const navigate = useNavigate();
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    const[adminData,setAdminData] = useState();
    useEffect(() => {
        if (data=== null)
        {
            navigate("/admin-login");
            navigate(0);
        }
        console.log(data);
        // console.log(data.first_name.charAt(0))
        getAdminInfos();
    }, []);
    

    // get admin information
    const getAdminInfos = async () => {
        try {
        let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
            // console.log(apiRes.data.data);
            setAdminData(apiRes.data.data[0]);
            }
        }
        } catch (error) {
        //close try
        // NotificationManager.error(error, "", 2000);
        }
    };

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
        <main id="main" className="main">
            <div className="pagetitle">
                <span className="TitleWrap TitleWrapNonSpce">
                <span className="editorNameLogo">{adminData?.first_name?.charAt(0)}{adminData?.last_name?.charAt(0)}</span>
                    <h2>Change Password</h2>
                </span>
            </div>
            <section className="section AccDtlsPnl">
                {/* <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Personal Details</h3>
                        <Link 
                            to = {`/admin-my-account/admin-edit-account/${adminData?.id}`}
                            className="btn btn-primary CmnBtn"
                        > 
                            <i className="bi bi-pencil" /> Edit Account      
                        </Link>
                    </div>
                    <ul className="AccDtls row">
                        <li className="col-md-3">
                            <label>First Name</label>
                            <p className="InputTxt">{adminData?.first_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Last Name</label>
                            <p className="InputTxt">{adminData?.last_name}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Phone Number</label>
                            <p className="InputTxt">{adminData?.ph_no}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Email Address</label>
                            <p className="InputTxt">{adminData?.email_address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Address</label>
                            <p className="InputTxt">{adminData?.address}</p>
                        </li>
                        <li className="col-md-3">
                            <label>Role</label>
                            <p className="InputTxt">
                              {
                                adminData?.role == '0' ? 'Administrator':
                                adminData?.role == '1' ? 'Editor':
                                adminData?.role == '2' ? 'Client':''
                              }
                            </p>
                        </li>
                        <li className="col-md-3">
                            <label>Organization</label>
                            <p className="InputTxt">{adminData?.organization}</p>
                        </li> 
                        <li className="col-md-3">
                            <label>ZIP Code</label>
                            <p className="InputTxt">{adminData?.zipcode}</p>
                        </li>
                    </ul>
                </div> */}

                <div className="AccBox">
                    <div className="pagetitle">
                        <h3>Password</h3>
                        {/* <NavLink to="/admin-my-account/admin-edit-password" className="btn btn-primary CmnBtn">
                            <i className="bi bi-pencil" /> Edit Password
                        </NavLink> */}
                        <Link 
                            to = {`/admin-my-account/admin-edit-password`}
                            className="btn btn-primary CmnBtn"
                        > 
                            <i className="bi bi-pencil" /> Edit Password      
                        </Link>
                    </div>
                    {/* <ul className="AccDtls row">
                        <li className="col-md-4">
                            <label>Password</label>
                            <p className="InputTxt">Sam@123456</p>
                        </li>
                    </ul> */}
                </div>

            </section>
        </main>
    </div>
  );
};

export default Adminmyaccount;
