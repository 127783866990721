import React from "react";
import ReactDOM from "react-dom/client";
import ScrollToTop from './ScrollToTop';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// "homepage": "https://demoupdates.com/updates/Elite-reviser/dev/",
{/* <BrowserRouter basename="/updates/Elite-reviser/html/"> */}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  // <React.StrictMode></React.StrictMode>
    // <BrowserRouter basename="/updates/Elite-reviser/dev/">
    <BrowserRouter basename="/">
      {/* <BrowserRouter basename=""> */}
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
