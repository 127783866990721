import {
  React, useEffect, useState, useRef,
  useNavigate, Link,
  axios, 
  NotificationManager,
  Button,
  Modal,
  DateTime,
  Rating,
  CKEditor,
  ClassicEditor
} from '../../utility/CommonImport'; // Import 

import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";

import $ from "jquery";
import { API_BASE_URL} from '../../api_url';
import { saveAs} from 'file-saver'
import { get_date_format } from '../../utility/Common';
import CountdownTimer from "../../Timer/CountdownTimer";
import CountUpTimerComponent from '../../Timer-new/CountUpTimerComponent';


const reviewIcon = require("../../assets/img/ReviewIcon.png");
const docFileImg = require("../../assets/img/DocFileImg.png");
const docImg = require("../../assets/img/docs-img.png");
const iconImg = require("../../assets/img/image-icon.png");
const pdfImg = require("../../assets/img/pdf.png");
const rtfImg = require("../../assets/img/rtf-img.png");
const odtImg = require("../../assets/img/odt-img.png");
const pagesImg = require("../../assets/img/pages-img.png");
const pptImg = require("../../assets/img/ppt-img.png");
const xlsxImg = require("../../assets/img/xlsx-img.png");
const gdocImg = require("../../assets/img/gdoc-img.png");
const gslideImg = require("../../assets/img/google-slides.png");
const gsheetImg = require("../../assets/img/gsheet-img.png");
const plainTextImg = require("../../assets/img/txt-img.png");
const LatexImg = require("../../assets/img/text-img.png");
const attachFileIcon = require("../../assets/img/attach-icon.png");
const linkIcon = require("../../assets/img/link-icon.png");
const feedbackStarImg = require("../../assets/img/feedbackStar.png");

const InternalEditorMyJobs = () => {
  const windowRef = useRef();
  const navigate = useNavigate();
  let data= JSON.parse(localStorage.getItem("user_data_elite"));

  const timeZone = DateTime.local().zoneName;

  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [orderDetailsById,setOrderDetailsById] = useState();
  const [orderList,setOrderList] = useState([]);
  const [arrayLength,setArrayLength] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setInstructionFile] = useState();
  const [displayMsg,setDisplayMsg] = useState(0);
  const [counterTimer,setCountTimer] = useState(false);
  const [ShowAllComments,setShowAllComments] = useState([]); // all comments.
  const [internalMsg,setInternalMsg] = useState();
  const [callAPI,setCallAPI] = useState(false);
  const [file,setFile] = useState(); // comment file upload
  const [fileName,setFileName] = useState(); // comment file upload
  const [apiCall,setAPiCall] = useState(false);
  const [notificationMsg,setNotificationMsg] = useState();

  const [loading,setLoading] = useState(false);
  // const [editorSubmitFile,setEditorSubmitFile] = useState(); // single file upload
  // const [editorSubmitFileName,setEditorSubmitFileName] = useState(); // single file upload

  
  const [editorSubmitFiles,setEditorSubmitFiles] = useState([]); // multiple files upload
  const [editorSubmitFileNames,setEditorSubmitFileNames] = useState([]); // multiple files upload

  const [taskFile,setTaskFile] = useState(false);
  const [isWindowOpen, setIsWindowOpen] = useState(false);

  const [taskSubmitModalOpen, setTaskSubmitModalOpen] = useState(false);
  const taskSubmitModalClose = () => setTaskSubmitModalOpen(false);
  const taskSubmitModalShow = () => setTaskSubmitModalOpen(true);

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }

  const openJobDetlsPnl = () => { 
    setIsWindowOpen(true)
    $(".OngoingPnl").addClass("Act");
  };
  const closeJobDetlsPnl = () => { 
    $(".OngoingPnl").removeClass("Act");
  };

  useEffect(() => {
    $(".completed-list").click(function(){
      $(".OngoingPnl").removeClass("Act");
      $(".CompletedPnl").addClass("Act");
    });
    $(".yet-to-asign-list, .ongoing-list").click(function(){
      $(".CompletedPnl").removeClass("Act");
      $(".OngoingPnl").addClass("Act");
    });
    $(".CloseBtn").click(function(){
      $(".DtlsSectionWrapper").removeClass("Act");
    });
  });
  useEffect(() => {
    // jobListByEditor();
    jobListByInternal();
}, []);

useEffect(() => {
  const checkIfClickedOutside = e => {
    if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
    {
      setIsWindowOpen(false)
      closeJobDetlsPnl();
    }
    else
    {
      console.log('here')
      console.log(isWindowOpen)
      console.log(windowRef.current)
    }
  }
  document.addEventListener("mousedown", checkIfClickedOutside)

  return () => {
    document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
  }
}, [isWindowOpen])


// useEffect(()=>{
//   fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id);
// },[callAPI])

// useEffect(() => {
//   if(apiCall && orderDetailsById?.job_status == '3')
//   {
//     let timer = setTimeout(() => {
//       console.clear();
//       setCallAPI(!callAPI);
//       // jobListByClient();
//       fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
//     }, 15000);
//     return () => clearTimeout(timer);
//     // console.log(callAPI);
//   }
// // }, [callAPI])
// })

useEffect(() => {
  if (data=== null)
  {
      navigate("/login");
      navigate(0);
  }
  else if(data!=null && (data?.role == '2' || data?.role == '0'))
  {
    navigate("/");
  }
})

// useEffect(() => {
//   setTimeout(() => {
//     setCallAPI(!callAPI);
//     // jobListByEditor();
//     fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id);
//   }, 3000);
//   console.log(callAPI);
// }, [callAPI])
 
  // https://www.demoupdates.com/updates/elite/api/job/jobdetailbyeditor?editor_id=56

  // Short User Name
  const shortUserName = (user_name) =>{
    console.log(user_name);
    var sl_name = user_name?.split(' ');
    console.log(sl_name)
    var short_first = sl_name[0]?.charAt(0);
    var short_last = sl_name[1]?.charAt(0);
    var short_final = short_first + short_last;
    return short_final;
  }

  //get Notification
  const getInternalNotification= async() =>{
    try
    {
      let apiRes = await axios.post(`${API_BASE_URL}job/getNotification`, {
        user_id: data?.id,
      });
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
            setNotificationMsg(apiRes.data.NotificationUnred);
        }
      }
    }
    catch (error) 
    {
      console.error(error);
    }
  }

  
const timeReachedMessage = () =>{
  return 'Time Reached';
}

  //Time format
  const get_time_format = (tm) => {
    if(tm!= '')
    {
        var time_values = tm.slice(11,16);
        let start_time_split = time_values?.split(":");
        console.log(start_time_split)
        let start_splt_hour = start_time_split[0];
        let start_splt_minutes = start_time_split[1];
        let start_check_dt = start_splt_hour >= 12 ? 'PM' : 'AM';
        start_splt_hour = start_splt_hour % 12;
        start_splt_hour = start_splt_hour ? start_splt_hour : 12; //hour 0 => 12
        start_splt_minutes = start_splt_minutes < 10 ? start_splt_minutes :  start_splt_minutes;
        let start_time_final = start_splt_hour + ':' + start_splt_minutes + ' ' + start_check_dt;
        return start_time_final;
    }
  }

  //get order details by id
  const getOrderDetails= async(job_id) =>{
    try
    {
      setAPiCall(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            // console.log(apiRes.data.data);
            setOrderDetailsById(apiRes.data.data[0]);
            setServiceFile(apiRes.data.data[0].service_filename);
            setInstructionFile(apiRes.data.data[0].instruction_filename);
            // setTimeout(() => {
            //   setCallAPI(!callAPI)
              // console.log('hi')
              fetchAllComments(apiRes.data.data[0].user_id,apiRes.data.data[0].editor_id,apiRes.data.data[0].id,apiRes.data.data[0].internal_id);
            // }, 1000);
          }
      }
    } //close try
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  // All jobs for a specific Client
  const jobListByInternal = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}job/myjobsbyInternal?internal_id=${data?.id}&UserTimeZone=${timeZone}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setOrderList(apiRes.data.data);
        }else{

        }
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }

  //file download
  const downloadFile = (item) => {
    saveAs(item);
  }

  //file-upload
  // const handleEditorFile = async (event) =>{
  //   if(
  //     event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
  //     event.target.files[0].type=='application/msword' ||
  //     event.target.files[0].type=='application/pdf' )
  //   {
  //     setTaskFile(true);
  //     setEditorSubmitFile(event.target.files[0]);
  //     setEditorSubmitFileName(event.target.files[0].name);
  //     event.target.value='';
  //   }
  //   else
  //   {
  //     NotificationManager.error('File type is not matched!', "", 2000);
  //     event.target.value='';
  //   }
  // }

  const handleEditorFiles = (e) => {
    const files = Array.from(e.target.files);
    console.log(files);
    const validFileTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/rtf',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.text-flat-xml',
      'application/vnd.apple.pages',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.google-apps.document',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow',
      'application/vnd.google-apps.spreadsheet',
      'text/plain'
    ];
  
    let temp_arr = [...editorSubmitFiles];
    console.log(temp_arr);
  
    files.forEach((file, index) => {
      if (validFileTypes.includes(file.type) || file.type === '')
      {
        const temp_obj = {
          index: temp_arr.length + index,
          file,
          file_name: file.name
        };
        temp_arr.push(temp_obj);
        console.log(temp_arr)
      }
      else
      {
        NotificationManager.error('File type is not matched!', "", 2000);
        e.target.value = '';
      }
    });
  
    setEditorSubmitFiles(temp_arr);
    setTaskFile(true);
  };

  const deleteFile = (indexToDelete) => {
    const updatedFiles = editorSubmitFiles.filter((item, index) => index !== indexToDelete);
    setEditorSubmitFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setTaskFile(false);
    }
  };

//finished editor task and submitted
// const handleSubmittedTask = async(job_id,type) =>{
//   if(type =='1')
//   {
//     NotificationManager.error('Please upload file','',2000);
//   }
//   else
//   {
//     try
//     {
//       const formData = new FormData();
//       formData.append('job_id', job_id);

//       if(orderDetailsById?.editor_id!=null)
//       {
//         // console.warn('editor');
//         formData.append('assign_editor_id', orderDetailsById?.editor_id);
//         formData.append('assign_internal_id', '');
//       }
//       else
//       {
//         // console.warn('internal editor');
//         formData.append('assign_internal_id', orderDetailsById?.internal_id);
//         formData.append('assign_editor_id', '');
//       }
//       formData.append('job_status', '30'); // job status =>Returned-30 , Review-31
//       formData.append('editor_submit_file[]', editorSubmitFile);

//       setLoading(true);
//       const config = {
//         headers: { 'content-type': 'multipart/form-data' }
//       }
//     let apiRes = await axios.post(`${API_BASE_URL}job/jobhold`, formData, config)

//         if(apiRes)
//         {
//           if(apiRes.data.res == true) 
//           {
//             let apiRes1 = await(axios.post(`${API_BASE_URL}job/job_status`,{
//                   job_id: job_id,
//                   job_status: '30' // job status =>Returned
//                 },
//               )
//             )
//             if(apiRes1)
//             {
//               if(apiRes1.data.res == true)
//               {
//                 setEditorSubmitFileName();
//                 NotificationManager.success(apiRes1.data.msg,'',2000);
//                 setLoading(false);
//                 taskSubmitModalClose();
//                 $(".DtlsSectionWrapper").removeClass("Act");
//                 jobListByInternal();
//                 let apiRes2 = await axios.post(`${API_BASE_URL}job/job_statusmail`,{
//                   job_id: job_id,
//                   job_status: '30', // job status =>Returned
//                   sender_id: orderDetailsById?.editor_id!=null ?  orderDetailsById?.editor_id : orderDetailsById?.internal_id,
//                 },
//                 )
//                 if(apiRes2)
//                 {
//                   if(apiRes2.data.res == true) 
//                   {
//                   //   NotificationManager.success('Email Send,'',2000);
//                   }
//                 }
//               }
//             }
//           }
//           else
//           {
//               setLoading(false);
//               NotificationManager.error(apiRes.data.msg, '',2000);
//           }
//         }
//         else
//         {
//           throw new Error('Network request failed');
//         }
//     }
//     catch (error) 
//     {
//       NotificationManager.error(error.message, "", 2000);
//     }
//   }
// }

//finished editor task and submitted
const handleSubmittedTask = async(job_id) =>{
  if(editorSubmitFiles.length == 0)
  {
    NotificationManager.error('Please upload file','',2000);
  }
  else
  {
    try
    {
      const formData = new FormData();
      formData.append('job_id', job_id);
      console.log(orderDetailsById);
      // return
      if(orderDetailsById?.editor_id =='' || orderDetailsById?.editor_id == null)
      {
        formData.append('assign_internal_id', orderDetailsById?.internal_id);
        formData.append('assign_editor_id', '');
        console.log('internal editor');
      }
      else
      {
        formData.append('assign_editor_id', orderDetailsById?.editor_id);
        formData.append('assign_internal_id', '');
        console.log('editor');
      }
      formData.append('job_status', '30');  // job status =>Returned-30 , Review-31
      // formData.append('editor_submit_file[]', editorSubmitFile);
      // formData.append('editor_submit_file[]', editorSubmitFiles);

      editorSubmitFiles?.forEach((item, index) => {
        formData.append(`editor_submit_file[]`, item.file === '' ? '' : item.file);
      });

      setLoading(true);

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let apiRes = await axios.post(`${API_BASE_URL}job/jobhold`, formData, config)

      if(apiRes)
      {
        if(apiRes.data.res == true) 
        {
          let apiRes1 = await(axios.post(`${API_BASE_URL}job/job_status`,{
            job_id: job_id,
            job_status: '30' // job status =>Returned
          },
          ))
          if(apiRes1)
          {
            if(apiRes1.data.res == true)
            {
              // setEditorSubmitFileName();
              // setEditorSubmitFileNames();
              NotificationManager.success(apiRes1.data.msg,'',2000);
              setLoading(false);
              taskSubmitModalClose();
              $(".DtlsSectionWrapper").removeClass("Act");
              // jobListByEditor();
              jobListByInternal();
              let apiRes2 = await axios.post(`${API_BASE_URL}job/job_statusmail`,{
                job_id: job_id,
                job_status: '30', // job status =>Returned
                sender_id: orderDetailsById?.editor_id!=null ?  orderDetailsById?.editor_id : orderDetailsById?.internal_id,
              },
              )
              if(apiRes2)
              {
                if(apiRes2.data.res == true) 
                {
                //   NotificationManager.success('Email Send,'',2000);
                }else{
                  console.log('Email is not send.');
                }
              }
            }
          }
        }
        else
        {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, '',2000);
        }
      }
      else
      {
        throw new Error('Network request failed');
      }
    }
    catch (error) 
    {
      NotificationManager.error(error.message, "", 2000);
    }
  }
}
  //short file name
  // const shortFileName=(item) =>{
  //   let filename = item?.slice(64,69);
  //   return filename +'.....';
  // }

  //short service-file name
  const shortFileName=(item) =>{
    console.log(item?.slice(56,70));
    let filename = item?.slice(56,70);
    return filename +'...';
    // return item\
  }

    //short comment-file name
    const shortCommentFileName = (item) =>
    {
      let splt_filename = item?.split("/");
      return splt_filename[1];
      // return item;
    }

   //short instruction-file name
   const shortInstFileName=(item) =>{
    console.log(item?.slice(57,65));
    let filename = item?.slice(57,65);
    return filename;
    // return item
  }
  
  //sorting job list
  const sortingJobList = async(filter_id) =>{
    console.log(filter_id)
    if (filter_id == 0)
    {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/joblist`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setOrderList(apiRes.data.data);
            }
        }
      }
      catch (error)
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }
    else
    {
      try 
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}`)
        if (apiRes) 
        {
          if (apiRes.data.res == true) 
          {
            setOrderList(apiRes.data.data);
          }else{
            console.log('Something went wrong!');
          }
        }
      } 
        catch (error) 
        {
          console.error(error);
        }
    }
  }

    //sorting job list by id
    const sortingJobByEditorId = async(filter_id) =>{
      console.log(filter_id)
      if (filter_id == 0)
      {
        try
        {
          // https://www.demoupdates.com/updates/elite/api/job/joblistbyeditor?editor_id=115
          // let apiRes = await axios.get(`${API_BASE_URL}job/joblist`) 
          // https://www.demoupdates.com/updates/elite/api/job/myjobsbyeditorid?editor_id=115
          let apiRes = await axios.get(`${API_BASE_URL}job/myjobsbyeditorid?editor_id=${115}`)
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setOrderList(apiRes.data.data);
              }
          }
        }//close try 
        catch (error)
        {
          // NotificationManager.error(error.message, "", 2000);
        }
      }
      else
      {
        try 
        {
          // https://www.demoupdates.com/updates/elite/api/job/jobliststatusbyeditor?job_status=4&editor_id=115
          // let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}`)
          let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatusbyeditor?job_status=${filter_id}&editor_id=${data?.id}`)
          if (apiRes) 
          {
              console.log(apiRes.data.res);
              if (apiRes.data.res == true) 
              {
                setOrderList(apiRes.data.data);
              }
          }
        } 
          catch (error) 
          {
            // NotificationManager.error(error.message, "", 2000);
          }
      }
    }

  const remainingTimeCount = (datetime) =>{
    console.log(datetime)
    
    var split_days = datetime?.split(",");
      console.log(split_days)
      var sp_days = split_days[0].slice(0,2);
      var sp_hours = split_days[1].slice(0,2);
      var sp_minutes = split_days[2].slice(0,2);
      var sp_seconds = split_days[3].slice(0,2);
  
      // console.log('Days: ' + sp_days + ' ' + 'Hours: ' + sp_hours + ' ' + 'Minutes: ' + sp_minutes + ' ' + 'Seconds: ' + sp_seconds);
      // console.log('Days: ' + sp_days.slice(0,2) + ' ' + 'Hours: ' + sp_hours.slice(0,2) + ' ' + 'Minutes: ' + sp_minutes.slice(0,2) + ' ' + 'Seconds: ' + sp_seconds.slice(0,2));
  
    const DAYS_IN_MS = parseInt(sp_days.slice(0,2))* 24 * 60 * 60 * 1000;
    const hour_ms = parseInt(sp_hours.slice(0,2)) * 3600000;
    const minutes_ms = parseInt(sp_minutes.slice(0,2)) * 60000;
    const sec_ms = parseInt(sp_seconds.slice(0,2)) * 1000;
    const total_ms = DAYS_IN_MS + hour_ms + minutes_ms + sec_ms;
    console.log(total_ms)
  
    if(total_ms < 0 )
    {
      setCountTimer(false);
    }
    else
    {
      const NOW_IN_MS = new Date().getTime();
      const dateTimeAfterThreeDays = NOW_IN_MS + total_ms;
      console.log(dateTimeAfterThreeDays)
      // <CountdownTimer targetDate={dateTimeAfterThreeDays} />
      return(<CountdownTimer targetDate={dateTimeAfterThreeDays} />)
    }
    
  }

  const countUpTimer = (datetime) => {
    let total_ms = 0;
  
    if (datetime !== "0") {
      const split_days = datetime?.split(",");
      if (split_days?.length === 4) {
        const sp_days = parseInt(split_days[0]);
        const sp_hours = parseInt(split_days[1]);
        const sp_minutes = parseInt(split_days[2]);
        const sp_seconds = parseInt(split_days[3]);
  
        const DAYS_IN_MS = sp_days * 24 * 60 * 60 * 1000;
        const HOURS_IN_MS = sp_hours * 60 * 60 * 1000;
        const MINUTES_IN_MS = sp_minutes * 60 * 1000;
        const SECONDS_IN_MS = sp_seconds * 1000;
  
        total_ms = DAYS_IN_MS + HOURS_IN_MS + MINUTES_IN_MS + SECONDS_IN_MS;
      }
    }
  
    return <CountUpTimerComponent MS={total_ms} />;
  };

    // Job is accepted or not   {accept_type = 0 initially, accept_type = 1 accept, accept_type = 2 declined pickup = 3}
    const jobAcceptOrNot = async(job_id,accept_type) =>{
      
            // let apiRes = await axios.get(`${API_BASE_URL}job/jobacceptstatus?id=${job_id}&accept_status=${accept_type}&job_status=${1}`)
            let apiRes = await axios.get(`${API_BASE_URL}job/rejectjob?id=${job_id}&accept_status=${0}&job_status=${1}`)
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                  NotificationManager.info('Job Declined', "", 2000);
                  $(".DtlsSectionWrapper").removeClass("Act");
                  handleClose();
                  // jobListByEditor();
                  jobListByInternal();
                }
            }
          else
          {
            NotificationManager.error('something went wrong', "", 2000);
          }
    }

  //single file upload in comments
  const uploadFileHandle = (e) =>{
    if(
      e.currentTarget.files[0].type=='application/msword' ||
      e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||
      e.currentTarget.files[0].type== 'application/pdf' ||
      e.currentTarget.files[0].type== 'image/jpeg' ||
      e.currentTarget.files[0].type=='image/png'
      // e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.presentationml.presentation'||
    )
    {
      console.log(e.target.files[0].type);
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
    else
    {
      NotificationManager.warning('File is not supported.Supported file types are DOC,DOCX,PDF,JPEG,PNG','',3000);
    }
  }

  //single file delete in comments
  const deleteFileHandle = () =>
  {    
    setFile('');
    setFileName('');
  }
    
  //All Insert Comments
  //  const allComments = async() =>{
  //   try 
  //   {
  //     let apiRes = await(
  //         axios
  //         .post(
  //           `${API_BASE_URL}getAllComments`,{
  //             user_id: orderDetailsById?.user_id,
  //             editor_id: orderDetailsById?.editor_id,
  //             job_id: orderDetailsById?.id,
  //           },
  //         )
  //       )
  //     if(apiRes)
  //     { 
  //         console.log(apiRes.data.res)
  //         if(apiRes.data.res == true) 
  //         {
  //           setInternalMsgArray(apiRes.data.data[0]);
  //         }
  //         else
  //         {
  //           NotificationManager.error('error', '',2000);
  //         }
  //     }
  //   }
  //   catch (error) 
  //   {
  //     NotificationManager.error(error.message, '',2000);
  //   }

  // }

   //Client-Editor Comment Section
   const commentSection = async() =>{
    if((internalMsg === '' || internalMsg == null || internalMsg == undefined) && (fileName === '' || fileName == null || fileName == undefined))
    {
      console.log(internalMsg);
      NotificationManager.warning('Please write something..','',2000);
    }
    else
    {
      var job_title='';
      var service_type ='';
      try 
      {
        if(orderDetailsById?.service_type == '0')
        {
          service_type = 'Regular Service'
        }
        else if(orderDetailsById?.service_type == '1')
        {
          service_type = 'Expedited Service'
        }

        if(orderDetailsById?.job_type ==='type2')
        {
          job_title = '#' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week'
        }
        else if(orderDetailsById?.job_type ==='type1')
        {
          job_title = '#' + orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + ' ' + orderDetailsById?.total_wordcount +' ' + '|' + ' ' + service_type + ' ' + '|' + ' ' + ' ' +orderDetailsById?.service_hour + ' '  +'hrs'
        
        }

          const formData = new FormData();

          formData.append('user_id',orderDetailsById?.user_id);
          formData.append('jobtitle',job_title);
          formData.append('editor_id',orderDetailsById?.editor_id ? orderDetailsById?.editor_id : '');
          formData.append('admin_id','5');
          formData.append('internal_id',orderDetailsById?.internal_id ? orderDetailsById?.internal_id : '');
          formData.append('deputy_id',orderDetailsById?.deputy_id ? orderDetailsById?.deputy_id : '');
          formData.append('job_id',orderDetailsById?.id);

          formData.append('editor_designation',orderDetailsById?.editor_designation? orderDetailsById?.editor_designation : '');
          formData.append('internal_designation',orderDetailsById?.internal_designation? orderDetailsById?.internal_designation : '');
          formData.append('admin_designation','Admin');
          formData.append('deputy_designation',orderDetailsById?.deputy_designation ? orderDetailsById?.deputy_designation : '');


          formData.append('client_comment','');
          formData.append('editor_comment','');
          formData.append('admin_comment','');
          formData.append('internal_comment',internalMsg);
          formData.append('deputy_comment','');
          formData.append('client_file','');
          formData.append('editor_file','');
          formData.append('admin_file','');
          formData.append('internal_file',file);
          formData.append('deputy_file','');

          const config = {     
            headers: { 'content-type': 'multipart/form-data' }
          }

          let apiRes = await axios.post(`${API_BASE_URL}comment`,formData,config) 

          if(apiRes)
          { 
            setInternalMsg('');
            setFile('');
            setFileName('');
            formData.append('comment_id',apiRes.data.comment_id);
            formData.append('sender_id',data?.id);
            
            fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id);
            let apiRes1 = await axios.post(`${API_BASE_URL}comment/chatmail`,formData,config) 
            // console.log(apiRes.data.res)
            if(apiRes1.data.res == true) 
            {
              // setInternalMsg('');
              // setFile('');
              // setFileName('');
              getInternalNotification();
              fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id);
            }
            else
            {
              // NotificationManager.warning(apiRes.data.msg, '',2000);
            }
          }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      }
    }
  }
    
  //fetch all comments Editor-Client
  const fetchAllComments = async(user_id,editor_id,job_id,internal_id,deputy_id) =>{
    try 
    {
      let intern_id= '';
      let dep_id = '';
      if(internal_id === null && deputy_id === null)
      {
        intern_id = '';
        dep_id = '';
      }
      else
      {
        intern_id = internal_id;
      }
      // setLoading(true);
      let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}&internal_id=${intern_id}&deputy_id=${dep_id}&UserTimeZone=${timeZone}`)
        if(apiRes)
        { 
          console.log(apiRes.data.res)
          if(apiRes.data.res == true) 
          {
            setShowAllComments(apiRes.data.data);
          }
          else
          {
            // NotificationManager.warning(apiRes.data.msg, '',2000);
          }
        }
    }
    catch (error) 
    {
      // NotificationManager.error(error.message, '',2000);
    }
  }

  return (
    <div>
      <InternalEditorHeader unreadMsg={notificationMsg}/>
      <InternalEditorSidebar/>
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>My Jobs</h2>
          </span>
          <div className="dropdown">
            Filter By
              <select className="dropdown-toggle" data-toggle="dropdown" style={{border: 'none',outline: 'none',scrollBehavior: 'smooth'}} onChange={(e)=>sortingJobByEditorId(e.target.value)}>
                <option className="dropdown-item" href="#" value='0'>All Jobs</option>
                {/* <option className="dropdown-item" href="#" value='1'>Not Yet Assigned</option> */}
                {/* <option className="dropdown-item" href="#" value='2'>Assigned</option> */}
                <option className="dropdown-item" href="#" value='3'>Ongoing</option>
                <option className="dropdown-item" href="#" value='4'>Completed</option>
              </select>
          </div>
        </div>
        {/* <br className="clearfix" /> */}
        <section className="section OdrDtlsPnl">
          <div className="complete-table-sec">
            {/* <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table"> */}
            <table width="100%" className="CmnTbl OrdersTable editor-my-job-table table-striped blue-header-table EditorMyJobsTable"> 
                <thead>
                  <tr>
                    <th>Job #</th>
                    <th className="text-center">Task Name</th>
                    <th>WC/Pages</th>
                    <th>Amount</th> 
                    <th>Due Date</th>
                    <th style={{textAlign: "center"}}>Editor Timer</th>
                    <th>Status</th>
                    <th>Job Request</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {orderList?.length > 0 ? (
                  orderList?.map((item,index)=>{
                    return(
                      <tr>
                        {(item?.job_type==='type2')?(
                          <>
                            <td> {item?.id} </td>
                            <td>
                              <p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getOrderDetails(item?.id)}}>
                                {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                              </p>
                            </td>
                            <td style={{textAlign: "center"}}>{item.number_of_pages}</td>
                            <td>${parseFloat(Number(item.editor_cv_price)).toFixed(2)}</td>
                          </>
                        ):(
                          <>
                            <td> {item?.id} </td>
                            <td>
                              <p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getOrderDetails(item?.id)}}>
                                {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {item?.service_hour} hrs
                              </p>
                            </td>
                            <td style={{textAlign: "center"}}>{item.total_wordcount}</td>
                            <td>${parseFloat(Number(item.editor_reg_price)).toFixed(2)}</td>
                          </>
                        )}
                        
                        <td> <span className="GreenTxt">{get_date_format(item?.due_date)}</span></td>
                        <td style={{textAlign: "center"}}>
                          {item?.job_status == '4'?(
                            <div className="count-down-timer">
                                <b>----</b>
                            </div>
                          ):(
                            <div className="count-down-timer">
                              {item?.date75 == 0 ?(
                                <div class="count-down-timer count-down-timer-stop">
                                  <div class="show-counter">
                                    {countUpTimer(item?.date75_new)}
                                  </div>
                                </div>
                              ):(
                                <>
                                  {remainingTimeCount(item?.date75)}
                                </>
                              )}
                            </div>
                          )}
                          
                        </td>
                        <td className="order-table-project-status">
                          {
                            item?.date75 == 0 && item?.differncetime ==0 ? (<span className="asign-brown"><i class="bi bi-clock"></i>Expired</span>):
                            item?.job_status == '2'? (<span className="asign-deep-blue"><i class="bi bi-clock"></i> Assigned</span>):
                            item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                            item?.job_status == '30' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                            item?.job_status == '31' && item?.date75!= 0 ? (<span className="StatusTxt reassign"><i class="bi bi-arrow-clockwise"></i> ReAssign</span>):
                            item?.job_status == '32' && item?.date75!= 0 ? (<span className="StatusTxt returned"><i class="bi bi-arrow-clockwise"></i> Returned</span>):
                            item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                            item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Canceled</span>):''
                        }
                        </td>
                        <td>
                          {item?.job_request == '1' ? (
                            <>{item?.internal_designation}</>
                          ):(
                            <></>
                          )}
                        </td>
                        <td>
                          {((item?.job_status == '3') || (item?.job_status == '2'))? (
                            <a className="reject-job-link" onClick={()=>jobAcceptOrNot(item?.id,0)}>Reject</a>
                          ):(
                            <></>
                          )}
                        </td>
                    </tr>
                    )
                  })
                  ):(
                    <>
                      {displayMsg && displayMsg == 0 ?(
                          <p style={{textAlign:'end'}}>Please Wait while fetching job list..</p>
                      ):(
                        <>
                          {displayMsg && displayMsg == 1 ?(
                            <></>
                          ):(
                            <tr>
                              <td className='no-data' colSpan={9} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                            </tr>
                          )}
                        </>
                      )}
                        
                    </>
                  )}
                </tbody>
            </table>  
          </div>
         
          {/* left Ongoing Pannel */}
          <div className={`DtlsSectionWrapper OngoingPnl`} ref={windowRef}>
          <div className={`TaskDtlsPnl ${orderDetailsById?.job_status == '3' ? 'textarea-sec':'no-textarea-sec'}`}>
              <div className="HdrTop">
                <span className="Lft" style={{cursor:'not-allowed'}}>
                  {orderDetailsById?.job_status=='3' || orderDetailsById?.job_status=='31' ?(
                    loading ? (
                    <Button className="CmnBtn MarkCompleteBtn">
                      <i className="bi bi-check-circle" /> Please Wait..
                    </Button>
                    ):(
                      <Button onClick={()=>taskSubmitModalShow()} className="CmnBtn submitBtm">
                        <i className="bi bi-check-circle" /> Submit Task
                      </Button>
                    )
                  ):(
                    <Button className="CmnBtn MarkCompleteBtn" disabled>
                      <i className="bi bi-check-circle" /> Task Submitted
                    </Button>
                  )}
                  {/* <a onClick={markShow} className="CmnBtn MarkCompleteBtn">
                    <i className="bi bi-check-circle" /> Mark Complete
                  </a>
                  <a onClick={orderCancelShow} className="CmnBtn CancelOrdereBtn">
                    <i className="bi bi-check-circle" /> Cancel Order
                  </a> */}
                  {/* <span class="Lft"><a class="CmnBtn CompletedBtn"><i class="bi bi-check-circle"></i> Completed</a></span> */}
                </span>
                <span className="Rht">
                  {/* <a className="OpenReview" onClick={handleShow}>
                    <img src={reviewIcon} al="img" />
                  </a> */}
                  <a href="javascript:void(0);" className="CloseBtn" onClick={()=>{closeJobDetlsPnl();handleClose();setAPiCall(false)}}>
                    <i className="bi bi-x" />
                  </a>
                </span>
              </div>
              <div className="MiddleBody">
                {(orderDetailsById?.job_type==="type2")?(
                    <>
                      <h2>
                        #{orderDetailsById?.id} | {orderDetailsById?.service_name} | Number of Pages {orderDetailsById?.number_of_pages} | {orderDetailsById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                      </h2>
                      <table className="DtlsDescrpTbl">
                        <tbody>
                          <tr>
                            {/* <td>Editor</td> */}
                            {/* <td>
                              <span className="NameLogo">sw</span> <span className="name-span">Sam Willium</span>
                            </td> */}
                          </tr>
                          <tr>
                            <td>Due date</td>
                            <td>
                              <span className="GreenTxt">{get_date_format(orderDetailsById?.due_date)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Instruction</td>
                            {/* <td>{orderDetailsById?.client_instruction}</td> */}
                            {/* <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} /> */}
                            {orderDetailsById?.client_instruction!='null' ? (
                              <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} />
                            ):(
                              <td>No instruction included.</td>
                            )}
                          </tr>
                          <tr>
                            <td>Services:</td>
                            <td>{orderDetailsById?.service_name}</td>
                          </tr>
                          {/* <tr>
                            <td>Options:</td>
                            <td>{orderDetailsById?.sub_service}</td>
                          </tr> */}
                          {/* <tr>
                            <td>Word Count:</td>
                            <td>{orderDetailsById?.from_count}-{orderDetailsById?.to_count}</td>
                          </tr> */}

                          <tr>
                            {/* <td>Seep Services:</td> */}
                            <td>Service Type:</td>
                            <td>{orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                          </tr>

                          <tr>
                            <td>Number of Pages: </td>
                            <td>{orderDetailsById?.number_of_pages}</td>
                          </tr>

                          <tr>
                            <td>Price: </td>
                            <td>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</td>
                          </tr>

                          {/* <tr>
                            <td>Language:</td>
                            <td>
                              {
                                orderDetailsById?.english_version=='1'?'American':
                                orderDetailsById?.english_version=='2'?'British':
                                orderDetailsById?.english_version=='3'?'Canadian':''
                              }
                            </td>
                          </tr> */}
                          
                          <tr>
                            <td>Service File:</td>
                            <td>
                              {serviceFile?.length > 0 && serviceFile?.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                                // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                              <br />
                            </td>
                          </tr>
                          
                          <tr>
                            <td>Instruction File:</td>
                            <td><br/>
                              {instructionFile?.length > 0 && instructionFile?.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                                        // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                            </td>
                          </tr>
                        
                        </tbody>
                      </table>
                    </>
                ):(
                    <>
                      <h2>
                        #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {orderDetailsById?.sub_service} | Word Count {orderDetailsById?.total_wordcount} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {orderDetailsById?.service_hour} hrs 
                      </h2>
                      <table className="DtlsDescrpTbl">
                        <tbody>
                          <tr>
                            {/* <td>Editor</td> */}
                            {/* <td>
                              <span className="NameLogo">sw</span> <span className="name-span">Sam Willium</span>
                            </td> */}
                          </tr>
                          <tr>
                            <td>Due date</td>
                            <td>
                              <span className="GreenTxt">{get_date_format(orderDetailsById?.due_date)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Instruction</td>
                            {orderDetailsById?.client_instruction!='null' ? (
                              <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} />
                            ):(
                              <td>No instruction included.</td>
                            )}
                          </tr>
                          <tr>
                            <td>Services:</td>
                            <td>{orderDetailsById?.service_name}</td>
                          </tr>
                          <tr>
                            <td>Options:</td>
                            <td>{orderDetailsById?.sub_service}</td>
                          </tr>
                          <tr>
                            <td>Word Count:</td>
                            {/* <td>251-999</td> */}
                            {/* <td>{orderDetailsById?.total_wordcount}</td> */}
                            {/* <td>{orderDetailsById?.from_count}-{orderDetailsById?.to_count}</td> */}
                            <td>{orderDetailsById?.total_wordcount}</td>
                          </tr>
                          <tr>
                            {/* <td>Seep Services:</td> */}
                            <td>Service Type:</td>
                            {/* <td>Regular[24hrs]</td> */}
                            <td>{orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                          </tr>
                          <tr>
                            <td>Language:</td>
                            <td>
                              {
                                orderDetailsById?.english_version=='1'? 'American':
                                orderDetailsById?.english_version=='2'? 'Australian':
                                orderDetailsById?.english_version=='3'? 'British':
                                orderDetailsById?.english_version=='4'? 'Canadian':''
                              }
                            </td>
                          </tr>
                          
                          <tr>
                            <td>Service File:</td>
                            <td>
                              {serviceFile?.length > 0 && serviceFile?.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                                // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                              <br />
                            </td>
                          </tr>
                          
                          <tr>
                            <td>Instruction File:</td>
                            <td><br/>
                              {instructionFile?.length > 0 && instructionFile?.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                                  // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                            </td>
                          </tr>
                        
                        </tbody>
                      </table>
                    </>
                )}
                
                
                {/* <div className="CommentItem">
                  <p className="AuthorName">
                    <span className="NameLogo BlueNameLogo">ad</span> Editor
                    Comment:
                  </p>
                  {ShowAllComments && ShowAllComments.map((item,index)=>{
                    return(
                        <p>{item?.editor_comment}</p>
                    )
                  })}
                </div> */}
                {/* <div className="CommentItem">
                  <p className="AuthorName">
                    <span className="NameLogo">sw</span> Sam Willium
                  </p>
                  {ShowAllComments && ShowAllComments.map((item,index)=>{
                    return(
                        <p>{item?.client_comment}</p>
                    )
                  })}
                  <div className="RhtDate">
                    <span>
                      Dec 09, 2022
                      <br />
                      10:45 PM
                    </span>
                  </div>
                </div> */}

              {orderDetailsById?.job_status == '3' &&(
              <>
                {/* Comment Section Start */}

                {ShowAllComments && ShowAllComments.map((item,index)=>{
                  return(
                    <div className="CommentItem">
                      {/* --Client Comment Section Start-- */}
                        {item?.client_comment!='' && (
                          <>
                            <p className="AuthorName">
                              {/* <span className="NameLogo">sw</span> Sam Willium */}
                              <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.client_comment =="undefined" ? '' : item?.client_comment}}/>
                          </>
                        )}

                        {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment =='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                {
                                  item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>

                        )}

                        {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment!='' && (
                          <>
                            <ul className="showAttachFileList">
                              <li>
                                {
                                  item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>

                        )}
                      {/* --Client Comment Section End-- */}
                        
                      {/* --Editor Comment Section Start-- */}
                        {item?.editor_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.editor_comment =="undefined" ? '' : item?.editor_comment}}/>
                          </>
                        )}

                        {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment =='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment!='' &&(

                        <ul className="showAttachFileList">
                          <li>
                            { 
                            item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                            }
                              
                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>

                        )}
                      {/* --Editor Comment Section End-- */}

                      {/* --Admin Comment Section Start-- */}
                        {item?.admin_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.admin_comment =="undefined" ? '' : item?.admin_comment}}/>
                          </>
                        )}

                        {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment!='' && (

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                            }
                              
                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>

                        )}
                      {/* --Admin Comment Section End-- */}

                      {/* --Internal Comment Section Start-- */}
                        {item?.internal_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.internal_comment =="undefined" ? '' : item?.internal_comment }}/>
                          </>
                        )}

                        {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                                }
                                  
                                  {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment!='' &&(

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                            }
                              
                              {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                          </li>
                        </ul>

                        )}
                      {/* --Internal Comment Section End-- */}

                      {/* --Deputy Comment Section Start-- */}
                        {item?.deputy_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.deputy_comment =="undefined" ? '' : item?.deputy_comment }}/>
                          </>
                        )}

                        {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment =='' &&(
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                              </p>
                              <ul className="showAttachFileList">
                                <li>
                                  { 
                                    item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                    item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                                  }
                                </li>
                              </ul>
                            </>
                        )}

                        {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment!=''&& (

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                            }
                          </li>
                        </ul>

                        )}
                      {/* --Deputy Comment Section End-- */}

                    <div className="RhtDate">
                      <span>  
                        {get_date_format(item?.created_at)}
                        <br />
                        {get_time_format(item?.created_at)}
                      </span>
                    </div>

                  </div>
                )})}

                {/* Comment Section End */}
              </>
              )}
              </div>

               {orderDetailsById?.job_status == '3' &&(
                <div className="BtmFtr">
                  <a className="attachFileLink"><input type="file" className="inputFile" onChange={(e) => {uploadFileHandle(e)}} /><img src={attachFileIcon} alt /></a>
                  <CKEditor 
                    editor={ ClassicEditor }
                    data = {internalMsg}
                    config={{
                      toolbarLocation: "bottom",
                      toolbar: ['link']
                    }}
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                          setInternalMsg(data);
                        }}
                  />
                  {/* <ul className="showAttachFileList">
                    <li>
                      <span className="icon-span"><img src={pdfImg} alt="img" /></span>
                      <span className="upload-title-span">feedback3.pdf</span>
                      <span className="delete-upload-span"><i class="bi bi-trash"></i></span>
                    </li>
				          </ul> */}
                  <ul className="showAttachFileList">
                    {((fileName && fileName != undefined) || (fileName && fileName != null) || (fileName && fileName != '')) ? (
                      <li>
                        <span className="icon-span">
                          {
                            fileName?.endsWith('.pdf') ? <img src={pdfImg} alt="img" />:
                            fileName?.endsWith('.PDF') ? <img src={pdfImg} alt="img" />:
                            fileName?.endsWith('.docx') ? <img src={docImg} alt="img" />:
                            fileName?.endsWith('.DOCX') ? <img src={docImg} alt="img" />:
                            fileName?.endsWith('.jpg') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JPG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.jpeg') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JPEG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.png') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.PNG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.jfif') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JFIF') ? <img src={iconImg} alt="img" />:''
                          }
                        </span>
                        {/* <span className="upload-title-span">feedback3.pdf</span> */}
                        <span className="upload-title-span">{fileName}</span>
                        <span className="delete-upload-span"><i class="bi bi-trash" onClick={()=>deleteFileHandle()}></i></span>
                      </li>
                    ):(
                      <li></li>
                    )}
				          </ul>

                  <a className="btn btn-primary CmnBtn submitBtm" onClick={()=>commentSection()}>Submit</a>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>

      {/* <Modal show={taskSubmitModalOpen} onHide={()=>{taskSubmitModalClose();setTaskFile(false);setEditorSubmitFile();setEditorSubmitFileName()}} className="CmnModal taskSubmitModal"> */}
      <Modal show={taskSubmitModalOpen} onHide={()=>{taskSubmitModalClose();setTaskFile(false);setEditorSubmitFiles();setEditorSubmitFileNames()}} className="CmnModal taskSubmitModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <div className="row">
            <div className="col-md-4">
              <div className="styles-browser-sec">
                <input type="file" id="file" name="file" onChange={(e)=>handleEditorFile(e)} className="browseInput" />
              </div>
            </div>
            {taskFile?(
              <div className="col-md-8 upload-content">
                <h5 style={{margin: '25px'}}>
                  <p>File Name:  {editorSubmitFileName}</p>
                </h5>
              </div>
            ):(
              <div className="col-md-8 upload-content">
                <h4>Choose Files</h4>
                <p>Preferred files are MS Word & PDF </p>
              </div>
            )}

          </div>
          {taskFile?(
            <a class="CmnBtn" onClick={()=>handleSubmittedTask(orderDetailsById?.id,'0')}>
              <i class="bi bi-check-circle"></i> 
              Submit Task
            </a>
          ):(
            <a class="CmnBtn" onClick={()=>handleSubmittedTask(orderDetailsById?.id,'1')}>
              <i class="bi bi-check-circle"></i> 
              Submit Task
            </a>
          )} */}
          <div className="row">
            <div className="col-md-4">
              <div className="styles-browser-sec">
                <input type="file" id="file" multiple name="file" onChange={handleEditorFiles} className="browseInput" />
              </div>
            </div>
            {taskFile ? (
              <div className="col-md-8 upload-content">
                <ul className="UploadList">
                  {editorSubmitFiles.map((item, index) => (
                    <li key={index}>
                      File Name: {item.file_name}
                      <a className="DeleteLink" onClick={() => deleteFile(index)}>
                        <i className="bi bi-trash"></i>
                      </a>
                    </li>
                  ))}
                  <li>
                    <a className="CmnBtn" onClick={() => handleSubmittedTask(orderDetailsById?.id)}>
                      <i className="bi bi-check-circle"></i>
                      Submit Job
                    </a>
                  </li>
                </ul>
              </div>
            ):(
              <div className="col-md-8 upload-content">
                <h4>Choose Files</h4>
                <p>MS Word, PowerPoint, & Excel; PDF; RTF; ODT; Pages; Google Docs, Slides, & Sheets; and Plain Text are preferred.</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InternalEditorMyJobs;
