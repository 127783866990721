import React, { Component, useState, useEffect, useRef } from "react";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
// const logo = require("./assets/img/logo.png");
const logo = require("../../assets/img/logo-new.svg").default;

const Header = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  let data = JSON.parse(localStorage.getItem("user_data_elite"));
  // setUserData(data);

  const LogoutFunction = () => {
    localStorage.removeItem("user_data_elite");
    localStorage.clear();
    navigate("/login");
  };

  const checkUser = () => {
    let data = JSON.parse(localStorage.getItem("user_data_elite"));
    if (data != undefined || data != null) {
      setUserData(data);
    }
  };

  useEffect(() => {
    checkUser();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = (e) => {
    const header = document.querySelector(".beforeHeader");
    const scrollTop = window.scrollY;
    scrollTop >= 50 ? header.classList.add("is-sticky") : header.classList.remove("is-sticky");
  };

  const handleToggleOffcanvas = () => {
    setShowOffcanvas((prev) => !prev);
  };

  return (
    <>
      {userData != null || userData != undefined ? (
        <div className="beforeHeader beforeHeader-login">
          <Navbar expand="lg" className="login-nav">
            <Container>
              <NavLink className="navbar-brand" to="/">
                <img src={logo} alt="Image" />
              </NavLink>
              <Button variant="outline-primary" onClick={handleToggleOffcanvas} className="mobile-toggle-button">
                <span className="navbar-toggler-icon"></span>
              </Button>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto with-login-nav">
                  <li className="nav-item menu-dropdown-item">
                    <a className="my-account-dropdown-link">
                      <span>Home</span>
                    </a>
                    <div className="dropdown-menu">
                      <Dropdown>
                        <NavLink to="/" className="home-link">
                          Home
                        </NavLink>
                      </Dropdown>
                      <Dropdown>
                        <NavLink to="/about" className="about-link">
                          About Us
                        </NavLink>
                      </Dropdown>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/partner-with-us" className="partner-link">
                      <span>Partner</span>
                    </NavLink>
                  </li>
                  <li className="nav-item menu-dropdown-item">
                    <a className="my-account-dropdown-link">
                      <span>Services</span>
                    </a>
                    <div className="dropdown-menu">
                      <Dropdown>
                        <NavLink to="/services">Editing/Proofreading</NavLink>
                      </Dropdown>
                      <Dropdown>
                        <NavLink to="/services-choose-cv">Resume/CV Building</NavLink>
                      </Dropdown>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Career" className="career-link">
                      <span>Careers</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" className="contact-link">
                      <span>Contact</span>
                    </NavLink>
                  </li>
                  {data?.role == 0 ? (
                    <>
                      <li className="nav-item my-account-item">
                        <NavLink to="/admin-dashboard">
                          <span>My Account</span>
                        </NavLink>
                      </li>
                      <li className="nav-item sign-out-item">
                        <NavLink to="/client-my-account">
                          <span>Sign Out</span>
                        </NavLink>
                      </li>
                      <li className="nav-item my-account-dropdown-item">
                        <a className="my-account-dropdown-link">My Account</a>
                        <div className="dropdown-menu">
                          <Dropdown>
                            <NavLink to="/admin-dashboard">Dashboard</NavLink>
                          </Dropdown>
                          <Dropdown>
                            <a onClick={() => LogoutFunction()}>Sign Out</a>
                          </Dropdown>
                        </div>
                      </li>
                    </>
                  ) : data?.role == 1 ? (
                    <>
                      <li className="nav-item my-account-item">
                        <NavLink to="/editor-my-account">
                          <span>My Account</span>
                        </NavLink>
                      </li>
                      <li className="nav-item sign-out-item">
                        <NavLink to="/client-my-account">
                          <span>Sign Out</span>
                        </NavLink>
                      </li>
                      <li className="nav-item my-account-dropdown-item">
                        <a className="my-account-dropdown-link">My Account</a>
                        <div className="dropdown-menu">
                          <Dropdown>
                            <NavLink to="/editor-my-account">My Account</NavLink>
                          </Dropdown>
                          <Dropdown>
                            <a onClick={() => LogoutFunction()}>Sign Out</a>
                          </Dropdown>
                        </div>
                      </li>
                    </>
                  ) : data?.role == 2 ? (
                    <>
                      <li className="nav-item my-account-item">
                        <NavLink to="/client-my-account">
                          <span>My Account</span>
                        </NavLink>
                      </li>
                      <li className="nav-item sign-out-item">
                        <NavLink to="/client-my-account">
                          <span>Sign Out</span>
                        </NavLink>
                      </li>
                      <li className="nav-item my-account-dropdown-item">
                        <a className="my-account-dropdown-link">My Account</a>
                        <div className="dropdown-menu">
                          <Dropdown>
                            <NavLink to="/client-my-orders">My Orders</NavLink>
                          </Dropdown>
                          <Dropdown>
                            <a onClick={() => LogoutFunction()}>Sign Out</a>
                          </Dropdown>
                        </div>
                      </li>
                    </>
                  ) : data?.role == 3 ? (
                    <>
                      <li className="nav-item my-account-item">
                        <NavLink to="/deputy-admin-my-account">
                          <span>My Account</span>
                        </NavLink>
                      </li>
                      <li className="nav-item sign-out-item">
                        <NavLink to="/client-my-account">
                          <span>Sign Out</span>
                        </NavLink>
                      </li>

                      <li className="nav-item my-account-dropdown-item">
                        <a className="my-account-dropdown-link">My Account</a>
                        <div className="dropdown-menu">
                          <Dropdown>
                            <NavLink to="/deputy-admin-dashboard">Dashboard</NavLink>
                          </Dropdown>
                          <Dropdown>
                            <a onClick={() => LogoutFunction()}>Sign Out</a>
                          </Dropdown>
                        </div>
                      </li>
                    </>
                  ) : data?.role == 4 ? (
                    <li className="nav-item my-account-dropdown-item">
                      <a className="my-account-dropdown-link">My Account</a>
                      <div className="dropdown-menu">
                        <Dropdown>
                          <NavLink to="/internal-editor-dashboard">Dashboard</NavLink>
                        </Dropdown>
                        <Dropdown>
                          <a onClick={() => LogoutFunction()}>Sign Out</a>
                        </Dropdown>
                      </div>
                    </li>
                  ) : null}
                </Nav>
              </Navbar.Collapse>
            </Container>
            {showOffcanvas && <div className="offcanvas-overlay" onClick={handleToggleOffcanvas} />}
            <div className={`offcanvas${showOffcanvas ? " active" : ""}`} onClick={() => setShowOffcanvas(false)}>
              <a className="CloseBtn close-sidebar-btn" onClick={handleToggleOffcanvas}>
                <i className="bi bi-x" />
              </a>
              <Nav className="me-auto with-login-nav">
                <li className="nav-item">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="home-link"
                      >
                        Home
                      </Accordion.Header>
                      <Accordion.Body>
                        <NavLink to="/">Home</NavLink>
                        <NavLink to="/about">About Us</NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
                <li className="nav-item">
                  <NavLink to="/partner-with-us" className="partner-link">
                    <span>Partner</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Accordion>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="services-link"
                      >
                        Services
                      </Accordion.Header>
                      <Accordion.Body>
                        <NavLink to="/services">Editing/Proofreading</NavLink>
                        <NavLink to="/services-choose-cv">Resume/CV Building</NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
                <li className="nav-item">
                  <NavLink to="/Career" className="career-link">
                    <span>Careers</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className="contact-link">
                    <span>Contact</span>
                  </NavLink>
                </li>
                {userData?.role == "1" ? (
                  <>
                    <li className="nav-item my-account-item">
                      <NavLink to="/editor-my-account">
                        <span>My Account</span>
                      </NavLink>
                    </li>
                    <li className="nav-item sign-out-item">
                      <a onClick={() => LogoutFunction()}>Sign Out</a>
                    </li>
                    <li className="nav-item my-account-dropdown-item">
                      <a className="my-account-dropdown-link">My Account</a>
                      <div className="dropdown-menu">
                        <Dropdown>
                          <NavLink to="/editor-my-account">My Account</NavLink>
                        </Dropdown>
                        <Dropdown>
                          <a onClick={() => LogoutFunction()}>Sign Out</a>
                        </Dropdown>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    {userData?.role == "2" ? (
                      <>
                        <li className="nav-item my-orders-item">
                          <NavLink to="/client-my-orders">
                            <span>My Orders</span>
                          </NavLink>
                        </li>
                        <li className="nav-item sign-out-item">
                          <a onClick={() => LogoutFunction()}>Sign Out</a>
                        </li>

                        <li className="nav-item my-account-dropdown-item">
                          <a className="my-account-dropdown-link">My Account</a>
                          <div className="dropdown-menu">
                            <Dropdown>
                              <NavLink to="/client-my-account">My Account</NavLink>
                            </Dropdown>
                            <Dropdown>
                              <a onClick={() => LogoutFunction()}>Sign Out</a>
                            </Dropdown>
                          </div>
                        </li>
                      </>
                    ) : (
                      <>
                        {userData?.role == "0" ? (
                          <>
                            <li className="nav-item my-dashboard-item">
                              <NavLink to="/admin-dashboard">
                                <span>Dashboard</span>
                              </NavLink>
                            </li>
                            <li className="nav-item sign-out-item">
                              <a onClick={() => LogoutFunction()}>Sign Out</a>
                            </li>

                            <li className="nav-item my-account-dropdown-item">
                              <a className="my-account-dropdown-link">My Account</a>
                              <div className="dropdown-menu">
                                <Dropdown>
                                  <NavLink to="/admin-dashboard">Dashboard</NavLink>
                                </Dropdown>
                                <Dropdown>
                                  <a onClick={() => LogoutFunction()}>Sign Out</a>
                                </Dropdown>
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            {userData?.role == "4" ? (
                              <>
                                <li className="nav-item my-dashboard-item">
                                  <NavLink to="/internal-editor-dashboard">
                                    <span>Dashboard</span>
                                  </NavLink>
                                </li>
                                <li className="nav-item sign-out-item">
                                  <a onClick={() => LogoutFunction()}>Sign Out</a>
                                </li>

                                <li className="nav-item my-account-dropdown-item">
                                  <a className="my-account-dropdown-link">My Account</a>
                                  <div className="dropdown-menu">
                                    <Dropdown>
                                      <NavLink to="/internal-editor-dashboard">My Account</NavLink>
                                    </Dropdown>
                                    <Dropdown>
                                      <a onClick={() => LogoutFunction()}>Sign Out</a>
                                    </Dropdown>
                                  </div>
                                </li>
                              </>
                            ) : (
                              <>
                                <li className="nav-item my-dashboard-item">
                                  <NavLink to="/deputy-admin-dashboard">
                                    <span>Dashboard</span>
                                  </NavLink>
                                </li>
                                <li className="nav-item sign-out-item">
                                  <a onClick={() => LogoutFunction()}>Sign Out</a>
                                </li>

                                <li className="nav-item my-account-dropdown-item">
                                  <a className="my-account-dropdown-link">My Account</a>
                                  <div className="dropdown-menu">
                                    <Dropdown>
                                      <NavLink to="/deputy-admin-my-account">My Account</NavLink>
                                    </Dropdown>
                                    <Dropdown>
                                      <a onClick={() => LogoutFunction()}>Sign Out</a>
                                    </Dropdown>
                                  </div>
                                </li>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Nav>
            </div>
          </Navbar>
        </div>
      ) : (
        <div className="beforeHeader">
          <Navbar expand="lg">
            <Container>
              <NavLink className="navbar-brand" to="/">
                <img src={logo} alt="Image" />
              </NavLink>
              <Button variant="outline-primary" onClick={handleToggleOffcanvas} className="mobile-toggle-button">
                <span className="navbar-toggler-icon"></span>
              </Button>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <li className="nav-item menu-dropdown-item">
                    <a className="my-account-dropdown-link">
                      <span>Home</span>
                    </a>
                    <div className="dropdown-menu">
                      <Dropdown>
                        <NavLink to="/" className="home-link">
                          Home
                        </NavLink>
                      </Dropdown>
                      <Dropdown>
                        <NavLink to="/about" className="about-link">
                          About Us
                        </NavLink>
                      </Dropdown>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/partner-with-us" className="partner-link">
                      <span>Partner</span>
                    </NavLink>
                  </li>
                  <li className="nav-item menu-dropdown-item">
                    <a className="my-account-dropdown-link">
                      <span>Services</span>
                    </a>
                    <div className="dropdown-menu">
                      <Dropdown>
                        <NavLink to="/services">Editing/Proofreading</NavLink>
                      </Dropdown>
                      <Dropdown>
                        <NavLink to="/services-choose-cv">Resume/CV Building</NavLink>
                      </Dropdown>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Career" className="career-link">
                      <span>Careers</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" className="contact-link">
                      <span>Contact</span>
                    </NavLink>
                  </li>
                  <li className="nav-item signUp-item">
                    <NavLink to="/signup" className="signup-link">
                      <span>Sign Up</span>
                    </NavLink>{" "}
                    |{" "}
                    <NavLink to="/login" className="login-link">
                      <span>Sign In</span>
                    </NavLink>
                  </li>
                </Nav>
              </Navbar.Collapse>
            </Container>
            {showOffcanvas && <div className="offcanvas-overlay" onClick={handleToggleOffcanvas} />}
            <div className={`offcanvas${showOffcanvas ? " active" : ""}`} onClick={() => setShowOffcanvas(false)}>
              <a className="CloseBtn close-sidebar-btn" onClick={handleToggleOffcanvas}>
                <i className="bi bi-x" />
              </a>
              <Nav className="me-auto">
                <li className="nav-item">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="home-link"
                      >
                        Home
                      </Accordion.Header>
                      <Accordion.Body>
                        <NavLink to="/">Home</NavLink>
                        <NavLink to="/about">About Us</NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
                <li className="nav-item">
                  <NavLink to="/partner-with-us" className="partner-link">
                    <span>Partner</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Accordion>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="services-link"
                      >
                        Services
                      </Accordion.Header>
                      <Accordion.Body>
                        <NavLink to="/services">Editing/Proofreading</NavLink>
                        <NavLink to="/services-choose-cv">Resume/CV Building</NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
                <li className="nav-item">
                  <NavLink to="/Career" className="career-link">
                    <span>Careers</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className="contact-link">
                    <span>Contact</span>
                  </NavLink>
                </li>
                <li className="nav-item signUp-item">
                  <NavLink to="/signup" className="signup-link">
                    <span>Sign Up</span>
                  </NavLink>{" "}
                  |{" "}
                  <NavLink to="/login" className="login-link">
                    <span>Sign In</span>
                  </NavLink>
                </li>
              </Nav>
            </div>
          </Navbar>
        </div>
      )}
    </>
  );
};

export default Header;
