import React, { Component, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";
import Button from "react-bootstrap/Button";
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 

const EditInternalByDeputy = () => {
    const {id} = useParams();
    console.log(id);
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false); 
    const [showInternalData,setShowInternalData] = useState(); // for editor info
    const [showCountryList,setShowCountryList] = useState([]); // for getting all countries

    useEffect(() => 
    {
      getInternalInfos();
      getCountry();
    }, []);

//get country list
const getCountry =async() =>
{
    try
    {
        let apiRes = await axios.get(`${API_BASE_URL}login/countries`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              console.log(apiRes.data.data);
              setShowCountryList(apiRes.data.data);
            }
        }
    }//close try
    catch (error) 
    {
        // NotificationManager.error(error.message, "", 2000);
    }   
}

  // get editor information
  const getInternalInfos = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${id}`) 
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          setShowInternalData(apiRes.data.data[0]);
        }
        else
        {
          NotificationManager.error(apiRes.data.res, "", 2000);
        }
      }
    }
    catch (error)
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  };

    const updateInternalData = async(values,{resetForm}) =>{
      console.log(values);
        try
        {
            setLoading(true);
              let apiRes = await(
                axios
                .post(
                ` ${API_BASE_URL}account/editaccount`,{
                      id: values.id,
                      first_name: values.first_name,
                      last_name: values.last_name,
                      ph_no: values.ph_no.toString(),
                      email_address: values.email_address,
                      // organization: values.organization,
                      address: values.address,
                      city: values.city,
                      state: values.state,
                      zipcode: values.zipcode,
                      country: values.country,
                      password: values.password,
                      assigned_number_of_job: values.assigned_number_of_job,
                      designation : values.designation,
                      role: '4'  // 4 for internal editor
                    },
                  )
            )
            if(apiRes)
            {
                console.log(apiRes.data.res)
                if(apiRes.data.res == true) 
                {
                    NotificationManager.success('Update Successfully','',2000);
                    setLoading(false);
                    navigate("/deputy-admin-internal-editor-management");
                }
                else
                {
                    setLoading(false);
                    NotificationManager.error(apiRes.data.msg, '',2000);
                }
            }
        }
        catch (error) 
        {
            // NotificationManager.error(error.message, '',2000);
        } 
    }
  
    let schema = yup.object().shape({
  
      first_name: yup
      .string()
      .required('First name is required'),

      last_name: yup
      .string()
      .required('Last name is required'),
  
      ph_no: yup
      .string()
      .required('Phone number is required')
      .max(10,'Invalid Phone number'),

      email_address: yup
      .string()
      .required('Email is required')
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
        
      address: yup
      .string()
      .required('Street Address is required'),

      city: yup
      .string()
      .required('City is required'),

      state: yup
      .string()
      .required('State is required'),

      zipcode: yup
      .string()
      .required('Zip Code is required'),

      country: yup
      .string()
      .required('Country is required'),
      
      assigned_number_of_job: yup
        .number()
        // .max(1 , 'Maximum number of job can assign is 8')
        // .required('Assign number of Jobs')
        // .moreThan(0, 'Minimum number of job can assign is 1')
        // .lessThan(9, "Maximum number of job can assign is 8"),
        // .max(1 , 'Max. no. of jobs at a time is 8')
        .required('Number of Jobs')
        .moreThan(0, 'Min. no. of jobs at a time is 1')
        .lessThan(9, "Max. no. of jobs at a time is 8"),

      designation: yup
      .string()
      .required('Designation is required'),

  });  

  return (
    <div>
      <DeputyAdminHeader />
      <DeputyAdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            {/* <span className="NameLogo">sw</span> */}
            <h2>Edit Internal Editor</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
                <Formik
                    enableReinitialize
                    initialValues={{
                        id: showInternalData && showInternalData.id,
                        first_name: showInternalData && showInternalData.first_name,
                        last_name: showInternalData && showInternalData.last_name,
                        ph_no: showInternalData && showInternalData.ph_no,
                        email_address: showInternalData && showInternalData.email_address,
                        address: showInternalData && showInternalData.address,
                        city: showInternalData && showInternalData.city,
                        state: showInternalData && showInternalData.state,
                        zipcode: showInternalData && showInternalData.zipcode,
                        country: showInternalData && showInternalData.country,
                        // organization: showInternalData && showInternalData.organization,
                        assigned_number_of_job: showInternalData && showInternalData.assigned_number_of_job? showInternalData.assigned_number_of_job:'0',
                        designation: showInternalData && showInternalData.designation,
                      }}
                    onSubmit={(values,{resetForm})=>{updateInternalData(values,{resetForm})}}
                    validationSchema = {schema}
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        values, 
                        setFieldValue
                    })=>(
                    <div className="AccBox">
                        <div className="pagetitle">
                        <h3>Personal Details</h3>
                        {/* <a href="#" className="btn btn-primary CmnBtn">
                            <i className="bi bi-check-circle" /> Save
                        </a> */}
                        {loading && loading ? (
                                <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i class="fa fa-refresh fa-spin"></i>Update</Button>
                            ):(
                                <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Update</Button>
                            )
                        }
                        </div>
                        <ul className="AccDtls EditAccDtls addEditorForm row">
                            <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>First Name</label>
                                {/* <input className="InptField" type="text" defaultValue="Sam" /> */}
                                <input type="text" className="InptField" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} value={values.first_name}/>
                              </div>
                              {errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                            </li>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Last Name</label>
                                {/* <input className="InptField" type="text" defaultValue="Williams" /> */}
                                <input type="text" className="InptField" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name}/>
                              </div>
                              {errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                            </li>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Phone Number</label>
                                {/* <input className="InptField" type="tel" defaultValue={9806963456} /> */}
                                <input type="number" className="InptField" id="phoneInput" placeholder="Phone Number" name="ph_no"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                                </div>
                              {errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                            </li>

                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Email Address</label>
                                {/* <input className="InptField" type="email" defaultValue="sam@_williams.gmail.com" /> */}
                                <input type="email" className="InptField" id="emailInput" placeholder="Email Address" name="email_address" disabled onChange={handleChange} value={values.email_address}/>
                              </div>
                              {errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                            </li>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Street Address</label>
                                {/* <input className="InptField" type="text" defaultValue="Sam" /> */}
                                <input type="text" className="InptField" name="address" placeholder="Street Address" onChange={handleChange} value={values.address}/>
                              </div>
                              {errors.address ? <div className='error'>{errors.address}</div> : null}
                            </li>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>City</label>
                                {/* <input className="InptField" type="text" defaultValue="Sam" /> */}
                                <input type="text" className="InptField" name="city" placeholder="City" onChange={handleChange} value={values.city}/>
                              </div>
                              {errors.city ? <div className='error'>{errors.city}</div> : null}
                            </li>

                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>State</label>
                                {/* <input className="InptField" type="text" defaultValue="Sam" /> */}
                                <input type="text" className="InptField" name="state" placeholder="State" onChange={handleChange} value={values.state}/>
                              </div>
                              {errors.state ? <div className='error'>{errors.state}</div> : null}
                            </li>

                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Zip Code</label>
                                {/* <input className="InptField" type="text" defaultValue="United States of America" /> */}
                                <input type="text" className="InptField" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                              </div>
                              {errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                            </li>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Country</label>
                                {/* <input className="InptField" type="text" defaultValue="United States of America" /> */}
                                {/* <input type="text" className="InptField" id="country" placeholder="Country" name="country" onChange={handleChange} value={values.country}/> */}
                                <select class="select-input" id="selectCountry" aria-label="Floating label select example" name="country" onChange={handleChange} value={values.country}>
                                    <option selected hidden>Country</option>
                                    {showCountryList && showCountryList.map((item,index)=>{
                                        return(
                                            <option value={item?.country_name}>{item?.country_name}</option>
                                        )
                                    })}
                                </select>
                              </div>
                            </li>

                            {/* <li className="col-md-6">
                              <div className="small-input-box">
                                <input className="InptField" type="text" defaultValue="Cultural Organization" /> 
                                <input type="text" className="InptField" id="nameInput" placeholder="Name of Organization/Affiliation" name="organization" onChange={handleChange} value={values.organization} />
                              </div>
                              {errors.organization ? <div className='error'>{errors.organization}</div> : null}
                            </li>*/}
                            {/*<li className="col-md-6">
                              <div className="small-input-box">
                                 <input className="InptField" type="text" defaultValue="629 N. Shady Road" /> 
                                <input type="text" className="InptField" id="addressInput" placeholder="Address" name="address" onChange={handleChange} value={values.address}/>
                              </div>
                              {errors.address ? <div className='error'>{errors.address}</div> : null}
                            </li>*/}
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Number of Assigned Jobs</label>
                                {/* <input className="InptField" type="text" defaultValue={30188} /> */}
                                <input type="number" className="InptField" id="assigned_number_of_job" placeholder="Number of Jobs" name="assigned_number_of_job" onChange={handleChange} value={values.assigned_number_of_job}/>
                              </div>
                              {errors.assigned_number_of_job ? <div className='error'>{errors.assigned_number_of_job}</div> : null}
                            </li>
                            <li className="col-md-3">
                              <div className="small-input-box">
                                <label>Designation</label>
                                {/* <input className="InptField" type="text" defaultValue="United States of America" /> */}
                                <input type="text" className="InptField" placeholder="Designation" name="designation" onChange={handleChange} value={values.designation}/>
                              </div>
                            </li>
                            
                            {/* <li className="col-md-3"> */}
                            {/* <input className="InptField" type="text" defaultValue="Woodstock" /> */}
                            {/* <input type="password" className="InptField" id="passwordInput" placeholder="Password" name="password" onChange={handleChange} value={values.password}/> */}
                            {/* {errors.password ? <div className='error'>{errors.password}</div> : null} */}
                            {/* </li> */}
                            {/* <li className="col-md-3"> */}
                            {/* <input className="InptField" type="text" defaultValue={30188} /> */}
                            {/* <input type="password" className="InptField" id="confirmInput" placeholder="Confirm Password" name="cpassword" onChange={handleChange} value={values.cpassword}/> */}
                            {/* {errors.cpassword ? <div className='error'>{errors.cpassword}</div> : null} */}
                            {/* </li> */}

                        </ul>
                    </div>
                    )}
           </Formik>
          {/* <div className="AccBox">
            <div className="pagetitle">
              <h3>Password</h3>
              <NavLink to="/editor-my-account/editor-edit-password" className="btn btn-primary CmnBtn">
                <i className="bi bi-pencil" /> Edit Password
              </NavLink>
            </div>
            <ul className="AccDtls">
              <li>
                <label>Password</label>
                <p className="InputTxt">Sam@123456</p>
              </li>
            </ul>
          </div> */}
        </section>
      </main>
    </div>
  );
};

export default EditInternalByDeputy;
