import React, {Component, useState, useEffect} from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Accordion from 'react-bootstrap/Accordion';
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";

const faqBanner = require("../../assets/img/faq-banner.jpg");

const Faq = () => { 
    useEffect(() => {
        AOS.init();
    });
    return(
        <div>
            <Header />
            <div className="inner-banner" style={{backgroundImage: `url(${faqBanner})`}}>
                <div className="container">
                    <h3 data-aos="fade-up">Frequently Asked Questions</h3>
                    <p data-aos="fade-up">We place great diligence on every work submitted. You can alway scount on us to achieve the best outcome.</p>
                </div>
            </div>
            <div className="faqBody">
                <div className="container">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q. Are my details secure on your website?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                    Elite Reviser uses a secure 256-bit SSL encryption, which is an industry standard, to
                                    encrypt and decrypt data transferred between your browser and our website server.
                                    Whenever you pay with your credit card, our servers do not save your card details.
                                    So, you can do business with us worry-free.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q. What are the options for payments?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>  
                                    To process credit card payments, the following options are available to you: Stripe,
                                    PayPal, or Alipay. We accept Visa, MasterCard, Discover, American Express, and
                                    Alipay. For certain organizations, we can accept payments via Automated Clearing
                                    House (ACH) or wire transfer.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Q. How are prices determined?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                    Our prices are determined based on the word count and turnaround time (TAT).
                                    Once your file is uploaded, our system automatically counts the number of words,
                                    and based on the TAT chosen, the price for our service will be displayed instantly.
                                    Upload only the file you want edited or proofread. If you do not want your
                                    references to be edited, do not include them in the file; otherwise, the word count for
                                    the references will be considered in the pricing.<br/>

                                    If, for some reason, your word count is different from ours after uploading, kindly
                                    bring it to our awareness before your place your order.
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q. When do I make payment for the service?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                    Before you place your order, you will be asked for payment information. Once you
                                    provide all necessary information for your order, and you submit your order, your
                                    card will be charged for the amount displayed during order process. At this point,
                                    we will start your work.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q. How will the payment show on my bill?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                Your bill will show that your payment was made to Elite Reviser.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q. Which currency are your prices based on?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                Our prices are based on US Dollars. Therefore, you will be billed in the US Dollars.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q. What is the minimum word count you can edit or proofread?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                There is no minimum, however, order with word count less than 250 words will be charged as though it were order for 250 words.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q. How soon can I cancel my order if I must?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    For a full refund, orders must be canceled within 30 mins of submission on our
                                    website. Contact us immediately if you are having problems canceling. If you cancel
                                    beyond the 30 mins, your refund will depend on the time remaining for your work
                                    to be completed.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Q. Do you check my work for plagiarism?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                    No, we do not check for plagiarism. We advise that you check for plagiarism before
                                    you place your order if you want it done. But be advised that plagiarism has a
                                    consequence. Be assured that our editors and proofreaders will do their best to give
                                    you a superior work without rewriting or rephrasing your work to maintain your
                                    voice.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Q. What types of style guides do you work with?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    We can work with all types of style guides. Whatever style guide you want, tell us in
                                    the client instructions, and, if possible, include an example for us to apply to your
                                    work.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Q. Do you take orders globally?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    Yes, we are a company that serves everyone in any part of the world.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="11">
                            <Accordion.Header>Q. What types of English version do you have editors for?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong> 
                                    We have editors for American English, Canadian English, and British English.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="12">
                            <Accordion.Header>Q. How can I be sure I am getting the best service?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                Our editors are cream of the crop in their fields of expertise with years of experience,
                                many of whom have advanced degrees. Each editor must undergo rigorous selection
                                criteria to ensure they will give their best. We offer a one-time revision, if necessary,
                                at no additional cost when we receive the request within a month after finishing
                                your work. Also, our editors will provide you with suggestions to help improve
                                your work after completion.<br/>
                                Besides, we are independently certified by ISO; and, as a member of the Better
                                Business Bureau (BBB), we comply with its Code of Business Practices and other
                                applicable regulations.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="13">
                            <Accordion.Header>Q. What if I am not satisfied with your service?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    If you are not satisfied with our service, please reach out to us immediately through
                                    our contact page, by email at contact@elitereviser.com, or by phone at +1 (469) 305
                                    1828. We will do our best to resolve the issue as quickly as possible.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="14">
                            <Accordion.Header>Q. How do I get an editor to work on my document again?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    Find the code of the editor who worked on your document on your order page
                                    under your account. If you are not successful, please contact us.
                                </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="15">
                            <Accordion.Header>Q. Do you use real human editors and proofreader, or do you use software?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    Our editors and proofreaders are real human, and they are located globally.
                                    Software, when used, serves to assist, and improve the quality of your work. Bear in
                                    mind that human editors can understand your work, even if it is not articulate. But
                                    this is not true for software.
                                </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="16">
                            <Accordion.Header>Q. I am concerned about copyright.</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                You have nothing to worry about. Your work is strictly your own, and we (including
                                our editors) have no claim on it. We receive no credit or acknowledgment
                                whatsoever. Our job is to do the service your order on our secure website.
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="17">
                            <Accordion.Header>Q. Where are you located?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    Our head office is in Dallas, Texas, in the USA. However, our editors and
                                    proofreaders are all over the world. The Google map in the contact page displays
                                    our location.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="18">
                            <Accordion.Header>Q. What are your business hours?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    Our office is open from 9:00 am to 5:00 pm CST. However, our website is always
                                    open for transactions around-the-clock. Our editors and proofreaders are on duty
                                    around-the-clock, likewise. We answer emails as soon as possible.
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="19">
                            <Accordion.Header>Q. How can I contact you?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Ans. </strong>
                                    You can get in touch with us through our contact page, by email at
                                    contact@elitereviser.com, or by phone at +1 (469) 305 1828.
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p><NavLink to="/">Elite Reviser</NavLink> can handle that. 
                            <NavLink to="/contact"> Contact Us.</NavLink>
                            </p>                      
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                            <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Faq;