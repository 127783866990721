import React, { Component } from 'react';

class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      result: '',
    };
  }

  handleButtonClick = (value) => {
    const { input } = this.state;
    this.setState({ input: input + value });
  };

  handleClear = () => {
    this.setState({ input: '', result: '' });
  };

  handleCalculate = () => {
    try {
      const { input } = this.state;
      const result = this.evaluateExpression(input);
      this.setState({ result });
    } catch (error) {
      this.setState({ result: 'Error' });
    }
  };

//   evaluateExpression = (expression) => {
//     const operators = ['+', '-', '*', '/'];
//     let numbers = [];
//     let ops = [];
//     let currentNum = '';

//     for (let i = 0; i < expression.length; i++) {
//       const char = expression[i];

//       if (operators.includes(char)) {
//         numbers.push(parseFloat(currentNum));
//         currentNum = '';
//         while (
//           ops.length > 0 &&
//           operators.indexOf(ops[ops.length - 1]) >= operators.indexOf(char)
//         ) {
//           const b = numbers.pop();
//           const a = numbers.pop();
//           const op = ops.pop();
//           numbers.push(this.calculate(a, b, op));
//         }
//         ops.push(char);
//       } else {
//         currentNum += char;
//       }
//     }

//     numbers.push(parseFloat(currentNum));

//     while (ops.length > 0) {
//       const b = numbers.pop();
//       const a = numbers.pop();
//       const op = ops.pop();
//       numbers.push(this.calculate(a, b, op));
//     }

//     if (numbers.length === 1) {
//       return numbers[0];
//     } else {
//       throw new Error('Invalid expression');
//     }
//   };




evaluateExpression = (expression) => {
    const operators = ['+', '-', '*', '/']; // Array of supported operators
    let numbers = []; // Stack to hold numbers
    let ops = []; // Stack to hold operators
    let currentNum = ''; // Variable to accumulate digits to form numbers
  
    // Iterate through each character in the expression
    for (let i = 0; i < expression.length; i++) {
      const char = expression[i];
  
      if (operators.includes(char)) {
        // If the current character is an operator
        numbers.push(parseFloat(currentNum)); // Convert the accumulated digits to a number and push it to the stack
        currentNum = ''; // Reset the currentNum variable
  
        // Process operators in the ops stack based on precedence
        while (
          ops.length > 0 &&
          operators.indexOf(ops[ops.length - 1]) >= operators.indexOf(char)
        ) {
          const b = numbers.pop(); // Pop the top two numbers from the stack
          const a = numbers.pop();
          const op = ops.pop(); // Pop the top operator from the stack
          numbers.push(this.calculate(a, b, op)); // Calculate and push the result back to the numbers stack
        }
        ops.push(char); // Push the current operator to the ops stack
      } else {
        currentNum += char; // Accumulate digits to form numbers
      }
    }
  
    numbers.push(parseFloat(currentNum)); // Push the last accumulated number to the stack
  
    // Process any remaining operators in the ops stack
    while (ops.length > 0) {
      const b = numbers.pop();
      const a = numbers.pop();
      const op = ops.pop();
      numbers.push(this.calculate(a, b, op));
    }
  
    if (numbers.length === 1) {
      return numbers[0]; // The final result is the only remaining number in the stack
    } else {
      throw new Error('Invalid expression'); // If there are more than one number, the expression is invalid
    }
  };
  
  calculate = (a, b, operator) => {
    switch (operator) {
      case '+':
        return a + b;
      case '-':
        return a - b;
      case '*':
        return a * b;
      case '/':
        if (b === 0) {
          throw new Error('Division by zero');
        }
        return a / b;
      default:
        throw new Error('Invalid operator');
    }
  };

  render() {
    const { input, result } = this.state;

    return (
      <div className="calculator">
        <div className="display">
          <input type="text" value={input} readOnly />
          <div className="result">{result}</div>
        </div>
        <div className="buttons">
          {/* Buttons for digits (0-9) */}
          <button onClick={() => this.handleButtonClick('0')}>0</button>
          <button onClick={() => this.handleButtonClick('1')}>1</button>
          <button onClick={() => this.handleButtonClick('2')}>2</button>
          <button onClick={() => this.handleButtonClick('3')}>3</button>
          <button onClick={() => this.handleButtonClick('4')}>4</button>
          <button onClick={() => this.handleButtonClick('5')}>5</button>
          <button onClick={() => this.handleButtonClick('6')}>6</button>
          <button onClick={() => this.handleButtonClick('7')}>7</button>
          <button onClick={() => this.handleButtonClick('8')}>8</button>
          <button onClick={() => this.handleButtonClick('9')}>9</button>

          {/* Buttons for operators (+, -, *, /) */}
          <button onClick={() => this.handleButtonClick('+')}>+</button>
          <button onClick={() => this.handleButtonClick('-')}>-</button>
          <button onClick={() => this.handleButtonClick('*')}>*</button>
          <button onClick={() => this.handleButtonClick('/')}>/</button>

          {/* Clear and Calculate buttons */}
          <button onClick={this.handleClear}>Clear</button>
          <button onClick={this.handleCalculate}>=</button>
        </div>
      </div>
    );
  }
}

export default Calculator;
