import {
    React, Component, useEffect, useState,
    NavLink, useNavigate, useLocation,
    Formik, Field, yup,
    axios,
    NotificationManager,
    Button,
    DateTime
} from '../../utility/CommonImport'; // Import 

import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

import { API_BASE_URL} from '../../api_url';
import { registerUser } from "../../API/apiRequests";

const signupBanner = require("../../assets/img/signup-banner.jpg");

const Signup = () => { 
    const navigate = useNavigate();
    const location = useLocation();
    let loggedUser = JSON.parse(localStorage.getItem("allFormData"));
    let data = JSON.parse(localStorage.getItem("user_data_elite"));
    // console.log(data);
    // console.log(loggedUser);
    
    const [loading,setLoading] = useState(false); 
    const [showCountryList,setShowCountryList] = useState([]); // for getting all countries
    const [showText,setShowText] = useState(true);

    useEffect(() => {
        AOS.init();
        console.log(loggedUser)
        console.log(location?.state?.from);
    });

    useEffect(()=>{
        getCountry();
    },[]);

    const signUpData = async(values,{resetForm}) =>{
        try
        {
            setLoading(true);
            let apiRes = await registerUser(values);
            if(apiRes)
            {
                if(apiRes.data.res == true) 
                {
                    NotificationManager.success('Successfully Registered','',2000);
                    if(location?.state?.from && location?.state?.from === 'service')
                    {
                        try 
                        {
                            setLoading(true);
                            let apiRes = await axios.post(`${API_BASE_URL}login/signin`, {
                              email_address: values.email_address,
                              password: values.password,
                              user_type:"notAdmin"
                            });
                            if (apiRes) 
                            {
                              console.log(apiRes.data.res);
                              if (apiRes.data.res == true) 
                              {
                                  localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
                                  console.log( JSON.stringify(apiRes.data.data[0]))
                                  navigate("/payment");
                              } 
                              else 
                              {
                                  setLoading(false);
                                  NotificationManager.error(apiRes.data.msg, "", 2000);
                              }
                            }
                        } 
                        catch (error) 
                        {
                            setLoading(false);
                            //close try
                            // NotificationManager.error('error2', "", 2000);
                        }

                    }
                    else
                    {
                        console.log(loggedUser);
                        setLoading(false);
                        resetForm({values:''});
                        navigate('/login');
                    } 
                }
                else
                {
                    setLoading(false);
                    NotificationManager.error(apiRes.data.msg, '',2000);
                    // NotificationManager.error('error3', '',2000);
                }
            }
            else
            {
                setLoading(false);
            }
        }
        catch (error) 
        {
            // NotificationManager.error(error, '',2000);
        } 
    }
  
    let schema = yup.object().shape({
  
        first_name: yup
        .string()
        .required('First name is required'),
  
        last_name: yup
        .string()
        .required('Last name is required'),
    
        ph_no: yup
        .string()
        // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        // .matches(/[6-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{3}/, {
        // .matches(/[6-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{3}/, {
        //   message: "Invalid number",
        //   excludeEmptyString: false,
        // })
        .required('Phone number is required')
        .max(10,'Invalid Phone number'),

        email_address: yup
        .string()
        .required('Email is required')
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
          
        address: yup
        .string()
        .required('Address is required'),

        zipcode: yup
        .string()
        .required('Zip Code is required'),

        country: yup
        .string()
        .required('Country is required'),

        state: yup
        .string()
        .required('State is required'),

        password: yup
        .string()
        .required('Password is required')
        .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.')
        .min(8)
        .max(20),

        cpassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'),null],'Passwords did not match')
        
    });  

//get country list
const getCountry =async() =>
{
    try
    {
        let apiRes = await axios.get(`${API_BASE_URL}login/countries`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              console.log(apiRes.data.data);
              setShowCountryList(apiRes.data.data);
            }
        }
    }//close try
    catch (error) 
    {
        // NotificationManager.error(error.message, "", 2000);
    }   
}

    return(
        <div>
            <Header />
            <div className="inner-small-banner" style={{backgroundImage: `url(${signupBanner})`}}>
                <div className="container">
                    <h3 data-aos="fade-up">Create Your Account</h3>
                </div>
            </div>
            <Formik
                    // enableReinitialize
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        ph_no: '',
                        email_address: '',
                        address: '',
                        zipcode: '',
                        country: '',
                        state: '',
                        city: '',
                        password: '',
                        cpassword: '',
                    }}
                    onSubmit={(values,{resetForm})=>{signUpData(values,{resetForm})}}
                    validationSchema = {schema}
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        values, 
                        touched,
                        setFieldValue
                    })=>(
            <div className="signup-body">
                <div className="container">
                    <h3>Sign Up</h3>
                    {/* <Form id="myFormRef"> */}
                    <ul className="row signup-list">
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} value={values.first_name}/>
                                <label for="firstInput"><span>*</span>First Name</label>
                            </div>
                            {touched.first_name && errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                        </li>
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name}/>
                                <label for="lastInput"><span>*</span>Last Name</label>
                            </div>
                            {touched.last_name && errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                        </li>
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="number" className="form-control" id="phoneInput" placeholder="Phone Number" name="ph_no" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                                <label for="phoneInput"><span>*</span>Phone Number</label>
                            </div>
                            {touched.ph_no && errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                        </li>
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="email" className="form-control" id="emailInput" placeholder="Email Address" name="email_address" onChange={handleChange} value={values.email_address}/>
                                <label for="emailInput"><span>*</span>Email Address</label>
                            </div>
                            {touched.email_address && errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                        </li>
                        
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="addressInput" placeholder="Address" name="address" onChange={handleChange} value={values.address}/>
                                <label for="nameInput"><span>*</span>Address</label>
                            </div>
                            {touched.address && errors.address ? <div className='error'>{errors.address}</div> : null}
                        </li>

                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="nameInput" placeholder="State" name="state" onChange={handleChange} value={values.state} />
                                <label for="nameInput"><span>*</span>State</label>
                            </div>
                            {touched.state && errors.state ? <div className='error'>{errors.state}</div> : null}
                        </li>

                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="nameInput" placeholder="City" name="city" onChange={handleChange} value={values.city} />
                                <label for="nameInput"><span>*</span>City</label>
                            </div>
                            {touched.city && errors.city ? <div className='error'>{errors.city}</div> : null}
                        </li>
                        
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                                <label for="zipInput"><span>*</span>Zip Code</label>
                            </div>
                            {touched.zipcode && errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                        </li>
                        {/* <li className="col-md-6">
                            <div class="form-floating">
                                <select class="form-select" id="selectCountry" aria-label="Floating label select example" name="country" onChange={handleChange} value={values.country}>
                                    <option selected hidden>Country</option>
                                    {showCountryList && showCountryList.map((item,index)=>{
                                        return(
                                            <option value={item?.country_name}>{item?.country_name}</option>
                                        )
                                    })}
                                </select>
                                 <label for="floatingSelect"><span>*</span> Select Country</label>
                            </div>
                            {errors.country ? <div className='error'>{errors.country}</div> : null}
                        </li> */}
						<li className="col-md-6">
                            <div className="form-floating">

                                <input type="text" className="select-input" value={values.country}/>
                                
                                {showText &&(
                                    <span className="span-alert">* <span>Country</span></span>
                                )}
                                
                                <select className="form-select" id="selectCountry" aria-label="Floating label select example" name="country" onChange={(e)=>{handleChange(e);setShowText(false)}} value={values.country}>
                                    <option selected disabled>Country</option>
                                    {showCountryList && showCountryList.map((item,index)=>{
                                        return(
                                            <option value={item?.country_name}>{item?.country_name}</option>
                                        )
                                    })}
                                </select>

                                 {/* <label for="floatingSelect"><span>*</span> Select Country</label> */}
                            </div>
                            {touched.country && errors.country ? <div className='error'>{errors.country}</div> : null}
                            {/* {errors.country && (countryName=='' || countryName== undefined)? <div className='error'><p>Country Name is required</p></div> : null} */}
                        </li>
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="password" className="form-control" id="passwordInput" placeholder="Password" name="password" onChange={handleChange} value={values.password}/>
                                <label for="passwordInput"><span>*</span>Password</label>
                            </div>
                            {touched.password && errors.password ? <div className='error'>{errors.password}</div> : null}
                        </li>
                        <li className="col-md-6">
                            <div className="form-floating">
                                <input type="password" className="form-control" id="confirmInput" placeholder="Confirm Password" name="cpassword" onChange={handleChange} value={values.cpassword}/>
                                <label for="confirmInput"><span>*</span>Confirm Password</label>
                            </div>
                            {touched.cpassword && errors.cpassword ? <div className='error'>{errors.cpassword}</div> : null}
                        </li>
                        
                        <li className="col-md-12">
                            {/* <input type="submit" name="" value="Sign Up" className="btn btn-primary"/>  */}
                            {loading && loading ? (
                                <Button className="btn btn-primary" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Sign Up</Button>
                            ):(
                                <Button className="btn btn-primary" type="submit"  onClick={()=>handleSubmit()}>Sign Up</Button>
                                // <></>
                            )}
                            
                            <span className="or-span">Or</span>
                            <NavLink to="/login" className="signin-link">Sign In</NavLink>
                        </li>
                    </ul>
                    {/* </Form> */}
                </div>
            </div>
            )}
            </Formik>
            
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p><NavLink to="/">Elite Reviser</NavLink> can handle that. 
                            <NavLink to="/contact"> Contact Us.</NavLink></p>                      
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                            <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Signup;