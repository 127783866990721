import React, {Component, useState, useEffect} from "react";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Form from 'react-bootstrap/Form';
import $ from "jquery";
import AOS from "aos";
import Select from 'react-select';
import "aos/dist/aos.css";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { saveAs} from 'file-saver';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

const servicesBanner = require("../../assets/img/services-banner-min.jpg");
const docsImg = require("../../assets/img/docs-img.png");
const pdfImg = require("../../assets/img/pdf.png");
const crossImg = require("../../assets/img/red-cross-icon.png");
const ckEditorImg = require("../../assets/img/ck-editor-img.png");

const Serviceschoosecv = () => {
    const navigate = useNavigate();
    const {id,name} = useParams();
    const location = useLocation();
    console.log(location.pathname);
    // console.log(id);
    let data= JSON.parse(localStorage.getItem("user_data_elite"));
    console.log(data);
    const [userData,setUserData] = useState(data);
    const [serviceInfo,setServiceInfo] = useState(); // service info
    const [subService,setSubService] = useState([]); // used to store subservices
    const [serviceFile,setServiceFile] = useState([]); // service file upload
    const [serviceFileNames,setServiceFileNames] = useState(); // service file name
    const [instructionFile,setInstructionFile] = useState([]); // service file upload
    const [instructionFileNames,setInstructionFileNames] = useState([]); // service file name
    const [loading,setLoading] = useState(false); // this is for loader
    const [loading1,setLoading1] = useState(false); // this is for loader1
    const [showLoaderForPrice,setShowLoaderForPrice] = useState(false); // Price Calculation for RESUME/CV Per Pages

    const [progress,setProgress] = useState(0); // this is for loader
    const [countDetails,setCountDetails] = useState(); // for count information

    const [selectedSubService,setSelectedSubService] = useState(); // for Selected sub service
    // const [selectedSubServiceName,setSelectedSubServiceName] = useState(); // for Selected sub service name
    const [selectedVersion,setSelectedVersion] = useState(); // for Selected version
    const [service,setService] = useState(); // for selected service
    const [serviceType,setServiceType] = useState(); // set service type
    const [editorData,setEditorData] = useState(); // for CK Editor Data
    const [autoData,setAutoData] = useState(false);


    const [showError,setShowError] = useState(false); // for all fields validation
    const [fileDlt,setFileDlt] = useState();
    const [insFileDelete,setInsFileDelete] = useState();


    //multiple file upload instruction
    const [insFile,setInsFile] = useState([]);
    const [insFileNames,setInsFileNames] = useState([]);


    const [selectedOption,setSelectedOption] = useState();
    const [serviceName,setServiceName] = useState();
    const [isSelected,setIsSelected] = useState(0);
    const [isPageSelect,setIsPageSelect] = useState(0);
    const [calculatePrice,setCalculatePrice] = useState();
    const [showLevel,setShowLevel] = useState('Regular Service');
    const [showIFile,setShowIFile] = useState(false);

    const [mergeServiceFile,setMergeServiceFile] = useState([]); // This is for merge service file
    const [mergeServiceFullFileNames,setMergeServiceFullFileNames] = useState([]); // This is for merge service file full name

    const [instructFile,setInstructFile] = useState([]); // store instruct file
    const [instructionFullFileNames,setInstructionFullFileNames] = useState([]); // instruction file names

    const [isEmailExist,setIsEmailExist] = useState('');
    const [isPhoneExist,setIsPhoneExist] = useState('');
    const [showClientData,setShowClientData] = useState(); // for editor info
    const [userDetails, setUserDetails] = useState(false);  //modal
    const userDetailsClose = () => setUserDetails(false);   //modal-close
    useEffect(() => {
        // if (data=== null)
        // {
        //     navigate("/login");
        //     navigate(0);
        // }

        // if(data!=null && (data?.role == '0'))
        // {
        //   navigate("/admin-job-board");
        // }
        // else if(data!=null && (data?.role == '1'))
        // {
        //   navigate("/editor-job-board");
        // }
        // else if(data!=null && (data?.role == '3'))
        // {
        //   navigate("/deputy-admin-job-board");
        // }
        // else if(data!=null && (data?.role == '4'))
        // {
        //   navigate("/internal-editor-job-board");
        // }

        
        if(data!=null && (data?.role == '1'))
        {
          navigate("/editor-job-board");
        }
    })
    
    const handleChange = async(selectedOption) => {
        console.warn(selectedOption);
        setSelectedOption(selectedOption);
        setIsPageSelect(1);
        setShowLoaderForPrice(true);
        try
        {
            // let apiRes = await axios.get(`${API_BASE_URL}cvresume/getprice?number_of_pages=${selectedOption.value}`)
            let apiRes = await axios.get(`${API_BASE_URL}job/getprice?number_of_pages=${selectedOption.value}`)
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                    console.log(apiRes.data.data);
                    setCalculatePrice(apiRes.data.data);
                }
            }
            setShowLoaderForPrice(false);
        } 
        catch (error) 
        {
            setShowLoaderForPrice(false);
            // NotificationManager.error(error.message, "", 2000); 
        }
    };
    // const handleChange2 = (selectedOption2) => {
    //   setSelectedOption2(selectedOption2)
    // };
    const options = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      
    ];

    const Resumeoptions = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      
    ];
    const CVoptions = [
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' },
      { value: '13', label: '13' },
      { value: '14', label: '14' },
      { value: '15', label: '15' },
      { value: '16', label: '16' },
      { value: '17', label: '17' },
      { value: '18', label: '18' },
      { value: '19', label: '19' },
      { value: '20', label: '20' },
      { value: '21', label: '21' },
      { value: '22', label: '22' },
      { value: '23', label: '23' },
      { value: '24', label: '24' },
      { value: '25', label: '25' },
      { value: '26', label: '26' },
      { value: '27', label: '27' },
      { value: '28', label: '28' },
      { value: '29', label: '29' },
      { value: '30', label: '30' },
      { value: '31', label: '31' },
      { value: '32', label: '32' },
      { value: '33', label: '33' },
      { value: '34', label: '34' },
      { value: '35', label: '35' },
      { value: '36', label: '36' },
      { value: '37', label: '37' },
      { value: '38', label: '38' },
      { value: '39', label: '39' },
      { value: '40', label: '40' },
      { value: '41', label: '41' },
      { value: '42', label: '42' },
      { value: '43', label: '43' },
      { value: '44', label: '44' },
      { value: '45', label: '45' },
      { value: '46', label: '46' },
      { value: '47', label: '47' },
      { value: '48', label: '48' },
      { value: '49', label: '49' },
      { value: '50', label: '50' },
    ];
    // const ResumePages = [1,2,3,4];
    // const CvPages = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50];

    useEffect(() => {
        AOS.init();
        console.log(serviceName)
        // console.log(calculatePrice)
    });

    useEffect(() => 
    {
        // getServiceDetailsById();
        console.warn(mergeServiceFullFileNames)
    }, [mergeServiceFullFileNames]);

    useEffect(()=>{
        getClientInfos();
    },[]);

  // get service deatils by id
//   const getServiceDetailsById = async () => {
//     try {
//     let apiRes = await axios.get(`${API_BASE_URL}service/servicedetail?id=${id}`)
//     if (apiRes) 
//     {
//         console.log(apiRes.data.res);
//         if (apiRes.data.res == true) 
//         {
//           console.log(apiRes.data.data);
//           setServiceInfo(apiRes.data.data[0]);
//           setSubService(apiRes.data.data[0].subservice);
          
//         }
//     }
//     } catch (error) {
//     //close try
//     NotificationManager.error(error, "", 2000);
//     }
// }

//file download
const downloadFile = (item) =>{{
    saveAs(item);
  }
}

//Message
const handleMessage =(val) =>{
    if( val == 1 )
    {
        NotificationManager.warning('Add address and contact number to place the order', "", 4000);
        setUserDetails(true);
    }
    else if( val == 2 )
    {
        NotificationManager.warning('Only Client have an access to place the order', "", 4000);
    }
}

// get editor information
const getClientInfos = async () => {
    console.warn(showClientData)
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${data?.id}`) 
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          // console.log(apiRes.data.data);
          setShowClientData(apiRes.data.data[0]);
          setUserData(apiRes.data.data[0]);
        //   localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
        }
      }
    } catch (error) {
      //close try
      // NotificationManager.error(error, "", 2000);
    }
  };

const updateEditorData = async(values,{resetForm}) =>{
    console.log(values);
      try {
          setLoading(true);
          let apiRes = await(
              axios
              .post(
              `${API_BASE_URL}account/editaccount`,{
                  id: values.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  ph_no: values.ph_no.toString(),
                  email_address: values.email_address,
                  state: values.state,
                  address: values.address,
                  zipcode: values.zipcode,
                  password: values.password,
                  role: '2'  // 2 for Client
              },
          )
          )
          if(apiRes)
          {
              console.log(apiRes.data.res)
              if(apiRes.data.res == true) 
              {
                  NotificationManager.success('Update Successfully','',2000);
                  getClientInfos();
                  setLoading(false);
                  userDetailsClose();
                //   navigate("/client-my-account");
                  // resetForm({values:''})
              }else
              {
                  setLoading(false);
                  NotificationManager.error(apiRes.data.msg, '',2000);
              }
          }
      }
      catch (error) 
      {
          // NotificationManager.error(error, '',2000);
      } 
  }

  let schema = yup.object().shape({

      first_name: yup
      .string()
      // .ensure()
      .nullable('First name is required')
      .required('First name is required'),

      last_name: yup
      .string()
      .nullable('Last name is required')
      // .ensure()
      .required('Last name is required'),
  
      ph_no: yup
      .string()
      .nullable('Phone number is required')
      .required('Phone number is required')
      .max(10,'Invalid Phone number'),

      email_address: yup
      .string()
      .nullable('Email is required')
      .required('Email is required')
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
        
      address: yup
      .string()
      .nullable('Address is required')
      .required('Address is required'),

      state: yup
      .string()
      .nullable('State is required')
      .required('State is required'),

      zipcode: yup
      .string()
      .nullable('Zip Code is required')
      .required('Zip Code is required'),
  }); 

//delete file Service Multiple
const deleteFile= async (i,itm) =>{
    let file_name = itm?.slice(51);
    if (window.confirm("Are you sure you want to delete?"))
    {
        try
        {
            let apiRes = await axios.get(`${API_BASE_URL}service/removefiles?service_filename=${file_name}&total_count=${countDetails?.count}`)
            if (apiRes) 
            {
                if (apiRes.data.res == true) 
                {
                    let newArr = mergeServiceFile.filter((item,index)=>index != i);
                    let newArr1 = mergeServiceFullFileNames.filter((item,index)=>index != i);
                    setMergeServiceFile(newArr);
                    setMergeServiceFullFileNames(newArr1);
                    NotificationManager.success('File deleted successfully', "", 2000);
                }
                else
                {
                    NotificationManager.success('Something went wrong!', "", 2000);
                }
            }
            else
            {
                console.warn(apiRes);
            }
        }   
        catch (error)
        {
            NotificationManager.error(error.message, "", 2000);
        }
    }
}

//delete file Instruction Multiple
const deleteInsFile= async (i,itm) =>{
    let file_name = itm?.slice(51);
    if (window.confirm("Are you sure you want to delete?"))
    {
        try
        {
            let apiRes = await axios.get(`${API_BASE_URL}service/removeinstructionfiles?instruction_filename=${file_name}`)
            if (apiRes) 
            {
                if (apiRes.data.res == true) 
                {

                    let newArr = instructFile.filter((item,index)=>index != i);
                    let newArr1 = instructionFullFileNames.filter((item,index)=>index != i);
                    setInstructFile(newArr);
                    setInstructionFullFileNames(newArr1);
                    NotificationManager.success('File deleted successfully', "", 2000);
                }
                else
                {
                    NotificationManager.success('Something went wrong!', "", 2000);
                }
            }
            else
            {
                console.warn(apiRes);
            }
        }   
        catch (error)
        {
            NotificationManager.error(error.message, "", 2000);
        }
    }
}

const editorInstructionData = (data) =>{
    
    // let text = "Test hellosumit@gmail.com Hi bye1@3";
    // let mobileRegEx = '/[0][4-5][0-9]{8}/g';
    
    // let result = data.match("@");
    // let result = data.match(
    //     /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/
    //   );
    // var matches = data.match(/\d+/g);
    
    // result == null => 'Not Match'
    // (matches != null)=>('number');
    
    
      // Regular expression pattern for email validation
    //   var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
      var emailPattern1 = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]/;
    //   var phonePattern = /\d{9}/;
    //   var phonePattern1 = /\d{5}\s*\d{5}/;
    //   var phonePattern2 = /\d+\s*\d+/;
    
        var pattern_phone1 = /\b(?:\d{3}[-.\s]??\d{3}[-.\s]??\d{4}|\d{3}[-.\s]??\d{4})\b/;
        var pattern_phone2 = '/\d{10}/';
        var pattern_phone3 = '/\d{5}\s*\d{5}/';
        var pattern_phone4 = /\+\d{2}\s\d{2,}(?:\s*\d+)+/;
        var pattern_phone5 = /\b\d{5}\s*\d{6}\b/;
        var pattern_phone6 = /\b\d{9}\b/;
        var pattern_phone7 = /\b\+?\d+(?:-\d+){2,}\b/;
        var pattern_phone8 = /\b\d{3}(?:-?\d{3}){2}\b|\b\d{10}\b/;
        var pattern_phone9 = /\b\d{3}(?:-?\d{3}){2}\b|\b\d{14}\b/;
    
      // Find all email addresses in the content using the pattern
      var emails = data.match(emailPattern);
    //   var phones = data.match(phonePattern);
    //   var phones1 = data.match(phonePattern1);
    //   var phones2 = data.match(phonePattern2);
      var phone1 = data.match(pattern_phone1);
      var phone2 = data.match(pattern_phone2);
      var phone3 = data.match(pattern_phone3);
      var phone4 = data.match(pattern_phone4);
      var phone5 = data.match(pattern_phone5);
      var phone6 = data.match(pattern_phone6);
      var phone7 = data.match(pattern_phone7);
      var phone8 = data.match(pattern_phone8);
      var phone9 = data.match(pattern_phone9);
    
    
      var emails1 = data.match(emailPattern1);
    // console.log(emails1)
        if(emails!= null)
        {
            setIsEmailExist('yes');
            setIsPhoneExist('no');
            console.log(1);
        }
        else if(emails1!= null)
        {
            setIsEmailExist('yes');
            setIsPhoneExist('no');
            console.log(2);
        }
        else if(phone1!=null || 
            phone2!=null || 
            phone3!=null || 
            phone4!=null ||
            phone5!=null ||
            phone6!=null ||
            phone7!=null ||
            phone8!=null ||
            phone9!=null)
            {
                setIsPhoneExist('yes');
                setIsEmailExist('no');
            }
        // else if(phones!= null)
        // {
        //    setIsPhoneExist('yes');
        //    setIsEmailExist('no');
        // // console.log(emails)
        // // console.log(emails1)
        //     console.log(3);
        // }
        // else if(phones1!= null)
        // {
        //    setIsPhoneExist('yes');
        //    setIsEmailExist('no');
        //    console.log(4);
        // }
        // else if(phones2!= null)
        // {
        //    setIsPhoneExist('yes');
        //    setIsEmailExist('no');
        //    console.log(5);
        //    console.log(phones)
        // }
        // else if(emails!= null && phones!= null || phones1!= null || phones2!= null)
        // {
        //     setIsPhoneExist('yes');
        //     setIsEmailExist('yes');
        //     console.log(6);
    
        // }
        else
        {
            setIsPhoneExist('no');
            setIsEmailExist('no');
            console.log(7);
    
        }
    
        setEditorData(data);
    //   else
    //   {
    //     // No valid email found or content is empty
    //     console.log('No valid email and phone no found.');
    //     // NotificationManager.warning('Instruction contains emails or digits', "", 2000);
    //   }
    
    //   return
    // if((result == null) )
    // {
    //     // console.warn('Matched');
    //     setValidMatch(1);
    //     setEditorData(data);
    // }
    // else
    // {
    //     NotificationManager.warning('Instruction contains @ or digits', "", 2000);
    //     setValidMatch(2);
    // 	// console.warn('Not Matched');
    //     setEditorData(data);
    // }
    // console.warn(result)
    
}

//delete instruction file
const deleteInstructionFile= async (instructionFileName) =>{
    // alert(instructionFileName)
    try
    {
        let apiRes = await axios.get(`${API_BASE_URL}job/removeinstructionfile?instruction_filename=${instructionFileName}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                setInsFileDelete('');
                setInstructionFile('');
                setInstructionFileNames('');
                NotificationManager.success('File deleted successfully', "", 2000);
            }
        }
    } 
    catch (error) 
    {
        NotificationManager.error(error, "", 2000);
    }
}


const notLoginSubmit = async () => {
    // alert(serviceName)
    let error= 0;
    // if(serviceName==undefined && selectedOption?.value==undefined && calculatePrice== undefined && countDetails?.service_filename==undefined && editorData==undefined && instructFile==undefined)
    if(serviceName==undefined && selectedOption?.value==undefined && calculatePrice== undefined && mergeServiceFullFileNames.length==undefined)
    {
        // alert(1)
        error = 0;
        NotificationManager.error('All fields are Blank!', '',2000); 
        setShowError(true);
    }
    // else if(serviceName==undefined ||selectedOption?.value==undefined || calculatePrice== undefined || countDetails?.service_filename==undefined || editorData==undefined || instructFile==undefined)
    else if(serviceName==undefined ||selectedOption?.value==undefined || calculatePrice== undefined || mergeServiceFullFileNames==undefined)
    {
        // alert(2)
        error = 1;
        NotificationManager.error('Blank field is not allowed!', '',2000);
        setShowError(true);
    }
    else
    {
        if(isEmailExist === 'yes')
        {
            NotificationManager.warning('Please remove email address from instruction', '',2000);
        }
        else if(isPhoneExist === 'yes')
        {
            NotificationManager.warning('Please remove phone number from instruction', '',2000);
        }
        else if(isEmailExist === 'yes' && isPhoneExist === 'yes')
        {
            NotificationManager.warning('Please remove email address and phone number from instruction', '',2000);
        }
        else
        {
            localStorage.removeItem("allFormData");
        
            var service_type = 2;
            console.log(service)
            if(showLevel === 'Regular Service')
            {
                service_type = 0;
            }
            else if (showLevel === 'Expedited Service') 
            {
                service_type = 1;
            }
            
            const collectdData = {
                id: null,
                service_name: serviceName,
                sub_service: null,
                english_version: null,
                pages: selectedOption?.value,
                price: calculatePrice,
                service_filename: mergeServiceFullFileNames,
                total_wordcount: countDetails?.count,
                total_price: countDetails?.expeditedprice,
                // service_hour: service && service,
                service_hour: '1 Week',
                service_type: service_type,
                client_instruction: editorData && editorData ? editorData:'null',
                instruction_filename: instructionFullFileNames,
                full_description: serviceInfo?.full_description,
                from_count: countDetails?.from_count,
                to_count: countDetails?.to_count,
                link: 'cvtype',
                job_type: 'type2'
            }
              localStorage.setItem('allFormData', JSON.stringify(collectdData));
              
              navigate('/login', { state: { from: 'servicecv'}});
        }
    }
}

const LoggedSubmit = async () => {
    // alert(serviceName)
    let error= 0;
    if(serviceName==undefined && selectedOption?.value==undefined && calculatePrice== undefined && countDetails?.service_filename==undefined)
    {
        // alert(1)
        error = 0;
        NotificationManager.error('All fields are Blank!', '',2000); 
        setShowError(true);
    }
    else if(serviceName==undefined ||selectedOption?.value==undefined || calculatePrice== undefined || countDetails?.service_filename==undefined)
    {
        // alert(2)
        error = 1;
        NotificationManager.error('Blank field is not allowed!', '',2000);
        setShowError(true);
    }
    else
    {
        if(isEmailExist === 'yes')
        {
            NotificationManager.warning('Please remove email address from instruction', '',2000);
        }
        else if(isPhoneExist === 'yes')
        {
            NotificationManager.warning('Please remove phone number from instruction', '',2000);
        }
        else if(isEmailExist === 'yes' && isPhoneExist === 'yes')
        {
            NotificationManager.warning('Please remove email address and phone number from instruction', '',2000);
        }
        else
        {
            localStorage.removeItem("allFormData");
        
            var service_type = 2;
            console.log(service)
            if(showLevel === 'Regular Service')
            {
                service_type = 0;
            }
            else if (showLevel === 'Expedited Service') 
            {
                service_type = 1;
            }
            
            const collectdData = {
                id: null,
                service_name: serviceName,
                sub_service: null,
                english_version: null,
                pages: selectedOption?.value,
                price: calculatePrice,
                service_filename: mergeServiceFullFileNames,
                total_wordcount: countDetails?.count,
                total_price: countDetails?.regularprice,
                service_hour: service && service,
                service_type: service_type,
                client_instruction: editorData,
                instruction_filename: instructionFullFileNames,
                full_description: serviceInfo?.full_description,
                from_count: countDetails?.from_count,
                to_count: countDetails?.to_count,
                link: 'cvtype',
                job_type: 'type2'
            }
    
              localStorage.setItem('allFormData', JSON.stringify(collectdData));
              
              navigate('/payment', { state: { from: 'servicecv'}});
        }
    }
}

// submit all datas
const submitAllData = async() =>{
    let service_type = 2;
    // alert(1);
    if (service == 24)
    {
        service_type = 0;
        setServiceType(0);
    }
    else
    {
        service_type = 1;
        setServiceType(1);
    }
    // console.log(serviceFile);
    
            // console.log('service_id',id);
            // console.log('service_name',name);
            // console.log('sub_service',selectedSubService);
            // console.log('english_version',selectedVersion);
            // console.log('service_filename',serviceFile);
            // console.log('total_wordcount',countDetails?.count);
            // if(service == 24)
            // {
            //     // ${Math.round(Number(countDetails?.regularprice))}
            //     console.log('total_price',Math.round(Number(countDetails?.regularprice)));
            //     console.log('service_hour',service);
            //     console.log('service_type',service_type);
            //     console.log('client_instruction',editorData);
            //     console.log('instruction_filename',instructionFile);
            // }
            // else
            // {
            //     console.log('total_price',Math.round(Number(countDetails?.expeditedprice)));
            //     console.log('service_hour',service);
            //     console.log('service_type',service_type);
            //     console.log('client_instruction',editorData);
            //     console.log('instruction_filename',instructionFile);
            // }
           
    
    // navigate('/payment');
    // https://www.demoupdates.com/updates/elite/api/job?
    // userid=1&
    // service_id=1&
    // service_name=TEST&
    // sub_service=1&
    // english_version=TEST&
    // service_filename=TEST&
    // total_wordcount=213&
    // total_price=212&
    // service_hour=22&
    // service_type=1&
    // client_instruction=sxfsdfsfsf&
    // instruction_filename=erwr&
    // editor_id=3
    try {
            const formData = new FormData();
            if(service == 24)
            {
                formData.append('service_id',id);
                formData.append('service_name',name);
                formData.append('sub_service',selectedSubService);
                formData.append('english_version',selectedVersion);
                formData.append('service_filename',countDetails?.service_filename);
                // formData.append('service_filename',serviceFileNames);
                formData.append('total_wordcount',countDetails?.count);
                formData.append('total_price',Math.round(Number(countDetails?.expeditedprice)));
                formData.append('service_hour',service);
                formData.append('service_type',service_type);
                formData.append('client_instruction',editorData);
                formData.append('instruction_filename',instructFile);
                // formData.append('instruction_filename',instructionFileNames);
            }
            else
            {
                formData.append('service_id',id);
                formData.append('service_name',name);
                formData.append('sub_service',selectedSubService);
                formData.append('english_version',selectedVersion);
                formData.append('service_filename',countDetails?.service_filename);
                // formData.append('service_filename',serviceFileNames);
                formData.append('total_wordcount',countDetails?.count);
                formData.append('total_price',Math.round(Number(countDetails?.expeditedprice)));
                formData.append('service_hour',service);
                formData.append('service_type',service_type);
                formData.append('client_instruction',editorData);
                formData.append('instruction_filename',instructFile);
                // formData.append('instruction_filename',instructionFileNames);
                // formData.append('from_count',instructFile);
                // formData.append('to_count',instructFile);
            }
            
            const config = {     
                headers: { 'content-type': 'multipart/form-data' }
            }
            let apiRes = await axios.post(`${API_BASE_URL}job`,formData,config) 
        
            if (apiRes) 
            {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) 
                {
                    // console.log(apiRes.data);
                    NotificationManager.success('Job Created successfully','',3000);
                    navigate("/");
                }
            }
        }
        catch (error) 
        {
            // NotificationManager.error(error, '',2000);
        } 
    
}

//for service file delete
function deleteServiceFile()
{    
    setServiceFile('');
    setServiceFile('');
}

//for instruction file uploading
// function instructionFileHandle(e)
// {
//     setInstructionFile(e.target.files[0]);
//     setInstructionFileNames(e.target.files[0].name);
// }

//for instruction file uploading

//for service file uploading
const serviceFileHandle = async(e) =>
{
    const formData = new FormData();
    setLoading(true);
    for(let i=0;i<e.target.files.length;i++)
    {
        if((e.target.files[i].name.endsWith(".docx") || e.target.files[i].name.endsWith(".doc") || e.target.files[i].name.endsWith(".pdf")) && (e.target.files[i].type=='application/msword' || e.target.files[i].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[i].type=='application/pdf'))
        {
            formData.append('service_filename[]',e.target.files[i]);
        }
        else
        {
            setLoading(false);
            NotificationManager.error('File is not supported', '',3000);
        }
    }
    const config = {    
        headers: { 'content-type': 'multipart/form-data' },
    }
    let apiRes = await axios.post(`${API_BASE_URL}service/wordcount`,formData,config) 
    if (apiRes) 
    {
        if (apiRes.data.res == true) 
        {
            setCountDetails(apiRes.data.data[0]);
            setService(apiRes.data.data[0].regular); // for default set Regular Service Hours
            for(let i=0;i<apiRes?.data?.data[0]?.onlyFileName?.length;i++)
            {
                setMergeServiceFile(old => [...old, e.target.files[i]]);
                setMergeServiceFullFileNames(old => [...old, apiRes.data.data[0].service_filename[i]]);
            }

            // setAutoData(true);
            setLoading(false);
        }
        else
        {
            NotificationManager.error(apiRes.data.msg, '',2000);
        }
    }
};

//for instruction file uploading
const instructionFileHandle = async(e) =>
{
    const formData = new FormData();
    setLoading1(true);
    for(let i=0;i<e.target.files.length;i++)
    {
        if((e.target.files[i].name.endsWith(".docx") || e.target.files[i].name.endsWith(".doc") || e.target.files[i].name.endsWith(".pdf")) && (e.target.files[i].type=='application/msword' || e.target.files[i].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.target.files[i].type=='application/pdf'))
        {
            formData.append('instruction_filename[]',e.target.files[i]);
        }
        else
        {
            setLoading1(false);
            NotificationManager.error('File is not supported', '',3000);
        }
    }
    const config = {    
        headers: { 'content-type': 'multipart/form-data' },
    }
    let apiRes = await axios.post(`${API_BASE_URL}service/instructionfiles`,formData,config)
    if (apiRes) 
    {
        if (apiRes.data.res == true) 
        {
            for(let i=0;i<apiRes?.data?.data[0]?.onlyFileName?.length;i++)
            {
                setInstructFile(old => [...old, e.target.files[i]]);
                setInstructionFullFileNames(old => [...old, apiRes.data.data[0].instruction_filename[i]]);
            }
            setLoading1(false);
        }
        else
        {
            NotificationManager.error(apiRes.data.msg, '',2000);
        }
    }
};

//for instruction file delete
// function deleteInstructionFile()
// {    
//     setInstructionFile('');
//     setInstructionFileNames('');
// }

  // page price calculation
  const getPrice = async (pages) => {
    // alert('pages:' + pages)
    try {
    let apiRes = await axios.get(`${API_BASE_URL}cvresume/getprice?number_of_pages=${pages}`)
    if (apiRes) 
    {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          console.log(apiRes.data.data);
          setCalculatePrice(apiRes.data.data);
        }
    }
    } catch (error) {
    // NotificationManager.error(error.message, "", 2000);
    }
}

    return(
        <div>
            <Header />
            <div className="inner-banner" style={{backgroundImage: `url(${servicesBanner})`}}>
                <div className="container">
                    <h3 data-aos="fade-up">Services</h3>
                    <p data-aos="fade-up">We place great diligence on every work submitted. You can always count on us to achieve the best outcome.</p>
                </div>
            </div>
            <div className="services-sec-one">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 left-text-content">
                            <h3>RESUME</h3>
                        </div>
                        <div className="col-md-7 right-text-content">
                            <p>Do you want a resume built from scratch? We can handle that for you. We believe your dream job deserves a perfect resume. How your resume appears says a lot about you to the hiring managers; so, let us take care of that for you.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-sec-two">
                <div className="container">
                    <ul className="row service-form-list">
                        <li className="col-md-6">
                            <Form.Group>
                                {/* <Form.Select name = "subservice" onChange={(e)=>{setSelectedSubService(e.target.value)}}> */}
                                  <Form.Select name = "subservice" onChange={(e)=>{setServiceName(e.target.value);setIsSelected(1);setCalculatePrice('');setSelectedOption('')}}>
                                    <option value="noValue" selected hidden>Select Service Option</option>
                                    <option value="Resume">Resume</option>
                                    <option value="CV">CV</option>
                                  </Form.Select>
                            </Form.Group>
                            {/* {showError && <div className='error'><p>SubService is required</p></div>} */}
                            {showError && (serviceName==undefined || serviceName=='') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Service Name is required</p></div>}
                             {/* <div className='error'><p>SubService is required</p></div> */}
                        </li>
                        {/* <li className="col-md-6">
                            <Form.Group>
                              <Select value={selectedOption} onChange={handleChange} options={options} placeholder={"# of pages"} />
                            </Form.Group>
                            {showError && (selectedVersion==undefined || selectedVersion == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>English Version is required</p></div>}
                        </li> */}
                        <li className="col-md-6">
                           <div className="select-wrapper">
                                {/* {serviceName}{selectedOption?.value} */}
                                {/* sfdfsd{isSelected}{serviceName}{isPageSelect} */}
                                {isSelected && isSelected!= 0 ?(
                                    <>
                                        {serviceName && serviceName == 'Resume' &&(
                                            <Form.Group>
                                                <Select value={selectedOption} options={Resumeoptions} onChange={handleChange} placeholder='Resume Pages' />
                                            </Form.Group>
                                        )}

                                        {serviceName && serviceName == 'CV' &&(
                                            <Form.Group>
                                                <Select value={selectedOption} options={CVoptions} onChange={handleChange} placeholder='CV Pages'  />
                                            </Form.Group>
                                        )}
                                    </>
                                ):(
                                    <>
                                        <Form.Group>
                                            <Select className="form-select" placeholder='Select Number of Pages' isDisabled />
                                        </Form.Group>
                                    </>
                                )}
                                {showError && (selectedOption?.value==undefined || selectedOption?.value == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Number of Pages is required</p></div>}
                               
                            </div>
                        </li>
                       
                        <li className="col-lg-6">
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    {loading ? (
                                        <>
                                            <i class="fa fa-refresh fa-spin" style={{fontSize:'48px'}}></i>
                                            <br/>
                                        </>
                                    ):(
                                        <div className="styles-browser-sec">
                                            <input type="file" className="browseInput" id="file" multiple name = "file"  onChange={e => {serviceFileHandle(e)}}/>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-9 upload-content">
                                
                                {loading ? (
                                    <>
                                        <h4>Uploading....</h4>
                                        <p>Please wait....</p>
                                    </>
                                ):(
                                    <>
                                        <h4>Choose File</h4>
                                        <p>Preferred files are MS Word & PDF </p>
                                    </>
                                )}
                                </div>
                                <div className="col-md-12 upload-content">
                                    <ul className="service-upload-list choose-service-upload-list">
                                    {mergeServiceFullFileNames && mergeServiceFullFileNames.length>0 && mergeServiceFullFileNames.map((item,index) =>(
                                        <li>
                                            <span className="icon-span">
                                                {
                                                    item?.endsWith(".docx")?<img src={docsImg} alt="img" />:
                                                    item?.endsWith(".DOCX")?<img src={docsImg} alt="img" />:
                                                    item?.endsWith(".doc")?<img src={docsImg} alt="img" />:
                                                    item?.endsWith(".DOC")?<img src={docsImg} alt="img" />:
                                                    item?.endsWith(".pdf")?<img src={pdfImg} alt="img" />:
                                                    item?.endsWith(".PDF")?<img src={pdfImg} alt="img" />:null
                                                }
                                            </span>
                                            <span className="upload-title-span">{item?.slice(56)}</span>
                                            <span className="delete-upload-span" onClick={()=>deleteFile(index,item)}><i class="bi bi-trash"></i></span>
                                            <span className="downoad-upload-span" onClick={()=>downloadFile(item)}><i class="bi bi-download"></i></span>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                            {showError && (mergeServiceFullFileNames==undefined || mergeServiceFullFileNames == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>File is required</p></div>}
                        </li>
                        
                        <li className="col-lg-6">
                          <Form.Group controlId="formFullName">
                            {isPageSelect==1 && calculatePrice!=0?(
                                <>
                                {showLoaderForPrice? (
                                    <>
                                        {/* <i class="fa fa-refresh fa-spin" style={{fontSize:'48px'}}></i><br/>
                                        <i class="fa fa-circle-o-notch fa-spin" style={{fontSize:'35px'}}></i><br/> */}
                                        <i class="fa fa-spinner fa-spin" style={{fontSize:'30px'}}></i>Calculating Price..<br/>
                                        <div className="overlay-input-form">
                                            <span className="currency-span">$</span><Form.Control type="text" placeholder="Price"  value={calculatePrice} className="price-input"/>
                                        </div>
                                    </>
                                ):(
                                    // <Form.Control type="text" placeholder="Price" disabled/>  
                                    <>
                                        <div className="overlay-input-form">
                                            <span className="currency-span">$</span>
                                            <Form.Control type="text" placeholder="Price"  value={calculatePrice} className="price-input"/>
                                        </div>
                                    </>
                                    
                                )}
                                </>
                              
                            ):(
                                <Form.Control type="text" placeholder="Price" className="input-control price-control" disabled/>
                                
                            )}
                            
                          </Form.Group>
                          {showError && (calculatePrice==undefined || calculatePrice == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Price is required</p></div>}
                        </li>
                        <li className="col-md-12 img-sec">
                            <label>Client Instruction</label>
                            {/* <img src={ckEditorImg} alt="" /> */}
                            {/* <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                wrapperStyle={{ width: 1050, border: "1px solid black" }}
                                toolbar={{
                                    options:['inline','blockType', 'fontSize', 'fontFamily', 'list',  'history','emoji','colorPicker', 'link','remove'],
                                    textAlign: { inDropdown: true },
                                    inline :{options:['bold', 'italic', 'underline', 'strikethrough','superscript','subscript']}
                                  }}
                            /> */}
                            <CKEditor
                                editor={ ClassicEditor }
                                config={ {
                                    // toolbar: [ 'bold', 'italic', 'heading' ,'|', 'undo', 'redo']
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote',  'numberedList', 'bulletedList', 
                                              'outdent', 'indent','|', 'undo', 'redo', '|']
                                   
                                    // toolbar: {
                                    //     items: [
                                    //         'heading', '|',
                                    //         'alignment', '|',
                                    //         'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                    //         'link', '|',
                                    //         'bulletedList', 'numberedList', 'todoList',
                                    //         '-', // break point
                                    //         'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                                    //         'code', 'codeBlock', '|',
                                    //         'insertTable', '|',
                                    //         'outdent', 'indent', '|',
                                    //         'uploadImage', 'blockQuote', '|',
                                    //         'undo', 'redo'
                                    //     ],
                                    //     shouldNotGroupWhenFull: false
                                    // }
                                }}
                                
                                // data="<p>Hello from CKEditor 5!</p>"
                                // onReady={ editor => {
                                //     console.log( 'Editor is ready to use!', editor );
                                // }}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    // console.log(data);
                                    // setEditorData(data);
                                    editorInstructionData(data)
                                } }
                               
                            />
                             {/* {showError && (editorData==undefined || editorData=='') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Client Instruction is required</p></div>} */}
                        </li>
                        <li className="col-lg-6">
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    {loading1 ? (
                                        <>
                                            <i class="fa fa-refresh fa-spin" style={{fontSize:'48px'}}></i><br/>
                                            {/* <span>File Uploading....</span> */}
                                        </>
                                    ):(
                                        <div className="styles-browser-sec">
                                            {/* <input type="file" className="browseInput" name = "file"  onChange={(e) => {serviceFileHandle(e)}}/> */}
                                            {/* <input type="file" id="file" multiple name = "file" onChange={e => {fileHandle(e); setFieldValue('file',e.currentTarget.files[0]); }}/> */}
                                             <input type="file" className="browseInput" id="file" multiple name = "file"  onChange={e => {instructionFileHandle(e)}}/>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-9 upload-content">
                                    {loading1 ? (
                                        <>
                                            <h4>Uploading....</h4>
                                            <p>Please wait....</p>
                                        </>
                                    ):(
                                        <>
                                            <h4>Choose Instruction Files</h4>
                                            <p>Preferred files are MS Word & PDF </p>
                                        </>
                                    )}
                                </div>
                                <div className="col-md-12 upload-content">
                                    <ul className="service-upload-list choose-service-upload-list">
                                    {instructionFullFileNames && instructionFullFileNames.length>0 && instructionFullFileNames.map((item,index) =>(
                                        <li>
                                            <span className="icon-span">
                                                {
                                                    item.endsWith(".docx")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".DOCX")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".doc")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".DOC")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".pdf")?<img src={pdfImg} alt="img" />:
                                                    item.endsWith(".PDF")?<img src={pdfImg} alt="img" />:null
                                                }
                                            </span>
                                            <span className="upload-title-span">{item?.slice(56)}</span>
                                            <span className="delete-upload-span" onClick={()=>deleteInsFile(index,item)}><i class="bi bi-trash"></i></span>
                                            <span className="downoad-upload-span" onClick={()=>downloadFile(item)}><i class="bi bi-download"></i></span>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                            {/* {showError && (instructionFileNames==undefined || instructionFileNames == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>File is required</p></div>} */}
                        </li>

                        <li className="col-md-12 btn-list">
                            {/* <input type="submit" name="" value="Submit" className="btn btn-primary" />  */}
                            {/* <NavLink className="btn btn-primary" to="/payment">Submit</NavLink>  */}
                            {/* <Link 
                                to = {`/edit-editor/${list?.id}`}
                            > 
                            </Link> */}
                            {userData!= null || userData!=undefined ? (
                                // <Button className="btn btn-primary" type="submit"  onClick={()=>submitAllData()}>Submit</Button>
                                // <NavLink className="btn btn-primary" 
                                //     to="/payment"  
                                //     state={{from:'servicecv', path: location.pathname}}>
                                //     Submit
                                // </NavLink>

                                <>
                                    {/* {userData.role == '2' ? (
                                       <Button className="btn btn-primary" type="submit"  onClick={()=>LoggedSubmit()}>Submit</Button>
                                    ):(                                        
                                        <Button className="btn btn-primary" type="submit" onClick={()=>handleMessage()}>Submit</Button>
                                    )} */}
                                    
                                    {userData?.role == '2' && userData?.ph_no!=null && userData?.state!=null && userData?.address!=null && userData?.zipcode!=null? (
                                        <Button className="btn btn-primary" type="submit"  onClick={()=>LoggedSubmit()}>Submit</Button>
                                    ):
                                    userData?.role == '2' && (userData?.ph_no=='' || userData?.ph_no==null) && userData?.state==null && userData?.address==null && userData?.zipcode==null ?(
                                        <Button className="btn btn-primary" type="submit" onClick={()=>handleMessage(1)}>Submit</Button>
                                    ):(                                        
                                        // <Button className="btn btn-primary" type="submit" onClick={()=>handleMessage(2)}>Submit</Button>
                                        <Button className="btn btn-primary" type="submit" onClick={()=>LoggedSubmit()}>Submit</Button>
                                        // <a data-toggle="tooltip" data-placement="top" title='Only Client can place the order'>Submit</a>
                                        
                                        // <Button className="btn btn-primary" type="submit">
                                        //     <a  data-toggle="tooltip" data-placement="top" title="Only Client can place the order"></a>
                                        // </Button>
                                    )}
                                </>
                            ):(
                                // <NavLink className="btn btn-primary" 
                                //     to="/login"  
                                //     state={{from:'service', path: location.pathname}}
                                // >
                                //      Submit
                                // </NavLink>
                                <Button className="btn btn-primary" type="submit"  onClick={()=>notLoginSubmit()}>Submit</Button>
                               
                            )}
                            {/* <Button className="btn btn-primary" type="submit"  onClick={()=>submitAllData()}>Submit</Button> */}
                            {/* <NavLink className="btn btn-primary" 
                                    to="/payment"  state={{from:'service', path: location.pathname}}>
                                Submit
                                </NavLink>
                              */}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p><NavLink to="/">Elite Reviser</NavLink> can handle that. 
                            <NavLink to="/contact"> Contact Us.</NavLink></p>                      
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                            <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={userDetails} onHide={userDetailsClose} className="CmnModal CancelOrder editAccModal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <main className="main">
                        <div className="pagetitle">
                            <span className="TitleWrap">
                                <span className="NameLogo">{showClientData?.first_name?.charAt(0)}{showClientData?.last_name.charAt(0)}</span>
                                <h2>Edit Account</h2>
                            </span>
                        </div>

                        <section className="section AccDtlsPnl">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    id: showClientData && showClientData.id,
                                    first_name: showClientData && showClientData.first_name,
                                    last_name: showClientData && showClientData.last_name,
                                    ph_no: showClientData && showClientData.ph_no,
                                    email_address: showClientData && showClientData.email_address,
                                    address: showClientData && showClientData.address,
                                    zipcode: showClientData && showClientData.zipcode,
                                    state: showClientData && showClientData.state,
                                }}
                                onSubmit={(values,{resetForm})=>{updateEditorData(values,{resetForm})}}
                                validationSchema = {schema}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    errors,
                                    values, 
                                    setFieldValue
                                })=>(
                            <div className="AccBox">
                                <div className="pagetitle">
                                <h3>Personal Details</h3>
                                {loading && loading ? (
                                    <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i className="bi bi-check-circle" />Update</Button>
                                    ):(
                                    <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Update</Button>
                                    )
                                }
                                </div>
                                <form action method="get">
                                <ul className="AccDtls EditAccDtls row">
                                <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} value={values.first_name}/>
                                    {errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name}/>
                                    {errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="number" className="InptField" id="phoneInput" placeholder="Phone Number" name="ph_no"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                                    {errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="email" className="InptField" id="emailInput" placeholder="Email Address" name="email_address" onChange={handleChange} value={values.email_address}/>
                                    {errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="nameInput" placeholder="State" name="state" onChange={handleChange} value={values.state} />
                                    {errors.state ? <div className='error'>{errors.state}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                                    {errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                                    </li>
                                    <li className="col-md-8">
                                    <input type="text" className="InptField" id="addressInput" placeholder="Address" name="address" onChange={handleChange} value={values.address}/>
                                    {errors.address ? <div className='error'>{errors.address}</div> : null}
                                    </li>
                                </ul>
                                </form>
                            </div>
                            )}
                            </Formik>
                        </section>
                    </main>
                
                {/* <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a> */}
                {/* <a class="cancelBtn noBtn" onClick={()=>orderCancelClose()}>NO</a> */}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Serviceschoosecv;