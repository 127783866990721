import React, { Component, useState, useEffect, useRef  } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import DeputyAdminHeader from "../../Components/DeputyComponents/DeputyAdminHeader";
import DeputyAdminSidebar from "../../Components/DeputyComponents/DeputyAdminSidebar";
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import {NotificationManager} from 'react-notifications'; 
import { saveAs} from 'file-saver';
import CountdownTimer from "../../Timer/CountdownTimer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import { get_date_format } from "../../utility/Common";

const reviewIcon = require("../../assets/img/ReviewIcon.png");
const docFileImg = require("../../assets/img/DocFileImg.png");
const docImg = require("../../assets/img/docs-img.png");
const pdfImg = require("../../assets/img/pdf.png");
const rtfImg = require("../../assets/img/rtf-img.png");
const odtImg = require("../../assets/img/odt-img.png");
const pagesImg = require("../../assets/img/pages-img.png");
const pptImg = require("../../assets/img/ppt-img.png");
const xlsxImg = require("../../assets/img/xlsx-img.png");
const gdocImg = require("../../assets/img/gdoc-img.png");
const gslideImg = require("../../assets/img/google-slides.png");
const gsheetImg = require("../../assets/img/gsheet-img.png");
const plainTextImg = require("../../assets/img/txt-img.png");
const LatexImg = require("../../assets/img/text-img.png");
const attachFileIcon = require("../../assets/img/attach-icon.png");
const linkIcon = require("../../assets/img/link-icon.png");
const feedbackStarImg = require("../../assets/img/feedbackStar.png");


const DeputyAdminCancelledJobs = () => {
  const windowRef = useRef();
  const timeZone = DateTime.local().zoneName;
  // date picker
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isWindowOpen, setIsWindowOpen] = useState(false);

  //API dates
  const [useStartDate,setUseStartDate]= useState();
  const [useEndDate,setUseEndDate]= useState();

  const openJobDetlsPnl = () => {
    setIsWindowOpen(true); 
    $(".openJobDetlsPnl").addClass("Act");
  };
  const closeJobDetlsPnl = () => { 
    $(".openJobDetlsPnl").removeClass("Act");
  };
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [loading,setLoading] = useState(false); 
  const [loading1,setLoading1] = useState(false); 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showModal, setShowModal] = useState(false);
  const modalclose  = () => setShowModal(false)
  const [showEditorName,setShowEditorName] = useState(); // editor name
  const [showDropDown,setShowDropDown] = useState(false);
  const [showDropDown1,setShowDropDown1] = useState(false);
  const [showMsg,setShowMsg] = useState();
  const [endCounterTimer,setEndCounterTimer] = useState(false);  
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }

  const [showEditorList,setShowEditorList] = useState([]); // for all editors
  const [selectedEditor,setSelectedEditor] = useState(); // selected editor
  const [jobList,setJobList] = useState([]); // all job list
  const [jobInfoById,setJobInfoById] = useState(); // job deatils by id
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setinstructionFile] = useState();
  const [jobId,setJobId] = useState();
  const [editorAssign,setEditorAssign] = useState(false); // editor assign or not checking
  const [editorEmail,setEditorEmail] = useState(); // for editor assign
  const [ShowCancelDetails,setShowCancelDetails] = useState(); // Cancel job details
  const navigate = useNavigate();
  let loggedUser= JSON.parse(localStorage.getItem("user_data_elite")); 
  useEffect(() => {
      getAllEditor();
      getAllJobs();
      // console.log(editorDetails)
    }, []);

    useEffect(() => {
      if (loggedUser=== null)
      {
          navigate("/admin-login");
          navigate(0);
      }
      else if(loggedUser?.role == '1' || loggedUser?.role == '2' || loggedUser?.role == '4')
      {
          navigate("/admin-login");
      }
    })
  
  //get formatted start date
  const getFormattedStartDate = (start_date) =>{
    setUseStartDate(start_date);
  }

  //get formatted end date
  const getFormattedEndDate =async(end_date) =>{
    var startDate= '';
    var endDate= '';
    setUseEndDate(end_date);
    startDate = getymdFormattedDate(useStartDate);
    endDate = getymdFormattedDate(end_date);
    if(startDate == '' || startDate == undefined || startDate ==null)
    {
      NotificationManager.warning('Select Start Date', '',1000);
    }
    else if(endDate == '' || endDate == undefined || endDate ==null)
    {
      NotificationManager.warning('Select End Date', '',1000);
    }
    else
    {
      // alert('Start Date:' + startDate); 
      // alert('End Date:' + endDate); 
      // Start Date:2023-04-12
      // End Date:2023-04-27
      // https://www.demoupdates.com/updates/elite/api/job/joblistByDate?startDate=2023-01-12&endDate=2023-09-12
      try
      {
        // setJobList
        let apiRes = await axios.get(`${API_BASE_URL}job/joblistByDate?startDate=${startDate}&endDate=${endDate}&job_status=${3}`)
        if(apiRes)
        {
          if(apiRes.data.res == true) 
          {
            setJobList(apiRes.data.data);
          }
          else
          {
            // NotificationManager.error(apiRes.data.msg, '',2000);
          }
        }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      } 
    }
  }

  //formatted date yyyy-mm-dd
  const getymdFormattedDate = (use_date) =>{
    var year = use_date.toLocaleString("default", { year: "numeric" });
    var month = use_date.toLocaleString("default", { month: "2-digit" });
    var day = use_date.toLocaleString("default", { day: "2-digit" });
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  // get all editors
  const getAllEditor = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=1`) 
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          console.log(apiRes.data.data);
          setShowEditorList(apiRes.data.data);
        }
      }
    }  //close try
    catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  // show all jobs
  const getAllJobs = async () => {
    try 
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${5}&UserTimeZone=${timeZone}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setJobList(apiRes.data.data);
            }
        }
      } 
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
  };
  
  //file download
  const downloadFile = (item) => {
    saveAs(item);
  }

  //short file name
  const shortFileName=(item) =>{
    console.log(item?.slice(56,66));
    let filename = item?.slice(56,70);
    return filename + '...';
    // return item
  }
  
  // assign Editor 
  const assignEditor = async(editor_id) =>
  {
    // alert(editor_id)
    console.log('Editor id: ' + editor_id);
    console.log('Job id: ' + jobInfoById?.id);
    try 
    {
      setLoading(true);
      setShowDropDown(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/inserteditor?id=${jobInfoById?.id}&editor_id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let apiRes1 = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
            if (apiRes1) 
            {
                console.log(apiRes1.data.data);
                if (apiRes1.data.res == true) 
                {
                  let fullName = '';
                  let email = '';
                  console.log(apiRes1.data.data[0]);
                  // setEditorEmail(apiRes1.data?.data[0]?.email_address);
                  fullName = apiRes1.data?.data[0]?.first_name + ' ' + apiRes1.data.data[0]?.last_name;
                  email = apiRes1.data?.data[0]?.email_address;
                  // console.log(fullName)
                  // alert(fullName)
                  console.log('Editor Id: ' + editor_id)
                  console.log('Editor Name: ' + fullName)
                  // getJobDetails(jobInfoById?.id)
                  // setShowEditorName(fullName)
                  let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                  if (apiRes2) 
                   {
                        console.log(apiRes2.data.res);
                        if (apiRes2.data.res == true) 
                        {
                          getJobDetails(jobInfoById?.id);
                          setShowDropDown(false);
                          setShowDropDown1(true);
                          NotificationManager.success('Job Successfully Assigned', "", 2000);
                        }
                        else
                        {
                          
                          NotificationManager.error('error', "", 2000);
                        }
                   }
                  setLoading(false);
                  setEditorEmail('');
                  // setShowEditorList('');
                }
                else
                {
                  setLoading(false);
                  NotificationManager.error('error', "", 2000);
                }
            }
            getAllJobs();
          }
      }
    }
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }
 
  // Editor Details 
  const editorDetailsById = async(editor_id) =>
  {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let fullName='';
            console.log(apiRes.data.data[0])
            // setEditorEmail(apiRes.data?.data[0]?.first_name);
            setEditorEmail(apiRes.data?.data[0]?.first_name);
            fullName = apiRes.data?.data[0]?.first_name + ' ' + apiRes.data.data[0]?.last_name;
            console.log(fullName)
            alert(fullName)
            setShowEditorName(fullName)
            return fullName;
          }
          else
          {
            NotificationManager.error('error', "", 2000);
          }
      }
    
  }

  const calculate_age = () =>{
    alert('function called!');
  }
  // Editor Details 
  const editorName = async(editor_id) =>
  {
    console.log(editor_id)
    return
    try 
    {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
           
            console.log(apiRes.data.data[0])
            return apiRes.data?.data[0]?.first_name + ' ' + apiRes.data.data[0]?.last_name;
            
          }
      }
    }
    catch (error) 
    {
      // NotificationManager.error(error, "", 2000);
    }
  }

  //get job details by id
  const getJobDetails= async(job_id) =>{
    // alert(job_id)
    try {
      // https://www.demoupdates.com/updates/elite/api/job/jobcanceldetails?id=116
      let apiRes = await axios.get(`${API_BASE_URL}job/jobcanceldetails?id=${job_id}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            // console.log(apiRes.data.data[0]);
            setJobInfoById(apiRes.data.data[0]);
            setServiceFile(apiRes.data.data[0].service_filename);
            setinstructionFile(apiRes.data.data[0].instruction_filename);
            setShowCancelDetails(apiRes.data.data[1]);
            console.log(apiRes.data.data[1])
          }
      }
      } catch (error) {
      //close try
      // NotificationManager.error(error.message, "", 2000);
      }

  }

  //sorting job list
  const sortingJobList = async(filter_id) =>{
    console.log(filter_id)
    if (filter_id == 0)
    {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/joblist`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setJobList(apiRes.data.data);
            }
        }
      }//close try 
      catch (error)
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }
    else
    {
      try 
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${filter_id}&UserTimeZone=${timeZone}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setJobList(apiRes.data.data);
            }
        }
      } 
        catch (error) 
        {
          // NotificationManager.error(error.message, "", 2000);
        }
    }
  }

  //reAssign Editor
  const reAssignEditor = () =>{
    // alert(1)
    setShowDropDown(true);
    // setShowDropDown1(true);
  }

   
  //Counter
const remainingTimeCount = (datetime) =>{
  console.log(datetime)
  var split_days = datetime?.split(",");
    console.log(split_days)
    var sp_days = split_days[0].slice(0,2);
    var sp_hours = split_days[1].slice(0,2);
    var sp_minutes = split_days[2].slice(0,2);
    var sp_seconds = split_days[3].slice(0,2);

    // console.log('Days: ' + sp_days + ' ' + 'Hours: ' + sp_hours + ' ' + 'Minutes: ' + sp_minutes + ' ' + 'Seconds: ' + sp_seconds);
    // console.log('Days: ' + sp_days.slice(0,2) + ' ' + 'Hours: ' + sp_hours.slice(0,2) + ' ' + 'Minutes: ' + sp_minutes.slice(0,2) + ' ' + 'Seconds: ' + sp_seconds.slice(0,2));

  const DAYS_IN_MS = parseInt(sp_days.slice(0,2))* 24 * 60 * 60 * 1000;
  const hour_ms = parseInt(sp_hours.slice(0,2)) * 3600000;
  const minutes_ms = parseInt(sp_minutes.slice(0,2)) * 60000;
  const sec_ms = parseInt(sp_seconds.slice(0,2)) * 1000;
  const total_ms = DAYS_IN_MS + hour_ms + minutes_ms + sec_ms;
  console.log(total_ms)

  if(total_ms < 0 )
  {
    setEndCounterTimer(true);
  }
  else
  {
    // setEndCounterTimer(true);
    const NOW_IN_MS = new Date().getTime();
    const dateTimeAfterThreeDays = NOW_IN_MS + total_ms;
    console.log(dateTimeAfterThreeDays)
    // <CountdownTimer targetDate={dateTimeAfterThreeDays} />
    return(<CountdownTimer targetDate={dateTimeAfterThreeDays} />)
  }
}

useEffect(() => {
  const checkIfClickedOutside = e => {
    if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
    {
      setIsWindowOpen(false)
      closeJobDetlsPnl();
    }
    else
    {
      console.log('here')
      console.log(isWindowOpen)
      console.log(windowRef.current)
    }
  }
  document.addEventListener("mousedown", checkIfClickedOutside)

  return () => {
    document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
  }
}, [isWindowOpen])

  return (
    <div>
      <DeputyAdminHeader />
      <DeputyAdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
          {/* <span className="editorNameLogo"></span> */}
            <h2>Canceled Jobs</h2>
            {/* <h2>Canceled</h2> */}
          </span>
        </div>
        <div className="date-range-sec">
          <div className="chose-date-sec"><DatePicker selected={startDate} onChange={(date) => {setStartDate(date);getFormattedStartDate(date)}} dateFormat="P" /></div>
          <div className="chose-date-sec"><DatePicker selected={endDate} onChange={(date) => {setEndDate(date);getFormattedEndDate(date)}}  dateFormat="P" /></div>
        </div>
        <br className="clearfix" />
        <section className="section OdrDtlsPnl">
          <div className="ClientMyOrders">
            <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table InternalCancelJobTable">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th className="text-center">Task Name</th>
                  {/* <th>Word Count/Page No.</th>  */}
                  <th>WC/Pages</th> 
                  <th>Amount</th>  
                  <th>Due Date</th>
                  {/* <th>Created On</th> */}
                  {/* <th style={{textAlign: "center"}}>Countdown</th> */}
                  {/* <th>Status</th> */}
                  {/* <th>Request Job</th> */}
                  {/* <th>Assign Editor</th> */}
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {jobList&& jobList.length > 0 ? (
                  jobList.map((item,index)=>{
                    return(
                      <>
                      {(item?.job_type==='type2')?(
                        <tr>
                          <td>{item?.id}</td>
                          <td>
                            {/* <p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetailsResumeCv(item?.id)}}> */}
                            <p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetails(item?.id)}}>
                              {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                            </p>
                          </td>
                          <td>{item.number_of_pages}</td>
                          {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                          <td>${parseFloat(Number(item.editor_cv_price)).toFixed(2)}</td>
                          <td>
                            <span className="GreenTxt">{get_date_format(item?.due_date)}</span>
                          </td>
                          {/* <td><div className="count-down-timer">{remainingTimeCount(item?.differencetime)}</div></td> */}
                          {/* <td>
                            {
                              item?.job_status == '1'? (<span className="asign-red"><i class="bi bi-clock"></i> Not Yet Assigned</span>):
                              item?.job_status == '2'? (<span className="asign-blue"><i class="bi bi-clock"></i> Assigned</span>):
                              item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                              item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                              item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-check-circle"></i> Cancelled</span>):''
                            }
                          </td> */}
                        </tr>
                        ):(
                        <tr>
                          <td>{item?.id}</td>
                          <td><p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetails(item?.id)}}>
                            {/* #{item?.id} | {item?.service_name} | {item?.sub_service} | Word Count {item?.from_count}-{item?.to_count} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} |  [{item?.service_hour}hrs]</p></td> */}
                            {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} | {item?.service_hour}hrs </p></td>
                          <td>{item.total_wordcount}</td>
                          {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                          <td>${parseFloat(Number(item.editor_reg_price)).toFixed(2)}</td>
                          <td>
                            <span className="GreenTxt">{get_date_format(item?.due_date)}</span>
                          </td>
                          {/* <td><div className="count-down-timer">{remainingTimeCount(item?.differencetime)}</div></td> */}
                          {/* <td>
                            {
                              item?.job_status == '1'? (<span className="asign-red"><i class="bi bi-clock"></i> Not Yet Assigned</span>):
                              item?.job_status == '2'? (<span className="asign-blue"><i class="bi bi-clock"></i> Assigned</span>):
                              item?.job_status == '3'? (<span className="StatusTxt ongoing"><i class="bi bi-arrow-clockwise"></i> Ongoing</span>):
                              item?.job_status == '4'? (<span className="StatusTxt completed"><i class="bi bi-check-circle"></i> Completed</span>):
                              item?.job_status == '5'? (<span className="StatusTxt Cancelled"><i class="bi bi-x-circle"></i> Cancelled</span>):''
                            }
                          </td> */}
                        </tr>
                      )}
                        
                      </>
                    
                    )
                  })
                ):(
                  <>
                      <tr>
                          <td className='no-data' colSpan={5} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                      </tr>
                  </>
                )}
              
              </tbody>
            </table>
          </div>
        </section>
        
         {/* left Job Details Pannel */}
         <div className="DtlsSectionWrapper OngoingPnl openJobDetlsPnl" ref={windowRef}>
            <div className="TaskDtlsPnl no-textarea-sec">
              <div className="HdrTop">
                {/* <span className="Lft">
                  <a href="javascript:void(0);" className="CmnBtn CompleteTag">
                    <i className="bi bi-check-circle" /> Pickup Job
                  </a>
                  <a className="CmnBtn CancelOrdereBtn">
                    <i className="bi bi-check-circle" /> Reject Job 
                  </a>
                </span> */}
                <span className="Rht">
                  {/* <a className="OpenReview" onClick={handleShow}>
                    <img src={reviewIcon} al="img" />
                  </a> */}
                  {/* <a onClick={()=>{closeJobDetlsPnl();setShowDropDown(false)}} className="CmnBtn CancelledTag"> */}
                    {/* <i className="bi bi-x" /> */}
                    {/* <i className="bi bi-x-circle"/> Canceled */}
                    {/* CloseBtn */}
                  {/* </a> */}
                  <a class="CmnBtn CancelOrdereBtn cancel-odr-deactivate" onClick={()=>{closeJobDetlsPnl();setShowDropDown(false)}} style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Order Canceled">Canceled</a>
                  <a onClick={()=>{closeJobDetlsPnl()}} className="CloseBtn" style={{position:'absolute', right: '5px'}}>
                    <i className="bi bi-x" />
                  </a>
                 
                </span>
              </div>

              <div className="MiddleBody">
                {/* <h2>
                  #84 | WRITER | Book | Word Count 2,000-3,999 | Expedited
                  Service [32hrs]
                </h2> */}
                {(jobInfoById?.job_type==='type2')?(
                  <>
                      <h2>
                      {/* #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.from_count}-{jobInfoById?.to_count} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  [{jobInfoById?.service_hour}hrs] */}
                      #{jobInfoById?.id} | {jobInfoById?.service_name} | Number of Pages {jobInfoById?.number_of_pages} | {jobInfoById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                      </h2>
                      <table className="DtlsDescrpTbl">
                        <tbody>
                          <tr>
                            <td>Editor</td> 
                              <td>
                                
                                {jobInfoById?.editor_name!=""?(
                                  <>
                                    {jobInfoById?.editor_name}
                                    <div className="edit-sec">
                                    {/* <a>Assign Again</a> */}
                                    {loading && loading ? (
                                        <i class="fa fa-refresh fa-spin"></i>
                                        ):(
                                          <></>
                                    )}

                                    {showDropDown && showDropDown ?(
                                      <>
                                        <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value)}}>
                                          <option selected hidden>--Select Editor--</option>
                                            {showEditorList && showEditorList.map((item,index)=>{
                                              return(
                                                <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                              )
                                            })}
                                        </select>
                                        {/* <Form.Group>
                                                <Select value={selectedOption} options={Resumeoptions} placeholder='Select no of Resume Pages' />
                                        </Form.Group> */}
                                      </>
                                    ):(
                                      <>
                                       {jobInfoById?.job_status === '4' ? (
                                          <></>
                                        ):(
                                          <></>
                                          // <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                        )}
                                        
                                      </>
                                    )}
                                    </div>
                                  </>
                                ):(
                                  <>
                                    {loading && loading ? (
                                        <>
                                          <i class="fa fa-refresh fa-spin"></i><p>Assigning Editor.. Please Wait..</p>
                                        </>
                                        ):(
                                          <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value)}}>
                                            <option selected hidden>--Assign Editor--</option>
                                              {showEditorList && showEditorList.map((item,index)=>{
                                                return(
                                                  <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                                )
                                              })}    
                                          </select>
                                    )}
                                  </>
                                )}

                              </td>
                          </tr>
                          <tr>
                            <td>Due date</td>
                            {/* <td>Dec 08, 2022</td> */}
                            {/* <td>Created at</td> */}
                            <td>
                              <span className="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Instruction</td>
                            {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} /> */}
                            {jobInfoById?.client_instruction!='null' ? (
                              <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} />
                            ):(
                              <td>No instruction included.</td>
                            )}
                          </tr>
                          <tr>
                            <td>Services:</td>
                            {/* <td>ESL</td> */}
                            <td>{jobInfoById?.service_name}</td>
                          </tr>
                          {/* <tr>
                            <td>Options:</td>
                            <td>{jobInfoById?.sub_service}</td>
                          </tr> */}
                          {/* <tr>
                            <td>Word Count:</td>
                            <td>{jobInfoById?.from_count}-{jobInfoById?.to_count}</td>
                          </tr> */}
                          <tr>
                            <td>Service Type:</td>
                            {/* <td>Regular[24hrs]</td> */}
                            <td>{jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                          </tr>
                          <tr>
                            <td>Number of Pages: </td>
                            <td>{jobInfoById?.number_of_pages}</td>
                          </tr>

                          <tr>
                            <td>Price: </td>
                            <td>${parseFloat(jobInfoById?.cv_price).toFixed(2)}</td>
                          </tr>
                          {/* <tr>
                            <td>Language:</td>
                            <td>
                              {
                                jobInfoById?.english_version=='1'?'American':
                                jobInfoById?.english_version=='2'?'British':
                                jobInfoById?.english_version=='3'?'Canadian':''
                              }
                            </td>
                          </tr> */}
                          <tr>
                            <td>Service File:</td>
                            <td>
                                  {/* {
                                    jobInfoById?.service_filename.endsWith('.doc') || 
                                    jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                  } */}
                                  {/* <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link service-file-link">
                                    {
                                      jobInfoById?.service_filename.endsWith('.doc') || 
                                      jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                    }
                                    <span>{shortFileName(jobInfoById?.service_filename)}</span>
                                  </a> */}
                                  {serviceFile && serviceFile?.length > 0 && serviceFile?.map((item,index)=>{
                                      return(
                                        item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                      )
                                         {/* <a data-toggle="tootltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                                   })}
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>Instruction File:</td>
                            <td><br/>
                              {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                                 return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                              })}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Cancellation Reason:</td>
                            <td>{ShowCancelDetails?.cancellation_title}</td>
                          </tr>
                          <tr>
                            <td>Cancellation Description:</td>
                            <td>{ShowCancelDetails?.cancellation_description}</td>
                          </tr> */}
                        </tbody>
                      </table>
                  </>
                ):
                (
                  <>
                      <h2>
                        #{jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.total_wordcount} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {jobInfoById?.service_hour}hrs
                      </h2>
                      <table className="DtlsDescrpTbl">
                        <tbody>
                          <tr>
                            <td>Editor</td> 
                              <td>
                                
                                {jobInfoById?.editor_name!=""?(
                                  <>
                                    {jobInfoById?.editor_name}
                                    <div className="edit-sec">
                                    {/* <a>Assign Again</a> */}
                                    {loading && loading ? (
                                      <>
                                        <i class="fa fa-refresh fa-spin"></i>Processing...
                                      </>
                                      ):(
                                        <></>
                                    )}
                                    {showDropDown && showDropDown ?(
                                      <>
                                        <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value)}}>
                                          <option selected hidden>--Select Editor--</option>
                                            {showEditorList && showEditorList.map((item,index)=>{
                                              return(
                                                <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                              )
                                            })}
                                        </select>
                                      </>
                                    ):(
                                      // <>
                                      //   {showMsg && showMsg == 0 ?(
                                      //     <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                      //   ):(
                                      //     <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                      //   )}
                                        
                                      // </>
                                      <>
                                       {jobInfoById?.job_status === '4' ? (
                                          <></>
                                        ):(
                                          <></>
                                          // <Button className="CmnBtn" type="submit" onClick={()=>reAssignEditor()}>Assign</Button>
                                        )}
                                        
                                      </>
                                    )}
                                    </div>
                                  </>
                                ):(
                                  <>
                                    {loading && loading ? (
                                        <>
                                          <i class="fa fa-refresh fa-spin"></i><p>Assigning Editor.. Please Wait..</p>
                                        </>
                                        ):(
                                          <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value)}}>
                                            <option selected hidden>--Assign Editor--</option>
                                              {showEditorList && showEditorList.map((item,index)=>{
                                                return(
                                                  <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                                                )
                                              })}    
                                          </select>
                                    )}
                                  </>
                                )}

                              </td>
                          </tr>
                          <tr>
                            <td>Due date</td>
                            {/* <td>Dec 08, 2022</td> */}
                            {/* <td>Created at</td> */}
                            <td>
                              <span className="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Instruction</td>
                            {/* <td>{jobInfoById?.full_description}</td> */}
                            {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.full_description}} /> */}
                            {jobInfoById?.client_instruction!='null' ? (
                              <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} />
                            ):(
                              <td>No instruction included.</td>
                            )}
                          </tr>
                          <tr>
                            <td>Services:</td>
                            {/* <td>ESL</td> */}
                            <td>{jobInfoById?.service_name}</td>
                          </tr>
                          <tr>
                            <td>Options:</td>
                            {/* <td>Document</td> */}
                            <td>{jobInfoById?.sub_service}</td>
                          </tr>
                          <tr>
                            <td>Word Count:</td>
                            {/* <td>251-999</td> */}
                            {/* <td>{jobInfoById?.total_wordcount}</td> */}
                            {/* <td>{jobInfoById?.from_count}-{jobInfoById?.to_count}</td> */}
                            <td>{jobInfoById?.total_wordcount}</td>
                          </tr>
                          <tr>
                            <td>Service Type:</td>
                            {/* <td>Regular[24hrs]</td> */}
                            <td>{jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                          </tr>
                          <tr>
                            <td>Language:</td>
                            {/* <td>British</td> */}
                            <td>
                              {
                                jobInfoById?.english_version=='1'?'American':
                                jobInfoById?.english_version=='2'?'Australian':
                                jobInfoById?.english_version=='3'?'British':
                                jobInfoById?.english_version=='4'?'Canadian':''
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Service File:</td>
                            <td>
                                  {/* {
                                    jobInfoById?.service_filename.endsWith('.doc') || 
                                    jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                  } */}
                                  {/* <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link service-file-link">
                                    {
                                      jobInfoById?.service_filename.endsWith('.doc') || 
                                      jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                                    }
                                    <span>{shortFileName(jobInfoById?.service_filename)}</span>
                                  </a> */}
                                  {serviceFile && serviceFile?.length > 0 && serviceFile?.map((item,index)=>{
                                      return(
                                        item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                        item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                      )
                                         {/* <a data-toggle="tootltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                                   })}
                              <br />
                            </td>
                          </tr>
                          <tr>
                            <td>Instruction File:</td>
                            <td><br/>
                              {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                              })}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Cancellation Reason:</td>
                            <td>{ShowCancelDetails?.cancellation_title}</td>
                          </tr>
                          <tr>
                            <td>Cancellation Description:</td>
                            <td>{ShowCancelDetails?.cancellation_description}</td>
                          </tr> */}
                        </tbody>
                      </table>
                  </>
                )}
                

                {/* <table className="table table-header">
                  <tr>
                    <td>
                      <table className="table table-left-header">
                        <tr>
                          <td><span class="NameLogo">sw</span></td>
                          <td>
                            <h5>Sam Willium</h5>
                            <p>7455 Drew Court White City,<br />
                               Kansas 66872 U.S<br />
                               270-510-0585</p>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td align="right">
                        <h3>Invoice</h3>  
                        <h4>Invoice# INV-000136</h4>
                        <h5>Amount<br /><strong>$550.00</strong></h5>
                    </td>
                  </tr>
                </table>
                <div className="CommentItem">
                  <p className="AuthorName">
                    <span className="NameLogo BlueNameLogo">ad</span> Editor
                    Comment:
                  </p>
                  <p>Attached File</p>
                  <img src={docFileImg} alt />
                  <p>Your document is ready and I am submitted.</p>
                  <div className="RhtDate">
                    <span>
                      Dec 09, 2022
                      <br />
                      10:45 PM
                    </span>
                  </div>
                </div>
                <div className="CommentItem">
                  <p className="AuthorName">
                    <span className="NameLogo">sw</span> Sam Willium
                  </p>
                  <p>Here are the feedback. Please fix it.</p>
                  <ul>
                    <li>1. Lorem ipsum dolor sit amet.</li>
                    <li>2. Duis a diam faucibus erat vehicula.</li>
                    <li>
                      3. Duis nec lorem sit amet purus pellentesque sagittis ac
                      ac neque.
                    </li>
                    <li>
                      4. Curabitur volutpat elit sit amet arcu tristique
                      imperdiet.
                    </li>
                  </ul>
                  <div className="RhtDate">
                    <span>
                      Dec 09, 2022
                      <br />
                      10:45 PM
                    </span>
                  </div>
                </div> */}
              </div>
              {/* <div className="BtmFtr">
                <span class="NameLogo">sw</span>
                <textarea cols="" rows=""></textarea>
                <input type="button" value="Submit" className="btn btn-primary CmnBtn" />
                <ul className="msg-list">
                  <li><img src={attachFileIcon} alt /></li>
                  <li><img src={linkIcon} alt /></li>
                </ul>
              </div> */}
            </div>
         </div>

      </main>
      <Modal show={markComplete} onHide={markClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Rate your experience and quality of work</p>
          <p><img src={feedbackStarImg} alt="" /></p>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Mark Complete</a>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} className="CmnModal CustReview">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Feedback</h2>
          <p>Give some feedback about your experience with our service to the admin</p>
          <textarea name="" cols="" rows="" placeholder="Type here..."></textarea>
          <label className="radio inline">
            <input defaultChecked name="lost-password" defaultValue type="radio" />
            <span>Send to all</span>
          </label>
          <label className="radio inline">
            <input name="lost-password" defaultValue type="radio" />
            <span>Send to only admin</span>
          </label>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Feedback</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>*You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when you cancel order after 30 min of submission there is no full refund.</p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrderForm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p><span>*</span>You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when you cancel order after 30 min of submission there is no full refund.</p>
          <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" />
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..."></textarea>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={modalclose}  className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Select Editor</h2>
          <>
            <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value);modalclose();}}>
              <option value='null'>--Select Editor--</option>
                {showEditorList && showEditorList.map((item,index)=>{
                  return(
                    // <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                    <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                  )
                })}    
            </select>
          </>
          {/* <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a> */}
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default DeputyAdminCancelledJobs;
