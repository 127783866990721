// import React, { useEffect, useMemo, useState } from "react";

// //Filter list by category in React JS
// export default function Testing() {
//   // Default Value
//   var defaultSports = [
//     { name: "Table Tennis", category: "Indoor" },
//     { name: "Football", category: "Outdoor" },
//     { name: "Swimming", category: "Aquatics" },
//     { name: "Chess", category: "Indoor" },
//     { name: "BaseBall", category: "Outdoor" }
//   ];
//   const [sportList, setSportList] = useState([]);

//   const [selectedCategory, setSelectedCategory] = useState();
//   const Item = ({ name, category }) => (
//     <div className="item-container">
//       <div>
//         <span className="item-label">Name:</span>
//         {name}
//       </div>
//       <div>
//         <span className="item-label">Category:</span>
//         {category}
//       </div>
//     </div>
//   );
//   // Add default value on page load
//   useEffect(() => {
//     setSportList(defaultSports);
//   }, []);

//   // Function to get filtered list
//   function getFilteredList() {
//     // Avoid filter when selectedCategory is null
//     if (!selectedCategory) {
//       return sportList;
//     }
//     return sportList.filter((item) => item.category === selectedCategory);
//   }

//   // Avoid duplicate function calls with useMemo
//   var filteredList = useMemo(getFilteredList, [selectedCategory, sportList]);

//   function handleCategoryChange(event) {
//     setSelectedCategory(event.target.value);
//   }

//   return (
//     <div className="app">
//       <div className="filter-container">
//         <div>Filter by Category:</div>
//         <div>
//           <select
//             name="category-list"
//             id="category-list"    
//             onChange={handleCategoryChange}
//           >
//             <option value="">All</option>
//             <option value="Outdoor">Outdoor</option>
//             <option value="Indoor">Indoor</option>
//             <option value="Aquatics">Aquatics</option>
//           </select>
//         </div>
//       </div>
//       <div className="sport-list">
//         {filteredList.map((element, index) => (
//           <Item {...element} key={index} />
//         ))}
//       </div>
//     </div>
//   );
// }

// import React, { useState } from 'react';

// function Testing() {
//   const [checkboxes, setCheckboxes] = useState([
//     { id: 'checkbox1', label: 'Checkbox 1', isChecked: false },
//     { id: 'checkbox2', label: 'Checkbox 2', isChecked: false },
//     { id: 'checkbox3', label: 'Checkbox 3', isChecked: false },
//   ]);

//   const handleCheckboxChange = (id) => {
//     setCheckboxes((prevCheckboxes) =>
//       prevCheckboxes.map((checkbox) => ({
//         ...checkbox,
//         isChecked: checkbox.id === id,
//       }))
//     );
//   };

//   const getSelectedCheckbox = () => {
//     const selectedCheckbox = checkboxes.find((checkbox) => checkbox.isChecked);
//     if (selectedCheckbox) {
//       console.log(`Selected Checkbox: ${selectedCheckbox.label}`);
//     } else {
//       console.log('No checkbox selected');
//     }
//   };

//   return (
//     <div>
//       {checkboxes.map((checkbox) => (
//         <div key={checkbox.id}>
//           <input
//             type="checkbox"
//             id={checkbox.id}
//             checked={checkbox.isChecked}
//             onChange={() => handleCheckboxChange(checkbox.id)}
//           />
//           <label htmlFor={checkbox.id}>{checkbox.label}</label>
//         </div>
//       ))}
//       <button onClick={getSelectedCheckbox}>Get Selected Checkbox</button>
//     </div>
//   );
// }

// export default Testing;

// import React, { useState } from 'react';

// function Testing() {
//   const [files, setFiles] = useState([]);
//   const [isDragging, setIsDragging] = useState(false);

//   const handleFileSelect = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     setFiles([...files, ...selectedFiles]);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setIsDragging(true);
//   };

//   const handleDragLeave = (e) => {
//     e.preventDefault();
//     setIsDragging(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setIsDragging(false);
//     const droppedFiles = Array.from(e.dataTransfer.files);
//     setFiles([...files, ...droppedFiles]);
//   };

//   const handleFileUpload = () => {
//     if (files.length === 0) {
//       alert('Please select one or more files first.');
//       return;
//     }

//     // Here, you can implement your file upload logic for all selected files.
//     console.log('Uploading files:', files);

//     // Clear the selected files after upload if needed
//     setFiles([]);
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         accept=".jpg, .jpeg, .png, .pdf"
//         multiple // Allow multiple file selection
//         onChange={handleFileSelect}
//       />

//       <div
//         className={`drop-area ${isDragging ? 'dragging' : ''}`}
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//       >
//         {files.length > 0 ? (
//           <div>
//             <p>Selected Files:</p>
//             <ul>
//               {files.map((file, index) => (
//                 <li key={index}>{file.name}</li>
//               ))}
//             </ul>
//             <button onClick={handleFileUpload}>Upload Files</button>
//           </div>
//         ) : (
//           <p>Drag and drop files here or click to select files.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Testing;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function Testing() {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     axios.get(`https://jsonplaceholder.typicode.com/posts`)
//       .then((res) => {
//         const postsData = res.data;
//         setPosts(postsData);
//       });
//   }, []);

//   const deleteRow = (id) => {
//     axios.delete(`https://jsonplaceholder.typicode.com/posts/${id}`)
//       .then((res) => {
//         console.log(res);
//         console.log(res.data);

//         const updatedPosts = posts.filter((item) => item.id !== id);
//         setPosts(updatedPosts);
//       });
//   };

//   return (
//     <div>
//       <h1>Example of React Axios Delete Request</h1>

//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Title</th>
//             <th>Body</th>
//             <th>Action</th>
//           </tr>
//         </thead>

//         <tbody>
//           {posts.map((post) => (
//             <tr key={post.id}>
//               <td>{post.id}</td>
//               <td>{post.title}</td>
//               <td>{post.body}</td>
//               <td>
//                 <button className="btn btn-danger" onClick={() => deleteRow(post.id)}>Delete</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default Testing;


// import React, { useState } from 'react';
// import UserList from './userList';

// const users = [
//   { name: 'John Doe', email: 'johndoe@example.com' },
//   { name: 'Jane Smith', email: 'janesmith@example.com' },
//   // Add more user data
// ];

// function Testing() {
//   const [query, setQuery] = useState('');
//   const [searchResults, setSearchResults] = useState(users);

//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     const filteredUsers = users.filter(
//       (user) =>
//         user.name.toLowerCase().includes(value.toLowerCase()) ||
//         user.email.toLowerCase().includes(value.toLowerCase())
//     );
//     setSearchResults(filteredUsers);
//   };

//   return (
//     <div>
//       <h1>User Search</h1>
//       <input
//         type="text"
//         placeholder="Search by name or email"
//         value={query}
//         onChange={handleSearch}
//       />
//       <UserList users={searchResults} />
//     </div>
//   );
// }

// export default Testing;


// import './App.css';
// import { useEffect, useState } from 'react';

// function Testing() {
//   const [users, setUsers] = useState([]);
//   const [searchText, setSearchText] = useState('');
//   const [filteredUser, setFilteredUser] = useState([]);

//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         const data = await fetch('https://jsonplaceholder.typicode.com/users');
//         const userData = await data.json();

//         setUsers(userData);
//       } catch (err) {
//         console.log(err);
//       }
//     };

//     fetchUser();
//   }, []);

//   useEffect(() => {
//     if (searchText.length) {
//       const filterUsers = users.filter((user) => {
//         return (user.name).toLowerCase().includes(searchText.toLowerCase());
//       });

//       setFilteredUser(filterUsers);
//     } else {
//       setFilteredUser([]);
//     }
//   }, [searchText, users]);

//   return (
//     <div className="container">
//       <h1 className='title'>Search Filter in react <span>( by weekendtutorial.com )</span></h1>
//       <input className='search' type="text" placeholder='search user' value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
//       <ul className='userList'>
//         {
//           filteredUser.length ?
//             filteredUser.map((user) => <li className='userListItem' key={user.id}>{user.name}</li>) :
//             users.map((user) => <li className='userListItem' key={user.id}>{user.name}</li>)
//         }
//       </ul>

//     </div>
//   );
// }

// export default Testing;

// import React, { useState } from "react";

// function Testing() {
//   // State to store manual word counts for each input
//   const [manualWordCounts, setManualWordCounts] = useState(Array(4).fill(""));

//   const items = ["file1.doc", "file2.pdf", "file3.docx", "file4.txt"];

//   // Function to calculate automatic word count for a file
//   const calculateAutomaticWordCount = (item) => {
//     // Replace this with your actual word count calculation logic for files
//     const placeholderWordCount = 100; // Replace with the actual word count
//     return placeholderWordCount;
//   };

//   // Function to calculate the total word count for all files
//   const calculateTotalWordCount = () => {
//     let totalWordCount = 0;

//     for (let i = 0; i < items.length; i++) {
//       const item = items[i];
//       const automaticWC = calculateAutomaticWordCount(item);
//       const manualWC = manualWordCounts[i] ? parseInt(manualWordCounts[i]) : 0;
//       totalWordCount += automaticWC + manualWC;
//     }

//     return totalWordCount;
//   };

//   const handleInputChange = (index, value) => {
//     // Update the manual word count for the specified index
//     const newManualWordCounts = [...manualWordCounts];
//     newManualWordCounts[index] = value;
//     setManualWordCounts(newManualWordCounts);
//   };

//   return (
//     <div>
//       {items.map((item, index) => (
//         <div key={index} className="col-md-2">
//           <input
//             type="text"
//             className="form-control"
//             value={calculateAutomaticWordCount(item)}
//             readOnly
//           />
//           <input
//             type="text"
//             className="form-control"
//             value={manualWordCounts[index]}
//             onChange={(e) => handleInputChange(index, e.target.value)}
//           />
//         </div>
//       ))}
//       <div>Total Word Count: {calculateTotalWordCount()}</div>
//     </div>
//   );
// }

// export default Testing;


// import React, { useState } from 'react';

// function Testing() {
//   const [val, setVal] = useState();

//   const updateValue = () => {
//     // setVal('100', () => {
//     //   console.log(val);
//     // });
    
//     setVal('100');
//     setTimeout(()=>{
//       console.log(val);
//     },5000)
//   };

//   return (
//     <div>
//       <button onClick={updateValue}>Update Value</button>
//     </div>
//   );
// }

// export default Testing;


// src/components/Pagination.js

// import React, { useState, useEffect } from 'react';

// let items = ["Banana", "Orange", "Apple", "Mango","Banana1", "Orange1", "Apple1", "Mango1"];
// let itemsPerPage = 2;

// function Testing() {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [currentItems, setCurrentItems] = useState([]);

//   useEffect(() => {
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const newCurrentItems = items.slice(indexOfFirstItem, indexOfLastItem);
//     setCurrentItems(newCurrentItems);
//   }, [currentPage, items, itemsPerPage]);

//   const totalPages = Math?.ceil(items.length / itemsPerPage);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   // Helper function to generate page numbers with ellipses
//   const generatePageNumbers = () => {
//     const pageNumbers = [];
//     const maxDisplayedPages = 5; // You can adjust this number
//     const maxPagesEitherSide = Math.floor(maxDisplayedPages / 2);

//     if (totalPages <= maxDisplayedPages) {
//       // If total pages are less than or equal to max displayed pages, show all pages.
//       for (let i = 1; i <= totalPages; i++) {
//         pageNumbers.push(i);
//       }
//     } else if (currentPage <= maxPagesEitherSide) {
//       // If current page is near the start, show pages up to maxDisplayedPages.
//       for (let i = 1; i <= maxDisplayedPages; i++) {
//         pageNumbers.push(i);
//       }
//       pageNumbers.push('...');
//       pageNumbers.push(totalPages);
//     } else if (currentPage >= totalPages - maxPagesEitherSide) {
//       // If current page is near the end, show pages from (totalPages - maxDisplayedPages) to totalPages.
//       pageNumbers.push(1);
//       pageNumbers.push('...');
//       for (let i = totalPages - maxDisplayedPages + 2; i <= totalPages; i++) {
//         pageNumbers.push(i);
//       }
//     } else {
//       // Otherwise, show pages around the current page.
//       pageNumbers.push(1);
//       pageNumbers.push('...');
//       for (let i = currentPage - maxPagesEitherSide; i <= currentPage + maxPagesEitherSide; i++) {
//         pageNumbers.push(i);
//       }
//       pageNumbers.push('...');
//       pageNumbers.push(totalPages);
//     }

//     return pageNumbers;
//   };

//   return (
//     <div>
//       {/* Display your current items */}
//       <ul>
//         {currentItems.map((item, index) => (
//           <li key={index}>{item}</li>
//         ))}
//       </ul>

//       {/* Pagination controls */}
//       <nav>
//         <ul className="pagination">
//           {generatePageNumbers().map((page, index) => (
//             <li
//               key={index}
//               className={`page-item ${page === '...' ? 'disabled' : currentPage === page ? 'active' : ''}`}
//             >
//               {page === '...' ? (
//                 <span className="page-link">...</span>
//               ) : (
//                 <button
//                   className="page-link"
//                   onClick={() => handlePageChange(page)}
//                 >
//                   {page}
//                 </button>
//               )}
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// }

// export default Testing;

// import React from "react";
// import Marquee from "react-fast-marquee";

// const Testing = () => (
//   <Marquee>
//     <p>Hello</p>
//   </Marquee>
// );

// export default Testing;

// import React, { useState } from 'react';

// const Testing = () => {
//   const [modalOpen, setModalOpen] = useState(false);

//   const openModal = () => {
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const handleOverlayClick = (event) => {
//     if (event.target.classList.contains('modal-overlay')) {
//       closeModal();
//     }
//   };

//   return (
//     <div className="modal-container">
//       <button onClick={openModal}>Click Me</button>
//       {modalOpen && (
//         <div className="modal-overlay" onClick={handleOverlayClick}>
//           <div className="modal">
//             <div className="modal-header">
//               <h2>Modal Title</h2>
//               <button onClick={closeModal}>Close</button>
//             </div>
//             <div className="modal-body">
//               <p>This is the modal content.</p>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Testing;


// Modal.js

// import React, { useState } from 'react';

// const Testing = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const openModal = () => {
//     setIsOpen(true);
//   };

//   const closeModal = () => {
//     setIsOpen(false);
//   };

//   const handleOutsideClick = (event) => {
//     if (event.target === event.currentTarget) {
//       closeModal();
//     }
//   };

//   return (
//     <div>
//       <button onClick={openModal}>Open Modal</button>
//       {isOpen && (
//         <div className="modal" onClick={handleOutsideClick}>
//           <div className="modal-content">
//             <p>Click outside this modal to close.</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Testing;


// import { useEffect, useRef, useState } from "react"

//   const Testing = () => {
//   const ref = useRef()

//   const [isMenuOpen, setIsMenuOpen] = useState(false)

//   useEffect(() => {
//     const checkIfClickedOutside = e => {
//       // If the menu is open and the clicked target is not within the menu,
//       // then close the menu
//       if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
//         setIsMenuOpen(false)
//       }
//     }

//     document.addEventListener("mousedown", checkIfClickedOutside)

//     return () => {
//       document.removeEventListener("mousedown", checkIfClickedOutside)   // Cleanup the event listener
//     }
//   }, [isMenuOpen])

//   return (
//     <div className="wrapper">
//       <button className="button" onClick={() => setIsMenuOpen(true)}>
//         Click Me
//       </button>
//       {isMenuOpen && (
//         <ul className="list" ref={ref}>
//           <li className="list-item">dropdown option 1</li>
//           <li className="list-item">dropdown option 2</li>
//           <li className="list-item">dropdown option 3</li>
//           <li className="list-item">dropdown option 4</li>
//         </ul>
//       )}
//     </div>
//   )
// }

// export default Testing

// import React, { useState } from 'react';

// const Testing = () => {
//     // State to manage if the dropdown should be open
//     const [isOpen, setIsOpen] = useState(false);

//     // Toggle the dropdown open/close
//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     // Close the dropdown when an option is selected
//     const handleOptionSelect = () => {
//         setIsOpen(true);
//     };

//     return (
//         <div>
//             <button onClick={toggleDropdown}>Click to open select</button>
//             {isOpen && (
//                 <select onChange={handleOptionSelect}>
//                     <option value="option1">Option 1</option>
//                     <option value="option2">Option 2</option>
//                     <option value="option3">Option 3</option>
//                 </select>
//             )}
//         </div>
//     );
// };

// export default Testing;


// import React, { Component, useEffect, useState  } from 'react';
// import Select from 'react-select';
// import { Button } from 'react-bootstrap';
// import { API_BASE_URL} from './api_url';
// import axios from "axios";

// const Testing = () => {
//   const [showInternalList,setShowInternalList] = useState([]); // for all internal

//     // get all internal-editors
//     const getAllInternal = async () => {
//       try {
//         let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`) 
//         if (apiRes) 
//         {
//           console.log(apiRes.data.res);
//           if (apiRes.data.res == true) 
//           {
//             console.log(apiRes.data.data);
//             setShowInternalList(apiRes.data.data);
//           }
//         }
//       }  //close try
//       catch (error) {
//         // NotificationManager.error(error, "", 2000);
//       }
//   };
//   useEffect(()=>{
//     getAllInternal();
//   },[])
//   // Convert showInternalList to options format for React Select
//   const options = showInternalList.map((item) => ({
//     value: item.id,
//     label: `${item.first_name} ${item.last_name}`,
//   }));

//   const handleSingleJobRelease = (id) => {
//     console.log('Releasing job with id:', id);
//   };

//   // Programmatically open the dropdown
//   const customSelectRef = React.useRef(null);

//   const openMenu = () => {
//     if (customSelectRef.current) {
//       customSelectRef.current.focus();
//     }
//   };

//   return (
//     <tr>
//       <td>
//         <Button
//           className="CmnBtn"
//           type="button"
//           name="release"
//           // onClick={() => handleSingleJobRelease(item?.id)}
//         >
//           Release
//         </Button>
//         <Button className="CmnBtn" type="button" onClick={openMenu}>
//           Assign
//         </Button>
//         <Button className="accept-job-link" type="button">
//           Accept
//         </Button>
//       </td>
//       <td>
//         <div>
//           <Select
//             ref={customSelectRef}
//             options={options}
//             placeholder="--Reviewers--"
//             onFocus={() => customSelectRef.current?.setState({ menuIsOpen: true })}
//             // You can also control it directly via state if needed
//           />
//         </div>
//       </td>
//     </tr>
//   );
// };

// export default Testing;

// import React, { useEffect, useState, useRef } from 'react';
// import Select from 'react-select';
// import { Button } from 'react-bootstrap';
// import axios from 'axios';
// import { API_BASE_URL } from './api_url';

// const Testing = () => {
//   const [showInternalList, setShowInternalList] = useState([]); // for all internal
//   const [menuIsOpen, setMenuIsOpen] = useState(false); // control the dropdown menu open state
//   const customSelectRef = useRef(null);

//   // Fetch all internal editors
//   const getAllInternal = async () => {
//     try {
//       let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`);
//       if (apiRes && apiRes.data.res === true) {
//         console.log(apiRes.data.data);
//         setShowInternalList(apiRes.data.data);
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching editor list", error);
//     }
//   };

//   useEffect(() => {
//     getAllInternal();
//   }, []);

//   // Convert showInternalList to options format for React Select
//   const options = showInternalList.map((item) => ({
//     value: item.id,
//     label: `${item.first_name} ${item.last_name}`,
//   }));

//   const handleSingleJobRelease = (id) => {
//     console.log('Releasing job with id:', id);
//   };

//   // Programmatically open the dropdown
//   const openMenu = () => {
//     setMenuIsOpen(true); // set menuIsOpen to true to open the menu
//     if (customSelectRef.current) {
//       customSelectRef.current.focus();
//     }
//   };

//   return (
//     <tr>
//       <td>
//         <Button
//           className="CmnBtn"
//           type="button"
//           name="release"
//           // Uncomment and use this line if you have item data available
//           // onClick={() => handleSingleJobRelease(item?.id)}
//         >
//           Release
//         </Button>
//         <Button className="CmnBtn" type="button" onClick={openMenu}>
//           Assign
//         </Button>
//         <Button className="accept-job-link" type="button">
//           Accept
//         </Button>
//       </td>
//       <td>
//         <div>
//           <Select
//             ref={customSelectRef}
//             options={options}
//             placeholder="--Reviewers--"
//             menuIsOpen={menuIsOpen} // control the menu state with state
//             onMenuClose={() => setMenuIsOpen(false)} // close the menu when user clicks away
//             onChange={() => setMenuIsOpen(false)} // optionally close the menu on selection
//           />
//         </div>
//       </td>
//     </tr>
//   );
// };

// export default Testing;


// import React, { useEffect, useState, useRef } from 'react';
// import Select from 'react-select';
// import { Button } from 'react-bootstrap';
// import axios from 'axios';
// import { API_BASE_URL } from './api_url';

// const Testing = () => {
//   const [showInternalList, setShowInternalList] = useState([]); // for all internal
//   const [menuIsOpen, setMenuIsOpen] = useState(false); // control the dropdown menu open state
//   const customSelectRef = useRef(null);

//   // Fetch all internal editors
//   const getAllInternal = async () => {
//     try {
//       let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`);
//       if (apiRes && apiRes.data.res === true) {
//         console.log(apiRes.data.data);
//         setShowInternalList(apiRes.data.data);
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching editor list", error);
//     }
//   };

//   useEffect(() => {
//     getAllInternal();
//   }, []);

//   // Convert showInternalList to options format for React Select
//   const options = showInternalList.map((item) => ({
//     value: item.id,
//     label: `${item.first_name} ${item.last_name}`,
//   }));

//   const handleSingleJobRelease = (id) => {
//     console.log('Releasing job with id:', id);
//   };

//   // Programmatically open the dropdown
//   const openMenu = () => {
//     setMenuIsOpen(true); // set menuIsOpen to true to open the menu
//     if (customSelectRef.current) {
//       customSelectRef.current.focus();
//     }
//   };

//   return (
//     <tr>
//       <td>
//         <Button
//           className="CmnBtn"
//           type="button"
//           name="release"
//           // Uncomment and use this line if you have item data available
//           // onClick={() => handleSingleJobRelease(item?.id)}
//         >
//           Release
//         </Button>
//         <Button className="CmnBtn" type="button" onClick={openMenu}>
//           Assign
//         </Button>
//         <Button className="accept-job-link" type="button">
//           Accept
//         </Button>
//       </td>
//       <td>
//         <div>
//           <Select
//             ref={customSelectRef}
//             options={options}
//             placeholder="--Reviewers--"
//             menuIsOpen={menuIsOpen} // control the menu state with state
//             isSearchable={false} // disable typing/searching
//             onMenuClose={() => setMenuIsOpen(false)} // close the menu when user clicks away
//             onChange={() => setMenuIsOpen(false)} // optionally close the menu on selection
//           />
//         </div>
//       </td>
//     </tr>
//   );
// };

// export default Testing;


// import React, { useEffect, useState, useRef } from 'react';
// import Select from 'react-select';
// import { Button } from 'react-bootstrap';
// import axios from 'axios';
// import { API_BASE_URL } from './api_url';

// const Testing = () => {
//   const [showInternalList, setShowInternalList] = useState([]); // for all internal
//   const [menuIsOpen, setMenuIsOpen] = useState(false); // control the dropdown menu open state
//   const customSelectRef = useRef(null);

//   // Fetch all internal editors
//   const getAllInternal = async () => {
//     try {
//       let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`);
//       if (apiRes && apiRes.data.res === true) {
//         console.log(apiRes.data.data);
//         setShowInternalList(apiRes.data.data);
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching editor list", error);
//     }
//   };

//   useEffect(() => {
//     getAllInternal();
//   }, []);

//   // Convert showInternalList to options format for React Select
//   const options = showInternalList.map((item) => ({
//     value: item.id,
//     label: `${item.first_name} ${item.last_name}`,
//   }));

//   const handleSingleJobRelease = (id) => {
//     console.log('Releasing job with id:', id);
//   };

//   // Toggle dropdown visibility
//   const toggleMenu = () => {
//     setMenuIsOpen((prevMenuIsOpen) => !prevMenuIsOpen); // Toggle the menu state
//     if (customSelectRef.current) {
//       customSelectRef.current.focus();
//     }
//   };

//     // Handle item selection
//     const handleChange = (selectedOption) => {
//       if (selectedOption) {
//         alert(`Selected: ${selectedOption.label}`);
//       }
//       setMenuIsOpen(false); // Optionally close the menu after selection
//     };
    
//   return (
//     <tr>
//       <td>
//         <Button
//           className="CmnBtn"
//           type="button"
//           name="release"
//           // Uncomment and use this line if you have item data available
//           // onClick={() => handleSingleJobRelease(item?.id)}
//         >
//           Release
//         </Button>
//         <Button className="CmnBtn" type="button" onClick={toggleMenu}>
//           Assign
//         </Button>
//         <Button className="accept-job-link" type="button">
//           Accept
//         </Button>
//       </td>
//       <td>
//         <div>
//           <Select
//             ref={customSelectRef}
//             options={options}
//             placeholder="--Reviewers--"
//             isSearchable={false} // disable typing/searching
//             menuIsOpen={menuIsOpen} // control the menu state with state
//             onMenuOpen={() => setMenuIsOpen(true)} // ensure menu opens on dropdown click
//             onMenuClose={() => setMenuIsOpen(false)} // close the menu when user clicks away
//             // onChange={(e) => {setMenuIsOpen(false);alert(e.target.value)}} // optionally close the menu on selection
//             onChange={handleChange} // handle item selection
//             onFocus={() => setMenuIsOpen(true)} // open the menu when the dropdown is focused
//           />
//         </div>
//       </td>
//     </tr>
//   );
// };

// import React, { useState } from 'react';

// const Testing = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(null);

//   // Static options
//   const options = [
//     { value: '1', label: 'Option 1' },
//     { value: '2', label: 'Option 2' },
//     { value: '3', label: 'Option 3' },
//   ];

//   const handleToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelect = (option) => {
//     setSelectedOption(option);
//     setIsOpen(false);
//     alert(`Selected: ${option.label}`);
//   };

//   // Inline styles
//   const dropdownStyles = {
//     position: 'relative',
//     display: 'inline-block',
//   };

//   const buttonStyles = {
//     backgroundColor: '#f8f9fa',
//     border: '1px solid #ced4da',
//     padding: '10px',
//     cursor: 'pointer',
//   };

//   const menuStyles = {
//     position: 'absolute',
//     backgroundColor: '#ffffff',
//     border: '1px solid #ced4da',
//     listStyle: 'none',
//     padding: '0',
//     margin: '0',
//     width: '100%',
//     zIndex: 1,
//     maxHeight: '200px',
//     overflowY: 'auto',
//   };

//   const menuItemStyles = {
//     padding: '10px',
//     cursor: 'pointer',
//   };

//   const menuItemHoverStyles = {
//     backgroundColor: '#e9ecef',
//   };

//   return (
//     <div style={dropdownStyles}>
//       <button style={buttonStyles} onClick={handleToggle}>
//         {selectedOption ? selectedOption.label : 'Select an option'}
//       </button>
//       {isOpen && (
//         <ul style={menuStyles}>
//           {options.map((option) => (
//             <li
//               key={option.value}
//               style={menuItemStyles}
//               onMouseEnter={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
//               onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
//               onClick={() => handleSelect(option)}
//             >
//               {option.label}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default Testing;

// import React, { useRef, useState } from 'react';
// import { Form, Button } from 'react-bootstrap';

// const Testing = () => {
//   const showInternalList = [
//     { id: 1, first_name: 'John', last_name: 'Doe' },
//     { id: 2, first_name: 'Jane', last_name: 'Smith' },
//   ];

//   const handleAssignReviewer = (value, itemId, action) => {
//     console.log(`Reviewer ID: ${value}, Item ID: ${itemId}, Action: ${action}`);
//   };

//   const item = { id: 123, reviewer: null }; // Example item

//   return (
//     <div style={styles.appContainer}>
//       <AlwaysOpenDropdown
//         showInternalList={showInternalList}
//         handleAssignReviewer={handleAssignReviewer}
//         item={item}
//       />
//     </div>
//   );
// };

// const AlwaysOpenDropdown = ({ showInternalList, handleAssignReviewer, item }) => {
//   const selectRef = useRef();
//   const [isOpen, setIsOpen] = useState(false);

//   const handleButtonClick = () => {
//     if (selectRef.current) {
//       setIsOpen(!isOpen);
//       selectRef.current.size = isOpen ? 1 : selectRef.current.length;
//     }
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Button onClick={handleButtonClick} style={styles.button}>
//         {isOpen ? 'Close Dropdown' : 'Open Dropdown'}
//       </Button>
//       <Form.Group style={styles.formGroup}>
//         <Form.Select
//           name="subservice"
//           ref={selectRef}
//           onChange={(e) => handleAssignReviewer(e.target.value, item?.id, 'Assign')}
//           size={isOpen ? showInternalList.length : 1}
//           style={styles.select}
//         >
//           <option selected hidden>--Reviewers--</option>
//           {showInternalList?.map((item1, index1) => (
//             <option
//               selected={item?.reviewer != null}
//               key={index1}
//               value={item1?.id}
//             >
//               {item1?.first_name} {item1?.last_name}
//             </option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//     </div>
//   );
// };

// // Styles in the same component
// const styles = {
//   appContainer: {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//   },
//   dropdownContainer: {
//     margin: '20px 0',
//     textAlign: 'center',
//   },
//   button: {
//     marginBottom: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   formGroup: {
//     margin: '0 auto',
//     width: '50%',
//   },
//   select: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   },
// };

// export default Testing;

// import React, { useRef, useState } from 'react';
// import { Form, Button } from 'react-bootstrap';

// const Testing = () => {
//   const showInternalList = [
//     { id: 1, first_name: 'John', last_name: 'Doe' },
//     { id: 2, first_name: 'Jane', last_name: 'Smith' },
//   ];

//   const handleAssignReviewer = (value, itemId, action) => {
//     console.log(`Reviewer ID: ${value}, Item ID: ${itemId}, Action: ${action}`);
//   };

//   const item = { id: 123, reviewer: null }; // Example item

//   return (
//     <div style={styles.appContainer}>
//       <AlwaysOpenDropdown
//         showInternalList={showInternalList}
//         handleAssignReviewer={handleAssignReviewer}
//         item={item}
//       />
//     </div>
//   );
// };

// const AlwaysOpenDropdown = ({ showInternalList, handleAssignReviewer, item }) => {
//   const selectRef = useRef();
//   const [isOpen, setIsOpen] = useState(false);

//   const handleButtonClick = () => {
//     if (selectRef.current) {
//       setIsOpen(!isOpen);
//       selectRef.current.size = isOpen ? 1 : selectRef.current.length;
//     }
//   };

//   const handleSelectChange = (e) => {
//     handleAssignReviewer(e.target.value, item?.id, 'Assign');
//     // Close the dropdown after selection
//     setIsOpen(false);
//     if (selectRef.current) {
//       selectRef.current.size = 1;
//     }
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Button onClick={handleButtonClick} style={styles.button}>
//         {isOpen ? 'Close Dropdown' : 'Open Dropdown'}
//       </Button>
//       <Form.Group style={styles.formGroup}>
//         <Form.Select
//           name="subservice"
//           ref={selectRef}
//           onChange={handleSelectChange}
//           size={isOpen ? showInternalList.length : 1}
//           style={styles.select}
//         >
//           <option selected hidden>--Reviewers--</option>
//           {showInternalList?.map((item1, index1) => (
//             <option
//               selected={item?.reviewer != null}
//               key={index1}
//               value={item1?.id}
//             >
//               {item1?.first_name} {item1?.last_name}
//             </option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//     </div>
//   );
// };

// // Styles in the same component
// const styles = {
//   appContainer: {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//   },
//   dropdownContainer: {
//     margin: '20px 0',
//     textAlign: 'center',
//   },
//   button: {
//     marginBottom: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   formGroup: {
//     margin: '0 auto',
//     width: '50%',
//   },
//   select: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   },
// };

// export default Testing;

// import React, { useRef, useState } from 'react';
// import { Form, Button } from 'react-bootstrap';

// const Testing = () => {
//   const showInternalList = [
//     { id: 1, first_name: 'John', last_name: 'Doe' },
//     { id: 2, first_name: 'Jane', last_name: 'Smith' },
//   ];

//   const handleAssignReviewer = (value, itemId, action) => {
//     console.log(`Reviewer ID: ${value}, Item ID: ${itemId}, Action: ${action}`);
//   };

//   const item = { id: 123, reviewer: null }; // Example item

//   return (
//     <div style={styles.appContainer}>
//       <AlwaysOpenDropdown
//         showInternalList={showInternalList}
//         handleAssignReviewer={handleAssignReviewer}
//         item={item}
//       />
//     </div>
//   );
// };

// const AlwaysOpenDropdown = ({ showInternalList, handleAssignReviewer, item }) => {
//   const selectRef = useRef();
//   const [isOpen, setIsOpen] = useState(false);

//   const handleButtonClick = () => {
//     if (selectRef.current) {
//       setIsOpen(!isOpen);
//       // Focus on the dropdown so it behaves like a native dropdown
//       selectRef.current.focus();
//       selectRef.current.size = isOpen ? 1 : selectRef.current.length;
//     }
//   };

//   const handleSelectChange = (e) => {
//     handleAssignReviewer(e.target.value, item?.id, 'Assign');
//     setIsOpen(false);
//     if (selectRef.current) {
//       selectRef.current.size = 1;
//     }
//   };

//   const handleBlur = () => {
//     // Close the dropdown when it loses focus
//     setIsOpen(false);
//     if (selectRef.current) {
//       selectRef.current.size = 1;
//     }
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Button onClick={handleButtonClick} style={styles.button}>
//         {isOpen ? 'Close Dropdown' : 'Open Dropdown'}
//       </Button>
//       <Form.Group style={styles.formGroup}>
//         <Form.Select
//           name="subservice"
//           ref={selectRef}
//           onChange={handleSelectChange}
//           onBlur={handleBlur}  // Close dropdown when focus is lost
//           size={isOpen ? showInternalList.length : 1}
//           style={styles.select}
//         >
//           <option selected hidden>--Reviewers--</option>
//           {showInternalList?.map((item1, index1) => (
//             <option
//               selected={item?.reviewer != null}
//               key={index1}
//               value={item1?.id}
//             >
//               {item1?.first_name} {item1?.last_name}
//             </option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//     </div>
//   );
// };

// // Styles in the same component
// const styles = {
//   appContainer: {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//   },
//   dropdownContainer: {
//     margin: '20px 0',
//     textAlign: 'center',
//   },
//   button: {
//     marginBottom: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   formGroup: {
//     margin: '0 auto',
//     width: '50%',
//   },
//   select: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   },
// };

// export default Testing;
// #done

// import React, { useState } from 'react';
// import { Button } from 'react-bootstrap';

// const Testing = () => {
//   const showInternalList = [
//     { id: 1, first_name: 'John', last_name: 'Doe' },
//     { id: 2, first_name: 'Jane', last_name: 'Smith' },
//     { id: 3, first_name: 'Alice', last_name: 'Johnson' },
//     { id: 4, first_name: 'Bob', last_name: 'Brown' },
//   ];

//   const handleAssignReviewer = (value, itemId, action) => {
//     console.log(`Reviewer ID: ${value}, Item ID: ${itemId}, Action: ${action}`);
//   };

//   const item = { id: 123, reviewer: null }; // Example item

//   return (
//     <div style={styles.appContainer}>
//       <CustomDropdown
//         showInternalList={showInternalList}
//         handleAssignReviewer={handleAssignReviewer}
//         item={item}
//       />
//     </div>
//   );
// };

// const CustomDropdown = ({ showInternalList, handleAssignReviewer, item }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedValue, setSelectedValue] = useState('');

//   const handleButtonClick = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (value) => {
//     setSelectedValue(value);
//     handleAssignReviewer(value, item?.id, 'Assign');
//     setIsOpen(false);
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Button onClick={handleButtonClick} style={styles.button}>
//         {selectedValue ? showInternalList.find(item => item.id === selectedValue)?.first_name : 'Select Reviewer'}
//       </Button>
//       {isOpen && (
//         <div style={styles.dropdownMenu}>
//           {showInternalList.map((item1) => (
//             <div
//               key={item1.id}
//               style={styles.dropdownOption}
//               onClick={() => handleOptionClick(item1.id)}
//             >
//               {item1.first_name} {item1.last_name}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// // Styles in the same component
// const styles = {
//   appContainer: {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//   },
//   dropdownContainer: {
//     margin: '20px 0',
//     textAlign: 'center',
//     position: 'relative',
//   },
//   button: {
//     marginBottom: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     width: '200px',
//     textAlign: 'left',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   dropdownMenu: {
//     position: 'absolute',
//     top: '100%',
//     left: 0,
//     width: '100%',
//     border: '1px solid #ccc',
//     borderRadius: '5px',
//     backgroundColor: '#fff',
//     zIndex: 1000,
//     maxHeight: '150px',
//     overflowY: 'auto',
//   },
//   dropdownOption: {
//     padding: '10px',
//     cursor: 'pointer',
//     borderBottom: '1px solid #ddd',
//   },
//   dropdownOptionHover: {
//     backgroundColor: '#f1f1f1',
//   },
// };

// export default Testing; last-edit

// src/App.js
import React from "react";
// import "./App.css"; // Import the CSS for styling FontAwesome icons

const Testing = () => {
  return (
    <div style={styles.container}>
      <div style={styles.loginBox}>
        <h2 style={styles.title}>Login</h2>
        <p style={styles.subtitle}>Please enter your Login and your Password</p>
        <form>
          <div style={styles.inputContainer}>
            <i className="fas fa-user" style={styles.icon}></i>
            <input
              type="text"
              placeholder="Username or E-mail"
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputContainer}>
            <i className="fas fa-key" style={styles.icon}></i>
            <input
              type="password"
              placeholder="Password"
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.loginButton}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#0059b3",
  },
  loginBox: {
    backgroundColor: "#1a73e8",
    padding: "40px",
    borderRadius: "10px",
    textAlign: "center",
    color: "#fff",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: "20px",
    fontSize: "2.5rem",
  },
  subtitle: {
    marginBottom: "30px",
    fontSize: "1.1rem",
  },
  inputContainer: {
    position: "relative",
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "15px",
    paddingLeft: "40px",
    border: "none",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
    fontSize: "1.2rem",
    color: "gray",
  },
  loginButton: {
    backgroundColor: "#21ba45",
    color: "#fff",
    padding: "15px",
    width: "100%",
    border: "none",
    borderRadius: "5px",
    fontSize: "1.2rem",
    cursor: "pointer",
    transition: "0.3s",
  },
};

export default Testing;








// import React, { useRef, useState } from 'react';
// import { Form, Button } from 'react-bootstrap';

// const Testing = () => {
//   const showInternalList = [
//     { id: 1, first_name: 'John', last_name: 'Doe' },
//     { id: 2, first_name: 'Jane', last_name: 'Smith' },
//   ];

//   const handleAssignReviewer = (value, itemId, action) => {
//     console.log(`Reviewer ID: ${value}, Item ID: ${itemId}, Action: ${action}`);
//   };

//   const item = { id: 123, reviewer: null }; // Example item

//   return (
//     <div style={styles.appContainer}>
//       <AlwaysOpenDropdown
//         showInternalList={showInternalList}
//         handleAssignReviewer={handleAssignReviewer}
//         item={item}
//       />
//     </div>
//   );
// };

// const AlwaysOpenDropdown = ({ showInternalList, handleAssignReviewer, item }) => {
//   const selectRef = useRef();
//   const [isOpen, setIsOpen] = useState(false);

//   const handleButtonClick = () => {
//     if (selectRef.current) {
//       setIsOpen(!isOpen);
//       selectRef.current.focus();
//       // Trigger a click on the select element to simulate opening
//       const event = new MouseEvent('mousedown', { bubbles: true });
//       selectRef.current.dispatchEvent(event);
//     }
//   };

//   const handleSelectChange = (e) => {
//     handleAssignReviewer(e.target.value, item?.id, 'Assign');
//     setIsOpen(false);
//     if (selectRef.current) {
//       selectRef.current.size = 1;
//     }
//   };

//   const handleBlur = () => {
//     setIsOpen(false);
//     if (selectRef.current) {
//       selectRef.current.size = 1;
//     }
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Button onClick={handleButtonClick} style={styles.button}>
//         {isOpen ? 'Close Dropdown' : 'Open Dropdown'}
//       </Button>
//       <Form.Group style={styles.formGroup}>
//         <Form.Select
//           name="subservice"
//           ref={selectRef}
//           onChange={handleSelectChange}
//           onBlur={handleBlur}
//           size={1}
//           style={styles.select}
//         >
//           <option selected hidden>--Reviewers--</option>
//           {showInternalList?.map((item1, index1) => (
//             <option
//               selected={item?.reviewer != null}
//               key={index1}
//               value={item1?.id}
//             >
//               {item1?.first_name} {item1?.last_name}
//             </option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//     </div>
//   );
// };

// // Styles in the same component
// const styles = {
//   appContainer: {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//   },
//   dropdownContainer: {
//     margin: '20px 0',
//     textAlign: 'center',
//   },
//   button: {
//     marginBottom: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   formGroup: {
//     margin: '0 auto',
//     width: '50%',
//   },
//   select: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   },
// };

// export default Testing;

// import React, { useRef } from 'react';
// import { Form, Button } from 'react-bootstrap';

// const Testing = () => {
//   const showInternalList = [
//     { id: 1, first_name: 'John', last_name: 'Doe' },
//     { id: 2, first_name: 'Jane', last_name: 'Smith' },
//   ];

//   const handleAssignReviewer = (value, itemId, action) => {
//     console.log(`Reviewer ID: ${value}, Item ID: ${itemId}, Action: ${action}`);
//   };

//   const item = { id: 123, reviewer: null }; // Example item

//   return (
//     <div style={styles.appContainer}>
//       <AlwaysOpenDropdown
//         showInternalList={showInternalList}
//         handleAssignReviewer={handleAssignReviewer}
//         item={item}
//       />
//     </div>
//   );
// };

// const AlwaysOpenDropdown = ({ showInternalList, handleAssignReviewer, item }) => {
//   const selectRef = useRef();

//   const handleButtonClick = () => {
//     if (selectRef.current) {
//       // Focus on the dropdown and trigger a click event
//       selectRef.current.focus();
//       const event = new MouseEvent('mousedown', { bubbles: true });
//       selectRef.current.dispatchEvent(event);
//     }
//   };

//   const handleSelectChange = (e) => {
//     handleAssignReviewer(e.target.value, item?.id, 'Assign');
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Button onClick={handleButtonClick} style={styles.button}>
//         Open Dropdown
//       </Button>
//       <Form.Group style={styles.formGroup}>
//         <Form.Select
//           name="subservice"
//           ref={selectRef}
//           onChange={handleSelectChange}
//           style={styles.select}
//         >
//           <option selected hidden>--Reviewers--</option>
//           {showInternalList?.map((item1, index1) => (
//             <option
//               selected={item?.reviewer != null}
//               key={index1}
//               value={item1?.id}
//             >
//               {item1?.first_name} {item1?.last_name}
//             </option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//     </div>
//   );
// };

// // Styles in the same component
// const styles = {
//   appContainer: {
//     padding: '20px',
//     fontFamily: 'Arial, sans-serif',
//   },
//   dropdownContainer: {
//     margin: '20px 0',
//     textAlign: 'center',
//   },
//   button: {
//     marginBottom: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   formGroup: {
//     margin: '0 auto',
//     width: '50%',
//   },
//   select: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   },
// };

// export default Testing;
// ff


// import React, { useRef, useState } from 'react';
// import ReactDOM from 'react-dom';
// import Modal from 'react-modal';

// // Define custom styles for the modal
// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '300px',
//     height: '200px',
//   },
// };

// // Set the app element to ensure accessibility compliance
// Modal.setAppElement('#root'); // Adjust the ID if your root element has a different ID

// const Testing = () => {
//   // Define state and reference hooks
//   const [modalIsOpen, setIsOpen] = useState(false);
//   const subtitle = useRef(null);

//   // Function to open the modal
//   const openModal = () => {
//     setIsOpen(true);
//   };

//   // Function to perform actions after the modal opens
//   const afterOpenModal = () => {
//     // Change the color of the subtitle when the modal is opened
//     if (subtitle.current) {
//       subtitle.current.style.color = '#f00';
//     }
//   };

//   // Function to close the modal
//   const closeModal = () => {
//     setIsOpen(false);
//   };

//   return (
//     <div>
//       <button onClick={openModal}>Open Modal</button>
//       <Modal
//         isOpen={modalIsOpen}
//         onAfterOpen={afterOpenModal}
//         onRequestClose={closeModal}
//         style={customStyles}
//         contentLabel="Example Modal"
//       >
//         <h2 ref={subtitle}>Hello</h2>
//         <button onClick={closeModal}>close</button>
//         <div>I am a modal</div>
//         <form>
//           <input />
//           <button type="button">tab navigation</button>
//           <button type="button">stays</button>
//           <button type="button">inside</button>
//           <button type="button">the modal</button>
//         </form>
//       </Modal>
//     </div>
//   );
// };


// export default Testing;








// import React, { useEffect, useState } from 'react';

// const Testing = () => {
//   const [countUp, setCountUp] = useState(0);
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCountUp(prevCountUp => prevCountUp + 1000); // increment by 1000 milliseconds (1 second)
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const getReturnValues = (countUp) => {
//     console.log(countUp)
//     // calculate time elapsed
//     const days = Math.floor(countUp / (1000 * 60 * 60 * 24));
//     const hours = Math.floor((countUp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//     const minutes = Math.floor((countUp % (1000 * 60 * 60)) / (1000 * 60));
//     const seconds = Math.floor((countUp % (1000 * 60)) / 1000);
//     return [days, hours, minutes, seconds];
//   };

//   const [days, hours, minutes, seconds] = getReturnValues(countUp);

//   const formatValue = (value) => {
//     return value < 10 ? '0' + value : value;
//   };

//   return (
//     <div className="show-counter">
//       <DateTimeDisplay value={formatValue(days)} type={'D'} isDanger={days <= 3} />
//       <DateTimeDisplay value={formatValue(hours)} type={'H'} isDanger={false} />
//       <DateTimeDisplay value={formatValue(minutes)} type={'M'} isDanger={false} />
//       <DateTimeDisplay value={formatValue(seconds)} type={'S'} isDanger={false} />
//     </div>
//   );
// };

// const DateTimeDisplay = ({ value, type, isDanger }) => {
//   return (
//     <div className={isDanger ? 'countdown danger' : 'countdown'}>
//       <p>{value}</p>
//       <span>{type}</span>
//     </div>
//   );
// };

// export default Testing;




