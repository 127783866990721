import {
  React, Component, useEffect, useState, useRef,
  NavLink, useNavigate, useLocation,
  Formik, Field, 
  yup,
  axios,
  NotificationManager,
  Button,
  Modal,
  DateTime,
  DatePicker,
  CKEditor,
  ClassicEditor,

} from '../../utility/CommonImport'; // Import 

import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";

import { API_BASE_URL} from '../../api_url';
import { get_date_format } from "../../utility/Common";

const paymentBanner = require("../../assets/img/payment-banner.jpg");
const greenTickImg = require("../../assets/img/green-success-tick.png");

const Paymentsuccess = () => { 
    const navigate = useNavigate();
    const {state} = useLocation();
    console.warn(state)
    console.log(state?.OrderType);
    console.log(state?.responseData)
    console.log(state?.userDetails)
    const [paymentDetails,setPaymentDetails] = useState();
    const [userInfo,setUserInfo] = useState();
    let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
    let data= JSON.parse(localStorage.getItem("allFormData"));
    let user_data_elite= JSON.parse(localStorage.getItem("user_infos"));

    console.warn(loggedUser)
    console.warn(data)
    console.warn(user_data_elite)
    // let vat_service_price = data?.total_price + ((data?.total_price * 10)/100);
    // let vat_cv_price = parseFloat(data?.price) + ((data?.price * 10)/100);

    let vat_service_price = data?.total_price;
    let vat_cv_price = parseFloat(data?.price);
    // let vat_cv_price = parseFloat(props.data?.price) + ((props.data?.price * 10)/100);

    const calculateVAT =(price)=>{
        let vat = (parseFloat(price*10)/100);
         return vat.toFixed(2);
    }
      
    const calculateTotal =(price)=>{
      let vat = (parseFloat(price*10)/100).toFixed(2);
      let total = parseFloat(price) + parseFloat(vat);
      return total.toFixed(2);
    }

    const submitAllData = async() =>{

      let split_data = state?.cardExpire.split('/');

      if(data?.link === "cvtype")
      {
          var job_title = '#' + ' ' +' ' + data?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ data?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week';
          var full_name = user_data_elite.first_name + ' ' + user_data_elite.last_name;
          try 
          {
              const formData = new FormData();
              // setLoading(true);
              
              formData.append('user_id',loggedUser?.id);// customer_id
              formData.append('service_name',data?.service_name);
              formData.append('english_version',data?.english_version);
              formData.append('service_filename',data?.service_filename);
              formData.append('number_of_pages',data?.pages);
              formData.append('total_wordcount',data?.total_wordcount);
              // formData.append('cv_price',(Number(data?.price)));
              formData.append('cv_price',(parseFloat(vat_cv_price).toFixed(2)));
              formData.append('total_price',0);
              // formData.append('service_hour',data?.service_hour);
              formData.append('service_hour',168); // for a week hours
              formData.append('service_type',data?.service_type);
              formData.append('client_instruction',data?.client_instruction);
              formData.append('instruction_filename',data?.instruction_filename);
              formData.append('from_count',data?.from_count);
              formData.append('to_count',data?.to_count);
              formData.append('job_type','type2');
              formData.append('job_request','0');
  
              formData.append('number',state?.cardNumber);
              formData.append('exp_month',split_data[0]);
              formData.append('exp_year',split_data[1]);
              formData.append('cvc',state?.cvc);
  
              formData.append('line1',user_data_elite.address);
              formData.append('postal_code',user_data_elite.zipcode);
              formData.append('city','');
              formData.append('state',user_data_elite.state);
              formData.append('country',user_data_elite.country);
              formData.append('email',user_data_elite.email_address);
              formData.append('name',full_name);
              formData.append('amount',(parseFloat(vat_cv_price).toFixed(2)));
              formData.append('description',job_title);
              formData.append('JobId', state?.jobid);

  
              // cv_price   , number_of_pages
              const config = {     
                  headers: { 'content-type': 'multipart/form-data' }
              }
              // let apiRes = await axios.post(`${API_BASE_URL}cvresume`,formData,config) 
              let apiRes = await axios.post(`${API_BASE_URL}job/paymentThanksMail`,formData,config) 
              if (apiRes) 
              {
                  console.log(apiRes.data.res);
                  if (apiRes.data.res == true) 
                  {
                      setPaymentDetails(apiRes.data.data);
                      setUserInfo(apiRes.data.user);
                      // navigate('/client-my-orders');
                  }
                  else
                  {
                      // navigate('/client-my-orders');
                  }
              }
          } //try closed
          catch (error) 
          {
              // navigate('/client-my-orders');
          } 
      }
      else if(data?.link === "exisingtype")
      {
          // var job_title = '#' + ' ' + '|' +' ' + data?.service_name +' ' + '|' +' ' + data?.sub_service +' ' + '|' +' ' +'Word Count' + data?.total_wordcount +' ' + '|' + ' ' + data?.service_type + ' ' + '|' + data?.service_hour + ' '  +'hrs'
          var job_title = '#' + ' ' + '|' +' ' + data?.service_name +' ' + '|' +' ' + data?.sub_service +' ' + '|' +' ' +'Word Count' + data?.total_wordcount +' ' + '|' + ' ' + data?.service_type + ' ' + '|' + data?.service_hour + ' '  +'hrs'
          var full_name = user_data_elite.first_name + ' ' + user_data_elite.last_name;
          if(data?.role === '1')
          {
              // alert('Editor Selected')
              try
              {
                  const formData = new FormData();
                  formData.append('user_id',loggedUser?.id);// customer_id
                  formData.append('service_id','2');
                  formData.append('service_name',data?.service_name);
                  formData.append('sub_service',data?.sub_service);
                  formData.append('english_version',data?.english_version);
                  formData.append('service_filename',data?.service_filename);
                  formData.append('total_wordcount',data?.total_wordcount);
                  // formData.append('total_price',Math.round(Number(data?.total_price)));
                  formData.append('total_price',(parseFloat(vat_service_price).toFixed(2)));
                  formData.append('cv_price',0);
                  formData.append('service_hour',data?.service_hour);
                  formData.append('service_type',data?.service_type);
                  formData.append('client_instruction',data?.client_instruction);
                  formData.append('instruction_filename',data?.instruction_filename);
                  formData.append('full_description',data?.client_instruction);
                  formData.append('from_count',data?.from_count);
                  formData.append('to_count',data?.to_count);
                  formData.append('job_type','type1');
                  formData.append('editor_id',data?.editor_id);
                  formData.append('editor_name',data?.editor_name);
                  formData.append('editor_email',data?.editor_email);
                  formData.append('job_status',data?.editor_id === ''?1:2);
                  formData.append('job_request','1');
                  formData.append('assign_user','Editor');
  
                  formData.append('number',state?.cardNumber);
                  formData.append('exp_month',split_data[0]);
                  formData.append('exp_year',split_data[1]);
                  formData.append('cvc',state?.cvc);
  
                  formData.append('line1',user_data_elite.address);
                  formData.append('postal_code',user_data_elite.zipcode);
                  formData.append('city','');
                  formData.append('state',user_data_elite.state);
                  formData.append('country',user_data_elite.country);
                  formData.append('email',user_data_elite.email_address);
                  formData.append('name',full_name);
                  formData.append('amount',(parseFloat(vat_service_price).toFixed(2)));
                  formData.append('description',job_title);
                  formData.append('JobId', state?.jobid);

                  const config = {     
                      headers: { 'content-type': 'multipart/form-data' }
                  }
                  let apiRes = await axios.post(`${API_BASE_URL}job/paymentThanksMail`,formData,config) 
                      
                  if (apiRes) 
                  {
                      console.log(apiRes.data.res);
                      if (apiRes.data.res == true) 
                      {
                          // data.submitStatus = 1;
                          // console.log(apiRes.data);
                          // setPaymentSuccessMsg(true);
                          // NotificationManager.success('Job Created successfully','',3000);
                          // NotificationManager.success('Email has been sent to you','',2000);
                          // localStorage.setItem('allFormData', JSON.stringify(data));
                            // localStorage.setItem("allFormData");
                          // navigate("/");
      
                          // localStorage.removeItem("allFormData");
                          // localStorage.clear();
                          // navigate("/");
                          setPaymentDetails(apiRes.data.data);
                          setUserInfo(apiRes.data.user);
                          // navigate('/client-my-orders');
                      }
                      else
                      {
                          // navigate('/client-my-orders');
                      }
                  }
              }
              catch (error) 
              {
                  // NotificationManager.error(error.message, '',2000);
                  // navigate('/client-my-orders');
              } 
          }
          else
          {
              // alert('Internal Editor Selected')
              try
              {
                  const formData = new FormData();
                  formData.append('user_id',loggedUser?.id);// customer_id
                  formData.append('service_id','2');
                  formData.append('service_name',data?.service_name);
                  formData.append('sub_service',data?.sub_service);
                  formData.append('english_version',data?.english_version);
                  formData.append('service_filename',data?.service_filename);
                  formData.append('total_wordcount',data?.total_wordcount);
                  // formData.append('total_price',Math.round(Number(data?.total_price)));
                  formData.append('total_price',(parseFloat(vat_service_price).toFixed(2)));
                  formData.append('cv_price',0);
                  formData.append('service_hour',data?.service_hour);
                  formData.append('service_type',data?.service_type);
                  formData.append('client_instruction',data?.client_instruction);
                  formData.append('instruction_filename',data?.instruction_filename);
                  formData.append('full_description',data?.client_instruction);
                  formData.append('from_count',data?.from_count);
                  formData.append('to_count',data?.to_count);
                  formData.append('job_type','type1');
                  formData.append('internal_id',data?.editor_id);
                  formData.append('internal_name',data?.editor_name);
                  formData.append('internal_email',data?.editor_email);
                  formData.append('job_status',data?.editor_id === ''?1:2);
                  formData.append('job_request','1');
                  formData.append('assign_user','Internal');
  
                  formData.append('number',state?.cardNumber);
                  formData.append('exp_month',split_data[0]);
                  formData.append('exp_year',split_data[1]);
                  formData.append('cvc',state?.cvc);
  
                  formData.append('line1',user_data_elite.address);
                  formData.append('postal_code',user_data_elite.zipcode);
                  formData.append('city','');
                  formData.append('state',user_data_elite.state);
                  formData.append('country',user_data_elite.country);
                  formData.append('email',user_data_elite.email_address);
                  formData.append('name',full_name);
                  formData.append('amount',(parseFloat(vat_service_price).toFixed(2)));
                  formData.append('description',job_title);
                  formData.append('JobId', state?.jobid);

                  const config = {     
                      headers: { 'content-type': 'multipart/form-data' }
                  }
                  let apiRes = await axios.post(`${API_BASE_URL}job/paymentThanksMail`,formData,config) 
                      
                  if (apiRes) 
                  {
                      console.log(apiRes.data.res);
                      if (apiRes.data.res == true) 
                      {
                          setPaymentDetails(apiRes.data.data);
                          setUserInfo(apiRes.data.user);
                          // navigate('/client-my-orders');

                          // data.submitStatus = 1;
                          // console.log(apiRes.data);
                          // setPaymentSuccessMsg(true);
                          // NotificationManager.success('Job Created successfully','',3000);
                          // NotificationManager.success('Email has been sent to you','',2000);
                          // localStorage.setItem('allFormData', JSON.stringify(data));
                            // localStorage.setItem("allFormData");
                          // navigate("/");
      
                          // localStorage.removeItem("allFormData");
                          // localStorage.clear();
                          // navigate("/");
                      }
                      else
                      {
                          // navigate('/client-my-orders');
                      }
                  }
              }
              catch (error) 
              {
                  // NotificationManager.error(error.message, '',2000);
                  // navigate('/client-my-orders');
              } 
          }
          // return
      }
      else
      {
        // alert('3');
        var job_title = '#' + ' ' + '|' +' ' + data?.service_name +' ' + '|' +' ' + data?.sub_service +' ' + '|' +' ' +'Word Count' + data?.total_wordcount +' ' + '|' + ' ' + data?.service_type + ' ' + '|' + data?.service_hour + ' '  +'hrs'
        var full_name = user_data_elite.first_name + ' ' + user_data_elite.last_name;
        try {
          const formData = new FormData();
          formData.append('user_id',loggedUser?.id); // customer_id
          formData.append('service_id','2');
          formData.append('service_name',data?.service_name);
          formData.append('sub_service',data?.sub_service);
          formData.append('english_version',data?.english_version);
          formData.append('service_filename',data?.service_filename);
          formData.append('total_wordcount',data?.total_wordcount);
          // formData.append('total_price',(parseFloat(data?.total_price).toFixed(2)));
          formData.append('total_price',(parseFloat(vat_service_price).toFixed(2)));
          formData.append('cv_price',0);
          formData.append('service_hour',data?.service_hour);
          formData.append('service_type',data?.service_type);
          formData.append('client_instruction',data?.client_instruction);
          formData.append('instruction_filename',data?.instruction_filename);
          formData.append('full_description',data?.client_instruction);
          formData.append('from_count',data?.from_count);
          formData.append('to_count',data?.to_count);
          formData.append('job_type','type1');
          formData.append('job_request','0');

          formData.append('number',state?.cardNumber);
          formData.append('exp_month',split_data[0]);
          formData.append('exp_year',split_data[1]);
          formData.append('cvc',state?.cvc);

          formData.append('line1',user_data_elite.address);
          formData.append('postal_code',user_data_elite.zipcode);
          formData.append('city','');
          formData.append('state',user_data_elite.state);
          formData.append('country',user_data_elite.country);
          formData.append('email',user_data_elite.email_address);
          formData.append('name',full_name);
          formData.append('amount',(parseFloat(vat_service_price).toFixed(2)));
          formData.append('description',job_title);
          formData.append('JobId', state?.jobid);

          // cv_price   , number_of_pages   
          const config = {     
              headers: { 'content-type': 'multipart/form-data' }
          }
          let apiRes = await axios.post(`${API_BASE_URL}job/paymentThanksMail`,formData,config) 
          
          if (apiRes) {
            if (apiRes.data.res == true) {
              setPaymentDetails(apiRes.data.data);
              setUserInfo(apiRes.data.user);
            }
            else{
                // navigate('/client-my-orders');
            }
          }
        }
        catch (error) {
          console.error(error);
        } 
      } 
    }

    useEffect(() => {
      AOS.init();
      submitAllData();
    },[]);

    return(
        <div>
            <Header />
            <div className="inner-small-banner" style={{backgroundImage: `url(${paymentBanner})`}}>
              <div className="container">
                <h3 data-aos="fade-up">Order Details</h3>
              </div>
            </div>
            <div className="payment-success-body">
              <div className="container">
                <div className="row">
                  <div className="gray-success-sec">
                    <h3>Order submitted successfully</h3>
                    <img src={greenTickImg} alt="" />
                  </div>
                  <div className="col-md-12 right-payment-sec">
                    <div className="new-order-summary">
                      <div className="top-sec">
                        <h3>Order Summary</h3>
                        <h5>You're all set.</h5>
                      </div>
                    </div>
                  </div>
                  
                  {state?.OrderType == "New Order" ? (
                    <div className="col-md-12">
                    <div className="card main-card">
                      <ul className="row">
                          <li className="col-md-4 right-payment-sec">
                            {data?.link=='cvtype'? (
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Order</h5>
                                  <table className="table table-one">
                                    <tr>
                                      <td>Order</td>
                                      <td className="right-text">#{state?.orderId}</td>
                                    </tr>
                                    <tr>
                                      <td>Transaction Date</td>
                                      <td className="right-text">{get_date_format(state?.transactionDate)}</td>
                                    </tr>
                                    <tr>
                                      <td>Total Price</td>
                                      <td className="right-text">${parseFloat(vat_cv_price).toFixed(2)}</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            ):(
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Order</h5>
                                  <table className="table table-one">
                                    <tr>
                                      <td>Order</td>
                                      <td className="right-text">#{state?.orderId}</td>
                                    </tr>
                                    <tr>
                                      <td>Transaction Date</td>
                                      <td className="right-text">{get_date_format(state?.transactionDate)}</td>
                                    </tr>
                                    <tr>
                                      <td>Total Price</td>
                                      <td className="right-text">${parseFloat(vat_service_price).toFixed(2)}</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            )}
                          </li>

                          <li className="col-md-4 right-payment-sec">
                            {data?.link=='cvtype'?(
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Bill To</h5>
                                  <p>
                                    {state?.userName}<br />
                                    {state?.userAddress}<br />
                                    {state?.userState}<br />
                                    {state?.userZipCode}<br />
                                    {state?.userCountry}<br />
                                  </p>
                                </div>
                              </div>
                            ):(
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Bill To</h5>
                                  <p>
                                    {state?.userName}<br />
                                    {state?.userAddress}<br />
                                    {state?.userState}<br />
                                    {state?.userZipCode}<br />
                                    {state?.userCountry}<br />
                                  </p>
                                </div>
                              </div>
                            )}
                          </li>

                        <li className="col-md-4 right-payment-sec">
                          {data?.link=='cvtype'?(
                            <div className="new-order-summary">
                              <div className="card">
                                <h5>Item Description</h5>
                                <table className="table table-two">
                                  <tbody>
                                    <tr>
                                      <td>Category</td>
                                      <td className="right-text">{data?.service_name}</td>
                                    </tr>
                                    {/* <tr>
                                      <td>Option</td>
                                      <td className="right-text">{data?.sub_service}</td>
                                    </tr> */}
                                    <tr>
                                      <td>Number of Pages</td>
                                      <td className="right-text">{data?.pages}</td>
                                    </tr>
                                    <tr>
                                      <td>Speed</td>
                                      <td className="right-text">
                                        {
                                          data?.service_type == '0' ? 'Regular' :
                                          data?.service_type == '1' ? 'Expedited':''
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                  <tr>
                                    <td>Total</td>
                                    <td className="right-text">${parseFloat(data?.price).toFixed(2)}</td>
                                  </tr>
                                  {/* <tr>
                                    <td>VAT(10%)</td>
                                    <td className="right-text">${calculateVAT(data?.price)}</td>
                                  </tr> */}
                                  {/* <tr>
                                    <td>Grand Total</td>
                                    <td className="right-text">${calculateTotal(data?.price)}</td>
                                  </tr> */}
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          ):(
                            <div className="new-order-summary">
                              <div className="card">
                                <h5>Item Description</h5>
                                <table className="table table-two">
                                  <tbody>
                                    <tr>
                                      <td>Category</td>
                                      <td className="right-text">{data?.service_name}</td>
                                    </tr>
                                    <tr>
                                      <td>Option</td>
                                      <td className="right-text">{data?.sub_service}</td>
                                    </tr>
                                    <tr>
                                      <td>Word Count</td>
                                      <td className="right-text">{data?.total_wordcount}</td>
                                    </tr>
                                    <tr>
                                      <td>Speed</td>
                                      <td className="right-text">
                                      {
                                        data?.service_type == '0' ? 'Regular' :
                                        data?.service_type == '1' ? 'Expedited':''
                                      }
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                  <tr>
                                    <td>Total</td>
                                    <td className="right-text">${parseFloat(data?.total_price).toFixed(2)}</td>
                                  </tr>
                                  {/* <tr>
                                    <td>VAT(10%)</td>
                                    <td className="right-text">${calculateVAT(data?.total_price)}</td>
                                  </tr> */}
                                  {/* <tr>
                                    <td>Grand Total</td>
                                    <td className="right-text">${calculateTotal(data?.total_price)}</td>
                                  </tr> */}
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          )}
                        </li>
                      </ul>
                    </div>
                    </div>
                  ):
                  state?.OrderType == "Redo Order" ? (
                    <div className="col-md-12">
                      <div className="card main-card">
                        <ul className="row">
                          <li className="col-md-4 right-payment-sec">
                              {data?.link=='cvtype'?(
                                <div className="new-order-summary">
                                  <div className="card">
                                    <h5>Order</h5>
                                    <table className="table table-one">
                                      <tr>
                                        <td>Order</td>
                                        <td className="right-text">#{state?.responseData?.orderId}</td>
                                      </tr>
                                      <tr>
                                        <td>Transaction Date</td>
                                        <td className="right-text">{get_date_format(state?.responseData?.transactionDate)}</td>
                                      </tr>
                                      <tr>
                                        <td>Total Price</td>
                                        <td className="right-text">${parseFloat(vat_cv_price).toFixed(2)}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              ):(
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Order</h5>
                                  <table className="table table-one">
                                    <tr>
                                      <td>Order</td>
                                      <td className="right-text">#{state?.responseData?.OrderId}</td>
                                    </tr>
                                    <tr>
                                      <td>Transaction Date</td>
                                      <td className="right-text">{get_date_format(state?.responseData?.created_at)}</td>
                                    </tr>
                                    <tr>
                                      <td>Total Price</td>
                                      <td className="right-text">$0.00</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                              )}
                          </li>

                          <li className="col-md-4 right-payment-sec">
                              {data?.link=='cvtype'?(
                                <div className="new-order-summary">
                                  <div className="card">
                                    <h5>Bill To</h5>
                                    <p>
                                    {/* Matt Reiner<br />
                                    FutureScope<br />
                                    1055 West 7th St<br />
                                    Los Angeles, CA 90017<br />
                                    United States<br /> */}

                                    {state?.userName}<br />
                                    {state?.userAddress}<br />
                                    {state?.userState}<br />
                                    {state?.userZipCode}<br />
                                    {state?.userCountry}<br />
                                    </p>
                                  </div>
                                </div>
                              ):(
                                <div className="new-order-summary">
                                  <div className="card">
                                    <h5>Bill To</h5>
                                    <p>
                                      {state?.userDetails?.first_name} {state?.userDetails?.last_name}<br />
                                      {state?.userDetails?.email_address}<br />
                                      {state?.userDetails?.state}<br />
                                      {state?.userDetails?.zipcode}<br />
                                      {state?.userDetails?.country}<br />
                                    </p>
                                  </div>
                                </div>
                              )}
                          </li>

                          <li className="col-md-4 right-payment-sec">
                              {data?.link=='cvtype'?(
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Item Description</h5>
                                  <table className="table table-two">
                                    <tbody>
                                      <tr>
                                        <td>Category</td>
                                        <td className="right-text">{data?.service_name}</td>
                                      </tr>
                                      {/* <tr>
                                        <td>Option</td>
                                        <td className="right-text">{data?.sub_service}</td>
                                      </tr> */}
                                      <tr>
                                        <td>Number of Pages</td>
                                        <td className="right-text">{data?.pages}</td>
                                      </tr>
                                      <tr>
                                        <td>Speed</td>
                                        <td className="right-text">
                                          {
                                            data?.service_type == '0' ? 'Regular' :
                                            data?.service_type == '1' ? 'Expedited':''
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                      <td>Total</td>
                                      <td className="right-text">${parseFloat(data?.price).toFixed(2)}</td>
                                    </tr>
                                    {/* <tr>
                                      <td>VAT(10%)</td>
                                      <td className="right-text">${calculateVAT(data?.price)}</td>
                                    </tr> */}
                                    {/* <tr>
                                      <td>Grand Total</td>
                                      <td className="right-text">${calculateTotal(data?.price)}</td>
                                    </tr> */}
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                              ):(
                              <div className="new-order-summary">
                                <div className="card">
                                  <h5>Item Description</h5>
                                  <table className="table table-two">
                                    <tbody>
                                      <tr>
                                        <td>Category</td>
                                        <td className="right-text">{data?.service_name}</td>
                                      </tr>
                                      <tr>
                                        <td>Option</td>
                                        <td className="right-text">{data?.sub_service}</td>
                                      </tr>
                                      <tr>
                                        <td>Word Count</td>
                                        <td className="right-text">{data?.total_wordcount}</td>
                                      </tr>
                                      <tr>
                                        <td>Speed</td>
                                        <td className="right-text">
                                        {
                                          data?.service_type == '0' ? 'Regular' :
                                          data?.service_type == '1' ? 'Expedited':''
                                        }
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                      <td>Total</td>
                                      {/* <td className="right-text">${parseFloat(data?.total_price).toFixed(2)}</td> */}
                                      <td className="right-text">$0.00</td>
                                    </tr>
                                    {/* <tr>
                                      <td>VAT(10%)</td>
                                      <td className="right-text">${calculateVAT(data?.total_price)}</td>
                                    </tr> */}
                                    {/* <tr>
                                      <td>Grand Total</td>
                                      <td className="right-text">${calculateTotal(data?.total_price)}</td>
                                    </tr> */}
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                              )}
                          </li>

                        </ul>
                      </div>
                    </div>
                  ):'123'}

                  
                  <ul className="row order-link-list">
                    {loggedUser?.role == 0 ?(
                      <li className="col-md-12"><NavLink className="btn btn-primary" to="/admin-job-board">My Orders</NavLink></li>
                    ):
                    loggedUser?.role == 1 ?(
                      <li className="col-md-12"><NavLink className="btn btn-primary" to="/editor-job-board">My Orders</NavLink></li>
                    ):
                    loggedUser?.role == 2 ?(
                      <li className="col-md-12"><NavLink className="btn btn-primary" to="/client-my-orders">My Orders</NavLink></li>
                    ):
                    loggedUser?.role == 3 ?(
                      <li className="col-md-12"><NavLink className="btn btn-primary" to="/deputy-admin-job-board">My Orders</NavLink></li>
                    ):
                    loggedUser?.role == 4 ?(
                      <li className="col-md-12"><NavLink className="btn btn-primary" to="/internal-editor-job-board">My Orders</NavLink></li>
                    ):null}
                      
                  </ul>
                    {/* Admin: 0 Editor: 1 Client: 2 Sub Admin/Deputy Admin: 3 Internal Editor: 4 */}
                </div>
              </div>
            </div>

            <div className="home-yellow-section align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                    <h3>Still Not Sure What Service Is Right For You?</h3>
                    <p><NavLink to="/">Elite Reviser</NavLink> can handle that. 
                    <NavLink to="/contact"> Contact Us.</NavLink></p>                      
                  </div>
                  <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                    <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
        </div>
    );
};

export default Paymentsuccess;