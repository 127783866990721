import React, { Component, useState, useEffect, useRef } from "react";
import Clientheader from "../../Components/ClientComponents/Clientheader";
import Clientsidebar from "../../Components/ClientComponents/Clientsidebar";
import $ from "jquery";
import axios from "axios";
import { API_BASE_URL} from '../../api_url';
import { get_date_format } from '../../utility/Common';
import PrintableData from "../CommonPages/PrintableData";
import { useReactToPrint } from 'react-to-print';
import Example from "../../Example";
import { saveAs} from 'file-saver';

const visaImg = require("../../assets/img/visa-img.png");
const paypalImg = require("../../assets/img/paypal-img.png");
const stripeImg = require("../../assets/img/stripe-img.png");
const alipayImg = require("../../assets/img/alipay-img.png");
const masterImg = require("../../assets/img/master-card-img.png");
const signatureImg = require("../../assets/img/signature-img.png");
const docImg = require("../../assets/img/docs-img.png");
const iconImg = require("../../assets/img/image-icon.png");
const pdfImg = require("../../assets/img/pdf.png");

const Billingdetails = () => {
  const windowRef = useRef();
  const[customerData,setCustomerData] = useState();
  const [billList,setBillList] = useState([]);
  const [orderDetailsById,setOrderDetailsById] = useState();
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setInstructionFile] = useState();
  const [jobStats,setJobStats] = useState();
  const [ShowPrint,setShowPrint] = useState(false);
  // const [apiCall,setAPiCall] = useState(false);
  const [isWindowOpen, setIsWindowOpen] = useState(false)

  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  const componentRef = useRef();

    const openinvoicePnl = () => { 
      setIsWindowOpen(true)
      $(".invoicePnl").addClass("Act");
    };
    const closeInvoicePnl = () => { 
      $(".invoicePnl").removeClass("Act");
    };
    useEffect(()=>{
      getClientInfos();
      billingList();
    },[])

  // get client information
  const getClientInfos = async () => {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}account?id=${data.id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setCustomerData(apiRes.data.data[0]);
            }
        }
      } 
      catch (error)
      {
        //close try
        // NotificationManager.error(error, "", 2000);
      }
  };

  const handlePrint=()=>{
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
   setShowPrint(false);
}

useEffect(() => {
  const checkIfClickedOutside = e => {
    if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
    {
      setIsWindowOpen(false)
      closeInvoicePnl()
    }
    else
    {
      console.log('here')
      console.log(isWindowOpen)
      console.log(windowRef.current)
    }
  }
  document.addEventListener("mousedown", checkIfClickedOutside)

  return () => {
    document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
  }
}, [isWindowOpen])

//file download
const downloadFile = (item) => {
  saveAs(item);
}

//short service-file name
const shortFileName=(item) =>{
  console.log(item?.slice(62,70));
  let filename = item?.slice(62,70);
  return filename + '...';
  // return item
}

//All billing list
const billingList = async() =>{
  try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobdetailbyuser?user_id=${data.id}`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setBillList(apiRes.data.data);
            }
        }
      } 
      catch (error)
      {
        // NotificationManager.error(error, "", 2000);
      }
}

//get order details by id
const getOrderDetails= async(job_id) =>{
  try
  {
    // setAPiCall(true);
    let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}`)
    if (apiRes) 
    {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          // console.log(apiRes.data.data);
          setOrderDetailsById(apiRes.data.data[0]);
          console.log(apiRes.data.data[0]);
          setServiceFile(apiRes.data.data[0].service_filename);
          setInstructionFile(apiRes.data.data[0].instruction_filename);
          // setShowSendOption(apiRes.data.data[1].send_option);
          // fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id,apiRes.data.data[0].deputy_id);
        }
    }
  } //close try
  catch (error) 
  {
    // NotificationManager.error(error.message, "", 2000);
  }
}

//get Resume/Cv order details by id
const getOrderDetailsResumeCv= async(cv_id) =>{
  try
  {
    // setAPiCall(true);
    // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumedetail?id=${cv_id}`)
    let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${cv_id}`)
    if (apiRes) 
    {
        console.log(apiRes.data.data);
        if (apiRes.data.res == true) 
        {
          // console.log(apiRes.data.data);
          setOrderDetailsById(apiRes.data.data[0]);
          setServiceFile(apiRes.data.data[0].service_filename);
          setInstructionFile(apiRes.data.data[0].instruction_filename);
          // fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id);
        }
    }
  } //close try
  catch (error) 
  {
    // NotificationManager.error(error.message, "", 2000);
  }
}

//Calculate VAT
const calculateVAT = (price,vat) =>{
  let get_price = Number(price);
  let get_vat = Number(vat);
  let cal_vat = parseFloat((get_price * get_vat)/100).toFixed(2);
  return cal_vat;
}

//Calculate Total Price
const calculateTotalPrice = (price,vat) =>{
  let get_price = Number(price);
  let get_vat = Number(vat);
  let cal_vat = ((get_price * get_vat)/100);
  let cal_total = (parseFloat(get_price) + parseFloat(cal_vat)).toFixed(2);
  return cal_total;
}

  return (
    <div>
      <Clientheader />
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle pagetitleWithSearch MobileAlignLeft">
          <span className="TitleWrap TitleWrapNonSpce">
            <span className="NameLogo">{customerData?.first_name?.charAt(0)}{customerData?.last_name?.charAt(0)}</span>
            <h2>Billing Details</h2>
          </span>
        </div>
        <section className="section BillDtlsPnl" style={{marginTop:'5px'}}>
        <div className="complete-table-sec">
          <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table BillingDetailsTable">
            <thead>
              <tr>
                <th>Job #</th>
                <th>Task Name</th>
                <th>Billing Date</th>
                <th>Amount</th>
                <th>Payment Gateway</th>
              </tr>
            </thead>
            <tbody>
            {billList?.length > 0 ? (
              billList?.map((item,index)=>{
                return(
                  <tr>
                    <td>{item?.id}</td>
                    {(item?.job_type==='type2')?(
                      <td>
                        <p className="ListItem yet-to-asign-list" onClick={()=>{openinvoicePnl();setJobStats(item?.job_status);getOrderDetailsResumeCv(item?.id)}}>
                          {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                        </p>
                      </td>
                    ):(
                      <td>
                        <p className="ListItem yet-to-asign-list" onClick={()=>{openinvoicePnl();setJobStats(item?.job_status);getOrderDetails(item?.id)}}>
                          {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {item?.service_hour} hrs
                        </p>
                      </td>
                    )}
                    <td className="text-center"> <span className="GreenTxt">{get_date_format(item?.created_at)}</span></td>
                    <td className="text-center">
                      {
                        item?.job_type==='type2'?(
                          <>
                              ${parseFloat(Number(item?.cv_price)).toFixed(2)}
                              {/* ${parseFloat(item?.GrandTotal).toFixed(2)} */}
                          </>
                        ):(
                          <>
                              ${parseFloat(Number(item?.total_price)).toFixed(2)}
                              {/* ${parseFloat(item?.GrandTotal).toFixed(2)} */}
                          </>
                        )
                      }
                    </td>
                    <td className="text-center">
                      {
                        item?.PaymentType === 'Stripe' ? <img src={stripeImg} al="img" /> :
                        item?.PaymentType === 'Paypal' ? <img src={paypalImg} al="img" /> :
                        item?.PaymentType === 'alipay' ? <img src={alipayImg} al="img" /> :''
                      }
                        
                       {/* {  
                        billList?.payment_type === 'visa' ? <img src={visaImg} al="img" /> :
                        billList?.payment_type === 'master' ? <img src={masterImg} al="img" /> :
                        billList?.payment_type === 'visa' ? <img src={visaImg} al="img" /> : ''
                      } */}
                    </td>
                 </tr>
                )
              })
              ):(
                <tr>
                  <td className='no-data' colSpan={5} style={{background: "none"}}><p style={{textAlign:'center'}}>No Billings Found</p></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
         
        </section>
         {/* left Complete Pannel */}
         <div className="DtlsSectionWrapper invoicePnl no-bootom-footer" ref={windowRef}>
            <div className='TaskDtlsPnl no-textarea-sec'>
              <div className="HdrTop">
                <a className="CmnBtn printTag" onClick={()=>{setShowPrint(true);handlePrint()}}>
                  <i class="bi bi-printer-fill"></i> Print
                </a>
                <span className="Rht">
                  <a className="CloseBtn" onClick={closeInvoicePnl}>
                    <i className="bi bi-x" />
                  </a>
                </span>
              </div>

              <div className="MiddleBody">
                {(orderDetailsById?.job_type==="type2")?(
                    <>
                      <h2>
                        #{orderDetailsById?.id} | {orderDetailsById?.service_name} | Number of Pages {orderDetailsById?.number_of_pages} | {orderDetailsById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                      </h2>
                      <table className="table table-header">
                        <tr>
                          <td>
                            <table className="table table-left-header">
                              <tr>
                                {/* <td><span class="NameLogo">sw</span></td> */}
                                <td>
                                  <h5>{orderDetailsById?.ClientName}</h5>
                                  <p>{orderDetailsById?.address}<br />
                                    {orderDetailsById?.zipcode}</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td align="right">
                              <h3>Invoice</h3>  
                              {/* <h4>Invoice# INV-000136</h4>  */}
                              <h4>Invoice #{orderDetailsById?.invoice}</h4> 
                              <h5>Amount<br /><strong>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</strong></h5>
                          </td>
                        </tr>
                      </table>
                      {/* <table className="table table-user-details">
                        <tr>
                          <td></td>
                          <td>
                            <table className="table">
                              <tr>
                                <th>Invoice Date:</th>
                                <td>14 Dec 2022</td>
                              </tr>
                              <tr>
                                <th>Terms:</th>
                                <td>Due on Receipt</td>
                              </tr>
                              <tr>
                                <th>Due Date:</th>
                                <td>{get_date_format(orderDetailsById?.due_date)}</td>
                              </tr>
                              <tr>
                                <th>Project Name:</th>
                                <td>Corporate</td>
                              </tr>
                              <tr>
                                <th>Editor Name:</th>
                                <td>{orderDetailsById?.editor_name?orderDetailsById?.editor_name:'---'}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table> */}
                      <table className="table table-invoice-details">
                        <thead>
                          <tr>
                            <td>#</td>
                            <td>Task & Description</td>
                            <td className="text-center">Number of Pages</td>
                            <td></td>
                            <td></td>
                            <td>Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            {/* <td>Document Writing</td> */}
                            <td>{orderDetailsById?.service_name}</td>
                            <td style={{textAlign: "center"}}>{orderDetailsById?.number_of_pages}</td>
                            <td></td>
                            <td></td>
                            <td>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</td>
                          </tr>
                          {/* <tr className="sub-total-row">
                            <td colSpan={4}>&nbsp;</td>
                            <td>VAT({orderDetailsById?.vat}%)</td>
                            <td>${calculateVAT(orderDetailsById?.cv_price,orderDetailsById?.vat)}</td>
                          </tr> */}
                          <tr className="grand-total-row">
                            <td colSpan={4}>&nbsp;</td>
                            <td><b>Total</b></td>
                            {/* <td><b>${calculateTotalPrice(orderDetailsById?.GrandTotal,orderDetailsById?.vat)}</b></td> */}
                            {/* <td><b>${parseFloat(orderDetailsById?.GrandTotal).toFixed(2)}</b></td> */}
                            <td><b>${parseFloat(orderDetailsById?.cv_price).toFixed(2)}</b></td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <table className="table note-table">
                        <tr>
                          <td>
                            <p>Note<br />
                            <small>Thanks for your business.</small></p>
                          </td>
                          <td><img src={signatureImg} alt /></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <p>Tearm & Conditions<br /><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
      normal distribution of letters, as opposed to using making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</small></p>
                          </td>
                        </tr>
                      </table> */}
                    </>
                  ):(
                    <>
                      <h2>
                        #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {orderDetailsById?.sub_service} | Word Count {orderDetailsById?.total_wordcount} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {orderDetailsById?.service_hour} hrs
                      </h2>
                      <table className="table table-header">
                        <tr>
                          <td>
                            <table className="table table-left-header">
                              <tr>
                                {/* <td><span class="NameLogo">sw</span></td> */}
                                <td>
                                  <h5>{orderDetailsById?.ClientName}</h5>
                                  <p>{orderDetailsById?.address}<br />
                                    {orderDetailsById?.zipcode}</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td align="right">
                              <h3>Invoice</h3>  
                              <h4>Invoice# {orderDetailsById?.invoice}</h4> 
                              <h5>Amount<br /><strong>${parseFloat(orderDetailsById?.total_price).toFixed(2)}</strong></h5>
                          </td>
                        </tr>
                      </table>
                      {/* <table className="table table-user-details">
                        <tr>
                          <td></td>
                          <td>
                            <table className="table">
                              <tr>
                                <th>Invoice Date:</th>
                                <td>14 Dec 2022</td>
                              </tr>
                              <tr>
                                <th>Terms:</th>
                                <td>Due on Receipt</td>
                              </tr>
                              <tr>
                                <th>Due Date:</th>
                                <td>{get_date_format(orderDetailsById?.due_date)}</td>
                              </tr>
                              <tr>
                                <th>Project Name:</th>
                                <td>Corporate</td>
                              </tr>
                              <tr>
                                <th>Editor Name:</th>
                                <td>{orderDetailsById?.editor_name?orderDetailsById?.editor_name:'---'}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table> */}
                      <table className="table table-invoice-details">
                        <thead>
                          <tr>
                            <td>#</td>
                            <td>Task & Description</td>
                            <td className="text-center">Hours</td>
                            {/* <td>Rate</td> */}
                            <td></td>
                            <td className="text-center">Word Count</td>
                            <td className="text-center">Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left">1</td>
                            <td className="text-left">{orderDetailsById?.service_name}</td>
                            <td className="text-center">{orderDetailsById?.service_hour} hrs</td>
                            {/* <td>
                              {orderDetailsById?.service_type === "0" ?(
                                  <>
                                      {orderDetailsById?.Pagecount?.regularprice}
                                  </>
                              ):(
                                  <>
                                       {orderDetailsById?.Pagecount?.expeditedprice}
                                  </>
                              )}
                            </td> */}
                            <td></td>
                            <td className="text-center">{orderDetailsById?.total_wordcount}</td>
                            <td className="text-center">${parseFloat(orderDetailsById?.total_price).toFixed(2)}</td>
                          </tr>
                          {/* <tr className="sub-total-row">
                            <td colSpan={4}>&nbsp;</td>
                            <td>VAT({orderDetailsById?.vat}%)</td>
                            <td>${calculateVAT(orderDetailsById?.total_price,orderDetailsById?.vat)}</td>
                          </tr> */}
                          <tr className="grand-total-row">
                            <td colSpan={4}>&nbsp;</td>
                            <td className="text-center"><b>Total</b></td>
                            {/* <td><b>${calculateTotalPrice(orderDetailsById?.total_price,orderDetailsById?.vat)}</b></td> */}
                            <td className="text-center"><b>${parseFloat(orderDetailsById?.total_price).toFixed(2)}</b></td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <table className="table note-table">
                        <tr>
                          <td>
                            <p>Note<br />
                            <small>Thanks for your business.</small></p>
                          </td>
                          <td><img src={signatureImg} alt /></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <p>Tearm & Conditions<br /><small>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
      normal distribution of letters, as opposed to using making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</small></p>
                          </td>
                        </tr>
                      </table> */}
                    </>
                  )}
              </div>
                
              {ShowPrint && (
                <div id='printablediv'>
                  <PrintableData orderDetailsById={orderDetailsById}/>
                </div>
              )}
              
              {/* <Example/> */}
            </div>
         </div>
      </main>
    </div>
  );
};

export default Billingdetails;
