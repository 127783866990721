import React from 'react'
import { ThreeDots } from  'react-loader-spinner'

const Loading = () => {
  return (
    <div>
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#0c0e78"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{marginLeft: '483px'}}
        wrapperClass=""
      />
    </div>
  )
}

export default Loading