import {
  React,
  useEffect,
  useState,
  useRef,
  NavLink,
  axios,
  NotificationManager,
  Form,
} from "../../utility/CommonImport"; // Import

import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import { API_BASE_URL } from "../../api_url";
import AOS from "aos";
import "aos/dist/aos.css";

const partnersBanner = require("../../assets/img/partners-with-us-banner-min.jpg");
const partnersImg = require("../../assets/img/partners-img1.png");
const partnersBenefitsImg1 = require("../../assets/img/partners-benefits-img1.png");
const partnersBenefitsImg2 = require("../../assets/img/partners-benefits-img2.png");
const partnersBenefitsImg3 = require("../../assets/img/partners-benefits-img3.png");
const partnersBenefitsImg4 = require("../../assets/img/partners-benefits-img4.png");

const PartnerWithUs = () => {
  const divRef = useRef(null);
  const [showServices, setShowServices] = useState([]); // For all service
  const [showCountryList, setShowCountryList] = useState([]); // for getting all countries
  const [isEmail, setIsEmail] = useState(false); // for getting all countries
  const [isLoading,setIsLoading] = useState(false);

  const [inquiry, setInquiry] = useState(
    {
      first_name: "",
      last_name: "",
      email_address: "",
      companywebsite: "",
      roleincompany: "",
      serviceneeded: "",
      numberordermonth: "",
      avgwcpd: "",
      country: "",
    },
  );
  const [errors, setErrors] = useState({});

  const handleButtonClick = () => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // get all services
  const getAllServices = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}service`);
      if (apiRes) {
        if (apiRes.data.res == true) {
          setShowServices(apiRes.data.data);
        }
      }
    } catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  };

  //get country list
  const getCountry = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}login/countries`);
      if (apiRes) {
        if (apiRes.data.res == true) {
          setShowCountryList(apiRes.data.data);
        } else {
          // NotificationManager.error('test1', "", 2000);
        }
      } else {
        // NotificationManager.error('test1', "", 2000);
      }
    } catch (error) {
      //close try
      // NotificationManager.error(error.message, "", 2000);
    }
  };

  //validation
  const validateInquiryData = () => {
    const validationErrors = {};

    if (!inquiry.first_name)
    {
      console.warn(inquiry.first_name)
      validationErrors.first_name = "Please enter a first name";
    }

    if (!inquiry.last_name)
    {
      validationErrors.last_name = "Please enter a last name";
    }

    if (!inquiry.email_address)
    {
      validationErrors.email_address = "Please enter an email address";
      setIsEmail(true)
    }
    else
    {
      if (!/^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/.test(inquiry.email_address))
      {
        validationErrors.email_address = "Please enter a valid email address";
        setIsEmail(true)
      }
    }

    if (!inquiry.companywebsite)
    {
      validationErrors.companywebsite = "Please enter a company website";
    }

    if (!inquiry.roleincompany)
    {
      validationErrors.roleincompany = "Please enter a role in the company";
    }

    if (!inquiry.serviceneeded)
    {
      validationErrors.serviceneeded = "Please specify the service needed";
    }

    if (!inquiry.numberordermonth)
    {
      validationErrors.numberordermonth ="Please enter the number of orders per month";
    }

    if (!inquiry.avgwcpd)
    {
      validationErrors.avgwcpd = "Please enter the average orders per day";
    }

    if (!inquiry.country)
    {
      validationErrors.country = "Please select a country";
    }

    return validationErrors;
  };

  //Submit Inquiry details
  const submitInquiry = async () => {
    const validationErrors = validateInquiryData();

    if (Object.keys(validationErrors).length > 0)
    {
      setErrors(validationErrors); // Display errors and stop submission
      return;
    } 
    else
    {
      setErrors({}); // If validation passes, clear errors and make the API call
      
      try
      {
        setIsLoading(true);
        let apiRes = await axios.post(
          `${API_BASE_URL}account/inquiry`,inquiry
        );
        if (apiRes) {
          if (apiRes.data.res == true) {
            NotificationManager.success("Inquiry Successfully Submitted","",2000);
            setIsLoading(false);
            setInquiry(
              {
                first_name: "",
                last_name: "",
                email_address: "",
                companywebsite: "",
                roleincompany: "",
                serviceneeded: "",
                numberordermonth: "",
                avgwcpd: "",
                country: "",
              },
            );
          } else {
            // NotificationManager.error('test1', "", 2000);
          }
        }
      } 
      catch (error) 
      {
        setIsLoading(false);
        NotificationManager.error("An error occurred while submitting the inquiry","",2000);
      }
    }
  };

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    getAllServices();
    getCountry();
  }, []);

  return (
    <div>
      <Header />
      <div
        className="inner-banner partners-banner"
        style={{ backgroundImage: `url(${partnersBanner})` }}
      >
        <div className="container">
          <h3 data-aos="fade-up">Partner With Us</h3>
          <p data-aos="fade-up">
            Join our global community of partners and improve the services you
            offer your clients with Elite Reviser editing services.
          </p>
          {/* <NavLink className="btn btn-primary" to="/services">
              Get Started
          </NavLink> */}
          <button className="btn btn-primary" onClick={handleButtonClick}>
              Get Started
          </button>
        </div>
      </div>
      <div className="career-body-top partners-top-body">
        <div className="container">
          <div className="row align-items-center" data-aos="fade-up">
            <div className="col-lg-5 col-12 img-section">
              <img src={partnersImg} alt="Image" />
            </div>
            <div className="col-lg-7 col-12 text-section">
              <h4>Why Elite Reviser?</h4>
              <h3>Create new streams of revenue with Elite Reviser</h3>
              <p>
                Take advantage of new, greater opportunities by joining Elite
                Reviser global community of partners to enjoy complementary
                solutions and services to ensure your success. Elite Reviser
                partners deliver elevated customer experiences and unlock new
                monetization opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section benefits-gray-section">
        <div className="container">
          <h3 data-aos="fade-up">Benefits Of Being A Partner</h3>
          <p>
            {/* Help your customers achieve their goals and take advantage of
            financial incentives. */}
            Help your customers achieve their goals and take advantage of our financial incentives.
          </p>
          <ul className="row benefits-list" data-aos="fade-up">
            <li className="col-md-3">
              <div className="img-sec">
                <img src={partnersBenefitsImg1} alt="Image" />
              </div>
              <div className="text-section">
                <p>
                  Take advantage of our financial incentives as you partner with
                  us.
                </p>
              </div>
            </li>
            <li className="col-md-3">
              <div className="img-sec">
                <img src={partnersBenefitsImg2} alt="Image" />
              </div>
              <div className="text-section">
                <p>
                  We have dedicated editors to take care of your editing needs.
                </p>
              </div>
            </li>
            <li className="col-md-3">
              <div className="img-sec">
                <img src={partnersBenefitsImg3} alt="Image" />
              </div>
              <div className="text-section">
                <p>
                  We will set you up so that your clients can enjoy our
                  services.
                </p>
              </div>
            </li>
            <li className="col-md-3">
              <div className="img-sec">
                <img src={partnersBenefitsImg4} alt="Image" />
              </div>
              <div className="text-section">
                <p>Our support advisors are waiting and ready to assist you.</p>
              </div>
            </li>
          </ul>
          <button className="btn btn-primary" onClick={handleButtonClick}>
            Partner With Us
          </button>
        </div>
      </div>

      <div className="apply-section partners-apply-section" ref={divRef}>
        <div className="container">
          <h3 data-aos="fade-up">Inquiry Form</h3>
          <p data-aos="fade-up">
            Tell us about yourself to start your journey to become an Elite
            Reviser partner. Questions? Reach out to us at{" "}
            <a href="mailto:support@elitereviser.com">
              support@elitereviser.com
            </a>
            .
          </p>
          <ul className="row inquiry-form-list ">
            <li className="col-md-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="firstInput"
                  name="first_name"
                  placeholder="First Name"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,first_name:e.target.value}))
                    setErrors({ ...errors, first_name: '' }); // Clear the error
                  }}
                  value={inquiry.first_name}
                />
                <label for="firstInput">First name</label>
              </div>
              {inquiry.first_name == '' && errors.first_name && (<div className="error-message">{errors.first_name}</div>
              )}
            </li>
            <li className="col-md-4">
              <div className="form-floating  /l;">
                <input
                  type="text"
                  className="form-control"
                  id="lastInput"
                  name="last_name"
                  placeholder="Last name"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,last_name:e.target.value}))
                    setErrors({ ...errors, last_name: '' }); // Clear the error
                  }}
                  value={inquiry.last_name}
                />
                <label for="lastInput">Last name</label>
              </div>
              {inquiry.last_name == '' && errors.last_name && <div className="error-message">{errors.last_name}</div>}
            </li>
            <li className="col-md-4">
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="emailInput"
                  name="email_address"
                  placeholder="Email address"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,email_address:e.target.value}))
                    setErrors({ ...errors, email_address: '' }); // Clear the error
                  }}
                  value={inquiry.email_address}
                />
                <label for="emailInput"> Email address</label>
              </div>
              {((inquiry.email_address == '' && errors.email_address) || (isEmail)) && <div className="error-message">{errors.email_address}</div>}
            </li>
            <li className="col-md-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="companyInput"
                  name="companywebsite"
                  placeholder="Company website"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,companywebsite:e.target.value}))
                    setErrors({ ...errors, companywebsite: '' }); // Clear the error
                  }}
                  value={inquiry.companywebsite}
                />
                <label for="companyInput">Company website</label>
              </div>
              {inquiry.companywebsite == '' && errors.companywebsite && <div className="error-message">{errors.companywebsite}</div>}
            </li>
            <li className="col-md-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="roleincompany"
                  name="roleincompany"
                  placeholder="Your role in company"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,roleincompany:e.target.value}))
                    setErrors({ ...errors, roleincompany: '' }); // Clear the error
                  }}
                  value={inquiry.roleincompany}
                />
                <label for="roleincompany">Your role in company</label>
              </div>
              {inquiry.roleincompany == '' && errors.roleincompany && <div className="error-message">{errors.roleincompany}</div>}
            </li>
            <li className="col-md-4">
              <Form.Group>
                <Form.Select
                  name="serviceneeded"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,serviceneeded:e.target.value}))
                    setErrors({ ...errors, serviceneeded: '' }); // Clear the error
                  }}
                  value={inquiry.serviceneeded}
                >
                  <option value="null" selected hidden>
                    Type of service needed
                  </option>
                  {showServices &&
                    showServices.map((item, index) => {
                      return (
                        <option value={item?.title}>
                          {item?.title === 'ESL' ?(
                            <>{item?.title}</>
                          ):(
                            <>{item?.title.toLowerCase()}</>
                          )}
                        </option>
                      );
                  })}

                </Form.Select>
                {inquiry.serviceneeded == '' && errors.serviceneeded && <div className="error-message">{errors.serviceneeded}</div>}
              </Form.Group>
            </li>
            <li className="col-md-4">
              <Form.Group>
                <Form.Select
                  name="numberordermonth"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,numberordermonth:e.target.value}))
                    setErrors({ ...errors, numberordermonth: '' }); // Clear the error
                  }}
                  value={inquiry.numberordermonth}
                >
                  <option value="null" selected hidden>
                    Number of orders per month
                  </option>
                  <option value="10-20">10-20</option>
                  <option value="21-30">21-30</option>
                  <option value="31-40">31-40</option>
                  <option value="41+">41+</option>
                </Form.Select>
                {inquiry.numberordermonth == '' && errors.numberordermonth && <div className="error-message">{errors.numberordermonth}</div>
                }
              </Form.Group>
            </li>
            <li className="col-md-4">
              <Form.Group>
                <Form.Select
                  name="avgwcpd"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,avgwcpd:e.target.value}))
                    setErrors({ ...errors, avgwcpd: '' }); // Clear the error
                  }}
                  value={inquiry.avgwcpd}
                >
                  <option value="null" selected hidden>
                    Average word count per document
                  </option>
                  <option value="500-2,500">500-2,500</option>
                  <option value="2,501-5,000">2,501-5,000</option>
                  <option value="5,001-10,000">5,001-10,000</option>
                  <option value="10,001+">10,001+</option>
                </Form.Select>
                {inquiry.avgwcpd == '' && errors.avgwcpd && <div className="error-message">{errors.avgwcpd}</div>}
              </Form.Group>
            </li>
            <li className="col-md-4">
              <Form.Group>
                <Form.Select
                  name="country"
                  onChange={(e) => {
                    setInquiry((prev)=>({...prev,country:e.target.value}))
                    setErrors({ ...errors, country: '' }); // Clear the error
                  }}
                  value={inquiry.country}
                >
                  <option value="null" selected hidden>
                    Country
                  </option>
                  {showCountryList &&
                    showCountryList.map((item, index) => {
                      return (
                        <option value={item?.country_name}>
                          {item?.country_name}
                        </option>
                      );
                    })}
                </Form.Select>
                {inquiry.country == '' && errors.country && <div className="error-message">{errors.country}</div>}
              </Form.Group>
            </li>
            <li className="col-md-12">
              {isLoading && isLoading ? (
                <button className="btn btn-primary" disabled><i class="fa fa-refresh fa-spin"/> Please Wait..</button>
              ):(
                  <button className="btn btn-primary" onClick={() => submitInquiry()}>Submit</button>
              )}
              
            </li>
          </ul>
        </div>
      </div>

      <div className="home-yellow-section align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7 col-md-8 text-content"
              data-aos="fade-right"
            >
              <h3>Still Not Sure What Service Is Right For You?</h3>
              <p>
                <NavLink to="/">Elite Reviser</NavLink> can handle that.
                <NavLink to="/contact"> Contact Us.</NavLink>
              </p>
            </div>
            <div
              className="col-lg-5 col-md-4 right-content"
              data-aos="fade-left"
            >
              <NavLink className="btn btn-primary" to="/services">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PartnerWithUs;
