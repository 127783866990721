import React, { useEffect, useState } from "react";
import { useLocation, useNavigate,Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "react-bootstrap/Button";
import { Formik, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { API_BASE_URL } from "../../api_url";
import { NotificationManager } from "react-notifications";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  
  const passwordForget = async (values, { resetForm }) => {
    try {
      setLoading(true);
      let apiRes = await axios.post(`${API_BASE_URL}login/forgetpassword`, {
        email_address: values.email_address,
      });
      if (apiRes) {
        if (apiRes.data.res == true) {
          NotificationManager.success('Check Mail.We have e-mailed your password reset link!', "", 2000); 
          navigate('/');
        }
        else{
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
    }catch (error){
      console.error(error);
    }
  };

  let schema = yup.object().shape({
    email_address: yup
      .string()
      .required("Email is required")
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Invalid email address!"
      ),
  });

  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Formik
        initialValues={{
          email_address: "",
        }}
        onSubmit={(values, { resetForm }) => {passwordForget(values, { resetForm })}}
        validationSchema={schema}
      >
        {({ handleChange, handleSubmit, errors, values, touched, setFieldValue }) => (
          <div className="login-body">
            <div className="container">
              <h3>Forgot Password</h3>
                <ul className="row login-form-list">
                  <li className="col-12">
                    <div class="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Email"
                        name ="email_address"
                        onChange={handleChange}
                        value={values.email_address}
                      />
                      <label for="emailInput">Username/Email</label>
                    </div>
                    {touched?.email_address && errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                  </li>
                    
                  <li className="col-md-12">
                    {loading ? (
                      <Button className="btn btn-primary" type="submit" disabled><i class="fa fa-refresh fa-spin"></i>Reset Password</Button>
                    ):(
                      <Button className="btn btn-primary" type="submit"  onClick={()=>handleSubmit()}>Submit</Button>
                    )}
                  </li>
                </ul>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ForgetPassword;