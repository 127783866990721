import {
  React, useEffect, useState,
  useNavigate, useParams,
  Formik, 
  yup,
  axios,
  NotificationManager,
  Button
} from '../../utility/CommonImport';

import Adminheader from "../../Components/AdminComponents/Adminheader";
import Adminsidebar from "../../Components/AdminComponents/Adminsidebar";

import { API_BASE_URL} from '../../api_url';

const EditClient = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false); 
    const [showClientData,setShowClientData] = useState();
    const [showCountryList,setShowCountryList] = useState([]);

  //get country list
  const getCountry =async() =>
  {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}login/countries`)
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowCountryList(apiRes.data.data);
        }
        else
        {
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error) 
    {
      console.error(error);
    }  
  }

  // get editor information
  const getClientInfos = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${id}`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowClientData(apiRes.data.data[0]);
        }
        else
        {
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error)
    {
      console.error(error);
    }
  }

  const updateClientData = async(values,{resetForm}) =>{
    try
    {
      setLoading(true);
      let apiRes = await(
        axios
          .post(
            `${API_BASE_URL}account/editaccount`,{
              id: values.id,
              first_name: values.first_name,
              last_name: values.last_name,
              ph_no: values.ph_no.toString(),
              email_address: values.email_address,
              address: values.address,
              state: values.state,
              zipcode: values.zipcode,
              country: values.country,
              city: values.city,
              password: values.password,
              designation : values.designation,
              role: '2'
            },
          )
      )
      if(apiRes)
      {
        if(apiRes.data.res == true) 
        {
          NotificationManager.success('Update Successfully','',2000);
          setLoading(false);
          navigate("/admin-client-management");
        }
        else
        {
          setLoading(false);
          console.error(apiRes.data.res);
        }
      }
      else
      {
        console.error('Something Went Wrong!');
      }
    }
    catch (error) 
    {
      console.error(error);
    } 
  }
  
  let schema = yup.object().shape({
    first_name: yup
    .string()
    .required('First name is required'),

    last_name: yup
    .string()
    .required('Last name is required'),

    ph_no: yup
    .string()
    .required('Phone number is required')
    .max(10,'Invalid Phone number'),

    email_address: yup
    .string()
    .required('Email is required')
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
      
    address: yup
    .string()
    .required('Street Address is required'),

    zipcode: yup
    .string()
    .required('Zip Code is required'),

    country: yup
    .string()
    .required('Country is required'),

    city: yup
    .string()
    .required('City is required'),

    designation: yup
    .string()
    .required('Designation is required'),
  });
  
  useEffect(() => 
  {
    getClientInfos();
    getCountry();
  }, []);

  return (
    <div>
      <Adminheader />
      <Adminsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>Edit Client</h2>
          </span>
        </div>
        <section className="section AccDtlsPnl">
          <Formik
            enableReinitialize
            initialValues={{
              id: showClientData && showClientData.id,
              first_name: showClientData && showClientData.first_name,
              last_name: showClientData && showClientData.last_name,
              ph_no: showClientData && showClientData.ph_no,
              email_address: showClientData && showClientData.email_address,
              address: showClientData && showClientData.address,
              state: showClientData && showClientData.state,
              zipcode: showClientData && showClientData.zipcode,
              country: showClientData && showClientData.country,
              city: showClientData && showClientData.city,
              designation: showClientData && showClientData.designation,
            }}
            onSubmit={(values,{resetForm})=>{updateClientData(values,{resetForm})}}
            validationSchema = {schema}
          >
            {({
                handleChange,
                handleSubmit,
                errors,
                values,
                touched,
                setFieldValue
            })=>(
            <div className="AccBox">
              <div className="pagetitle">
                <h3>Personal Details</h3>
                {loading && loading ? (
                  <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i class="fa fa-refresh fa-spin"></i>Update</Button>
                ):(
                  <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Update</Button>
                )}
              </div>
              <ul className="AccDtls EditAccDtls addEditorForm row">
                <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>First Name</label>
                      <input type="text" className="InptField" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} value={values.first_name}/>
                    </div>
                    {touched.first_name && errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                  </li>
                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Last Name</label>
                      <input type="text" className="InptField" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name}/>
                    </div>
                    {touched.last_name && errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                  </li>
                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Phone Number</label>
                      <input type="number" className="InptField" id="phoneInput" placeholder="Phone Number" name="ph_no"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                    </div>
                    {touched.ph_no && errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                  </li>

                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Email Address</label>
                      <input type="email" className="InptField" id="emailInput" placeholder="Email Address" name="email_address" disabled onChange={handleChange} value={values.email_address}/>
                    </div>
                    {touched.email_address && errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                  </li>
                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Street Address</label>
                      <input type="text" className="InptField" name="address" placeholder="Street Address" onChange={handleChange} value={values.address}/>
                    </div>
                    {touched.address && errors.address ? <div className='error'>{errors.address}</div> : null}
                  </li>

                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>State</label>
                      <input type="text" className="InptField" name="state" placeholder="State" onChange={handleChange} value={values.state}/>
                    </div>
                    {touched.state && errors.state ? <div className='error'>{errors.state}</div> : null}
                  </li>

                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Zip Code</label>
                      <input type="text" className="InptField" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                    </div>
                    {touched.zipcode && errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                  </li>
                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Country</label>
                      <select class="select-input" id="selectCountry" aria-label="Floating label select example" name="country" onChange={handleChange} value={values.country}>
                        <option selected hidden>Country</option>
                        {showCountryList && showCountryList.map((item,index)=>{
                          return(
                            <option value={item?.country_name}>{item?.country_name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </li>

                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>City</label>
                      <input type="text" className="InptField" id="city" placeholder="City" name="city" onChange={handleChange} value={values.city}/>
                    </div>
                    {touched.city && errors.city ? <div className='error'>{errors.city}</div> : null}
                  </li>
                  
                  <li className="col-md-3">
                    <div className="small-input-box">
                      <label>Designation</label>
                      <input type="text" className="InptField" placeholder="Designation" name="designation" onChange={handleChange} value={values.designation}/>
                    </div>
                  </li>
              </ul>
            </div>
            )}
          </Formik>
        </section>
      </main>
    </div>
  );
};

export default EditClient;
