import React, { useEffect, useState } from "react";
import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";
import axios from "axios";
import DatePicker from "react-datepicker";
import { API_BASE_URL} from '../../api_url';
import { get_date_format,commaFunc } from '../../utility/Common';

const InternalEditorMyEarnings = () => {
  let data= JSON.parse(localStorage.getItem("user_data_elite"));

  const [orderList,setOrderList] = useState([]);
  const [totalArray,setTotalArr] = useState([]);
  const [currentMonth,setCurrentMonth] = useState();
  const [notificationMsg,setNotificationMsg] = useState();

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const d = new Date();
  let name = month[d.getMonth()];
    // date picker
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
  // setCurrentMonth(name);

  // All jobs for a specific Client
//   const jobListByInternalEditorEarning = async () => {
//     try
//     {
//       let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?editor_id=${data?.id}`)
//       if (apiRes) 
//       {
//           console.log(apiRes.data.res);
//           if (apiRes.data.res == true) 
//           {
//             setOrderList(apiRes.data.data);
//             apiRes.data.data.map((item,index)=>{
//               totalArray.push(
//                 {
//                   'total_wc': item.total_wordcount ? item.total_wordcount: 0 ,
//                   'total_pages': item.number_of_pages ?  item.number_of_pages: 0 ,
//                   'total_amount': item.total_price
//                 }
//               )
//             })
//           }
//       }
//     }
//     catch (error)
//     {
//       // NotificationManager.error(error.message, "", 2000);
//     }
//  }


const jobListByInternalEditorEarning = async (month_value) => {
  let total_wc = 0;
  let total_pages = 0;
  let total_amount = 0;
  
  try
  {      
    // let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?internal_id=${data?.id}`);
    let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?editor_id=${''}&created_at=${month_value}&internal_id=${data?.id}`);
    if (apiRes)
    {
      if (apiRes.data.res === true) {
        apiRes.data.data.forEach((item) =>
        {
          total_wc += item.total_wordcount ? item.total_wordcount : 0;
          total_pages += item.number_of_pages ? item.number_of_pages : 0;
          total_amount += item.editor_reg_price;

          setTotalArr({
            'total_wc': total_wc,
            'total_pages': total_pages,
            'total_amount': total_amount,
          });
        });
        setOrderList(apiRes.data.data);
      }
    }
  }
  catch (error)
  {
    // Handle the error here
    // NotificationManager.error(error.message, "", 2000);
  }
};

//get Notification
const getInternalNotification= async() =>{
  try
  {
      let apiRes = await axios.post(`${API_BASE_URL}job/getNotification`, {
          user_id: data?.id,
      });
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
              setNotificationMsg(apiRes.data.NotificationUnred);
          }
      }
  } //close try
  catch (error) 
  {
      // NotificationManager.error(error.message, "", 2000);
  }
}

 useEffect(()=>{
  jobListByInternalEditorEarning(d.getMonth()+1);
  getInternalNotification();
},[])

useEffect(() => {
  setCurrentMonth(month[new Date().getMonth()]);
}, []);


  //get formatted start date
  const getFormattedStartDate = (start_date) =>{
    // setUseStartDate(start_date);
  }

  //get formatted end date
  const getFormattedEndDate =async(end_date) =>{
    
  }

  //Month Change
  const handleMonthChange = async(month_value) => {
    let total_wc = 0;
    let total_pages = 0;
    let total_amount = 0;
    
    try
    {      
      let apiRes = await axios.get(`${API_BASE_URL}job/editorEarning?editor_id=${''}&created_at=${month_value}&internal_id=${data?.id}`);
      if (apiRes)
      {
        if (apiRes.data.res === true) {
          apiRes.data.data.forEach((item) =>
          {
            total_wc += item.total_wordcount ? item.total_wordcount : 0;
            total_pages += item.number_of_pages ? item.number_of_pages : 0;
            total_amount += item.editor_reg_price;
  
            setTotalArr({
              'total_wc': total_wc,
              'total_pages': total_pages,
              'total_amount': total_amount,
            });
          });
          setOrderList(apiRes.data.data);
        }
      }
    }
    catch (error)
    {
      // Handle the error here
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  return (
    <div>
      <InternalEditorHeader unreadMsg={notificationMsg}/>
      <InternalEditorSidebar/>
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap fullWidthTitleWrap MobileBreak2">
            {/* <span className="editorNameLogo">MW</span> */}
            {/* <h2>My Earnings</h2> */}
            
              <h2>My Production</h2>
          
            {/* <ul className="select-date-list">
              <li>
                <DatePicker onChange={onChange} value={value} format="MM-dd-y" />
              </li>
              <li>
                <DatePicker onChange={onChange2} value={endValue} format="MM-dd-y" />
              </li>
              <li><input type="button" value="Search" className="btn btn-primary" /></li>
            </ul> */}
            <div className="dropdown">
              Filter By
                <select className="dropdown-toggle" data-toggle="dropdown" style={{border: 'none',outline: 'none',scrollBehavior: 'smooth'}} onChange={(e)=>handleMonthChange(e.target.value)}>
                {month.map((m,index) => (
                  <option key={m} className="dropdown-item" href="#" value={index+1} selected={currentMonth === m}>
                  {m}
                </option>
                ))}                
              </select>
            </div>
          </span>

          {/* <div className="dropdown">
            <button type="button" className="dropdown-toggle" data-toggle="dropdown">
              Sort by:
            </button>
            <div className="dropdown-menu">
              <a className="dropdown-item" href="#">Name</a>
            </div>
          </div> */}
        </div>
        {/* <div className="date-range-sec">
          <div className="chose-date-sec"><DatePicker selected={startDate} onChange={(date) => {setStartDate(date);getFormattedStartDate(date)}} dateFormat="P" /></div>
          <div className="chose-date-sec"><DatePicker selected={endDate} onChange={(date) => {setEndDate(date);getFormattedEndDate(date)}}  dateFormat="P" /></div>
        </div> */}

        {/* <br className="clearfix" /> */}
        <section className="section BillDtlsPnl BillDtlsPnlClear" style={{marginTop:'-25px'}}>
          <div className="adminEarningTableSec">
            <table width="100%" className="CmnTbl myJobTable table-striped blue-header-table new-earning-table">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th>Task Name</th>
                  <th>Word Count</th>
                  <th>Page</th>
                  <th>Completion Date</th>
                  <th>Amount</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {orderList?.length > 0 ?
                  orderList?.map((item,index)=>{
                  return(
                    <tr>
                      {(item?.job_type==='type2')?(
                        <>
                          <td>{item?.id}</td>
                          <td>
                            <p className="ListItem yet-to-asign-list">
                              {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                            </p>
                          </td>
                        </>
                      ):(
                        <>
                          <td>{item?.id}</td>
                          <td>
                            <p className="ListItem yet-to-asign-list">
                              {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {item?.service_hour}hrs
                            </p>
                          </td>
                        </>
                      )}

                      {item?.job_type == "type1" ?(
                        <>
                          <td style={{textAlign: "center"}}>{commaFunc(item?.total_wordcount)}</td>
                          <td style={{textAlign: "center"}}>--</td>
                        </>
                      ):(
                        <>
                          <td style={{textAlign: "center"}}>--</td>
                          <td style={{textAlign: "center"}}>{commaFunc(item?.number_of_pages)}</td>
                        </>
                      )}

                      <td style={{textAlign: "center"}}>
                        <span className="GreenTxt">{get_date_format(item?.due_date)}</span>
                      </td>
                      <td style={{textAlign: "center"}}>
                        {item?.job_type==='type2' ?(
                            <>${parseFloat(Number(item?.editor_cv_price)).toFixed(2)}</>
                        ):(
                            <>${parseFloat(Number(item?.editor_reg_price)).toFixed(2)}</>
                        )}
                        {/* $41.20 USD */}
                      </td>
                      {/* <td><span className="paid-span">Paid</span></td> */}
                    </tr>
                  )
                }):(
                  <tr>
                    {/* {/* <td colSpan={5} style={{background: "none"}}><p style={{textAlign:'center'}}>No Earnings Found</p></td> */}
                    <td className='no-data' colSpan={6} style={{background: "none"}}><p style={{textAlign:'center'}}>No Productions Found</p></td>
                  </tr>
                )}
                {orderList && orderList.length > 0 && (
                  <tr className="special-foot-tr">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td><span>{commaFunc(totalArray?.total_wc)}</span></td>
                    <td><span>{commaFunc(totalArray?.total_pages)}</span></td>
                    <td>&nbsp;</td>
                    <td><span>${totalArray?.total_amount}</span></td>
                  </tr>
                )}
                </tbody>
              
              
            </table>
          </div>
        </section>
      </main>
    </div>
  );
};

export default InternalEditorMyEarnings;
