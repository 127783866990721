import React, { Component, useState, useEffect, useCallback, useRef } from "react";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../../Components/CommonComponents/Header";
import Footer from "../../Components/CommonComponents/Footer";
import Form from 'react-bootstrap/Form';
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { API_BASE_URL } from '../../api_url';
import Modal from 'react-bootstrap/Modal';
import { NotificationManager } from 'react-notifications';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromHTML } from 'draft-js';
import { saveAs } from 'file-saver';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// import { ProgressBar } from "react-bootstrap";

const servicesBanner = require("../../assets/img/services-banner-min.jpg");
const docsImg = require("../../assets/img/docs-img.png");
const pdfImg = require("../../assets/img/pdf.png");
const crossImg = require("../../assets/img/red-cross-icon.png");
const ckEditorImg = require("../../assets/img/ck-editor-img.png");
const choseEditorIcon = require("../../assets/img/chose-editor-icon.png");
const closedAutoSearch = require("../../assets/img/close-icon.png");

const Servicesexistingclient = (state) => {
    const navigate = useNavigate();
    const [orderCancel, setOrderCancel] = useState(false);  //modal
    const orderCancelClose = () => setOrderCancel(false);   //modal-close
    const [showServiceName, setShowServiceName] = useState(); //set service name
    // const onLoginFormSubmit = (e) => {
    //     e.preventDefault();
    //     orderCancelClose();
    //     orderCancelFormShow();
    // }
    // const {id,name} = useParams();
    // const {name} = useParams();
    const location = useLocation();
    const { id } = location.state ?? '';
    // console.log(location.pathname);
    console.log(location); // here id is service id
    let data = JSON.parse(localStorage.getItem("user_data_elite"));
    let data1 = JSON.parse(localStorage.getItem("allFormData"));

    const [userData, setUserData] = useState(data);
    const [serviceInfo, setServiceInfo] = useState(); // service info
    const [subService, setSubService] = useState([]); // used to store subservices
    const [serviceFile, setServiceFile] = useState([]); // service file upload
    const [serviceFileNames, setServiceFileNames] = useState([]); // service file name
    const [instructionFile, setInstructionFile] = useState([]); // service file upload
    const [instructionFileNames, setInstructionFileNames] = useState([]); // service file name
    const [loading, setLoading] = useState(false); // this is for loader
    const [loading1, setLoading1] = useState(false); // this is for loader1

    // const [progress,setProgress] = useState(0); // this is for progress bar
    const [countDetails, setCountDetails] = useState(); // for count information

    const [selectedSubService, setSelectedSubService] = useState(); // for Selected sub service
    // const [selectedSubServiceName,setSelectedSubServiceName] = useState(); // for Selected sub service name
    const [selectedVersion, setSelectedVersion] = useState(); // for Selected version
    const [service, setService] = useState(); // for selected service
    const [showLevel, setShowLevel] = useState('Regular Service');
    const [serviceType, setServiceType] = useState(); // set service type
    const [editorData, setEditorData] = useState(); // for CK Editor Data
    const [autoData, setAutoData] = useState(false);
    const [instructFile, setInstructFile] = useState(); // store instruct file
    const [isVisible, SetIsVisible] = useState(false); //disable
    const [showSFile, setShowSFile] = useState(false);

    const [showError, setShowError] = useState(false); // for all fields validation
    const [fileDlt, setFileDlt] = useState();
    const [insFileDelete, setInsFileDelete] = useState();
    const [showIFile, setShowIFile] = useState(false);
    const [listEditor, setListEditor] = useState([]); //autocomplete search 

    //multiple file upload
    const [file, setFile] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    //multiple file upload instruction
    const [insFile, setInsFile] = useState([]);
    const [insFileNames, setInsFileNames] = useState([]);

    const [showExistingEditor, setshowExistingEditor] = useState([]); // existing client list
    const [selectEditor, setSelectEditor] = useState(); //select editor or not
    const [selectRole, setSelectRole] = useState(); // select editor/iternal role
    const [selectedEditorId, setSelectedEditorId] = useState(); // select editor id
    const [selectedEditorName, setSelectedEditorName] = useState(); // select editor name
    const [selectedEditorEmail, setSelectedEditorEmail] = useState(); // select editor email
    const [instructionFullFileNames, setInstructionFullFileNames] = useState([]); // instruction file names
    const [showOption, setShowOption] = useState(1);
    const [mergeServiceFile, setMergeServiceFile] = useState([]); // This is for merge file
    const [mergeServiceFileNames, setMergeServiceFileNames] = useState([]); // This is for merge file
    const [mergeServiceFullFileNames, setMergeServiceFullFileNames] = useState([]); // This is for merge file full name

    const [updateCount, setUpdateCount] = useState(0); //File changes count
    const [updateRegPrice, setUpdateRegPrice] = useState(0); //File changes regular price
    const [updateExpPrice, setUpdateExpPrice] = useState(0); //File changes Expedited price
    const [updateRegService, setUpdateRegService] = useState(0); //File changes regular Service hrs
    const [updateExpService, setUpdateExpService] = useState(0); //File changes regular Service hrs

    const [useVal, setUseVal] = useState(false);
    const [showFilterData, setShowFilterData] = useState([]);
    const [searchingData, setSearchingData] = useState();
    const [showSearch, setShowSearch] = useState(false);
    const [noRecordFound, setNoRecordFound] = useState(false);
    const [showRole, setShowRole] = useState();
    const [validMatch,setValidMatch] = useState(0);
    const [isEmailExist,setIsEmailExist] = useState('');
    const [isPhoneExist,setIsPhoneExist] = useState('');
    const [isSearchValueClicked,setIsSearchValueClicked] = useState(false);
    const ref = useRef(null);
    const [showClientData,setShowClientData] = useState(); // for editor info
    const [userDetails, setUserDetails] = useState(false);  //modal
    const userDetailsClose = () => setUserDetails(false);   //modal-close
    useEffect(() => {
        AOS.init();
    });

    useEffect(() => {
        getServiceDetailsById();
        existingClientList(data?.id);
        existingClientListDropdown(data?.id);
        // getAllEditor();
    }, []);

    useEffect(() => {
        // if (data=== null)
        // {
        //     navigate("/login");
        //     navigate(0);
        // }
        if (data != null && (data?.role == '0')) {
            navigate("/admin-job-board");
        }
        else if (data != null && (data?.role == '1')) {
            navigate("/editor-job-board");
        }
        else if (data != null && (data?.role == '3')) {
            navigate("/deputy-admin-job-board");
        }
        else if (data != null && (data?.role == '4')) {
            navigate("/internal-editor-job-board");
        }
    })

    useEffect(()=>{
        getClientInfos();
    },[]);

    // get service deatils by id
    const getServiceDetailsById = async () => {
        try {
            let apiRes = await axios.get(`${API_BASE_URL}service/servicedetail?id=${id}`)
            if (apiRes) {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) {
                    console.log(apiRes.data.data);
                    setServiceInfo(apiRes.data.data[0]);
                    setSubService(apiRes.data.data[0].subservice);
                    setShowServiceName(apiRes.data.data[0].title);
                }
            }
        }
        catch (error) {
            // NotificationManager.error(error.message, "", 2000);
        }
    }

    //redirect to contact us
    const redirectComponent = () => {
        navigate('/');
    }

    const searchData = (data) => {
        // console.log(data)
        setShowSearch(true);
        if (data) {
            const filterData = edtId.filter((item) => {
                console.log(item.designation.indexOf(data))
                return (
                    // item.designation.indexOf(data) >= 0
                    item.designation.indexOf(data) >= 0
                )
            })
            setShowFilterData(filterData);
            if (filterData.length > 0) {
                setNoRecordFound(false);
            }
            else {
                setNoRecordFound(true);
            }

        }
        else {
            setShowFilterData(edtId);
            // setNoRecordFound(true);
        }

    }

    // get editor information
const getClientInfos = async () => {
    try {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${data?.id}`) 
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          // console.log(apiRes.data.data);
          setShowClientData(apiRes.data.data[0]);
          setUserData(apiRes.data.data[0]);
        //   localStorage.setItem('user_data_elite', JSON.stringify(apiRes.data.data[0]));
        }
      }
    } catch (error) {
      //close try
      // NotificationManager.error(error, "", 2000);
    }
  };

  //Message
const handleMessage =(val) =>{
    if( val == 1 )
    {
        NotificationManager.warning('Add address and contact number to place the order', "", 4000);
        setUserDetails(true);
    }
    else if( val == 2 )
    {
        NotificationManager.warning('Only Client have an access to place the order', "", 4000);
    }
}

const updateEditorData = async(values,{resetForm}) =>{
    console.log(values);
      try {
          setLoading(true);
          let apiRes = await(
              axios
              .post(
              `${API_BASE_URL}account/editaccount`,{
                  id: values.id,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  ph_no: values.ph_no.toString(),
                  email_address: values.email_address,
                  state: values.state,
                  address: values.address,
                  zipcode: values.zipcode,
                  password: values.password,
                  role: '2'  // 2 for Client
              },
          )
          )
          if(apiRes)
          {
              console.log(apiRes.data.res)
              if(apiRes.data.res == true) 
              {
                  NotificationManager.success('Update Successfully','',2000);
                  getClientInfos();
                  setLoading(false);
                  userDetailsClose();
                //   navigate("/client-my-account");
                  // resetForm({values:''})
              }else
              {
                  setLoading(false);
                  NotificationManager.error(apiRes.data.msg, '',2000);
              }
          }
      }
      catch (error) 
      {
          // NotificationManager.error(error, '',2000);
      } 
}

  let schema = yup.object().shape({

      first_name: yup
      .string()
      // .ensure()
      .nullable('First name is required')
      .required('First name is required'),

      last_name: yup
      .string()
      .nullable('Last name is required')
      // .ensure()
      .required('Last name is required'),
  
      ph_no: yup
      .string()
      .nullable('Phone number is required')
      .required('Phone number is required')
      .max(10,'Invalid Phone number'),

      email_address: yup
      .string()
      .nullable('Email is required')
      .required('Email is required')
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Invalid email address!"),
        
      address: yup
      .string()
      .nullable('Address is required')
      .required('Address is required'),

      state: yup
      .string()
      .nullable('State is required')
      .required('State is required'),

      zipcode: yup
      .string()
      .nullable('Zip Code is required')
      .required('Zip Code is required'),
  }); 

    //Editor/Internal Search
    const handleDataSearch =(e)=>{
        searchData(e.target.value);
        setSearchingData(e.target.value);
    }

    const editorInstructionData = (data) =>{
    
        // let text = "Test hellosumit@gmail.com Hi bye1@3";
        // let mobileRegEx = '/[0][4-5][0-9]{8}/g';
        
        // let result = data.match("@");
        // let result = data.match(
        //     /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/
        //   );
        // var matches = data.match(/\d+/g);
        
        // result == null => 'Not Match'
        // (matches != null)=>('number');
        
        
          // Regular expression pattern for email validation
        //   var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
          var emailPattern1 = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]/;
        //   var phonePattern = /\d{9}/;
        //   var phonePattern1 = /\d{5}\s*\d{5}/;
        //   var phonePattern2 = /\d+\s*\d+/;
        
            var pattern_phone1 = /\b(?:\d{3}[-.\s]??\d{3}[-.\s]??\d{4}|\d{3}[-.\s]??\d{4})\b/;
            var pattern_phone2 = '/\d{10}/';
            var pattern_phone3 = '/\d{5}\s*\d{5}/';
            var pattern_phone4 = /\+\d{2}\s\d{2,}(?:\s*\d+)+/;
            var pattern_phone5 = /\b\d{5}\s*\d{6}\b/;
            var pattern_phone6 = /\b\d{9}\b/;
            var pattern_phone7 = /\b\+?\d+(?:-\d+){2,}\b/;
            var pattern_phone8 = /\b\d{3}(?:-?\d{3}){2}\b|\b\d{10}\b/;
            var pattern_phone9 = /\b\d{3}(?:-?\d{3}){2}\b|\b\d{14}\b/;
        
          // Find all email addresses in the content using the pattern
          var emails = data.match(emailPattern);
        //   var phones = data.match(phonePattern);
        //   var phones1 = data.match(phonePattern1);
        //   var phones2 = data.match(phonePattern2);
          var phone1 = data.match(pattern_phone1);
          var phone2 = data.match(pattern_phone2);
          var phone3 = data.match(pattern_phone3);
          var phone4 = data.match(pattern_phone4);
          var phone5 = data.match(pattern_phone5);
          var phone6 = data.match(pattern_phone6);
          var phone7 = data.match(pattern_phone7);
          var phone8 = data.match(pattern_phone8);
          var phone9 = data.match(pattern_phone9);
        
        
          var emails1 = data.match(emailPattern1);
        // console.log(emails1)
            if(emails!= null)
            {
                setIsEmailExist('yes');
                setIsPhoneExist('no');
                console.log(1);
            }
            else if(emails1!= null)
            {
                setIsEmailExist('yes');
                setIsPhoneExist('no');
                console.log(2);
            }
            else if(phone1!=null || 
                phone2!=null || 
                phone3!=null || 
                phone4!=null ||
                phone5!=null ||
                phone6!=null ||
                phone7!=null ||
                phone8!=null ||
                phone9!=null)
                {
                    setIsPhoneExist('yes');
                    setIsEmailExist('no');
                }
            // else if(phones!= null)
            // {
            //    setIsPhoneExist('yes');
            //    setIsEmailExist('no');
            // // console.log(emails)
            // // console.log(emails1)
            //     console.log(3);
            // }
            // else if(phones1!= null)
            // {
            //    setIsPhoneExist('yes');
            //    setIsEmailExist('no');
            //    console.log(4);
            // }
            // else if(phones2!= null)
            // {
            //    setIsPhoneExist('yes');
            //    setIsEmailExist('no');
            //    console.log(5);
            //    console.log(phones)
            // }
            // else if(emails!= null && phones!= null || phones1!= null || phones2!= null)
            // {
            //     setIsPhoneExist('yes');
            //     setIsEmailExist('yes');
            //     console.log(6);
        
            // }
            else
            {
                setIsPhoneExist('no');
                setIsEmailExist('no');
                console.log(7);
        
            }
        
            setEditorData(data);
        //   else
        //   {
        //     // No valid email found or content is empty
        //     console.log('No valid email and phone no found.');
        //     // NotificationManager.warning('Instruction contains emails or digits', "", 2000);
        //   }
        
        //   return
        // if((result == null) )
        // {
        //     // console.warn('Matched');
        //     setValidMatch(1);
        //     setEditorData(data);
        // }
        // else
        // {
        //     NotificationManager.warning('Instruction contains @ or digits', "", 2000);
        //     setValidMatch(2);
        // 	// console.warn('Not Matched');
        //     setEditorData(data);
        // }
        // console.warn(result)
        
    }

    const deSelectList = () => {
        // alert(1);
    }
    const onClear = () => {
        ref.current.value = "";
    };
    // get all editors
    const getAllEditor = async () => {
        try {
            let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=1`)
            if (apiRes) {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) {
                    console.log(apiRes.data.data);
                    //   setShowEditorList(apiRes.data.data);
                    setListEditor([]);
                    apiRes.data.data.map((value) => {
                        setListEditor(oldArray => [...oldArray, {
                            id: value.id,
                            name: value.id
                            // first_name: value.first_name,
                            // last_name: value.last_name
                        }]);
                        // setListEditor([{
                        //     id: 156,
                        // }])
                    });
                }
            }
        }  //close try
        catch (error) {
            // NotificationManager.error(error, "", 2000);
        }
    };


    // const onEditorStateChange = useCallback(
    //     (rawcontent) => {
    //       setEditorState(rawcontent.blocks[0].text);
    //     },
    //     [editorState]
    // ); 

    const onEditorStateChange = useCallback(
        (rawcontent) => {
            setEditorState(rawcontent.blocks[0].text);
        },
        [editorState]
    )
    //for deleting files one by one
    // function deleteFile(i)
    // {
    //   const dlt_fileName = fileNames.filter((item,index) => index !== i)
    //   const dlt_file = file.filter((item,index) => index !== i)

    //   setFileNames(dlt_fileName);
    //   setFile(dlt_file);
    //   console.log(data);
    //   console.log(dlt_file);
    // }



    // const deleteUser = value =>{
    //     if (window.confirm("Are you sure you want to delete?")){ 
    //         axios
    //         .post(
    //           `${API_BASE_URL}deleteUser`,
    //           {UserId:value}
    //         ).then(async (response)=>{
    //           getData(0);
    //           document.getElementById('success_msg').style.display = 'block';
    //           document.getElementById("success_msg").innerHTML = response.data.msg;
    //           setTimeout(() => {
    //             document.getElementById('success_msg').style.display = 'none';
    //             document.getElementById("success_msg").innerHTML = '';
    //           }, 3000);

    //         })
    //     }

    //  }


    //file download
    const downloadFile = (item) => {
        {
            let full_fileName = 'https://elitereviser.com/serversite/public/uploads/' + item;
            saveAs(full_fileName);
        }
    }

    //delete file Service Multiple
    const deleteFile = async (i, itm) => {
        // alert(itm);
        // alert(JSON.stringify(itm));
        // console.log('Index: ' + i);
        // console.log('Item: ' + item);
        // return
        // try
        // {
        //     let apiRes = await axios.get(`${API_BASE_URL}job/removeservicefiles?service_filename=${serviceFileName}`)
        //     if (apiRes) 
        //     {
        //         console.log(apiRes.data.res);
        //         if (apiRes.data.res == true) 
        //         {
        //             setFileDlt('');
        //             setCountDetails(null);
        //             setServiceFile('');
        //             setServiceFileNames('');
        //             NotificationManager.success('File deleted successfully', "", 2000);
        //         }
        //     }
        // } 
        // catch (error) 
        // {
        //     NotificationManager.error(error, "", 2000);
        // }
        // }
        if (window.confirm("Are you sure you want to delete?")) {
            try {
                const check_file = itm.replace(/ /g, '')
                const dlt_fileName = fileNames.filter((item, index) => item !== check_file)
                const dlt_file = file.filter((item, index) => item !== check_file)

                // let apiRes = await axios.get(`${API_BASE_URL}job/removeservicefiles?service_filename=${check_file}&total_count=${countDetails?.count}`)
                let apiRes = await axios.get(`${API_BASE_URL}service/removefiles?service_filename=${check_file}&total_count=${countDetails?.count}`)
                if (apiRes) {
                    console.log(apiRes.data.res);
                    if (apiRes.data.res == true) {
                        let newArr = [];
                        let newArr1 = [];
                        let newArrFile = [];
                        console.log(apiRes);
                        setCountDetails(apiRes.data.data[0]);

                        setUpdateCount(prev => prev - Number(apiRes.data.data[0].count)); //File changes count
                        setUpdateRegPrice(prev => prev - Number(apiRes.data.data[0].regularprice)); //File changes regular price
                        setUpdateExpPrice(prev => prev - Number(apiRes.data.data[0].expeditedprice)); //File changes Expedited price
                        setUpdateRegService(prev => prev - Number(apiRes.data.data[0].regular)); //File changes regular Service hrs
                        setUpdateExpService(prev => prev - Number(apiRes.data.data[0].expedited)); //File changes regular Service hrs


                        // setFileDlt('');
                        // setCountDetails(null);
                        // setServiceFile('');

                        // newArr = serviceFileNames.filter((item,index)=>index != i);
                        newArr = mergeServiceFileNames.filter((item, index) => index != i);
                        newArr1 = mergeServiceFullFileNames.filter((item, index) => index != i);
                        // newArrFile = setFile;
                        // setServiceFileNames(newArr);
                        setMergeServiceFileNames(newArr);
                        setMergeServiceFullFileNames(newArr1);
                        // setFile(newArrFile);
                        // setFile(dlt_file);
                        NotificationManager.success('File deleted successfully', "", 2000);

                        if (newArr.length === 0 && newArr1.length === 0) {
                            setCountDetails();
                            setUpdateCount(0);
                            setUpdateRegPrice(0);
                            setUpdateExpPrice(0);
                            setUpdateRegService(0);
                            setUpdateExpService(0);

                        }
                        // setFileNames(dlt_fileName);
                        // setFile(dlt_file);
                        console.log(dlt_fileName);
                        console.log(dlt_file);

                        // setFileNames('');
                        // setFile('');
                        // console.log(data);
                        // console.log(dlt_file);

                    }
                }
            }
            catch (error) {
                // alert('catch')
                // NotificationManager.error(error.message, "", 2000);
            }
        }
    }

    //delete file Instruction Multiple
    const deleteInsFile = async (i, itm) => {
        // alert(itm);
        if (window.confirm("Are you sure you want to delete?")) {
            try {
                const check_file = itm.replace(/ /g, '')
                const dlt_fileName = insFileNames.filter((item, index) => item !== check_file)
                const dlt_file = insFile.filter((item, index) => item !== check_file)

                // let apiRes = await axios.get(`${API_BASE_URL}job/removeservicefiles?service_filename=${check_file}&total_count=${countDetails?.count}`)
                let apiRes = await axios.get(`${API_BASE_URL}service/removeinstructionfiles?instruction_filename=${check_file}`)
                if (apiRes) {
                    console.log(apiRes.data.res);
                    if (apiRes.data.res == true) {
                        let newArr = [];
                        let newArrFile = [];

                        newArr = instructionFileNames.filter((item, index) => index != i);
                        setInstructionFileNames(newArr);
                        NotificationManager.success('File deleted successfully', "", 2000);

                    }
                }
            }
            catch (error) {
                // NotificationManager.error(error.message, "", 2000);
            }
        }
    }

    //delete instruction file
    const deleteInstructionFile = async (instructionFileName) => {
        // alert(instructionFileName)
        try {
            let apiRes = await axios.get(`${API_BASE_URL}job/removeinstructionfile?instruction_filename=${instructionFileName}`)
            if (apiRes) {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) {
                    setInsFileDelete('');
                    setInstructionFile('');
                    setInstructionFileNames('');
                    NotificationManager.success('File deleted successfully', "", 2000);
                }
            }
        }
        catch (error) {
            NotificationManager.error(error.message, "", 2000);
        }
    }

    // Editor Details 
    const editorDetailsById = async (editor_id) => {
        // https://demoupdates.com/updates/elite/api/job/existingcount?user_id=62&editor_id=115
        console.log(editor_id);

        if (editor_id != 'null') {
            try {
                let apiRes = await axios.get(`${API_BASE_URL}job/existingcount?user_id=${data?.id}&editor_id=${editor_id}`)
                if (apiRes) {
                    console.log(apiRes.data.res);
                    if (apiRes.data.data >= 8) {
                        NotificationManager.warning('This Editor is already in 8 jobs,please choose another editor', "", 4000);
                        setSelectEditor('null');
                        // editorDetailsById(null);
                    }
                    else {
                        let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
                        if (apiRes) {
                            console.log(apiRes.data.res);
                            if (apiRes.data.res == true) {
                                let fullName = '';
                                fullName = apiRes.data.data[0].first_name + ' ' + apiRes.data.data[0].last_name;
                                console.log(apiRes.data.data[0])
                                setSelectedEditorId(editor_id);
                                setSelectedEditorName(fullName);
                                setSelectedEditorEmail(apiRes.data.data[0]?.email_address);
                                setSelectEditor(editor_id);
                                setShowRole(apiRes.data.data[0]?.role);
                                //    console.log(fullName);
                                //    console.log(editor_id);
                                //    console.log(apiRes.data.data[0]?.email_address);
                            }
                            else {
                                NotificationManager.error('error', "", 2000);
                            }
                        }
                    }
                }
            }
            catch (error) {
                // NotificationManager.error(error.message, "", 2000);
            }
        }
        else {
            setSelectEditor('null');
            setSelectedEditorId();
            setSelectedEditorName();
            setSelectedEditorEmail();
            setSelectEditor();
        }

    }

    const notLoginSubmit = async () => {
        console.log(service)
        // console.log(data);
        // console.log(data1);
        // console.log('id:' + id);
        // console.log('name:' + name);
        // console.log('selectedSubService:' + selectedSubService);
        // console.log('selectedVersion:' + selectedVersion);

        // return
        let error = 0;
        // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined && editorData==undefined && instructFile==undefined)
        // if (selectedSubService == undefined && selectedVersion == undefined && countDetails?.service_filename == undefined && instructFile == undefined) {
        if (selectedSubService == undefined && selectedVersion == undefined && countDetails?.service_filename == undefined) {
            // alert(1)
            error = 0;
            NotificationManager.error('All fields are Blank!', '', 2000);
            setShowError(true);
        }
        // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined || editorData==undefined || instructFile==undefined)
        else if (selectedSubService == undefined || selectedVersion == undefined || countDetails?.service_filename == undefined || instructFile == undefined) {
            // alert(2)
            error = 1;
            NotificationManager.error('Blank field is not allowed!', '', 2000);
            setShowError(true);
        }
        else {
            if (countDetails?.count > 100000) {
                NotificationManager.error('Word Count is greater than 100000', '', 2000);
                // NotificationManager.error('Word Count is greater than 100000', 'Contact Us!', 3000, () => {
                //     // alert('callback');
                //     orderCancel();
                // });
            }
            else {
                localStorage.removeItem("allFormData");
                // alert(3)
                // dis_error = 2;
                var service_type = 2;
                var total_cost = 0;
                console.log(service)
                if (showLevel === 'Regular Service') {
                    service_type = 0;
                    total_cost = countDetails?.regularprice;
                }
                else if (showLevel === 'Expedited Service') {
                    service_type = 1;
                    total_cost = countDetails?.expeditedprice;
                }
                const collectdData = {
                    id: id,
                    service_name: showServiceName,
                    sub_service: selectedSubService,
                    english_version: selectedVersion,
                    service_filename: mergeServiceFullFileNames,
                    total_wordcount: countDetails?.count,
                    total_price: total_cost,
                    service_hour: service && service,
                    service_type: service_type,
                    // client_instruction: editorData,
                    client_instruction: editorData && editorData ? editorData : 'null',
                    instruction_filename: instructionFullFileNames,
                    full_description: serviceInfo?.full_description,
                    from_count: countDetails?.from_count,
                    to_count: countDetails?.to_count,
                    job_type: 'type1'
                }
                localStorage.setItem('allFormData', JSON.stringify(collectdData));

                navigate('/login', { state: { from: 'service' } });
            }

        }
    }

    const LoggedSubmit = async () => {
        let error = 0;
        // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined && editorData==undefined && instructFile==undefined)
        if (selectedSubService == undefined && selectedVersion == undefined && countDetails?.service_filename == undefined && instructFile == undefined) {
            // alert(1)
            error = 0;
            NotificationManager.error('All fields are Blank!', '', 2000);
            setShowError(true);
        }
        // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined || editorData==undefined || instructFile==undefined)
        else if (selectedSubService == undefined || selectedVersion == undefined || countDetails?.service_filename == undefined || instructFile == undefined) {
            // alert(2)
            error = 1;
            NotificationManager.error('Blank field is not allowed!', '', 2000);
            setShowError(true);
        }
        else {
            if (countDetails?.count > 100000) {

            }
            else {
                localStorage.removeItem("allFormData");
                // alert(3)
                // dis_error = 2;
                var service_type = 2;
                var total_cost = 0;
                console.log(service)
                if (showLevel === 'Regular Service') {
                    service_type = 0;
                    total_cost = countDetails?.regularprice;
                }
                else if (showLevel === 'Expedited Service') {
                    service_type = 1;
                    total_cost = countDetails?.expeditedprice;
                }
                const collectdData = {
                    id: id,
                    service_name: showServiceName,
                    sub_service: selectedSubService,
                    english_version: selectedVersion,
                    service_filename: countDetails?.service_filename,
                    total_wordcount: countDetails?.count,
                    total_price: total_cost,
                    service_hour: service && service,
                    service_type: service_type,
                    // client_instruction: editorData,
                    client_instruction: editorData && editorData ? editorData : 'null',
                    instruction_filename: instructFile,
                    full_description: serviceInfo?.full_description,
                    from_count: countDetails?.from_count,
                    to_count: countDetails?.to_count,
                    job_type: 'type1'
                }
                localStorage.setItem('allFormData', JSON.stringify(collectdData));

                {/* <NavLink className="btn btn-primary" 
                                            to="/payment"  
                                            state={{from:'service', path: location.pathname}}>
                                            Submit
                                        </NavLink> */}


                navigate('/payment', { state: { from: 'service' } });
            }

        }
    }

    const LoggedExisingSubmit = async () => {
        // console.log(mergeServiceFullFileNames?.length);
        // console.log(instructionFileNames?.length);
        // console.log(selectEditor)
        // alert('existing');
        let error = 0;
        // if(selectedSubService==undefined && selectedVersion==undefined && countDetails?.service_filename==undefined && editorData==undefined && instructFile==undefined)
        // if(selectedSubService==undefined && selectedVersion==undefined && mergeServiceFullFileNames==undefined && instructionFileNames==undefined && (selectEditor==undefined || selectEditor=="null"))
        // if (selectedSubService == undefined && selectedVersion == undefined && mergeServiceFullFileNames?.length == 0 && instructionFileNames?.length == 0) {
        if (selectedSubService == undefined && selectedVersion == undefined && mergeServiceFullFileNames?.length == 0) {
            // alert(1)
            error = 0;
            NotificationManager.error('All fields are Blank!', '', 2000);
            setShowError(true);
        }
        // else if(selectedSubService==undefined || selectedVersion==undefined || countDetails?.service_filename==undefined || editorData==undefined || instructFile==undefined)
        // else if (selectedSubService == undefined || selectedVersion == undefined || mergeServiceFullFileNames?.length == 0 || instructionFileNames?.length == 0) {
        else if (selectedSubService == undefined || selectedVersion == undefined || mergeServiceFullFileNames?.length == 0) {
            // alert(2)
            error = 1;
            NotificationManager.error('Blank field is not allowed!', '', 2000);
            setShowError(true);
            // selectedEditorId
        }
        // else if (!isSearchValueClicked)
        // {
        //     NotificationManager.error('Please select the editor', '', 2000);
        // }
        else {

            if (countDetails?.count > 100000)
            {
                navigate("/contact");
            }
            else
            {
                if(isEmailExist === 'yes')
                {
                    NotificationManager.warning('Please remove email address from instruction', '',2000);
                }
                else if(isPhoneExist === 'yes')
                {
                    NotificationManager.warning('Please remove phone number from instruction', '',2000);
                }
                else if(isEmailExist === 'yes' && isPhoneExist === 'yes')
                {
                    NotificationManager.warning('Please remove email address and phone number from instruction', '',2000);
                }
                else
                {
                    localStorage.removeItem("allFormData");
                    // alert(3)
                    // dis_error = 2;
                    var service_type = 2;
                    var total_cost = 0;
                    var total_hour = 0;
                    console.log(service)
                    if (showLevel === 'Regular Service') {
                        service_type = 0;
                        // total_cost = countDetails?.regularprice;
                        total_cost = updateRegPrice;
                        total_hour = updateRegService;
                    }
                    else if (showLevel === 'Expedited Service') {
                        service_type = 1;
                        // total_cost = countDetails?.expeditedprice;
                        total_cost = updateExpPrice;
                        total_hour = updateExpService;
                    }
                    const collectdData = {
                        id: id,
                        service_name: showServiceName,
                        sub_service: selectedSubService,
                        english_version: selectedVersion,
                        service_filename: mergeServiceFullFileNames,
                        // total_wordcount: countDetails?.count,
                        total_wordcount: updateCount,
                        total_price: total_cost,
                        // service_hour: service && service,
                        service_hour: total_hour,
                        service_type: service_type,
                        // client_instruction: editorData,
                        client_instruction: editorData && editorData ? editorData : 'null',
                        instruction_filename: instructionFullFileNames,
                        full_description: serviceInfo?.full_description,
                        from_count: countDetails?.from_count,
                        to_count: countDetails?.to_count,
                        job_type: 'type1',
                        link: 'exisingtype',
                        option_type: showOption,
                        editor_id: selectedEditorId ? selectedEditorId : '',
                        editor_name: selectedEditorName ? selectedEditorName : '',
                        editor_email: selectedEditorEmail ? selectedEditorEmail : '',
                        role: showRole ? showRole : ''
                    }
                    localStorage.setItem('allFormData', JSON.stringify(collectdData));

                    {/* <NavLink className="btn btn-primary" 
                                                to="/payment"  
                                                state={{from:'service', path: location.pathname}}>
                                                Submit
                                            </NavLink> */}


                    navigate('/payment', { state: { from: 'service' } });
                }
            }

        }
    }

    // submit all datas
    const submitAllData = async () => {
        let service_type = 2;
        // alert(1);
        if (service == 24) {
            service_type = 0;
            setServiceType(0);
        }
        else {
            service_type = 1;
            setServiceType(1);
        }
        // console.log(serviceFile);

        // console.log('service_id',id);
        // console.log('service_name',name);
        // console.log('sub_service',selectedSubService);
        // console.log('english_version',selectedVersion);
        // console.log('service_filename',serviceFile);
        // console.log('total_wordcount',countDetails?.count);
        // if(service == 24)
        // {
        //     // ${Math.round(Number(countDetails?.regularprice))}
        //     console.log('total_price',Math.round(Number(countDetails?.regularprice)));
        //     console.log('service_hour',service);
        //     console.log('service_type',service_type);
        //     console.log('client_instruction',editorData);
        //     console.log('instruction_filename',instructionFile);
        // }
        // else
        // {
        //     console.log('total_price',Math.round(Number(countDetails?.expeditedprice)));
        //     console.log('service_hour',service);
        //     console.log('service_type',service_type);
        //     console.log('client_instruction',editorData);
        //     console.log('instruction_filename',instructionFile);
        // }


        // navigate('/payment');
        // https://www.demoupdates.com/updates/elite/api/job?
        // userid=1&
        // service_id=1&
        // service_name=TEST&
        // sub_service=1&
        // english_version=TEST&
        // service_filename=TEST&
        // total_wordcount=213&
        // total_price=212&
        // service_hour=22&
        // service_type=1&
        // client_instruction=sxfsdfsfsf&
        // instruction_filename=erwr&
        // editor_id=3
        try {
            const formData = new FormData();
            if (service == 24) {
                formData.append('service_id', id);
                formData.append('service_name', 'sumit');
                formData.append('sub_service', selectedSubService);
                formData.append('english_version', selectedVersion);
                formData.append('service_filename', countDetails?.service_filename);
                // formData.append('service_filename',serviceFileNames);
                // formData.append('total_wordcount',countDetails?.count);
                formData.append('total_wordcount', updateCount);
                formData.append('total_price', Math.round(Number(countDetails?.expeditedprice)));
                formData.append('service_hour', service);
                formData.append('service_type', service_type);
                formData.append('client_instruction', editorData);
                formData.append('instruction_filename', instructFile);
                // formData.append('instruction_filename',instructionFileNames);
            }
            else {
                formData.append('service_id', id);
                formData.append('service_name', 'name');
                formData.append('sub_service', selectedSubService);
                formData.append('english_version', selectedVersion);
                formData.append('service_filename', countDetails?.service_filename);
                // formData.append('service_filename',serviceFileNames);
                formData.append('total_wordcount', countDetails?.count);
                formData.append('total_price', Math.round(Number(countDetails?.expeditedprice)));
                formData.append('service_hour', service);
                formData.append('service_type', service_type);
                formData.append('client_instruction', editorData);
                formData.append('instruction_filename', instructFile);
                // formData.append('instruction_filename',instructionFileNames);
                // formData.append('from_count',instructFile);
                // formData.append('to_count',instructFile);
            }

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            let apiRes = await axios.post(`${API_BASE_URL}job`, formData, config)

            if (apiRes) {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) {
                    // console.log(apiRes.data);
                    NotificationManager.success('Job Created successfully', '', 3000);
                    navigate("/");
                }
            }
        }
        catch (error) {
            NotificationManager.error(error.message, '', 2000);
        }

    }

    //for service file uploading
    // function serviceFileHandle(e)
    // {
    //     setServiceFile(e.target.files[0]);
    //     // console.log(e.target.files[0]);
    //     setServiceFile(e.target.files[0].name);
    //     // console.log(e.target.files[0].name);
    // }

    //for service file uploading


    //for service file delete


    function deleteServiceFile() {
        setServiceFile('');
        setServiceFile('');
    }

    //for service file uploading
    const serviceFileHandle = async (e) => {
        // setUpdateRegPrice(0);
        // setUpdateExpPrice(0);
        //for single file upload
        // try {
        //     console.log(event.target.files[0]);
        //     console.log(event.target.files[0].type);
        //     console.log(event.target.result)
        //     setLoading(true);application/msword
        //     if(event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || event.target.files[0].type=='application/msword' )
        //     {
        //         setServiceFile(event.target.files[0]);
        //         setServiceFileNames(event.target.files[0].name);

        //         const formData = new FormData();
        //         formData.append('service_filename',event.target.files[0]);
        //             const config = {     
        //                 headers: { 'content-type': 'multipart/form-data' }
        //             }
        //             let apiRes = await axios.post(`${API_BASE_URL}service/wordcount`,formData,config) 

        //             if (apiRes) 
        //             {
        //                 console.log(apiRes.data.res);
        //                 if (apiRes.data.res == true) 
        //                 {
        //                     setCountDetails(apiRes.data.data[0]);
        //                     setFileDlt(apiRes.data.data[0].onlyFileName)
        //                     setAutoData(true);
        //                     console.log(apiRes.data);
        //                 }
        //             }
        //     }
        //     else
        //     {
        //         NotificationManager.error('Upload Valid File. Only DOC and DOCX are allowed', '',2000); 
        //     }
        //     setLoading(false);
        // }
        // catch (error) 
        // {

        // }
        // console.log(4);
        // NotificationManager.error('File is not supported', '',3000);

        console.log(e.currentTarget.files[0]);
        console.log(e.currentTarget.files[0].type);
        if (
            e.currentTarget.files[0].type == 'application/msword' ||
            e.currentTarget.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            e.currentTarget.files[0].type == 'application/pdf') {
            // if(((file.length + e.target.files.length)>5) || serviceFileNames.length > 5)
            // {
            //   NotificationManager.error('Please upload minimum 5 file at a time', '',3000);
            // }
            // else
            // {
            const formData = new FormData();
            setLoading(true);
            for (let i = 0; i < e.target.files.length; i++) {
                setFileNames(old => [...old, e.target.files[i].name]);
                setFile(old => [...old, e.target.files[i]]);

                // setMergeServiceFileNames(old => [...old, e.target.files[i].name]);
                // setMergeServiceFile(old => [...old, e.target.files[i]]);

                formData.append('service_filename[]', e.target.files[i]);
                console.log(file);
            }
            const config = {
                headers: { 'content-type': 'multipart/form-data' },
                // onUploadProgress: data =>{
                //      console.log(Math.round((100 * data.loaded) / data.total));
                //      setProgress(Math.round((100 * data.loaded) / data.total))
                // }
            }
            let apiRes = await axios.post(`${API_BASE_URL}service/wordcount`, formData, config)
            if (apiRes) {
                console.log(apiRes.data.res);
                // console.log(mergeServiceFile);
                if (apiRes.data.res == true) {
                    setCountDetails(apiRes.data.data[0]);
                    // setUpdateCount(updateCount + Number(apiRes.data.data[0]?.count));
                    // setUpdateRegPrice(updateRegPrice + Number(apiRes.data.data[0]?.regularprice));
                    // setUpdateExpPrice(updateExpPrice + Number(apiRes.data.data[0]?.expeditedprice));
                    // setUpdateRegService(updateRegService + Number(apiRes.data.data[0]?.regular));
                    // setUpdateExpService(updateExpService + Number(apiRes.data.data[0]?.expedited));



                    setUpdateCount(prev => prev + Number(apiRes.data.data[0]?.count));
                    setUpdateRegPrice(prev => prev + Number(apiRes.data.data[0]?.regularprice));
                    setUpdateExpPrice(prev => prev + Number(apiRes.data.data[0]?.expeditedprice));
                    setUpdateRegService(prev => prev + Number(apiRes.data.data[0]?.regular));
                    setUpdateExpService(prev => prev + Number(apiRes.data.data[0]?.expedited));
                    // console.log(updateRegPrice + Number(apiRes.data.data[0]?.regularprice));
                    // console.log(updateExpPrice + Number(apiRes.data.data[0]?.expeditedprice));
                    // console.log(updateExpPrice,apiRes.data.data[0]?.regularprice,updateExpService,apiRes.data.data[0]?.expeditedprice);

                    // console.log(apiRes.data.data[0].regular)
                    // setService(apiRes.data.data[0]?.regular);
                    // console.log(countDetails?.regular)
                    // countDetails?.regular
                    setFileDlt(apiRes.data.data[0].onlyFileName)
                    setServiceFileNames(apiRes.data.data[0].onlyFileName);
                    setService(apiRes.data.data[0].regular); // for default set Regular Service Hours 
                    setFile([]);
                    for (let i = 0; i < apiRes?.data?.data[0]?.onlyFileName?.length; i++) {
                        console.log(e.target.files[i].name)
                        setMergeServiceFileNames(old => [...old, apiRes.data.data[0].onlyFileName[i]]);
                        setMergeServiceFile(old => [...old, e.target.files[i]]);
                        setMergeServiceFullFileNames(old => [...old, apiRes.data.data[0].service_filename[i]]);
                    }


                    // setMergeServiceFile(prev => [...prev,apiRes.data.data[0].service_filename]);
                    // setMergeServiceFileNames(prev => [...prev,apiRes.data.data[0].onlyFileName]);
                    setAutoData(true);
                    console.log(apiRes.data);
                    setShowSFile(true);
                    setLoading(false);
                }
                else {
                    NotificationManager.error(apiRes.data.msg, '', 2000);
                }
                // console.log(mergeServiceFileNames);
            }
            // }
        }
        else {
            NotificationManager.error('File is not supported', '', 3000);
        }

    };

    //for instruction file uploading
    const instructionFileHandle = async (e) => {
        console.log(e.currentTarget.files[0]);
        console.log(e.currentTarget.files[0].type);
        if (
            e.currentTarget.files[0].type == 'application/msword' ||
            e.currentTarget.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            e.currentTarget.files[0].type == 'application/pdf') {
            // if(((insFile.length + e.target.files.length)>5) || instructionFile.length > 5)
            // {
            //   NotificationManager.error('Please upload minimum 5 file at a time', '',3000);
            // }
            // else
            // {
            const formData = new FormData();
            setLoading1(true);
            for (let i = 0; i < e.target.files.length; i++) {
                setInsFile(old => [...old, e.target.files[i].name]);
                setInsFile(old => [...old, e.target.files[i]]);
                formData.append('instruction_filename[]', e.target.files[i]);
            }
            const config = {
                headers: { 'content-type': 'multipart/form-data' },
            }
            let apiRes = await axios.post(`${API_BASE_URL}service/instructionfiles`, formData, config)

            if (apiRes) {
                console.log(apiRes.data.res);
                if (apiRes.data.res == true) {
                    // setInstructionFileNames(apiRes.data.data[0].onlyFileName);
                    // setInstructionFullFileNames(apiRes.data.data[0].instruction_filename);
                    setInsFile([]);
                    for (let i = 0; i < apiRes?.data?.data[0]?.onlyFileName?.length; i++) {
                        // console.log(e.target.files[i].name)
                        // setMergeServiceFileNames(old => [...old, apiRes.data.data[0].onlyFileName[i]]);
                        // setMergeServiceFile(old => [...old, e.target.files[i]]);
                        // setMergeServiceFullFileNames(old => [...old, apiRes.data.data[0].service_filename[i]]);
                        setInstructionFileNames(old => [...old, apiRes.data.data[0].onlyFileName[i]]);
                        setInstructionFullFileNames(old => [...old, apiRes.data.data[0].instruction_filename[i]]);
                    }
                    setAutoData(true);
                    setShowIFile(true);
                    setLoading1(false);
                }
                else {
                    setLoading1(false);
                    NotificationManager.error(apiRes.data.msg, '', 2000);
                }
            }
            // }
        }
        else {
            NotificationManager.error('File is not supported', '', 3000);
        }
    };

    //for instruction file uploading
    // function instructionFileHandle(e)
    // {
    //     setInstructionFile(e.target.files[0]);
    //     setInstructionFileNames(e.target.files[0].name);
    // }

    //for instruction file uploading
    // const instructionFileHandle = async(event) =>
    // { 
    //     try
    //     {
    //         console.log(event.target.files[0]);
    //         console.log(event.target.files[0].type);
    //         console.log(event.target.result)

    //         setLoading1(true);
    //         if(event.target.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    //         {
    //             setInstructionFile(event.target.files[0]);
    //             setInstructionFileNames(event.target.files[0].name);
    //             const formData = new FormData();
    //             formData.append('instruction_filename',event.target.files[0]);
    //                 const config = {     
    //                     headers: { 'content-type': 'multipart/form-data' }
    //                 }
    //                 let apiRes = await axios.post(`${API_BASE_URL}service/instructionfiles`,formData,config) 

    //                 if (apiRes) 
    //                 {
    //                 console.log(apiRes.data.res);
    //                 if (apiRes.data.res == true) 
    //                 {
    //                     setInstructFile(apiRes.data.data[0].instruction_filename);
    //                     setInsFileDelete(apiRes.data.data[0].onlyFileName);
    //                     console.log((apiRes.data.data[0].onlyFileName))
    //                     console.log(apiRes.data.data);
    //                 }
    //                 }
    //         }
    //         else
    //         {
    //             NotificationManager.error('Upload Valid File. PDF, DOC and DOCX are allowed', '',2000); 
    //         }
    //         setLoading1(false);
    //     } 
    //     catch (error) 
    //     {

    //     }
    // };

    //for instruction file delete
    // function deleteInstructionFile()
    // {    
    //     setInstructionFile('');
    //     setInstructionFileNames('');
    // }

    // https://www.demoupdates.com/updates/elite/api/job/existingeditor

    //Existing Client List by search
    const existingClientList = async () => {
        try {
            let apiRes = await axios.get(`${API_BASE_URL}job/existingeditorSearch?user_id=${data?.id}`)
            if (apiRes) {
                console.log(apiRes.data.data);
                if (apiRes.data.res == true) {
                    // setshowExistingEditor(apiRes.data.data);
                    setListEditor([]);
                    apiRes.data.data.map((value) => {
                        setListEditor(oldArray => [...oldArray, {
                            id: value.editor_id,
                            name: value.editor_id,
                            editor_email: value.editor_email,
                            editor_name: value.editor_name,
                            designation: value.designation,
                            role: value.role
                        }]);
                    });
                }
            }
        }
        catch (error) {
            // NotificationManager.error(error.message, "", 2000);
        }
    }

    const edtId = listEditor;
    console.log(edtId);


    //Existing Client List by dropdown
    const existingClientListDropdown = async () => {
        try {
            let apiRes = await axios.get(`${API_BASE_URL}job/existingeditor?user_id=${data?.id}`)
            if (apiRes) {
                console.log(apiRes.data.data);
                if (apiRes.data.res == true) {
                    setshowExistingEditor(apiRes.data.data);
                }
            }
        }
        catch (error) {
            // NotificationManager.error(error.message, "", 2000);
        }
    }



    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //    console.log(string, results)
        console.log(string)
    }

    const handleOnHover = (result) => {
        // the item hovered
        //console.log(result)
    }

    const handleOnSelect = (item) => {
        // the item selected
        // console.log(item?.id);
        // console.log(item?.editor_name);
        // console.log(item?.editor_email);

        // setSelectedEditorId(item?.id);
        // setSelectedEditorName(item?.editor_name);
        // setSelectedEditorEmail(item?.editor_email);

        editorDetailsById(item?.id);

    }

    const handleOnFocus = () => {
        // console.log('Focused')
    }
    return (
        <div>
            <Header />
            <div className="inner-banner" style={{ backgroundImage: `url(${servicesBanner})` }}>
                <div className="container">
                    <h3 data-aos="fade-up">Services</h3>
                    <p data-aos="fade-up">We place great diligence on every work submitted. You can always count on us to achieve the best outcome.</p>
                </div>
            </div>
            <div className="services-sec-one">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 left-text-content">
                            <h3>{showServiceName}</h3>
                            {/* <h3>sumit</h3> */}
                        </div>
                        <div className="col-md-7 col-lg-8 right-text-content">
                            {/* <p>We understand you take great pride in your academic, business, professional, or literary endeavors, and you are non-English speaker. That is why we take great interest, as well. Our editors will correct misspellings, typos, and grammatical errors in your work to make it readable and articulate. We take pride in our
        services. You have nothing to worry about.</p> */}
                            <p>{serviceInfo?.full_description}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-sec-two">
                <div className="container">
                    <ul className="row service-form-list">
                        <li className="col-md-6">
                            <Form.Group>
                                <Form.Select name="subservice" onChange={(e) => { setSelectedSubService(e.target.value) }}>
                                    <option hidden>Sub Service</option>
                                    {subService && subService.map((item, index) => {
                                        return (
                                            <option value={item?.title}>{item?.title}</option>
                                        )
                                    })}
                                    {/* <option value="10">Abstract</option>
                                    <option value="7">Journal Article</option>
                                    <option value="9">Presentation</option>
                                    <option value="11">Research Paper</option>
                                    <option value="8">Research Proposal</option> */}
                                </Form.Select>
                            </Form.Group>
                            {/* {showError && <div className='error'><p>SubService is required</p></div>} */}
                            {showError && (selectedSubService == undefined || selectedSubService == '') && <div><p style={{ color: 'red', fontFamily: 'Poppins', fontWeight: '400' }}>SubService is required</p></div>}
                            {/* <div className='error'><p>SubService is required</p></div> */}
                        </li>
                        <li className="col-md-6">
                            <Form.Group>
                                <Form.Select name="version" onChange={(e) => { setSelectedVersion(e.target.value) }}>
                                    <option hidden>Select English Version</option>
                                    <option value="1">American</option>
                                    {/* <option value="2">Australian</option> */}
                                    <option value="3">British</option>
                                    <option value="4">Canadian</option>
                                </Form.Select>
                            </Form.Group>
                            {showError && (selectedVersion == undefined || selectedVersion == '') && <div><p style={{ color: 'red', fontFamily: 'Poppins', fontWeight: '400' }}>English Version is required</p></div>}
                        </li>
                        <li className="col-lg-6">
                            <div className="row">
                                <div className="col-md-3">
                                    {loading ? (
                                        <>
                                            <i class="fa fa-refresh fa-spin" style={{ fontSize: '48px' }}></i><br />
                                            {/* <span>File Uploading....</span> */}
                                        </>
                                    ) : (
                                        <div className="styles-browser-sec">
                                            {/* <input type="file" className="browseInput" name = "file"  onChange={(e) => {serviceFileHandle(e)}}/> */}
                                            {/* <input type="file" id="file" multiple name = "file" onChange={e => {fileHandle(e); setFieldValue('file',e.currentTarget.files[0]); }}/> */}
                                            <input type="file" className="browseInput" id="file" multiple name="file" onChange={e => { serviceFileHandle(e) }} />
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-9 upload-content">

                                    {loading ? (
                                        <>
                                            <h4>Uploading....</h4>
                                            <p>Please wait....</p>

                                        </>
                                    ) : (
                                        <>
                                            <h4>Choose File</h4>
                                            <p>Preferred files are MS Word & PDF </p>
                                        </>
                                    )}

                                    {/* {!loading ? (
                                        <>
                                         {showSFile && (
                                    <ul className="service-upload-list">
                                   
                                        <>
                               
                                        {serviceFileNames && serviceFileNames.length>0 && serviceFileNames.map((item,index) =>(
                                        <li>
                                            <span className="icon-span">
                                                {
                                                    item.endsWith(".docx")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".DOCX")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".doc")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".DOC")?<img src={docsImg} alt="img" />:
                                                    item.endsWith(".pdf")?<img src={pdfImg} alt="img" />:
                                                    item.endsWith(".PDF")?<img src={pdfImg} alt="img" />:
                                                    NotificationManager.error('File type is not matched!', '',2000)
                                                }
                                            </span>
                                            <span className="upload-title-span">{item?.slice(5)}</span>
                                            <span className="delete-upload-span" onClick={()=>deleteFile(index,item)}><i class="bi bi-trash"></i></span>
                                            <span className="downoad-upload-span" onClick={()=>downloadFile(item)}><i class="bi bi-download"></i></span>
                                        </li>
                                        ))}
                                         </>
                                    
                                    </ul>
                                    )}

                                    </>
                                    ):''} */}
                                </div>
                                <div className="col-md-12 upload-content">

                                    {/* {!loading ? (
                                        <> */}
                                    {/* {showSFile && ( */}
                                    <ul className="service-upload-list">
                                        {/* {mergeServiceFileNames && mergeServiceFileNames} */}
                                        <>
                                            {/* {fileNames && fileNames.length>0 && fileNames.map((item,index) =>( */}
                                            {/* {serviceFileNames && serviceFileNames.length>0 && serviceFileNames.map((item,index) =>( */}
                                            {mergeServiceFullFileNames && mergeServiceFullFileNames.length > 0 && mergeServiceFileNames.map((item, index) => (
                                                <>
                                                    <li>
                                                        {/* <br/>{mergeServiceFileNames && mergeServiceFileNames} */}
                                                        <span className="icon-span">
                                                            {
                                                                item?.endsWith(".docx") ? <img src={docsImg} alt="img" /> :
                                                                    item?.endsWith(".DOCX") ? <img src={docsImg} alt="img" /> :
                                                                        item?.endsWith(".doc") ? <img src={docsImg} alt="img" /> :
                                                                            item?.endsWith(".DOC") ? <img src={docsImg} alt="img" /> :
                                                                                item?.endsWith(".pdf") ? <img src={pdfImg} alt="img" /> :
                                                                                    item?.endsWith(".PDF") ? <img src={pdfImg} alt="img" /> :
                                                                                        NotificationManager.error('File type is not matched!', '', 2000)
                                                            }
                                                        </span>
                                                        <span className="upload-title-span">{item?.slice(5)}</span>
                                                        {/* <span className="upload-title-span">{item}</span> */}
                                                        <span className="delete-upload-span" onClick={() => deleteFile(index, item)}><i class="bi bi-trash"></i></span>
                                                        <span className="downoad-upload-span" onClick={() => downloadFile(item)}><i class="bi bi-download"></i></span>
                                                    </li>
                                                    {/* <li>{TypeError(mergeServiceFileNames && mergeServiceFileNames)}</li> */}
                                                    {/* <li>
                                        {Object.keys(mergeServiceFileNames).map((item, i) => (
                                            <li className="travelcompany-input" key={i}>
                                                {mergeServiceFileNames[i]}
                                            </li>
                                        ))}
                                        </li> */}
                                                </>
                                            ))}
                                        </>
                                    </ul>
                                    {/* )} */}

                                    {/* </>
                                    ):''} */}
                                </div>
                            </div>
                            {/* {showError && (serviceFileNames==undefined || serviceFileNames == '') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>File is required</p></div>} */}
                            {showError && (mergeServiceFileNames == undefined || mergeServiceFileNames == '') && <div><p style={{ color: 'red', fontFamily: 'Poppins', fontWeight: '400' }}>File is required</p></div>}
                        </li>
                        <li className="col-lg-6">
                            <ul className="row radio-list">
                                <li className="col-md-6">
                                    {/* <span className="blue-text-price">Total Word Count : 585</span> */}
                                    {countDetails && countDetails ? (
                                        // <span className="blue-text-price">Total Word Count : {countDetails?.count}</span>
                                        <span className="blue-text-price">Total Word Count : {updateCount}</span>
                                    ) : (
                                        <span className="blue-text-price">Total Word Count : 0</span>
                                    )}
                                </li>
                                <li className="col-md-6">
                                    {/* <span className="yellow-text-price">Total Price: $26.32</span> */}
                                    {/* (Math.round(0.9)); */}
                                    {((countDetails?.regularprice != undefined) && showLevel === 'Regular Service') ? (
                                        <>
                                            {/* <span className="yellow-text-price">Total Price: ${Math.round(Number(countDetails?.regularprice))}</span> */}
                                            {/* <span className="yellow-text-price">Total Price: ${(Number(countDetails?.regularprice)).toFixed(2)}</span> */}
                                            <span className="yellow-text-price">Total Price: ${(Number(updateRegPrice)).toFixed(2)}</span>
                                        </>
                                    ) : (
                                        <>
                                            {((countDetails?.expeditedprice != undefined) && showLevel === 'Expedited Service') ? (
                                                // <span className="yellow-text-price">Total Price: ${Math.round(Number(countDetails?.expeditedprice))}</span>
                                                // <span className="yellow-text-price">Total Price: ${(Number(countDetails?.expeditedprice)).toFixed(2)}</span>
                                                <span className="yellow-text-price">Total Price: ${(Number(updateExpPrice)).toFixed(2)}</span>
                                            ) : (
                                                <span className="yellow-text-price">Total Price: $0</span>
                                            )}
                                        </>
                                        // <span className="yellow-text-price">Total Price: $0</span>
                                    )}

                                    {/* {countDetails && countDetails ? (
                                        <span className="yellow-text-price">Total Price: ${countDetails?.expeditedprice}</span>
                                    ):(
                                        <span className="yellow-text-price">Total Price: $0</span>
                                    )} */}
                                </li>
                                {(countDetails && countDetails != null) ? (
                                    <li className="col-md-6">
                                        {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service" defaultChecked value="24" onChange={(e)=>{setService(e.target.value);setService('24')}}/> */}
                                        {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service" defaultChecked value={countDetails?.regular} onChange={(e)=>{setService(e.target.value);setShowLevel('Regular Service')}}/> */}
                                        {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service" defaultChecked value={countDetails?.regular} onChange={(e)=>{setService(e.target.value);setShowLevel('Regular Service')}}/> */}
                                        <Form.Check type="radio" label={` Regular Service (${updateRegService} hrs)`} name="service" defaultChecked value={updateRegService} onChange={() => { setUpdateRegService(updateRegService); setShowLevel('Regular Service') }} />
                                    </li>
                                ) : (
                                    <li className="col-md-6">
                                        {/* <Form.Check type="radio" label={` Regular Service (${countDetails?.regular?countDetails?.regular:'0'}hrs)`} name="service"  value="24" disabled/> */}
                                        <Form.Check type="radio" label={` Regular Service (${updateRegService} hrs)`} name="service" value="24" disabled />
                                    </li>
                                )}

                                {(countDetails && countDetails != null) ? (
                                    <li className="col-md-6">
                                        {/* <Form.Check type="radio" label={` Expedited Service (${countDetails?.expedited?countDetails?.expedited:'0'}hrs)`} name="service" value="12"  onChange={(e)=>{setService(e.target.value);setService('12')}}/> */}
                                        {/* <Form.Check type="radio" label={` Expedited Service (${countDetails?.expedited?countDetails?.expedited:'0'}hrs)`} name="service" value={countDetails?.expedited}  onChange={(e)=>{setService(e.target.value);setShowLevel('Expedited Service')}}/> */}
                                        <Form.Check type="radio" label={` Expedited Service (${updateExpService} hrs)`} name="service" value={updateExpService} onChange={() => { setUpdateExpService(updateExpService); setShowLevel('Expedited Service') }} />
                                    </li>
                                ) : (
                                    <li className="col-md-6">
                                        {/* <Form.Check type="radio" label={` Expedited Service (${countDetails?.expedited?countDetails?.expedited:'0'}hrs)`} name="service" value="12" disabled/> */}
                                        <Form.Check type="radio" label={` Expedited Service (${updateExpService} hrs)`} name="service" value="12" disabled />
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li className="col-md-12 img-sec">
                            <label>Client Instruction</label>
                            {/* <img src={ckEditorImg} alt="" /> */}
                            {/* <Editor
                                // editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                wrapperStyle={{ width: 1120, border: "1px solid black" }}
                                // onEditorStateChange={onEditorStateChange()}
                                // placeholder="Tell a story..."
                                onChange={onEditorStateChange}
                                toolbar={{
                                    options:['blockType','inline','colorPicker'],
                                    textAlign: { inDropdown: true },
                                    inline :{options:['bold', 'italic', 'underline', 'strikethrough','superscript','subscript']}
                                  }}
                                //   toolbar={{
                                //     options:['inline','blockType', 'fontSize', 'fontFamily', 'list',  'history','emoji','colorPicker', 'link','remove'],
                                //     textAlign: { inDropdown: true },
                                //     inline :{options:['bold', 'italic', 'underline', 'strikethrough','superscript','subscript']}
                                //   }}
                                // onEditorStateChange={ ( event, editor ) => {
                                //     const data = editor.getData();
                                //     // console.log(data);
                                //     setEditorData(data);
                                // } }
                            /> */}
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    // toolbar: [ 'bold', 'italic', 'heading' ,'|', 'undo', 'redo']
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList',
                                        'outdent', 'indent', '|', 'undo', 'redo', '|']

                                    // toolbar: {
                                    //     items: [
                                    //         'heading', '|',
                                    //         'alignment', '|',
                                    //         'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                    //         'link', '|',
                                    //         'bulletedList', 'numberedList', 'todoList',
                                    //         '-', // break point
                                    //         'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                                    //         'code', 'codeBlock', '|',
                                    //         'insertTable', '|',
                                    //         'outdent', 'indent', '|',
                                    //         'uploadImage', 'blockQuote', '|',
                                    //         'undo', 'redo'
                                    //     ],
                                    //     shouldNotGroupWhenFull: false
                                    // }
                                }}

                                // data="<p>Hello from CKEditor 5!</p>"
                                // onReady={ editor => {
                                //     console.log( 'Editor is ready to use!', editor );
                                // }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log(data);
                                    // setEditorData(data);
                                    editorInstructionData(data)
                                }}
                            />
                            {/* {showError && (editorData==undefined || editorData=='') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Client Instruction is required</p></div>} */}
                        </li>
                        <li className="col-lg-6">
                            <div className="row">
                                <div className="col-md-3">
                                    {loading1 ? (
                                        <>
                                            <i class="fa fa-refresh fa-spin" style={{ fontSize: '48px' }}></i><br />
                                            {/* <span>File Uploading....</span> */}
                                        </>
                                    ) : (
                                        <div className="styles-browser-sec">
                                            {/* <input type="file" className="browseInput" name = "file"  onChange={(e) => {serviceFileHandle(e)}}/> */}
                                            {/* <input type="file" id="file" multiple name = "file" onChange={e => {fileHandle(e); setFieldValue('file',e.currentTarget.files[0]); }}/> */}
                                            <input type="file" className="browseInput" id="file" multiple name="file" onChange={e => { instructionFileHandle(e) }} />
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-9 upload-content">

                                    {loading1 ? (
                                        <>
                                            <h4>Uploading....</h4>
                                            <p>Please wait....</p>
                                            {/* {progress && <ProgressBar now={progress} label={`${progress}%`} /> }{progress} */}
                                        </>
                                    ) : (
                                        <>
                                            <h4>Choose Instruction Files</h4>
                                            <p>Preferred files are MS Word & PDF </p>
                                        </>
                                    )}


                                </div>
                                <div className="col-md-12 upload-content">
                                    {/* {!loading1 ? (
                                        <>
                                         {showIFile && ( */}
                                    <ul className="service-upload-list">

                                        <>
                                            {/* {fileNames && fileNames.length>0 && fileNames.map((item,index) =>( */}
                                            {instructionFileNames && instructionFileNames.length > 0 && instructionFileNames.map((item, index) => (
                                                <li>
                                                    <span className="icon-span">
                                                        {
                                                            item.endsWith(".docx") ? <img src={docsImg} alt="img" /> :
                                                                item.endsWith(".DOCX") ? <img src={docsImg} alt="img" /> :
                                                                    item.endsWith(".doc") ? <img src={docsImg} alt="img" /> :
                                                                        item.endsWith(".DOC") ? <img src={docsImg} alt="img" /> :
                                                                            item.endsWith(".pdf") ? <img src={pdfImg} alt="img" /> :
                                                                                item.endsWith(".PDF") ? <img src={pdfImg} alt="img" /> :
                                                                                    NotificationManager.error('File type is not matched!', '', 2000)
                                                        }
                                                    </span>
                                                    <span className="upload-title-span">{item?.slice(5)}</span>
                                                    <span className="delete-upload-span" onClick={() => deleteInsFile(index, item)}><i class="bi bi-trash"></i></span>
                                                    <span className="downoad-upload-span" onClick={() => downloadFile(item)}><i class="bi bi-download"></i></span>
                                                </li>
                                            ))}
                                        </>

                                    </ul>
                                    {/* )}

                                    </>
                                    ):''} */}
                                </div>
                            </div>
                            {/* {showError && (instructionFileNames == undefined || instructionFileNames == '') && <div><p style={{ color: 'red', fontFamily: 'Poppins', fontWeight: '400' }}>File is required</p></div>} */}
                        </li>
                        <li className="col-lg-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <img src={choseEditorIcon} alt="" className="editorIconImg" />
                                </div>
                                <div className="col-md-10 upload-content">
                                    <h4>Choose Editor (Optional)</h4>
                                    <p>Search editor by editor code OR choose from dropdown</p>
                                    <ul className="row radio-list">
                                        <li className="col-md-5">
                                            <div className="form-check">
                                                <input name="choose-editor" type="radio" className="form-check-input" onClick={() => { setShowOption(1) }} value="bycode" defaultChecked />
                                                <label title="" class="form-check-label"> Search by code</label>
                                            </div>
                                        </li>
                                        <li className="col-md-7">
                                            <div className="form-check">
                                                <input name="choose-editor" type="radio" className="form-check-input" onClick={() => { setShowOption(2) }} value="bydropdown" />
                                                <label title="" class="form-check-label"> Choose from dropdown</label>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="search-list">
                                        {showOption && showOption == 1 ? (
                                            <li>
                                                {/* {showError && selectedEditorId === undefined && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Enter Editor Id </p></div>} */}
                                                {/* <input type="text" name="test"  placeholder="Search editor’s code or name here" /> */}
                                                <div className="auto-search-input">
                                                    {/* <ReactSearchAutocomplete
                                                        items={edtId}
                                                        placeholder='Search Editor Code Here..'
                                                        onSearch={handleOnSearch}
                                                        onHover={handleOnHover}
                                                        onSelect={handleOnSelect}
                                                        onFocus={handleOnFocus}
                                                        autoFocus
                                                    /> */}
                                                    {/* {showError && selectedEditorId === undefined && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Editor is required</p></div>} */}
                                                    <span className="closed-auto-search" onClick={() => { setSearchingData(); onClear(); setNoRecordFound(true) }}>
                                                        {(searchingData != undefined) ? (
                                                            <img src={closedAutoSearch} alt="" />

                                                        ) : (
                                                            // <img src={closedAutoSearch} alt="" />
                                                            <></>
                                                        )}

                                                    </span>
                                                    <input ref={ref} type="text" name="test" onChange={(e) => {handleDataSearch(e)}} placeholder="Search Editor’s code here" value={searchingData} />
                                                    <>
                                                        <div className="editor-search-dropdown">
                                                            <ul>
                                                                {searchingData?.length >=3 && !noRecordFound && showFilterData && showFilterData?.map((item, index) => {
                                                                    return (
                                                                        <li className={!showSearch ? 'list-hide' : ''}>
                                                                            <div onClick={() => { setSearchingData(item?.designation); editorDetailsById(item?.id); setShowSearch(false); setIsSearchValueClicked(true)}}>
                                                                                <>
                                                                                    {!showSearch ? (
                                                                                        <></>
                                                                                    ) : (
                                                                                        <>
                                                                                            {item?.designation}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </>
                                                </div>

                                            </li>
                                        ) : (
                                            <>
                                                {showOption && showOption == 2 ? (
                                                    <li className="col-md-6">
                                                        {/* {showError && selectedEditorId === undefined && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Select Editor from dropdown</p></div>} */}
                                                        <div className="auto-search-input">
                                                            <Form.Group>
                                                                <Form.Select name="subservice" onChange={(e) => { editorDetailsById(e.target.value); setSelectEditor(e.target.value) }} value={selectEditor}>
                                                                    <option value='null' selected>Select Editor</option>
                                                                    {showExistingEditor && showExistingEditor.length > 0 && showExistingEditor.map((item, index) => {
                                                                        return (
                                                                            <option value={item?.editor_id}>{item?.designation}</option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                                {/* {showExistingEditor && showExistingEditor.length} */}

                                                                {/* {showError && (selectEditor==undefined || selectEditor == '' || selectEditor === 'null') && <div><p style={{color: 'red', fontFamily: 'Poppins', fontWeight: '400'}}>Existing Editor is required</p></div>} */}
                                                            </Form.Group>
                                                        </div>
                                                    </li>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </ul>

                                </div>
                            </div>
                        </li>
                        <li className="col-md-12 btn-list">
                            {/* <input type="submit" name="" value="Submit" className="btn btn-primary" />  */}
                            {/* <NavLink className="btn btn-primary" to="/payment">Submit</NavLink>  */}
                            {/* <Link 
                                to = {`/edit-editor/${list?.id}`}
                            > 
                            </Link> */}
                            {userData != null || userData != undefined ? (
                                <>

                                    {/* <NavLink className="btn btn-primary" 
                                        to="/payment"  
                                        state={{from:'service', path: location.pathname}}>
                                        Submit
                                    </NavLink> */}
                                    {/* {selectedEditorName && selectedEditorName!=null ?(
                                        <Button className="btn btn-primary" type="submit"  onClick={()=>LoggedExisingSubmit()}>Submit</Button>
                                    ):(
                                        <Button className="btn btn-primary" type="submit" disabled>Submit</Button>
                                    )} */}

                                    {searchingData && noRecordFound && (
                                        <span className="error-span">No Records Found.</span>
                                    )}
                                    
                                    <br />
                                    {userData?.role == '2' && (userData?.ph_no=='' || userData?.ph_no==null) && userData?.state==null && userData?.address==null && userData?.zipcode==null ?(
                                        <Button className="btn btn-primary" type="submit" onClick={()=>handleMessage(1)}>Submit</Button>
                                    ):(
                                        <Button className="btn btn-primary" type="submit" onClick={() => LoggedExisingSubmit()}>Submit</Button>
                                    )}
                                    
                                </>
                            ) : (
                                // <NavLink className="btn btn-primary" 
                                //     to="/login"  
                                //     state={{from:'service', path: location.pathname}}
                                // >
                                //      Submit
                                // </NavLink>
                                <Button className="btn btn-primary" type="submit" onClick={() => notLoginSubmit()}>Submit</Button>

                            )}
                            {/* <Button className="btn btn-primary" type="submit"  onClick={()=>submitAllData()}>Submit</Button> */}
                            {/* <NavLink className="btn btn-primary" 
                                    to="/payment"  state={{from:'service', path: location.pathname}}>
                                Submit
                                </NavLink>
                              */}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="home-yellow-section align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-8 text-content" data-aos="fade-right">
                            <h3>Still Not Sure What Service Is Right For You?</h3>
                            <p><NavLink to="/">Elite Reviser</NavLink> can handle that. 
                            <NavLink to="/contact"> Contact Us.</NavLink></p>
                        </div>
                        <div className="col-lg-5 col-md-4 right-content" data-aos="fade-left">
                            <NavLink className="btn btn-primary" to="/services">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2>Send us a message</h2>
                    {/* <p>You can cancel your order for a full refund if your request is made within 30 min after order is placed. If you cancel beyond the 30 mins, your refund will depend on the time remaining for your work to be completed</p> */}
                    <p>Word Count is greater than 1,00,000 Please Contact us</p>
                    <a class="CmnBtn yesBtn" onClick={() => redirectComponent()}>YES</a>
                    <a class="cancelBtn noBtn">NO</a>
                </Modal.Body>
            </Modal>

            <Modal show={userDetails} onHide={userDetailsClose} className="CmnModal CancelOrder editAccModal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <main className="main">
                        <div className="pagetitle">
                            <span className="TitleWrap">
                                <span className="NameLogo">{showClientData?.first_name?.charAt(0)}{showClientData?.last_name.charAt(0)}</span>
                                <h2>Edit Account</h2>
                            </span>
                        </div>

                        <section className="section AccDtlsPnl">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    id: showClientData && showClientData.id,
                                    first_name: showClientData && showClientData.first_name,
                                    last_name: showClientData && showClientData.last_name,
                                    ph_no: showClientData && showClientData.ph_no,
                                    email_address: showClientData && showClientData.email_address,
                                    address: showClientData && showClientData.address,
                                    zipcode: showClientData && showClientData.zipcode,
                                    state: showClientData && showClientData.state,
                                }}
                                onSubmit={(values,{resetForm})=>{updateEditorData(values,{resetForm})}}
                                validationSchema = {schema}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    errors,
                                    values, 
                                    setFieldValue
                                })=>(
                            <div className="AccBox">
                                <div className="pagetitle">
                                <h3>Personal Details</h3>
                                {loading && loading ? (
                                    <Button className="btn btn-primary CmnBtn" type="submit" disabled style={{cursor: 'not-allowed'}}><i className="bi bi-check-circle" />Update</Button>
                                    ):(
                                    <Button className="btn btn-primary CmnBtn" type="submit"  onClick={()=>handleSubmit()}><i className="bi bi-check-circle" />Update</Button>
                                    )
                                }
                                </div>
                                <form action method="get">
                                <ul className="AccDtls EditAccDtls row">
                                <input type="hidden" className="InptField" id="firstInput" name="id" placeholder="Id" onChange={handleChange} value={values.id}/>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="firstInput" name="first_name" placeholder="First Name" onChange={handleChange} value={values.first_name}/>
                                    {errors.first_name ? <div className='error'>{errors.first_name}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="lastInput" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name}/>
                                    {errors.last_name ? <div className='error'>{errors.last_name}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="number" className="InptField" id="phoneInput" placeholder="Phone Number" name="ph_no"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleChange} value={values.ph_no}/>
                                    {errors.ph_no ? <div className='error'>{errors.ph_no}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="email" className="InptField" id="emailInput" placeholder="Email Address" name="email_address" onChange={handleChange} value={values.email_address}/>
                                    {errors.email_address ? <div className='error'>{errors.email_address}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="nameInput" placeholder="State" name="state" onChange={handleChange} value={values.state} />
                                    {errors.state ? <div className='error'>{errors.state}</div> : null}
                                    </li>
                                    <li className="col-md-4">
                                    <input type="text" className="InptField" id="zipInput" placeholder="Zip Code" name="zipcode" onChange={handleChange} value={values.zipcode}/>
                                    {errors.zipcode ? <div className='error'>{errors.zipcode}</div> : null}
                                    </li>
                                    <li className="col-md-8">
                                    <input type="text" className="InptField" id="addressInput" placeholder="Address" name="address" onChange={handleChange} value={values.address}/>
                                    {errors.address ? <div className='error'>{errors.address}</div> : null}
                                    </li>
                                </ul>
                                </form>
                            </div>
                            )}
                            </Formik>
                        </section>
                    </main>
                
                {/* <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a> */}
                {/* <a class="cancelBtn noBtn" onClick={()=>orderCancelClose()}>NO</a> */}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Servicesexistingclient;