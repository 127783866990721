import {
  React, Component, useEffect, useState, useRef,
  NavLink, useNavigate,
  Formik, Field, 
  yup,
  axios,
  NotificationManager,
  Button,
  Modal,
  DateTime,
  DatePicker,
  CKEditor,
  ClassicEditor,

    // all icons
    reviewIcon,
    docFileImg,
    docImg,
    iconImg,
    pdfImg,
    attachFileIcon,
    rtfImg,
    odtImg,
    pagesImg,
    pptImg,
    xlsxImg,
    gdocImg,
    gslideImg,
    gsheetImg,
    plainTextImg,
    LatexImg,
    linkIcon,
    feedbackStarImg
} from '../../utility/CommonImport'; // Import 
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from 'react-select';

import InternalEditorHeader from "../../Components/InternalComponents/InternalEditorHeader";
import InternalEditorSidebar from "../../Components/InternalComponents/InternalEditorSidebar";
import $ from "jquery";
import Form from 'react-bootstrap/Form';
import "react-datepicker/dist/react-datepicker.css";
import CountdownTimer from "../../Timer/CountdownTimer";


import { API_BASE_URL} from '../../api_url';
import { get_date_format, downloadFile, get_time_format, getymdFormattedDate, downloadEditorFile } from '../../utility/Common';
import CountUpTimerComponent from '../../Timer-new/CountUpTimerComponent';

// const reviewIcon = require("../../assets/img/ReviewIcon.png");
// const docFileImg = require("../../assets/img/DocFileImg.png");
// const docImg = require("../../assets/img/docs-img.png");
// const iconImg = require("../../assets/img/image-icon.png");
// const pdfImg = require("../../assets/img/pdf.png");
// const attachFileIcon = require("../../assets/img/attach-icon.png");
// const linkIcon = require("../../assets/img/link-icon.png");
// const feedbackStarImg = require("../../assets/img/feedbackStar.png");



const InternalEditorHoldingJobs = () => {
  const windowRef = useRef();
  const timeZone = DateTime.local().zoneName;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [jobIdArr, setJobIdArr] = useState([]);
  const [initVal, setInitVal] = useState('1');
  const [isAssigned,setIsAssigned] = useState(false);
  const [notificationMsg,setNotificationMsg] = useState();

  //API dates
  const [useStartDate,setUseStartDate]= useState();
  const [useEndDate,setUseEndDate]= useState();
  const [endCounterTimer,setEndCounterTimer] = useState(false);
  const [fileName,setFileName] = useState(); // comment file upload

  let loggedUser = JSON.parse(localStorage.getItem("user_data_elite"));
  // console.log(loggedUser) 

  const openJobDetlsPnl = () => {
    $(".openJobDetlsPnl").addClass("Act");
  };
  const closeJobDetlsPnl = () => { 
    $(".openJobDetlsPnl").removeClass("Act");
  };
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [loading,setLoading] = useState(false); 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [showModal, setShowModal] = useState(false);
  const modalclose  = () => setShowModal(false)
  const [showEditorName,setShowEditorName] = useState(); // editor name
  const [showDropDown,setShowDropDown] = useState(false);
  const [showDropDown1,setShowDropDown1] = useState(false);
  const [showMsg,setShowMsg] = useState();
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }
  const [showPartialModal, setShowPartialModal] = useState(false);
  const partialModalShow = () => setShowPartialModal(true);
  const partialModalclose  = () => setShowPartialModal(false);

  const [jobList,setJobList] = useState([]); // all job list
  const [jobInfoById,setJobInfoById] = useState(); // job deatils by id
  const [editorAllSubmittedFiles,setEditorAllSubmittedFiles] = useState([]);
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setInstructionFile] = useState();
  const [editorEmail,setEditorEmail] = useState(); // for editor assign
  const [seconds, setSeconds] = useState(30);
  const [pause, setPause] = useState(false);
  const [ShowAllComments,setShowAllComments] = useState([]); // all comments.
  const [apiCall,setAPiCall] = useState(false);
  const [callAPI,setCallAPI] = useState(false);

  const [userAssignType,setUserAssignType] = useState('Editor');
  const [showEditorList,setShowEditorList] = useState([]); // for all editors
  const [showInternalList,setShowInternalList] = useState([]); // for all internal

  const [selectedEditorId,setSelectedEditorId] = useState();
  const [selectedInternalEditorId,setSelectedInternalEditorId] = useState();
  const [prevEditorWC,setPrevEditorWC] = useState();
  const [prevEditorAmount,setPrevEditorAmount] = useState();
  const [currentEditorWC,setCurrentEditorWC] = useState();
  const [currentEditorAmount,setCurrentEditorAmount] = useState();
  const [adminMsg,setAdminMsg] = useState();
  const [file,setFile] = useState(); // comment file upload

  const [reviewerId,setReviewerId] = useState(); // for internal id who review the job
  const [isOpen, setIsOpen] = useState(false);
  const customSelectRef = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false); // control the dropdown menu open state
  const selectRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [selectedReleaseJob,setSelectedReleaseJob] = useState([]);
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  const options = [
    {
      label: "1",
      value: 1
    },
    {
      label: "2",
      value: 2
    },
    {
      label: "3",
      value: 3
    },
    {
      label: "4",
      value: 4
    }
  ];

  const onInputChange = (options, { action }) => {
    if (action === "menu-close") {
      setIsAssigned(false);
    }
  };

  const openMenu = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      // selectRef.current.size = showInternalList.length;
    }
    setIsAssigned(true);
  };

    // Toggle dropdown visibility
    const toggleMenu = () => {
      setMenuIsOpen((prevMenuIsOpen) => !prevMenuIsOpen); // Toggle the menu state
      if (customSelectRef.current) {
        customSelectRef.current.focus();
      }
    };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // alert(`Selected: ${option.label}`);
    alert(`Selected: ${option?.first_name} ${option?.last_name}`);
  };

  useEffect(() => {
      holdingJobList();
      getAllEditor();
      getAllInternal();
      console.log(reviewerId)
      getInternalNotification();
  }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        if(!pause) { //I used '!paused' because I set pause initially to false. 
          if (seconds > 0) {
            setSeconds(seconds - 1);
            // console.log(1);
          }
        }
        // getAllJobs();
      }, 1000);
      return () => clearInterval(interval);
    });
    
    useEffect(() => {
      if(apiCall)
      {
        let timer = setTimeout(() => { 
          console.clear();
          setCallAPI(!callAPI);
          // jobListByClient();
          fetchAllComments(jobInfoById?.user_id,jobInfoById?.editor_id,jobInfoById?.id,jobInfoById?.internal_id);
        }, 15000);
        
        return () => clearTimeout(timer);
        // console.log(callAPI);
      }
    // }, [callAPI])
    })

    // useEffect(()=>{
    //   console.log(showInternalList)
    // },[showInternalList])

    useEffect(() => {
      const checkIfClickedOutside = e => {
        if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
        {
          setIsWindowOpen(false)
          closeJobDetlsPnl();
        }
        else
        {
          console.log('here')
          console.log(isWindowOpen)
          console.log(windowRef.current)
        }
      }
      document.addEventListener("mousedown", checkIfClickedOutside)
    
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
      }
    }, [isWindowOpen])
    // Close the dropdown when an option is selected
    const handleOptionSelect = () => {
      setIsOpen(false);
    };

  //get formatted start date
  const getFormattedStartDate = (start_date) =>{
    setUseStartDate(start_date);
  }

  //short comment-file name
  const shortCommentFileName = (item) =>
  {
      let splt_filename = item?.split("/");
      return splt_filename[1];
  }

  //Counter
  const remainingTimeCount = (datetime) =>{
      console.log(datetime)
      var split_days = datetime?.split(",");
        console.log(split_days)
        var sp_days = split_days[0].slice(0,2);
        var sp_hours = split_days[1].slice(0,2);
        var sp_minutes = split_days[2].slice(0,2);
        var sp_seconds = split_days[3].slice(0,2);
    
        // console.log('Days: ' + sp_days + ' ' + 'Hours: ' + sp_hours + ' ' + 'Minutes: ' + sp_minutes + ' ' + 'Seconds: ' + sp_seconds);
        // console.log('Days: ' + sp_days.slice(0,2) + ' ' + 'Hours: ' + sp_hours.slice(0,2) + ' ' + 'Minutes: ' + sp_minutes.slice(0,2) + ' ' + 'Seconds: ' + sp_seconds.slice(0,2));
    
      const DAYS_IN_MS = parseInt(sp_days.slice(0,2))* 24 * 60 * 60 * 1000;
      const hour_ms = parseInt(sp_hours.slice(0,2)) * 3600000;
      const minutes_ms = parseInt(sp_minutes.slice(0,2)) * 60000;
      const sec_ms = parseInt(sp_seconds.slice(0,2)) * 1000;
      const total_ms = DAYS_IN_MS + hour_ms + minutes_ms + sec_ms;
      console.log(total_ms)
    
      if(total_ms < 0 )
      {
        setEndCounterTimer(true);
      }
      else
      {
        // setEndCounterTimer(true);
        const NOW_IN_MS = new Date().getTime();
        const dateTimeAfterThreeDays = NOW_IN_MS + total_ms;
        console.log(dateTimeAfterThreeDays)
        // <CountdownTimer targetDate={dateTimeAfterThreeDays} />
        return(<CountdownTimer targetDate={dateTimeAfterThreeDays} />)
      }
  }

  const timeReachedMessage = () =>{
    return 'Time Reached';
  }

  const timeReachedMessage_editor = (job_id) =>{
    handleSingleJobRelease(job_id);
    return 'Time Reached';
  }

  
  //get Notification
  const getInternalNotification= async() =>{
    try
    {
        let apiRes = await axios.post(`${API_BASE_URL}job/getNotification`, {
            user_id: data?.id,
        });
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
                setNotificationMsg(apiRes.data.NotificationUnred);
            }
        }
    } //close try
    catch (error) 
    {
        // NotificationManager.error(error.message, "", 2000);
    }
  }

  //Client-Editor-Admin Comment Section
  const commentSection = async() =>{
      if((adminMsg === '' || adminMsg == null || adminMsg == undefined) && (fileName === '' || fileName == null || fileName == undefined))
      {
          console.log(adminMsg);
          NotificationManager.warning('Please write something..','',2000);
      }
      else
      {
        try 
        {
            const formData = new FormData();
            formData.append('user_id',jobInfoById?.user_id);
            formData.append('editor_id',jobInfoById?.editor_id ? jobInfoById?.editor_id : '');
            formData.append('admin_id',loggedUser?.id);
            formData.append('internal_id',jobInfoById?.internal_id ? jobInfoById?.internal_id : '');
            formData.append('job_id',jobInfoById?.id);

            formData.append('editor_designation',jobInfoById?.editor_designation? jobInfoById?.editor_designation : '');
            formData.append('internal_designation',jobInfoById?.internal_designation? jobInfoById?.internal_designation : '');
            formData.append('admin_designation','Admin');
            formData.append('deputy_designation',jobInfoById?.deputy_designation ? jobInfoById?.deputy_designation : '');
            
            formData.append('client_comment','');
            formData.append('editor_comment','');
            formData.append('admin_comment',adminMsg);
            formData.append('internal_comment','');
            formData.append('client_file','');
            formData.append('editor_file','');
            formData.append('admin_file',file);
            formData.append('internal_file','');

            const config = {     
              headers: { 'content-type': 'multipart/form-data' }
            }

            let apiRes = await axios.post(`${API_BASE_URL}comment`,formData,config) 

            if(apiRes)
            {
              setAdminMsg('');
              setFile('');
              setFileName('');
              formData.append('comment_id',apiRes.data.comment_id);
              formData.append('sender_id',loggedUser?.id);

              fetchAllComments(jobInfoById?.user_id,jobInfoById?.editor_id,jobInfoById?.id,jobInfoById?.internal_id);
              // console.log(apiRes.data.res)
              let apiRes1 = await axios.post(`${API_BASE_URL}comment/chatmail`,formData,config) 
              if(apiRes1.data.res == true) 
              {
                // setAdminMsg('');
                // setFile('');
                // setFileName('');
                fetchAllComments(jobInfoById?.user_id,jobInfoById?.editor_id,jobInfoById?.id,jobInfoById?.internal_id);
              }
              else
              {
                // NotificationManager.warning(apiRes.data.msg, '',2000);
              }
            }
        }
        catch (error) 
        {
          // NotificationManager.error(error.message, '',2000);
        }
      }
  }

  //fetch all comments Editor-Client
  const fetchAllComments = async(user_id,editor_id,job_id,internal_id) =>{
      try 
      {
        let intern_id= '';
        if(internal_id === null)
        {
          intern_id = '';
        }
        else
        {
          intern_id = internal_id;
        }
        // setLoading(true);
        // let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}`)
        let apiRes = await axios.get(`${API_BASE_URL}comment/commentdetailbyusereditor?user_id=${user_id}&editor_id=${editor_id}&admin_id=${5}&job_id=${job_id}&internal_id=${intern_id}&UserTimeZone=${timeZone}`)
          if(apiRes)
          { 
            console.log(apiRes.data.res)
            if(apiRes.data.res == true) 
            {
              setShowAllComments(apiRes.data.data);
            }
            else
            {
              // NotificationManager.warning(apiRes.data.msg, '',2000);
            }
          }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      }
  }

  //single file upload in comments
    const uploadFileHandle = (e) =>{
      if(
        e.currentTarget.files[0].type=='application/msword' ||
        e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||
        e.currentTarget.files[0].type== 'application/pdf' ||
        e.currentTarget.files[0].type== 'image/jpeg' ||
        e.currentTarget.files[0].type=='image/png'
        // e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.presentationml.presentation'||
      )
      {
        console.log(e.target.files[0].type);
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
      }
      else
      {
        NotificationManager.warning('File is not supported.Supported file types are DOC,DOCX,PDF,JPEG,PNG','',3000);
      }
      
  }
  
  //single file delete in comments
  const deleteFileHandle = () =>
    {    
      setFile('');
      setFileName('');
  }
  
  //Short User Name
  const shortUserName = (user_name) =>{
    console.log(user_name);
    var sl_name = user_name?.split(' ');
    console.log(sl_name)
    var short_first = sl_name[0]?.charAt(0);
    var short_last = sl_name[1]?.charAt(0);
    var short_final = short_first + short_last;
    return short_final;
}

  //get formatted end date
  const getFormattedEndDate =async(end_date) =>{
    var startDate= '';
    var endDate= '';
    setUseEndDate(end_date);
    startDate = getymdFormattedDate(useStartDate);
    endDate = getymdFormattedDate(end_date);
    if(startDate == '' || startDate == undefined || startDate ==null)
    {
      NotificationManager.warning('Select Start Date', '',1000);
    }
    else if(endDate == '' || endDate == undefined || endDate ==null)
    {
      NotificationManager.warning('Select End Date', '',1000);
    }
    else
    {
      // alert('Start Date:' + startDate); 
      // alert('End Date:' + endDate); 
      // Start Date:2023-04-12
      // End Date:2023-04-27
      // https://www.demoupdates.com/updates/elite/api/job/joblistByDate?startDate=2023-01-12&endDate=2023-09-12
      try
      {
        // setJobList
        let apiRes = await axios.get(`${API_BASE_URL}job/joblistByDate?startDate=${startDate}&endDate=${endDate}&job_status=${4}`)
        if(apiRes)
        {
          if(apiRes.data.res == true) 
          {
            setJobList(apiRes.data.data);
          }
          else
          {
            // NotificationManager.error(apiRes.data.msg, '',2000);
          }
        }
      }
      catch (error) 
      {
        // NotificationManager.error(error.message, '',2000);
      } 
    }
  }
  
  // get all editors
  const getAllEditor = async () => {
    try
    {
      let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=1`) 
      if (apiRes) 
      {
        if (apiRes.data.res == true) 
        {
          setShowEditorList(apiRes.data.data);
        }
        else
        {
          NotificationManager.error(apiRes.data.msg, "", 2000);
        }
      }
      else
      {
        throw new Error('Network request failed');
      }
    }
    catch (error)
    {
      NotificationManager.error(error.message, "", 2000);
    }
  };

  // get all internal-editors
  const getAllInternal = async () => {
      try {
        let apiRes = await axios.get(`${API_BASE_URL}account/editorlist?role=4`) 
        if (apiRes) 
        {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            console.log(apiRes.data.data);
            setShowInternalList(apiRes.data.data);
          }
        }
      }  //close try
      catch (error) {
        // NotificationManager.error(error, "", 2000);
      }
  };

  // Convert showInternalList to options format for React Select
  const intList = showInternalList.map((item) => ({
    value: item.id,
    label: `${item.first_name} ${item.last_name}`,
    email_address: item?.email_address
  }));

  // Handle item selection
  const handleChange = (selectedOption) => {
    if (selectedOption) {
      alert(`Selected: ${selectedOption.value}`);
    }
    setMenuIsOpen(false); // Optionally close the menu after selection
  };

  // Toggle the dropdown open/close
  // const toggleDropdown = () => {
  //   // setIsOpen(!isOpen);
  //   setIsAssigned(!isAssigned);
  // };

  //short file name
  const shortFileName=(item) =>{
    console.log(item?.slice(56,70));
    let filename = item?.slice(56,70);
    return filename +'...';
    // return item
  }
  
  // assign Editor 
  const assignEditor = async(editor_id) =>
  {
    // alert(editor_id)
    console.log('Editor id: ' + editor_id);
    console.log('Job id: ' + jobInfoById?.id);
    try 
    {
      setLoading(true);
      setShowDropDown(true);
      let apiRes = await axios.get(`${API_BASE_URL}job/inserteditor?id=${jobInfoById?.id}&editor_id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let apiRes1 = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
            if (apiRes1) 
            {
                console.log(apiRes1.data.data);
                if (apiRes1.data.res == true) 
                {
                  let fullName = '';
                  let email = '';
                  console.log(apiRes1.data.data[0]);
                  // setEditorEmail(apiRes1.data?.data[0]?.email_address);
                  fullName = apiRes1.data?.data[0]?.first_name + ' ' + apiRes1.data.data[0]?.last_name;
                  email = apiRes1.data?.data[0]?.email_address;
                  // console.log(fullName)
                  // alert(fullName)
                  console.log('Editor Id: ' + editor_id)
                  console.log('Editor Name: ' + fullName)
                  // getJobDetails(jobInfoById?.id)
                  // setShowEditorName(fullName)
                  let apiRes2 = await axios.get(`${API_BASE_URL}job/inserteditorname?id=${jobInfoById?.id}&editor_id=${editor_id}&editor_name=${fullName}&editor_email=${email}`)
                  if (apiRes2) 
                   {
                        console.log(apiRes2.data.res);
                        if (apiRes2.data.res == true) 
                        {
                          getJobDetails(jobInfoById?.id);
                          setShowDropDown(false);
                          setShowDropDown1(true);
                          NotificationManager.success('Job assigned successfully.', "", 2000);
                        }
                        else
                        {
                          
                          // NotificationManager.error('error', "", 2000);
                        }
                   }
                  setLoading(false);
                  setEditorEmail('');
                  // setShowEditorList('');
                }
                else
                {
                  setLoading(false);
                  // NotificationManager.error('error', "", 2000);
                }
            }
            // getAllJobs();
          }
      }
    }
    catch (error) 
    {
      // NotificationManager.error(error, "", 2000);
    }
  }
 
  // Editor Details 
  const editorDetailsById = async(editor_id) =>
  {
      let apiRes = await axios.get(`${API_BASE_URL}account?id=${editor_id}`)
      if (apiRes) 
      {
          console.log(apiRes.data.res);
          if (apiRes.data.res == true) 
          {
            let fullName='';
            console.log(apiRes.data.data[0])
            // setEditorEmail(apiRes.data?.data[0]?.first_name);
            setEditorEmail(apiRes.data?.data[0]?.first_name);
            fullName = apiRes.data?.data[0]?.first_name + ' ' + apiRes.data.data[0]?.last_name;
            console.log(fullName)
            alert(fullName)
            setShowEditorName(fullName)
            return fullName;
          }
          else
          {
            // NotificationManager.error('error', "", 2000);
          }
      }
  }

  //get job details by id
  const getJobDetails= async(job_id) =>{
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`)
      if (apiRes) {
        if (apiRes.data.res == true) 
        {
          let temArr = [];
          console.log(apiRes.data.data[0])
          setJobInfoById(apiRes.data.data[0]);
          if(apiRes.data.data[0].submit_file?.length > 0) {
            apiRes.data.data[0].submit_file.forEach(element => {
              temArr.push(element.submit_file)
            });
            setEditorAllSubmittedFiles(temArr);
          }
          setServiceFile(apiRes.data.data[0].service_filename);
          setInstructionFile(apiRes.data.data[0].instruction_filename);
          if(apiRes.data.data[0].job_type == 'type1')
          {
            setPrevEditorWC(apiRes.data.data[0]?.total_wordcount);
            setPrevEditorAmount(apiRes.data.data[0]?.total_price);
          }
          else if(apiRes.data.data[0].job_type == 'type2')
          {
            setPrevEditorWC(apiRes.data.data[0]?.number_of_pages);
            setPrevEditorAmount();
          }

          fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id,apiRes.data.data[0]?.internal_id);
        
        }
      }
    }catch (error)
    {
      console.error(error);
    }
  }

  //holding job list
  const holdingJobList = async() =>{
    try {
      let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=30&UserTimeZone=${timeZone}`)
      if (apiRes) {
        if (apiRes.data.res == true) {
          setJobList(apiRes.data.data);
          // setReviewerId(apiRes.data.data.reviewer)
          console.log(apiRes.data.data.reviewer)
        }else{
          console.log('Something went wrong!');
        }
      }
    }
    catch (error) {
      // NotificationManager.error(error, "", 2000);
    }
  }

  //holding job release one-by-one
  const handleSingleJobRelease = async(job_id) =>{
    let tempArr = [];
    tempArr.push(job_id);
    try
    {
      setLoading(true);
      let apiRes = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassign`,{
            job_id: tempArr,
            job_status: '32', // job status =>Returned and Client Status-Review
            holdjob_status:'32'
          },
        )
      )
      if(apiRes)
      {
          if(apiRes.data.res == true) 
          {
            NotificationManager.success('Job released successfully','',2000);
            setLoading(false);
            holdingJobList();

            try
            {
              let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`)
              if (apiRes) 
              {
                let apiRes1 = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassignMail`,{
                  job_id: tempArr,
                  job_status: '31', // job status =>ReAssigned and Client Status-Review
                  role: loggedUser?.role, //for admin
                  sender_id:loggedUser?.id,
                  receiver2_id: apiRes.data.data[0]?.editor_id!='' ?  apiRes.data.data[0]?.editor_id : apiRes.data.data[0]?.internal_id,
                  holdjob_status:'31',
                  type:'release',
                  release_by: loggedUser?.id,
                  resultString:''
                },
                ))
                if(apiRes1)
                {
                  if(apiRes1.data.res == true) 
                  {
                    // NotificationManager.success('Mail Send','',2000);
                  }
                }
              }
              else
              {
                  setLoading(false);
                  // NotificationManager.error(apiRes1.data.msg, '',2000);
              }
            }
            catch (error)
            {
                // NotificationManager.error(error, "", 2000);
            }
          }
          else
          {
              setLoading(false);
              // NotificationManager.error(apiRes.data.msg, '',2000);
          }
      }
      else
      {
        throw new Error('Network request failed');
      }
    }
    catch (error) 
    {
      NotificationManager.error(error.message, "", 2000);
    } 
  }

  const selectedReleaseJobFunc = (selected_id, isChecked) => {
    let temp_arr = [...selectedReleaseJob];
  
    if (isChecked) {
      if (!temp_arr.includes(selected_id)) {
        temp_arr.push(selected_id);
      }
    } else {
      temp_arr = temp_arr.filter(id => id !== selected_id);
    }
  
    setSelectedReleaseJob(temp_arr);
    // handleMultipleJobRelease();
  }
  
  //holding job release all-at-a-time
  const handleMultipleJobRelease = async() =>{
    // console.log('All jobs:' + selectedReleaseJob);
    // console.log('All jobs length:' + selectedReleaseJob?.length);
   
    if(selectedReleaseJob?.length > 0 && selectedReleaseJob!=undefined) {
      let i = 0;
      // console.log(selectedReleaseJob);
      // console.log(selectedReleaseJob?.length);
      // return
      try
      {
        let tempArr = [...selectedReleaseJob];
        let finalArr =[];

        tempArr.forEach(element => {
          // finalArr.push(element?.id)
          finalArr.push(element)
        });

        let resultString = finalArr.join(', ');
        
        setLoading(true);
        let apiRes = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassign`,{
          job_id: finalArr, // with multiple job id
          job_status: '32', // job status =>Returned and Client Status-Review
          holdjob_status:'32'
        },
        ))
        if(apiRes)
        {
            if(apiRes.data.res == true) 
            {
              NotificationManager.success('Released all Jobs','',2000);
              setLoading(false);
              holdingJobList();
            
              for(i=0;i<finalArr.length;i++)
              {
                try
                {
                  let apiRes1 = await axios.get(`${API_BASE_URL}job/jobdetail?id=${finalArr[i]}&UserTimeZone=${timeZone}`)
                  if (apiRes1) 
                  {
                    let apiRes2 = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassignMail`,{
                      job_id: finalArr,
                      job_status: '31', // job status =>ReAssigned and Client Status-Review
                      role: loggedUser?.role, //for admin
                      sender_id:loggedUser?.id,
                      receiver2_id: apiRes1.data.data[0]?.editor_id!='' ?  apiRes1.data.data[0]?.editor_id : apiRes1.data.data[0]?.internal_id,
                      holdjob_status:'31',
                      type:'release',
                      release_by: loggedUser?.id,
                      joballid: resultString
                    },
                    ))
                    if(apiRes2)
                    {
                      if(apiRes2.data.res == true) 
                      {
                        // NotificationManager.success('Mail Send','',2000);
                      }
                    }
                  }
                  else
                  {
                      setLoading(false);
                      // NotificationManager.error(apiRes1.data.msg, '',2000);
                  }
                }
                catch (error)
                {
                    // NotificationManager.error(error, "", 2000);
                }
              }
            }
            else
            {
                setLoading(false);
                NotificationManager.error(apiRes.data.msg, '',2000);
            }
        }
        else
        {
          throw new Error('Network request failed');
        }
      }
      catch (error) 
      {
        NotificationManager.error(error.message, "", 2000);
      }
    } else {
      // NotificationManager.warning('Please select a job to proceed with the release.');
      // NotificationManager.warning('To move forward with the release, please select a job.');
      NotificationManager.warning('Select the appropriate jobs to release.');
    }
  }

  //holding job release all-at-a-time
  // const handleMultipleJobRelease = async() =>{
  //   let i = 0;
  //   try
  //   {
  //     let tempArr = [...jobList];
  //     let finalArr =[];

  //     tempArr.forEach(element => {
  //       finalArr.push(element?.id)
  //     });

  //     let resultString = finalArr.join(', ');
      
  //     setLoading(true);
  //     let apiRes = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassign`,{
  //           job_id: finalArr, // with multiple job id
  //           job_status: '32', // job status =>Returned and Client Status-Review
  //           holdjob_status:'32'
  //         },
  //       )
  //     )
  //     if(apiRes)
  //     {
  //         if(apiRes.data.res == true) 
  //         {
  //           NotificationManager.success('Released all Jobs','',2000);
  //           setLoading(false);
  //           holdingJobList();
           
  //           for(i=0;i<finalArr.length;i++)
  //           {
  //             try
  //             {
  //               let apiRes1 = await axios.get(`${API_BASE_URL}job/jobdetail?id=${finalArr[i]}&UserTimeZone=${timeZone}`)
  //               if (apiRes1) 
  //               {
  //                 let apiRes2 = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassignMail`,{
  //                   job_id: finalArr,
  //                   job_status: '31', // job status =>ReAssigned and Client Status-Review
  //                   role: loggedUser?.role, //for admin
  //                   sender_id:loggedUser?.id,
  //                   receiver2_id: apiRes1.data.data[0]?.editor_id!='' ?  apiRes1.data.data[0]?.editor_id : apiRes1.data.data[0]?.internal_id,
  //                   holdjob_status:'31',
  //                   type:'release',
  //                   release_by: loggedUser?.id,
  //                   joballid: resultString
  //                 },
  //                 ))
  //                 if(apiRes2)
  //                 {
  //                   if(apiRes2.data.res == true) 
  //                   {
  //                     // NotificationManager.success('Mail Send','',2000);
  //                   }
  //                 }
  //               }
  //               else
  //               {
  //                   setLoading(false);
  //                   // NotificationManager.error(apiRes1.data.msg, '',2000);
  //               }
  //             }
  //             catch (error)
  //             {
  //                 // NotificationManager.error(error, "", 2000);
  //             }
  //           }
  //         }
  //         else
  //         {
  //             setLoading(false);
  //             NotificationManager.error(apiRes.data.msg, '',2000);
  //         }
  //     }
  //     else
  //     {
  //       throw new Error('Network request failed');
  //     }
  //   }
  //   catch (error) 
  //   {
  //     NotificationManager.error(error.message, "", 2000);
  //   } 
  // }

  const handleReplaceFile = async(e,id,job_id) =>{
    const formData = new FormData();
    formData.append('editor_submit_file[]', e.target.files[0]);
    formData.append('id', id);
    
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    let apiRes = await axios.post(`${API_BASE_URL}job/jobhold_update`, formData, config)
    if (apiRes) 
    {
      if (apiRes.data.res == true) {
        NotificationManager.success('File replaced successfully.','',2000);
        getJobDetails(job_id);
        holdingJobList();
      }
      else{
        NotificationManager.error('File is not replaced successfully.','',2000);
        getJobDetails(job_id);
      }
    }
  }

  //ReAssign the job to Editor
  const handleResignToEditor = async(job_id) => {
    let tempArr = [];
    tempArr.push(job_id);
    try
    {
      setLoading(true);
      let apiRes = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassign`,{
        job_id: tempArr,
        job_status: '31', // job status =>ReAssigned and Client Status-Review
        holdjob_status:'31',
      }))

      if(apiRes)
      {
        if(apiRes.data.res == true) 
        {
          NotificationManager.success('Job ReAssigned to Editor','',2000);
          setLoading(false);
          holdingJobList();
          try
          {
            let apiRes1 = await axios.get(`${API_BASE_URL}job/jobdetail?id=${job_id}&UserTimeZone=${timeZone}`)
            if (apiRes1) 
            {
              let apiRes2 = await(axios.post(`${API_BASE_URL}job/jobReleaseOrReassignMail`,{
                job_id: tempArr,
                job_status: '31', // job status =>ReAssigned and Client Status-Review
                role: loggedUser?.role, 
                sender_id:loggedUser?.id,
                receiver2_id: apiRes1.data.data[0]?.editor_id!='' ?  apiRes1.data.data[0]?.editor_id : apiRes1.data.data[0]?.internal_id,
                holdjob_status:'31',
                type:'reassign',
                reassign_by: loggedUser?.id
              },
              ))
              if(apiRes2)
              {
                if(apiRes2.data.res == true) 
                {
                  // NotificationManager.success('Mail Send','',2000);
                }
              }
            }
            else
            {
                setLoading(false);
                NotificationManager.error(apiRes.data.msg, '',2000);
            }
          }
          catch (error)
          {
              // NotificationManager.error(error, "", 2000);
          }
        }
        else
        {
          setLoading(false);
          NotificationManager.error(apiRes.data.msg, '',2000);
        } 
      }
      else
      {
        throw new Error('Network request failed');
      }
    }
    catch (error) 
    {
      NotificationManager.error(error.message, "", 2000);
    } 
  }

  const selfAssignedReview = async(job_id,review_option) => {
    try{
      handleAssignReviewer(loggedUser?.id,job_id,'Assign','self');
      handleReview(job_id,review_option,'self')
    }catch(error){
      console.error(error);
    }
  }
  
  const selfUnAssignedReview = async(job_id,review_option) => {
    try{
      handleAssignReviewer(loggedUser?.id,job_id,'UnAssign','self_unassign');
      handleReview(job_id,review_option,'self_unassign')
    }catch(error){
      console.error(error);
    }
  }

  // Assign Reviewer
  const handleAssignReviewer = (internal_id,job_id,type,self_type) => {
    if(type == 'Assign') {
      setReviewerId(internal_id);
      handleJobReviewer(job_id,internal_id,type,self_type);
    }else if(type == 'UnAssign') {
      setReviewerId();
      handleJobReviewer(job_id,internal_id,type,self_type);
    }else{
      console.error('Error in type: ' + type);
    }
  }

  // Job reviewer
  const handleJobReviewer = async(job_id,internal_id,type,self_type) => {
    try
    {
      if(type == 'Assign') {
        setLoading(true);
        let apiRes = await(axios.post(`${API_BASE_URL}job/JobReviewerUpdate`,{
            job_id: job_id,
            reviewer: internal_id,
            type: 'assign',
            SenderId: loggedUser?.id
          },
        ))
        if(apiRes)
        {
          if(apiRes.data.res == true) 
          {
            if(self_type == 'self') {
              
            } else {
              NotificationManager.success('Reviewer Added.' ,'',2000);
            }
            
            setLoading(false);
            holdingJobList();
          }
          else
          {
            setLoading(false);
            NotificationManager.error(apiRes.data.msg, '',2000);
          } 
        }
        else
        {
          throw new Error('Network request failed');
        }
      }else if(type == 'UnAssign') {
        setLoading(true);
        let apiRes = await(axios.post(`${API_BASE_URL}job/JobReviewerUpdate`,{
            job_id: job_id,
            reviewer: internal_id,
            type: 'unassign',
            SenderId: loggedUser?.id
          },
        ))
        if(apiRes)
        {
          if(apiRes.data.res == true) 
          {
            if(self_type == 'self') {

            }else{
              NotificationManager.success('Reviewer Removed.' ,'',2000);
            }
            setLoading(false);
            holdingJobList();
          }
          else
          {
            setLoading(false);
            NotificationManager.error(apiRes.data.msg, '',2000);
          } 
        }
        else
        {
          throw new Error('Network request failed');
        }
  
      }else{
        console.log('type error: ' + type);
      }
    }
    catch (error) 
    {
      NotificationManager.error(error.message, "", 2000);
    } 
  }

  const handleReview = async(job_id,review_option,type) => {
    let payload_data = {
      id: job_id,
      reviewer_accept: review_option
    }
    let apiRes = await axios.post(`${API_BASE_URL}job/update_reviewer_accept`, payload_data)
    if (apiRes) 
    {
      if (apiRes.data.res == true) {
        if(type == 'self') {
          NotificationManager.success('Accepted to review the job','',2000);
        }else{
          // NotificationManager.error('Declined to review the job','',2000);
        }
        holdingJobList();
      }
      else{
        NotificationManager.error('Declined to review the job','',2000);
        holdingJobList();
      }
    }else{
      console.error('Something went wrong!');
    }
  }

  //Who is assign
  const handleAssign = async (user_type) => {
    setUserAssignType(user_type);
  }

  //Partial Payment
  const handleReAssignPayment = async(job_id,user_type,prev_user_id,reassign_by) => {
    try
    {
      let apiRes = await(
        axios.post(`${API_BASE_URL}service/addReassign `,{
          job_id: job_id,
          user_type: user_type,
          prev_user_id: prev_user_id,
          prev_user_wc: prevEditorWC,
          prev_user_amount: prevEditorAmount,
          current_user_id: user_type =='1' ? selectedEditorId : selectedInternalEditorId,
          current_user_wc: currentEditorWC,
          current_user_amount: parseFloat(currentEditorAmount).toFixed(2),
          sender_id: reassign_by
        },
      ))
      return
      if(apiRes)
      {
        if(apiRes.data.res == true) 
        {
            NotificationManager.success('ReAssigned Successfully','',2000);
            // setLoading(false);
            partialModalclose();
        }
        else
        {
            // setLoading(false);
            NotificationManager.error(apiRes.data.msg, '',2000);
        }
      }
      else
      {
        throw new Error('Network request failed');
      }
    }
    catch (error) 
    {
      NotificationManager.error(error.message, "", 2000);
    }
  }

  const countUpTimer = (datetime) => {
    let total_ms = 0;
  
    if (datetime !== "0") {
      const split_days = datetime?.split(",");
      if (split_days?.length === 4) {
        const sp_days = parseInt(split_days[0]);
        const sp_hours = parseInt(split_days[1]);
        const sp_minutes = parseInt(split_days[2]);
        const sp_seconds = parseInt(split_days[3]);
  
        const DAYS_IN_MS = sp_days * 24 * 60 * 60 * 1000;
        const HOURS_IN_MS = sp_hours * 60 * 60 * 1000;
        const MINUTES_IN_MS = sp_minutes * 60 * 1000;
        const SECONDS_IN_MS = sp_seconds * 1000;
  
        total_ms = DAYS_IN_MS + HOURS_IN_MS + MINUTES_IN_MS + SECONDS_IN_MS;
      }
    }
  
    return <CountUpTimerComponent MS={total_ms} />;
  };

  useEffect(()=>{
    // handleJobReviewer();
  },[])

  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    { label: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    { label: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { label: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
    { label: 'Forrest Gump', year: 1994 },
    { label: 'Inception', year: 2010 },
    { label: 'The Lord of the Rings: The Two Towers', year: 2002 },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: 'Goodfellas', year: 1990 },
    { label: 'The Matrix', year: 1999 },
    { label: 'Seven Samurai', year: 1954 },
    { label: 'Star Wars: Episode IV - A New Hope', year: 1977 },
    { label: 'City of God', year: 2002 },
    { label: 'Se7en', year: 1995 },
    { label: 'The Silence of the Lambs', year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: 'Life Is Beautiful', year: 1997 },
    { label: 'The Usual Suspects', year: 1995 },
    { label: 'Léon: The Professional', year: 1994 },
    { label: 'Spirited Away', year: 2001 },
    { label: 'Saving Private Ryan', year: 1998 },
    { label: 'Once Upon a Time in the West', year: 1968 },
    { label: 'American History X', year: 1998 },
    { label: 'Interstellar', year: 2014 },
    { label: 'Casablanca', year: 1942 },
    { label: 'City Lights', year: 1931 },
    { label: 'Psycho', year: 1960 },
    { label: 'The Green Mile', year: 1999 },
    { label: 'The Intouchables', year: 2011 },
    { label: 'Modern Times', year: 1936 },
    { label: 'Raiders of the Lost Ark', year: 1981 },
    { label: 'Rear Window', year: 1954 },
    { label: 'The Pianist', year: 2002 },
    { label: 'The Departed', year: 2006 },
    { label: 'Terminator 2: Judgment Day', year: 1991 },
    { label: 'Back to the Future', year: 1985 },
    { label: 'Whiplash', year: 2014 },
    { label: 'Gladiator', year: 2000 },
    { label: 'Memento', year: 2000 },
    { label: 'The Prestige', year: 2006 },
    { label: 'The Lion King', year: 1994 },
    { label: 'Apocalypse Now', year: 1979 },
    { label: 'Alien', year: 1979 },
    { label: 'Sunset Boulevard', year: 1950 },
    { label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
    { label: 'The Great Dictator', year: 1940 },
    { label: 'Cinema Paradiso', year: 1988 },
    { label: 'The Lives of Others', year: 2006 },
    { label: 'Grave of the Fireflies', year: 1988 }, 
    { label: 'Paths of Glory', year: 1957 },
    { label: 'Django Unchained', year: 2012 },
    { label: 'The Shining', year: 1980 },
    { label: 'WALL·E', year: 2008 },
    { label: 'American Beauty', year: 1999 },
    { label: 'The Dark Knight Rises', year: 2012 },
    { label: 'Princess Mononoke', year: 1997 },
    { label: 'Aliens', year: 1986 },
    { label: 'Oldboy', year: 2003 },
    { label: 'Once Upon a Time in America', year: 1984 },
    { label: 'Witness for the Prosecution', year: 1957 },
    { label: 'Das Boot', year: 1981 },
    { label: 'Citizen Kane', year: 1941 },
    { label: 'North by Northwest', year: 1959 },
    { label: 'Vertigo', year: 1958 },
    { label: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
    { label: 'Reservoir Dogs', year: 1992 },
    { label: 'Braveheart', year: 1995 },
    { label: 'M', year: 1931 },
    { label: 'Requiem for a Dream', year: 2000 },
    { label: 'Amélie', year: 2001 },
    { label: 'A Clockwork Orange', year: 1971 },
    { label: 'Like Stars on Earth', year: 2007 },
    { label: 'Taxi Driver', year: 1976 },
    { label: 'Lawrence of Arabia', year: 1962 },
    { label: 'Double Indemnity', year: 1944 },
    { label: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
    { label: 'Amadeus', year: 1984 },
    { label: 'To Kill a Mockingbird', year: 1962 },
    { label: 'Toy Story 3', year: 2010 },
    { label: 'Logan', year: 2017 },
    { label: 'Full Metal Jacket', year: 1987 },
    { label: 'Dangal', year: 2016 },
    { label: 'The Sting', year: 1973 },
    { label: '2001: A Space Odyssey', year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: 'Toy Story', year: 1995 },
    { label: 'Bicycle Thieves', year: 1948 },
    { label: 'The Kid', year: 1921 },
    { label: 'Inglourious Basterds', year: 2009 },
    { label: 'Snatch', year: 2000 },
    { label: '3 Idiots', year: 2009 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
  ];



  return (
    <div>
      <InternalEditorHeader unreadMsg={notificationMsg}/>
      <InternalEditorSidebar/>
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2 style={{marginRight: '20px'}}>Returned Jobs</h2>
            {jobList?.length > 0 && (
              <Button className="CmnBtn" type="submit" name='allrelease' onClick={()=>handleMultipleJobRelease()}>Release Selected Jobs</Button>
            )}
          </span>
        </div>
        <div className="date-range-sec">
          <div className="chose-date-sec"><DatePicker selected={startDate} onChange={(date) =>{getFormattedStartDate(date);setStartDate(date)}} dateFormat="P" /></div>
          <div className="chose-date-sec"><DatePicker selected={endDate} onChange={(date) => {getFormattedEndDate(date);setEndDate(date)}} dateFormat="P" /></div>
        </div>
        <br className="clearfix" />
        <section className="section OdrDtlsPnl">
          <div className="complete-table-sec HoldingJobTableSec">
            <table width="100%" className="CmnTbl OrdersTable table-striped blue-header-table CmnTableFontSize HoldingJobTable">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th>Task Name</th>
                  <th>WC/Pages</th> 
                  <th>Amount</th>  
                  <th>Due Date</th>  
                  <th>Client Timer</th>
                  <th>Editor</th>
                  <th>Client</th>
                  <th>Payment</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Reviewer</th>
                  {jobList?.length > 0 ? (
                    <th>&nbsp;</th>
                  ):(
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>

              {jobList?.length > 0 ? (
                jobList?.map((item,index)=>{
                  return(
                  <>
                    {(item?.job_type==='type2')?(
                      <tr>
                        <td>{item?.id}</td>
                        <td>
                          <p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetails(item?.id)}}>
                            {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ?  'Regular Service  | 1 Week ' : 'Expedited Service'}
                          </p>
                        </td>
                        <td>{item.number_of_pages}</td>
                        {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                        <td>${parseFloat(Number(item.editor_cv_price)).toFixed(2)}</td>
                        <td>{get_date_format(item?.due_date)}</td>
                        {/* <td style={{textAlign: "center"}}>
                          <div className="count-down-timer">
                            {item?.date75 == 0 ?(
                              <h6 style={{color: 'red'}}>{timeReachedMessage_editor(item?.id)}</h6>
                            ):(
                              <>
                                {remainingTimeCount(item?.date75)}
                              </>
                            )}
                          </div>
                        </td> */}

                        <td>
                          <div className="count-down-timer">
                            {item?.differencetime == 0 ? (
                              <div class="count-down-timer count-down-timer-stop">
                                <div class="show-counter">
                                  {countUpTimer(item?.date75_new)}
                                </div>
                              </div>
                            ):(
                              <>
                                {remainingTimeCount(item?.differencetime)}
                              </>
                            )}
                          </div>
                        </td>
                        {/* <td>
                          <div className="count-down-timer">{remainingTimeCount(item?.differencetime)}</div>
                        </td> */}
                        <td style={{whiteSpace: 'nowrap'}}>
                          {
                            item?.job_status == '30'? (<Button className="StatusTxt returned" type="submit">Returned</Button>):''
                          }
                        </td>
                        <td>
                          {/* {
                            (item?.editor_name!=null)? item?.editor_name + ' ' + '(' + item?.editor_designation + ')' :
                            (item?.internal_name!=null)? item?.internal_name + ' ' + '(' + item?.internal_designation + ')' : '--'
                            
                          } */}
                                                    {
                            (item?.editor_name!=null)? item?.editor_name :
                            (item?.internal_name!=null)? item?.internal_name : '--'
                          }
                        </td>
                        <td>{item?.client_name}</td>
                        {/* <td>{item?.payment===''?'---':'---'}</td> */}
                        {/* <td>${parseFloat(Number(item.cv_price)).toFixed(2)}</td> */}
                        <td>${parseFloat(item.GrandTotal).toFixed(2)}</td>
                        {/* <td>{projectStatus(item?.differencetime)}</td> */}
                        {/* <td>{projectStatus(item?.differencetime)}</td> */}
                        {/* <td>{projectStatus(item?.date75)}</td> */}

                        <td style={{whiteSpace: 'nowrap'}}>
                          {/* {
                            item?.job_status == '30'? (<Button className="StatusTxt returned" type="submit">Returned</Button>):''
                          } */}
                          {
                            item?.job_status == '30' && item?.download_status == 0 ? (<Button className="StatusTxt returned" type="submit">Returned</Button>):
                            item?.job_status == '30' && item?.download_status == 1 ? (<Button className="StatusTxt returned" type="submit">Reviewed</Button>):''
                          }
                        </td>
                        {/* <td> */}
                        <td>
                          <Button className="CmnBtn" type="submit" name='release' onClick={()=>handleSingleJobRelease(item?.id)}>Release</Button> 
                          {/* <Button className="CmnBtn" type="submit" onClick={()=>toggleMenu()}>Assign</Button> */}
                          
                          {/* reviewerId!= undefined || */}

                          
                            {/* <Button className="CmnBtn" type="submit" disabled>Assign</Button> */}
                          
                          {(item?.reviewer!=undefined || item?.reviewer!=null) ? (
                            item?.reviewer_accept == '0' ? (
                              <>
                                {/* <Button className="CmnBtn" type="submit" style={{cursor:'not-allowed'}}>Unassign1</Button> */}
                                <Button className="accept-job-link" type="submit" onClick={()=>handleReview(item?.id,1)}>Accept</Button>
                                <Button className="accept-job-link" type="submit" onClick={()=>selfUnAssignedReview(item?.id,0)}>Reject</Button>
                              </>
                            ):
                            item?.reviewer_accept == '1' ? (
                              <>
                                {/* <Button className="CmnBtn" type="submit" style={{cursor:'not-allowed'}}>Unassign2</Button> */}
                                <Button className="accept-job-link reject-disabled-btn" type="submit" disabled>Accept</Button>
                                {/* <Button className="accept-job-link reject-disabled-btn" type="submit" disabled>Reject</Button> */}
                                <Button className="accept-job-link" type="submit" onClick={()=>selfUnAssignedReview(item?.id,0)}>Reject</Button>
                              </>
                            ):
                            item?.reviewer_accept == '2' ? (
                             <Button className="accept-job-link" type="submit" onClick={()=>handleReview(item?.id,1)}>Accept</Button>
                            // <Button className="accept-job-link" type="submit" onClick={()=>handleReview(item?.id,2)}>Reject</Button>
                            ):null
                          ):(
                            <>
                              {/* <Button className="CmnBtn" type="submit" style={{cursor:'not-allowed'}}>Assign3</Button> */}
                              <Button className="accept-job-link" type="submit" onClick={()=>selfAssignedReview(item?.id,1)}>Accept</Button>
                              <Button className="accept-job-link reject-disabled-btn" type="submit" disabled>Reject</Button>
                            </>
                          )}
                          
                        </td>
                        {/* </td> */}
                      </tr>
                    ):(
                      <tr>
                        <td>{item?.id}</td>
                        <td><p className="ListItem yet-to-asign-list" onClick={()=>{openJobDetlsPnl();getJobDetails(item?.id)}}>
                         {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'} |  {item?.service_hour} hrs</p></td>
                        {/* <td>{item?.from_count}-{item?.to_count}</td> */}
                        <td>{item.total_wordcount}</td>
                        {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                        <td>${parseFloat(Number(item.editor_reg_price)).toFixed(2)}</td>
                        <td>
                          <span className="GreenTxt">{get_date_format(item?.due_date)}</span>
                        </td>
                        {/* <td style={{textAlign: "center"}}>
                          <div className="count-down-timer">
                            {item?.date75 == 0 ?(
                              <h6 style={{color: 'red'}}>{timeReachedMessage_editor(item?.id)}</h6>
                            ):(
                              <>
                                {remainingTimeCount(item?.date75)}
                              </>
                            )}
                          </div>
                        </td> */}

                        <td>
                          <div className="count-down-timer">
                            {item?.differencetime == 0 ?(
                              <div class="count-down-timer count-down-timer-stop">
                                <div class="show-counter">
                                  {countUpTimer(item?.differencetime_new)}
                                </div>
                              </div>
                            ):(
                              <>
                                {remainingTimeCount(item?.differencetime)}
                              </>
                            )}
                          </div>
                        </td>
                        {/* <td><div className="count-down-timer">{remainingTimeCount(item?.differencetime)}</div></td> */}
                        
                        {/* <td>{item?.editor_name}</td> */}
                        <td>
                          {/* {
                            (item?.editor_name!=null)? item?.editor_name + ' ' + '(' + item?.editor_designation + ')' :
                            (item?.internal_name!=null)? item?.internal_name + ' ' + '(' + item?.internal_designation + ')' : '--'
                          } */}
                                                    {
                            (item?.editor_name!=null)? item?.editor_name :
                            (item?.internal_name!=null)? item?.internal_name : '--'
                          }
                        </td>
                        <td>{item?.client_name}</td>
                        {/* <td>{item?.payment===''?'---':'---'}</td> */}
                        {/* <td>${parseFloat(Number(item.total_price)).toFixed(2)}</td> */}
                        <td>${parseFloat(Number(item.GrandTotal)).toFixed(2)}</td>
                        <td style={{whiteSpace: 'nowrap'}}>
                          {/* {
                            item?.job_status == '30'? (<Button className="StatusTxt returned" type="submit">Returned</Button>):''
                          } */}
                          {
                            item?.job_status == '30' && item?.download_status == 0 ? (<Button className="StatusTxt returned" type="submit">Returned</Button>):
                            item?.job_status == '30' && item?.download_status == 1 ? (<Button className="StatusTxt returned" type="submit">Reviewed</Button>):''
                          }
                        </td>
                        {/* <td> */}
                        <td>
                          <Button className="CmnBtn" type="submit" name='release' onClick={()=>handleSingleJobRelease(item?.id)}>Release</Button> 
                          {/* <Button className="CmnBtn" type="submit" onClick={()=>toggleMenu()}>Assign</Button> */}
                          
                          {/* reviewerId!= undefined || */}

                          
                            {/* <Button className="CmnBtn" type="submit" disabled>Assign</Button> */}
                          
                          {(item?.reviewer!=undefined || item?.reviewer!=null) ? (
                            item?.reviewer_accept == '0' ? (
                              <>
                                {/* <Button className="CmnBtn" type="submit" style={{cursor:'not-allowed'}}>Unassign1</Button> */}
                                <Button className="accept-job-link" type="submit" onClick={()=>handleReview(item?.id,1)}>Accept</Button>
                                <Button className="accept-job-link" type="submit" onClick={()=>selfUnAssignedReview(item?.id,0)}>Reject</Button>
                              </>
                            ):
                            item?.reviewer_accept == '1' ? (
                              <>
                                {/* <Button className="CmnBtn" type="submit" style={{cursor:'not-allowed'}}>Unassign2</Button> */}
                                <Button className="accept-job-link reject-disabled-btn" type="submit" disabled>Accept</Button>
                                {/* <Button className="accept-job-link reject-disabled-btn" type="submit" disabled>Reject</Button> */}
                                <Button className="accept-job-link" type="submit" onClick={()=>selfUnAssignedReview(item?.id,0)}>Reject</Button>
                              </>
                            ):
                            item?.reviewer_accept == '2' ? (
                             <Button className="accept-job-link" type="submit" onClick={()=>handleReview(item?.id,1)}>Accept</Button>
                            // <Button className="accept-job-link" type="submit" onClick={()=>handleReview(item?.id,2)}>Reject</Button>
                            ):null
                          ):(
                            <>
                              {/* <Button className="CmnBtn" type="submit" style={{cursor:'not-allowed'}}>Assign3</Button> */}
                              <Button className="accept-job-link" type="submit" onClick={()=>selfAssignedReview(item?.id,1)}>Accept</Button>
                              <Button className="accept-job-link reject-disabled-btn" type="submit" disabled>Reject</Button>
                            </>
                          )}
                          
                        </td>
                        {/* </td> */}
                        <td>
                          <Form.Group>
                            <Form.Select disabled style={{cursor:'not-allowed'}} name = "subservice" ref={selectRef} onChange={(e)=>handleAssignReviewer(e.target.value,item?.id,'Assign')}>
                              <option selected hidden>Reviewers</option>
                              {showInternalList?.map((item1,index1)=>{
                                return(
                                  <option disabled selected={item?.reviewer!=null ? true : false} key={index1} value={item1?.id}>{item1?.first_name} {item1?.last_name}</option>
                                )
                              })}
                            </Form.Select>
                          </Form.Group>

                          {/* <Select
                            ref={customSelectRef}
                            options={intList}
                            placeholder="Reviewers"
                            isSearchable={false} // disable typing/searching
                            menuIsOpen={menuIsOpen} // control the menu state with state
                            onMenuOpen={() => setMenuIsOpen(true)} // ensure menu opens on dropdown click
                            onMenuClose={() => setMenuIsOpen(false)} // close the menu when user clicks away
                            // onChange={() => setMenuIsOpen(false)} // optionally close the menu on selection
                            onChange={handleChange}
                            onFocus={() => setMenuIsOpen(true)} // open the menu when the dropdown is focused
                          /> */}

                          {/* <div className='dropdownStyles' ref={customSelectRef} onFocus={() => setMenuIsOpen(true)}>
                            <button className='buttonStyles form-select' onClick={handleToggle}>
                              {selectedOption ? selectedOption.label : '--Select Reviewers--'}
                            </button>
                            {isOpen && (
                              <ul className='menuStyles'>
                                {showInternalList?.map((option) => (
                                  <li
                                    key={option.value}
                                    className='menuItemStyles'
                                    // onMouseEnter={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                                    // onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                                    onClick={() => handleSelect(option)}
                                  >
                                    {option?.first_name} {option?.last_name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div> */}
                        </td>
                        <td>
                          {/* <div class="form-check"><input name="choose-editor" onClick={()=>handleSingleJobRelease(item?.id)} type="checkbox" class="form-check-input" value="bycode" /></div> */}
                          <div class="form-check"><input name="choose-editor"  onClick={(e) => selectedReleaseJobFunc(item?.id, e.target.checked)} type="checkbox" class="form-check-input" value="bycode" /></div>
                        </td>
                      </tr>
                    )}
                  </>
                  )
                })
              ):(
                <>
                  <tr>
                    <td className='no-data' colSpan={13} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                  </tr>
                </>
              )}

                {/* <tr>
                  <td className='no-data' colSpan={13} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                </tr> */}
                {/* <tr> */}
                  {/* <td>#823</td>
                  <td>#823 | CORPORATION | Report | Word Count 372 | Regular Service | 24hrs</td>
                  <td>5,005</td>
                  <td><span class="GreenTxt">Mar 02, 2024</span></td>
                  <td>00 12 45 08</td>
                  <td>Abel johnson</td>
                  <td>Jam esZa chary</td>
                  <td>$1,00.00</td>
                  <td><Button className="StatusTxt returned" type="submit">Returned</Button></td>
                  <td><Button className="CmnBtn" type="submit">Release</Button> <Button className="CmnBtn" type="submit">Assign</Button> <Button className="accept-job-link" type="submit">Accept</Button></td> */}
                  {/* <td>Harry</td> */}
                  {/* <td> */}
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={top100Films}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Select Reviewer" />}
                    /> */}
                    {/* <select style={{width:'100%'}}> */}
                      {/* <option>--Select Reviewer--</option> */}
                      {/* {top100Films?.length > 0 ? (
                        top100Films?.map((item,index)=>{
                          return(
                            <option>{item?.label}</option>
                          )
                        })
                      ):(
                        <option>No Internal Editor Found</option>
                      )} */}
                      {/* <option>100</option>
                      <option>200</option>
                      <option>300</option> */}
                    {/* </select> */}
                  {/* </td> */}
                  {/* <td>
                    <div class="form-check"><input name="choose-editor" type="checkbox" class="form-check-input" value="bycode" checked="" /></div>
                  </td> */}

                {/* </tr> */}

                
                {/* <tr>
                  <td>#823</td>
                  <td>#823 | CORPORATION | Report | Word Count 372 | Regular Service | 24hrs</td>
                  <td>5,005</td>
                  <td><span class="GreenTxt">Mar 02, 2024</span></td>
                  <td>00 12 45 08</td>
                  <td>Abel johnson</td>
                  <td>Jam esZa chary</td>
                  <td>$1,00.00</td>
                  <td><Button className="StatusTxt returned" type="submit">Returned</Button></td>
                  <td><Button className="CmnBtn" type="submit">Release</Button> <Button className="CmnBtn" type="submit">Unassign</Button> <Button className="accept-job-link" type="submit">Reject</Button></td>
                  <td>Harry</td>
                  <td>
                    <div class="form-check"><input name="choose-editor" type="checkbox" class="form-check-input" value="bycode" checked="" /></div>
                  </td>
                </tr> */}

                {/* <tr>
                  <td className='no-data' colSpan={11} style={{background: "none", padding: "0"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                </tr> */}
                
              </tbody>
            </table>
          </div>
        </section>
        
         {/* left Job Details Pannel */}
         <div className="DtlsSectionWrapper OngoingPnl openJobDetlsPnl" ref={windowRef}>
          <div className={`TaskDtlsPnl ${jobInfoById?.job_status == '30' ? 'textarea-sec':'no-textarea-sec'}`}>
            <div className="HdrTop">
              {/* <a onClick={partialModalShow} className="CmnBtn MarkCompleteBtn"> */}
                {/* <i className="bi bi-check-circle" /> Partial Payment  */}
                {/* <i className="bi bi-check-circle" /> Assign */}
              {/* </a> */}
              <span className="Rht">
                <a onClick={()=>{closeJobDetlsPnl();setShowDropDown(false)}} className="CloseBtn">
                  <i className="bi bi-x" />
                </a>
              </span>
            </div>

            <div className="MiddleBody">
              {(jobInfoById?.job_type==='type2')?(
                <>
                  <h2>
                    {jobInfoById?.id} | {jobInfoById?.service_name} | Number of Pages {jobInfoById?.number_of_pages} | {jobInfoById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                  </h2>
                  <table className="DtlsDescrpTbl">
                    <tbody>
                      <tr>
                        <td>Editor</td>
                        <td>
                          <span className="name-span">
                            {jobInfoById?.editor_name!= null ?(
                              <>
                                {jobInfoById?.editor_name}
                              </>
                            ):(
                              <>
                              {jobInfoById?.internal_name!= null ?(
                                <>
                                  {jobInfoById?.internal_name}
                                </>
                              ):(
                                <></>
                              )}
                              </>
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Editor Submitted Files</td>
                          <td>
                            <ul className="uploadList">
                            {jobInfoById?.submit_file.length > 0 ? (
                              jobInfoById?.submit_file.map((item,index)=>{
                                return(
                                  <li>
                                    <input type="text" name="" value={item?.submit_file?.substring(56)} className='form-control' /> 
                                    <a className='DownloadBtn' onClick={()=>downloadEditorFile(jobInfoById?.id,item?.submit_file,jobInfoById?.download_status)}></a>
                                    <div className='UploadSec'>
                                      <input type='button' name="" value="Replace" className='btn' />
                                      <input type='file' name="" className="InputFile" onChange={(e)=>handleReplaceFile(e,item.id,item.job_id)}/>
                                    </div>
                                  </li>
                                )
                              })
                            ):(
                              <></>
                            )}
                            </ul>
                          </td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>
                          <span className="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction</td>
                        {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} /> */}
                        {jobInfoById?.client_instruction!='null' ? (
                          <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} />
                        ):(
                          <td>No instruction included.</td>
                        )}
                      </tr>
                      <tr>
                        <td>Services:</td>
                        <td>{jobInfoById?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Service Type:</td>
                        <td>{jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                      </tr>
                      <tr>
                        <td>Number of Pages: </td>
                        <td>{jobInfoById?.number_of_pages}</td>
                      </tr>

                      <tr>
                        <td>Price: </td>
                        {/* <td>${parseFloat(jobInfoById?.cv_price).toFixed(2)}</td> */}
                        <td>${parseFloat(jobInfoById?.GrandTotal).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Service File:</td>
                        <td>
                              {/* {
                                jobInfoById?.service_filename.endsWith('.doc') || 
                                jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                              } */}
                              {serviceFile && serviceFile.length > 0 && serviceFile.map((item,index)=>{
                                  return(
                                    item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                  )
                                    // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction File:</td>
                        <td><br/>
                          {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                              return(
                                item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                              )
                                    // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ):
              (
                <>
                  <h2>
                    {jobInfoById?.id} | {jobInfoById?.service_name} | {jobInfoById?.sub_service} | Word Count {jobInfoById?.total_wordcount} | {jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {jobInfoById?.service_hour} hrs
                  </h2>
                  <table className="DtlsDescrpTbl">
                    <tbody>
                      <tr>
                        <td>Editor</td>
                        <td>
                          <span className="name-span">
                            {jobInfoById?.editor_name!= null ?(
                              <>
                                {jobInfoById?.editor_name}
                              </>
                            ):(
                              <>
                              {jobInfoById?.internal_name!= null ?(
                                <>
                                  {jobInfoById?.internal_name}
                                </>
                              ):(
                                <></>
                              )}
                              </>
                            )}
                            
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Editor Submitted Files</td>
                          <td>
                            <ul className="uploadList">
                            {jobInfoById?.submit_file.length > 0 ? (
                              jobInfoById?.submit_file.map((item,index)=>{
                                return(
                                  <li>
                                    <input type="text" name="" value={item?.submit_file?.substring(56)} className='form-control' /> 
                                    <a className='DownloadBtn' onClick={()=>downloadEditorFile(jobInfoById?.id,item?.submit_file,jobInfoById?.download_status)}></a>
                                    <div className='UploadSec'>
                                      <input type='button' name="" value="Replace" className='btn' />
                                      <input type='file' name="" className="InputFile" onChange={(e)=>handleReplaceFile(e,item.id,item.job_id)}/>
                                    </div>
                                  </li>
                                )
                              })
                            ):(
                              <></>
                            )}
                            </ul>
                          </td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>
                          <span className="GreenTxt">{get_date_format(jobInfoById?.due_date)}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction</td>
                        {/* <td dangerouslySetInnerHTML={{__html: jobInfoById?.full_description}} /> */}
                        {jobInfoById?.client_instruction!='null' ? (
                          <td dangerouslySetInnerHTML={{__html: jobInfoById?.client_instruction}} />
                        ):(
                          <td>No instruction included.</td>
                        )}
                      </tr>
                      <tr>
                        <td>Services:</td>
                        <td>{jobInfoById?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Options:</td>
                        <td>{jobInfoById?.sub_service}</td>
                      </tr>
                      <tr>
                        <td>Word Count:</td>
                        <td>{jobInfoById?.total_wordcount}</td>
                      </tr>
                      <tr>
                        <td>Service Type:</td>
                        <td>{jobInfoById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                      </tr>
                      <tr>
                        <td>Language:</td>
                        <td>
                          {
                            jobInfoById?.english_version=='1'?'American':
                            jobInfoById?.english_version=='2'?'Australian':
                            jobInfoById?.english_version=='3'?'British':
                            jobInfoById?.english_version=='4'?'Canadian':''
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Service File:</td>
                        <td>
                              {/* {
                                jobInfoById?.service_filename.endsWith('.doc') || 
                                jobInfoById?.service_filename.endsWith('.docx') ? <img src={docImg} alt />:''
                              } */}
                              {serviceFile && serviceFile.length > 0 && serviceFile.map((item,index)=>{
                                  return(
                                    item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                    item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                  )
                                    // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                              })}
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>Instruction File:</td>
                        <td><br/>
                          {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                            return(
                              item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                              item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(56,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                            )
                                    // <a data-toggle="tooltip" data-placement="top" title={jobInfoById?.service_filename} onClick={()=>downloadFile(jobInfoById?.service_filename)} className="anchor-link">{shortFileName(jobInfoById?.service_filename)}</a> */}
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              
              {jobInfoById?.job_status == '3' || jobInfoById?.job_status == '30' &&(
                <>
                  {/* Comment Section Start */}

                  {ShowAllComments && ShowAllComments.map((item,index)=>{
                    return(
                      <div className="CommentItem">
                        {/* --Client Comment Section Start-- */}
                          {item?.client_comment!='' && (
                            <>
                              <p className="AuthorName">
                                {/* <span className="NameLogo">sw</span> Sam Willium */}
                                <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.client_comment =="undefined" ? '' : item?.client_comment}}/>
                            </>
                          )}

                          {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment =='' && (
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                              </p>
                              <ul className="showAttachFileList">
                                <li>
                                  {
                                    item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                  }
                                    
                                    {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                                </li>
                              </ul>
                            </>

                          )}

                          {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment!='' && (
                            <>
                              <ul className="showAttachFileList">
                                <li>
                                  {
                                    item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                    item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                  }
                                    
                                    {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                                </li>
                              </ul>
                            </>

                          )}
                        {/* --Client Comment Section End-- */}
                          
                        {/* --Editor Comment Section Start-- */}
                          {item?.editor_comment!='' && (
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.editor_comment =="undefined" ? '' : item?.editor_comment}}/>
                            </>
                          )}

                          {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment =='' && (
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                              </p>
                              <ul className="showAttachFileList">
                                <li>
                                  { 
                                  item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                  item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                                  }
                                    
                                    {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                                </li>
                              </ul>
                            </>
                          )}

                          {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment!='' &&(

                          <ul className="showAttachFileList">
                            <li>
                              { 
                              item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                              item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                              }
                                
                                {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>

                          )}
                        {/* --Editor Comment Section End-- */}

                        {/* --Admin Comment Section Start-- */}
                          {item?.admin_comment!='' && (
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.admin_comment =="undefined" ? '' : item?.admin_comment}}/>
                            </>
                          )}

                          {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment =='' &&(
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                              </p>
                              <ul className="showAttachFileList">
                                <li>
                                  { 
                                    item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                    item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                                  }
                                    
                                    {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                                </li>
                              </ul>
                            </>
                          )}

                          {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment!='' && (

                          <ul className="showAttachFileList">
                            <li>
                              { 
                                item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                              }
                                
                                {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>

                          )}
                        {/* --Admin Comment Section End-- */}

                        {/* --Internal Comment Section Start-- */}
                          {item?.internal_comment!='' && (
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.internal_comment =="undefined" ? '' : item?.internal_comment }}/>
                            </>
                          )}

                          {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment =='' &&(
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                              </p>
                              <ul className="showAttachFileList">
                                <li>
                                  { 
                                    item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                    item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                                  }
                                    
                                    {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                                </li>
                              </ul>
                            </>
                          )}

                          {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment!='' &&(

                          <ul className="showAttachFileList">
                            <li>
                              { 
                                item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                              }
                                
                                {/* <span className="upload-title-span">{shortFileName(item?.client_file)}</span> */}
                            </li>
                          </ul>

                          )}
                        {/* --Internal Comment Section End-- */}

                        {/* --Deputy Comment Section Start-- */}
                          {item?.deputy_comment!='' && (
                            <>
                              <p className="AuthorName">
                                <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                              </p>
                              <p dangerouslySetInnerHTML={{__html: item?.deputy_comment =="undefined" ? '' : item?.deputy_comment }}/>
                            </>
                          )}

                          {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment =='' &&(
                              <>
                                <p className="AuthorName">
                                  <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                                </p>
                                <ul className="showAttachFileList">
                                  <li>
                                    { 
                                      item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                      item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                                    }
                                  </li>
                                </ul>
                              </>
                          )}

                          {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment!=''&& (

                          <ul className="showAttachFileList">
                            <li>
                              { 
                                item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                              }
                            </li>
                          </ul>

                          )}
                        {/* --Deputy Comment Section End-- */}

                      <div className="RhtDate">
                        <span>  
                          {get_date_format(item?.created_at)}
                          <br />
                          {get_time_format(item?.created_at)}
                        </span>
                      </div>

                    </div>
                  )})}

                  {/* Comment Section End */}
                </>
              )}
            </div>  

            {jobInfoById?.job_status == '30' &&(
              <div className="BtmFtr">
                <a className="attachFileLink"><input type="file" className="inputFile" onChange={(e) => {uploadFileHandle(e)}}/><img src={attachFileIcon} alt /></a>
                <CKEditor 
                  editor={ ClassicEditor }
                  data = {adminMsg}
                  config={{
                    toolbarLocation: "bottom",
                    toolbar: ['link']
                  }}
                  onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setAdminMsg(data);
                  }}
                />
                <ul className="showAttachFileList">
                  {((fileName && fileName != undefined) || (fileName && fileName != null) || (fileName && fileName != '')) ? (
                    <li>
                      <span className="icon-span">
                        {
                          fileName?.endsWith('.pdf') ? <img src={pdfImg} alt="img" />:
                          fileName?.endsWith('.PDF') ? <img src={pdfImg} alt="img" />:
                          fileName?.endsWith('.docx') ? <img src={docImg} alt="img" />:
                          fileName?.endsWith('.DOCX') ? <img src={docImg} alt="img" />:
                          fileName?.endsWith('.jpg') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.JPG') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.jpeg') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.JPEG') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.png') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.PNG') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.jfif') ? <img src={iconImg} alt="img" />:
                          fileName?.endsWith('.JFIF') ? <img src={iconImg} alt="img" />:''
                        }
                      </span>
                      <span className="upload-title-span">{fileName}</span>
                      <span className="delete-upload-span"><i class="bi bi-trash" onClick={()=>deleteFileHandle()}></i></span>
                    </li>
                  ):(
                    <li></li>
                  )}
                </ul>

                <a className="btn btn-primary CmnBtn submitBtm" onClick={()=>commentSection()}>Submit</a>
              </div>
            )}
            {/* Comment Section End */}
              
          </div>
        </div>

      </main>

      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p>*You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when you cancel order after 30 min of submission there is no full refund.</p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn">NO</a>
        </Modal.Body>
      </Modal>
      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrderForm">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Request Order Cancellation</h2>
          <p><span>*</span>You will be able to cancel their order if they wish to within 30 min of submission for a full refund..This might help you to understand that when you cancel order after 30 min of submission there is no full refund.</p>
          <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" />
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..."></textarea>
          <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={modalclose}  className="CmnModal CancelOrder">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Select Editor</h2>
          <>
            <select name = "subservice" onChange={(e)=>{assignEditor(e.target.value);modalclose();}}>
              <option value='null'>--Select Editor--</option>
                {showEditorList && showEditorList.map((item,index)=>{
                  return(
                    <option value={item?.id}>{item?.first_name} {item?.last_name}</option>
                  )
                })}    
            </select>
          </>
        </Modal.Body>
      </Modal>

      {/* Reassign Modal Start*/}

      <Modal show={showPartialModal} onHide={partialModalclose}  className="CmnModal CancelOrder reassignModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Reassign Job</h3>
          <ul className="reassign-list">
            <li><Form.Check type="radio" label='ReAssign Editor' name="service" value="Editor" defaultChecked onChange={()=>handleAssign('Editor')}/></li>
            <li><Form.Check type="radio" label='ReAssign Internal' name="service" value="IntEditor" onChange={()=>handleAssign('IntEditor')}/></li>
          </ul>
          {userAssignType && userAssignType == 'Editor' ? (
            <ul class="row reassign-list2">
              <li className="col-md-6">
                <select name="EditorList" className="form-select" onChange={(e)=>{setSelectedEditorId(e.target.value)}}>
                  <option selected hidden>Select Editor</option>
                  {showEditorList && showEditorList.map((item,index)=>{
                    return(
                      <option key={index} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                    )
                  })}
                </select>
              </li>
              <li className="col-md-6"><input type="wcOrpage" class="form-control" placeholder="Editor A WC/Page No." name='prevwc'  value={prevEditorWC}/></li>
              <li className="col-md-6"><input type="number" class="form-control" placeholder="Editor A Amount" name='prevamount' value={prevEditorAmount}/></li>
              <li className="col-md-6"><input type="wcOrpage" class="form-control" placeholder="Editor B WC/Page No." name='currentwc' onChange={(e)=>setCurrentEditorWC(e.target.value)} value={currentEditorWC}/></li>
              <li className="col-md-6"><input type="number" class="form-control" placeholder="Editor B Amount" name='currentamount' onChange={(e)=>setCurrentEditorAmount(e.target.value)} value={currentEditorAmount}/></li>
              <li className="col-md-6">
                <a 
                  class="CmnBtn" 
                  name='reassign' 
                  onClick={()=>handleReAssignPayment(
                    jobInfoById?.id,
                    '1',
                    jobInfoById?.editor_id!=''?jobInfoById?.editor_id:jobInfoById?.internal_id,
                    loggedUser?.id
                  )}>
                  <i class="bi bi-check-circle"></i> ReAssign
                </a>
              </li>
            </ul>
            // (job_id,user_type,prev_user_id,current_user_id,reassign_by)
          ):
          userAssignType && userAssignType == 'IntEditor' ?(
            <ul class="row reassign-list2">
              <li className="col-md-6">
                <select name="EditorList" className="form-select" onChange={(e)=>{setSelectedInternalEditorId(e.target.value)}}>
                  <option selected hidden>Select Internal Editor</option>
                  {showInternalList && showInternalList.map((item,index)=>{
                    return(
                      <option key={index} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                    )
                  })}
                </select>
              </li>
              <li className="col-md-6"><input type="wcOrpage" class="form-control" placeholder="Editor A WC/Page No." name='prevwc'  value={prevEditorWC}/></li>
              <li className="col-md-6"><input type="number" class="form-control" placeholder="Editor A Amount" name='prevamount' value={prevEditorAmount}/></li>
              <li className="col-md-6"><input type="wcOrpage" class="form-control" placeholder="Editor B WC/Page No." name='currentwc' onChange={(e)=>setCurrentEditorWC(e.target.value)} value={currentEditorWC}/></li>
              <li className="col-md-6"><input type="number" class="form-control" placeholder="Editor B Amount" name='currentamount' onChange={(e)=>setCurrentEditorAmount(e.target.value)} value={currentEditorAmount}/></li>
              <li className="col-md-6">
                <a 
                  class="CmnBtn" 
                  name='reassign' 
                  onClick={()=>handleReAssignPayment(
                    jobInfoById?.id,
                    '4',
                    jobInfoById?.editor_id!=''?jobInfoById?.editor_id:jobInfoById?.internal_id,
                    loggedUser?.id
                  )}>
                    <i class="bi bi-check-circle"></i> ReAssign
                </a>
              </li>
            </ul>
          ):null}

        </Modal.Body>
      </Modal>

      {/* Reassign Modal End*/}

    </div>
  );
};

export default InternalEditorHoldingJobs;
