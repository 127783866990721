import {
  React, useEffect, useState, useRef,
  useNavigate, Link,
  axios, 
  NotificationManager,
  Button,
  Modal,
  DateTime,
  Rating,
  CKEditor,
  ClassicEditor,
  Form,

  // all icons
  reviewIcon,
  docFileImg,
  docImg,
  iconImg,
  pdfImg,
  attachFileIcon,
  rtfImg,
  odtImg,
  pagesImg,
  pptImg,
  xlsxImg,
  gdocImg,
  gslideImg,
  gsheetImg,
  plainTextImg,
  LatexImg,
  linkIcon,
  feedbackStarImg
} from '../../utility/CommonImport'; // Import

import { Dropdown } from 'react-bootstrap';

import Clientheader from "../../Components/ClientComponents/Clientheader";
import Clientsidebar from "../../Components/ClientComponents/Clientsidebar";

import $ from "jquery";

import { API_BASE_URL} from '../../api_url';
import { get_date_format, get_time_format, shortUserName } from '../../utility/Common';
import { saveAs} from 'file-saver';
import { clientRating, fetchJobDetails, sortingJobListFunc, jobDetails, jobDetailsById, jobDetailsByUser, submitClientFeedbackFunc, 
  jobCanceledByClient, commentDetailsByEditor, postComment, postCommentMail, fetchAllNotification, jobApprovedByClient, 
  jobApprovedMailByClient } from '../../API/apiRequests';

const Clientmyorders = () => {
  const windowRef = useRef();
  const navigate = useNavigate();
  let data= JSON.parse(localStorage.getItem("user_data_elite"));
  const timeZone = DateTime.local().zoneName;
  const editorRef = useRef();
  const [progress, setProgress] = useState()
  const [show, setShow] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [orderCancel, setOrderCancel] = useState(false);
  const [orderCancelAfter30, setOrderCancelAfter30] = useState(false);
  const [orderCancelForm, setOrderCancelForm] = useState(false);
  const [orderDetailsById,setOrderDetailsById] = useState();
  const [orderList,setOrderList] = useState([]);
  const [arrayLength,setArrayLength] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const markClose = () => setMarkComplete(false);
  const markShow = () => setMarkComplete(true);
  const orderCancelClose = () => setOrderCancel(false);
  const orderCancelCloseAfter30mins = () => setOrderCancelAfter30(false);
  const orderCancelShow = () => setOrderCancel(true);
  const orderCancelShow30mins = () => setOrderCancelAfter30(true);
  const orderCancelFormClose = () => setOrderCancelForm(false);
  const orderCancelFormShow = () => setOrderCancelForm(true);
  const [serviceFile,setServiceFile] = useState();
  const [instructionFile,setInstructionFile] = useState();
  const [loading,setLoading] = useState(false); 
  const [onApprove,setOnApprove] = useState(false)
  const [cancelLoading,setcancelLoading] = useState(false); 
  const [clientMsg,setClientMsg] = useState(); // set message onChange
  const [ShowAllComments,setShowAllComments] = useState([]); // editor client comments.
  const [callAPI,setCallAPI] = useState(false);
  const [commentData,setCommentData] = useState(null);
  const [star, setStar] = useState({
    rating: 0,
    totalStars: 5,
    sharpness: 2.5,
    width: 50,
    height: 50,
    outlined: true,
    outlinedColor: "",
    fullStarColor: "#FFBC00",
    emptyStarColor: "transparent"
  });
  const [rating, setRating] = useState(0);
  const [showMarkCompleteBtn,setShowMarkCompleteBtn] = useState(false);
  const [loadingMarkComplete,setLoadingMarkComplete] = useState(false); 
  const [loadingMarkComplete1,setLoadingMarkComplete1] = useState(false); 
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    orderCancelClose();
    orderCancelFormShow();
  }
  const [displayMsg,setDisplayMsg] = useState(0);
  const [value2,setValue2] = useState('1');
  const [clientFeedback,setClientFeedback] = useState();
  const [oneTimeFeedback,setOneTimeFeedBack] = useState('0');
  const [sendMsgTo,setSendMsgTo] = useState();
  const [clientFeedbackError,setClientFeedbackError] = useState(false); //for client feedback validation
  const [sendMsgToError,setsendMsgToError] = useState(false); //for sendMsgTo validation
  const [showSendOption,setShowSendOption] = useState(); //send option
  const [showCancelReasonTitle,setShowCancelReasonTitle] = useState(); // cancel reason title
  const [showCancelReasonDescription,setShowCancelReasonDescription] = useState(); // cancel reason description

  const [showCancelReasonTitleError,setShowCancelReasonTitleError] = useState(false); // cancel reason title
  const [showCancelReasonDescriptionError,setShowCancelReasonDescriptionError] = useState(false); // cancel reason description
  const [editorData,setEditorData] = useState(); // for CK Editor Data
  const [file,setFile] = useState(); // comment file upload
  const [fileName,setFileName] = useState(); // comment file upload
  const [jobStats,setJobStats] = useState();
  const [apiCall,setAPiCall] = useState(false);
  const [feedbackLoading,setFeedbackLoading] = useState(false);
  const [notificationMsg,setNotificationMsg] = useState();

  const [isWindowOpen, setIsWindowOpen] = useState(false)

  useEffect(() => {
    $(".completed-list").click(function(){
      $(".OngoingPnl").removeClass("Act");
      $(".CompletedPnl").addClass("Act");
    });
    $(".yet-to-asign-list, .ongoing-list").click(function(){
      $(".CompletedPnl").removeClass("Act");
      $(".OngoingPnl").addClass("Act");
    });
    $(".CloseBtn").click(function(){
      $(".DtlsSectionWrapper").removeClass("Act");
    });
  });


  const openJobDetlsPnl = () => { 
    setIsWindowOpen(true)
    $(".ClientMyOrderPnl").addClass("Act");
  };

  const closeJobDetlsPnl = () => { 
    $(".ClientMyOrderPnl").removeClass("Act");
  };

// useEffect(() => {
//   setTimeout(() => {
//     console.clear();
//     setCallAPI(!callAPI);
//     // jobListByClient();
//     fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id);
//   }, 3000);
//   console.log(callAPI);
// }, [callAPI])

useEffect(() => {
  if(apiCall)
  {
    let timer = setTimeout(() => {
      console.clear();
      setCallAPI(!callAPI);
      // jobListByClient();
      fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
    }, 15000);
    return () => clearTimeout(timer);
    // console.log(callAPI);
  }
// }, [callAPI])
})

useEffect(() => {
  const checkIfClickedOutside = e => {
    if (isWindowOpen && windowRef.current && !windowRef.current.contains(e.target))
    {
      setIsWindowOpen(false)
      closeJobDetlsPnl()
    }
    else
    {
      // console.log('here')
      // console.log(isWindowOpen)
      // console.log(windowRef.current)
    }
  }
  document.addEventListener("mousedown", checkIfClickedOutside)

  return () => {
    document.removeEventListener("mousedown", checkIfClickedOutside); // Cleanup the event listener
  }
}, [isWindowOpen])

useEffect(() => {
  jobListByClient();
}, []);

  // get Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }

  //Send Option
  const sendOption = (val) =>{
    setSendMsgTo(val);
    setsendMsgToError(false);
  }

  const uploadCommentFile = (e) =>{
    if(e.currentTarget.files[0].type=='application/msword' ||
      e.currentTarget.files[0].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||
      e.currentTarget.files[0].type== 'application/pdf' ||
      e.currentTarget.files[0].type== 'image/jpeg' ||
      e.currentTarget.files[0].type=='image/png')
    {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
    else{
      NotificationManager.warning('File is not supported.Supported file types are DOC,DOCX,PDF,JPEG,PNG','',3000);
    }
  }

  const deleteCommentFile = () => {    
    setFile('');
    setFileName('');
  }

  // Mark Complete Type one with star rating
  const submitTypeOneMarkComplete = async() =>{
    try {
      if( rating == 0 ) {
        NotificationManager.warning('Could you please give a rating for this?', "", 2000);
      }else {
        setLoadingMarkComplete(true);
        let apiRes = await clientRating(orderDetailsById?.id,rating,'4',data.id);
        if (apiRes.data.res == true) {
          setShowMarkCompleteBtn(false);
          markClose();
          // handleClose();
          $(".DtlsSectionWrapper").removeClass("Act");
          jobListByClient();
          getClientNotification();
        } else {
          NotificationManager.warning('The server failed to fullfill a request.')
        }
      }
    }
    catch (error) {
      console.error(error);
    } finally {
      setLoadingMarkComplete(false);
    }
  }

  // Mark Complete Type Two with star rating **pending
  const submitTypeTwoMarkComplete = async() =>{
    // alert(rating)
    // alert(orderDetailsById?.id)
    try
    {
      setLoadingMarkComplete1(true);
      // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumestatus?id=${orderDetailsById?.id}&rating=${rating}&job_status=${4}&user_id=${data.id}`)
      let apiRes = await axios.get(`${API_BASE_URL}job/jobstatus?id=${orderDetailsById?.id}&rating=${rating}&job_status=${4}&user_id=${data.id}`)
      if (apiRes) 
      {
        console.log(apiRes.data.res);
        if (apiRes.data.res == true) 
        {
          setShowMarkCompleteBtn(false);
          setLoadingMarkComplete1(false);
          // NotificationManager.success('Mark Completed Successfully..', "", 2000);
          markClose();
          // handleClose();
          $(".DtlsSectionWrapper").removeClass("Act");
          jobListByClient();
          getClientNotification();
        }
      }
    } //close try
    catch (error) 
    {
      setLoadingMarkComplete1(false);
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  const fetchJobDetails= async(job_id) =>{
    try {
      setAPiCall(true);
      let apiRes = await jobDetailsById(job_id,timeZone);
      if (apiRes.data.res == true) {
        setOrderDetailsById(apiRes.data.data[0]);
        // setOneTimeFeedBack(apiRes.data.data[0].Feedback);
        setServiceFile(apiRes.data.data[0].service_filename);
        setInstructionFile(apiRes.data.data[0].instruction_filename);
        // setShowSendOption(apiRes.data.data[1].send_option);
        // fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id,apiRes.data.data[0].deputy_id);
        fetchAllComments(apiRes.data.data[0].user_id,apiRes.data.data[0].editor_id,apiRes.data.data[0].id,apiRes.data.data[0].internal_id,apiRes.data.data[0].deputy_id);
        // fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
      } else {
        NotificationManager.warning('The server failed to fullfill a request.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get Resume/Cv order details by id
  const fetchJobDetailsResumeCv= async(cv_id) =>{
    try
    {
      setAPiCall(true);
      // let apiRes = await axios.get(`${API_BASE_URL}cvresume/cvresumedetail?id=${cv_id}`)
      let apiRes = await axios.get(`${API_BASE_URL}job/jobdetail?id=${cv_id}&UserTimeZone=${timeZone}`)
      if (apiRes) 
      {
          console.log(apiRes.data.data);
          if (apiRes.data.res == true) 
          {
            // console.log(apiRes.data.data);
            setOrderDetailsById(apiRes.data.data[0]);
            setServiceFile(apiRes.data.data[0].service_filename);
            setInstructionFile(apiRes.data.data[0].instruction_filename);
            fetchAllComments(apiRes.data.data[0]?.user_id,apiRes.data.data[0]?.editor_id,apiRes.data.data[0]?.id);
          }
      }
    } //close try
    catch (error) 
    {
      // NotificationManager.error(error.message, "", 2000);
    }
  }

  const jobListByClient = async () => {
    try {
      let apiRes = await jobDetailsByUser(data?.id,timeZone);
      if (apiRes.data.res == true) {
        setOrderList(apiRes.data.data);
      } else {
        NotificationManager.warning('The server failed to fullfill a request.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  //file download
  const downloadFile = (item) => {
    saveAs(item);
  }

  //short service-file name
  const shortFileName=(item) =>{
    console.log(item?.slice(62,70));
    let filename = item?.slice(56,70);
    return filename + '...';
    // return item
  }

 //short comment-file name
  const shortCommentFileName = (item) =>
  {
    let splt_filename = item?.split("/");
    // console.log(splt_filename);
    return splt_filename[1];
    // return item;
  }

   //short instruction-file name
   const shortInstFileName=(item) =>{
    console.log(item?.slice(57,65));
    let filename = item?.slice(57,65);
    return filename;
    // return item
  }

  
  //sorting job list
  const sortingJobList = async(selected_job_status) =>{
    console.log(selected_job_status)
    if (selected_job_status == 0)
    {
      try
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/joblist`) 
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setOrderList(apiRes.data.data);
            }
        }
      }//close try 
      catch (error)
      {
        // NotificationManager.error(error.messaage, "", 2000);
      }
    }
    else
    {
      try 
      {
        let apiRes = await axios.get(`${API_BASE_URL}job/jobliststatus?job_status=${selected_job_status}`)
        if (apiRes) 
        {
            console.log(apiRes.data.res);
            if (apiRes.data.res == true) 
            {
              setOrderList(apiRes.data.data);
            }
        }
      } 
      catch (error) 
      {
        // NotificationManager.error(error.message, "", 2000);
      }
    }
  }

  //sorting job list
  const sortingJobListByClientId = async(selected_job_status) =>{
    if (selected_job_status == 0){
      try {
        let apiRes = await jobDetailsByUser(data?.id,timeZone);
        if (apiRes.data.res == true) {
          setOrderList(apiRes.data.data);
        } else {
          NotificationManager.warning('The server failed to fullfill a request.');
        }
      } catch (error) {
        console.error(error);
      }
    }
    else {
      try {
        let apiRes = await sortingJobListFunc(selected_job_status,data?.id);
        if (apiRes.data.res == true) {
          setOrderList(apiRes.data.data);
        } else {
          NotificationManager.warning('The server failed to fullfill a request.');
        }
      } 
      catch (error) {
        console.error(error);
      }
    }
  }

  const onSubmitFeedback = async() =>{
    if(orderDetailsById?.service_type == '0') {
      service_type = 'Regular Service'
    }
    else if(orderDetailsById?.service_type == '1') {
      service_type = 'Expedited Service'
    } 
    var full_name = data.first_name + ' ' + data.last_name;
    if((clientFeedback== '' && sendMsgTo== '') || (clientFeedback== undefined && sendMsgTo== undefined) || (clientFeedback== null && sendMsgTo== null)) {
      setClientFeedbackError(true);
      setsendMsgToError(true);
      NotificationManager.error('All fields are Blank!!', '',2000);
    }
    else if(clientFeedback== ''  || clientFeedback== undefined  || clientFeedback== null ) {
      setClientFeedbackError(true);
      NotificationManager.error('Blank field is not allowed!!', '',2000);
    }
    else if(sendMsgTo== '' || sendMsgTo== undefined ||  sendMsgTo== null) {
      setsendMsgToError(true);
      NotificationManager.error('Blank field is not allowed!!', '',2000);
    }
    else {
      let job_type = '';
      if(orderDetailsById?.job_type=='type2') {
        job_type='type2';
        var job_title = orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week'
      }
      else if(orderDetailsById?.job_type=='type1') {
        job_type='type1';
        var job_title = orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + ' ' + orderDetailsById?.total_wordcount +' ' + '|' + ' ' + service_type + ' ' + '|' + ' ' + orderDetailsById?.service_hour + ' '  +'hrs'
      }
      setClientFeedbackError(false);
      setsendMsgToError(false);
      var service_type='';
      try {
        setFeedbackLoading(true);
        let payload_data = {
          user_id: data?.id,
          user_name: full_name,
          editor_id: orderDetailsById?.editor_id,
          editor_name: orderDetailsById?.editor_name,
          editor_email: orderDetailsById?.editor_email,
          job_id: orderDetailsById?.id,
          job_title: job_title,
          comment: clientFeedback,
          // send_option: sendMsgTo && sendMsgTo =='Send-to-all'? '0':'1',
          send_option: sendMsgTo && sendMsgTo =='Send-to-all'? '2': sendMsgTo && sendMsgTo =='Send-to-only-admin'? '1' : '0',
          job_type: job_type
        }
        let apiRes = await submitClientFeedbackFunc(payload_data);
        if(apiRes.data.res == true) {
          NotificationManager.success('Thank you for your feedback','',2000);
          setClientFeedback('');
          setOneTimeFeedBack('1');
          setSendMsgTo('');
          handleClose();
        } else {
          NotificationManager.warning('The server failed to fullfill a request.');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFeedbackLoading(false);
      }
    }
  }

  //Order Cancellation
  const orderCancellation = async() =>{

    // if((showCancelReasonTitle== '' && showCancelReasonDescription== '') || (showCancelReasonTitle== undefined && showCancelReasonDescription== undefined) || (showCancelReasonTitle== null && showCancelReasonDescription== null))
    // {
    //   setShowCancelReasonTitleError(true);
    //   setShowCancelReasonDescriptionError(true);
    //   NotificationManager.error('All fields are Blank!!', '',2000);
    // }
    // else if(showCancelReasonTitle== ''  || showCancelReasonTitle== undefined  || showCancelReasonTitle== null )
    // {
    //   setShowCancelReasonTitleError(true);
    //   NotificationManager.error('Blank field is not allowed!!', '',2000);
    // }
    // else if(showCancelReasonDescription== ''  || showCancelReasonDescription== undefined  || showCancelReasonDescription== null )
    // {
    //   setShowCancelReasonDescriptionError(true);
    //   NotificationManager.error('Blank field is not allowed!!', '',2000);
    // }
    // else
    // {
      
      let service_type= '';
      var job_title = '';
      let job_type = '';
  
      if(orderDetailsById?.service_type == '0') {
        service_type = 'Regular Service'
      } else if(orderDetailsById?.service_type == '1') {
        service_type = 'Expedited Service'
      } 
  
      if(orderDetailsById?.job_type=='type2') {
        job_type='type2';
        var job_title = orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week'
      }
      else if(orderDetailsById?.job_type=='type1') {
        job_type='type1';
        var job_title = orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + ' '+ orderDetailsById?.total_wordcount +' ' + '|' + ' ' + service_type + ' ' + '|' +' '+ orderDetailsById?.service_hour + ' '  +'hrs'
      }
      try {
        let full_name = data?.first_name + ' ' + data?.last_name
        setcancelLoading(true);
        let payload_data = {
          job_id: orderDetailsById?.id,
          job_title: job_title,
          job_type: orderDetailsById?.job_type,
          user_id:data?.id,
          user_name: full_name,
          user_mail: data?.email_address,
          // cancellation_title: showCancelReasonTitle && showCancelReasonTitle,
          // cancellation_description: showCancelReasonDescription && showCancelReasonDescription
          cancellation_title: '',
          cancellation_description: '',
          cancelled_by: 'client'
        }
        let apiRes = await jobCanceledByClient(payload_data);
        if(apiRes.data.res == true) {
          NotificationManager.success('Order Canceled Successfully','',2000);
          orderCancelFormClose();
          orderCancelClose();
          $(".DtlsSectionWrapper").removeClass("Act");
          jobListByClient();
        } else {
          NotificationManager.error('Order not canceled', '',2000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setcancelLoading(false);
      }
  }
  
  //fetch all comments Editor-Client
  const fetchAllComments = async(user_id,editor_id,job_id,internal_id,deputy_id) =>{
    try {
      let intern_id= '';
      let dep_id = '';
      setLoading(true);
      if(internal_id == null && deputy_id == null) {
        intern_id = '';
        dep_id = '';
      } else {
        intern_id = internal_id;
        dep_id = '';
      }
      let apiRes = await commentDetailsByEditor(user_id,editor_id,'5',job_id,intern_id,dep_id,timeZone);
      if(apiRes.data.res == true) {
        setShowAllComments(apiRes.data.data);
      } else {
        NotificationManager.warning('The server failed to fullfill a request.');
      }
    } catch (error) {
      console.error(error);
    }
 }

  //Client-Editor Comment Section
  const commentSection = async(editor) =>{
    if((clientMsg == '' || clientMsg == null || clientMsg == undefined) && (fileName == '' || fileName == null || fileName == undefined)) {
      NotificationManager.warning('Please write something..','',2000);
    } else {
      var job_title='';
      var service_type ='';
      setClientMsg('');
      try {
        if(orderDetailsById?.service_type == '0') {
          service_type = 'Regular Service'
        } else if(orderDetailsById?.service_type == '1') {
          service_type = 'Expedited Service'
        }

        if(orderDetailsById?.job_type =='type2') {
          job_title = orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + 'Number of Pages' + ' '+ orderDetailsById?.number_of_pages +' ' + '|' +' ' +'Regular Service' + ' ' + '|' + ' ' +  '1 Week'
        } else if(orderDetailsById?.job_type =='type1') {
          job_title = orderDetailsById?.id +' ' + '|' +' ' + orderDetailsById?.service_name +' ' + '|' +' ' + orderDetailsById?.sub_service +' ' + '|' +' ' +'Word Count' + ' ' + orderDetailsById?.total_wordcount +' ' + '|' + ' ' + service_type + ' ' + '|' + ' ' + ' ' +orderDetailsById?.service_hour + ' '  +'hrs'
        }
        setLoading(true);
        const formData = new FormData();

        formData.append('user_id',orderDetailsById?.user_id);
        formData.append('jobtitle',job_title);
        formData.append('editor_id',orderDetailsById?.editor_id? orderDetailsById?.editor_id : '');
        formData.append('admin_id','5');
        formData.append('internal_id',orderDetailsById?.internal_id? orderDetailsById?.internal_id : '');
        formData.append('deputy_id',orderDetailsById?.deputy_id ? orderDetailsById?.deputy_id : '');
        formData.append('job_id',orderDetailsById?.id);

        formData.append('editor_designation',orderDetailsById?.editor_designation? orderDetailsById?.editor_designation : '');
        formData.append('internal_designation',orderDetailsById?.internal_designation? orderDetailsById?.internal_designation : '');
        formData.append('admin_designation','Admin');
        formData.append('deputy_designation',orderDetailsById?.deputy_designation ? orderDetailsById?.deputy_designation : '');

        formData.append('client_comment',clientMsg);
        formData.append('editor_comment','');
        formData.append('admin_comment','');
        formData.append('internal_comment','');
        formData.append('deputy_comment','');
        formData.append('client_file',file);
        formData.append('editor_file','');
        formData.append('admin_file','');
        formData.append('internal_file','');
        formData.append('deputy_file','');

        const config = {     
          headers: { 'content-type': 'multipart/form-data' }
        }
        let apiRes = await postComment(formData,config);
         
        if(apiRes.data.res == true) {
          setClientMsg('');
          setFile('');
          setFileName('');
          formData.append('comment_id',apiRes.data.comment_id);
          formData.append('sender_id',data?.id);
          fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
          getClientNotification();
          
          let apiRes1 = await postCommentMail(formData,config);
          if(apiRes1.data.res == true) {
            fetchAllComments(orderDetailsById?.user_id,orderDetailsById?.editor_id,orderDetailsById?.id,orderDetailsById?.internal_id,orderDetailsById?.deputy_id);
            CKEditor.instances['editor1'].setData('');
          } else {
            NotificationManager.warning('The server failed to fullfill a request.');
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const getClientNotification = async() =>{
    try {
      let payload_data = {user_id: data?.id};
      let apiRes = fetchAllNotification(payload_data);
      if (apiRes.data.res == true) {
        setNotificationMsg(apiRes.data.NotificationUnred);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleApproveOrder = async(job_id) => {
    try {
      setLoading(true);
      let payload_data = {
        job_id: job_id,
        client_approve: '1',
        editor_id: orderDetailsById?.editor_id!=null ? orderDetailsById?.editor_id: orderDetailsById?.internal_id,
        user_id: data?.id 
      }
      let apiRes = await jobApprovedByClient(payload_data);
      if(apiRes.data.res == true) {
        // NotificationManager.success('Successfully Approved!','',2000);
        setOnApprove(true);
        orderCancelFormClose();
        $(".DtlsSectionWrapper").removeClass("Act");

        let apiRes1 = await jobApprovedMailByClient(payload_data);
        if(apiRes1) {
          if(apiRes1.data.res == true) {
            console.log('Mail Send','',2000);
          } else{
            console.log('Mail Not Send','',2000);
          }
        } else {
          throw new Error('Network request failed');
        }
      } else {
        NotificationManager.error(apiRes.data.msg, '',2000);
      }
    } catch (error) {
      NotificationManager.error(error.message, "", 2000);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Clientheader unreadMsg={notificationMsg}/>
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <span className="TitleWrap">
            <h2>My Orders</h2>
          </span>
          <div className="dropdown">Filter By
            <select className="dropdown-toggle" data-toggle="dropdown" style={{border: 'none',outline: 'none',scrollBehavior: 'smooth'}} onChange={(e)=>sortingJobListByClientId(e.target.value)}>
              <option className="dropdown-item" href="#" value='0'>All Jobs</option>
              <option className="dropdown-item" href="#" value='1'>Submitted</option>
              {/* <option className="dropdown-item" href="#" value='2'>Assigned</option> */}
              <option className="dropdown-item" href="#" value='3'>Ongoing</option>
              {/* <option className="dropdown-item" href="#" value='30'>Ongoing</option> */}
              {/* <option className="dropdown-item" href="#" value='31'>ReAssign</option> */}
              {/* <option className="dropdown-item" href="#" value='32'>Review</option> */}
              <option className="dropdown-item" href="#" value='4'>Completed</option>
              <option className="dropdown-item" href="#" value='5'>Canceled</option>:''
            </select>
          </div>
        </div>
        <div className="ButtonDiv MYOrderButtonDiv">
          <div className="webHide">
            <Dropdown className="AddDropdownBtn">
              <Dropdown.Toggle id="dropdown-basic">Add Order</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>navigate('/services')}>Editing/Proofreading</Dropdown.Item>
                <Dropdown.Item onClick={()=>navigate('/services-choose-cv')}>Resume/CV Building</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mobileHide">
            <div className="AddDropdownBtn dropdown">
              <button type="button" className="dropdown-toggle btn btn-primary">Add Order</button>
              <div className="dropdown-content">
                <a className="dropdown-item" onClick={()=>navigate('/services')}>Editing/Proofreading</a>
                <a className="dropdown-item" onClick={()=>navigate('/services-choose-cv')}>Resume/CV Building</a>
              </div>
            </div>
          </div>
        </div>
        <section className="section OdrDtlsPnl">
          <div className="ClientMyOrders">
            <table width="100%" className="CmnTbl OrdersTable client-my-order-table table-striped blue-header-table">
              <thead>
                <tr>
                  <th>Job #</th>
                  <th className="text-center">Task Name</th>
                  <th style={{textAlign: "center"}}>WC/Pages</th> 
                  <th>Due Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {orderList.length > 0 ? (
                orderList.map((item,index)=>{
                  return(
                    <tr>
                      <td>{item?.id}</td>
                      {(item?.job_type=='type2')?(
                        <td>
                          <p className="ListItem yet-to-asign-list" onClick={()=>{setJobStats(item?.job_status);fetchJobDetailsResumeCv(item?.id);openJobDetlsPnl()}}>
                            {item?.service_name} | Number of Pages {item?.number_of_pages} | {item?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                          </p>
                        </td>
                      ):(
                        <td>
                          <p className="ListItem yet-to-asign-list" onClick={()=>{setJobStats(item?.job_status);fetchJobDetails(item?.id);openJobDetlsPnl()}}>
                            {item?.service_name} | {item?.sub_service} | Word Count {item?.total_wordcount} | {item?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {item?.service_hour} hrs
                          </p>
                        </td>
                      )}
                      <td style={{textAlign: "center"}}>{item?.job_type=='type1'? item?.total_wordcount:item?.number_of_pages}</td>
                      <td> <span className="GreenTxt">{get_date_format(item?.due_date)}</span></td>
                      <td>
                      {
                          // item?.job_status == '1'? (<span>Not Assigned</span>):
                          // item?.job_status == '2'? (<span>Assigned</span>):
                          // item?.job_status == '3'? (<span>Submitted</span>):
                          // item?.job_status == '4'? (<span>Completed</span>):
                          // item?.job_status == '5'? (<span>Cancelled</span>):''
                          
                          item?.job_status == '1'? (<span>Submitted</span>):
                          item?.job_status == '2'? (<span>Assigned</span>):
                          item?.job_status == '3'? (<span>Ongoing</span>):
                          item?.job_status == '30'? (<span>Ongoing</span>):
                          item?.job_status == '31'? (<span>ReAssign</span>):
                          // item?.job_status == '32' ? (<span>Review</span>):
                          item?.job_status == '32' ? (<span>Assess</span>):
                          // item?.job_status == '32' && item?.client_approve == '1' ? (<span>Assess</span>):
                          item?.job_status == '4'? (<span>Completed</span>):
                          item?.job_status == '5'? (<span>Canceled</span>):
                          <span>Expired</span>
                      }
                      </td>
                    </tr>
                  )
                })
                ):(
                  <>
                  <tr>
                    <td className='no-data' colSpan={5} style={{background: "none"}}><p style={{textAlign:'center'}}>No Jobs Found</p></td>
                  </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>

          {/* left Ongoing Pannel */}
          <div className={`DtlsSectionWrapper OngoingPnl ClientMyOrderPnl`} ref={windowRef}>
            <div className={`TaskDtlsPnl ${orderDetailsById?.job_status == '3' ? 'textarea-sec':'no-textarea-sec'}`}>
              <div className="HdrTop">
                <span className="Lft">

                  {orderDetailsById?.job_status!= '5' ? (
                    <>
                      {orderDetailsById?.timeOver == "No" ?(
                        <>
                          <a onClick={orderCancelShow} className="CmnBtn CancelOrdereBtn" title="Cancel Order">Cancel Order</a>
                          {/* when admin released the job   !onApprove */}
                          {(orderDetailsById?.job_status == '32' && orderDetailsById?.client_approve =='0' ) ? (
                            <>
                              <a onClick={()=>handleApproveOrder(orderDetailsById?.id)} className="CmnBtn ApprovedOrderBtn-before" title="Approve Order">Approve Order</a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled mark-completed-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Mark Complete
                              </a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Feedback">
                                Feedback
                              </a>
                            </>
                          ):
                          //after approved
                          (orderDetailsById?.job_status== '32' && orderDetailsById?.client_approve =='1' && (orderDetailsById?.date75!= 0 || orderDetailsById?.differencetime!= 0)) ? (
                            <>
                              {/* <a className="CmnBtn CancelOrdereBtn">
                                <i className="bi bi-check-circle" /> 
                                Approved
                              </a> */}
                              <a className="CmnBtn CancelOrdereBtn  ApprovedOrderBtn-new" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Already Approved">Approved</a>

                              <a onClick={markShow} className="CmnBtn CancelOrdereBtn  mark-completed-ennable" data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Mark Complete
                              </a>

                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="feedback">
                                Feedback
                              </a>
                            </>
                          ):
                          //after mark complete
                          orderDetailsById?.job_status == '4' ? (
                            <>
                              {/* <a className="CmnBtn CancelOrdereBtn">
                                <i className="bi bi-check-circle" /> Approved
                              </a> */}
                              <a className="CmnBtn CancelOrdereBtn  ApprovedOrderBtn-new" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Already Approved">Approved</a>

                              {/* <a className="CmnBtn MarkCompleteBtn" data-toggle="tooltip" data-placement="top" title="mark completed">
                                <i className="bi bi-check-circle"/> Completed1
                              </a> */}
                              {/* <a onClick={markShow} className="CmnBtn MarkCompleteBtn" data-toggle="tooltip" data-placement="top" title="mark complete">
                                <i className="bi bi-check-circle"/> Mark Complete100
                              </a> */}
                              <a className="CmnBtn CancelOrdereBtn  MarkCompleteBtn" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Completed
                              </a>
                              {/* feedback submitted or not */}

                              {/* {oneTimeFeedback && oneTimeFeedback == '0' ? (
                                <a className="OpenReview" onClick={handleShow}>
                                  {orderDetailsById?.Feedback == 0 ? (
                                    <img src={reviewIcon} alt="img" />
                                  ):(
                                    <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="feedback">
                                      Feedback1
                                    </a>
                                  )}
                                </a>   
                              ):(
                                <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="feedback submitted">
                                  submitted
                                </a>
                              )} */}

                              {orderDetailsById?.Feedback == '0' ? (
                                <a className="OpenReview" onClick={handleShow}>
                                  <a className="CmnBtn CancelOrdereBtn FeedbackActiveBtn" data-toggle="tooltip" data-placement="top" title="Feedback">
                                    Feedback
                                  </a>
                                </a>  
                              ):
                              orderDetailsById?.Feedback == '1' ? (
                                <a className="CmnBtn CancelOrdereBtn FeedbackActiveBtn-new" data-toggle="tooltip" data-placement="top" title="Feedback">
                                  Feedback
                                </a>
                              ):(
                                <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="feedback submitted">
                                  Feedback
                                </a>
                              )}
                            </>
                          ):(
                            <>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled OpenReview approved-order-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Approve">Approve</a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled mark-completed-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Mark Complete
                              </a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Feedback">
                                Feedback
                              </a>
                            </>
                          )}
                        </>
                      ):(
                        <>
                          {orderDetailsById?.job_status == '4' && orderDetailsById?.client_approve == '1' ? (
                            <>
                              <a onClick={orderCancelShow30mins} className="CmnBtn CancelOrdereBtn cancel-odr-dissabled" data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                                Cancel Order
                              </a>
                              <a className="CmnBtn CancelOrdereBtn  ApprovedOrderBtn-new" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Already Approved">Approved</a>
                              <a className="CmnBtn CancelOrdereBtn  MarkCompleteBtn" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Completed
                              </a>
                              {orderDetailsById?.Feedback == '0' ? (
                                <a className="OpenReview" onClick={handleShow}>
                                  <a className="CmnBtn CancelOrdereBtn FeedbackActiveBtn" data-toggle="tooltip" data-placement="top" title="Feedback">
                                    Feedback
                                  </a>
                                </a>  
                              ):
                              orderDetailsById?.Feedback == '1' ? (
                                <a className="CmnBtn CancelOrdereBtn FeedbackActiveBtn-new" data-toggle="tooltip" data-placement="top" title="Feedback">
                                  Feedback
                                </a>
                              ):(
                                <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="feedback submitted">
                                  Feedback
                                </a>
                              )}
                            </>
                          ):
                          orderDetailsById?.job_status == '32' && orderDetailsById?.client_approve == '0' ? (
                            <>
                              <a onClick={orderCancelShow30mins} className="CmnBtn CancelOrdereBtn cancel-odr-dissabled" data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                                Cancel Order
                              </a>
                              <a onClick={()=>handleApproveOrder(orderDetailsById?.id)} className="CmnBtn ApprovedOrderBtn-before" title="Approve Order">Approve Order</a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled mark-completed-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Mark Complete
                              </a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Feedback">
                                Feedback
                              </a>
                            </>
                          ):
                          orderDetailsById?.job_status == '32' && orderDetailsById?.client_approve == '1' ? (
                            <>
                              <a onClick={orderCancelShow30mins} className="CmnBtn CancelOrdereBtn cancel-odr-dissabled" data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                                Cancel Order
                              </a>
                              <a className="CmnBtn CancelOrdereBtn  ApprovedOrderBtn-new" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Already Approved">Approved</a>
                              <a onClick={markShow} className="CmnBtn CancelOrdereBtn  mark-completed-ennable" data-toggle="tooltip" data-placement="top" title="Mark Complete">
                                <i className="bi bi-check-circle"/> Mark Complete
                              </a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Feedback">
                                Feedback
                              </a>
                            </>
                          ):(
                            <>
                              <a onClick={orderCancelShow30mins} className="CmnBtn CancelOrdereBtn cancel-odr-dissabled" data-toggle="tooltip" data-placement="top" title="Order Canceled">
                                Cancel Order
                              </a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled OpenReview approved-order-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">Approve</a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled mark-completed-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                                <i className="bi bi-check-circle"/> Mark Complete
                              </a>
                              <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                                Feedback
                              </a>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ):
                  orderDetailsById?.job_status == '5' ? (
                    <>
                      {/* <a className="CmnBtn CancelledTag" style={{cursor:'not-allowed'}}>
                        <i className="bi bi-x-circle"/> Canceled
                      </a> */}
                      {/* <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled OpenReview approved-order-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">Approve</a>
                      <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled MarkCompleteBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                        <i className="bi bi-check-circle"/> Mark Complete
                      </a>
                      <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Order Cancelled Requesting">
                        Feedback
                      </a> */}

                      <a class="CmnBtn CancelOrdereBtn cancel-odr-deactivate" style={{cursor:'not-allowed'}} data-toggle="tooltip" data-placement="top" title="Order Canceled">Canceled</a>
                      <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled OpenReview approved-order-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Approve">Approve</a>
                      <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled mark-completed-dissabled" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Mark Complete">
                        <i className="bi bi-check-circle"/> Mark Complete
                      </a>
                      <a className="CmnBtn CancelOrdereBtn cancel-odr-dissabled FeedbackDeactiveBtn" style={{cursor:'not-allowed',opacity:'.5'}} data-toggle="tooltip" data-placement="top" title="Feedback">
                        Feedback
                      </a>
                    </>
                  ):(
                    <></>
                  )}
                  
                </span>
                <span className="Rht">
                  {/* {(orderDetailsById?.job_status == '4' || orderDetailsById?.job_status == '5') ?(
                    <>
                      {(showSendOption!='1' || showSendOption!='0') && (oneTimeFeedback && oneTimeFeedback == '0')?(
                          <a className="OpenReview" onClick={handleShow}>
                            {orderDetailsById?.Feedback == 0 ? (
                              <img src={reviewIcon} al="img" />
                            ):(
                              <></>
                            )}
                         </a>   
                      ):(
                          <></>
                      )}
                    </>
                   
                  ):(
                    <></>
                  )} */}
                  <a href="javascript:void(0);" className="CloseBtn" onClick={()=>{closeJobDetlsPnl();setAPiCall(false)}}>
                    <i className="bi bi-x" />
                  </a>
                </span>
              </div>
              <div className="MiddleBody">
              {(orderDetailsById?.job_type=="type2")?(
                  <>
                    <h2>
                        #{orderDetailsById?.id} | {orderDetailsById?.service_name} | Number of Pages {orderDetailsById?.number_of_pages} | {orderDetailsById?.service_type == '0' ? 'Regular Service | 1 Week ' : 'Expedited Service'}
                    </h2>
                    <table className="DtlsDescrpTbl">
                      <tbody>
                        <tr>
                          {/* <td>Editor</td> */}
                          {/* <td>
                            <span className="NameLogo">sw</span> <span className="name-span">Sam Willium</span>
                          </td> */}
                        </tr>
                        <tr>
                          <td>Due date</td>
                          <td>
                            <span className="GreenTxt">{get_date_format(orderDetailsById?.due_date)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Editor</td>
                          <td>
                            <span className="name-span">
                              {
                                orderDetailsById?.editor_name!= null ? orderDetailsById?.editor_designation :
                                orderDetailsById?.internal_name!= null ? orderDetailsById?.internal_designation : null
                              }
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Instruction</td>
                          {(orderDetailsById?.client_instruction!='null' || orderDetailsById?.client_instruction!=null) ? (
                            <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} />
                          ):(
                            <td>No instruction included.</td>
                          )}
                        </tr>
                        <tr>
                          <td>Services:</td>
                          <td>{orderDetailsById?.service_name}</td>
                        </tr>
                        <tr>
                          <td>Service Type:</td>
                          <td>{orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                        </tr>
                        <tr>
                          <td>Number of Pages: </td>
                          <td>{orderDetailsById?.number_of_pages}</td>
                        </tr>
                        <tr>
                          <td>Price: </td>
                          <td>${parseFloat(orderDetailsById?.GrandTotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Service File:</td>
                          <td>
                            {serviceFile?.length > 0 && serviceFile.map((item,index)=>{
                              return(
                                item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                              )
                            })}
                            <br />
                          </td>
                        </tr>
                        
                        <tr>
                          <td>Instruction File:</td>
                          <td><br/>
                            {instructionFile && instructionFile.length > 0 && instructionFile.map((item,index)=>{
                              return(
                                item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                              )
                            })}
                          </td>
                        </tr>
                      
                      </tbody>
                    </table>
                  </>
                ):(
                  <>
                    <h2>
                      #{orderDetailsById?.id} | {orderDetailsById?.service_name} | {orderDetailsById?.sub_service} | Word Count {orderDetailsById?.total_wordcount} | {orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}  | {orderDetailsById?.service_hour} hrs
                    </h2>
                    <table className="DtlsDescrpTbl">
                      <tbody>
                        <tr>
                          <td>Due date</td>
                          <td>
                            <span className="GreenTxt">{get_date_format(orderDetailsById?.due_date)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Editor</td>
                          <td>
                            <span className="name-span">
                              {
                                orderDetailsById?.editor_name!= null ? orderDetailsById?.editor_designation :
                                orderDetailsById?.internal_name!= null ? orderDetailsById?.internal_designation : null
                              }
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Instruction</td>
                          {orderDetailsById?.client_instruction!='null' ? (
                            <td dangerouslySetInnerHTML={{__html: orderDetailsById?.client_instruction}} />
                          ):(
                            <td>No instruction included.</td>
                          )}
                        </tr>
                        <tr>
                          <td>Services:</td>
                          <td>{orderDetailsById?.service_name}</td>
                        </tr>
                        <tr>
                          <td>Options:</td>
                          <td>{orderDetailsById?.sub_service}</td>
                        </tr>
                        <tr>
                          <td>Word Count:</td>
                          <td>{orderDetailsById?.total_wordcount}</td>
                        </tr>
                        <tr>
                          <td>Service Type:</td>
                          <td>{orderDetailsById?.service_type == '0' ? 'Regular Service' : 'Expedited Service'}</td>
                        </tr>
                        <tr>
                          <td>Language:</td>
                          <td>
                            {
                              orderDetailsById?.english_version=='1'?'American':
                              orderDetailsById?.english_version=='2'?'Australian':
                              orderDetailsById?.english_version=='3'?'British':
                              orderDetailsById?.english_version=='4'?'Canadian':''
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Service File:</td>
                          <td>
                            {serviceFile?.length > 0 ? (
                              serviceFile?.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                              })
                            ):null}
                            <br />
                          </td>
                        </tr>
                        
                        <tr>
                          <td>Instruction File:</td>
                          <td><br/>
                            {instructionFile?.length > 0 ? (
                              instructionFile?.map((item,index)=>{
                                return(
                                  item.endsWith(".docx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOCX")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".doc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".DOC")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pdf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".PDF")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".rtf")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={rtfImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".odt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".fodt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={odtImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pages")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pagesImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".ppt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={pptImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xls")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".xlsx")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={xlsxImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gdoc")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gdocImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pptm")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".pps")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gslideImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".gsheet")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={gsheetImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".txt")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={plainTextImg} alt="img" /><span>{shortFileName(item)}</span></a>:
                                  item.endsWith(".text")?<a data-toggle="tooltip" data-placement="top" title={item?.slice(62,80)} onClick={()=>downloadFile(item)} className="anchor-link service-file-link"><img src={LatexImg} alt="img" /><span>{shortFileName(item)}</span></a>:''
                                )
                              })
                            ): null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}

                {/* Comment Section Start */}

                {ShowAllComments?.map((item,index)=>{
                  return(
                    <div className="CommentItem">
                      {/*Client Comment Section Start*/}
                        {item?.client_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.client_comment =="undefined" ? '' : item?.client_comment}}/>
                          </>
                        )}

                        {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment =='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.client_name)}</span>{item?.client_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                {
                                  item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                }
                              </li>
                            </ul>
                          </>

                        )}

                        {item?.client_file!= 'https://elitereviser.com/serversite/public/uploads/' &&  item?.client_comment!='' && (
                          <>
                            <ul className="showAttachFileList">
                              <li>
                                {
                                  item?.client_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:
                                  item?.client_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.client_file_name)} onClick={()=>downloadFile(item?.client_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.client_file_name)}</span></a>:''
                                }
                              </li>
                            </ul>
                          </>

                        )}
                      {/*Client Comment Section End*/}
                        
                      {/* --Editor Comment Section Start-- */}
                        {item?.editor_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.editor_comment =="undefined" ? '' : item?.editor_comment}}/>
                          </>
                        )}

                        {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment =='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo BlueNameLogo">{shortUserName(item?.editor_name)}</span>{item?.editor_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                                item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                                }
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.editor_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.editor_comment!='' &&(

                        <ul className="showAttachFileList">
                          <li>
                            { 
                            item?.editor_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:
                            item?.editor_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.editor_file)} onClick={()=>downloadFile(item?.editor_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.editor_file_name)}</span></a>:''
                            }
                          </li>
                        </ul>

                        )}
                      {/* --Editor Comment Section End-- */}

                      {/* --Admin Comment Section Start-- */}
                        {item?.admin_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.admin_comment =="undefined" ? '' : item?.admin_comment}}/>
                          </>
                        )}

                        {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.admin_name)}</span>{item?.admin_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                                  item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                                }
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.admin_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.admin_comment!='' && (

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.admin_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:
                              item?.admin_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.admin_file_name)} onClick={()=>downloadFile(item?.admin_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.admin_file_name)}</span></a>:''
                            } 
                          </li>
                        </ul>

                        )}
                      {/* --Admin Comment Section End-- */}

                      {/* --Internal Comment Section Start-- */}
                        {item?.internal_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.internal_comment =="undefined" ? '' : item?.internal_comment }}/>
                          </>
                        )}

                        {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.internal_name)}</span>{item?.internal_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                                  item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                                }
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.internal_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.internal_comment!='' &&(

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.internal_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:
                              item?.internal_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.internal_file_name)} onClick={()=>downloadFile(item?.internal_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.internal_file_name)}</span></a>:''
                            }
                          </li>
                        </ul>

                        )}
                      {/* --Internal Comment Section End-- */}

                      {/* --Deputy Comment Section Start-- */}
                        {item?.deputy_comment!='' && (
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                            </p>
                            <p dangerouslySetInnerHTML={{__html: item?.deputy_comment =="undefined" ? '' : item?.deputy_comment }}/>
                          </>
                        )}

                        {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment =='' &&(
                          <>
                            <p className="AuthorName">
                              <span className="NameLogo">{shortUserName(item?.deputy_name)}</span>{item?.deputy_designation}
                            </p>
                            <ul className="showAttachFileList">
                              <li>
                                { 
                                  item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                                  item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                                }
                              </li>
                            </ul>
                          </>
                        )}

                        {item?.deputy_file!= 'https://elitereviser.com/serversite/public/uploads/' && item?.deputy_comment!=''&& (

                        <ul className="showAttachFileList">
                          <li>
                            { 
                              item?.deputy_file?.endsWith('.pdf') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.PDF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={pdfImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.docx') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.DOCX') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={docImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jpg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JPG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jpeg') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JPEG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.png') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.PNG') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.jfif') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:
                              item?.deputy_file?.endsWith('.JFIF') ? <a data-toggle="tooltip" data-placement="top" title={shortCommentFileName(item?.deputy_file_name)} onClick={()=>downloadFile(item?.deputy_file)} className=" icon-span anchor-link service-file-link"><img src={iconImg} alt="img" /><span>{shortCommentFileName(item?.deputy_file_name)}</span></a>:''
                            }
                          </li>
                        </ul>

                        )}
                      {/* --Deputy Comment Section End-- */}

                    <div className="RhtDate">
                      <span>  
                        {get_date_format(item?.created_at)}
                        <br />
                        {get_time_format(item?.created_at)}
                      </span>
                    </div>
                  </div>
                )})}

                {/* Comment Section End */}
                
              </div>

              {orderDetailsById?.job_status == '3' && (
                <div className="BtmFtr">
                  <a className="attachFileLink"><input type="file" className="inputFile" onChange={(e) => {uploadCommentFile(e)}} /><img src={attachFileIcon} alt /></a>
                  <CKEditor 
                    editor={ ClassicEditor }
                    data = {clientMsg}
                    config={{
                      toolbarLocation: "bottom",
                      toolbar: ['link']
                    }}
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setClientMsg(data);
                    }}
                  />
                  
                  <ul className="showAttachFileList">
                    {((fileName && fileName != undefined) || (fileName && fileName != null) || (fileName && fileName != '')) ? (
                      <li>
                        <span className="icon-span">
                          {
                            fileName?.endsWith('.pdf') ? <img src={pdfImg} alt="img" />:
                            fileName?.endsWith('.PDF') ? <img src={pdfImg} alt="img" />:
                            fileName?.endsWith('.docx') ? <img src={docImg} alt="img" />:
                            fileName?.endsWith('.DOCX') ? <img src={docImg} alt="img" />:
                            fileName?.endsWith('.jpg') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JPG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.jpeg') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JPEG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.png') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.PNG') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.jfif') ? <img src={iconImg} alt="img" />:
                            fileName?.endsWith('.JFIF') ? <img src={iconImg} alt="img" />:''
                          }
                        </span>
                        <span className="upload-title-span">{fileName}</span>
                        <span className="delete-upload-span"><i class="bi bi-trash" onClick={()=>deleteCommentFile()}></i></span>
                      </li>
                    ):(
                      <li></li>
                    )}
				          </ul>

				          <a className="btn btn-primary CmnBtn submitBtm" onClick={()=>commentSection()}>Submit</a>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <Modal show={markComplete} onHide={()=>{markClose();setRating(0)}} className="CmnModal CustReview" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Customer Reviews</h2>
          <p>Please rate your experience with our service.</p>
          <p> 
            <Rating
              onClick={handleRating}
              // onPointerEnter={onPointerEnter}
              // onPointerLeave={onPointerLeave}
              // onPointerMove={onPointerMove}
              /* Available Props */
            />
          </p>
          {orderDetailsById?.job_type == "type2" ? (
            showMarkCompleteBtn ? (
              <button className="CmnBtn" disabled><i class="bi bi-check-circle"></i> Mark Complete</button>
            ):(
              <>
                {loadingMarkComplete1 ? (
                  <Button className="CmnBtn" disabled><i class="fa fa-refresh fa-spin"></i>Mark Complete</Button>
                ):(
                  <Button className="CmnBtn" onClick={()=>submitTypeTwoMarkComplete()}><i class="bi bi-check-circle"></i> Mark Complete</Button>
                )}
              </>
            )
          ):
          orderDetailsById?.job_type == "type1" ? (
            showMarkCompleteBtn ? (
              <button className="CmnBtn" disabled><i class="bi bi-check-circle"></i> Mark Complete</button>
            ):(
              <>
                {loadingMarkComplete ? (
                  <Button className="CmnBtn" onClick={()=>submitTypeOneMarkComplete()} disabled><i class="fa fa-refresh fa-spin"></i>Mark Complete</Button>
                ):(
                  <Button className="CmnBtn" onClick={()=>submitTypeOneMarkComplete()}><i class="bi bi-check-circle"></i> Mark Complete</Button>
                )}
              </>
            )
          ):null}
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={()=>{setClientFeedbackError(false);setSendMsgTo(false);handleClose()}} className="CmnModal CustReview" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Client Feedback</h2>
          <p>Give some feedback about your experience with our service to better serve you.</p>
          
          <textarea cols="" rows="" placeholder="Type here..." name="feedback" onChange={(e)=>setClientFeedback(e.target.value)} value={clientFeedback}></textarea>
          {clientFeedbackError && (clientFeedback== '' || clientFeedback== undefined || clientFeedback== null) && (<div className='error error-feedback'> Feedback is required</div>)}
          
          <label className="radio inline">
            <input Checked name="sendTo" type="radio" value="Send-to-all"  onChange={(e)=>sendOption(e.target.value)}/>
            <span>Send to All</span>
          </label>
          
          <label className="radio inline">
            <input name="sendTo" type="radio" value="Send-to-only-admin" onChange={(e)=>sendOption(e.target.value)}/>
            <span>Send to Elite Reviser</span>
          </label>

          {/* <label className="radio inline">
            <input Checked name="sendTo" type="radio" value="To-editor"  onChange={(e)=>sendOption(e.target.value)}/>
            <span>To editor</span>
          </label>

          <label className="radio inline">
            <input name="sendTo" type="radio" value="To-Elite-Reviser" onChange={(e)=>sendOption(e.target.value)}/>
            <span>To Elite Reviser</span>
          </label>

          <label className="radio inline">
            <input name="sendTo" type="radio" value="To-both" onChange={(e)=>sendOption(e.target.value)}/>
            <span>To both</span>
          </label> */}

          {feedbackLoading ? (
            <button class="CmnBtn" disabled><i class="bi bi-check-circle"></i> Send Feedback</button>
          ):(
            <button class="CmnBtn" onClick={()=> onSubmitFeedback()}><i class="bi bi-check-circle"></i> Send Feedback</button>
          )}
          {sendMsgToError && (sendMsgTo== '' || sendMsgTo== undefined || sendMsgTo== null) && (<div className='error error-bottom-feedback'> Send option is required</div>)}
        </Modal.Body>
      </Modal>
      
      <Modal show={orderCancel} onHide={orderCancelClose} className="CmnModal CancelOrder" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Order Cancelation</h2>
          <p>If you cancel, your order will be removed. Are you sure you want to cancel?</p>
          <a class="CmnBtn yesBtn" onClick={onLoginFormSubmit}>YES</a>
          <a class="cancelBtn noBtn" onClick={()=>orderCancelClose()}>NO</a>
        </Modal.Body>
      </Modal>

      <Modal show={orderCancelAfter30} onHide={orderCancelCloseAfter30mins} className={(orderDetailsById?.job_status == '32' || orderDetailsById?.job_status == '4') ? "CmnModal modal-dialog-new" : "CmnModal CancelOrder"} centered>
        <Modal.Header closeButton></Modal.Header>
        {(orderDetailsById?.job_status == '32' || orderDetailsById?.job_status == '4') ? (
          <Modal.Body>
            <p>Sorry, it is too late to cancel this order.</p>
            <a class="CmnBtn yesBtn" onClick={()=>orderCancelCloseAfter30mins()}>CLOSE</a>
          </Modal.Body>
        ):(
          <Modal.Body>
            <h2>Request Order Cancelation</h2>
            <p>Your order submission is past 30 min. Contact the sales team to cancel your order at <a href="mailto:sales@elitereviser.com">sales@elitereviser.com</a>.</p>
            <a class="CmnBtn yesBtn" onClick={()=>orderCancelCloseAfter30mins()}>CLOSE</a>
          </Modal.Body>
        )}
      </Modal>

      <Modal show={orderCancelForm} onHide={orderCancelFormClose} className="CmnModal CancelOrder" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>Confirm Cancelation</h2>
          <p>Are you sure you want to cancel your order?</p>
          
          {/* <input type="text" class="form-control" placeholder="Give proper reason for this cancellation" name="cancellation_title" onChange={(e)=>setShowCancelReasonTitle(e.target.value)} value={showCancelReasonTitle}/>
          {showCancelReasonTitleError && (showCancelReasonTitle== '' || showCancelReasonTitle== undefined || showCancelReasonTitle== null) && (<div className='error'>Reason is required</div>)}
          
          <textarea class="form-control" rows="3" placeholder="Type here reason with full description..." name="cancellation_description" onChange={(e)=>setShowCancelReasonDescription(e.target.value)} value={showCancelReasonDescription}></textarea>
          {showCancelReasonDescriptionError && (showCancelReasonDescription== '' || showCancelReasonDescription== undefined || showCancelReasonDescription== null) && (<div className='error'> Reason Description is required</div>)} */}
          
          
          {/* <a class="CmnBtn"><i class="bi bi-check-circle"></i> Send Request Oder Cancellation</a> */}
          

          {/* {cancelLoading && cancelLoading ? (
              <Button class="CmnBtn" disabled><i class="fa fa-refresh fa-spin"></i>Send Request Order Cancellation</Button>
            ):( */}
                {/* <Button class="CmnBtn" onClick={()=>orderCancellation()}><i class="bi bi-check-circle"></i> Send Request Order Cancellation</Button> */}
                {/* <Button class="CmnBtn" onClick={()=>orderCancellation()}><i class="bi bi-check-circle"></i> YES</Button> */}
              <>
                {cancelLoading ? (
                  <Button className="CmnBtn yesBtn" disabled onClick={()=>orderCancellation()}><i class="bi bi-check-circle"></i>PLEASE WAIT..</Button>
                ):(
                  <>
                    <Button className="CmnBtn yesBtn" onClick={()=>orderCancellation()}>YES</Button>
                    <Button className="cancelBtn noBtn" onClick={()=>orderCancelFormClose()}>NO</Button>
                  </>
                )}
              </>
            {/* ) */}
          {/* } */}
          
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Clientmyorders;
