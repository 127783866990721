import {
  React, useEffect, useState, useRef,
  NavLink, useNavigate
} from '../../utility/CommonImport'; // Import 

const Adminsidebar = () => {
  const navigate = useNavigate();
  const sidebarRef = useRef();
  
  useEffect(() => {
    const currentUrl = window.location.href;
    const links = document.querySelectorAll("ul.sidebar-nav a");

    links.forEach((link) => {
      if (link.href === currentUrl) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });

    // Cleanup function (optional)
    return () => {
      links.forEach((link) => {
        link.classList.remove("active");
      });
    };
  }, []);

  //start
const [deviceType, setDeviceType] = useState("");

useEffect(() => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      var UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  if (hasTouchScreen) {
    setDeviceType("Mobile");
    
    document.body.classList.add('toggle-sidebar');
  } else {
    setDeviceType("Desktop");
  }
}, []);


//end
  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };
  
    const on = (type, el, listener, all = false) => {
      if (all) {
        select(el, all).forEach((e) => e.addEventListener(type, listener));
      } else {
        select(el, all).addEventListener(type, listener);
      }
    };
  
    const handleBodyClick = (e) => {
      // Check if the clicked element is outside the sidebar
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        select('body').classList.add('toggle-sidebar');
      }
    };
  
    on('click', '.close-sidebar-btn', function (e) {
      select('body').classList.toggle('toggle-sidebar');
    });
  
    // Add the event listener to the document for body clicks
    // document.addEventListener('click', handleBodyClick);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const LogoutFunction = () => {
    localStorage.removeItem("user_data_elite");
    localStorage.clear();
    navigate("/admin-login");
  };
  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <a className="CloseBtn close-sidebar-btn">
          <i className="bi bi-x" />
        </a>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink to="/admin-dashboard" className="nav-link collapsed">
              <span className="icon-span">
                <i class="fa fa-tachometer" aria-hidden="true"></i>
              </span>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item job-board-item">
            <NavLink to="/admin-job-board" className="nav-link collapsed">
              <span>Job Board</span>
            </NavLink>
          </li>
          <li className="nav-item admin-job-item ongoing-jobs-item">
            <NavLink to="/admin-on-going-job" className="nav-link collapsed">
              <span className="icon-span">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </span>
              <span>Ongoing Jobs</span>
            </NavLink>
          </li>
          <li className="nav-item admin-job-item">
            <NavLink to="/admin-holding-job" className="nav-link collapsed">
            {/* <NavLink to="#" className="nav-link collapsed"> */}
              <span className="icon-span">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </span>
              {/* <span>Holding Jobs</span> */}
              <span>Returned Jobs</span>
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to="/admin-returned-job" className="nav-link collapsed">
              <span className="icon-span">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </span>
              <span>Returned Jobs</span>
            </NavLink>
          </li> */}
          {/* <li className="nav-item">
            <NavLink to="/admin-approve-job" className="nav-link collapsed">
              <span className="icon-span">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </span>
              <span>Client Approved</span>
            </NavLink>
          </li> */}
          <li className="nav-item admin-job-item completed-jobs-item">
            <NavLink to="/admin-completed-job" className="nav-link collapsed">
              <span className="icon-span">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </span>
              <span>Completed Jobs</span>
            </NavLink>
          </li>
          <li className="nav-item job-cancel-item">
            <NavLink to="/admin-cancelled-job" className="nav-link collapsed">
              <span>Canceled Jobs</span>
            </NavLink>
          </li>
          {/* <li className="nav-item no-link"><NavLink className="nav-link collapsed"><i class="fa fa-calendar-check-o" aria-hidden="true"></i> <span>Completed Jobs</span></NavLink></li> */}
          {/* <li className="nav-item no-link"><NavLink className="nav-link collapsed"><i className="bi bi-house"></i> <span>My Account</span></NavLink></li> */}
          <li className="nav-item admin-item">
            <NavLink to="/admin-deputy-management" className="nav-link">
              <span className="icon-span">
                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
              </span>
              <span>Deputy Admin</span>
            </NavLink>
          </li>
          <li className="nav-item admin-item">
            <NavLink
              to="/admin-internal-editor-management"
              className="nav-link"
            >
              <span className="icon-span">
                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
              </span>
              <span>Internal Editors</span>
            </NavLink>
          </li>
          <li className="nav-item admin-item">
            <NavLink to="/admin-editor-management" className="nav-link">
              <span className="icon-span">
                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
              </span>
              <span>Editors</span>
            </NavLink>
          </li>
          <li className="nav-item client-item">
            <NavLink to="/admin-client-management" className="nav-link">
              <span>Clients</span>
            </NavLink>
          </li>
          <li className="nav-item change-password-item">
            <NavLink to="/admin-my-account" className="nav-link">
              <span className="icon-span">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <span>Change Password</span>
            </NavLink>
          </li>
          <li className="nav-item price-settings-item">
            <NavLink
              to="/admin-pricing-settings"
              className="nav-link collapsed"
            >
              <span className="icon-span">
                <i class="bi bi-gear"></i>
              </span>
              <span>Pricing Settings</span>
            </NavLink>
          </li>
          {/* <li className="nav-item billing-details-item"><NavLink to="/admin-my-earnings" className="nav-link collapsed"> <span>My Earnings</span></NavLink></li> */}
          <li className="nav-item clients-feedback-item">
            <NavLink to="/admin-feedback" className="nav-link collapsed">
              <span>Client Feedback</span>
            </NavLink>
          </li>
          <li className="nav-item admin-billing-details-item">
            <NavLink to="/admin-billing-details" className="nav-link collapsed">
              <i className="bi bi-inbox" /> <span>Billing Details</span>
            </NavLink>
          </li>
          <li className="nav-item inquiry-item">
            <NavLink to="/admin-inquiry-details" className="nav-link collapsed">
              <span>Inquiry Details</span>
            </NavLink>
          </li>

          <li className="nav-item logout-item">
            <a href="javascript:void(0);" className="nav-link collapsed" onClick={() => LogoutFunction()}>
              Sign Out
            </a>
          </li>
        </ul>
      </aside>
      <div className="side-menu-overlay"></div>
    </div>
  );
};

export default Adminsidebar;
